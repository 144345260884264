import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getAllInstitutionAndAggrementDetails,
  getCountInstitutionDetails,
} from "../../actions/shg";
import { Link } from "react-router-dom";
import RenewalInstitutionAgreement from "./RenewalInstitutionAgreement";
import InstitutionRenewalHistory from "./InstitutionRenewalHistory";

import Spinner from "../layout/Spinner";

const AllInstitutionRenewal = ({
  auth: { isAuthenticated, user },
  shg: { allInstitutions, allInstutionAggrementDetails, countInstitutions },
  getAllInstitutionAndAggrementDetails,
  getCountInstitutionDetails,
}) => {
  useEffect(() => {
    getAllInstitutionAndAggrementDetails();
  }, [getAllInstitutionAndAggrementDetails]);
  useEffect(() => {
    getCountInstitutionDetails();
  }, [getCountInstitutionDetails]);

  const [showEditModal, setShowEditModal] = useState(false);
  const handleEditModalClose = () => setShowEditModal(false);
  const [userData, setUserData] = useState(null);
  const onRenewal = (allInstitutions) => {
    setShowEditModal(true);
    setUserData(allInstitutions);
  };

  const onRenewalModalChange = (e) => {
    if (e) {
      handleEditModalClose();
    }
  };

  // Renewal History start
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const handleHistoryModalClose = () => setShowHistoryModal(false);

  const [historyData, setHistoryData] = useState(null);
  const onRenewalHistory = (allInstitutions) => {
    setShowHistoryModal(true);
    setUserData(allInstitutions);
    setHistoryData(allInstitutions);
  };

  const onRenewalHistoryModalChange = (e) => {
    if (e) {
      handleHistoryModalClose();
    }
  };
  // Renewal History end

  return !isAuthenticated || !user || !allInstutionAggrementDetails ? (
    <Spinner />
  ) : (
    
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">All Institution Renewal Details </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-11 col-md-11 col-sm-12 col-11 py-3">
              <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              {/* <Link to="/add-institution">
                <img
                  className="img_icon_size log float-right"
                  src={require("../../static/images/add-icon.png")}
                  alt="Add Institution"
                  title="Add Institution"
                />
              </Link> */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Institution Name</th>
                        <th>Info</th>
                        <th> Status</th>
                        <th> StartDate</th>
                        <th> EndDate</th>
                        <th> PaymentMode</th>
                        <th>Amount</th>
                        <th>Op</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allInstutionAggrementDetails &&
                        allInstutionAggrementDetails.map(
                          (allInstitutions, idx) => {
                            var ED = allInstitutions.output.aggrementStartDate.split(
                              /\D/g
                            );
                            var aggrementStartDate = [ED[2], ED[1], ED[0]].join(
                              "-"
                            );
                            var EDE = allInstitutions.output.aggrementEndDate.split(
                              /\D/g
                            );
                            var aggrementEndDate = [
                              EDE[2],
                              EDE[1],
                              EDE[0],
                            ].join("-");
                            return (
                              <tr key={idx}>
                                <td className="">
                                  {allInstitutions.institutionName}
                                </td>
                                <td>{"B-"+allInstitutions.shgCount.length+" "+"M-"+ allInstitutions.memberCount.length }</td>
                                <td>
                                  {allInstitutions.output.aggrementStatus}
                                </td>
                                <td>{aggrementStartDate}</td>
                                <td>{aggrementEndDate}</td>
                                <td>
                                  {allInstitutions.output.aggrementPaymentMode}
                                </td>
                                <td>
                                  {allInstitutions.output.aggrementAmount}
                                </td>

                                <td>
                                  <button
                                    variant="success"
                                    className="btn sub_form"
                                    onClick={() =>
                                      onRenewal(allInstitutions, idx)
                                    }
                                  >
                                    {allInstitutions.output.aggrementStatus ===
                                    "Expired" ? (
                                      <span style={{ color: "red" }}>
                                        Renewal
                                      </span>
                                    ) : (
                                      <span style={{ color: "black" }}>
                                        Renewal
                                      </span>
                                    )}
                                  </button>
                                  <button
                                    variant="success"
                                    className="btn sub_form"
                                    onClick={() =>
                                      onRenewalHistory(allInstitutions, idx)
                                    }
                                  >
                                    History
                                  </button>
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </section>
        <Modal
          show={showEditModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h4 className="modal-title text-center">
                Renewal Institution Agreement
              </h4>
            </div>
            <div className="col-lg-2">
              <button onClick={handleEditModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <RenewalInstitutionAgreement
              RenewalData={userData}
              onRenewalModalChange={onRenewalModalChange}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={showHistoryModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h4 className="modal-title text-center">
                Institution Renewal History
              </h4>
            </div>
            <div className="col-lg-2">
              <button onClick={handleHistoryModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <InstitutionRenewalHistory
              HistoryData={historyData}
              onRenewalHistoryModalChange={onRenewalHistoryModalChange}
            />
          </Modal.Body>
        </Modal>
        <div className="row">
          <div className="col-lg-11 col-md-6 col-sm-12 col-12 align_right">
            <label className="float-right ml-3">
              No. of Institutions : {allInstutionAggrementDetails.length}
            </label>
            <label>No. of Clients : {countInstitutions.length}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AllInstitutionRenewal.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getAllInstitutionAndAggrementDetails: PropTypes.func.isRequired,
  getCountInstitutionDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getAllInstitutionAndAggrementDetails,
  getCountInstitutionDetails,
})(AllInstitutionRenewal);
