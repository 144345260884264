import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AddUserGroupDetails } from "../../actions/user";
import Spinner from "../layout/Spinner";
import { Redirect, Link } from "react-router-dom";

import { getActiveInstitutions } from "../../actions/shg";

const AddUserGroup = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { activeInstitutions },
  AddUserGroupDetails,
  onAddStaffModalChange,
  getActiveInstitutions,
}) => {
  useEffect(() => {
    getActiveInstitutions();
  }, [getActiveInstitutions]);
  //formData
  const [formData, setFormData] = useState({
    userGroupName: "",

    isSubmitted: false,
  });

  const { userGroupName, isSubmitted } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    const finalData = {
      userGroupName: userGroupName,
    };
    AddUserGroupDetails(finalData);
    setFormData({
      ...formData,
      userGroupName: "",
      isSubmitted: true,
    });
  };

  if (isSubmitted) {
    return <Redirect to="/all-user-groups" />;
  }

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="col-lg-11 col-md-11 col-sm-12 col-12">
            <h2 className="heading_color">Add User Group Details </h2>
            <hr />
          </div>
          <section className="sub_reg">
            <div className=" col-lg-8 col-md-12 col-sm-12 col-12 py-3">
              <div className="row col-lg-11 col-md-11 col-sm-12 col-12 no_padding ">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control"> User Group Name* :</label>
                  <input
                    type="text"
                    name="userGroupName"
                    value={userGroupName}
                    className="form-control"
                    onChange={(e) => onInputChange(e)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 Savebutton " size="lg">
              {loading ? (
                <button
                  className="btn sub_form btn_continue Save float-right"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <>
                  <input
                    type="submit"
                    name="Submit"
                    value="Submit"
                    className="btn sub_form btn_continue blackbrd float-right"
                  />
                  <Link
                    className="btn sub_form btn_continue blackbrd float-right"
                    to="/all-user-groups"
                  >
                    Cancel
                  </Link>
                </>
              )}
            </div>
          </section>
        </form>
      </div>
    </Fragment>
  );
};

AddUserGroup.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  AddUserGroupDetails: PropTypes.func.isRequired,
  getActiveInstitutions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  AddUserGroupDetails,
  getActiveInstitutions,
})(AddUserGroup);
