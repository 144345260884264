import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
// import // getPartialMeetingCount,
// // getUnverifiedMeetingCount,
// "../../actions/auth";
import {
  getPartialMeetingCount,
  getUnverifiedMeetingCount,
} from "../../actions/auth";
const Notification = ({
  auth: { user, isAuthenticated, unverifiedmeetingcount, partialmeetingcount },
  getPartialMeetingCount,
  getUnverifiedMeetingCount,
}) => {
  // useEffect(() => {
  //   getPartialMeetingCount();
  // }, [getPartialMeetingCount]);
  // useEffect(() => {
  //   getUnverifiedMeetingCount();
  // }, [getUnverifiedMeetingCount]);
  return !isAuthenticated || !user ? (
    <Spinner />
  ) : (
    <>
      {user && user.userGroupName !== "Super Admin" ? (
        <div className="row col-lg-11 col-md-12 col-sm-12 col-12 notifyTop">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <marquee>
              {" "}
              Unverified Meetings :{" "}
              {unverifiedmeetingcount &&
              unverifiedmeetingcount[0] &&
              unverifiedmeetingcount[0].NOM
                ? unverifiedmeetingcount[0].NOM
                : 0}
            </marquee>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <marquee>
              Partial Member Meetings :{" "}
              {partialmeetingcount &&
              partialmeetingcount[0] &&
              partialmeetingcount[0].NOM
                ? partialmeetingcount[0].NOM
                : 0}
            </marquee>
          </div>
        </div>
      ) : (
        <Fragment></Fragment>
      )}
    </>
  );
};

Notification.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getPartialMeetingCount: PropTypes.func.isRequired,
  getUnverifiedMeetingCount: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getPartialMeetingCount,
  getUnverifiedMeetingCount,
})(Notification);
