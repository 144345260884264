import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import Select from "react-select";
import { getAllBatches, getAllBatchesDropdown } from "../../actions/shg";
import EditSkillTrainingDetail from "../dashboard/EditSkillTrainingDetail";
import DeactiveSkillTrainingDetails from "../dashboard/DeactiveSkillTrainingDetails";
import {
  getActivePrograms,
  AddSkillProgramDetails,
  getSkillDetails,
  getbatchShgMembers,
} from "../../actions/skill";
const AddSkillTraining = ({
  auth: { isAuthenticated, user, users },
  shg: { allbatchesdropdown },
  skill: { activeprogram, shgMembersList, allskillData },
  getbatchShgMembers,
  getAllBatchesDropdown,
  getActivePrograms,
  getAllBatches,
  AddSkillProgramDetails,
  getSkillDetails,
  // getAllchanges,
}) => {
  useEffect(() => {
    getSkillDetails();
  }, [getSkillDetails]);
  useEffect(() => {
    getActivePrograms();
  }, [getActivePrograms]);

  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);

  useEffect(() => {
    getAllBatches();
  }, [getAllBatches]);
  //formData

  const [formData, setFormData] = useState({
    isSubmitted: false,
  });

  const { isSubmitted } = formData;

  const [error, setError] = useState({
    InchargeIdChecker: false,
    InchargeIdErrorStyle: {},
  });
  const { InchargeIdChecker, InchargeIdErrorStyle } = error;

  const checkErrors = () => {
    if (!InchargeIdChecker) {
      setError({
        ...error,
        InchargeIdErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };
  const [showHide, setShowHide] = useState({
    showAddSkillSection: false,
  });
  const { showAddSkillSection } = showHide;

  const [trainingDate, settrainingDate] = useState("");

  const onDateChange = (e) => {
    settrainingDate(e.target.value);
  };

  const onInputChange1 = (e) => {
    setFormDatas({ ...addData, [e.target.name]: e.target.value });
  };

  const [addData, setFormDatas] = useState({
    clientTime: "",
    trainingTime: "",
    trainingDescription: "",
    village: "",
  });

  const { trainingTime, trainingDescription, village } = addData;
  const allbatchesList = [];
  allbatchesdropdown.map((batchs) =>
    allbatchesList.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState();
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();
  const onBatchChange = (e) => {
    setError1({
      ...error1,
      nametypeIdChecker: true,
      nametypeIdErrorStyle: { color: "#000" },
    });
    getmembersData("");
    var batchId = "";
    var batchName = "";
    getbatchsData(e);
    batchId = e.batchId;
    batchName = e.value;
    setbatchId(batchId);
    setbatchName(batchName);

    const finalData = {
      batchId: batchId,
      batchName: batchName,
    };

    getbatchShgMembers(finalData);
  };
  //member
  const allmemberList = [];
  shgMembersList.map((members) =>
    allmemberList.push({
      membersId: members._id,
      memberDesg: members.memberDesg,
      label: members.memberName,
      value: members.memberName,
    })
  );

  const [members, getmembersData] = useState();
  const [membersId, setmembersId] = useState();
  const [memberName, setmembersName] = useState();
  const [memberDesg, setmemberDesg] = useState();

  const onmembersChange = (e) => {
    setError1({
      ...error1,
      membertypeIdChecker: true,
      membertypeIdErrorStyle: { color: "#000" },
    });
    var membersId = "";
    var memberName = "";
    var memberDesg = "";
    getmembersData(e);
    membersId = e.membersId;
    memberName = e.value;
    memberDesg = e.memberDesg;
    setmembersId(membersId);
    setmembersName(memberName);
    setmemberDesg(memberDesg);
  };

  const allProgramList = [];
  activeprogram.map((program) =>
    allProgramList.push({
      programId: program._id,
      label: program.programName,
      value: program.programName,
    })
  );

  const [program, getprogramData] = useState();
  const [programId, setprogramId] = useState();
  const [programName, setprogramName] = useState();
  const onprogramChange = (e) => {
    //Required Validation starts
    setError({
      ...error,
      InchargeIdChecker: true,
      InchargeIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    var programId = "";
    var programName = "";
    getprogramData(e);
    programId = e.programId;
    programName = e.value;
    setprogramId(programId);
    setprogramName(programName);
  };
  const [error1, setError1] = useState({
    nametypeIdChecker: false,
    nametypeIdErrorStyle: {},
    membertypeIdChecker: false,
    membertypeIdErrorStyle: {},
  });
  const {
    nametypeIdChecker,
    nametypeIdErrorStyle,
    membertypeIdChecker,
    membertypeIdErrorStyle,
  } = error1;
  const checkErrorscontact = () => {
    if (!nametypeIdChecker) {
      setError1({
        ...error1,
        nametypeIdErrorStyle: { color: "#F00" },
      });
      return false;
    }
    if (!membertypeIdChecker) {
      setError1({
        ...error1,
        membertypeIdErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };
  const [AddedDetails, AddDetails] = useState([]);
  const onAdd = (e) => {
    const programList = AddedDetails.filter(
      (AddDetails) => AddDetails.memberName === memberName
    );

    e.preventDefault();
    if (programList.length === 0) {
      if (checkErrorscontact()) {
        const addData = {
          memberName: memberName,
          village: village,
          trainingTime: trainingTime,
          trainingDescription: trainingDescription,
          batchId: batchId,
          batchName: batchName,
          memberId: membersId,
          memberDesg: memberDesg,
          trainingEnteredDateTime: new Date().toLocaleString("en-GB"),
          trainingEnteredById: user._id,
          institutionId: user && user.institutionId,
          trainingDate: trainingDate,
          programId: programId,
          programName: programName,
        };

        setFormDatas({
          ...addData,
          memberName: "",
          village: "",
          trainingTime: "",
          trainingDescription: "",
          batchName: "",
          memberId: "",
          memberDesg: "",
        });
        setmemberDesg("");
        setmembersName("");
        setbatchName("");
        getbatchsData("");
        getmembersData("");
        let temp = [];

        AddSkillProgramDetails(addData);
        getSkillDetails(addData);
      }
    }
  };

  const onSave = (e) => {
    e.preventDefault();
    if (checkErrors()) {
      const finalData = {
        trainingDate: trainingDate,
        programId: programId,
        programName: programName,
      };
      setShowHide({
        ...showHide,
        showAddSkillSection: true,
      });
      getSkillDetails(finalData);
    }
  };

  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (allskillData, idx) => {
    setShowUpdateModal(true);
    setUserData(allskillData);
  };

  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const [userDatas, setUserDatas] = useState(null);
  const onUpdate = (allskillData, idx) => {
    setShowDeactiveModal(true);
    setUserDatas(allskillData);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding">
            <div className=" col-lg-2 col-md-11 col-sm-10 col-10">
              <h5 className="heading_color">Add Skill Training Details</h5>
            </div>

            <div className="col-lg-10 col-md-11 col-sm-12 col-11 py-3">
            <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              
            </div>
          </div>
          <form className="row" onSubmit={(e) => onSave(e)}>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
              <div className="row card-new ">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <label className="label-control">Training Date* :</label>
                  <br />
                  <input
                    type="date"
                    placeholder="dd/mm/yyyy"
                    className="form-control cpp-input datevalidation"
                    name="trainingDate"
                    value={trainingDate}
                    onChange={(e) => onDateChange(e)}
                    style={{
                      width: "65%",
                    }}
                    required
                  />
                </div>

                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <label className="label-control" style={InchargeIdErrorStyle}>
                    Training Type :
                  </label>
                  <Select
                    name="programName"
                    options={allProgramList}
                    isSearchable={true}
                    value={program}
                    placeholder="Select Program"
                    onChange={(e) => onprogramChange(e)}
                  />
                </div>
                <div className="col-md-10 col-lg-3 col-sm-12 col-12 py-4">
                  <input
                    type="submit"
                    name="submit"
                    value="Save"
                    className="btn sub_form btn_continue Save float-right"
                  />
                </div>
              </div>
            </div>
          </form>
          {showAddSkillSection && (
            <>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-2">
                <div className="row card-new ">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <label
                      className="label-control"
                      style={nametypeIdErrorStyle}
                    >
                      Batch* :
                    </label>
                    <Select
                      name="batchName"
                      options={allbatchesList}
                      isSearchable={true}
                      value={batchs}
                      placeholder="Select SHG"
                      onChange={(e) => onBatchChange(e)}
                    />
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <label
                      className="label-control"
                      style={membertypeIdErrorStyle}
                    >
                      Member* :
                    </label>
                    <Select
                      name="memberName"
                      options={allmemberList}
                      isSearchable={true}
                      value={members}
                      placeholder="Select Members"
                      onChange={(e) => onmembersChange(e)}
                    />
                  </div>

                  <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                    <label className="label-control">Village :</label>
                    <input
                      type="text"
                      name="village"
                      value={village}
                      className="form-control"
                      onChange={(e) => onInputChange1(e)}
                      required
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                    <label className="label-control"> Member Role :</label>
                    <input
                      type="text"
                      name="memberDesg"
                      value={memberDesg}
                      className="form-control"
                      disabled
                    />
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-6 col-12 py-2">
                    <label className="label-control">Training Time :</label>
                    <input
                      type="time"
                      className="form-control"
                      onChange={(e) => onInputChange1(e)}
                      name="trainingTime"
                      value={trainingTime}
                      min="00:00"
                      max="24:00"
                      required
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <label className="label-control">Remarks* :</label>
                    <textarea
                      name="trainingDescription"
                      id="trainingDescription"
                      className="textarea form-control"
                      rows="4"
                      placeholder="Instructions"
                      style={{ width: "100%" }}
                      value={trainingDescription}
                      onChange={(e) => onInputChange1(e)}
                      required
                    ></textarea>
                  </div>
                  <div className="col-lg-6 col-md-11 col-sm-11 col-11 ">
                    <label className="label-control colorRed">
                      * Indicates mandatory fields, Please fill mandatory fields
                      before Submit
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-1">
                    <button
                      variant="success"
                      className="btn sub_form btn_continue Save float-right"
                      onClick={(e) => onAdd(e)}
                      style={
                        batchId !== "" &&
                        membersId !== "" &&
                        trainingDescription !== ""
                          ? { opacity: "1" }
                          : { opacity: "1", pointerEvents: "none" }
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="row py-3">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                  <section className="body">
                    <div className=" body-inner no-padding table-responsive fixTableHead">
                      <table
                        className="table table-bordered table-striped table-hover smll_row"
                        id="datatable2"
                      >
                        <thead>
                          <tr>
                            <th>Member Name</th>
                            <th>Member Role</th>
                            <th>Village</th>
                            <th>Training Type</th>
                            <th>Time</th>
                            <th>Description</th>
                            <th>Batch </th>
                            <th>OP </th>
                          </tr>
                        </thead>
                        <tbody>
                          {allskillData &&
                            allskillData &&
                            allskillData.map((allskillData, idx) => {
                              if (allskillData.trainingStatus === "Active")
                                return (
                                  <tr key={idx}>
                                    <td>{allskillData.memberName}</td>
                                    <td>{allskillData.memberDesg}</td>
                                    <td>{allskillData.village}</td>
                                    <td>{allskillData.programName}</td>
                                    <td>{allskillData.trainingTime}</td>
                                    <td>{allskillData.trainingDescription}</td>
                                    <td>{allskillData.batchName}</td>
                                    <td>
                                      <img
                                        className="img_icon_size log"
                                        onClick={() =>
                                          onUpdate(allskillData, idx)
                                        }
                                        src={require("../../static/images/delete.png")}
                                        alt="Deactivate"
                                        title="Deactivate"
                                      />
                                      &nbsp;
                                      <img
                                        className="img_icon_size log"
                                        onClick={() =>
                                          onEdit(allskillData, idx)
                                        }
                                        src={require("../../static/images/edit_icon.png")}
                                        alt="Edit"
                                        title="Edit"
                                      />
                                    </td>
                                  </tr>
                                );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
              </div>
              <Modal
                show={showUpdateModal}
                backdrop="static"
                keyboard={false}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <div className="col-lg-10">
                    <h3 className="modal-title text-center">
                      Edit Training Type Details{" "}
                    </h3>
                  </div>
                  <div className="col-lg-2">
                    <button onClick={handleUpdateModalClose} className="close">
                      <img
                        src={require("../../static/images/close.png")}
                        alt="X"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <EditSkillTrainingDetail
                    skillData={userData}
                    onUpdateModalChange={onUpdateModalChange}
                  />
                </Modal.Body>
              </Modal>
              <Modal
                show={showDeactiveModal}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <div className="col-lg-10">
                    <h3 className="modal-title text-center">
                      Delete Skill Training Details
                    </h3>
                  </div>
                  <div className="col-lg-2">
                    <button
                      onClick={handleDeactiveModalClose}
                      className="close"
                    >
                      <img
                        src={require("../../static/images/close.png")}
                        alt="X"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <DeactiveSkillTrainingDetails
                    trainingDeactiveData={userDatas}
                    onDeactiveModalChange={onDeactiveModalChange}
                  />
                </Modal.Body>
              </Modal>
            </>
          )}
        </section>
      </div>
    </Fragment>
  );
};

AddSkillTraining.propTypes = {
  auth: PropTypes.object.isRequired,
  skill: PropTypes.object.isRequired,
  getAllBatchesDropdown: PropTypes.func.isRequired,
  getActivePrograms: PropTypes.func.isRequired,
  getAllBatches: PropTypes.func.isRequired,
  getbatchShgMembers: PropTypes.func.isRequired,
  AddSkillProgramDetails: PropTypes.func.isRequired,
  getSkillDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
  skill: state.skill,
});

export default connect(mapStateToProps, {
  getAllBatches,
  getAllBatchesDropdown,
  getActivePrograms,
  getbatchShgMembers,
  AddSkillProgramDetails,
  getSkillDetails,
})(AddSkillTraining);
