import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import {
  AddUserDetails,
  getUserGroups,
  checkForUser,
} from "../../actions/user";
import {
  getActiveInstitutions,
  getAllBatchesDropdown,
} from "../../actions/shg";
import Spinner from "../layout/Spinner";

const AddUser = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { activeInstitutions, allbatchesdropdown },
  user: { userGroups, checkUser },
  AddUserDetails,
  errorResponse,
  onAddUserModalChange,
  getActiveInstitutions,
  getUserGroups,
  getAllBatchesDropdown,
  checkForUser,
}) => {
  useEffect(() => {
    getActiveInstitutions();
  }, [getActiveInstitutions]);
  useEffect(() => {
    getUserGroups();
  }, [getUserGroups]);
  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);
  useEffect(() => {
    checkForUser();
  }, [checkForUser]);

  let passwrdTooltip = {
    marginLeft: "-16em",
    position: "absolute",
    marginTop: "1.5em",
    pointerEvents: "none",
    zIndex: "999",
    width: "300px",
  };

  //formData
  const [formData, setFormData] = useState({
    password: "",
    userFullName: "",
    userName: "",
    userEmail: "",
    userPhone: "",
    usergroup: "",
    userAddress: "",
    userBatchId: "",
    isSubmitted: false,
  });

  const {
    password,
    rePassword,
    userName,
    userFullName,
    userEmail,
    userPhone,
    userBatchId,
    usergroup,
    userAddress,
  } = formData;

  const allbatchesList = [];

  allbatchesdropdown.map((batchs) =>
    allbatchesList.push({
      batchId: batchs._id,
      label: batchs.batchName + " (" + batchs.batchCode + ")",
      value: batchs.batchName,
    })
  );
  const [batchs, getbatchsData] = useState();
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();

  const onBatchChange = (e) => {
    //Required Validation starts
    setError({
      ...error,
      vIdChecker: true,
      vIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    var batchId = "";
    var batchName = "";
    getbatchsData(e);
    batchId = e.batchId;
    batchName = e.value;
    setbatchId(batchId);
    setbatchName(batchName);
  };

  const onInputChange2 = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const allusergroups = [];
  userGroups.map((usergrp) =>
    allusergroups.push({
      usergroupId: usergrp._id,
      label: usergrp.userGroupName,
      value: usergrp.userGroupName,
    })
  );

  const [usergrp, getGroups] = useState();

  const [usergroupId, setusergroupId] = useState();
  const [userGroupName, setuserGroupName] = useState();
  const [showHide, setShowHide] = useState({
    showBatchSection: false,
    showIsInchargeSection: false,
  });
  const { showBatchSection, showIsInchargeSection } = showHide;
  const onUserGroupChange = (e) => {
    setError({
      ...error,
      sIdChecker: true,
      sIdErrorStyle: { color: "#000" },
    });

    var usergroupId = "";
    var userGroupName = "";
    getGroups(e);

    usergroupId = e.usergroupId;
    userGroupName = e.value;

    setusergroupId(usergroupId);
    setuserGroupName(userGroupName);
    if (e.value === "Member") {
      setShowHide({
        ...showHide,
        showBatchSection: true,
        showIsInchargeSection: false,
      });
    } else if (e.value === "Co-ordinator") {
      setShowHide({
        ...showHide,
        showBatchSection: false,
        showIsInchargeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showBatchSection: false,
        showIsInchargeSection: false,
      });
    }
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const allinstitutions = [];
  activeInstitutions.map((institutions) =>
    allinstitutions.push({
      institutionId: institutions._id,
      label: institutions.institutionName,
      value: institutions.institutionName,
    })
  );

  const [institutions, getinstitutionsData] = useState();

  const [institutionId, setinstitutionId] = useState();
  const [institutionName, setinstitutionName] = useState();

  const onInstitutionChange = (e) => {
    setError({
      ...error,
      InstitutionIdChecker: true,
      InstitutionErrorStyle: { color: "#000" },
    });

    var institutionId = "";
    var institutionName = "";
    getinstitutionsData(e);
    institutionId = e.institutionId;
    institutionName = e.value;

    setinstitutionId(institutionId);
    setinstitutionName(institutionName);
  };

  const [userExistError, setUserExistError] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    if (checkError()) {
      const finalData = {
        password: password,
        userFullName: userFullName,
        userName: userName,
        userEmail: userEmail,
        userPhone: userPhone,
        userAddress: userAddress,
        institutionId:
          user.userGroupName === "Super Admin"
            ? institutionId
            : user.institutionId,
        institutionName:
          user.userGroupName === "Super Admin"
            ? institutionName
            : user.institutionName,

        isIncharge: isChecked,
        userEnteredById: user._id,
        userEnteredByName: user.userName,
        userGroupName: userGroupName,
        userGroupId: usergroupId,
        // userData: user,
        userBatchId: batchId ? batchId : null,
      };

      let isExist = false;
      checkUser.map((existingUserName) => {
        if (existingUserName.userName === userName) {
          isExist = "true";
        }
      });
      if (!isExist) {
        AddUserDetails(finalData);
        onAddUserModalChange(true);
        setFormData({
          ...formData,
          userFullName: "",
          userBatchId: "",
          userName: "",
          userEmail: "",
          userPhone: "",
          userGroupName: "",
          password: "",
          rePassword: "",
          userAddress: "",
          institutionName: "",
          // usergrp: "",
          institutions: "",
          batchId: "",
          isSubmitted: true,
        });
        getGroups("");
        getbatchsData("");
        getinstitutionsData("");
        setUserExistError(false);
      } else {
        setUserExistError(true);
      }
    }
  };

  const [error, setError] = useState({
    passwordValChecker: false,
    passwordValResult: "",
    passwordValStyle: {},
    passwordInptErrStyle: {},

    repwdValChecker: false,
    repwdValResult: "",
    repwdValStyle: {},
    repwdInptErrStyle: {},
  });

  const {
    passwordValChecker,
    passwordValResult,
    passwordValStyle,
    passwordInptErrStyle,

    repwdValChecker,
    repwdValResult,
    repwdValStyle,
    repwdInptErrStyle,
  } = error;
  const onInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "password":
        if (value === "") {
          setError({
            ...error,
            passwordValChecker: true,
            passwordValResult: "REQUIRED",
            passwordValStyle: { color: "#FF0000", marginTop: "30px" },
            passwordInptErrStyle: { border: "1px solid #FF0000" },
          });
          setFormData({ ...formData, [e.target.name]: "" });
        } else {
          const pwdFilter = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
          if (pwdFilter.test(value)) {
            setError({
              ...error,
              passwordValChecker: true,
              passwordValResult: "STRONG",
              passwordValStyle: { color: "#43b90f", marginTop: "30px" },
              passwordInptErrStyle: { border: "1px solid #43b90f" },
            });
          } else {
            setError({
              ...error,
              passwordValChecker: true,
              passwordValResult: "WEAK",
              passwordValStyle: { color: "#FF0000", marginTop: "30px" },
              passwordInptErrStyle: { border: "1px solid #FF0000" },
            });
          }
          setFormData({ ...formData, [e.target.name]: value.trim() });
        }
        break;

      case "rePassword":
        if (value === "") {
          setError({
            ...error,
            repwdValChecker: true,
            repwdValResult: "REQUIRED",
            repwdValStyle: { color: "#FF0000", marginTop: "30px" },
            repwdInptErrStyle: { border: "1px solid #FF0000" },
          });
          setFormData({ ...formData, [e.target.name]: "" });
        } else {
          if (value === formData.password) {
            setError({
              ...error,
              repwdValChecker: true,
              repwdValResult: "MATCHED",
              repwdValStyle: { color: "#43b90f", marginTop: "30px" },
              repwdInptErrStyle: { border: "1px solid #43b90f" },
            });
          } else {
            setError({
              ...error,
              repwdValChecker: true,
              repwdValResult: "DOES NOT MATCH",
              repwdValStyle: { color: "#FF0000", marginTop: "30px" },
              repwdInptErrStyle: { border: "1px solid #FF0000" },
            });
          }
          setFormData({ ...formData, [e.target.name]: value.trim() });
        }
        break;

      default:
        break;
    }
  };

  const checkErrors = (formData) => {
    if (formData && formData.password === "") {
      setError({
        ...error,
        passwordValChecker: true,
        passwordValResult: "REQUIRED",
        passwordValStyle: { color: "#FF0000", marginTop: "30px" },
        passwordInptErrStyle: { border: "1px solid #FF0000" },
      });
      return false;
    }
    if (formData && formData.rePassword !== formData.password) {
      setError({
        ...error,
        repwdValChecker: true,
        repwdValResult: "DOESNOT MATCH",
        // repwdValResult: "REQUIRED",
        repwdValStyle: { color: "#FF0000", marginTop: "30px" },
        repwdInptErrStyle: { border: "1px solid #FF0000" },
      });
      return false;
    }

    if (formData && formData.rePassword === "") {
      setError({
        ...error,
        repwdValChecker: true,
        repwdValResult: "REQUIRED",
        repwdValStyle: { color: "#FF0000", marginTop: "30px" },
        repwdInptErrStyle: { border: "1px solid #FF0000" },
      });
      return false;
    }

    return true;
  };
  //Required Validation Starts
  const [errors, setErrors] = useState({
    sIdChecker: false,
    sIdErrorStyle: {},
    InstitutionIdChecker: false,
    InstitutionErrorStyle: {},
    vIdChecker: false,
    vIdErrorStyle: {},
  });
  const {
    sIdChecker,
    sIdErrorStyle,
    InstitutionIdChecker,
    InstitutionErrorStyle,
    vIdChecker,
    vIdErrorStyle,
  } = error;

  const checkError = () => {
    if (!sIdChecker) {
      setError({
        ...error,
        sIdErrorStyle: { color: "#F00" },
      });
      return false;
    }
    if (user.userGroupName === "Super Admin") {
      if (!InstitutionIdChecker) {
        setError({
          ...error,
          InstitutionErrorStyle: { color: "#F00" },
        });
        return false;
      }
    }

    if (userGroupName === "Member") {
      if (!vIdChecker) {
        setError({
          ...error,
          vIdErrorStyle: { color: "#F00" },
        });
        return false;
      }
    }
    return true;
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <>
        {errorResponse && <p style={{ color: "red" }}>{errorResponse}</p>}
        <div className="container container_align">
          <form onSubmit={(e) => onSubmit(e)} autoComplete="off">
            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 py-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label className="label-control">Full Name* :</label>
                <input
                  type="text"
                  name="userFullName"
                  value={userFullName}
                  className="form-control"
                  onChange={(e) => onInputChange2(e)}
                  required
                />
              </div>

              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">Email :</label>
                <input
                  type="text"
                  name="userEmail"
                  value={userEmail}
                  className="form-control"
                  onChange={(e) => onInputChange2(e)}
                />
              </div>
            </div>

            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 py-12">
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control"> Phone* :</label>
                <input
                  type="Number"
                  name="userPhone"
                  value={userPhone}
                  className="form-control"
                  onChange={(e) => onInputChange2(e)}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 || e.keyCode === 190) &&
                    e.preventDefault()
                  }
                  required
                />
              </div>

              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control" style={sIdErrorStyle}>
                  User Group* :
                </label>
                <Select
                  name="userGroupName"
                  options={allusergroups}
                  isSearchable={true}
                  value={usergrp}
                  placeholder="Select User Group"
                  onChange={(e) => onUserGroupChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
            </div>

            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 py-12">
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control"> Address *:</label>
                <textarea
                  name="userAddress"
                  id="userAddress"
                  value={userAddress}
                  className="textarea form-control"
                  rows="3"
                  placeholder="Address"
                  onChange={(e) => onInputChange2(e)}
                  style={{ width: "100%" }}
                  required
                ></textarea>
              </div>

              {showIsInchargeSection && (
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Is the Co-ordinator also an Incharge? :
                  </label>
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "block",
                    }}
                    type="checkbox"
                    id="Unconfirmed"
                    onChange={handleOnChange}
                  />
                </div>
              )}
              {user.userGroupName === "Super Admin" && (
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label
                    className="label-control"
                    style={InstitutionErrorStyle}
                  >
                    {" "}
                    Institution*:
                  </label>
                  <Select
                    name="institutionName"
                    options={allinstitutions}
                    isSearchable={true}
                    value={institutions}
                    placeholder="Select Institution"
                    onChange={(e) => onInstitutionChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
              )}
            </div>
            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 py-12">
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control"> User Name* :</label>
                <input
                  type="userName"
                  name="userName"
                  value={userName}
                  autoComplete="new-password"
                  className="form-control"
                  onChange={(e) => onInputChange2(e)}
                  required
                />
              </div>
              {showBatchSection && (
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control" style={vIdErrorStyle}>
                    {" "}
                    SHG :
                  </label>
                  <Select
                    name="batchName"
                    options={allbatchesList}
                    isSearchable={true}
                    value={batchs}
                    placeholder="Select SHG"
                    onChange={(e) => onBatchChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
              )}
            </div>

            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 py-12">
              <div className=" col-lg-6 col-md-12 col-sm-12 col-12 py-12">
                <label className="label-control"> Password* :</label>
                <div
                  className="cstm-hint"
                  id="pass_admin_help"
                  //   style={{ top: "100px" }}
                >
                  <img
                    src={require("../../static/images/help1.png")}
                    alt="help"
                    id="img_tool_admin"
                    className="pass_admin_help_icon_question"
                  />
                  <div
                    id="tooltipPassAdmin"
                    className="syle-hint"
                    style={passwrdTooltip}
                    data-hint="Password  at least 1 uppercase and 1 lowercase, 1 digit, 1 symbol, length from 8 to 20"
                  ></div>
                </div>
                <div className="">
                  <input
                    type="password"
                    name="password"
                    className="form-control "
                    value={password}
                    style={passwordInptErrStyle}
                    onChange={(e) => onInputChange(e)}
                    autoComplete="new-password"
                    required
                  />
                  {passwordValChecker && (
                    <span
                      className="form-input-info positioning"
                      style={passwordValStyle}
                    >
                      {passwordValResult}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-12">
                <label className="label-control">Confirm Password* :</label>

                <div>
                  <input
                    type="password"
                    name="rePassword"
                    className="form-control "
                    value={rePassword}
                    style={repwdInptErrStyle}
                    onChange={(e) => onInputChange(e)}
                    autoComplete="new-password"
                    required
                  />
                  {repwdValChecker && (
                    <Fragment>
                      <span
                        className="form-input-info positioning"
                        style={repwdValStyle}
                      >
                        {repwdValResult}
                      </span>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>

            <div className="col-lg-12 Savebutton " size="lg">
              {userExistError && (
                <label className="label-control colorRed">
                  User Name Not Available
                </label>
              )}
              {loading ? (
                <button
                  className="btn sub_form btn_continue Save float-right"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <input
                  type="submit"
                  name="Submit"
                  value="Submit"
                  className="btn sub_form blackbrd btn_continue Save float-right"
                />
              )}
            </div>
          </form>
        </div>
      </>
    </Fragment>
  );
};

AddUser.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  AddUserDetails: PropTypes.func.isRequired,
  errorResponse: PropTypes.string,
  getActiveInstitutions: PropTypes.func.isRequired,
  getUserGroups: PropTypes.func.isRequired,
  checkForUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  user: state.user,
  errorResponse: state.auth.errorResponse,
});

export default connect(mapStateToProps, {
  AddUserDetails,
  getActiveInstitutions,
  getUserGroups,
  getAllBatchesDropdown,
  checkForUser,
})(AddUser);
