import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AddVarado } from "../../actions/area";
import Spinner from "../layout/Spinner";
const AddVaradoDetails = ({
  auth: { isAuthenticated, user, users, loading },
  AddVarado,
}) => {
  //formData
  const [formData, setFormData] = useState({
    varadoName: "",
    varadoCode: "",
    isSubmitted: false,
  });

  const { varadoName, varadoCode } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const finalData = {
      varadoName: varadoName,
      varadoCode: varadoCode,
      varadoEnteredById: user._id,
      institutionId: user.institutionId,
      userData: user,
    };
    AddVarado(finalData);
    setFormData({
      ...formData,
      varadoName: "",
      varadoCode: "",
      isSubmitted: true,
    });
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-6 col-sm-12 col-12">
            <div className="col-lg-10 col-md-6 col-sm-12 col-12">
              <label className="label-control"> Varado Name* :</label>
              <input
                type="text"
                name="varadoName"
                value={varadoName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>

            <div className="col-lg-10 col-md-6 col-sm-12 col-12 py-3">
              <label className="label-control">Varado Code* :</label>
              <input
                type="text"
                name="varadoCode"
                value={varadoCode}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>

          <div className="col-md-12 col-lg-10 col-sm-12 col-12 text-left">
            {loading ? (
              <button className="btn contact_reg" disabled>
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Save"
                value="Submit"
                className="btn sub_form btn_continue blackbrd float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

AddVaradoDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  AddVarado: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  AddVarado,
})(AddVaradoDetails);
