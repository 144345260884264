import React from "react";

const LoanRepaymentDetails = ({ loanRepaymentData, watchingDate }) => {
  return (
    <>
      <div className="body-inner no-padding table-responsive">
        <table
          className="tabllll table table-bordered table-striped table-hover mt-2"
          id="datatable2"
        >
          <thead>
            <tr>
              <th>Repayment Date</th>
              <th>Repayment Principal</th>
              <th>Repayment Interest</th>
            </tr>
          </thead>
          <tbody>
            {loanRepaymentData &&
              loanRepaymentData.map((ele) => {
                return (
                  <tr
                    style={
                      watchingDate === ele.paidDate
                        ? { backgroundColor: "#E5FFEF" }
                        : {}
                    }
                  >
                    <td>
                      {ele.paidDate
                        .split("-")
                        .reverse()
                        .join("-")}
                    </td>
                    <td>{ele.loanPrincipalPaid}</td>
                    <td>{ele.loanInterestPaid}</td>
                  </tr>
                );
              })}
            <tr>
              <td>Total</td>
              <td>
                {loanRepaymentData.reduce(
                  (acu, ele) => (acu += ele.loanPrincipalPaid),
                  0
                )}
              </td>
              <td>
                {loanRepaymentData.reduce(
                  (acu, ele) => (acu += ele.loanInterestPaid),
                  0
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LoanRepaymentDetails;
