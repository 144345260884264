import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getAllInstitutionDetails,
  getCountInstitutionDetails,
} from "../../actions/shg";
import { Link } from "react-router-dom";
import EditInstitutionDetails from "./EditInstitutionDetails";
import Spinner from "../layout/Spinner";
import DeactiveInstitutionDetails from "./DeactiveInstitutionDetails";
const AllInstitutionDetails = ({
  auth: { isAuthenticated, user },
  shg: { allInstitutions, countInstitutions },
  getAllInstitutionDetails,
  getCountInstitutionDetails,
}) => {
  useEffect(() => {
    getAllInstitutionDetails();
  }, [getAllInstitutionDetails]);
  useEffect(() => {
    getCountInstitutionDetails();
  }, [getCountInstitutionDetails]);

  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (institutionsdata, idx) => {
    setShowUpdateModal(true);
    setUserData(institutionsdata);
  };
  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const [userDatas, setUserDatas] = useState(null);
  const onUpdate = (allInstitutions, idx) => {
    setShowDeactiveModal(true);
    setUserData(allInstitutions);
  };
  return !isAuthenticated || !user ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">All Institution Details </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-11 col-md-11 col-sm-12 col-11 py-3">
              <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <Link to="/add-institution">
                <img
                  className="img_icon_size log float-right"
                  src={require("../../static/images/add-icon.png")}
                  alt="Add Institution"
                  title="Add Institution"
                />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className="body-inner no-padding table-responsive fixTableHead ">
                  <table
                    className="table table-bordered table-striped table-hover "
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        {/* <th className="headcolstatic">Institution Name</th> */}
                        <th >Institution Name</th>
                        <th>Institution Address</th>
                        <th>Institution InterestRate</th>
                        {/* <th>Institution Desc</th>
                        <th>Mission Statement</th>
                        <th>about Organization</th> */}
                        <th>Institution Type</th>
                        <th>Op</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allInstitutions &&
                        allInstitutions.map((allInstitutions, idx) => {
                          return (
                            <tr key={idx}>
                              {/* <td className="headcolstatic">
                                {allInstitutions.institutionName}
                              </td> */}
                              <td className="secondlinebreak" >
                         {allInstitutions.institutionName}
                              
                              </td>
                              <td style={{maxWidth:"450px",whiteSpace:"initial"}}>{allInstitutions.institutionAddress}</td>
                              <td>{allInstitutions.institutionInterestRate}</td>
                              {/* <td>{allInstitutions.institutionDesc}</td>
                              <td>{allInstitutions.missionStatement}</td>
                              <td>{allInstitutions.aboutOrganization}</td> */}
                              <td>{allInstitutions.institutionType}</td>
                              <td>
                                <>
                                  {allInstitutions.institutionStatus &&
                                  allInstitutions.institutionStatus !==
                                    "Deactive" ? (
                                    <img
                                      className="img_icon_size log"
                                      onClick={() =>
                                        onUpdate(allInstitutions, idx)
                                      }
                                      src={require("../../static/images/delete.png")}
                                      alt="Deactivate Institution"
                                      title="Deactivate Institution"
                                    />
                                  ) : (
                                    <Fragment></Fragment>
                                  )}
                                  &nbsp;
                                  <img
                                    className="img_icon_size log"
                                    onClick={() => onEdit(allInstitutions, idx)}
                                    src={require("../../static/images/edit_icon.png")}
                                    alt="Edit Institution"
                                    title="Edit Institution"
                                  />
                                </>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </section>
              <Modal
                show={showUpdateModal}
                backdrop="static"
                keyboard={false}
                size="xl"
                className="ml-5"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <div className="col-lg-10 col-md-10 col-sm-10 col-10">
                    <h3 className="modal-title text-center">
                      Edit Institution Details{" "}
                    </h3>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                    <button onClick={handleUpdateModalClose} className="close">
                      <img
                        src={require("../../static/images/close.png")}
                        alt="X"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <EditInstitutionDetails
                    institutionsdata={userData}
                    onUpdateModalChange={onUpdateModalChange}
                  />
                </Modal.Body>
              </Modal>
              <Modal
                show={showDeactiveModal}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <div className="col-lg-10">
                    <h3 className="modal-title text-center">
                      Deactivate Institution{" "}
                    </h3>
                  </div>
                  <div className="col-lg-2">
                    <button
                      onClick={handleDeactiveModalClose}
                      className="close"
                    >
                      <img
                        src={require("../../static/images/close.png")}
                        alt="X"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <DeactiveInstitutionDetails
                    allInstitutions={userData}
                    onDeactiveModalChange={onDeactiveModalChange}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-6 col-sm-12 col-12 align_right">
              <label className="float-right ml-3">
                No. of Institutions : {allInstitutions.length}
              </label>
              <label>No. of Clients : {countInstitutions.length}</label>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

AllInstitutionDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getAllInstitutionDetails: PropTypes.func.isRequired,
  getCountInstitutionDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getAllInstitutionDetails,
  getCountInstitutionDetails,
})(AllInstitutionDetails);
