import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  getParticularLoanReason,
  editMemberTransaction,
  AddLoanSanctionedDetails,
  EditLoanSanctionedDetails,
  getSeletedBatchDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  deleteOtherLoan,
  getExistingMemberOLData,
  getExistingMemberILData,
  addNewOtherLoanDetails,
  getMembersMeetingData,
  getSanctionedData,
  getMemberPreveiousMeetingData,
  getMemberPrevMeetingData,
  deleteBankLoanDetail,
  getCurMemMettingLoanSanc,
} from "../../actions/shg";
import Spinner from "../layout/Spinner";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LoanPayment from "../layout/LoanPayment";
import ShgInternalLoan from "../layout/ShgInternalLoan";
import EditCurLoan from "../layout/EditCurLoan";
import LoanRepaymentDetails from "../layout/LoanRepaymentDetails";

const EditMembermeetingDetailModal = ({
  auth: { isAuthenticated, user, users, loading },
  shg: {
    loanreason,
    prevData,
    activeOtherLoanTypes,
    exstMemOLData,
    exstMemILData,
    isloansanctioned,
    membersPreviousMeeting,
    curMemMettingLoanSanc,
    activeBankData,
  },
  getCurMemMettingLoanSanc,
  membersBatch,
  selectedDate,
  onMemberMeetingModalChanges,
  userData2,
  getMemberPreveiousMeetingData,
  getSanctionedData,
  getParticularLoanReason,
  editMemberTransaction,
  getActiveOtherLoanType,
  deleteOtherLoan,
  getExistingMemberOLData,
  getExistingMemberILData,
  deleteBankLoanDetail,
  getMemberPrevMeetingData,
}) => {
  let selectedBatchData = JSON.parse(localStorage.getItem("selBatchData"));
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  let memberEditData = JSON.parse(localStorage.getItem("memberEditData"));
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  useEffect(() => {}, [exstMemILData]);
  const meetingBatchInfo = {
    mdDate: userData2 && userData2.mdDate,
    mdBatchId: userData2 && userData2.mdBatchId,
    memberId: userData2 && userData2.memberId,
  };

  useEffect(() => {
    const reqData = {
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: membersBatch && membersBatch._id,
    };

    getMemberPreveiousMeetingData(reqData);
  }, [getMemberPreveiousMeetingData]);

  useEffect(() => {
    const reqData = {
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: userData2 && userData2.memberId,
    };
    getMemberPrevMeetingData(reqData);
  }, [getMemberPrevMeetingData]);

  useEffect(() => {
    getSanctionedData({
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: userData2 && userData2.memberId,
      institutionId: user && user.institutionId,
    });
  }, [getSanctionedData]);

  ///New///
  useEffect(() => {
    getActiveBankData(batchIdValue);
  }, [getActiveBankData]);

  ///other loan hide fields for already taken loan members

  let onlyID =
    isloansanctioned && isloansanctioned.map((ele) => ele.otherLoanTypeId);

  let alreadyLoanAvaiable =
    (activeOtherLoanTypes &&
      activeOtherLoanTypes
        .map((ele) => onlyID.includes(ele._id))
        .some((ele) => ele == true)) ||
    false;

  useEffect(() => {
    getParticularLoanReason();
  }, [getParticularLoanReason]);
  useEffect(() => {
    const reqData = {
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: userData2 && userData2.memberId,
    };
    getMemberPreveiousMeetingData(reqData);
  }, [getMemberPreveiousMeetingData]);

  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);

  useEffect(() => {
    getExistingMemberOLData(meetingBatchInfo);
  }, [getExistingMemberOLData]);

  useEffect(() => {
    getExistingMemberILData(meetingBatchInfo);
    getCurMemMettingLoanSanc({
      meetingBatchInfo,
    });
  }, [getExistingMemberILData]);

  // curMettingLoanSanc
  useEffect(() => {}, [curMemMettingLoanSanc]);
  //Has Repayment

  const handleOnChange = () => {
    setFormData({
      ...formData,
      loanChecker: !loanChecker,
    });
  };

  //Has Other Loan more than 2
  const [otherLoanChecker2, setOtherLoanChecker2] = useState(false);

  const handleOnOtherChange2 = () => {
    setOtherLoanChecker2(!otherLoanChecker2);
  };

  //Has Other Loan only 1s
  const [otherLoanChecker1, setOtherLoanChecker1] = useState(false);
  const handleOnOtherChange1 = () => {
    setFormData({
      ...formData,
      otherLoanPaid: "",
      otherInterestPaid: "",
      TotalOtherloanPaid: "",
      otherPenalty: "",

      OTHloanChecker: !OTHloanChecker,
    });
  };

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);

  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  let OtherLoanNames = [];
  isloansanctioned &&
    isloansanctioned.map((ele) => {
      OtherLoanNames.push({
        label: ele.otherLoanType,
        value: ele.otherLoanType,
      });
    });
  //this is for hiding the internal loan section

  //remaining loan data start
  let finalArray = membersPreviousMeeting.map((ele) => ele.otherLoan).flat();
  const [othRemaingLoan, setOthRemaingLoan] = useState(0); //othRemaingLoan(value in remaing laon)
  let reLoan = 0;

  // code for next previous tabing ends

  const [formData, setFormData] = useState({
    savingAmt: "",
    meetingHeldOnDate: selectedDate,
    batchSavingAmt: "",
    batchSubscriptionAmt: "",
    batchId: selectedBatchData[0] && selectedBatchData.batchId,
    institutionId: membersBatch && membersBatch.institutionId,
    otherContribution: "",
    loanTakenAmt: "",
    internalNominee: "",
    loanPaid: "",
    interestPaid: "",
    otherPurpose: "",
    loanReasonId: "",
    otherloanSanctionedAmount: "",
    otherLoanInterest: "",
    otherLoanPaid: "",
    otherInterestPaid: "",
    otherLoanReasonId: "",
    otherLoanOtherPurpose: "",
    indSubsidyDistributed: "",
    membershipFees: "",
    otherIncome: "",
    memSeedMoney: "",
    memSecurityDepositToShg: "",
    savingWithdrawals: "",
    dividendDistributed: "",
    securityDeposit: "",
    isSubmitted: false,
    batchIdTemp: null,
    attendance: "",

    //internal loan
    loanPayablePrincipal: "",
    loanPayableInterest: "",
    internalDuration: "",
    PrincipalPaid: "",
    InterestPaid: "",
    loanChecker: false,
    OTHloanChecker: false,
    //other loan
    otherloanPayablePrincipalAmt: "",
    otherloanPayableInterstAmt: "",
    otherloanSanctionedAmt: "",
    othDuration: "",
    internalPenalty: "",
    otherPenalty: "",
  });

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const [showHideBank, setbankShowHide] = useState({
    showbankotherPurposeSection: false,
  });
  const { showbankotherPurposeSection } = showHideBank;

  const [otherLoanType, getOtherLoanTypeData] = useState("");
  const [otherLoanTypeId, setOtherLoanTypeId] = useState("");
  const [otherLoanTypeName, setOtherLoanTypeName] = useState("");

  const [loanReasonId, setloanPurposeID] = useState();
  const [purpose, getloanPurposeData] = useState();
  const [loanPurpose, setloanPurposeName] = useState();

  const [otherloanpurposes, getbankpurposeData] = useState();
  const [bankloanpuroseId, setbankloanpuroseId] = useState();
  const [bankloanPurpose, setbankloanPurpose] = useState();

  const {
    otherloanpurpose,
    otherLoanReasons,
    otherLoanPaid,
    otherInterestPaid,
    // otherLoanInterest,
    meetingHeldOnDate,
    batchId,
    memberId,
    memberName,
    savingAmt,
    batchSavingAmt,
    batchSubscriptionAmt,
    loanPaid,
    interestPaid,
    subscriptionAmt,
    otherContribution,
    loanTakenAmt,
    internalNominee,
    otherPurpose,
    otherloanSanctionedAmount,
    otherLoanReason,
    indSubsidyDistributed,
    externalNominee,
    membershipFees,
    otherIncome,
    memSeedMoney,
    memSecurityDepositToShg,
    savingWithdrawals,
    dividendDistributed,
    securityDeposit,
    institutionId,
    batchIdTemp,
    //internal loan
    loanPayablePrincipal,
    loanPayableInterest,
    internalDuration,
    PrincipalPaid,
    InterestPaid,
    //other loan
    otherloanPayablePrincipalAmt,
    otherloanPayableInterstAmt,
    othDuration,
    attendance,
    loanChecker,
    internalPenalty,
    OTHloanChecker,
    otherPenalty,
  } = formData;

  var TotalPayableAmt =
    Number(loanPayableInterest) + Number(loanPayablePrincipal);

  var totalLoanPaid = Number(PrincipalPaid) + Number(InterestPaid);

  let othloanPayablePrincipalAmt = Number(otherloanPayablePrincipalAmt)
    ? Number(otherloanPayablePrincipalAmt)
    : 0;
  let othloanPayableInterstAmt = Number(otherloanPayableInterstAmt)
    ? Number(otherloanPayableInterstAmt)
    : 0;
  var otherloanSanctionedAmt =
    othloanPayablePrincipalAmt + othloanPayableInterstAmt;
  var TotalOtherloanPaid = Number(otherLoanPaid) + Number(otherInterestPaid);
  if (memberEditData && !batchIdTemp) {
    let OLTlen = activeOtherLoanTypes && activeOtherLoanTypes.length;
    setFormData({
      ...formData,
      memberName: memberEditData && memberEditData.memberName,
      batchSavingAmt: memberEditData && memberEditData.savingAmt,
      batchSubscriptionAmt: memberEditData && memberEditData.subscriptionAmt,
      otherContribution: memberEditData && memberEditData.otherContribution,

      internalNominee: memberEditData && memberEditData.internalNominee,

      otherPurpose: memberEditData && memberEditData.otherPurpose,
      loanReasonId: memberEditData && memberEditData.loanReasonId,
      internalPenalty: memberEditData && memberEditData.internalPenalty,

      otherLoanPaid:
        OLTlen > 1
          ? ""
          : memberEditData &&
            memberEditData.otherLoan &&
            memberEditData.otherLoan[0] &&
            memberEditData.otherLoan[0].otherLoanPaid,
      otherInterestPaid:
        OLTlen > 1
          ? ""
          : memberEditData &&
            memberEditData.otherLoan &&
            memberEditData.otherLoan[0] &&
            memberEditData.otherLoan[0].otherInterestPaid,

      TotalOtherloanPaid:
        OLTlen > 1
          ? ""
          : memberEditData &&
            memberEditData.otherLoan &&
            memberEditData.otherLoan[0] &&
            memberEditData.otherLoan[0].otherTotalPaid,

      otherLoanReasonId: memberEditData && memberEditData.otherLoanReasonId,
      otherLoanReasons: memberEditData && memberEditData.otherLoanOtherPurpose,
      indSubsidyDistributed:
        OLTlen > 1
          ? ""
          : memberEditData &&
            memberEditData.otherLoan &&
            memberEditData.otherLoan[0] &&
            memberEditData.otherLoan[0].indSubsidyDistributed,
      externalNominee: memberEditData && memberEditData.externalNominee,
      membershipFees: memberEditData && memberEditData.membershipFees,
      otherIncome: memberEditData && memberEditData.otherIncome,
      memSeedMoney: memberEditData && memberEditData.memSeedMoney,
      memSecurityDepositToShg:
        memberEditData && memberEditData.memSecurityDepositToShg,
      savingWithdrawals: memberEditData && memberEditData.savingWithdrawals,
      dividendDistributed: memberEditData && memberEditData.dividendDistributed,
      securityDeposit: memberEditData && memberEditData.securityDeposit,
      batchIdTemp: memberEditData && memberEditData.batchId,
      attendance: memberEditData && memberEditData.attendance,
      //internal loan
      loanPayablePrincipal:
        memberEditData && memberEditData.loanPayablePrincipal,
      loanPayableInterest: memberEditData && memberEditData.loanPayableInterest,
      internalDuration: memberEditData && memberEditData.internalDuration,
      PrincipalPaid: memberEditData && memberEditData.loanPaid,
      InterestPaid: memberEditData && memberEditData.interestPaid,
      TotalPayableAmt: memberEditData && memberEditData.loanTakenAmt,
      totalLoanPaid: memberEditData && memberEditData.totalLoanPaid,

      //other loan
      otherloanPayablePrincipalAmt:
        OLTlen > 1
          ? ""
          : memberEditData &&
            memberEditData.otherLoan &&
            memberEditData.otherLoan[0] &&
            memberEditData.otherLoan[0].otherloanPayablePrincipal,
      // otherloanPayablePrincipalAmt:memberEditData && memberEditData.otherloanPayablePrincipalAmt,
      otherloanPayableInterstAmt:
        OLTlen > 1
          ? ""
          : memberEditData &&
            memberEditData.otherLoan[0] &&
            memberEditData.otherLoan[0].otherloanPayableInterst,
      // otherloanSanctionedAmt:memberEditData && memberEditData.otherLoanTakenAmt,
      otherloanSanctionedAmt:
        OLTlen > 1
          ? ""
          : memberEditData &&
            memberEditData.otherLoan &&
            memberEditData.otherLoan[0] &&
            memberEditData.otherLoan[0].otherLoanTakenAmt,
      othDuration:
        OLTlen > 1
          ? ""
          : memberEditData &&
            memberEditData.otherLoan &&
            memberEditData.otherLoan[0] &&
            memberEditData.otherLoan[0].otherLoanDuration,

      otherPenalty:
        OLTlen > 1
          ? ""
          : memberEditData &&
            memberEditData.otherLoan &&
            memberEditData.otherLoan[0] &&
            memberEditData.otherLoan[0].otherPenalty,

      attendance: memberEditData && memberEditData.attendance,
      loanChecker:
        prevData[0] && Number(prevData[0].remainingLoan) ? true : false,
      OTHloanChecker:
        OLTlen > 1
          ? ""
          : memberEditData &&
            memberEditData.otherLoan &&
            memberEditData.otherLoan[0] &&
            memberEditData.otherLoan[0].otherLoanChecker,
    });

    setloanPurposeID(memberEditData && memberEditData.loanReasonId);
    setloanPurposeName(memberEditData && memberEditData.loanPurpose);

    setbankloanpuroseId(memberEditData && memberEditData.otherLoanReasonId);
    setbankloanPurpose(memberEditData && memberEditData.otherLoanOtherPurpose);
  }
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //attendance
  // const handlePresent = () => {
  //   setFormData({
  //     ...formData,
  //     attendance: !attendance,
  //   });
  //   //setisPresent(!isPresent);
  // };

  const onGridInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //to hide Has Repayment in internal Loan and open only if loanSancationedamt>0
  const [hideFieldInternal, sethideFieldInternal] = useState("");

  useEffect(() => {
    if (TotalPayableAmt > 0) {
      sethideFieldInternal("true");
      setFormData({
        ...formData,
        loanChecker: false,
      });
    } else {
      sethideFieldInternal("false");
      setFormData({
        ...formData,
        PrincipalPaid: "",
        InterestPaid: "",
        totalLoanPaid: "",
        internalPenalty: "",

        loanChecker: false,
      });
    }
    //for checker showing tick if already amount present need testing
    if (memberEditData && memberEditData.totalLoanPaid > 0) {
      setFormData({
        ...formData,
        loanChecker: true,
      });
    }
  }, [TotalPayableAmt]);
  //////////////////////////////////////////
  /////////////////////////////////////other loan for 1
  //to hide Has Repayment in 1 other Loan and open only if loanSancationedamt>0
  const [hideFieldOther, sethideFieldOther] = useState("");
  useEffect(() => {
    if (otherloanSanctionedAmt > 0) {
      sethideFieldOther("true");
      setFormData({
        ...formData,
        OTHloanChecker: false,
      });
    } else {
      sethideFieldOther("false");
      setFormData({
        ...formData,
        otherLoanPaid: "",
        otherInterestPaid: "",
        TotalOtherloanPaid: "",
        otherPenalty: "",

        OTHloanChecker: false,
      });
    }

    if (
      memberEditData &&
      memberEditData.otherLoan &&
      memberEditData.otherLoan[0] &&
      memberEditData.otherLoan[0].otherTotalPaid > 0
    ) {
      setFormData({
        ...formData,
        OTHloanChecker: true,
      });
    }
  }, [otherloanSanctionedAmt]);
  ///internal loan & other loan fields for already taken loan members and display data
  let Internalonly = [];
  let Otheronly = [];
  isloansanctioned &&
    isloansanctioned.map((ele) => {
      if (ele.isLoanType === "Internal") {
        Internalonly.push(ele);
      } else {
        Otheronly.push(ele);
      }
    });

  const [isOtherLoan, setisOtherLoan] = useState("");
  let sampledata1;
  useEffect(() => {
    Otheronly && Otheronly.length === 0
      ? (sampledata1 = "true")
      : Otheronly &&
        Otheronly.map((ele) => {
          if (
            ele.isLoanType === "otherLoan" &&
            ele.meetingHeldOnDate === userData2.mdDate
          ) {
            sampledata1 = "true";
          } else {
            sampledata1 = "false";
          }
        });
    setisOtherLoan(sampledata1);
  }, [tabIndex]);

  //this is for hiding the internal loan section
  const [isInternalLoan, setisInternalLoan] = useState("true");
  let sampledata;
  useEffect(() => {
    Internalonly && Internalonly.length === 0
      ? (sampledata = "true")
      : Internalonly &&
        Internalonly.map((ele) => {
          if (
            ele.isLoanType === "Internal" &&
            ele.meetingHeldOnDate === userData2.mdDate
          ) {
            sampledata = "true";
          } else {
            sampledata = "false";
          }
        });
    setisInternalLoan(sampledata);
  }, [tabIndex]);

  //ends here

  const [showHide, setShowHide] = useState({
    showotherPurposeSection: false,
  });
  const { showotherPurposeSection } = showHide;

  const onLoanPurposeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        loanPurpose: e,
      });
    }
    if (e.value === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
    }
  };
  const allpurpose = [];
  loanreason.map((purpose) =>
    allpurpose.push({
      loanReasonId: purpose._id,
      label: purpose.loanPurpose,
      value: purpose.loanPurpose,
    })
  );

  if (memberEditData && memberEditData.loanReasonId && !purpose) {
    getloanPurposeData(
      allpurpose && memberEditData
        ? allpurpose &&
            allpurpose.filter(
              (x) => x.loanReasonId === memberEditData.loanReasonId
            )[0]
        : ""
    );
    if (memberEditData.loanPurpose === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
    }
  }

  const onPurposeChange = (e) => {
    getloanPurposeData(e);
    setloanPurposeID(e.loanReasonId ? e.loanReasonId : "");
    setloanPurposeName(e.value ? e.value : "");

    if (e) {
      setFormData({
        ...formData,
        loanPurpose: e,
      });
    }
    if (e.value === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
      setFormData({
        ...formData,
        otherPurpose: "",
      });
    }
  };

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  const [error, setError] = useState({
    otherLoanTypeIdChecker: false,
    otherLoanTypeIdErrorStyle: {},
    bankIdChecker: false,
    bankIdErrorStyle: {},
  });
  const {
    otherLoanTypeIdChecker,
    otherLoanTypeIdErrorStyle,
    bankIdChecker,
    bankIdErrorStyle,
  } = error;
  //validation for select
  const [errors, setErrors] = useState({
    otherLoanTypesChecker: false,
    otherLoanTypesErrorStyle: {},
  });
  const { otherLoanTypesChecker, otherLoanTypesErrorStyle } = errors;

  const checkError = () => {
    if (!otherLoanTypesChecker) {
      setErrors({
        ...errors,
        otherLoanTypesErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };

  //this is for dropdown of otherloan type
  const [dataAvaiable, setdataAvaiable] = useState("");

  const onotherLoanTypeChange = (e) => {
    let reLoan = 0;
    finalArray &&
      finalArray.map((other) => {
        if (other.otherLoanTypeId === e.otherLoanTypeId) {
          reLoan =
            Number(other.otherLoanTakenAmt) - Number(other.otherLoanPaid);
        }
      });
    setOthRemaingLoan(reLoan);
    setErrors({
      ...errors,
      otherLoanTypesChecker: true,
      otherLoanTypesErrorStyle: { color: "#000" },
    });
    //Required Validation starts

    setError({
      ...error,
      otherLoanTypeIdChecker: true,
      otherLoanTypeIdErrorStyle: { color: "#000" },
    });
    let othRemLoan = 0;

    isloansanctioned &&
      isloansanctioned.map((other) => {
        if (other.otherLoanTypeId === e.otherLoanTypeId) {
          othRemLoan =
            Number(other.loanPayablePrincipal) - Number(other.loanCurPrincipal);
        }
      });

    setOthRemaingLoan(othRemLoan);

    const isalreadyAvaiable =
      isloansanctioned &&
      isloansanctioned.filter(
        (ele) => ele.otherLoanTypeId === e.otherLoanTypeId
      );

    if (
      (isalreadyAvaiable &&
        isalreadyAvaiable[0] &&
        isalreadyAvaiable[0].meetingHeldOnDate === userData2.mdDate) ||
      isalreadyAvaiable.length === 0
    ) {
      sethideFieldOther("false");
      setdataAvaiable("false");
    } else if (
      isalreadyAvaiable &&
      isalreadyAvaiable[0] &&
      isalreadyAvaiable[0].meetingHeldOnDate !== userData2.mdDate
    ) {
      sethideFieldOther("true");
      setdataAvaiable("true");
    } else {
      sethideFieldOther("false");
      setdataAvaiable("false");
    }

    //Required Validation ends
    getOtherLoanTypeData(e);
    setOtherLoanTypeId(e.otherLoanTypeId ? e.otherLoanTypeId : "");
    setOtherLoanTypeName(e.value ? e.value : "");
  };
  const allbankpurpose = [];
  loanreason.map((otherloanpurposes) =>
    allbankpurpose.push({
      bankloanpuroseId: otherloanpurposes._id,
      label: otherloanpurposes.loanPurpose,
      value: otherloanpurposes.loanPurpose,
    })
  );

  if (
    memberEditData &&
    memberEditData.otherLoanReasonId &&
    !otherloanpurposes
  ) {
    getbankpurposeData(
      allbankpurpose && memberEditData
        ? allbankpurpose &&
            allbankpurpose.filter(
              (x) => x.bankloanpuroseId === memberEditData.otherLoanReasonId
            )[0]
        : ""
    );
    if (memberEditData.otherloanpurpose === "Other") {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: true,
      });
    } else {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: false,
      });
    }
  }

  const handlePresent = () => {
    setFormData({
      ...formData,
      attendance: !attendance,
    });
    //setisPresent(!isPresent);
  };

  const onBankLoanPurposeChange = (e) => {
    getbankpurposeData(e);
    setbankloanpuroseId(e.bankloanpuroseId ? e.bankloanpuroseId : "");
    setbankloanPurpose(e.value ? e.value : "");

    if (e) {
      setFormData({
        ...formData,
        bankloanPurpose: e,
      });
    }
    if (e.value === "Other") {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: true,
      });
    } else {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: false,
      });
      setFormData({
        ...formData,
        otherLoanReasons: "",
      });
    }
  };

  //OLR GRID START==============================================================================
  const [DurationError, setDurationError] = useState({
    color: "black",
  });
  const [AddedDetailsOLR, AddDetailsOLR] = useState([]);
  const onAddOLR = (e) => {
    e.preventDefault();
    if (otherloanSanctionedAmt > 0 && othDuration <= 0) {
      setDurationError({ color: "red" });
    } else {
      setDurationError({ color: "black" });
      if (checkError()) {
        if (otherLoanType) {
          const listOLR = AddedDetailsOLR.filter(
            (AddDetails) =>
              AddDetails.otherLoanTypeId === otherLoanType.otherLoanTypeId
          );
          const listOLREx =
            exstMemOLData &&
            exstMemOLData.otherLoan.filter(
              (exOLR) => exOLR.otherLoanTypeId === otherLoanType.otherLoanTypeId
            );
          if (listOLR.length === 0 && listOLREx.length === 0) {
            const addData = {
              recordId: memberEditData && memberEditData._id,
              otherLoanType: otherLoanType.value,
              otherLoanTypeId: otherLoanType.otherLoanTypeId,
              otherLoanPaid: otherLoanPaid
                ? parseFloat(Number(otherLoanPaid).toFixed(2))
                : 0,
              otherInterestPaid: otherInterestPaid
                ? parseFloat(Number(otherInterestPaid).toFixed(2))
                : 0,
              otherPenalty: parseFloat(Number(otherPenalty))
                ? parseFloat(Number(otherPenalty))
                : 0,

              otherLoanTakenAmt: parseFloat(Number(otherloanSanctionedAmt)),
              //
              otherloanPayablePrincipal: otherloanPayablePrincipalAmt
                ? parseFloat(Number(otherloanPayablePrincipalAmt).toFixed(2))
                : 0,
              otherloanPayableInterst: otherloanPayableInterstAmt
                ? parseFloat(Number(otherloanPayableInterstAmt).toFixed(2))
                : 0,
              otherLoanDuration: othDuration
                ? parseFloat(Number(othDuration))
                : 0,
              otherLoanReasonId: otherloanpurposes
                ? otherloanpurposes.bankloanpuroseId
                : null,
              otherloanpurpose: otherloanpurposes
                ? otherloanpurposes.value
                : "",
              otherLoanOtherPurpose: otherLoanReasons,
              indSubsidyDistributed: indSubsidyDistributed
                ? parseFloat(Number(indSubsidyDistributed).toFixed(2))
                : 0,
              externalNominee: externalNominee || "",
              otherLoanChecker: OTHloanChecker,
              otherTotalPaid: parseFloat(Number(TotalOtherloanPaid))
                ? parseFloat(Number(TotalOtherloanPaid).toFixed(2))
                : 0,
            };
            setFormData({
              ...formData,
              otherLoanType: "",
              otherLoanTypeId: "",
              otherLoanPaid: "",
              otherInterestPaid: "",
              otherloanSanctionedAmount: "",
              otherloanPayablePrincipalAmt: "",
              otherloanPayableInterstAmt: "",
              othDuration: "",
              bankloanpuroseId: "",
              otherPenalty: "",
              otherloanpurposes: "",
              allbankpurpose: "",
              otherLoanReasons: "",
              indSubsidyDistributed: "",
              externalNominee: "",
            });
            let temp = [];
            temp.push(...AddedDetailsOLR, addData);
            AddDetailsOLR(temp);

            setOtherLoanChecker1(!otherLoanChecker1);
            getOtherLoanTypeData("");
            getbankpurposeData("");
          }
        }
        return true;
      }
    }
  };

  const onAddOLRSingle = (e) => {
    e.preventDefault();
    const addData = {
      recordId: memberEditData && memberEditData._id,
      otherLoanType: activeOtherLoan[0] ? activeOtherLoan[0].value : "",
      otherLoanTypeId: activeOtherLoan[0]
        ? activeOtherLoan[0].otherLoanTypeId
        : null,
      otherLoanPaid: otherLoanPaid
        ? parseFloat(Number(otherLoanPaid).toFixed(2))
        : 0,
      otherInterestPaid: otherInterestPaid
        ? parseFloat(Number(otherInterestPaid).toFixed(2))
        : 0,
      otherLoanTakenAmt: otherloanSanctionedAmount
        ? parseFloat(Number(otherloanSanctionedAmount).toFixed(2))
        : 0,
      //
      otherPenalty: parseFloat(Number(otherPenalty))
        ? parseFloat(Number(otherPenalty))
        : 0,
      otherloanPayablePrincipal: otherloanPayablePrincipalAmt
        ? parseFloat(Number(otherloanPayablePrincipalAmt).toFixed(2))
        : 0,
      otherloanPayableInterst: otherloanPayableInterstAmt
        ? parseFloat(Number(otherloanPayableInterstAmt).toFixed(2))
        : 0,
      otherLoanDuration: othDuration ? parseFloat(Number(othDuration)) : 0,

      otherLoanTakenAmt: parseFloat(Number(otherloanSanctionedAmt)),
      otherLoanChecker: OTHloanChecker,
      otherLoanReasonId: otherloanpurposes
        ? otherloanpurposes.bankloanpuroseId
        : null,
      otherloanpurpose: otherloanpurposes ? otherloanpurposes.value : "",
      otherLoanOtherPurpose: otherLoanReasons || "",
      indSubsidyDistributed: indSubsidyDistributed
        ? parseFloat(Number(indSubsidyDistributed).toFixed(2))
        : 0,
      externalNominee: externalNominee,
      otherTotalPaid: parseFloat(Number(TotalOtherloanPaid))
        ? parseFloat(Number(TotalOtherloanPaid))
        : 0,
    };

    let temp = [];
    temp.push(addData);
    setOtherLoanChecker1(!otherLoanChecker1);

    AddDetailsOLR(temp);
    return temp;
  };

  const onRemoveChangeOLR = (otherLoanTypeId) => {
    const removeList = AddedDetailsOLR.filter(
      (AddDetails) => AddDetails.otherLoanTypeId !== otherLoanTypeId
    );
    AddDetailsOLR(removeList);
  };

  //OLR GRID END================================================================================

  //For setting mindate as todays date

  const onDelete = (memberData) => {
    let otherLoanTakenAmt = 0;
    let otherLoanPaid = 0;
    let otherInterestPaid = 0;
    otherLoanTakenAmt =
      memberEditData.otherLoanTakenAmt - memberData.otherLoanTakenAmt;
    otherLoanPaid = memberEditData.otherLoanPaid - memberData.otherLoanPaid;
    otherInterestPaid =
      memberEditData.otherInterestPaid - memberData.otherInterestPaid;

    deleteOtherLoan({
      otherLoanId: memberData._id,
      transactionId: memberEditData._id,
      otherLoanTypeId: memberData.otherLoanTypeId,
      mdDate: userData2 && userData2.mdDate,
      mdBatchId: userData2 && userData2.mdBatchId,
      memberId: userData2 && userData2.memberId,
      otherLoanTypeId: memberData.otherLoanTypeId,
      otherLoanTakenAmt: otherLoanTakenAmt,
      otherLoanPaid: otherLoanPaid,
      otherInterestPaid: otherInterestPaid,
    });
  };

  const onUpdate = (e) => {
    var dateVal = new Date(meetingHeldOnDate);
    e.preventDefault();
    let OLRes = [];
    if (activeOtherLoan.length <= 1) {
      OLRes = onAddOLRSingle(e);
    } else {
      OLRes = AddedDetailsOLR;
    }

    let otherLoanSanctionedToMemberSum = 0,
      otherLoanPaidToShgSum = 0,
      otherInterestPaidSum = 0,
      otherTotalPaidSum = 0,
      batchOtherLoanInterestShgSum = 0,
      otherloanPayablePrincipalAmtSum = 0,
      otherloanPayableInterstAmtSum = 0,
      indSubsidyDistributedSum = 0;

    if (activeOtherLoan.length > 1) {
      exstMemOLData &&
        exstMemOLData.otherLoan &&
        exstMemOLData.otherLoan.map((AddDetail, idx) => {
          otherLoanSanctionedToMemberSum += parseFloat(
            AddDetail.otherLoanTakenAmt || 0
          );
          otherloanPayablePrincipalAmtSum += parseFloat(
            AddDetail.otherloanPayablePrincipalAmt || 0
          );

          otherloanPayableInterstAmtSum += parseFloat(
            AddDetail.otherloanPayableInterstAmt || 0
          );

          otherLoanPaidToShgSum += parseFloat(AddDetail.otherLoanPaid || 0);

          otherInterestPaidSum += parseFloat(AddDetail.otherInterestPaid || 0);
          otherTotalPaidSum += parseFloat(AddDetail.otherTotalPaid || 0);

          indSubsidyDistributedSum += parseFloat(
            AddDetail.indSubsidyDistributed || 0
          );
        });
    }

    OLRes.map((AddDetail, idx) => {
      otherLoanSanctionedToMemberSum += parseFloat(
        AddDetail.otherLoanTakenAmt || 0
      );
      otherloanPayablePrincipalAmtSum += parseFloat(
        AddDetail.otherloanPayablePrincipalAmt || 0
      );

      otherloanPayableInterstAmtSum += parseFloat(
        AddDetail.otherloanPayableInterstAmt || 0
      );

      otherLoanPaidToShgSum += parseFloat(AddDetail.otherLoanPaid || 0);
      otherInterestPaidSum += parseFloat(AddDetail.otherInterestPaid || 0);
      otherTotalPaidSum += parseFloat(AddDetail.otherTotalPaid || 0);

      indSubsidyDistributedSum += parseFloat(
        AddDetail.indSubsidyDistributed || 0
      );
    });

    //////////////////////////////////////////////////////////
    const finalData = {
      //tab 1
      savingAmt: batchSavingAmt && batchSavingAmt ? batchSavingAmt : 0,
      subscriptionAmt:
        batchSubscriptionAmt && batchSubscriptionAmt ? batchSubscriptionAmt : 0,
      otherContribution:
        otherContribution && otherContribution ? otherContribution : 0,
      memSecurityDepositToShg:
        memSecurityDepositToShg && memSecurityDepositToShg
          ? memSecurityDepositToShg
          : 0,
      attendance: attendance,
      membershipFees: membershipFees && membershipFees ? membershipFees : 0,
      otherIncome: otherIncome && otherIncome ? otherIncome : 0,
      memSeedMoney: memSeedMoney && memSeedMoney ? memSeedMoney : 0,

      //tab 2
      savingWithdrawals:
        savingWithdrawals && savingWithdrawals ? savingWithdrawals : 0,
      dividendDistributed:
        dividendDistributed && dividendDistributed ? dividendDistributed : 0,
      securityDeposit: securityDeposit && securityDeposit ? securityDeposit : 0,

      //tab 3
      internalLoan: NewInternalLoan,
      internalLoanPayment: paymentArray,
      //INT + PRI
      loanTakenAmt:
        NewInternalLoan.reduce(
          (acu, cur) => (acu += Number(cur.loanPayablePrincipal)),
          0
        ) +
        NewInternalLoan.reduce(
          (acu, cur) => (acu += Number(cur.loanPayableInterest)),
          0
        ),
      //loan PAid
      loanPaid: paymentArray.reduce(
        (acu, cur) => (acu += Number(cur.internalPrincipalPaid)),
        0
      ),
      interestPaid: paymentArray.reduce(
        (acu, cur) => (acu += Number(cur.internalIntrestPaid)),
        0
      ),

      loanSanctionedEnteredById: user && user._id ? user._id : "",
      loanSanctionedEnteredByName: user && user.userName ? user.userName : "",

      //tab 4
      otherLoan: OLRes,
      otherLoanReasonId:
        bankloanpuroseId && bankloanpuroseId ? bankloanpuroseId : null,
      otherloanpurpose:
        otherloanpurposes && otherloanpurposes.value
          ? otherloanpurposes.value
          : "",
      otherLoanOtherPurpose: otherLoanReasons ? otherLoanReasons : "",
      externalNominee:
        externalNominee && externalNominee ? externalNominee : "",
      otherLoanPaid: otherLoanPaidToShgSum
        ? parseFloat(Number(otherLoanPaidToShgSum).toFixed(2))
        : 0, //sum
      otherInterestPaid: otherInterestPaidSum
        ? parseFloat(Number(otherInterestPaidSum).toFixed(2))
        : 0, //sum
      otherTotalPaid: otherTotalPaidSum
        ? parseFloat(Number(otherTotalPaidSum).toFixed(2))
        : 0, //sum

      otherLoanTakenAmt: otherLoanSanctionedToMemberSum
        ? parseFloat(Number(otherLoanSanctionedToMemberSum).toFixed(2))
        : 0,
      recordId: memberEditData._id,
      memberEditData: memberEditData,
      /////////
      //loan sancationed
      batchId:
        selectedBatchData[0] && selectedBatchData[0]._id
          ? selectedBatchData[0]._id
          : "",
      memberId: userData2 && userData2.memberId ? userData2.memberId : "",
      memberName:
        membersBatch && membersBatch.memberName ? membersBatch.memberName : "",
      batchMeetingMonth: dateVal.getMonth() + 1,
      batchMeetingYear: dateVal.getFullYear(),
      meetingHeldOnDate:
        meetingHeldOnDate && meetingHeldOnDate ? meetingHeldOnDate : "",
      loanSanctionedAmt:
        TotalPayableAmt && TotalPayableAmt ? TotalPayableAmt : 0,
      loanReasonId: loanReasonId ? loanReasonId : null,
      loanPurpose: loanPurpose && loanPurpose ? loanPurpose : "",
      otherPurpose: otherPurpose && otherPurpose ? otherPurpose : "",
      loanSanctionedEnteredById: user && user._id ? user._id : "",
      loanSanctionedEnteredByName: user && user.userName ? user.userName : "",
      institutionId: user && user.institutionId ? user.institutionId : "",
      memberEditData: memberEditData,
      //other Loan
      otherloanPayablePrincipalAmt: otherloanPayablePrincipalAmtSum
        ? parseFloat(Number(otherloanPayablePrincipalAmtSum).toFixed(2))
        : 0,
      otherloanPayableInterstAmt: otherloanPayableInterstAmtSum
        ? parseFloat(Number(otherloanPayableInterstAmtSum).toFixed(2))
        : 0,
      othDuration: othDuration && othDuration ? othDuration : 0,
      otherLoanSanctionedAmt: TotalPayableAmt ? TotalPayableAmt : 0,
      isLoanChecker: loanChecker /*hideFieldInternal === "true" ? false : ,*/,
      loanRepayment: {
        loanPrincipalPaid: Number(PrincipalPaid) ? Number(PrincipalPaid) : 0,
        loanInterestPaid: Number(InterestPaid) ? Number(InterestPaid) : 0,
        totalLoanPaid: Number(totalLoanPaid) ? Number(totalLoanPaid) : 0,
        paidDate: meetingHeldOnDate ? meetingHeldOnDate : "",
        paidMonth: meetingHeldOnDate && meetingHeldOnDate.slice(5, 7),
        paidYear: meetingHeldOnDate && meetingHeldOnDate.slice(0, 4),
        penalty: Number(internalPenalty) ? Number(internalPenalty) : 0,
      },
    };

    editMemberTransaction(finalData);

    OLRes &&
      OLRes.map((otherLoan) => {
        if (parseFloat(otherLoan.otherLoanTakenAmt || 0) > 0) {
          const OLSanctionedData = {
            batchId:
              selectedBatchData[0] && selectedBatchData[0]._id
                ? selectedBatchData[0]._id
                : "",
            memberId:
              membersBatch && membersBatch.memberId
                ? membersBatch.memberId
                : "",
            memberName:
              membersBatch && membersBatch.memberName
                ? membersBatch.memberName
                : "",
            batchMeetingMonth: dateVal.getMonth() + 1,
            batchMeetingYear: dateVal.getFullYear(),
            meetingHeldOnDate:
              meetingHeldOnDate && meetingHeldOnDate ? meetingHeldOnDate : "",
            otherLoanTypeId: otherLoan.otherLoanTypeId
              ? otherLoan.otherLoanTypeId
              : null,
            otherLoanType: otherLoan.otherLoanType
              ? otherLoan.otherLoanType
              : "",
            otherLoanSanctionedAmt: otherLoan.otherLoanTakenAmt
              ? otherLoan.otherLoanTakenAmt
              : "",
            otherloanPayablePrincipalAmt: otherLoan.otherloanPayablePrincipalAmt
              ? otherLoan.otherloanPayablePrincipalAmt
              : "",

            otherloanInterst: otherLoan.otherloanPayableInterstAmt
              ? otherLoan.otherloanPayableInterstAmt
              : "",

            othDuration: otherLoan.othDuration ? otherLoan.othDuration : "",

            otherLoanReasonId: otherLoan.otherLoanReasonId
              ? otherLoan.otherLoanReasonId
              : null,
            otherLoanReason: otherLoan.otherloanpurpose
              ? otherLoan.otherloanpurpose
              : "",
            otherloanotherpurpose: otherLoan.otherLoanOtherPurpose
              ? otherLoan.otherLoanOtherPurpose
              : "",
            loanSanctionedEnteredById: user && user._id ? user._id : "",
            loanSanctionedEnteredByName:
              user && user.userName ? user.userName : "",
            institutionId:
              selectedBatchData[0] && selectedBatchData[0].institutionId
                ? selectedBatchData[0].institutionId
                : "",
            externalNominee: otherLoan.externalNominee
              ? otherLoan.externalNominee
              : "",
            memberEditData: memberEditData,
            otherloanSanctionedAmt: otherLoan.otherLoanTakenAmt
              ? otherLoan.otherLoanTakenAmt
              : "",
          };
        }
      });

    onMemberMeetingModalChanges(true);
  };

  let remalingLoanForChecker =
    prevData[0] && Number(prevData[0].remainingLoan).toFixed(2);

  useEffect(() => {
    remalingLoanForChecker > 0
      ? setFormData({
          ...formData,
          loanChecker: true,
        })
      : setFormData({
          ...formData,
          loanChecker: false,
        });
  }, [remalingLoanForChecker]);

  var ED = meetingHeldOnDate.split(/\D/g);
  var mdDate = [ED[2], ED[1], ED[0]].join("-");

  /////////////////////////////////////////////
  const [showPayment, setShowpayment] = useState(false);
  const [NewInternalLoan, setNewInternalLoan] = useState([]);
  const [selectedEditLoan, setSelectedEditLoan] = useState({});
  const [paymentArray, setPaymentArray] = useState([]);
  const [internalTypeName, setInternalTypeName] = useState("");

  useEffect(() => {}, [NewInternalLoan], [paymentArray]);

  const internalDelete = (loan) => {
    setNewInternalLoan(
      NewInternalLoan.filter((ele) => ele.intLoanType != loan.intLoanType)
    );
  };

  const clickPay = (loanName) => {
    setShowpayment(true);
    setInternalTypeName(loanName);
  };

  const onDeleteInternalPayment = (data) => {
    setPaymentArray(
      paymentArray.filter((e) => e.internalLoanType != data.internalLoanType)
    );
  };

  const onDeleteInternalDbPayment = (data) => {
    let finalData = {
      ...data,
      institutionId:
        selectedBatchData[0] && selectedBatchData[0].institutionId
          ? selectedBatchData[0].institutionId
          : "",
      batchId:
        selectedBatchData[0] && selectedBatchData[0]._id
          ? selectedBatchData[0]._id
          : "",
      memberId:
        membersBatch && membersBatch.memberId ? membersBatch.memberId : "",
    };
    deleteBankLoanDetail(finalData);
  };

  const [editCurLoan, setEditCurLoan] = useState(false);
  const [ILData, setILData] = useState({});

  const toEditCurMeetingLoan = (LoanData) => {
    setEditCurLoan(true);
    setILData(LoanData);
  };

  //////////////////////////////////REpayment
  const [showRepaymentModal, setShowRepaymentModal] = useState(false);
  const [loanRepaymentData, setLoanRepaymentData] = useState([]);

  // showRepaymentModal

  const indianFormat = (dateData) => {
    try {
      return dateData
        .split("-")
        .reverse("")
        .join("-");
    } catch (er) {
      return "";
    }
  };
  const paymentSVG = (loan, RepaymentArray) => {
    return (
      <>
        {RepaymentArray.some(
          (paid) => paid.internalLoanType == loan.intLoanType
        ) ? (
          <>Paid</>
        ) : (
          <>
            <span
              className="ml-4"
              style={{ cursor: "pointer" }}
              onClick={() => {
                clickPay(loan.intLoanType);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-cash-coin"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                />
                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
              </svg>
            </span>
          </>
        )}
      </>
    );
  };

  ////NEW///////////////////////////////
  //dropdown bank///
  const [mainBankToSel, setMainBankToSel] = useState("");
  useEffect(() => {
    if (activeBankData) {
      let allbanks = activeBankData.map((ele) => {
        return { label: ele.batchBankName, value: ele.batchBankName };
      });
      setMainBankToSel(allbanks);
    }
  }, [activeBankData]);

  const [mainBankSelected, setMainBankSelected] = useState("");

  //////////////////////////////////////////
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <Tabs selectedIndex={tabIndex}>
        <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
          <TabList>
            <Tab tabfor="0">Basic</Tab>
            <Tab tabfor="1">Expense</Tab>
            <Tab tabfor="2">New IL Sanc</Tab>
            <Tab tabfor="3">IL Repayment</Tab>
            <Tab tabfor="4">Other Loan</Tab>
          </TabList>
        </div>

        <div className="row col-12">
          <div className="col-lg-3 col-sm-12">
            <label className="label-control">Meeting Date : {mdDate}</label>
          </div>
          <div className="col-lg-3 col-sm-12">
            <label className="label-control">Member Name : {memberName} </label>
          </div>
          <div className="col-lg-3 col-sm-12">
            <Select
              options={mainBankToSel}
              className="mt-2"
              onChange={(e) => setMainBankSelected(e)}
            />
          </div>
          <div className="col-lg-3 col-sm-12">
            {allPermission && allPermission.includes("Attendance") ? (
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <label className="label-control mx-2">Is Present :</label>
                <input
                  type="checkbox"
                  id="Unconfirmed"
                  checked={attendance}
                  onChange={handlePresent}
                  style={{ display: "inline-block" }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* 123 */}
        <TabPanel tabId="0">
          <form onSubmit={(e) => NextBackBtn(1)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                <label className="label-control">
                  Savings Amount*
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Savings amount Cash and Bank Total Value"
                  />{" "}
                  :(1000)
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchSavingAmt"
                        // value={batchSavingAmt}
                        disabled={mainBankSelected ? false : true}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        // onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchSavingAmt"
                        value="500"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        // onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <label className="label-control">
                  Subscription Amount*{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Subscription amount Cash and Bank Total Value"
                  />{" "}
                  :(90)
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchSubscriptionAmt"
                        value="90"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        disabled={mainBankSelected ? false : true}
                        required
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash </div>{" "}
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchSavingAmt"
                        value="0"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        // disabled={svngAmtBKName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </div>
                <label className="label-control">
                  Other Contribution{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Other contribution Cash and Bank Total Value"
                  />{" "}
                  :(200)
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="otherContribution"
                        value="0"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        disabled={mainBankSelected ? false : true}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchSavingAmt"
                        value="200"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <label className="label-control">
                  Other Income{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Other Income	Cash and Bank Total Value"
                  />{" "}
                  :(0)
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="otherIncome"
                        value="0"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        disabled={mainBankSelected ? false : true}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchSavingAmt"
                        value="0"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        // disabled={svngAmtBKName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                <label className="label-control">
                  Total Savings{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="	
                    Total Savings amount"
                  />{" "}
                  :
                </label>

                <div className="row col-12">
                  <div className="col-lg-12">
                    <input
                      type="text"
                      value="1000"
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      disabled
                    />
                  </div>
                </div>
                <label className="label-control">
                  {" "}
                  Membership Fees{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Membership Fees	Cash and Bank Total Value"
                  />{" "}
                  :(350)
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="membershipFees"
                        value="300"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        disabled={mainBankSelected ? false : true}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchSavingAmt"
                        value="50"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        // disabled={svngAmtBKName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </div>
                <label className="label-control">
                  Seed Money{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Seed Money	Cash and Bank Total Value"
                  />{" "}
                  :(10000)
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="memSeedMoney"
                        value="5000"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        disabled={mainBankSelected ? false : true}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>{" "}
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchSavingAmt"
                        value="5000"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        // disabled={svngAmtBKName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </div>
                <label className="label-control">
                  Security Deposit Member To SHG{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Security deposit	Cash and Bank Total Value"
                  />{" "}
                  :(0)
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="memSecurityDepositToShg"
                        value="0"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        disabled={mainBankSelected ? false : true}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchSavingAmt"
                        value="0"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                *Indicates mandatory fields, Please fill mandatory fields before
                Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="1">
          <form onSubmit={(e) => NextBackBtn(2)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">Savings Withdrawal :</label>
                  <input
                    type="number"
                    name="savingWithdrawals"
                    value={savingWithdrawals}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Dividend Distributed :
                  </label>
                  <input
                    type="number"
                    name="dividendDistributed"
                    value={dividendDistributed}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    {" "}
                    Security Deposit SHG To Member :
                  </label>
                  <input
                    type="number"
                    name="securityDeposit"
                    value={securityDeposit}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>

            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(0)}
              >
                Previous
              </button>
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="2">
          <form onSubmit={(e) => NextBackBtn(3)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12"></div>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <h5>Loans in this meeting </h5>
            </div>
            <div className="body-inner no-padding table-responsive">
              <table
                className="tabllll table table-bordered table-striped table-hover mt-2"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>Info</th>
                    <th>IL Type</th>
                    <th>Sanc On</th>
                    <th>Sanc Prin</th>
                    <th>Sanc Int</th>
                    <th>Loan Status</th>
                    <th>Operation</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {curMettingLoanSanc &&
                    curMettingLoanSanc.map((ele) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <span
                                className="ml-1"
                                onClick={() => {
                                  setShowRepaymentModal(true);
                                  setLoanRepaymentData(ele.loanRepayment || []);
                                }}
                              >
                                {
                                  <img
                                    className="img_icon_size log mb-1"
                                    src={require("../../static/images/information.png")}
                                    alt="Repayment Info"
                                  />
                                }
                              </span>
                            </td>
                            <td>
                              <span>{ele.intLoanType || "OLD LOAN"}</span>
                            </td>
                            <td>{mdDate}</td>
                            <td>{ele.loanPayablePrincipal}</td>
                            <td>{ele.loanPayableInterest}</td>
                            <td>
                              <span className="mx-2">{ele.loanStatus}</span>
                            </td>
                            <td>
                              {ele.intLoanType ? (
                                <>
                                  <img
                                    onClick={() => toEditCurMeetingLoan(ele)}
                                    className="img_icon_size log mx-2"
                                    src={require("../../static/images/edit_icon.png")}
                                    alt="Edit"
                                    title="Edit"
                                    style={{ cursor: "pointer" }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })} */}

                  {NewInternalLoan &&
                    NewInternalLoan.map((loan, idx) => {
                      return (
                        <tr key={idx}>
                          <td></td>
                          <td>{loan.intLoanType}</td>
                          <td>{mdDate}</td>
                          <td>{loan.loanPayablePrincipal}</td>
                          <td>0</td>
                          <td>{loan.loanPayablePrincipal}</td>
                          {/* <td>{loan.internalDuration}</td> */}
                          <td>{loan.loanPayableInterest}</td>
                          <td>Active</td>
                          <td>
                            <span
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => {
                                internalDelete(loan);
                              }}
                            >
                              <img
                                src={require("../../static/images/delete.png")}
                                alt="help"
                                id="img_tool_admin"
                                className="pass_admin_help_icon_question mx-2"
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <>
              {editCurLoan ? (
                <EditCurLoan
                  ILData={ILData}
                  setEditCurLoan={setEditCurLoan}
                  user={user}
                />
              ) : (
                <ShgInternalLoan
                  Internalonly={Internalonly}
                  setNewInternalLoan={setNewInternalLoan}
                  internalLoanData={selectedEditLoan}
                  NewInternalLoan={NewInternalLoan}
                  allpurpose={allpurpose}
                />
              )}
            </>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(1)}
              >
                Previous
              </button>
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="3">
          <form onSubmit={(e) => NextBackBtn(4)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12"></div>

            <>
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                <h5>Active Loans</h5>
              </div>
              <div className="body-inner no-padding table-responsive">
                <table
                  className="tabllll table table-bordered table-striped table-hover mt-2"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>Info</th>
                      <th>IL Type</th>
                      <th>Sanc On</th>
                      <th>Sanc Prin</th>
                      <th>Total Prin Paid</th>
                      <th>Remaining Prin</th>
                      <th>Operation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Internalonly &&
                      Internalonly.map((loan, idx) => {
                        let remaingLoan =
                          loan.loanPayablePrincipal -
                          (loan.loanCurPrincipal || 0);

                        return (
                          <tr key={idx}>
                            <td>
                              <span
                                className="ml-1"
                                onClick={() => {
                                  setShowRepaymentModal(true);
                                  setLoanRepaymentData(
                                    loan.loanRepayment || []
                                  );
                                }}
                              >
                                {
                                  <img
                                    className="img_icon_size log mb-1"
                                    src={require("../../static/images/information.png")}
                                    alt="Repayment Info"
                                  />
                                }
                              </span>
                            </td>
                            <td>{loan.intLoanType}</td>
                            <td>{indianFormat(loan.meetingHeldOnDate)}</td>
                            <td>{loan.loanPayablePrincipal}</td>
                            <td>{loan.loanCurPrincipal}</td>
                            <td>{remaingLoan}</td>
                            <td>
                              {paymentArray.some(
                                (item) =>
                                  item.internalLoanType == loan.intLoanType
                              ) ||
                              (exstMemILData &&
                                exstMemILData.internalRepayment &&
                                exstMemILData.internalRepayment.some(
                                  (item) =>
                                    item.internalLoanType == loan.intLoanType
                                )) ? (
                                <>Paid</>
                              ) : (
                                <>{paymentSVG(loan, paymentArray)}</>
                              )}
                            </td>
                          </tr>
                        );
                      })}

                    {NewInternalLoan &&
                      NewInternalLoan.map((loan, idx) => {
                        return (
                          <tr key={idx}>
                            <td></td>
                            <td>{loan.intLoanType}</td>
                            <td>{mdDate}</td>
                            <td>{Number(loan.loanPayablePrincipal)}</td>
                            <td></td>
                            <td>{Number(loan.loanPayablePrincipal)}</td>
                            <td>{paymentSVG(loan, paymentArray)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              {showPayment ? (
                <LoanPayment
                  setShowpayment={setShowpayment}
                  internalTypeName={internalTypeName}
                  setPaymentArray={setPaymentArray}
                  paymentArray={paymentArray}
                  meetingHeldOnDate={meetingHeldOnDate}
                />
              ) : (
                <></>
              )}

              <div className=" body-inner no-padding table-responsive">
                <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                  <h5> Repayment Details112</h5>
                </div>

                <table
                  className="tabllll table table-bordered table-striped table-hover mt-2"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>IL Type</th>
                      <th>Paid Principal</th>
                      <th>Paid Interest</th>
                      <th>Penalty</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentArray &&
                      paymentArray.map((paid, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{paid.internalLoanType}</td>
                            <td>{paid.internalPrincipalPaid}</td>
                            <td>{paid.internalIntrestPaid}</td>
                            <td>{paid.penalty}</td>
                            <td>
                              <span
                                onClick={() => onDeleteInternalPayment(paid)}
                              >
                                <img
                                  src={require("../../static/images/delete.png")}
                                  alt="help"
                                  id="img_tool_admin"
                                  className="pass_admin_help_icon_question"
                                />
                              </span>
                            </td>
                          </tr>
                        );
                      })}

                    {exstMemILData &&
                      exstMemILData.internalRepayment &&
                      exstMemILData.internalRepayment.map((paid, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{paid.internalLoanType}</td>
                            <td>{paid.internalPrincipalPaid}</td>
                            <td>{paid.internalIntrestPaid}</td>
                            <td>{paid.penalty}</td>
                            <td>
                              {!!Internalonly.find(
                                (loan) =>
                                  loan.intLoanType == paid.internalLoanType
                              ) ? (
                                <span
                                  onClick={() =>
                                    onDeleteInternalDbPayment(paid)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={require("../../static/images/delete.png")}
                                    alt="help"
                                    id="img_tool_admin"
                                    className="pass_admin_help_icon_question"
                                  />
                                </span>
                              ) : (
                                "Closed"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(2)}
              >
                Previous
              </button>
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="4">
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
            <div className="col-lg-6  col-md-12 col-sm-12 col-12">
              <label className="label-control">Meeting Date : {mdDate}</label>
            </div>

            <div className="col-lg-6  col-md-12 col-sm-12 col-12">
              <label className="label-control">
                Member Name : {memberName}
              </label>
            </div>
          </div>

          <div className="col-lg-6  col-md-12 col-sm-12 col-12">
            <label className="label-control mx-3">
              Active Loans :
              {OtherLoanNames &&
                OtherLoanNames.map((ele) => {
                  return (
                    <label className="label-control">{ele.label} &nbsp; </label>
                  );
                })}
            </label>
          </div>
          {activeOtherLoan.length > 1 ? (
            <div className="row">
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label
                    className="label-control"
                    style={otherLoanTypesErrorStyle}
                  >
                    Other Loan Types* :
                  </label>
                  <Select
                    name="otherLoanTypes"
                    options={activeOtherLoan}
                    isSearchable={true}
                    value={otherLoanType}
                    placeholder="Select Mode"
                    onChange={(e) => onotherLoanTypeChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
                {dataAvaiable === "true" ? (
                  <></>
                ) : (
                  <>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Loan Sanctioned Principal :{" "}
                      </label>
                      <input
                        type="number"
                        name="otherloanPayablePrincipalAmt"
                        value={otherloanPayablePrincipalAmt}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Loan Sanctioned Interest :{" "}
                      </label>
                      <input
                        type="number"
                        name="otherloanPayableInterstAmt"
                        value={otherloanPayableInterstAmt}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Total Sanctioned Amount :{" "}
                      </label>
                      <input
                        type="number"
                        name="otherloanSanctionedAmt"
                        value={otherloanSanctionedAmt}
                        className="form-control"
                        disabled={true}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control" style={DurationError}>
                        Duration (In Months) :
                      </label>
                      <input
                        type="number"
                        name="othDuration"
                        value={othDuration}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">Loan Purpose :</label>
                      <Select
                        name="bankloanPurpose"
                        options={allbankpurpose}
                        isSearchable={true}
                        value={otherloanpurposes}
                        placeholder="Select Purpose"
                        onChange={(e) => onBankLoanPurposeChange(e)}
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>

                    {showbankotherPurposeSection && (
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Purpose Reason :
                        </label>
                        <input
                          type="text"
                          name="otherLoanReasons"
                          value={otherLoanReasons}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          required
                        />
                      </div>
                    )}

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Subsidy Distributed :
                      </label>
                      <input
                        type="number"
                        name="indSubsidyDistributed"
                        value={indSubsidyDistributed}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">Nominee :</label>
                      <input
                        type="text"
                        name="externalNominee"
                        value={externalNominee}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </>
                )}
                {hideFieldOther === "true" ? (
                  <>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Has Repayment ? :
                        </label>
                        <input
                          style={{
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                            display: "block",
                          }}
                          type="checkbox"
                          id="Unconfirmed"
                          checked={OTHloanChecker}
                          onChange={handleOnOtherChange1}
                        />
                      </div>
                    </div>
                    {OTHloanChecker && (
                      <>
                        <div className="row col-lg-12 col-md-9 col-sm-9 col-12 py-4 my-1">
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Principal Paid :
                            </label>
                            <input
                              type="number"
                              name="otherLoanPaid"
                              value={otherLoanPaid}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Interest Paid:
                            </label>
                            <input
                              type="number"
                              name="otherInterestPaid"
                              value={otherInterestPaid}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                        </div>

                        <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                          <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Total Paid :
                            </label>
                            <input
                              type="number"
                              name="TotalOtherloanPaid"
                              value={TotalOtherloanPaid}
                              className="form-control"
                              disabled={true}
                            />
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Remaining Loan(Principal) :
                            </label>
                            <input
                              type="text"
                              value={othRemaingLoan}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                              disabled
                            />
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Penalty fees :
                            </label>
                            <input
                              type="number"
                              name="otherPenalty"
                              value={otherPenalty}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <button
                    onClick={(e) => onAddOLR(e)}
                    className="btn sub_form btn_continue blackbrd Save float-right"
                  >
                    ADD
                  </button>
                </div>
              </div>
              <div className=" body-inner no-padding table-responsive">
                <table
                  className="tabllll table table-bordered table-striped table-hover mt-2"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>OL Type</th>
                      <th>OL Sanctioned Principal</th>
                      <th>OL Sanctioned Interest</th>
                      <th>Duration</th>
                      <th>OL Total Sanctioned Amount</th>
                      <th>OL Purpose</th>
                      <th>Subsidy Distributed</th>
                      <th>Nominee</th>
                      <th>OL Principal Paid</th>
                      <th>OL Interest Paid</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exstMemOLData &&
                      exstMemOLData.otherLoan &&
                      exstMemOLData.otherLoan.map((memberData, idx) => {
                        if (memberData.otherLoanTypeId) {
                          return (
                            <tr key={idx}>
                              <td>{memberData.otherLoanType}</td>
                              <td>{memberData.otherloanPayablePrincipal}</td>
                              <td>{memberData.otherloanPayableInterst}</td>
                              <td>{memberData.otherLoanDuration}</td>
                              <td>{memberData.otherLoanTakenAmt}</td>
                              <td>{memberData.otherloanpurpose}</td>
                              <td>{memberData.indSubsidyDistributed}</td>
                              <td>{memberData.externalNominee}</td>
                              <td>{memberData.otherLoanPaid}</td>
                              <td>{memberData.otherInterestPaid}</td>
                              <td>
                                <img
                                  className="img_icon_size log"
                                  onClick={() => onDelete(memberData, idx)}
                                  src={require("../../static/images/delete.png")}
                                  alt="Deactivate"
                                  title="Deactivate"
                                />
                              </td>
                            </tr>
                          );
                        }
                      })}
                    {AddedDetailsOLR &&
                      AddedDetailsOLR.map((AddDetail, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{AddDetail.otherLoanType}</td>
                            <td>{AddDetail.otherloanPayablePrincipal}</td>
                            <td>{AddDetail.otherloanPayableInterst}</td>
                            <td>{AddDetail.otherLoanDuration}</td>
                            <td>{AddDetail.otherLoanTakenAmt}</td>
                            <td>
                              {AddDetail.otherloanpurpose}{" "}
                              {AddDetail.otherLoanOtherPurpose
                                ? AddDetail.otherLoanOtherPurpose
                                : ""}
                            </td>
                            <td>{AddDetail.indSubsidyDistributed}</td>
                            <td>{AddDetail.externalNominee}</td>
                            <td>{AddDetail.otherLoanPaid}</td>
                            <td>{AddDetail.otherInterestPaid}</td>
                            <td>
                              <img
                                className="img_icon_size log"
                                onClick={() =>
                                  onRemoveChangeOLR(AddDetail.otherLoanTypeId)
                                }
                                src={require("../../static/images/close-buttonRed.png")}
                                alt="Remove"
                                title="Remove"
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <>
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                {isOtherLoan === "true" ? (
                  <>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Loan Sanctioned Principal :{" "}
                      </label>
                      <input
                        type="number"
                        name="otherloanPayablePrincipalAmt"
                        value={otherloanPayablePrincipalAmt}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Loan Sanctioned Interest :{" "}
                      </label>
                      <input
                        type="number"
                        name="otherloanPayableInterstAmt"
                        value={otherloanPayableInterstAmt}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Duration (In Months) :{" "}
                      </label>
                      <input
                        type="number"
                        name="othDuration"
                        value={othDuration}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Total Sanctioned Amount :{" "}
                      </label>
                      <input
                        type="number"
                        name="otherloanSanctionedAmt"
                        value={otherloanSanctionedAmt}
                        className="form-control"
                        disabled={true}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">Loan Purpose :</label>
                      <Select
                        name="bankloanPurpose"
                        options={allbankpurpose}
                        isSearchable={true}
                        value={otherloanpurposes}
                        placeholder="Select Purpose"
                        onChange={(e) => onBankLoanPurposeChange(e)}
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>

                    {showbankotherPurposeSection && (
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Loan Purpose :
                        </label>
                        <input
                          type="text"
                          name="otherLoanReasons"
                          value={otherLoanReasons}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    )}

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Subsidy Distributed :
                      </label>
                      <input
                        type="number"
                        name="indSubsidyDistributed"
                        value={indSubsidyDistributed}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">Nominee :</label>
                      <input
                        type="text"
                        name="externalNominee"
                        value={externalNominee}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {(Otheronly &&
                  Otheronly[0] &&
                  Otheronly[0].loanStatus === "Active" &&
                  Otheronly[0].isLoanType === "otherLoan") ||
                (Otheronly &&
                  Otheronly[0] &&
                  Otheronly[0].loanStatus === "Overdue" &&
                  Otheronly[0].isLoanType === "otherLoan") ||
                hideFieldOther === "true" ? (
                  <>
                    <div className=" col-lg-12 col-md-9 col-sm-9 col-12 ">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Has Repayment ? :
                        </label>
                        <input
                          style={{
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                            display: "block",
                          }}
                          type="checkbox"
                          id="Unconfirmed"
                          checked={OTHloanChecker}
                          onChange={handleOnOtherChange1}
                        />
                      </div>
                    </div>
                    {OTHloanChecker && (
                      <>
                        <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Principal Paid :
                            </label>
                            <input
                              type="number"
                              name="otherLoanPaid"
                              value={otherLoanPaid}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Interest Paid:
                            </label>
                            <input
                              type="number"
                              name="otherInterestPaid"
                              value={otherInterestPaid}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                        </div>

                        <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                          <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Total Paid :
                            </label>
                            <input
                              type="number"
                              name="TotalOtherloanPaid"
                              value={TotalOtherloanPaid}
                              className="form-control"
                              disabled={true}
                            />
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Remaining Loan :
                            </label>
                            <input
                              type="text"
                              value={
                                prevData[0] &&
                                Number(prevData[0].otherRemainingLoan).toFixed(
                                  2
                                )
                              }
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                              disabled
                            />
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Penalty fees :
                            </label>
                            <input
                              type="text"
                              name="otherPenalty"
                              value={otherPenalty}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}

          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label className="label-control colorRed">
              * Indicates mandatory fields, Please fill mandatory fields before
              Submit
            </label>
          </div>
          <div className=" col-md-12 col-lg-12 col-sm-12 col-12 ">
            {loading ? (
              <button
                className="btn sub_form btn_continue blackbrd Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Submit"
                onClick={(e) => onUpdate(e)}
                className="btn sub_form btn_continue Save float-right"
              />
            )}
            <button
              className="btn sub_form btn_continue Save float-right"
              onClick={() => NextBackBtn(3)}
            >
              Previous
            </button>
          </div>
        </TabPanel>
      </Tabs>

      <Modal
        show={showRepaymentModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">Repayment Details</h3>
          </div>
          <div className="col-lg-2">
            <button
              onClick={() => setShowRepaymentModal(false)}
              className="close"
            >
              <img
                src={require("../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <LoanRepaymentDetails
            loanRepaymentData={loanRepaymentData}
            watchingDate={mdDate}
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

EditMembermeetingDetailModal.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getMemberPreveiousMeetingData: PropTypes.func.isRequired,
  getMembersMeetingData: PropTypes.func.isRequired,
  getMemberPrevMeetingData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getParticularLoanReason,
  editMemberTransaction,
  AddLoanSanctionedDetails,
  EditLoanSanctionedDetails,
  getSeletedBatchDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  deleteOtherLoan,
  getSanctionedData,
  getExistingMemberOLData,
  getExistingMemberILData,
  getMemberPreveiousMeetingData,
  addNewOtherLoanDetails,
  getMembersMeetingData,
  getMemberPrevMeetingData,
  deleteBankLoanDetail,
  getCurMemMettingLoanSanc,
})(EditMembermeetingDetailModal);
