import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { connect } from "react-redux";
import { editSubOcccupation, getOcccupationsDropdown } from "../../actions/shg";
import Spinner from "../layout/Spinner";

const EditOccupation = ({
  savedMessage,
  auth: { isAuthenticated, user, users, loading },
  shg: { activeOccupation },
  allSubOccupation,
  editSubOcccupation,
  onUpdateModalChange,
  getOcccupationsDropdown,
}) => {
  useEffect(() => {
    getOcccupationsDropdown();
  }, [getOcccupationsDropdown]);
  //formData
  const [formData, setFormData] = useState({
    subOccupationName:
      allSubOccupation && allSubOccupation.subOccupationName
        ? allSubOccupation.subOccupationName
        : "",
    isSubmitted: false,
  });

  const { subOccupationName } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const allOccupation = [];
  let activeOccupationData = JSON.parse(
    localStorage.getItem("activeOccupationData")
  );
  activeOccupationData &&
    activeOccupationData.map((occupation) =>
      allOccupation.push({
        oId: occupation._id,
        label: occupation.occcupationName,
        value: occupation.occcupationName,
      })
    );
  const [occupation, setOccupationData] = useState(
    allSubOccupation
      ? allOccupation.length !== 0
        ? allOccupation &&
          allOccupation.filter(
            (x) => x.oId === allSubOccupation.occupationId
          )[0]
        : ""
      : ""
  );
  if (activeOccupationData && activeOccupationData.length !== 0) {
    let occ = allSubOccupation
      ? allOccupation.length !== 0
        ? allOccupation &&
          allOccupation.filter(
            (x) => x.oId === allSubOccupation.occupationId
          )[0]
        : ""
      : "";
  }
  const [occupationId, setOccupatioId] = useState(
    allSubOccupation.occupationId
  );
  const onOccupationChange = (e) => {
    var occupationId = "";
    setOccupationData(e);
    occupationId = e.oId;
    setOccupatioId(occupationId);
  };

  const onUpdate = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: allSubOccupation ? allSubOccupation._id : "",
      subOccupationName: subOccupationName,
      occupationId: occupationId,
      subOoccupationEnteredById: user._id,
      subOccupationEnteredByName: user.userName,
      institutionId: user.institutionId,
    };
    editSubOcccupation(finalData);
    onUpdateModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onUpdate(e)}>
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control"> Sub Occupation Name* :</label>
              <input
                type="text"
                name="subOccupationName"
                value={subOccupationName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control">Occupation* :</label>
              <Select
                name="occupationName"
                options={allOccupation}
                isSearchable={true}
                value={occupation}
                placeholder="Select Occupation"
                onChange={(e) => onOccupationChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
          </div>

          <div className="col-md-10 col-lg-10 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Update"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditOccupation.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  editSubOcccupation: PropTypes.func.isRequired,
  savedMessage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  savedMessage: state.auth.savedMessage,
});

export default connect(mapStateToProps, {
  editSubOcccupation,
  getOcccupationsDropdown,
})(EditOccupation);
