import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AddPermission from "./AddPermission";
import EditPermission from "./EditPermission";
import DeactivePermission from "./DeactivePermission";
import { getALLPermissionDetails } from "../../actions/setting";
import Pagination from "../layout/Pagination";
import Spinner from "../layout/Spinner";
const AllPermission = ({
  auth: { isAuthenticated, user, users },
  setting: { permissionData },
  getALLPermissionDetails,
}) => {
  useEffect(() => {
    getALLPermissionDetails();
  }, [getALLPermissionDetails]);
  const [showAddModal, setShowAddModal] = useState(false);
  const handleAddModalClose = () => setShowAddModal(false);
  const onClickHandler = () => {
    setShowAddModal(true);
  };

  const onAddModalChange = (e) => {
    if (e) {
      handleAddModalClose();
    }
  };
  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (permissionData, idx) => {
    setShowUpdateModal(true);
    setUserData(permissionData);
  };

  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const [userDatas, setUserDatas] = useState(null);
  const onUpdate = (permissionData, idx) => {
    setShowDeactiveModal(true);
    setUserDatas(permissionData);
  };

  //pagination code
  const [currentData, setCurrentData] = useState(1);
  const [dataPerPage] = useState(8);
  //Get Current Data
  const indexOfLastData = currentData * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentDatas =
    permissionData && permissionData.slice(indexOfFirstData, indexOfLastData);
  //change page
  const paginate = (nmbr) => {
    setCurrentData(nmbr);
  };
  //pagination code ends
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-12 col-sm-12 col-12 no_padding">
            <div className="row col-lg-5 col-md-11 col-sm-10 col-10">
              <h5 className="heading_color">All Permission </h5>
            </div>

            <div className="col-lg-7 col-md-1 col-sm-1 col-1 py-4">
              <img
                className="img_icon_size log float-right"
                onClick={() => onClickHandler()}
                src={require("../../static/images/add-icon.png")}
                alt="Add Permission Type"
                title="Add Permission Type"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 text-center py-2">
              <section className="body">
                <div className=" body-inner no-padding  table-responsive">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Permission Name</th>
                        <th>Description</th>
                        <th>Op</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentDatas &&
                        currentDatas.map((permissionData, idx) => {
                          return (
                            <tr key={idx}>
                              {permissionData.permissionStatus &&
                              permissionData.permissionStatus === "Deactive" ? (
                                <td style={{ backgroundColor: "#dda6a6" }}>
                                  {" "}
                                  {permissionData.permissionName}{" "}
                                </td>
                              ) : (
                                <td> {permissionData.permissionName} </td>
                              )}

                              <td>{permissionData.permissionDescription} </td>
                              <td>
                                {permissionData.permissionStatus &&
                                permissionData.permissionStatus !==
                                  "Deactive" ? (
                                  <>
                                    <img
                                      className="img_icon_size log"
                                      onClick={() =>
                                        onUpdate(permissionData, idx)
                                      }
                                      src={require("../../static/images/delete.png")}
                                      alt="Deactivate Permission Type"
                                      title="Deactivate Permission Type"
                                    />{" "}
                                    &nbsp;
                                    <img
                                      className="img_icon_size log"
                                      onClick={() =>
                                        onEdit(permissionData, idx)
                                      }
                                      src={require("../../static/images/edit_icon.png")}
                                      alt="Edit Permission Type"
                                      title="Edit Permission Type"
                                    />
                                  </>
                                ) : (
                                  <label>
                                    Deactivated&nbsp;
                                    <img
                                      className="img_icon_size log "
                                      src={require("../../static/images/info.png")}
                                      alt="Reason"
                                      title={
                                        permissionData.permissionDeactiveReason
                                      }
                                    />
                                  </label>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-6 col-sm-12 col-12 align_right">
                    {/* <label>No of Program : {permissionData.length}</label> */}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        <Modal
          show={showAddModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Add Permission </h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleAddModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <AddPermission onAddModalChange={onAddModalChange} />
          </Modal.Body>
        </Modal>
        <Modal
          show={showUpdateModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Edit Permission</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleUpdateModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditPermission
              editpermissiondata={userData}
              onUpdateModalChange={onUpdateModalChange}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={showDeactiveModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Deactivate Permission</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleDeactiveModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <DeactivePermission
              permissionDeactiveData={userDatas}
              onDeactiveModalChange={onDeactiveModalChange}
            />
          </Modal.Body>
        </Modal>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
            {permissionData && permissionData.length !== 0 ? (
              <Pagination
                dataPerPage={dataPerPage}
                totalData={permissionData.length}
                paginate={paginate}
                currentPage={currentData}
              />
            ) : (
              <Fragment />
            )}
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 col-12 align_right">
            <label>No. of Permissions : {permissionData.length}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AllPermission.propTypes = {
  auth: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  getALLPermissionDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  setting: state.setting,
});

export default connect(mapStateToProps, {
  getALLPermissionDetails,
})(AllPermission);
