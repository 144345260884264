//ADDMEMBERMEETINGOPMODAL

import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { connect } from "react-redux";
import {
  getParticularLoanReason,
  AddMemberTransaction,
  AddLoanSanctionedDetails,
  getMemberPrevMeetingData,
  getActiveOtherLoanType,
} from "../../actions/shg";
import Spinner from "../layout/Spinner";
import LoanPayment from "../layout/LoanPayment";
import ShgInternalLoan from "../layout/ShgInternalLoan";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const AddMembermeetingOpModal = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { loanreason, prevData, activeOtherLoanTypes, isloansanctioned },
  membersBatch,
  selectedDate,
  onMemberMeetingOPModalChanges,
  getParticularLoanReason,
  AddMemberTransaction,
  getMemberPrevMeetingData,
  getActiveOtherLoanType,
  AddLoanSanctionedDetails,
  // getSanctionedData,
}) => {
  let selectedBatchData = JSON.parse(localStorage.getItem("selBatchData"));
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));

  useEffect(() => {
    getParticularLoanReason();
  }, [getParticularLoanReason]);

  useEffect(() => {
    const reqData = {
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: membersBatch && membersBatch._id,
    };
    getMemberPrevMeetingData(reqData);
  }, [getMemberPrevMeetingData]);

  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  let OtherLoanTypeDropDown = [];
  isloansanctioned &&
    isloansanctioned.map((ele) => {
      OtherLoanTypeDropDown.push({
        label: ele.otherLoanType,
        value: ele.otherLoanTypeId,
      });
    });

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  ///other loan hide fields for already taken loan members
  let onlyID =
    isloansanctioned && isloansanctioned.map((ele) => ele.otherLoanTypeId);

  let alreadyLoanAvaiable =
    (activeOtherLoanTypes &&
      activeOtherLoanTypes
        .map((ele) => onlyID.includes(ele._id))
        .some((ele) => ele == true)) ||
    false;
  useEffect(() => {}, [alreadyLoanAvaiable]);

  ///internal loan & other loan fields for already taken loan members and display data
  let Internalonly = [];
  let Otheronly = [];
  isloansanctioned &&
    isloansanctioned.map((ele) => {
      if (ele.isLoanType === "Internal") {
        Internalonly.push(ele);
      } else {
        Otheronly.push(ele);
      }
    });

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);

  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };
  // code for next previous tabing ends
  const [formData, setFormData] = useState({
    savingAmt: 0,
    loanPaid: 0,
    memberName: membersBatch.memberName,
    meetingHeldOnDate: selectedDate,
    batchSavingAmt: 0,
    batchSubscriptionAmt: 0,
    interestPaid: "",
    batchId: selectedBatchData[0] && selectedBatchData.batchId,
    institutionId: membersBatch[0] && membersBatch[0].institutionId,
    otherContribution: "",
    // internalNominee: "",

    //other loan
    otherIncome: 0,
    otherLoanDuration: "",
    otherLoanPrincipalPaid: "",
    loanChecker: false,
    OTHloanChecker: false,
    memSecurityDepositToShg: 0,
    loanTakenAmt: 0,
    otherPurpose: "",
    otherloanSanctionedAmt: 0,
    remainingLoan: 0,
    otherRemainingLoan: 0,
    savingWithdrawals: 0,
    dividendDistributed: 0,
    securityDeposit: 0,
    isSubmitted: false,
    otherLoanDuration: "",
    //
    loanPayablePrincipal: "",
    loanPayableInterest: "",
    internalDuration: "",
    PrincipalPaid: "",
    InterestPaid: "",
    totalLoanPaid: "",
    //other loan
    otherloanPayablePrincipalAmt: "",
    otherloanPayableInterstAmt: "",
    othDuration: "",
    otherInterestPaid: "",
    otherLoanPaid: "",
    otherTotalPaid: "",
    loanChecker: false,
    OTHloanChecker: false,
  });

  const {
    otherloanpurpose,
    otherLoanReasons,
    otherLoanPaid,
    otherLoanInterest,
    meetingHeldOnDate,
    batchId,
    memberId,
    remainingLoan,
    otherRemainingLoan,
    memberName,
    savingAmt,
    batchSavingAmt,
    batchSubscriptionAmt,
    loanPaid,
    interestPaid,
    subscriptionAmt,
    otherContribution,
    otherIncome,
    membershipFees,
    memSeedMoney,
    memSecurityDepositToShg,
    loanTakenAmt,
    otherPurpose,
    savingWithdrawals,
    dividendDistributed,
    securityDeposit,
    otherloanSanctionedAmt,
    indSubsidyDistributed,
    otherLoanReason,
    institutionId,
    //
    loanPayablePrincipal,
    loanPayableInterest,
    internalDuration,
    PrincipalPaid,
    InterestPaid,
    otherLoanDuration,
    //other loan
    otherloanPayablePrincipalAmt,
    otherloanPayableInterstAmt,
    othDuration,
    otherInterestPaid,
    otherTotalPaid,
    loanChecker,
    OTHloanChecker,
  } = formData;

  var rl = Number(remainingLoan);

  var TotalPayableAmt =
    Number(loanPayableInterest) + Number(loanPayablePrincipal);

  //hide has loan (display only if loansancationedamt>0)
  const [hideFieldInternal, sethideFieldInternal] = useState("");
  useEffect(() => {
    if (TotalPayableAmt > 0) {
      sethideFieldInternal("true");
    } else {
      sethideFieldInternal("false");
    }
  }, [TotalPayableAmt]);

  /////////////////////////////////////other loan for 1
  //to hide has loan in 1 other Loan and open only if loanSancationedamt>0
  // const [hideFieldOther, sethideFieldOther] = useState("");
  // useEffect(() => {
  //   alert("useeffect")

  //   if (otherloanSanctionedAmtt > 0) {

  //     sethideFieldOther("true");
  //     setFormData({
  //       ...formData,
  //       OTHloanChecker: false,
  //     });
  //   } else {
  //     sethideFieldOther("false");
  //     setFormData({
  //       ...formData,
  //       otherLoanPaid: "",
  //       otherInterestPaid: "",
  //       TotalOtherloanPaid: "",
  //       otherPenalty: "",
  //       OTHloanChecker: false,
  //     });
  //   }
  // }, [otherloanSanctionedAmtt]);

  var otherloanSanctionedAmtt =
    Number(otherloanPayablePrincipalAmt) + Number(otherloanPayableInterstAmt);

  const [hideFieldOther, sethideFieldOther] = useState("");
  useEffect(() => {
    if (otherloanSanctionedAmtt > 0) {
      sethideFieldOther("true");
      setFormData({
        ...formData,
        OTHloanChecker: false,
      });
    } else {
      sethideFieldOther("false");
      setFormData({
        ...formData,
        otherLoanPaid: "",
        otherInterestPaid: "",
        otherRemainingLoan: "",
        OTHloanChecker: false,
      });
    }
  }, [otherloanSanctionedAmtt]);

  //////////////////////////////////////////////////////////////

  // const [hideHasLoan, sethideHasLoan] = useState(false);
  // if (TotalPayableAmt === 0) {
  //   sethideHasLoan(true);
  // } else {
  //   sethideHasLoan(false);
  // }
  // var totalLoanPaid = Number(PrincipalPaid) + Number(InterestPaid);

  //other loan

  // var TotalOtherloanPaid = Number(otherLoanPaid) + Number(otherInterestPaid);
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // const [loanChecker, setLoanChecker] = useState(false);
  const [otherLoanChecker2, setOtherLoanChecker2] = useState(false);
  const handleOnChange = () => {
    // setLoanChecker(!loanChecker);
    setFormData({
      ...formData,
      loanChecker: !loanChecker,
    });
  };

  const handleOnOtherChange2 = () => {
    setFormData({
      ...formData,
      otherLoanPaid: "",
      otherInterestPaid: "",
      otherRemainingLoan: "",
      OTHloanChecker: !OTHloanChecker,
    });
    setOtherLoanChecker2(!otherLoanChecker2);
  };

  var ED = selectedDate.split(/\D/g);
  var mdDate = [ED[2], ED[1], ED[0]].join("-");

  const [showHide, setShowHide] = useState({
    showotherPurposeSection: false,
  });
  const { showotherPurposeSection } = showHide;
  const allpurpose = [];
  loanreason.map((purpose) =>
    allpurpose.push({
      loanReasonId: purpose._id,
      label: purpose.loanPurpose,
      value: purpose.loanPurpose,
    })
  );

  const [purpose, getloanPurposeData] = useState();
  const [loanReasonId, setloanPurposeID] = useState();
  const [loanPurpose, setloanPurposeName] = useState();

  const onPurposeChange = (e) => {
    getloanPurposeData(e);
    setloanPurposeID(e.loanReasonId ? e.loanReasonId : "");
    setloanPurposeName(e.value ? e.value : "");
    if (e) {
      setFormData({
        ...formData,
        loanPurpose: e,
      });
    }
    if (e.value === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
    }
  };

  const [showHideBank, setbankShowHide] = useState({
    showbankotherPurposeSection: false,
  });
  const { showbankotherPurposeSection } = showHideBank;

  const allbankpurpose = [];

  loanreason.map((otherloanpurposes) =>
    allbankpurpose.push({
      bankloanpuroseId: otherloanpurposes._id,
      label: otherloanpurposes.loanPurpose,
      value: otherloanpurposes.loanPurpose,
    })
  );

  const [takeloandate, setloantakenDate] = useState("");
  const [internaltakeloandate, setinternalloantakenDate] = useState("");
  const onloantakenDateChange = (e) => {
    setloantakenDate(e.target.value);
  };

  const onloantakenDate = (e) => {
    setinternalloantakenDate(e.target.value);
  };

  const [otherloanpurposes, getbankpurposeData] = useState();
  const [bankloanpuroseId, setbankloanpuroseId] = useState();
  const [bankloanPurpose, setbankloanPurpose] = useState();

  const onBankLoanPurposeChange = (e) => {
    getbankpurposeData(e);
    setbankloanpuroseId(e.bankloanpuroseId ? e.bankloanpuroseId : "");
    setbankloanPurpose(e.value ? e.value : "");
    if (e) {
      setFormData({
        ...formData,
        bankloanPurpose: e,
      });
    }
    if (e.value === "Other") {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: true,
      });
    } else {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: false,
      });
    }
  };

  //validation for select otherLoanType
  const [errors, setErrors] = useState({
    otherLoanChecker: false,
    otherLoanCheckError: false,
    otherLoanErrorStyle: {},
  });
  const { otherLoanChecker, otherLoanErrorStyle } = errors;

  const checkError = () => {
    if (!otherLoanChecker) {
      setErrors({
        ...errors,
        otherLoanErrorStyle: { color: "#F00" },
      });
      return false;
    }
    return true;
  };

  const [error, setError] = useState({
    otherLoanTypeIdChecker: false,
    otherLoanTypeIdErrorStyle: {},
    bankIdChecker: false,
    bankIdErrorStyle: {},
  });
  const {
    otherLoanTypeIdChecker,
    otherLoanTypeIdErrorStyle,
    bankIdChecker,
    bankIdErrorStyle,
  } = error;

  const [otherLoanType, getOtherLoanTypeData] = useState("");
  const [otherLoanTypeId, setOtherLoanTypeId] = useState("");
  const [otherLoanTypeName, setOtherLoanTypeName] = useState("");

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  //this is for dropdown of otherloan type
  const [dataAvaiable, setdataAvaiable] = useState("");
  const onotherLoanTypeChange = (e) => {
    const isalreadyAvaiable =
      isloansanctioned &&
      isloansanctioned.filter(
        (ele) => ele.otherLoanTypeId === e.otherLoanTypeId
      );
    //might be a problrm
    if (isalreadyAvaiable.length > 0) {
      setdataAvaiable("true");
    } else {
      setdataAvaiable("false");
    }
    setErrors({
      ...errors,
      otherLoanChecker: true,
      otherLoanErrorStyle: { color: "#000" },
    });
    setError({
      ...error,
      otherLoanTypeIdChecker: true,
      otherLoanTypeIdErrorStyle: { color: "#000" },
    });
    getOtherLoanTypeData(e);
    setOtherLoanTypeId(e.otherLoanTypeId ? e.otherLoanTypeId : "");
    setOtherLoanTypeName(e.value ? e.value : "");
  };
  //For setting mindate as todays date

  //OLR GRID START==============================================================================
  const [DurationError, setDurationError] = useState({
    color: "black",
  });

  const [AddedDetailsOLR, AddDetailsOLR] = useState([]);
  const onAddOLR = (e) => {
    e.preventDefault();

    if (otherloanSanctionedAmtt > 0 && otherLoanDuration <= 0) {
      setDurationError({ color: "red" });
    } else {
      setDurationError({ color: "black" });

      if (checkError()) {
        if (otherLoanType) {
          const listOLR = AddedDetailsOLR.filter(
            (AddDetails) =>
              AddDetails.otherLoanTypeId === otherLoanType.otherLoanTypeId
          );
          if (listOLR.length === 0) {
            const addData = {
              otherLoanType: otherLoanType.value,
              otherLoanTypeId: otherLoanType.otherLoanTypeId,
              otherLoanReasonId: otherloanpurposes
                ? otherloanpurposes.bankloanpuroseId
                : null,
              otherloanpurpose: otherloanpurposes
                ? otherloanpurposes.value
                : "",
              otherLoanOtherPurpose: otherLoanReasons,
              indSubsidyDistributed: indSubsidyDistributed
                ? parseFloat(Number(indSubsidyDistributed).toFixed(2))
                : 0,
              // other loan
              otherloanPayablePrincipal: otherloanPayablePrincipalAmt
                ? parseFloat(Number(otherloanPayablePrincipalAmt).toFixed(2))
                : 0,
              otherloanPayableInterst: otherloanPayableInterstAmt
                ? parseFloat(Number(otherloanPayableInterstAmt).toFixed(2))
                : 0,
              otherLoanDuration: otherLoanDuration
                ? Number(otherLoanDuration)
                : 0,
              otherLoanTakenAmt: otherloanSanctionedAmtt
                ? parseFloat(Number(otherloanSanctionedAmtt).toFixed(2))
                : 0,
              otherLoanChecker: OTHloanChecker,
              otherLoanTakenDate: takeloandate,
              //////////////////Loan Repayment for other
              otherLoanPaid: Number(otherLoanPaid)
                ? parseFloat(Number(otherLoanPaid).toFixed(2))
                : 0,
              otherInterestPaid: Number(otherInterestPaid)
                ? parseFloat(Number(otherInterestPaid).toFixed(2))
                : 0,
              otherTotalPaid: OTHloanChecker
                ? (Number(otherLoanPaid)
                    ? parseFloat(Number(otherLoanPaid).toFixed(2))
                    : 0) +
                  (Number(otherInterestPaid)
                    ? parseFloat(Number(otherInterestPaid).toFixed(2))
                    : 0)
                : 0,
              // parseFloat(
              //     (
              //       Number(otherloanSanctionedAmtt) -
              //       Number(otherRemainingLoan)
              //     ).toFixed(2)
              //   )
              // : 0,
              paidDate: meetingHeldOnDate ? meetingHeldOnDate : "",
              paidMonth: meetingHeldOnDate && meetingHeldOnDate.slice(5, 7),
              paidYear: meetingHeldOnDate && meetingHeldOnDate.slice(0, 4),
            };
            setFormData({
              ...formData,
              otherLoanType: "",
              otherLoanTypeId: "",
              otherloanSanctionedAmt: "",
              allbankpurpose: "",
              otherLoanReasons: "",
              indSubsidyDistributed: "",
              otherloanPayablePrincipalAmt: "",
              otherloanPayableInterstAmt: "",
              otherLoanDuration: "",
              otherLoanPaid: "",
              otherInterestPaid: "",
              otherLoanChecker: "",
              OTHloanStatus: "",
              otherRemainingLoan: "",
              OTHloanChecker: false,
            });
            setloantakenDate("");
            let temp = [];
            temp.push(...AddedDetailsOLR, addData);
            AddDetailsOLR(temp);
            setOtherLoanChecker2(false);
            getOtherLoanTypeData("");
            getbankpurposeData("");
          }
        }
        return true;
      }
    }
  };

  useEffect(() => {}, [NewInternalLoan], [paymentArray]);

  /////tab 2 operations start////////////////////////////////
  const [selectedEditLoan, setSelectedEditLoan] = useState({});
  const [showPayment, setShowpayment] = useState(false);
  const [internalTypeName, setInternalTypeName] = useState("");
  const [paymentArray, setPaymentArray] = useState([]);
  const [NewInternalLoan, setNewInternalLoan] = useState([]);

  const internalDelete = (loan) => {
    setNewInternalLoan(
      NewInternalLoan.filter((ele) => ele.intLoanType != loan.intLoanType)
    );
  };

  const onDeleteInternalPayment = (data) => {
    setPaymentArray(
      paymentArray.filter((e) => e.internalLoanType != data.internalLoanType)
    );
    // setShowpayment(true)
  };

  const clickPay = (loanName) => {
    setShowpayment(true);
    setInternalTypeName(loanName);
  };

  /////tab 2 operations end/////////////////////////////////

  const onAddOLRSingle = (e) => {
    e.preventDefault();

    if (otherloanSanctionedAmtt > 0 && otherLoanDuration <= 0) {
      setDurationError({ color: "red" });
    } else {
      setDurationError({ color: "black" });

      if (OTHloanChecker) {
        const addData = {
          otherLoanType: activeOtherLoan[0] ? activeOtherLoan[0].value : "",
          otherLoanTypeId: activeOtherLoan[0]
            ? activeOtherLoan[0].otherLoanTypeId
            : null,
          otherTotalPaid: parseFloat(
            (
              Number(otherloanSanctionedAmt) - Number(otherRemainingLoan)
            ).toFixed(2)
          ),

          otherLoanReasonId: otherloanpurposes
            ? otherloanpurposes.bankloanpuroseId
            : null,
          otherloanpurpose: otherloanpurposes ? otherloanpurposes.value : "",
          otherLoanOtherPurpose: otherLoanReasons,
          indSubsidyDistributed: indSubsidyDistributed
            ? parseFloat(Number(indSubsidyDistributed).toFixed(2))
            : 0,
          otherLoanTakenDate: takeloandate,
          //
          otherloanPayablePrincipal: otherloanPayablePrincipalAmt
            ? parseFloat(Number(otherloanPayablePrincipalAmt).toFixed(2))
            : 0,
          otherloanPayableInterst: otherloanPayableInterstAmt
            ? parseFloat(Number(otherloanPayableInterstAmt).toFixed(2))
            : 0,
          otherLoanDuration: Number(otherLoanDuration)
            ? Number(otherLoanDuration)
            : 0,

          otherLoanTakenAmt: parseFloat(
            Number(otherloanSanctionedAmtt).toFixed(2)
          ),
          otherLoanChecker: OTHloanChecker,

          otherTotalPaid: parseFloat(
            (
              Number(otherloanSanctionedAmtt) - Number(otherRemainingLoan)
            ).toFixed(2)
          ),
          otherLoanPaid: Number(otherLoanPaid)
            ? parseFloat(Number(otherLoanPaid).toFixed(2))
            : 0,
          otherInterestPaid: Number(otherInterestPaid)
            ? parseFloat(Number(otherInterestPaid).toFixed(2))
            : 0,
          paidDate: meetingHeldOnDate ? meetingHeldOnDate : "",
          paidMonth: meetingHeldOnDate && meetingHeldOnDate.slice(5, 7),
          paidYear: meetingHeldOnDate && meetingHeldOnDate.slice(0, 4),
        };
        let temp = [];
        temp.push(addData);
        setOtherLoanChecker2(!otherLoanChecker2);
        AddDetailsOLR(temp);
        return temp;
      } else {
        const addData = {
          otherLoanType: activeOtherLoan[0] ? activeOtherLoan[0].value : "",
          otherLoanTypeId: activeOtherLoan[0]
            ? activeOtherLoan[0].otherLoanTypeId
            : null,

          otherloanpurpose: otherloanpurposes ? otherloanpurposes.value : "",
          otherLoanOtherPurpose: otherLoanReasons,
          indSubsidyDistributed: indSubsidyDistributed
            ? parseFloat(Number(indSubsidyDistributed).toFixed(2))
            : 0,
          // externalNominee: externalNominee,
          //
          otherloanPayablePrincipal: otherloanPayablePrincipalAmt
            ? parseFloat(Number(otherloanPayablePrincipalAmt).toFixed(2))
            : 0,
          otherloanPayableInterst: otherloanPayableInterstAmt
            ? parseFloat(Number(otherloanPayableInterstAmt).toFixed(2))
            : 0,
          otherLoanTakenAmt: parseFloat(
            Number(otherloanSanctionedAmtt).toFixed(2)
          ),
          otherLoanDuration: otherLoanDuration ? Number(otherLoanDuration) : 0,
          otherLoanChecker: OTHloanChecker,
          otherLoanTakenDate: takeloandate,
        };
        let temp = [];
        temp.push(addData);
        AddDetailsOLR(temp);
        return temp;
      }
    }
  };

  const onRemoveChangeOLR = (otherLoanTypeId) => {
    const removeList = AddedDetailsOLR.filter(
      (AddDetails) => AddDetails.otherLoanTypeId !== otherLoanTypeId
    );
    AddDetailsOLR(removeList);
  };

  //OLR GRID END================================================================================

  const onSubmit = (e) => {
    e.preventDefault();

    let OLRes = [];
    if (activeOtherLoan.length <= 1) {
      OLRes = onAddOLRSingle(e);
    } else {
      OLRes = AddedDetailsOLR;
    }

    let otherLoanTakenAmtSum = 0,
      otherInterestPaidSum = 0,
      indSubsidyDistributedSum = 0,
      otherloanPayablePrincipalAmtSum = 0,
      otherloanPayableInterstAmtSum = 0,
      otherLoanPaidSum = 0,
      otherTotalPaidSum = 0;

    OLRes.map((AddDetail) => {
      otherLoanTakenAmtSum += parseFloat(AddDetail.otherLoanTakenAmt || 0);
      otherLoanPaidSum += parseFloat(AddDetail.otherLoanPaid || 0);
      otherInterestPaidSum += parseFloat(AddDetail.otherInterestPaid || 0);
      otherTotalPaidSum += parseFloat(AddDetail.otherTotalPaid || 0);
      indSubsidyDistributedSum += parseFloat(
        AddDetail.indSubsidyDistributed || 0
      );
      //otherloan
      otherloanPayablePrincipalAmtSum += parseFloat(
        AddDetail.otherloanPayablePrincipalAmt || 0
      );
      otherloanPayableInterstAmtSum += parseFloat(
        AddDetail.otherloanPayableInterstAmt || 0
      );
    });

    var dateVal = new Date(meetingHeldOnDate);

    const olTotPaid =
      OLRes.length > 0 ? OLRes.map((ele) => Number(ele.otherTotalPaid)) : 0;

    const finalTotal = olTotPaid && olTotPaid.reduce((acc, cur) => acc + cur);
    const finalData = {
      savingAmt: batchSavingAmt && batchSavingAmt ? batchSavingAmt : 0,
      subscriptionAmt:
        batchSubscriptionAmt && batchSubscriptionAmt ? batchSubscriptionAmt : 0,
      otherContribution:
        otherContribution && otherContribution ? otherContribution : "",
      meetingHeldOnDate:
        meetingHeldOnDate && meetingHeldOnDate ? meetingHeldOnDate : "",
      membershipFees: membershipFees && membershipFees ? membershipFees : 0,
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: membersBatch && membersBatch._id,
      memberName: membersBatch && membersBatch.memberName,
      meetingMonth: dateVal.getMonth() + 1,
      meetingYear: dateVal.getFullYear(),

      otherIncome: otherIncome && otherIncome ? otherIncome : 0,
      memSeedMoney: memSeedMoney && memSeedMoney ? memSeedMoney : 0,
      memSecurityDepositToShg:
        memSecurityDepositToShg & memSecurityDepositToShg
          ? memSecurityDepositToShg
          : 0,
      loanReasonId: loanReasonId ? loanReasonId : null,
      loanPurpose: loanPurpose && loanPurpose ? loanPurpose : "",
      otherPurpose: otherPurpose && otherPurpose ? otherPurpose : "",
      savingWithdrawals:
        savingWithdrawals && savingWithdrawals ? savingWithdrawals : 0,
      dividendDistributed:
        dividendDistributed && dividendDistributed ? dividendDistributed : 0,
      securityDeposit: securityDeposit && securityDeposit ? securityDeposit : 0,
      otherLoanTakenAmt: otherLoanTakenAmtSum
        ? parseFloat(Number(otherLoanTakenAmtSum).toFixed(2))
        : 0,

      subsidyDistributed: indSubsidyDistributedSum
        ? parseFloat(Number(indSubsidyDistributedSum).toFixed(2))
        : 0, //sum
      /////////other loan
      otherLoanPaid: otherLoanPaidSum
        ? parseFloat(otherLoanPaidSum.toFixed(2))
        : 0, //sum
      otherInterestPaid: otherInterestPaidSum
        ? parseFloat(Number(otherInterestPaidSum).toFixed(2))
        : 0, //sum

      internalLoanTakenDate: internaltakeloandate ? internaltakeloandate : "",
      /////////////
      otherLoan: OLRes,
      mtEnteredById: user && user._id ? user._id : "",
      mtEnteredByName: user && user.userName ? user.userName : "",
      institutionId:
        selectedBatchData[0] && selectedBatchData[0].institutionId
          ? selectedBatchData[0].institutionId
          : "",
      loanPayablePrincipal:
        loanPayablePrincipal && loanPayablePrincipal
          ? parseFloat(Number(loanPayablePrincipal).toFixed(2))
          : 0,
      loanPayableInterest:
        loanPayableInterest && loanPayableInterest
          ? parseFloat(Number(loanPayableInterest).toFixed(2))
          : 0,
      internalDuration:
        internalDuration && internalDuration ? internalDuration : 0,

      loanTakenAmt: NewInternalLoan.reduce(
        (acu, cur) => (acu += Number(cur.loanPayablePrincipal)),
        0
      ),
      loanPaid: paymentArray.reduce(
        (acu, cur) => (acu += Number(cur.internalPrincipalPaid)),
        0
      ),
      interestPaid: paymentArray.reduce(
        (acu, cur) => (acu += Number(cur.internalIntrestPaid)),
        0
      ),
      totalLoanPaid:
        // rl === 0 ? 0 :
        loanChecker ? Number(TotalPayableAmt) - Number(remainingLoan) : 0,
      //  attendance: attendance && attendance ? attendance : 0,

      loanStatus: "Active",

      //////loan sanctioned
      batchId:
        selectedBatchData[0] && selectedBatchData[0]._id
          ? selectedBatchData[0]._id
          : "",
      memberId: membersBatch && membersBatch._id ? membersBatch._id : "",
      memberName:
        membersBatch && membersBatch.memberName ? membersBatch.memberName : "",
      batchMeetingMonth: dateVal.getMonth() + 1,
      batchMeetingYear: dateVal.getFullYear(),
      selectedDate: selectedDate && selectedDate ? selectedDate : "",

      loanReasonId: loanReasonId ? loanReasonId : null,
      loanPurpose: loanPurpose && loanPurpose ? loanPurpose : "",
      otherPurpose: otherPurpose && otherPurpose ? otherPurpose : "",
      loanSanctionedEnteredById: user && user._id ? user._id : "",
      loanSanctionedEnteredByName: user && user.userName ? user.userName : "",
      institutionId:
        selectedBatchData[0] && selectedBatchData[0].institutionId
          ? selectedBatchData[0].institutionId
          : "",
      loanSanctionedAmt:
        TotalPayableAmt && TotalPayableAmt ? TotalPayableAmt : 0,

      loanPayablePrincipal:
        loanPayablePrincipal && loanPayablePrincipal ? loanPayablePrincipal : 0,

      loanPayableInterest:
        loanPayableInterest && loanPayableInterest ? loanPayableInterest : 0,
      internalLoan: NewInternalLoan,
      internalLoanPayment: paymentArray,
      loanRepayment: {
        loanPrincipalPaid: Number(PrincipalPaid) ? Number(PrincipalPaid) : 0,
        loanInterestPaid: Number(InterestPaid) ? Number(InterestPaid) : 0,
        totalLoanPaid: Number(PrincipalPaid) + Number(InterestPaid),
        penalty: 0,
        paidDate: meetingHeldOnDate ? meetingHeldOnDate : "",
        paidMonth: meetingHeldOnDate && meetingHeldOnDate.slice(5, 7),
        paidYear: meetingHeldOnDate && meetingHeldOnDate.slice(0, 4),

        ////other loan
      },
    };

    AddMemberTransaction(finalData);

    onMemberMeetingOPModalChanges(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <Tabs
        selectedIndex={tabIndex}
        //   onSelect={(index) => setTabIndex(index)}
      >
        <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
          <TabList>
            <Tab tabfor="0">Basic</Tab>
            <Tab tabfor="1">Expense</Tab>
            <Tab tabfor="2">Internal Loan</Tab>
            <Tab tabfor="3">Other Loan</Tab>
          </TabList>
        </div>

        <TabPanel tabId="0">
          <form onSubmit={(e) => NextBackBtn(1)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">Meeting Date : {mdDate}</label>
              </div>

              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">
                  Member Name :{memberName}{" "}
                </label>
              </div>
            </div>

            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label className="label-control">
                  Total Savings Amount* :{" "}
                </label>
                <input
                  type="number"
                  name="batchSavingAmt"
                  value={batchSavingAmt}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                  required={true}
                />
              </div>
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">
                  Total Subscription Amount* :
                </label>

                <input
                  type="number"
                  name="batchSubscriptionAmt"
                  value={batchSubscriptionAmt}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                  required={true}
                />
              </div>
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">
                  Other Contribution Amount :
                </label>
                <input
                  type="number"
                  name="otherContribution"
                  value={otherContribution}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">Other Income :</label>
                <input
                  type="number"
                  name="otherIncome"
                  value={otherIncome}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">Seed Money :</label>
                <input
                  type="number"
                  name="memSeedMoney"
                  value={memSeedMoney}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label className="label-control">
                  Security Deposit Member To SHG :
                </label>
                <input
                  type="number"
                  name="memSecurityDepositToShg"
                  value={memSecurityDepositToShg}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">Membership Fees :</label>
                <input
                  type="number"
                  name="membershipFees"
                  value={membershipFees}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>

            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="1">
          <form onSubmit={(e) => NextBackBtn(2)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">Meeting Date : {mdDate}</label>
              </div>

              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">
                  Member Name : {memberName}{" "}
                </label>
              </div>
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">Savings Withdrawal :</label>
                  <input
                    type="number"
                    name="savingWithdrawals"
                    value={savingWithdrawals}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Dividend Distributed :
                  </label>
                  <input
                    type="number"
                    name="dividendDistributed"
                    value={dividendDistributed}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Security Deposit SHG To Member:
                  </label>
                  <input
                    type="number"
                    name="securityDeposit"
                    value={securityDeposit}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>

            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(0)}
              >
                Previous
              </button>
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="2">
          <form onSubmit={(e) => NextBackBtn(3)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">Meeting Date : {mdDate}</label>
              </div>

              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">
                  Member Name : {memberName}
                </label>
              </div>
            </div>
            <div className=" body-inner no-padding table-responsive">
              <table
                className="tabllll table table-bordered table-striped table-hover mt-2"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>IL Type</th>
                    <th>Sanctioned Principal</th>
                    <th>Total Principal Paid</th>
                    <th>Remaining Loan Principal</th>
                    {/* <th>Duration</th> */}
                    <th>Operation</th>
                  </tr>
                </thead>
                <tbody>
                  {NewInternalLoan &&
                    NewInternalLoan.map((loan, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{loan.intLoanType}</td>
                          <td>{loan.loanPayablePrincipal}</td>
                          <td>0</td>
                          <td>{loan.loanPayablePrincipal}</td>
                          {/* <td>{loan.internalDuration}</td> */}
                          <td>
                            <span
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => {
                                internalDelete(loan);
                              }}
                            >
                              <img
                                src={require("../../static/images/delete.png")}
                                alt="help"
                                id="img_tool_admin"
                                className="pass_admin_help_icon_question "
                                style={{ marginTop: "-14px" }}
                              />
                            </span>

                            <span
                              className="mt-5 ml-3 "
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                clickPay(loan.intLoanType);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-cash-coin"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                                />
                                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                              </svg>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {showPayment ? (
              <>
                <LoanPayment
                  setShowpayment={setShowpayment}
                  internalTypeName={internalTypeName}
                  setPaymentArray={setPaymentArray}
                  paymentArray={paymentArray}
                  meetingHeldOnDate={meetingHeldOnDate}
                />
              </>
            ) : (
              <ShgInternalLoan
                Internalonly={Internalonly}
                setNewInternalLoan={setNewInternalLoan}
                internalLoanData={selectedEditLoan}
                NewInternalLoan={NewInternalLoan}
                allpurpose={allpurpose}
              />
            )}

            <div className=" body-inner no-padding table-responsive">
              <label
                className="form-control-label"
                style={{ marginTop: "10px" }}
              >
                Paid Loan Details:{" "}
              </label>
              <table
                className="tabllll table table-bordered table-striped table-hover mt-2"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>IL Type</th>
                    <th>Paid Principal</th>
                    <th>Paid Interest</th>
                    <th>Penalty</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentArray &&
                    paymentArray.map((paid, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{paid.internalLoanType}</td>
                          <td>{paid.internalPrincipalPaid}</td>
                          <td>{paid.internalIntrestPaid}</td>
                          <td>{paid.penalty}</td>
                          <td>
                            <img
                              src={require("../../static/images/delete.png")}
                              onClick={() => onDeleteInternalPayment(paid)}
                              alt="help"
                              id="img_tool_admin"
                              className="pass_admin_help_icon_question"
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(1)}
              >
                Previous
              </button>
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="3">
          {activeOtherLoan.length > 1 ? (
            <>
              {/* <form onSubmit={(e) => onSubmit(e)}> */}
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Meeting Date : {mdDate}
                  </label>
                </div>

                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Member Name :{memberName}{" "}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                  <label className="label-control">Other Loan Types :</label>
                  <Select
                    name="otherLoanTypes"
                    options={activeOtherLoan}
                    isSearchable={true}
                    value={otherLoanType}
                    placeholder="Select Mode"
                    onChange={(e) => onotherLoanTypeChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Other Loan Taken Date :
                  </label>
                  <br />
                  <input
                    type="date"
                    placeholder="dd/mm/yyyy"
                    className="form-control cpp-input datevalidation"
                    name="takeloandate"
                    value={takeloandate}
                    onChange={(e) => onloantakenDateChange(e)}
                    style={{
                      width: "75%",
                    }}
                  />
                </div>
                {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Other Loan Taken Amount :
                  </label>
                  <input
                    type="number"
                    name="otherloanSanctionedAmt"
                    value={otherloanSanctionedAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div> */}
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Loan Sanctioned Principal :{" "}
                  </label>
                  <input
                    type="number"
                    name="otherloanPayablePrincipalAmt"
                    value={otherloanPayablePrincipalAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Loan Sanctioned Interest:{" "}
                  </label>
                  <input
                    type="number"
                    name="otherloanPayableInterstAmt"
                    value={otherloanPayableInterstAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Sanctioned Amount :{" "}
                  </label>
                  <input
                    type="number"
                    name="otherloanSanctionedAmtt"
                    value={otherloanSanctionedAmtt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Duration (In Months) :{" "}
                  </label>
                  <input
                    type="number"
                    name="otherLoanDuration"
                    value={otherLoanDuration}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Loan Purpose :</label>
                  <Select
                    name="bankloanPurpose"
                    options={allbankpurpose}
                    isSearchable={true}
                    value={otherloanpurposes}
                    placeholder="Select Purpose"
                    onChange={(e) => onBankLoanPurposeChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>

                {showbankotherPurposeSection && (
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      Other Loan Purpose :
                    </label>
                    <input
                      type="text"
                      name="otherLoanReasons"
                      value={otherLoanReasons}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                )}
                {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Other Loan Interest Paid :
                  </label>
                  <input
                    type="number"
                    name="otherLoanInterest"
                    value={otherLoanInterest}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div> */}

                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Subsidy Distributed :</label>
                  <input
                    type="number"
                    name="indSubsidyDistributed"
                    value={indSubsidyDistributed}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Other Loan Paid :{" "}
                    {(
                      Number(otherloanSanctionedAmt) -
                      Number(otherRemainingLoan)
                    ).toFixed(2)}
                  </label>
                </div> */}

                {hideFieldOther === "true" ? (
                  <>
                    {" "}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Has Repayment ? :
                        </label>
                        <input
                          style={{
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                            display: "block",
                          }}
                          type="checkbox"
                          id="Unconfirmed"
                          checked={OTHloanChecker}
                          onChange={handleOnOtherChange2}
                        />
                      </div>
                    </div>
                    {OTHloanChecker && (
                      <>
                        <div className="row col-lg-12 col-md-9 col-sm-9 col-12 py-4 my-1">
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Remaining Loan:
                            </label>
                            <input
                              type="text"
                              name="otherRemainingLoan"
                              value={otherRemainingLoan}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Total Paid :{" "}
                              {/* {(
                            Number(otherloanSanctionedAmt) -
                            Number(otherRemainingLoan)
                          ).toFixed(2)} */}
                            </label>
                            <input
                              type="number"
                              // name="totalLoanPaid"
                              value={
                                // Number(otherRemainingLoan) === 0
                                //   ? 0
                                //   :
                                Number(otherloanSanctionedAmtt) -
                                Number(otherRemainingLoan)
                              }
                              className="form-control"
                              disabled={true}
                            />
                          </div>
                          <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Principal Paid :
                            </label>
                            <input
                              type="number"
                              name="otherLoanPaid"
                              value={otherLoanPaid}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Interest Paid :
                            </label>
                            <input
                              type="number"
                              name="otherInterestPaid"
                              value={otherInterestPaid}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                        </div>

                        <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                          {/* <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                        <label className="label-control">Total Paid :</label>
                        <input
                          type="number"
                          name="TotalOtherloanPaid"
                          value={TotalOtherloanPaid}
                          className="form-control"
                          disabled={true}
                        />
                      </div> */}
                          {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Remaining Loan :
                        </label>
                        <input
                          type="text"
                          value={
                            prevData[0] &&
                            Number(prevData[0].otherRemainingLoan).toFixed(2)
                          }
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          disabled
                        />
                      </div> */}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <button
                    onClick={(e) => onAddOLR(e)}
                    className="btn sub_form btn_continue blackbrd Save float-right"
                  >
                    ADD
                  </button>
                </div>
              </div>
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                <div className=" body-inner no-padding  table-responsive">
                  <table
                    className="tabllll table table-bordered table-striped table-hover mt-2"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>OL Type</th>
                        <th>OL Sanctioned Principal</th>
                        <th>OL Sanctioned Interest</th>
                        <th>OL Total Sanctioned Amount</th>
                        <th>Duration</th>
                        <th>OL Principal Paid</th>
                        <th>OL Intrest Paid</th>
                        <th>OL Total Paid</th>
                        <th>OL Taken Date</th>
                        <th>OL Purpose</th>
                        <th>Subsidy Distributed</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {AddedDetailsOLR &&
                        AddedDetailsOLR.map((AddDetail, idx) => {
                          var otherLoanDate = AddDetail.otherLoanTakenDate;
                          var ED = otherLoanDate.split(/\D/g);
                          otherLoanDate = [ED[2], ED[1], ED[0]].join("-");
                          return (
                            <tr key={idx}>
                              <td>{AddDetail.otherLoanType}</td>
                              <td>{AddDetail.otherloanPayablePrincipal}</td>
                              <td>{AddDetail.otherloanPayableInterst}</td>
                              <td>{AddDetail.otherLoanTakenAmt}</td>
                              <td>{AddDetail.otherLoanDuration}</td>
                              <td>{AddDetail.otherLoanPaid}</td>
                              <td>{AddDetail.otherInterestPaid}</td>
                              <td>{AddDetail.otherTotalPaid}</td>
                              <td>{otherLoanDate}</td>
                              <td>
                                {AddDetail.otherloanpurpose}{" "}
                                {AddDetail.otherLoanOtherPurpose
                                  ? AddDetail.otherLoanOtherPurpose
                                  : ""}
                              </td>
                              <td>{AddDetail.indSubsidyDistributed}</td>
                              <td>
                                <img
                                  className="img_icon_size log"
                                  onClick={() =>
                                    onRemoveChangeOLR(AddDetail.otherLoanTypeId)
                                  }
                                  src={require("../../static/images/close-buttonRed.png")}
                                  alt="Remove"
                                  title="Remove"
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Other Loan Taken Amount :
                  </label>
                  <input
                    type="number"
                    name="otherloanSanctionedAmt"
                    value={otherloanSanctionedAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div> */}
                {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Other Loan Interest Paid :
                  </label>
                  <input
                    type="number"
                    name="otherLoanInterest"
                    value={otherLoanInterest}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div> */}
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Loan Sanctioned Principal :{" "}
                  </label>
                  <input
                    type="number"
                    name="otherloanPayablePrincipalAmt"
                    value={otherloanPayablePrincipalAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Loan Sanctioned Interest:{" "}
                  </label>
                  <input
                    type="number"
                    name="otherloanPayableInterstAmt"
                    value={otherloanPayableInterstAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Sanctioned Amount :{" "}
                  </label>
                  <input
                    type="number"
                    name="otherloanSanctionedAmtt"
                    value={otherloanSanctionedAmtt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Duration (In Months) :{" "}
                  </label>
                  <input
                    type="number"
                    name="otherLoanDuration"
                    value={otherLoanDuration}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Other Loan Taken Date :
                  </label>
                  <br />
                  <input
                    type="date"
                    placeholder="dd/mm/yyyy"
                    className="form-control cpp-input datevalidation"
                    name="takeloandate"
                    value={takeloandate}
                    onChange={(e) => onloantakenDateChange(e)}
                    style={{
                      width: "75%",
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Loan Purpose :</label>
                  <Select
                    name="bankloanPurpose"
                    options={allbankpurpose}
                    isSearchable={true}
                    value={otherloanpurposes}
                    placeholder="Select Purpose"
                    onChange={(e) => onBankLoanPurposeChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
                {showbankotherPurposeSection && (
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      Other Loan Purpose :
                    </label>
                    <input
                      type="text"
                      name="otherLoanReasons"
                      value={otherLoanReasons}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                )}

                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Subsidy Distributed :</label>
                  <input
                    type="number"
                    name="indSubsidyDistributed"
                    value={indSubsidyDistributed}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">Has Repayment ? :</label>
                    <input
                      style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "50%",
                        display: "block",
                      }}
                      type="checkbox"
                      id="Unconfirmed"
                      checked={OTHloanChecker}
                      onChange={handleOnOtherChange2}
                    />
                  </div>
                </div>
                {OTHloanChecker && (
                  <>
                    <div className="row col-lg-12 col-md-9 col-sm-9 col-12 py-4 my-1">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Remaining Loan:
                        </label>
                        <input
                          type="text"
                          name="otherRemainingLoan"
                          value={otherRemainingLoan}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Total Paid :{" "}
                          {/* {(
                            Number(otherloanSanctionedAmt) -
                            Number(otherRemainingLoan)
                          ).toFixed(2)} */}
                          <input
                            type="number"
                            // name="totalLoanPaid"
                            value={
                              // Number(otherRemainingLoan) === 0
                              //   ? 0
                              //   :
                              Number(otherloanSanctionedAmtt) -
                              Number(otherRemainingLoan)
                            }
                            className="form-control"
                            disabled={true}
                          />
                        </label>
                      </div>
                      <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Principal Paid :
                        </label>
                        <input
                          type="number"
                          name="otherLoanPaid"
                          value={otherLoanPaid}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Interest Paid :
                        </label>
                        <input
                          type="number"
                          name="otherInterestPaid"
                          value={otherInterestPaid}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                    </div>

                    <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                      {/* <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                        <label className="label-control">Total Paid :</label>
                        <input
                          type="number"
                          name="TotalOtherloanPaid"
                          value={TotalOtherloanPaid}
                          className="form-control"
                          disabled={true}
                        />
                      </div> */}
                      {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Remaining Loan :
                        </label>
                        <input
                          type="text"
                          value={
                            prevData[0] &&
                            Number(prevData[0].otherRemainingLoan).toFixed(2)
                          }
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          disabled
                        />
                      </div> */}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>

            <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
              {loading ? (
                <button
                  className="btn sub_form btn_continue blackbrd Save float-right"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <input
                  type="submit"
                  name="Submit"
                  value="Submit"
                  onClick={(e) => onSubmit(e)}
                  className="btn sub_form btn_continue Save float-right"
                />
              )}
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(2)}
              >
                Previous
              </button>
            </div>
          </div>
          {/* </form> */}
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

AddMembermeetingOpModal.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getParticularLoanReason,
  AddMemberTransaction,
  AddLoanSanctionedDetails,
  getMemberPrevMeetingData,
  getActiveOtherLoanType,
  // getActiveBankData,
  // getSanctionedData,
})(AddMembermeetingOpModal);
