import React, { useState, Fragment } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getShgMembers, getBatchInfo } from "../../actions/shg";
import Spinner from "../layout/Spinner";
import AddMembermeetingOpModal from "./AddMembermeetingOpModal";

const MemberwiseTransfer = ({
  auth: { isAuthenticated, user, users },
  shg: { shgMembers, isSet },
  getBatchInfo,
  batchId,
  getShgMembers,
}) => {
  const [showMemberMeetingOPModal, setMemberMeetingOPModal] = useState(false);
  const handleMemberMeetingOPModalClose = () => setMemberMeetingOPModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (membersdata, idx) => {
    setMemberMeetingOPModal(true);
    setUserData(membersdata);
  };
  const onMemberMeetingOPModalChanges = (e) => {
    if (e) {
      handleMemberMeetingOPModalClose();
    }
  };
  let selectedBatchIdData = JSON.parse(localStorage.getItem("selBatchIdData"));
  let selBatchId = JSON.parse(localStorage.getItem("selBatchId"));
  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const [userDatas, setUserDatas] = useState(null);
  const onUpdate = (shgMembers, idx) => {
    setShowDeactiveModal(true);
    setUserDatas(shgMembers);
  };
  const onMemberMeetingModalChange = (e) => {
    if (e) {
      handleMemberMeetingModalClose();
    }
  };
  const [showMemberMeetingDetailsModal, setMemberMeetingDetailModal] = useState(
    false
  );
  const handleMemberMeetingModalClose = () =>
    setMemberMeetingDetailModal(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const onClickHandler = () => {
    localStorage.removeItem("selBatchInfo");
    const finalData = {
      batchId: selBatchId.batchId,
    };
    getBatchInfo(finalData);
    setSubmitted(true);
  };
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  var todayDateymd = yyyy + "-" + mm + "-" + dd;
  if (isSet && isSubmitted) {
    return <Redirect to="/add-member-details" />;
  }

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2>
            All Member Details of:&emsp;
            {selBatchId && selBatchId.batchName}
          </h2>
          <hr />
        </div>

        <section className="sub_reg">
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className="body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Member Name</th>
                        <th>Op</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedBatchIdData &&
                        selectedBatchIdData.map((shgMembers, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{shgMembers.memberName}</td>

                              <td>
                                <img
                                  className="img_icon_size log"
                                  onClick={() => onEdit(shgMembers, idx)}
                                  src={require("../../static/images/edit_icon.png")}
                                  alt="Edit"
                                  title="Edit"
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <Link
                  className="btn sub_form reg_continue blackbrd"
                  to="/landing-page"
                >
                  Submit
                </Link>
              </section>
            </div>
          </div>
        </section>
        <Modal
          show={showMemberMeetingOPModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Add Opening Details</h3>
            </div>
            <div className="col-lg-2">
              <button
                onClick={handleMemberMeetingOPModalClose}
                className="close"
              >
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <AddMembermeetingOpModal
              membersBatch={userData}
              selectedDate={todayDateymd}
              onMemberMeetingOPModalChanges={onMemberMeetingOPModalChanges}
            />
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

MemberwiseTransfer.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getShgMembers: PropTypes.func.isRequired,
  getBatchInfo: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getShgMembers,
  getBatchInfo,
})(MemberwiseTransfer);
