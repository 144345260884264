import axios from "axios";

import {
  AUTH_ERROR,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  ALL_ACCESS_DETAILS,
  ALL_PERMISSION_DETAILS,
  ALL_ACTIVE_MODULE_DETAILS,
  ALL_ACTIVE_PERMISSIONS_DETAILS,
} from "./types";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

var linkPath = process.env.REACT_APP_BASE_URL;
// var linkPath = "";

export const getALLAccessDetails = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${linkPath}/api/setting/get-all-access-details`
    );
    // localStorage.setItem("allDesignationData", JSON.stringify(res.data));
    dispatch({
      type: ALL_ACCESS_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
//ADD

export const AddAccessDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/setting/add-access`, finalData, config);
    dispatch(getALLAccessDetails());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editAccessModuleDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/setting/edit-module-access`, finalData);
    dispatch(getALLAccessDetails());

    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const moduleAccessDeactive = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/setting/deactive-module-access-data`,
      finalData,
      config
    );
    dispatch(getALLAccessDetails());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getActiveModule = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${linkPath}/api/setting/get-all-active-module-details`
    );
    // localStorage.setItem("allDesignationData", JSON.stringify(res.data));
    dispatch({
      type: ALL_ACTIVE_MODULE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//ADD

export const AddPermissionDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/setting/add-permission`,
      finalData,
      config
    );
    dispatch(getALLPermissionDetails());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getALLPermissionDetails = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${linkPath}/api/setting/get-all-permission-details`
    );
    // localStorage.setItem("allDesignationData", JSON.stringify(res.data));
    dispatch({
      type: ALL_PERMISSION_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editPermissionModuleDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/setting/edit-permission`, finalData);
    dispatch(getALLPermissionDetails());

    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const PermissionDeactive = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/setting/deactive-permission-data`,
      finalData,
      config
    );
    dispatch(getALLPermissionDetails());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getActivePermissions = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${linkPath}/api/setting/get-all-active-permission-details`
    );
    // localStorage.setItem("allDesignationData", JSON.stringify(res.data));
    dispatch({
      type: ALL_ACTIVE_PERMISSIONS_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
