import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  addBatchMeetingData,
  editBatchMeetingData,
  addOnlyBatchWiseMeetingData,
  editOnlyBatchWiseMeetingData,
} from "../../../actions/shg";
import { useHistory } from "react-router-dom";

export const BatchBankDetTab = ({
  activeBank,
  batchData,
  NextBackBtn,
  formData,
  setFormData,
  setShowNewBankBalnce,

  addOnlyBatchWiseMeetingData,
  editOnlyBatchWiseMeetingData,
  selectedBatchData,
  fromPage,
  selectedDate,
  batchTransactionData,
  allRights,
  allPermission,
  auth: { isAuthenticated, user, users, loading },
}) => {
  const history = useHistory();

  let {
    cashInHand,
    //Expense
    internalBankExpense,
    travellingExpenses, //bc
    paidToMahasangha, //bc
    stationaryExpenses, //bc
    serviceCharges, //bc
    batchDividendDistributed, //bc
    otherExpenses, //bc
    loanGivenToOtherSHG, //bc
    batchSavingWithdrawals, //bc
    batchSecurityDeposit, //bc
    donationToOutsider, //bc
    otherLoanBatchTransaction, //Array //Exp batchOtherLoanInterestDonor subsidyDistributed otherLoanPaidToDonor   //Income otherLoanSanctionedToShg
    sdSHGToUnion,

    //Income
    batchSavingAmt, //bc
    batchSubAmt, //bc
    batchOtherContribution, //bc
    batchMembershipFees, //bc
    batchLoanPaid, //bc
    batchInterestPaid, //bc

    otherLoanPaidToShg, //only
    batchOtherLoanInterestShg, //only

    deactiveMemberSaving, //bc
    subsidyReceived, //bc
    batchOtherIncome, //bc
    seedMoney, //bc
    loanRepaidByOtherSHG, //bc
    batchSecurityDepositMemToShg, //bc
    sdUnionToSHG, //bc
  } = formData;

  const totExpenseCash =
    Number(internalBankExpense || 0) +
    Number(travellingExpenses ? travellingExpenses.cash : 0) +
    Number(paidToMahasangha ? paidToMahasangha.cash : 0) +
    Number(stationaryExpenses ? stationaryExpenses.cash : 0) +
    Number(serviceCharges ? serviceCharges.cash : 0) +
    Number(batchDividendDistributed ? batchDividendDistributed.cash : 0) +
    Number(otherExpenses ? otherExpenses.cash : 0) +
    Number(loanGivenToOtherSHG ? loanGivenToOtherSHG.cash : 0) +
    Number(batchSavingWithdrawals ? batchSavingWithdrawals.cash : 0) +
    Number(batchSecurityDeposit ? batchSecurityDeposit.cash : 0) +
    Number(donationToOutsider ? donationToOutsider.cash : 0) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestDonor.cash)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.subsidyDistributed.cash)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToDonor.cash)),
      0
    ) +
    Number(sdSHGToUnion ? sdSHGToUnion.cash : 0);

  const totExpenseBank =
    Number(travellingExpenses ? travellingExpenses.bankAmt : 0) +
    Number(paidToMahasangha ? paidToMahasangha.bankAmt : 0) +
    Number(stationaryExpenses ? stationaryExpenses.bankAmt : 0) +
    Number(serviceCharges ? serviceCharges.bankAmt : 0) +
    Number(batchDividendDistributed ? batchDividendDistributed.bankAmt : 0) +
    Number(otherExpenses ? otherExpenses.bankAmt : 0) +
    Number(loanGivenToOtherSHG ? loanGivenToOtherSHG.bankAmt : 0) +
    Number(batchSavingWithdrawals ? batchSavingWithdrawals.bankAmt : 0) +
    Number(batchSecurityDeposit ? batchSecurityDeposit.bankAmt : 0) +
    Number(donationToOutsider ? donationToOutsider.bankAmt : 0) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestDonor.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.subsidyDistributed.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToDonor.bankAmt || 0)),
      0
    ) +
    Number(sdSHGToUnion ? sdSHGToUnion.bankAmt : 0);

  const totIncomeCash =
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToShg.cash || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestShg.cash || 0)),
      0
    ) +
    Number(batchSavingAmt ? batchSavingAmt.cash : 0) +
    Number(batchSubAmt ? batchSubAmt.cash : 0) +
    Number(batchOtherContribution ? batchOtherContribution.cash : 0) +
    Number(batchMembershipFees ? batchMembershipFees.cash : 0) +
    Number(batchLoanPaid ? batchLoanPaid.cash : 0) +
    Number(batchInterestPaid ? batchInterestPaid.cash : 0) +
    Number(deactiveMemberSaving ? deactiveMemberSaving.cash : 0) +
    Number(
      otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.subsidyReceived.cash || 0)),
        0
      )
    ) +
    Number(batchOtherIncome ? batchOtherIncome.cash : 0) +
    Number(seedMoney ? seedMoney.cash : 0) +
    Number(loanRepaidByOtherSHG ? loanRepaidByOtherSHG.cash : 0) +
    Number(
      batchSecurityDepositMemToShg ? batchSecurityDepositMemToShg.cash : 0
    ) +
    Number(sdUnionToSHG ? sdUnionToSHG.cash : 0);

  const totIncomeBank =
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToShg.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestShg.bankAmt || 0)),
      0
    ) +
    Number(batchSavingAmt ? batchSavingAmt.bankAmt : 0) +
    Number(batchSubAmt ? batchSubAmt.bankAmt : 0) +
    Number(batchOtherContribution ? batchOtherContribution.bankAmt : 0) +
    Number(batchMembershipFees ? batchMembershipFees.bankAmt : 0) +
    Number(batchLoanPaid ? batchLoanPaid.bankAmt : 0) +
    Number(batchInterestPaid ? batchInterestPaid.bankAmt : 0) +
    Number(deactiveMemberSaving ? deactiveMemberSaving.bankAmt : 0) +
    Number(
      otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.subsidyReceived.bankAmt || 0)),
        0
      )
    ) +
    Number(batchOtherIncome ? batchOtherIncome.bankAmt : 0) +
    Number(seedMoney ? seedMoney.bankAmt : 0) +
    Number(loanRepaidByOtherSHG ? loanRepaidByOtherSHG.bankAmt : 0) +
    Number(
      batchSecurityDepositMemToShg ? batchSecurityDepositMemToShg.bankAmt : 0
    ) +
    Number(sdUnionToSHG ? sdUnionToSHG.bankAmt : 0);

  const [localBankDet, setlocalBankDet] = useState({
    batchBankNameObj: "",
    indBankInterest: 0,
    indBankCommission: 0,
    indBatchBankDeposit: 0,
    indBatchBankWithdrawals: 0,
  });

  let {
    batchBankNameObj,
    indBankInterest,
    indBankCommission,
    indBatchBankDeposit,
    indBatchBankWithdrawals,
  } = localBankDet;

  const inputBankDetChange = (e, selectedName = null) => {
    if (selectedName) {
      setlocalBankDet({
        ...localBankDet,
        batchBankNameObj: e,
      });
    } else {
      setlocalBankDet({
        ...localBankDet,
        [e.target.name]: e.target.value,
      });
    }
  };

  const [DBBankArray, setDBBankArray] = useState(
    formData ? formData.bankTransactions : []
  );

  setShowNewBankBalnce(formData ? formData.bankTransactions : []);

  const onInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onAddBankDet = () => {
    setShowNewBankBalnce([
      ...DBBankArray,
      {
        batchBankNameId: localBankDet.batchBankNameObj.bankId,
        batchBankName: localBankDet.batchBankNameObj.label,
        ...localBankDet,
      },
    ]);
    setFormData({
      ...formData,
      bankTransactions: [
        ...DBBankArray,
        {
          batchBankNameId: localBankDet.batchBankNameObj.bankId,
          batchBankName: localBankDet.batchBankNameObj.label,
          ...localBankDet,
        },
      ],
    });
    setDBBankArray([
      ...DBBankArray,
      {
        batchBankNameId: localBankDet.batchBankNameObj.bankId,
        batchBankName: localBankDet.batchBankNameObj.label,
        ...localBankDet,
      },
    ]);

    setlocalBankDet({
      batchBankNameObj: "",
      indBankInterest: 0,
      indBankCommission: 0,
      indBatchBankDeposit: 0,
      indBatchBankWithdrawals: 0,
    });
  };

  const onDeleteBankDet = (bankId) => {
    setShowNewBankBalnce(
      DBBankArray.filter((ele) => ele.batchBankNameId != bankId)
    );
    setDBBankArray(DBBankArray.filter((ele) => ele.batchBankNameId != bankId));

    // beta code
    setFormData({
      ...formData,
      bankTransactions: DBBankArray.filter(
        (ele) => ele.batchBankNameId != bankId
      ),
    });
  };

  const fianlTabFinish = async (meetingStatus) => {
    if (formData._id == null) {
      let finalFormData = {
        ...formData,
        bankTransactions: DBBankArray,
        bankInterest: DBBankArray.reduce(
          (ac, cur) => (ac += Number(cur.indBankInterest)),
          0
        ),
        bankCommission: DBBankArray.reduce(
          (ac, cur) => (ac += Number(cur.indBankCommission)),
          0
        ),
        batchBankDeposit: DBBankArray.reduce(
          (ac, cur) => (ac += Number(cur.indBatchBankDeposit)),
          0
        ),
        batchBankWithdrawals: DBBankArray.reduce(
          (ac, cur) => (ac += Number(cur.indBatchBankWithdrawals)),
          0
        ),
        batchId: selectedBatchData[0]._id,
        batchName: selectedBatchData[0].batchName,
        batchMeetingHeldOnDate: selectedDate,
      };

      addOnlyBatchWiseMeetingData({ mdStatus: "Submitted", ...finalFormData });

      history.push("/landing-page");
    } else {
      if (meetingStatus == "Verified") {
        editOnlyBatchWiseMeetingData({
          mdStatus: "Verified",
          verifiedById: user._id,
          verifiedByDateAndTime: new Date(),
          ...formData,
          bankTransactions: DBBankArray,
          bankInterest: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBankInterest)),
            0
          ),
          bankCommission: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBankCommission)),
            0
          ),
          batchBankDeposit: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBatchBankDeposit)),
            0
          ),
          batchBankWithdrawals: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBatchBankWithdrawals)),
            0
          ),
        });
      } else {
        editOnlyBatchWiseMeetingData({
          mdStatus: "Submitted",
          ...formData,
          bankTransactions: DBBankArray,
          bankInterest: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBankInterest)),
            0
          ),
          bankCommission: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBankCommission)),
            0
          ),
          batchBankDeposit: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBatchBankDeposit)),
            0
          ),
          batchBankWithdrawals: DBBankArray.reduce(
            (ac, cur) => (ac += Number(cur.indBatchBankWithdrawals)),
            0
          ),
        });
      }
      history.push("/landing-page");
    }
  };

  return (
    <div>
      {activeBank.length > 0 ? (
        <>
          <form>
            <div className="row card-new  py-3">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <h5>Bank Details</h5>
              </div>

              <div className="col-lg-5 col-md-12 col-sm-12 col-12 py-2">
                <h5>Income: </h5>
                Cash:
                <span class="mx-2">{totIncomeCash}</span> <br />
                {allPermission && allPermission.includes("batchCashBank") && (
                  <>
                    Bank:
                    <span class="mx-2">{totIncomeBank}</span>
                  </>
                )}
              </div>

              <div className="col-lg-5 col-md-12 col-sm-12 col-12 py-2">
                <h5>Expense :</h5>
                Cash:
                <span class="mx-2">{totExpenseCash}</span> <br />
                {allPermission && allPermission.includes("batchCashBank") && (
                  <>
                    Bank:
                    <span class="mx-2">{totExpenseBank}</span>
                  </>
                )}
              </div>

              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label className="label-control">Cash In Hand* :</label>
                <input
                  type="number"
                  name="cashInHand"
                  value={cashInHand}
                  className="form-control"
                  onChange={(e) => onInputChange(e)}
                  //   onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label
                  className="label-control"
                  // style={bankErrorStyle}
                >
                  Select Bank :
                </label>
                <Select
                  name="selBanks"
                  options={activeBank}
                  isSearchable={true}
                  placeholder="Select Bank"
                  onChange={(e) => inputBankDetChange(e, "selBanks")}
                  value={batchBankNameObj}
                />
              </div>

              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label className="label-control">Bank Interest : </label>
                <input
                  type="number"
                  name="indBankInterest"
                  value={indBankInterest}
                  className="form-control"
                  onChange={(e) => inputBankDetChange(e)}
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label className="label-control">Bank Commission : </label>
                <input
                  type="number"
                  name="indBankCommission"
                  value={indBankCommission}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => inputBankDetChange(e)}
                  //   onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label
                  // style={myerror}
                  className="label-control"
                >
                  Bank Deposit:
                </label>
                <input
                  type="number"
                  name="indBatchBankDeposit"
                  value={indBatchBankDeposit}
                  className="form-control"
                  onChange={(e) => inputBankDetChange(e)}
                  //   onKeyDown={(e) => funcKeyDown(e)}
                  required
                />
              </div>

              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label className="label-control">Bank Withdrawal :</label>
                <input
                  type="number"
                  name="indBatchBankWithdrawals"
                  value={indBatchBankWithdrawals}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => inputBankDetChange(e)}
                />
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <input
                  type="button"
                  name="Submit"
                  value="ADD"
                  className="btn sub_form btn_continue blackbrd Save float-right"
                  onClick={() => {
                    onAddBankDet();
                  }}
                  style={
                    DBBankArray.map((ele) => ele.batchBankNameId).includes(
                      batchBankNameObj.bankId
                    )
                      ? { display: "none" }
                      : {}
                  }
                  disabled={batchBankNameObj == ""}
                />
              </div>
            </div>

            <div className="body-inner no-padding table-responsive">
              <table
                className="tabllll table table-bordered table-striped table-hover"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>Bank Name</th>
                    <th>Bank Interest</th>
                    <th>Bank Commission</th>
                    <th>Bank Deposit Amount</th>
                    <th>Bank Withdrawal Amount</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {DBBankArray.map((ele, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{ele.batchBankName}</td>
                        <td>{ele.indBankInterest}</td>
                        <td>{ele.indBankCommission}</td>
                        <td>{ele.indBatchBankDeposit}</td>
                        <td>{ele.indBatchBankWithdrawals}</td>
                        <td>
                          <img
                            className="img_icon_size log"
                            src={require("../../../static/images/delete.png")}
                            alt="Delete"
                            title="Delete"
                            onClick={() => onDeleteBankDet(ele.batchBankNameId)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </form>
        </>
      ) : (
        <>
          <form>
            <div className="row card-new py-3">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <h5>Bank Details</h5>
              </div>

              <div className="col-lg-5 col-md-12 col-sm-12 col-12 py-2">
                <span>Income:</span>
              </div>

              <div className="col-lg-5 col-md-12 col-sm-12 col-12 py-2">
                <span>Expense :</span>
              </div>

              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <label className="label-control">Cash In Hand* :</label>
                <input
                  type="number"
                  name="cashInHand"
                  // value={cashInHand}
                  className="form-control"
                  // onChange={(e) => onInputChange(e)}
                  //   onChange={(e) => onInputChange(e)}
                  //   onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
          </form>
        </>
      )}

      <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
        {fromPage == "EditBatchMeeting" ? (
          <>
            {batchTransactionData && batchTransactionData.verifiedById ? (
              <>
                {allRights &&
                allRights.includes(user.userGroupName + "EditReport") ? (
                  <input
                    type="button"
                    name="Submit"
                    value="Submit"
                    onClick={() => fianlTabFinish("Verified")}
                    className="btn sub_form btn_continue Save float-right"
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {allRights &&
                allRights.includes(user.userGroupName + "VerifyMeeting") ? (
                  <>
                    <input
                      type="button"
                      onClick={() => fianlTabFinish("Verified")}
                      name="Verify"
                      value="Verify"
                      className="btn sub_form btn_continue Save float-right"
                    />
                  </>
                ) : (
                  ""
                )}

                {allRights &&
                allRights.includes(user.userGroupName + "EditMeeting") ? (
                  <>
                    <input
                      type="button"
                      onClick={() => fianlTabFinish("Submitted")}
                      name="Update"
                      value="Update"
                      className="btn sub_form btn_continue Save float-right"
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        ) : (
          <>
            <input
              type="button"
              onClick={() => fianlTabFinish("Submitted")}
              name="Update"
              value="Update"
              className="btn sub_form btn_continue Save float-right"
            />
          </>
        )}

        <button
          type="button"
          className="btn sub_form btn_continue Save float-right"
          onClick={() => NextBackBtn(6)}
        >
          Previous
        </button>
      </div>

      {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <button
          className="btn sub_form btn_continue Save float-right"
          // onClick={() => nextOfBatchLoanSanc()}
          onClick={() => {
            alert("hello");

            fianlTabFinish("Submitted");
          }}
        >
          Submit
        </button>
      </div> */}
    </div>
  );
};

BatchBankDetTab.propTypes = {
  //   second: PropTypes.third,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

const mapDispatchToProps = {
  editBatchMeetingData,
  addBatchMeetingData,
  addOnlyBatchWiseMeetingData,
  editOnlyBatchWiseMeetingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchBankDetTab);
