import {
  ALL_ACCESS_DETAILS,
  ALL_PERMISSION_DETAILS,
  ALL_ACTIVE_MODULE_DETAILS,
  ALL_ACTIVE_PERMISSIONS_DETAILS,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  users: [],
  errorResponse: "",
  successResponse: "",
  accessData: [],
  permissionData: [],
  activemoduleData: [],
  activepermissionData: [],
};

const area = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ALL_ACCESS_DETAILS:
      return {
        ...state,
        accessData: payload,
      };

    case ALL_PERMISSION_DETAILS:
      return {
        ...state,
        permissionData: payload,
      };

    case ALL_ACTIVE_MODULE_DETAILS:
      return {
        ...state,
        activemoduleData: payload,
      };
    case ALL_ACTIVE_PERMISSIONS_DETAILS:
      return {
        ...state,
        activepermissionData: payload,
      };
    default:
      return state;
  }
};

export default area;
