import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { AddCaste, getReligions } from "../../actions/religionSetting";

import Spinner from "../layout/Spinner";

const AddCasteDetails = ({
  auth: { isAuthenticated, user, users, loading },
  religionSetting: { religionsData },
  onAddCasteModalChange,
  AddCaste,
  getReligions,
}) => {
  useEffect(() => {
    getReligions();
  }, [getReligions]);

  //formData
  const [formData, setFormData] = useState({
    casteName: "",
    isSubmitted: false,
  });

  const { casteName } = formData;

  const allreligions = [];
  religionsData.map((religion) =>
    allreligions.push({
      religionId: religion._id,
      label: religion.religionName,
      value: religion.religionName,
    })
  );

  const [religion, getStateData] = useState();
  const [religionId, setStateID] = useState();

  const onStateChange = (e) => {
    //Required Validation Starts
    setError({
      ...error,
      sIdChecker: true,
      sIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    var religionId = "";
    getStateData(e);
    religionId = e.religionId;
    setStateID(religionId);
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //Required Validation Starts
  const [error, setError] = useState({
    sIdChecker: false,
    sIdErrorStyle: {},
  });
  const { sIdChecker, sIdErrorStyle } = error;

  const checkErrors = () => {
    if (!sIdChecker) {
      setError({
        ...error,
        sIdErrorStyle: { color: "#F00" },
      });
      return false;
    }
    return true;
  };
  //Required Validation ends
  const onSubmit = (e) => {
    e.preventDefault();
    //Required Validation Starts
    if (checkErrors()) {
      const finalData = {
        casteName: casteName,
        religionId: religionId,
        casteEnteredById: user._id,
        casteEnteredByName: user.userName,
        institutionId: user.institutionId,
        userData: user,
      };

      AddCaste(finalData);

      setFormData({
        ...formData,
        casteName: "",
        isSubmitted: true,
      });
      getStateData("");
    }
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control"> Caste Name* :</label>
              <input
                type="text"
                name="casteName"
                value={casteName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control" style={sIdErrorStyle}>
                Religion* :
              </label>
              <Select
                name="religionName"
                options={allreligions}
                isSearchable={true}
                value={religion}
                placeholder="Select Religion"
                onChange={(e) => onStateChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
          </div>

          <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

AddCasteDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  AddCaste: PropTypes.func.isRequired,
  getReligions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  religionSetting: state.religionSetting,
});

export default connect(mapStateToProps, {
  getReligions,
  AddCaste,
})(AddCasteDetails);
