import React, { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getIncomeAndExpenseReport,
  getBatchOfParish,
  getAllBatchesDropdown,
  getBatchOfDistrict,
  getIncomeAndExpenseOpBal,
} from "../../actions/shg";
import {
  getVarados,
  getParish,
  getStates,
  getDistrict,
  getBlockDetails,
} from "../../actions/area";
import Spinner from "../layout/Spinner";
import { CSVLink } from "react-csv";

const IncomeExpenseReport = ({
  auth: { isAuthenticated, user, users },
  shg: {
    batchOfParish,
    allbatchesdropdown,
    incomeandexpensereport,
    batchOfDistrict,
    incomeExpenseOpBal,
    closingAmt,
  },
  area: { varadosData, activeParish, statesData, activeDistrict, getBlock },
  getIncomeAndExpenseReport,
  getVarados,
  getParish,
  getBatchOfParish,
  getAllBatchesDropdown,
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getIncomeAndExpenseOpBal,
  getBlockDetails,
}) => {
  const SampadaId = "626a0c943fd98086d0ebc766";

  useEffect(() => {
    getIncomeAndExpenseReport();
  }, [getIncomeAndExpenseReport]);
  useEffect(() => {
    getVarados();
  }, [getVarados]);
  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);
  useEffect(() => {
    getStates();
  }, [getStates]);
  useEffect(() => {
    getIncomeAndExpenseOpBal();
  }, [getIncomeAndExpenseOpBal]);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const allvarado = [];
  varadosData.map((varado) =>
    allvarado.push({
      vId: varado._id,
      vCode: varado.varadoCode,
      label: varado.varadoName,
      value: varado.varadoName,
    })
  );
  const [varado, getVaradoData] = useState();
  const [varadoID, setVaradoID] = useState();
  const [varadoName, setvaradoName] = useState();
  const [varadoCode, setVaradoCode] = useState("");

  const onVaradoChange = (e) => {
    var varadoID = "";
    var varadoName = "";
    var varadoCode = "";
    getVaradoData(e);
    varadoID = e.vId;
    varadoName = e.value;
    varadoCode = e.vCode;
    setVaradoID(varadoID);
    setvaradoName(varadoName);
    setVaradoCode(varadoCode);
    let varadoVal = {
      varadoInfo: varadoID,
    };
    getParish(varadoVal);
    getParishData("");
    getbatchsData("");
    getIncomeAndExpenseReport("");
    getIncomeAndExpenseOpBal("");
    getBatchOfParish("");
  };

  const allparish = [];

  activeParish.map((parish) =>
    allparish.push({
      pId: parish._id,
      pCode: parish.parishCode,
      bCounter: parish.batchCounter,
      label: parish.parishName,
      value: parish.parishName,
    })
  );

  const [parish, getParishData] = useState();
  const [pId, setParishID] = useState();
  const [parishName, setParishName] = useState();
  const [parishCode, setParishCode] = useState("");
  const [batchCounter, setBatchCounter] = useState("");

  const onParishChange = (e) => {
    var pId = "";
    var parishName = "";
    var parishCode = "";
    var batchCounter = "";
    getParishData(e);
    pId = e.pId;
    parishName = e.value;
    parishCode = e.pCode;
    batchCounter = e.bCounter;
    setParishID(pId);
    setParishName(parishName);
    setParishCode(parishCode);
    setBatchCounter(batchCounter);
    var filterData = {
      parishId: pId,
    };
    getBatchOfParish(filterData);
    getIncomeAndExpenseReport("");
    getIncomeAndExpenseOpBal("");
    getbatchsData("");
  };
  const allbatches = [];

  batchOfParish.map((batchs) =>
    allbatches.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState();
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();
  let changeData = "";

  const onBatchesChange = (e) => {
    getbatchsData(e);
    setbatchId(e.batchId);
    setbatchName(e.batchName);
    changeData = {
      batchIdVal: e.batchId,
    };
    getIncomeAndExpenseReport(changeData);
    getIncomeAndExpenseOpBal(changeData);
  };

  const onClickReset = () => {
    getParishData("");
    getIncomeAndExpenseReport("");
    getIncomeAndExpenseOpBal("");
    getVaradoData("");
    getbatchsData("");
    getbatchesData("");
    getParish("");
    getBatchOfParish("");
    getStates("");
    getDistrict("");
    getStateData("");
    getdistrictData("");
    getBatchOfDistrict("");
    setBatchDate("");
    setBatchDates("");
    setBlock("");
  };

  const [state, getStateData] = useState();
  const [stateId, setStateID] = useState();
  const [stateName, setStateName] = useState();

  const onStateChange = (e) => {
    var stateId = "";
    var stateName = "";
    getStateData(e);

    stateId = e.sId;
    stateName = e.value;

    setStateID(stateId);
    setStateName(stateName);
    let stateVal = {
      // userInfo: user,
      stateInfo: stateId,
    };
    getDistrict(stateVal);
    getdistrictData("");
    getBatchOfDistrict("");
    getbatchesData("");
    setBlock("");
  };

  const alldistrict = [];

  activeDistrict.map((district) =>
    alldistrict.push({
      districtId: district._id,
      label: district.districtName,
      value: district.districtName,
    })
  );

  const allbatchesList = [];
  batchOfDistrict.map((batches) =>
    allbatchesList.push({
      batchesId: batches._id,
      label: batches.batchName,
      value: batches.batchName,
    })
  );
  const [batches, getbatchesData] = useState();
  const [batchesId, setbatchesId] = useState();
  const [batchesName, setbatchesName] = useState();
  const onBatchChange = (e) => {
    var batchesId = "";
    var batchesName = "";
    getbatchesData();
    batchesId = e.batchesId;
    batchesName = e.value;
    setbatchesId(batchesId);
    // setbatchesName(batchesName);

    const changeData = {
      batchIdVal: e.batchesId,
    };
    getIncomeAndExpenseReport(changeData);
    getIncomeAndExpenseOpBal(changeData);
    // getdistrictData("");
    // getStateData("");
    // setBlock("");
    // setdistrictName("");
  };

  const [district, getdistrictData] = useState();
  // const [bCode, getBCode] = useState();
  const [districtId, setdistrictID] = useState();
  const [districtName, setdistrictName] = useState();

  const ondistrictChange = (e) => {
    var districtId = "";
    var districtName = "";
    getdistrictData(e);
    getBlockDetails({ districtIdVal: e.districtId });
    districtId = e.districtId;
    districtName = e.value;
    setdistrictID(districtId);
    setdistrictName(districtName);
    var filterData = {
      districtId: districtId,
    };
    getBatchOfDistrict(filterData);
    getbatchesData("");
    setBlock("");
  };

  const allBlocks = [];
  getBlock &&
    getBlock.map((block) =>
      allBlocks.push({
        label: block.blockName,
        value: block._id,
      })
    );

  const [block, setBlock] = useState();
  const onBlockChange = (e) => {
    setBlock(e);
    getBatchOfDistrict({
      blockId: e.value,
    });
    getbatchesData("");
    // const changeData = {
    //   batchIdVal:null,
    //   blockIdVal: e.value,
    // };

    getIncomeAndExpenseReport({ blockId: e.value });
    getIncomeAndExpenseOpBal({ blockId: e.value });
  };

  let fiscalyearstart = "",
  fiscalyearend = "";
let today = new Date();

if (today.getMonth() + 1 <= 3) {
  fiscalyearstart = today.getFullYear() - 1 + "-04-01";
  fiscalyearend = today.getFullYear() + "-03-31";
} else {
  fiscalyearstart = today.getFullYear() + "-04-01";
  fiscalyearend = today.getFullYear() + 1 + "-03-31";
}


  const [fromSelectedDate, setBatchDate] = useState("2000-01-01");
  const [toSelectedDate, setBatchDates] = useState(fiscalyearend);
  const onDateChange = (e) => {
    setBatchDate(e.target.value);
    changeData = {
      batchIdVal: batchId || batchesId,
      fromDate: e.target.value,
      toDate: toSelectedDate,
    };
    getIncomeAndExpenseReport(changeData);
    getIncomeAndExpenseOpBal(changeData);
  };

  const onDateChanges = (e) => {
    setBatchDates(e.target.value);
    changeData = {
      batchIdVal: batchId || batchesId,
      fromDate: fromSelectedDate,
      toDate: e.target.value,
    };
    getIncomeAndExpenseReport(changeData);
    getIncomeAndExpenseOpBal(changeData);
  };
  const allstates = [];
  statesData.map((state) =>
    allstates.push({
      sId: state._id,
      label: state.stateName,
      value: state.stateName,
    })
  );

  let oldBankBalance = 0;
  incomeandexpensereport[0] &&
    incomeandexpensereport[0].otherLoanData.map((OLD) => {
      OLD.map((sub) => {
        oldBankBalance +=
          (sub.selectionType === "bank"
            ? parseFloat(sub.otherLoanSanctionedToShg || 0)
            : 0) +
          (sub.selectionType1 === "bank"
            ? parseFloat(sub.subsidyReceived || 0)
            : 0) -
          (sub.selectionOLIPDType === "bank"
            ? parseFloat(sub.batchOtherLoanInterestDonor || 0)
            : 0) -
          (sub.selectionOLPDType === "bank"
            ? parseFloat(sub.otherLoanPaidToDonor || 0)
            : 0);
      });
    });

  let batchSavingAmount,
    batchSubAmount,
    batchMembershipFees,
    batchOtherContribution,
    bankInterest,
    deactiveMemberSaving,
    batchOtherIncome,
    subsidyReceived,
    batchLoanPaid,
    batchInterestPaid,
    otherLoanPaidToShg,
    batchOtherLoanInterestShg,
    otherLoanSanctionedToShg,
    totalIncome,
    travellingExpenses,
    internalBankExpense,
    stationaryExpenses,
    paidToMahasangha,
    bankCommission,
    serviceCharges,
    batchDividendDistributed,
    otherExpenses,
    batchSavingWithdrawals,
    subsidyDistributed,
    batchLoanAmt,
    otherLoanSanctionedToMember,
    batchOtherLoanInterestDonor,
    otherLoanPaidToDonor,
    batchBankClosing,
    cashInHand,
    loanRepaidByOtherSHG,
    loanGivenToOtherSHG,
    internalBankExpenses,
    totalExpense = 0;

  const csvData = [
    [
      "Batch Saving Amount",
      "Batch Subscription Amount",
      "Batch Other Donation Amount",
      "Batch Membership Fees",
      "Bank Interest",
      "Deactive member Saving",
      "Other Income",
      "Loan Repaid By Other SHG",
      "Subsidy Received",
      "Batch Loan Paid",
      "Batch Interest Paid",
      "Other Loan Paid To SHG",
      "Other Loan Interest Paid To SHG",
      "Other Loan Sanctioned To SHG",
      "Batch Loan Amount Sanctioned",
      "Travelling Expenses",
      "Stationary Expenses",
      "Paid To Union (Mahasangha/Federation/General HUB)",
      "Bank Commission",
      "Service Charges",
      "Batch Dividend Distributed",
      "Batch Other Expenses",
      "Loan Given To Other SHG",
      "Batch Saving Withdrawals",
      "Subsidy Distributed",
      "Batch Other Loan Paid To Donor",
      "Batch Other Loan Sanctioned To Member",
      "Batch Other Loan Interest To Donor",
      "Closing Cash Balance",
      "Closing Bank Balance",
    ],
  ];

  incomeandexpensereport.map((incomeandexpensereport) => {
    batchSavingAmount = incomeandexpensereport.batchSavingAmt;
    batchSubAmount = incomeandexpensereport.batchSubAmt;
    batchOtherContribution = incomeandexpensereport.batchOtherContribution;
    batchMembershipFees = incomeandexpensereport.batchMembershipFees;
    bankInterest = incomeandexpensereport.bankInterest;
    deactiveMemberSaving = incomeandexpensereport.deactiveMemberSaving;
    batchOtherIncome = incomeandexpensereport.batchOtherIncome;

    loanRepaidByOtherSHG = incomeandexpensereport.loanRepaidByOtherSHG;
    subsidyReceived = incomeandexpensereport.subsidyReceived;
    batchLoanPaid = incomeandexpensereport.batchLoanPaid;
    batchInterestPaid = incomeandexpensereport.batchInterestPaid;
    otherLoanPaidToShg = incomeandexpensereport.otherLoanPaidToShg;
    batchOtherLoanInterestShg =
      incomeandexpensereport.batchOtherLoanInterestShg;
    otherLoanSanctionedToShg = incomeandexpensereport.otherLoanSanctionedToShg;
    //////////////////////////////////
    batchLoanAmt = incomeandexpensereport.batchLoanAmt;
    travellingExpenses = incomeandexpensereport.travellingExpenses;
    stationaryExpenses = incomeandexpensereport.stationaryExpenses;
    paidToMahasangha = incomeandexpensereport.paidToMahasangha;
    bankCommission = incomeandexpensereport.bankCommission;
    serviceCharges = incomeandexpensereport.serviceCharges;
    batchDividendDistributed = incomeandexpensereport.batchDividendDistributed;
    otherExpenses = incomeandexpensereport.otherExpenses;
    loanGivenToOtherSHG = incomeandexpensereport.loanGivenToOtherSHG;
    batchSavingWithdrawals = incomeandexpensereport.batchSavingWithdrawals;
    subsidyDistributed = incomeandexpensereport.subsidyDistributed;

    otherLoanPaidToDonor = incomeandexpensereport.otherLoanPaidToDonor;
    otherLoanSanctionedToMember =
      incomeandexpensereport.otherLoanSanctionedToMember;
    batchOtherLoanInterestDonor =
      incomeandexpensereport.batchOtherLoanInterestDonor;

    batchBankClosing =
      incomeandexpensereport.batchBankDeposit +
      incomeandexpensereport.otherLoanSanctionedToShgBank +
      incomeandexpensereport.subsidyReceivedBank -
      incomeandexpensereport.batchBankWithdrawals -
      incomeandexpensereport.memberClossingAmt +
      incomeandexpensereport.bankInterest -
      incomeandexpensereport.bankCommission +
      oldBankBalance;

    cashInHand = incomeandexpensereport.cashInHand;

    totalIncome =
      incomeandexpensereport.batchSavingAmt +
      incomeandexpensereport.batchSubAmt +
      incomeandexpensereport.batchOtherContribution +
      incomeandexpensereport.batchMembershipFees +
      incomeandexpensereport.deactiveMemberSaving +
      incomeandexpensereport.batchOtherIncome +
      incomeandexpensereport.loanRepaidByOtherSHG +
      incomeandexpensereport.subsidyReceived +
      incomeandexpensereport.batchLoanPaid +
      incomeandexpensereport.batchInterestPaid +
      incomeandexpensereport.otherLoanPaidToShg +
      incomeandexpensereport.batchOtherLoanInterestShg +
      incomeandexpensereport.otherLoanSanctionedToShg;

    totalExpense =
      travellingExpenses +
      stationaryExpenses +
      paidToMahasangha +
      bankCommission +
      serviceCharges +
      batchDividendDistributed +
      otherExpenses +
      loanGivenToOtherSHG +
      batchSavingWithdrawals +
      subsidyDistributed +
      batchLoanAmt +
      otherLoanSanctionedToMember +
      batchOtherLoanInterestDonor +
      otherLoanPaidToDonor +
      batchBankClosing +
      cashInHand;
    csvData.push([
      incomeandexpensereport.batchSavingAmt,
      incomeandexpensereport.batchSubAmt,
      incomeandexpensereport.batchOtherContribution,
      incomeandexpensereport.batchMembershipFees,
      incomeandexpensereport.bankInterest,
      incomeandexpensereport.deactiveMemberSaving,
      incomeandexpensereport.batchOtherIncome,
      incomeandexpensereport.loanRepaidByOtherSHG,
      incomeandexpensereport.subsidyReceived,
      incomeandexpensereport.batchLoanPaid,
      incomeandexpensereport.batchInterestPaid,
      incomeandexpensereport.otherLoanPaidToShg,
      incomeandexpensereport.batchOtherLoanInterestShg,
      incomeandexpensereport.otherLoanSanctionedToShg,

      ////////////////Expense
      incomeandexpensereport.batchLoanAmt,
      incomeandexpensereport.travellingExpenses,
      incomeandexpensereport.stationaryExpenses,
      incomeandexpensereport.paidToMahasangha,
      incomeandexpensereport.bankCommission,
      incomeandexpensereport.serviceCharges,
      incomeandexpensereport.batchDividendDistributed,
      incomeandexpensereport.otherExpenses,
      incomeandexpensereport.loanGivenToOtherSHG,
      incomeandexpensereport.batchSavingWithdrawals,
      incomeandexpensereport.subsidyDistributed,

      incomeandexpensereport.otherLoanPaidToDonor,
      incomeandexpensereport.otherLoanSanctionedToMember,

      incomeandexpensereport.batchOtherLoanInterestDonor,
      incomeandexpensereport.cashInHand,
      batchBankClosing,
    ]);
  });

  csvData.push([""]);
  csvData.push(["Total Income", totalIncome]);
  csvData.push(["Total Expense", totalExpense]);

  ////////////////////
  const csvDataSampada = [
    [
      "Batch Saving Amount",
      "opening Bank Balance",
      "Batch Subscription Amount",
      "Batch Other Donation Amount",
      "Batch Membership Fees",
      "Bank Interest",
      "Deactive member Saving",
      "Other Income",
      "Loan Repaid By Other SHG",
      "Subsidy Received",
      "Batch Loan Paid",
      "Batch Interest Paid",
      "Other Loan Paid To SHG",
      "Other Loan Interest Paid To SHG",
      "Other Loan Sanctioned To SHG",

      "Batch Loan Amount Sanctioned",
      "Travelling Expenses",
      "Stationary Expenses",
      "Paid To Union (Mahasangha/Federation/General HUB)",
      "Bank Commission",
      "Service Charges",
      "Batch Dividend Distributed",
      "Batch Other Expenses",
      "Loan Given To Other SHG",
      "Batch Saving Withdrawals",
      "Subsidy Distributed",
      "Batch Other Loan Paid To Donor",
      "Batch Other Loan Sanctioned To Member",
      "Batch Other Loan Interest To Donor",
      "Closing Cash Balance",
      "Closing Bank Balance",
      "Sugamya Bank savings",
    ],
  ];
  incomeandexpensereport.map((incomeandexpensereport) => {
    //Income
    batchSavingAmount = incomeandexpensereport.batchSavingAmt;
    batchSubAmount = incomeandexpensereport.batchSubAmt;
    batchOtherContribution = incomeandexpensereport.batchOtherContribution;
    batchMembershipFees = incomeandexpensereport.batchMembershipFees;
    bankInterest = incomeandexpensereport.bankInterest;
    deactiveMemberSaving = incomeandexpensereport.deactiveMemberSaving;
    batchOtherIncome = incomeandexpensereport.batchOtherIncome;
    loanRepaidByOtherSHG = incomeandexpensereport.loanRepaidByOtherSHG;
    subsidyReceived = incomeandexpensereport.subsidyReceived;
    batchLoanPaid = incomeandexpensereport.batchLoanPaid;
    batchInterestPaid = incomeandexpensereport.batchInterestPaid;
    otherLoanPaidToShg = incomeandexpensereport.otherLoanPaidToShg;
    batchOtherLoanInterestShg =
      incomeandexpensereport.batchOtherLoanInterestShg;
    otherLoanSanctionedToShg = incomeandexpensereport.otherLoanSanctionedToShg;
    batchLoanAmt = incomeandexpensereport.batchLoanAmt;

    ///Expense
    internalBankExpense = incomeandexpensereport.internalBankExpense;
    travellingExpenses = incomeandexpensereport.travellingExpenses;
    stationaryExpenses = incomeandexpensereport.stationaryExpenses;
    paidToMahasangha = incomeandexpensereport.paidToMahasangha;
    bankCommission = incomeandexpensereport.bankCommission;
    serviceCharges = incomeandexpensereport.serviceCharges;
    batchDividendDistributed = incomeandexpensereport.batchDividendDistributed;
    otherExpenses = incomeandexpensereport.otherExpenses;
    loanGivenToOtherSHG = incomeandexpensereport.loanGivenToOtherSHG;
    batchSavingWithdrawals = incomeandexpensereport.batchSavingWithdrawals;
    subsidyDistributed = incomeandexpensereport.subsidyDistributed;

    otherLoanPaidToDonor = incomeandexpensereport.otherLoanPaidToDonor;
    otherLoanSanctionedToMember =
      incomeandexpensereport.otherLoanSanctionedToMember;
    batchOtherLoanInterestDonor =
      incomeandexpensereport.batchOtherLoanInterestDonor;

    batchBankClosing =
      incomeandexpensereport.batchBankDeposit +
        Number(incomeandexpensereport.otherLoanSanctionedToShgBank || 0) +
        Number(incomeandexpensereport.subsidyReceivedBank || 0) -
        incomeandexpensereport.batchBankWithdrawals -
        incomeandexpensereport.memberClossingAmt +
        incomeandexpensereport.bankInterest -
        incomeandexpensereport.bankCommission +
        Number(
          closingAmt && closingAmt.result && closingAmt.result.toFixed(2)
        ) || 0;

    cashInHand = incomeandexpensereport.cashInHand;

    totalIncome =
      ((closingAmt && closingAmt.result) || 0) +
      (batchSavingAmount || 0) +
      (batchSubAmount || 0) +
      // (batchOtherContribution || 0) +
      (batchMembershipFees || 0) +
      (bankInterest || 0) +
      (deactiveMemberSaving || 0) +
      (batchOtherIncome || 0) +
      (loanRepaidByOtherSHG || 0) +
      (subsidyReceived || 0) +
      (batchLoanPaid || 0) +
      (batchInterestPaid || 0) +
      (otherLoanPaidToShg || 0) +
      (batchOtherLoanInterestShg || 0) +
      (otherLoanSanctionedToShg || 0);

    totalExpense =
      travellingExpenses +
      stationaryExpenses +
      paidToMahasangha +
      bankCommission +
      serviceCharges +
      batchDividendDistributed +
      otherExpenses +
      loanGivenToOtherSHG +
      batchSavingWithdrawals +
      subsidyDistributed +
      batchLoanAmt +
      otherLoanSanctionedToMember +
      batchOtherLoanInterestDonor +
      otherLoanPaidToDonor +
      batchBankClosing +
      cashInHand +
      internalBankExpense;

    csvDataSampada.push([
      incomeandexpensereport.batchSavingAmt,
      (closingAmt && closingAmt.result && closingAmt.result.toFixed(2)) || 0,
      incomeandexpensereport.batchSubAmt,
      incomeandexpensereport.batchOtherContribution,
      incomeandexpensereport.batchMembershipFees,
      incomeandexpensereport.bankInterest,
      incomeandexpensereport.deactiveMemberSaving,
      incomeandexpensereport.batchOtherIncome,
      incomeandexpensereport.loanRepaidByOtherSHG,
      incomeandexpensereport.subsidyReceived,
      incomeandexpensereport.batchLoanPaid,
      incomeandexpensereport.batchInterestPaid,
      incomeandexpensereport.otherLoanPaidToShg,
      incomeandexpensereport.batchOtherLoanInterestShg,
      incomeandexpensereport.otherLoanSanctionedToShg,
      ////////////////
      incomeandexpensereport.batchLoanAmt,
      incomeandexpensereport.travellingExpenses,
      incomeandexpensereport.stationaryExpenses,
      incomeandexpensereport.paidToMahasangha,
      incomeandexpensereport.bankCommission,
      incomeandexpensereport.serviceCharges,
      incomeandexpensereport.batchDividendDistributed,
      incomeandexpensereport.otherExpenses,
      incomeandexpensereport.loanGivenToOtherSHG,
      incomeandexpensereport.batchSavingWithdrawals,
      incomeandexpensereport.subsidyDistributed,
      incomeandexpensereport.otherLoanPaidToDonor,
      incomeandexpensereport.otherLoanSanctionedToMember,
      incomeandexpensereport.batchOtherLoanInterestDonor,
      incomeandexpensereport.cashInHand,
      batchBankClosing,
      incomeandexpensereport.internalBankExpense,
    ]);
  });

  csvDataSampada.push([""]);
  csvDataSampada.push(["Total Income", totalIncome]);
  csvDataSampada.push(["Total Expense", totalExpense]);

  ///////////////////
  const csvFreeZonedData = [
    [
      "Batch Name",
      "Batch Saving Amount",
      "Batch Subscription Amount",
      "Batch Other Donation Amount",
      "Batch Membership Fees",
      "Bank Interest",
      "Deactive member Saving",
      "Other Income",
      "Loan Repaid By Other SHG",
      "Subsidy Received",
      "Batch Loan Paid",
      "Batch Interest Paid",
      "Other Loan Paid To SHG",
      "Other Loan Interest Paid To SHG",
      "Other Loan Sanctioned To SHG",
      "Batch Loan Amount Sanctioned",
      "Travelling Expenses",
      "Stationary Expenses",
      "Paid To Union (Mahasangha/Federation/General HUB)",
      "Bank Commission",
      "Service Charges",
      "Batch Dividend Distributed",
      "Batch Other Expenses",
      "Loan Given To Other SHG",
      "Batch Saving Withdrawals",
      "Subsidy Distributed",
      "Batch Other Loan Paid To Donor",
      "Batch Other Loan Sanctioned To Member",
      "Batch Other Loan Interest To Donor",
      "Closing Cash Balance",
      "Closing Bank Balance",
      "Sugamya Bank savings",
    ],
  ];

  incomeandexpensereport.map((incomeandexpensereport) => {
    batchSavingAmount = incomeandexpensereport.batchSavingAmt;
    batchSubAmount = incomeandexpensereport.batchSubAmt;
    batchOtherContribution = incomeandexpensereport.batchOtherContribution;
    batchMembershipFees = incomeandexpensereport.batchMembershipFees;
    bankInterest = incomeandexpensereport.bankInterest;
    deactiveMemberSaving = incomeandexpensereport.deactiveMemberSaving;
    batchOtherIncome = incomeandexpensereport.batchOtherIncome;
    loanRepaidByOtherSHG = incomeandexpensereport.loanRepaidByOtherSHG;
    subsidyReceived = incomeandexpensereport.subsidyReceived;
    batchLoanPaid = incomeandexpensereport.batchLoanPaid;
    batchInterestPaid = incomeandexpensereport.batchInterestPaid;
    otherLoanPaidToShg = incomeandexpensereport.otherLoanPaidToShg;
    batchOtherLoanInterestShg =
      incomeandexpensereport.batchOtherLoanInterestShg;
    otherLoanSanctionedToShg = incomeandexpensereport.otherLoanSanctionedToShg;
    //////////////////////////////////////////
    batchLoanAmt = incomeandexpensereport.batchLoanAmt;

    travellingExpenses = incomeandexpensereport.travellingExpenses;
    stationaryExpenses = incomeandexpensereport.stationaryExpenses;
    paidToMahasangha = incomeandexpensereport.paidToMahasangha;
    bankCommission = incomeandexpensereport.bankCommission;
    serviceCharges = incomeandexpensereport.serviceCharges;
    batchDividendDistributed = incomeandexpensereport.batchDividendDistributed;
    otherExpenses = incomeandexpensereport.otherExpenses;
    loanGivenToOtherSHG = incomeandexpensereport.loanGivenToOtherSHG;
    batchSavingWithdrawals = incomeandexpensereport.batchSavingWithdrawals;
    subsidyDistributed = incomeandexpensereport.subsidyDistributed;
    otherLoanPaidToDonor = incomeandexpensereport.otherLoanPaidToDonor;
    otherLoanSanctionedToMember =
      incomeandexpensereport.otherLoanSanctionedToMember;
    batchOtherLoanInterestDonor =
      incomeandexpensereport.batchOtherLoanInterestDonor;
    batchBankClosing =
      incomeandexpensereport.batchBankDeposit +
      incomeandexpensereport.bankInterest -
      incomeandexpensereport.batchBankWithdrawals -
      incomeandexpensereport.bankCommission;
    // incomeandexpensereport.otherLoanSanctionedToShgBank +
    // incomeandexpensereport.subsidyReceivedBank -

    // incomeandexpensereport.memberClossingAmt +

    // oldBankBalance
    cashInHand = incomeandexpensereport.cashInHand;
    totalIncome =
      batchSavingAmount +
      batchSubAmount +
      batchOtherContribution +
      batchMembershipFees +
      bankInterest +
      deactiveMemberSaving +
      batchOtherIncome +
      loanRepaidByOtherSHG +
      subsidyReceived +
      batchLoanPaid +
      batchInterestPaid +
      otherLoanPaidToShg +
      batchOtherLoanInterestShg +
      otherLoanSanctionedToShg;
    totalExpense =
      travellingExpenses +
      stationaryExpenses +
      paidToMahasangha +
      bankCommission +
      serviceCharges +
      batchDividendDistributed +
      otherExpenses +
      loanGivenToOtherSHG +
      batchSavingWithdrawals +
      subsidyDistributed +
      batchLoanAmt +
      otherLoanSanctionedToMember +
      batchOtherLoanInterestDonor +
      otherLoanPaidToDonor +
      batchBankClosing +
      cashInHand +
      internalBankExpenses;

    csvFreeZonedData.push([
      incomeandexpensereport.batchName,
      incomeandexpensereport.batchSavingAmt,
      incomeandexpensereport.batchSubAmt,
      incomeandexpensereport.batchOtherContribution,
      incomeandexpensereport.batchMembershipFees,
      incomeandexpensereport.bankInterest,
      incomeandexpensereport.deactiveMemberSaving,
      incomeandexpensereport.batchOtherIncome,
      incomeandexpensereport.loanRepaidByOtherSHG,
      incomeandexpensereport.subsidyReceived,
      incomeandexpensereport.batchLoanPaid,
      incomeandexpensereport.batchInterestPaid,
      incomeandexpensereport.otherLoanPaidToShg,
      incomeandexpensereport.batchOtherLoanInterestShg,
      incomeandexpensereport.otherLoanSanctionedToShg,
      ///////////////
      incomeandexpensereport.batchLoanAmt,
      incomeandexpensereport.travellingExpenses,
      incomeandexpensereport.stationaryExpenses,
      incomeandexpensereport.paidToMahasangha,
      incomeandexpensereport.bankCommission,
      incomeandexpensereport.serviceCharges,
      incomeandexpensereport.batchDividendDistributed,
      incomeandexpensereport.otherExpenses,
      incomeandexpensereport.loanGivenToOtherSHG,
      incomeandexpensereport.batchSavingWithdrawals,
      incomeandexpensereport.subsidyDistributed,
      incomeandexpensereport.otherLoanPaidToDonor,
      incomeandexpensereport.otherLoanSanctionedToMember,
      incomeandexpensereport.batchOtherLoanInterestDonor,
      incomeandexpensereport.cashInHand,
      batchBankClosing,
      incomeandexpensereport.internalBankExpenses,
    ]);
  });
  csvFreeZonedData.push([""]);
  csvFreeZonedData.push(["Total Income", totalIncome]);
  csvFreeZonedData.push(["Total Expense", totalExpense]);

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color">Income and Expense Report </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding">
            {loggedUserInstitutionData.institutionType === "Zoned" ? (
              <>
                <div className="col-lg-2 col-md-3 col-sm-3 col-11 ">
                  <Select
                    name="varadoName"
                    options={allvarado}
                    isSearchable={true}
                    value={varado}
                    placeholder="Select Varado"
                    onChange={(e) => onVaradoChange(e)}
                  />
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 col-11 ">
                  <Select
                    name="parishName"
                    options={allparish}
                    isSearchable={true}
                    value={parish}
                    placeholder="Select Parish"
                    onChange={(e) => onParishChange(e)}
                  />
                </div>
                <div className="col-lg-4 col-md-5 col-sm-4 col-11 ">
                  <Select
                    name="batchName"
                    options={allbatches}
                    isSearchable={true}
                    value={batchs}
                    placeholder="Select SHG"
                    onChange={(e) => onBatchesChange(e)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-3 col-md-5 col-sm-4 col-11 mt-1">
                  <Select
                    name="stateName"
                    options={allstates}
                    isSearchable={true}
                    value={state}
                    placeholder="Select State"
                    onChange={(e) => onStateChange(e)}
                  />
                </div>
                <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                  <Select
                    name="districtName"
                    options={alldistrict}
                    isSearchable={true}
                    value={district}
                    placeholder="Select District"
                    onChange={(e) => ondistrictChange(e)}
                  />
                </div>
                {allPermission && allPermission.includes("Blockwise") && (
                  <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                    <Select
                      name="blockName"
                      options={allBlocks}
                      isSearchable={true}
                      value={block}
                      placeholder="Select Block"
                      onChange={(e) => onBlockChange(e)}
                    />
                  </div>
                )}
                <div className="col-lg-3 col-md-5 col-sm-4 col-3 mt-1">
                  <Select
                    name="batchName"
                    options={allbatchesList}
                    isSearchable={true}
                    value={batches}
                    placeholder="Select SHG"
                    onChange={(e) => onBatchChange(e)}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding mt-2">
            <div className="col-lg-2 col-md-3 col-sm-3 col-11 "style={{display:"none"}}>
              {/* <label className="label-control">From Date:</label> */}
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="fromDate"
                value={fromSelectedDate}
                onChange={(e) => onDateChange(e)}
                style={{
                  width: "75%",
                }}
              />
            </div>
            <div className="col-lg-2 col-md-5 col-sm-4 col-11 ">
              {/* <label className="label-control">To Date:</label> */}
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="toDate"
                value={toSelectedDate}
                onChange={(e) => onDateChanges(e)}
                style={{
                  width: "75%",
                }}
              />
            </div>
            <div className="col-lg-8 col-md-3 col-sm-4 col-11 py-3">
              <Link to="/all-reports">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <img
                className="img_icon_size log float-right ml-3"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />

              {loggedUserInstitutionData.institutionType === "Zoned" ? (
                <>
                  {user && user.institutionId === SampadaId ? (
                    <>
                      <CSVLink data={csvDataSampada}>
                        <img
                          className="img_icon_size log float-right ml-4"
                          src={require("../../static/images/excel_icon.png")}
                          alt="Excel-Export"
                          title="Excel-Export"
                        />
                      </CSVLink>
                    </>
                  ) : (
                    <>
                      <CSVLink data={csvData}>
                        <img
                          className="img_icon_size log float-right ml-4"
                          src={require("../../static/images/excel_icon.png")}
                          alt="Excel-Export"
                          title="Excel-Export"
                        />
                      </CSVLink>
                    </>
                  )}
                </>
              ) : (
                <CSVLink data={csvFreeZonedData}>
                  <img
                    className="img_icon_size log float-right ml-4"
                    src={require("../../static/images/excel_icon.png")}
                    alt="Excel-Export"
                    title="Excel-Export"
                  />
                </CSVLink>
              )}
            </div>
          </div>
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 "></div>
          {user && user.institutionId === SampadaId ? (
            <div className="row">
              <div className="col-lg-6 col-md-11 col-sm-11 col-11">
                <div className=" body-inner no-padding table-responsive ">
                  <h4>
                    <center>
                      <b>Income</b>
                    </center>
                  </h4>
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>PARTICULARS</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {incomeandexpensereport &&
                        incomeandexpensereport.map(
                          (incomeandexpensereport, idx) => {
                            let batchSavingAmount =
                              incomeandexpensereport.batchSavingAmt;
                            let batchSubAmount =
                              incomeandexpensereport.batchSubAmt;
                            let batchOtherContribution =
                              incomeandexpensereport.batchOtherContribution;
                            let batchMembershipFees =
                              incomeandexpensereport.batchMembershipFees;
                            let bankInterest =
                              incomeandexpensereport.bankInterest;
                            let deactiveMemberSaving =
                              incomeandexpensereport.deactiveMemberSaving;
                            let batchOtherIncome =
                              incomeandexpensereport.batchOtherIncome;
                            let seedMoney = incomeandexpensereport.seedMoney;
                            let subsidyReceived =
                              incomeandexpensereport.subsidyReceived;
                            let batchLoanPaid =
                              incomeandexpensereport.batchLoanPaid;
                            let batchInterestPaid =
                              incomeandexpensereport.batchInterestPaid;
                            let otherLoanPaidToShg =
                              incomeandexpensereport.otherLoanPaidToShg;
                            let batchOtherLoanInterestShg =
                              incomeandexpensereport.batchOtherLoanInterestShg;
                            let otherLoanSanctionedToShg =
                              incomeandexpensereport.otherLoanSanctionedToShg;
                            let loanRepaidByOtherSHG =
                              incomeandexpensereport.loanRepaidByOtherSHG;

                            let totalIncome =
                              batchSavingAmount +
                                batchSubAmount +
                                batchOtherContribution +
                                batchMembershipFees +
                                bankInterest +
                                deactiveMemberSaving +
                                batchOtherIncome +
                                seedMoney +
                                subsidyReceived +
                                batchLoanPaid +
                                batchInterestPaid +
                                otherLoanPaidToShg +
                                batchOtherLoanInterestShg +
                                otherLoanSanctionedToShg +
                                loanRepaidByOtherSHG +
                                Number(
                                  closingAmt &&
                                    closingAmt.result &&
                                    closingAmt.result.toFixed(2)
                                ) || 0;
                            return (
                              <>
                                <tr key={idx}>
                                  <td>Batch Saving Amount</td>
                                  <td>
                                    {incomeandexpensereport.batchSavingAmt}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Opening Bank Balance</td>
                                  <td>
                                    {(closingAmt &&
                                      closingAmt.result &&
                                      closingAmt.result.toFixed(2)) ||
                                      0}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Subscription Amount</td>
                                  <td>{incomeandexpensereport.batchSubAmt}</td>
                                </tr>
                                <tr>
                                  <td>Batch Other Donation Amount</td>
                                  <td>
                                    {
                                      incomeandexpensereport.batchOtherContribution
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Membership Fees</td>
                                  <td>
                                    {incomeandexpensereport.batchMembershipFees}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Bank Interest</td>
                                  <td>{incomeandexpensereport.bankInterest}</td>
                                </tr>
                                <tr>
                                  <td>Deactive member Saving</td>
                                  <td>
                                    {
                                      incomeandexpensereport.deactiveMemberSaving
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Other Income</td>
                                  <td>
                                    {incomeandexpensereport.batchOtherIncome}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Seed Money</td>
                                  <td>{incomeandexpensereport.seedMoney}</td>
                                </tr>
                                <tr>
                                  <td>Subsidy Received</td>
                                  <td>
                                    {incomeandexpensereport.subsidyReceived}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Loan Paid</td>
                                  <td>
                                    {incomeandexpensereport.batchLoanPaid}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Interest Paid</td>
                                  <td>
                                    {incomeandexpensereport.batchInterestPaid}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Other Loan Paid To SHG</td>
                                  <td>
                                    {incomeandexpensereport.otherLoanPaidToShg}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Other Loan Interest Paid To SHG</td>
                                  <td>
                                    {
                                      incomeandexpensereport.batchOtherLoanInterestShg
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Other Loan Sanctioned To SHG</td>
                                  <td>
                                    {
                                      incomeandexpensereport.otherLoanSanctionedToShg
                                    }
                                  </td>
                                </tr>
                                {allPermission &&
                                allPermission.includes("SHG-to-SHGLoan") ? (
                                  <>
                                    <tr>
                                      <td>Loan Repaid By Other SHG</td>
                                      <td>
                                        {
                                          incomeandexpensereport.loanRepaidByOtherSHG
                                        }
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  <td>
                                    <b>{totalIncome}</b>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-6 col-md-11 col-sm-11 col-11 ">
                <h4>
                  <center>
                    <b>Expense</b>
                  </center>
                </h4>

                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th width="60%">PARTICULARS</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {incomeandexpensereport &&
                        incomeandexpensereport.map(
                          (incomeandexpensereport, idx) => {
                            let internalBankExpense =
                              incomeandexpensereport.internalBankExpense;
                            let travellingExpenses =
                              incomeandexpensereport.travellingExpenses;
                            let stationaryExpenses =
                              incomeandexpensereport.stationaryExpenses;
                            let paidToMahasangha =
                              incomeandexpensereport.paidToMahasangha;
                            let bankCommission =
                              incomeandexpensereport.bankCommission;
                            let serviceCharges =
                              incomeandexpensereport.serviceCharges;
                            let batchDividendDistributed =
                              incomeandexpensereport.batchDividendDistributed;
                            let otherExpenses =
                              incomeandexpensereport.otherExpenses;
                            let loanGivenToOtherSHG =
                              incomeandexpensereport.loanGivenToOtherSHG;
                            let batchSavingWithdrawals =
                              incomeandexpensereport.batchSavingWithdrawals;
                            let subsidyDistributed =
                              incomeandexpensereport.subsidyDistributed;
                            let batchLoanAmt =
                              incomeandexpensereport.batchLoanAmt;
                            let otherLoanSanctionedToMember =
                              incomeandexpensereport.otherLoanSanctionedToMember;
                            let otherLoanPaidToDonor =
                              incomeandexpensereport.otherLoanPaidToDonor;
                            let batchOtherLoanInterestDonor =
                              incomeandexpensereport.batchOtherLoanInterestDonor;

                            let batchBankClosing =
                              incomeandexpensereport.batchBankDeposit +
                              Number(
                                incomeandexpensereport.otherLoanSanctionedToShgBank ||
                                  0
                              ) +
                              Number(                       
                                incomeandexpensereport.subsidyReceivedBank || 0) -
                              incomeandexpensereport.batchBankWithdrawals -
                              incomeandexpensereport.memberClossingAmt +
                              incomeandexpensereport.bankInterest -
                              incomeandexpensereport.bankCommission +
                              0 + //old Bank balance
                                Number(
                                  closingAmt &&
                                    closingAmt.result &&
                                    closingAmt.result.toFixed(2)
                                ) || 0;
                            let cashInHand = incomeandexpensereport.cashInHand;

                            let totalExpense =
                              internalBankExpense +
                              travellingExpenses +
                              stationaryExpenses +
                              paidToMahasangha +
                              bankCommission +
                              serviceCharges +
                              batchDividendDistributed +
                              otherExpenses +
                              loanGivenToOtherSHG +
                              batchSavingWithdrawals +
                              subsidyDistributed +
                              batchLoanAmt +
                              otherLoanSanctionedToMember +
                              batchOtherLoanInterestDonor +
                              otherLoanPaidToDonor +
                              batchBankClosing +
                              cashInHand +
                              internalBankExpenses;
                            return (
                              <>
                                <tr>
                                  <td>Batch Loan Amount Sanctioned</td>
                                  <td>{incomeandexpensereport.batchLoanAmt}</td>
                                </tr>
                                <tr key={idx}>
                                  <td>Travelling Expenses</td>
                                  <td>
                                    {incomeandexpensereport.travellingExpenses}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Stationary Expenses</td>
                                  <td>
                                    {incomeandexpensereport.stationaryExpenses}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Paid To Union (Mahasangha/Federation/General
                                    HUB)
                                  </td>
                                  <td>
                                    {incomeandexpensereport.paidToMahasangha}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Bank Commission</td>
                                  <td>
                                    {incomeandexpensereport.bankCommission}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Service Charges</td>
                                  <td>
                                    {incomeandexpensereport.serviceCharges}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Dividend Distributed</td>
                                  <td>
                                    {
                                      incomeandexpensereport.batchDividendDistributed
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Other Expenses</td>
                                  <td>
                                    {incomeandexpensereport.otherExpenses}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Saving Withdrawals</td>
                                  <td>
                                    {
                                      incomeandexpensereport.batchSavingWithdrawals
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Subsidy Distributed</td>
                                  <td>
                                    {incomeandexpensereport.subsidyDistributed}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Other Loan Paid To Donor</td>
                                  <td>
                                    {
                                      incomeandexpensereport.otherLoanPaidToDonor
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Other Loan Sanctioned To Member</td>
                                  <td>
                                    {
                                      incomeandexpensereport.otherLoanSanctionedToMember
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Other Loan Interest To Donor</td>
                                  <td>
                                    {
                                      incomeandexpensereport.batchOtherLoanInterestDonor
                                    }
                                  </td>
                                </tr>
                                {allPermission &&
                                allPermission.includes("SHG-to-SHGLoan") ? (
                                  <>
                                    <tr>
                                      <td>Loan Given To Other SHG</td>
                                      <td>
                                        {
                                          incomeandexpensereport.loanGivenToOtherSHG
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Sugamya Bank savings</td>
                                      <td>
                                        {
                                          incomeandexpensereport.internalBankExpense
                                        }
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <tr>
                                  <td>Closing Cash Balance</td>
                                  <td>{incomeandexpensereport.cashInHand}</td>
                                </tr>
                                <tr>
                                  <td>Closing Bank Balance</td>
                                  <td>{batchBankClosing}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  <td>
                                    <b>
                                      {/* {totalExpense +
                                      incomeandexpensereport.otherLoanSanctionedToShg} */}
                                      {totalExpense}
                                    </b>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-6 col-md-11 col-sm-11 col-11">
                <div className=" body-inner no-padding table-responsive ">
                  <h4>
                    <center>
                      <b>Income</b>
                    </center>
                  </h4>
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>PARTICULARS</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {incomeandexpensereport &&
                        incomeandexpensereport.map(
                          (incomeandexpensereport, idx) => {
                            let batchSavingAmount =
                              incomeandexpensereport.batchSavingAmt;
                            let batchSubAmount =
                              incomeandexpensereport.batchSubAmt;
                            let batchOtherContribution =
                              incomeandexpensereport.batchOtherContribution;
                            let batchMembershipFees =
                              incomeandexpensereport.batchMembershipFees;
                            let bankInterest =
                              incomeandexpensereport.bankInterest;
                            let deactiveMemberSaving =
                              incomeandexpensereport.deactiveMemberSaving;
                            let batchOtherIncome =
                              incomeandexpensereport.batchOtherIncome;
                            let seedMoney = incomeandexpensereport.seedMoney;
                            let subsidyReceived =
                              incomeandexpensereport.subsidyReceived;
                            let batchLoanPaid =
                              incomeandexpensereport.batchLoanPaid;
                            let batchInterestPaid =
                              incomeandexpensereport.batchInterestPaid;
                            let otherLoanPaidToShg =
                              incomeandexpensereport.otherLoanPaidToShg;
                            let batchOtherLoanInterestShg =
                              incomeandexpensereport.batchOtherLoanInterestShg;
                            let otherLoanSanctionedToShg =
                              incomeandexpensereport.otherLoanSanctionedToShg;
                            let loanRepaidByOtherSHG =
                              incomeandexpensereport.loanRepaidByOtherSHG;

                            let totalIncome =
                              batchSavingAmount +
                              batchSubAmount +
                              batchOtherContribution +
                              batchMembershipFees +
                              bankInterest +
                              deactiveMemberSaving +
                              batchOtherIncome +
                              seedMoney +
                              subsidyReceived +
                              batchLoanPaid +
                              batchInterestPaid +
                              otherLoanPaidToShg +
                              batchOtherLoanInterestShg +
                              otherLoanSanctionedToShg +
                              loanRepaidByOtherSHG;
                            return (
                              <>
                                <tr key={idx}>
                                  <td>Batch Saving Amount</td>
                                  <td>
                                    {incomeandexpensereport.batchSavingAmt}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Subscription Amount</td>
                                  <td>{incomeandexpensereport.batchSubAmt}</td>
                                </tr>
                                <tr>
                                  <td>Batch Other Donation Amount</td>
                                  <td>
                                    {
                                      incomeandexpensereport.batchOtherContribution
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Membership Fees</td>
                                  <td>
                                    {incomeandexpensereport.batchMembershipFees}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Bank Interest</td>
                                  <td>{incomeandexpensereport.bankInterest}</td>
                                </tr>
                                <tr>
                                  <td>Deactive member Saving</td>
                                  <td>
                                    {
                                      incomeandexpensereport.deactiveMemberSaving
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Other Income</td>
                                  <td>
                                    {incomeandexpensereport.batchOtherIncome}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Seed Money</td>
                                  <td>{incomeandexpensereport.seedMoney}</td>
                                </tr>
                                <tr>
                                  <td>Subsidy Received</td>
                                  <td>
                                    {incomeandexpensereport.subsidyReceived}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Loan Paid</td>
                                  <td>
                                    {incomeandexpensereport.batchLoanPaid}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Interest Paid</td>
                                  <td>
                                    {incomeandexpensereport.batchInterestPaid}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Other Loan Paid To SHG</td>
                                  <td>
                                    {incomeandexpensereport.otherLoanPaidToShg}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Other Loan Interest Paid To SHG</td>
                                  <td>
                                    {
                                      incomeandexpensereport.batchOtherLoanInterestShg
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Other Loan Sanctioned To SHG</td>
                                  <td>
                                    {
                                      incomeandexpensereport.otherLoanSanctionedToShg
                                    }
                                  </td>
                                </tr>
                                {allPermission &&
                                allPermission.includes("SHG-to-SHGLoan") ? (
                                  <tr>
                                    <td>Loan Repaid By Other SHG</td>
                                    <td>
                                      {
                                        incomeandexpensereport.loanRepaidByOtherSHG
                                      }
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}

                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  <td>
                                    <b>{totalIncome}</b>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-6 col-md-11 col-sm-11 col-11 ">
                <h4>
                  <center>
                    <b>Expense</b>
                  </center>
                </h4>

                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th width="60%">PARTICULARS</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {incomeandexpensereport &&
                        incomeandexpensereport.map(
                          (incomeandexpensereport, idx) => {
                            let travellingExpenses =
                              incomeandexpensereport.travellingExpenses;
                            let stationaryExpenses =
                              incomeandexpensereport.stationaryExpenses;
                            let paidToMahasangha =
                              incomeandexpensereport.paidToMahasangha;
                            let bankCommission =
                              incomeandexpensereport.bankCommission;
                            let serviceCharges =
                              incomeandexpensereport.serviceCharges;
                            let batchDividendDistributed =
                              incomeandexpensereport.batchDividendDistributed;
                            let otherExpenses =
                              incomeandexpensereport.otherExpenses;
                            let loanGivenToOtherSHG =
                              incomeandexpensereport.loanGivenToOtherSHG;
                            let batchSavingWithdrawals =
                              incomeandexpensereport.batchSavingWithdrawals;
                            let subsidyDistributed =
                              incomeandexpensereport.subsidyDistributed;
                            let batchLoanAmt =
                              incomeandexpensereport.batchLoanAmt;
                            let otherLoanSanctionedToMember =
                              incomeandexpensereport.otherLoanSanctionedToMember;
                            let otherLoanPaidToDonor =
                              incomeandexpensereport.otherLoanPaidToDonor;
                            let batchOtherLoanInterestDonor =
                              incomeandexpensereport.batchOtherLoanInterestDonor;

                            let batchBankClosing =
                              Number(incomeandexpensereport.bankInterest) +
                              Number(incomeandexpensereport.batchBankDeposit) -
                              (Number(incomeandexpensereport.bankCommission) +Number(incomeandexpensereport.batchBankWithdrawals)) -
                              Number(incomeandexpensereport.memberClossingAmt);

                            let cashInHand = incomeandexpensereport.cashInHand;

                            let totalExpense =
                              Number(batchLoanAmt || 0) +
                              Number(travellingExpenses || 0) +
                              Number(stationaryExpenses || 0) +
                              Number(paidToMahasangha || 0) +
                              Number(bankCommission || 0) +
                              Number(serviceCharges || 0) +
                              Number(batchDividendDistributed || 0) +
                              Number(otherExpenses || 0) +
                              Number(batchSavingWithdrawals || 0) +
                              Number(subsidyDistributed || 0) +
                              Number(otherLoanPaidToDonor || 0) +
                              Number(otherLoanSanctionedToMember || 0) +
                              Number(batchOtherLoanInterestDonor || 0) +
                              Number(cashInHand || 0) +
                              Number(batchBankClosing || 0);
                            // loanGivenToOtherSHG

                            return (
                              <>
                                <tr>
                                  <td>Batch Loan Amount Sanctioned</td>
                                  <td>{incomeandexpensereport.batchLoanAmt}</td>
                                </tr>
                                <tr key={idx}>
                                  <td>Travelling Expenses</td>
                                  <td>
                                    {incomeandexpensereport.travellingExpenses}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Stationary Expenses</td>
                                  <td>
                                    {incomeandexpensereport.stationaryExpenses}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    Paid To Union (Mahasangha/Federation/General
                                    HUB)
                                  </td>
                                  <td>
                                    {incomeandexpensereport.paidToMahasangha}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Bank Commission</td>
                                  <td>
                                    {incomeandexpensereport.bankCommission}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Service Charges</td>
                                  <td>
                                    {incomeandexpensereport.serviceCharges}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Dividend Distributed</td>
                                  <td>
                                    {
                                      incomeandexpensereport.batchDividendDistributed
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Other Expenses</td>
                                  <td>
                                    {incomeandexpensereport.otherExpenses}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Saving Withdrawals</td>
                                  <td>
                                    {
                                      incomeandexpensereport.batchSavingWithdrawals
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Subsidy Distributed</td>
                                  <td>
                                    {incomeandexpensereport.subsidyDistributed}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Other Loan Paid To Donor</td>
                                  <td>
                                    {
                                      incomeandexpensereport.otherLoanPaidToDonor
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Other Loan Sanctioned To Member</td>
                                  <td>
                                    {
                                      incomeandexpensereport.otherLoanSanctionedToMember
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Batch Other Loan Interest To Donor</td>
                                  <td>
                                    {
                                      incomeandexpensereport.batchOtherLoanInterestDonor
                                    }
                                  </td>
                                </tr>
                                {allPermission &&
                                allPermission.includes("SHG-to-SHGLoan") ? (
                                  <tr>
                                    <td>Loan Given To Other SHG</td>
                                    <td>
                                      {
                                        incomeandexpensereport.loanGivenToOtherSHG
                                      }
                                    </td>
                                  </tr>
                                ) : (
                                  <></>
                                )}

                                <tr>
                                  <td>Closing Cash Balance</td>
                                  <td>{incomeandexpensereport.cashInHand}</td>
                                </tr>
                                <tr>
                                  <td>Closing Bank Balance</td>
                                  <td>{batchBankClosing}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Total</b>
                                  </td>
                                  <td>
                                    <b>
                                      {/* {totalExpense +
                                      incomeandexpensereport.otherLoanSanctionedToShg} */}
                                      {totalExpense}
                                    </b>
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
    </Fragment>
  );
};

IncomeExpenseReport.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  getIncomeAndExpenseReport: PropTypes.func.isRequired,
  getParish: PropTypes.func.isRequired,
  getAllBatchesDropdown: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
});

export default connect(mapStateToProps, {
  getIncomeAndExpenseReport,
  getVarados,
  getParish,
  getBatchOfParish,
  getAllBatchesDropdown,
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getIncomeAndExpenseOpBal,
  getBlockDetails,
})(IncomeExpenseReport);
