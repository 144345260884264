import Select from "react-select";
import { getActiveOtherLoanType } from "../../../actions/shg";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

export const OLRepaymentTab = ({
  NextBackBtn,
  getActiveOtherLoanType,
  activeBank,
  formData,
  setFormData,
  membersMeetingData,
  auth: { isAuthenticated, user, users },
  shg: { activeOtherLoanTypes },
}) => {
  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);

  let MemberOther = membersMeetingData.map((ele) => ele.otherLoan).flat();

  let [OLTypeId, setOLTypeId] = useState(null);

  let [OLRepaymentObject, setOLRepaymentObject] = useState({
    otherLoanTypeId: null,
    otherLoanType: null,

    otherLoanSanctionedToShgCash: null,
    otherLoanSanctionedToShgBank: null,

    otherLoanPaidToShgCash: null,
    otherLoanPaidToShgBank: null,

    otherLoanInterestShgCash: null,
    otherLoanInterestShgBank: null,

    subsidyDistributedCash: null,
    subsidyDistributedBank: null,

    //Inputs
    subsidyReceivedCash: 0,
    subsidyReceivedBank: 0,
    subsidyReceivedBankName: null,

    otherLoanPaidToDonorCash: 0,
    otherLoanPaidToDonorBank: 0,
    otherLoanPaidToDonorBankName: null,

    otherLoanInterestDonorCash: 0,
    otherLoanInterestDonorBank: 0,
    otherLoanInterestDonorBankName: null,
  });

  useEffect(() => {
    if (OLTypeId) {
      setOLRepaymentObject({
        otherLoanTypeId: OLTypeId.otherLoanTypeId,
        otherLoanType: OLTypeId.label,

        //oth sanc
        otherLoanSanctionedToShgCash: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce(
          (acu, cur) => (acu += Number(cur.otherloanPayablePrincipal.cash)),
          0
        ),

        otherLoanSanctionedToShgBank: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce(
          (acu, cur) => (acu += Number(cur.otherloanPayablePrincipal.bankAmt)),
          0
        ),

        //otherLoanPaidToShg
        otherLoanPaidToShgCash: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce((acu, cur) => (acu += Number(cur.otherLoanPaid.cash)), 0),

        otherLoanPaidToShgBank: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce((acu, cur) => (acu += Number(cur.otherLoanPaid.bankAmt)), 0),

        // otherInterestPaid
        otherLoanInterestShgCash: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce((acu, cur) => (acu += Number(cur.otherInterestPaid.cash)), 0),

        otherLoanInterestShgBank: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce(
          (acu, cur) => (acu += Number(cur.otherInterestPaid.bankAmt)),
          0
        ),

        //sub dist
        subsidyDistributedCash: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce(
          (acu, cur) => (acu += Number(cur.indSubsidyDistributed.cash)),
          0
        ),
        subsidyDistributedBank: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce(
          (acu, cur) => (acu += Number(cur.indSubsidyDistributed.bankAmt)),
          0
        ),

        //sub rcv
        subsidyReceivedCash: 0,
        subsidyReceivedBank: 0,
        subsidyReceivedBankName: null,

        otherLoanPaidToDonorCash: 0,
        otherLoanPaidToDonorBank: 0,
        otherLoanPaidToDonorBankName: null,

        otherLoanInterestDonorCash: 0,
        otherLoanInterestDonorBank: 0,
        otherLoanInterestDonorBankName: null,
      });
    }
  }, [OLTypeId]);

  let {
    otherLoanTypeId,
    otherLoanType,
    otherLoanSanctionedToShgCash,
    otherLoanSanctionedToShgBank,

    otherLoanPaidToShgCash,
    otherLoanPaidToShgBank,

    otherLoanInterestShgCash,
    otherLoanInterestShgBank,

    subsidyDistributedCash,
    subsidyDistributedBank,

    subsidyReceivedCash,
    subsidyReceivedBank,
    subsidyReceivedBankName,

    otherLoanPaidToDonorCash,
    otherLoanPaidToDonorBank,
    otherLoanPaidToDonorBankName,

    otherLoanInterestDonorCash,
    otherLoanInterestDonorBank,
    otherLoanInterestDonorBankName,
  } = OLRepaymentObject;

  const InputChange = (e, type) => {
    if (type == "select") {
      setOLRepaymentObject({
        ...OLRepaymentObject,
        otherLoanType: e.label,
        otherLoanTypeId: e.otherLoanTypeId,
      });
    } else if (type == "") {
      setOLRepaymentObject({
        ...OLRepaymentObject,
        [e.target.name]: e.target.value,
      });
    } else {
      setOLRepaymentObject({
        ...OLRepaymentObject,
        [type]: e,
      });
    }
  };

  let [OLRepaymentArray, setOLRepaymentArray] = useState(
    formData.otherLoanBatchTransaction || []
  );

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const onOLRepaymentAdd = () => {
    // OLRepaymentObject;
    let OLRepaymentFinalArray = {
      otherLoanTypeId: otherLoanTypeId,
      otherLoanType: otherLoanType,

      otherLoanPaidToShg: {
        cash: Number(otherLoanPaidToShgCash),
        bankAmt: Number(otherLoanPaidToShgBank),
        bankName: "",
      },

      otherLoanInterestShg: {
        cash: Number(otherLoanInterestShgCash),
        bankAmt: Number(otherLoanInterestShgBank),
        bankName: "",
      },

      subsidyDistributed: {
        cash: Number(subsidyDistributedCash),
        bankAmt: Number(subsidyDistributedBank),
        bankName: "",
      },

      otherLoanSanctionedToShg: {
        cash: Number(otherLoanSanctionedToShgCash),
        bankAmt: Number(otherLoanSanctionedToShgBank),
        bankName: "",
      },

      otherLoanPaidToDonor: {
        cash: Number(otherLoanPaidToDonorCash),
        bankAmt: Number(otherLoanPaidToDonorBank),
        bankName: otherLoanPaidToDonorBankName
          ? otherLoanPaidToDonorBankName.label
          : "",
      },

      otherLoanInterestDonor: {
        cash: Number(otherLoanInterestDonorCash),
        bankAmt: Number(otherLoanInterestDonorBank),
        bankName: otherLoanInterestDonorBankName
          ? otherLoanInterestDonorBankName.label
          : "",
      },

      subsidyReceived: {
        cash: Number(subsidyReceivedCash),
        bankAmt: Number(subsidyReceivedBank),
        bankName: subsidyReceivedBankName ? subsidyReceivedBankName.label : "",
      },
    };

    setOLRepaymentArray([...OLRepaymentArray, OLRepaymentFinalArray]);

    setOLRepaymentObject({
      otherLoanTypeId: null,
      otherLoanType: null,

      otherLoanSanctionedToShgCash: null,
      otherLoanSanctionedToShgBank: null,

      otherLoanPaidToShgCash: null,
      otherLoanPaidToShgBank: null,

      otherLoanInterestShgCash: null,
      otherLoanInterestShgBank: null,

      subsidyDistributedCash: null,
      subsidyDistributedBank: null,

      subsidyReceivedCash: 0,
      subsidyReceivedBank: 0,
      subsidyReceivedBankName: null,

      otherLoanPaidToDonorCash: 0,
      otherLoanPaidToDonorBank: 0,
      otherLoanPaidToDonorBankName: null,

      otherLoanInterestDonorCash: 0,
      otherLoanInterestDonorBank: 0,
      otherLoanInterestDonorBankName: null,
    });
  };

  const onOLRepaymentDelete = (loanTypeId) => {
    setOLRepaymentArray(
      OLRepaymentArray.filter((ele) => ele.otherLoanTypeId != loanTypeId)
    );
  };

  const nextOfOLRepayment = () => {
    setFormData({
      ...formData,
      otherLoanBatchTransaction: OLRepaymentArray,
      subsidyReceived: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.subsidyReceived.cash) +
            Number(cur.subsidyReceived.bankAmt)),
        0
      ),
      batchOtherLoanInterestDonor: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanInterestDonor.cash) +
            Number(cur.otherLoanInterestDonor.bankAmt)),
        0
      ),
      otherLoanPaidToDonor: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanPaidToDonor.cash) +
            Number(cur.otherLoanPaidToDonor.bankAmt)),
        0
      ),
      otherLoanSanctionedToShg: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanSanctionedToShg.cash) +
            Number(cur.otherLoanSanctionedToShg.bankAmt)),
        0
      ),
      subsidyDistributed: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.subsidyDistributed.cash) +
            Number(cur.subsidyDistributed.bankAmt)),
        0
      ),

      otherLoanPaidToShg: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanPaidToShg.cash) +
            Number(cur.otherLoanPaidToShg.bankAmt)),
        0
      ),

      batchOtherLoanInterestShg: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanInterestShg.cash) +
            Number(cur.otherLoanInterestShg.bankAmt)),
        0
      ),
    });
    NextBackBtn(7);
  };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
      <>
        <div className="row card-new  py-3">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <h5>Other Loan Repayment</h5>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">OL Types* :</label>
            <Select
              name="otherLoanType"
              id="otherLoanTypeId"
              options={activeOtherLoan}
              isSearchable={true}
              value={OLTypeId}
              onChange={(e) => {
                setOLTypeId(e);
                InputChange(e, "select");
              }}
              placeholder="Select Bank"
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />

            <label className="label-control">OL Sanctioned To SHG:</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank{" "}
                <input
                  type="number"
                  name="otherLoanSanctionedToShgBank"
                  value={otherLoanSanctionedToShgBank}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  disabled
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Cash{" "}
                <input
                  type="number"
                  name="otherLoanSanctionedToShgCash"
                  value={otherLoanSanctionedToShgCash}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  disabled
                  required
                />
              </div>
            </div>

            <label className="label-control">OL Principal Paid To SHG :</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank{" "}
                <input
                  type="number"
                  name="otherLoanPaidToShgBank"
                  value={otherLoanPaidToShgBank}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  disabled
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Cash{" "}
                <input
                  type="number"
                  name="otherLoanPaidToShgCash"
                  value={otherLoanPaidToShgCash}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  disabled
                  required
                />
              </div>
            </div>

            <label className="label-control">OL Interest Paid To SHG :</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank{" "}
                <input
                  type="number"
                  name="otherLoanInterestShgBank"
                  value={otherLoanInterestShgBank}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  disabled
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Cash{" "}
                <input
                  type="number"
                  name="otherLoanInterestShgCash"
                  value={otherLoanInterestShgCash}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  disabled
                  required
                />
              </div>
            </div>

            <label className="label-control"> Subsidy Distributed</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank{" "}
                <input
                  type="number"
                  name="subsidyDistributedBank"
                  value={subsidyDistributedBank}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  disabled
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Cash{" "}
                <input
                  type="number"
                  name="subsidyDistributedCash"
                  value={subsidyDistributedCash}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  disabled
                  required
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">Subsidy Received To SHG :</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank List
                <Select
                  name="subsidyReceivedBankName"
                  options={activeBank}
                  value={subsidyReceivedBankName}
                  onChange={(e) => {
                    InputChange(e, "subsidyReceivedBankName");
                  }}
                  // onChange={(e) => onotherLoanTypeChange(e)}
                  isSearchable={true}
                  placeholder="Select Bank"
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank Amount{" "}
                <input
                  type="number"
                  name="subsidyReceivedBank"
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  value={subsidyReceivedBank}
                  disabled={subsidyReceivedBankName ? false : true}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <div className="row col-12">
              <div className="col-lg-6">
                {" "}
                Cash{" "}
                <input
                  type="number"
                  name="subsidyReceivedCash"
                  value={subsidyReceivedCash}
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            <label className="label-control">
              OL Principal Paid To Donor :
            </label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank List
                <Select
                  name="otherLoanPaidToDonorBankName"
                  options={activeBank}
                  value={otherLoanPaidToDonorBankName}
                  onChange={(e) => {
                    InputChange(e, "otherLoanPaidToDonorBankName");
                  }}
                  isSearchable={true}
                  placeholder="Select Bank"
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                {" "}
                Bank Amount{" "}
                <input
                  type="number"
                  name="otherLoanPaidToDonorBank"
                  value={otherLoanPaidToDonorBank}
                  disabled={otherLoanPaidToDonorBankName ? false : true}
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <div className="row col-12">
              <div className="col-lg-6">
                {" "}
                Cash{" "}
                <input
                  type="number"
                  name="otherLoanPaidToDonorCash"
                  value={otherLoanPaidToDonorCash}
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            <label className="label-control">OL Interest Paid To Donor:</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank List
                <Select
                  name="otherLoanInterestDonorBankName"
                  options={activeBank}
                  value={otherLoanInterestDonorBankName}
                  onChange={(e) => {
                    InputChange(e, "otherLoanInterestDonorBankName");
                  }}
                  isSearchable={true}
                  placeholder="Select Bank"
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                {" "}
                Bank Amount{" "}
                <input
                  type="number"
                  name="otherLoanInterestDonorBank"
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  value={otherLoanInterestDonorBank}
                  disabled={otherLoanInterestDonorBankName ? false : true}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <div className="row col-12">
              <div className="col-lg-6">
                {" "}
                Cash{" "}
                <input
                  type="number"
                  name="otherLoanInterestDonorCash"
                  value={otherLoanInterestDonorCash}
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <button
              className="btn sub_form btn_continue Save float-right"
              type="button"
              onClick={() => (OLTypeId ? onOLRepaymentAdd() : "")}
              disabled={OLRepaymentArray.map(
                (ele) => ele.otherLoanTypeId
              ).includes(OLTypeId ? OLTypeId.otherLoanTypeId : "")}
            >
              Add
            </button>
          </div>
        </div>
        <div className=" body-inner no-padding  table-responsive">
          <table
            className="tabllll table table-bordered table-striped table-hover"
            id="datatable2"
          >
            <thead>
              <tr>
                <th>OL Type</th>
                <th>OL Sanctioned To SHG</th>
                <th>OL Principal Paid To SHG</th>
                <th>OL Interest Paid SHG</th>
                <th>Subsidy Received</th>
                <th>Subsidy Distributed</th>
                <th>OL Principal Paid To Donor</th>
                <th>OL Interest Paid To Donor</th>
                <th>OP</th>
              </tr>
            </thead>
            <tbody>
              {OLRepaymentArray &&
                OLRepaymentArray.map((OLdata, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        {
                          activeOtherLoan.find(
                            (ele) =>
                              ele.otherLoanTypeId == OLdata.otherLoanTypeId
                          ).label
                        }
                      </td>
                      <td>
                        {Number(OLdata.otherLoanSanctionedToShg.cash) +
                          Number(OLdata.otherLoanSanctionedToShg.bankAmt)}
                      </td>
                      <td>
                        {Number(OLdata.otherLoanPaidToShg.cash) +
                          Number(OLdata.otherLoanPaidToShg.bankAmt)}
                      </td>

                      <td>
                        {Number(OLdata.otherLoanInterestShg.cash) +
                          Number(OLdata.otherLoanInterestShg.bankAmt)}
                      </td>

                      <td>
                        {Number(OLdata.subsidyReceived.cash) +
                          Number(OLdata.subsidyReceived.bankAmt)}
                      </td>

                      <td>
                        {Number(OLdata.subsidyDistributed.cash) +
                          Number(OLdata.subsidyDistributed.bankAmt)}
                      </td>

                      <td>
                        {Number(OLdata.otherLoanPaidToDonor.cash) +
                          Number(OLdata.otherLoanPaidToDonor.bankAmt)}
                      </td>

                      <td>
                        {Number(OLdata.otherLoanInterestDonor.cash) +
                          Number(OLdata.otherLoanInterestDonor.bankAmt)}
                      </td>

                      <td>
                        <img
                          className="img_icon_size log"
                          onClick={() =>
                            onOLRepaymentDelete(OLdata.otherLoanTypeId)
                          }
                          src={require("../../../static/images/close-buttonRed.png")}
                          alt="Remove"
                          title="Remove"
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <button
          className="btn sub_form btn_continue Save float-right"
          type="button"
          onClick={() => nextOfOLRepayment()}
        >
          Next
        </button>
        <button
          className="btn sub_form btn_continue Save float-right"
          type="button"
          onClick={() => NextBackBtn(5)}
        >
          Previous
        </button>
      </div>
    </div>
  );
};

OLRepaymentTab.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getActiveOtherLoanType,
})(OLRepaymentTab);
