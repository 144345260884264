import axios from "axios";

import {
  AUTH_ERROR,
  USER_GROUPS,
  ALL_USER_DETAILS,
  ALL_INCHARGE_DETAILS,
  ALL_COORDINATOR_DETAILS,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  CHECK_USER,
} from "./types";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

var linkPath = process.env.REACT_APP_BASE_URL;
// var linkPath = "";

export const AddUserDetails = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/users/add-user`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllUser(reqData));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddUserGroupDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/users/add-user-groups`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//DEACTIVE

export const deactiveUserDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/users/deactive-user`, finalData, config);
    dispatch(getAllUser());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//EDIT

export const editUserGroup = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/users/edit-user-groups`,
      finalData,
      config
    );
    dispatch(getUserGroups());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editUserDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/users/edit-user`, finalData, config);
    dispatch(getAllUser());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//SELECT
export const getALLUserGroups = () => async (dispatch) => {
  try {
    const res = await axios.get(`${linkPath}/api/users/get-all-user-groups`);
    dispatch({
      type: USER_GROUPS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getUserGroups = () => async (dispatch) => {
  try {
    const res = await axios.get(`${linkPath}/api/users/get-user-groups`);
    localStorage.setItem("selUserGroupData", JSON.stringify(res.data));
    dispatch({
      type: USER_GROUPS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllUser = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/users/get-all-users`,data);
    dispatch({
      type: ALL_USER_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};


export const getAllIncharge = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/users/get-all-incharge`);
    dispatch({
      type: ALL_INCHARGE_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllCoordinators = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/users/get-all-Coordinator`);
    dispatch({
      type: ALL_COORDINATOR_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const checkForUser = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/users/check-for-user`);
    dispatch({
      type: CHECK_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
