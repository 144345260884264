import React from "react";
import { Link } from "react-router-dom";
export const updateSHGMeetTamilVid = () => {
  return (
    <div>
      <div
        className="container container_align "
        style={{ marginLeft: "12px" }}
      >
        <div className=" row col-lg-12 col-md-11 col-sm-12 col-12">
          <div className="col-lg-9 col-md-11 col-sm-12 col-12">
            <h3 className="heading_color1">
              எப்படி SHG மீட்டிங் போடணும், பார்ஷியல் மீட்டிங் ஸ்டெப்ஸ் முடிந்த
              பிறகு. ?
              <h6>(How to Update SHG Meeting After Partial Meetings Steps?)</h6>{" "}
            </h3>
          </div>
          <div className="col-lg-2 col-md-11 col-sm-12 col-12 m-3 d-flex justify-content-end align-items-center">
            {" "}
            <Link to="/Tamil">
              <img
                className="img_icon_size log float-right ml-3"
                src={require("../../../static/images/back.png")}
                alt="Back"
                title="Back"
              />
            </Link>
          </div>
        </div>
        <hr style={{ width: "98%", marginLeft: "-0.5%" }} />
        <section className="row scstyle">
          <div className="col-lg-8 col-md-6 col-sm-12 col-12">
            <div>
              <center>
                <div class="m-3">
                  <video
                    controls
                    oncontextmenu="return false;"
                    controlsList="nodownload"
                    className="vidAlign"
                  >
                    <source
                      src="https://www.pinnacletechnologies.in/training/SHG/tamil/How to Update SHG Meeting After Partial Meetings Steps - Tamil.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </center>
            </div>
          </div>

          {/* <div className="col-lg-4 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>Desp</center>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </p>
            </div>
          </div> */}
        </section>
      </div>
    </div>
  );
};
