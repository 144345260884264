import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { editShgCategories } from "../../actions/shg";
import Spinner from "../layout/Spinner";

const EditShgCategories = ({
  savedMessage,
  auth: { isAuthenticated, user, users, loading },
  allShgCategories,
  editShgCategories,
  onUpdateModalChange,
}) => {
  //formData
  const [formData, setFormData] = useState({
    shgCategoryName:
      allShgCategories && allShgCategories.shgCategoryName
        ? allShgCategories.shgCategoryName
        : "",

    isSubmitted: false,
  });

  const { shgCategoryName } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onUpdate = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: allShgCategories ? allShgCategories._id : "",
      shgCategoryName: shgCategoryName,
      shgCategoryEnteredById: user._id,
      shgCategoryEnteredByName: user.userName,
      shgCategoryEnteredDateTime: new Date().toLocaleString("en-GB"),
      institutionId: user.institutionId,
    };
    editShgCategories(finalData);
    onUpdateModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onUpdate(e)}>
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <label className="label-control"> Edit Shg Categories* :</label>
              <input
                type="text"
                name="shgCategoryName"
                value={shgCategoryName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>

          <div className="col-md-10 col-lg-10 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Update"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditShgCategories.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  editShgCategories: PropTypes.func.isRequired,
  savedMessage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  savedMessage: state.auth.savedMessage,
});

export default connect(mapStateToProps, {
  editShgCategories,
})(EditShgCategories);
