import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AddOtherLoanType } from "../../actions/shg";
import Spinner from "../layout/Spinner";

const AddOtherLoanTypes = ({
  savedMessage,
  auth: { isAuthenticated, user, users, loading },
  AddOtherLoanType,
  onAddStateModalChange
}) => {
  //formData
  const [formData, setFormData] = useState({
    otherLoanName: "",
    isSubmitted: false,
  });
  const { otherLoanName } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      otherLoanType: otherLoanName,
      otherLoanTypeEnteredById: user._id,
      otherLoanTypeEnteredByName: user.userName,
      otherLoanTypeEnteredDateTime: new Date().toLocaleString("en-GB"),
      institutionId: user.institutionId,
    };
    AddOtherLoanType(finalData);
    setFormData({
      ...formData,
      otherLoanName: "",

      isSubmitted: true,
    });
    onAddStateModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <label className="label-control"> Other loan Name* :</label>
              <input
                type="text"
                name="otherLoanName"
                value={otherLoanName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>

          <div className="col-md-10 col-lg-10 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Save"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

AddOtherLoanTypes.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  AddOtherLoanType: PropTypes.func.isRequired,
  savedMessage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  savedMessage: state.auth.savedMessage,
});

export default connect(mapStateToProps, {
  AddOtherLoanType,
})(AddOtherLoanTypes);
