import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import {
  BatchTransaction,
  meetingDates,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  checkAvailableDate,
  removeMessage,
  getMeetingSummary,
  AddLoanSanctionedDetails,
  getActiveOtherLoanType,
  getActiveBankData,
} from "../../actions/shg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Spinner from "../layout/Spinner";

const OpeningBalforBatchMeetingDetails = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { activeOtherLoanTypes, activeBankData },
  BatchTransaction,
  meetingDates,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  getActiveOtherLoanType,
  removeMessage,
  getActiveBankData,
}) => {
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  let selectedBatchData = JSON.parse(localStorage.getItem("selBatchData"));
  let OpBalDate = JSON.parse(localStorage.getItem("selDate"));
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  useEffect(() => {
    removeMessage();
  }, [removeMessage]);
  useEffect(() => {
    getParticularLoanReason();
  }, [getParticularLoanReason]);
  useEffect(() => {
    getMembersOfSelectedBatch(batchIdValue);
  }, [getMembersOfSelectedBatch]);
  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);
  useEffect(() => {
    getActiveBankData(batchIdValue);
  }, [getActiveBankData]);

  const [formData, setFormData] = useState({
    batchOtherLoanAmts: "",
    remainingLoan: "",
    memberName2: "",
    bankloanOtherReason: "",
    batchSavingAmt: "",
    remainingotherLoan: "",
    batchLoanPaid: "",
    batchInterestPaid: "",
    batchLoanSanctionedAmt: "",
    batchOtherLoanPaid: "",
    batchOtherLoanInterestDonor: "",
    otherLoanPaidToShg: "",
    batchOtherLoanInterestShg: "",
    otherLoanSanctionedToShg: "",
    bankInterest: "",
    batchSubAmt: "",
    stationaryExpenses: "",
    paidToMahasangha: "",
    otherExpenses: "",
    travellingExpenses: "",
    serviceCharges: "",
    bankCommission: "",
    batchOtherContribution: "",
    subsidyReceived: "",
    batchOtherIncome: "",
    batchMembershipFees: "",
    deactiveMemberSaving: "",
    seedMoney: "",
    subsidyDistributed: "",
    batchSavingWithdrawals: "",
    batchDividendDistributed: "",
    batchBankDeposit: "",
    otherPurpose: "",
    cashInHand: "",
    batchSecurityDepositMemToShg: "",
    batchSecurityDeposit: "",
    donationToOutsider: "",
    sdUnionToSHG: "",
    sdSHGToUnion: "",
    loanRepaidByOtherSHG: "",
    loanGivenToOtherSHG: "",

    isSubmitted: false,
  });

  const {
    batchOtherLoanAmts,
    batchSavingAmt,
    batchLoanPaid,
    cashInHand,
    batchInterestPaid,
    batchLoanSanctionedAmt,
    otherLoanPaidToDonor,
    batchOtherLoanInterestDonor,
    otherLoanPaidToShg,
    batchOtherLoanInterestShg,
    otherLoanSanctionedToShg,
    bankInterest,
    batchSubAmt,
    stationaryExpenses,
    paidToMahasangha,
    otherExpenses,
    travellingExpenses,
    serviceCharges,
    bankCommission,
    batchOtherContribution,
    subsidyReceived,
    batchOtherIncome,
    batchMembershipFees,
    deactiveMemberSaving,
    seedMoney,
    subsidyDistributed,
    batchSavingWithdrawals,
    batchDividendDistributed,
    batchBankDeposit,
    batchSecurityDepositMemToShg,
    batchSecurityDeposit,
    donationToOutsider,
    sdUnionToSHG,
    sdSHGToUnion,
    loanRepaidByOtherSHG,
    loanGivenToOtherSHG,
    isSubmitted,
  } = formData;

  const [tabIndex, setTabIndex] = useState(0);
  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  //for opening balance validation
  let passwrdTooltip = {
    marginLeft: "-16em",
    position: "absolute",
    marginTop: "1.5em",
    pointerEvents: "none",
    zIndex: "999",
    width: "300px",
  };
  const [error2, setError2] = useState({
    openBalChecker: false,
    openBalErrorStyle: {},
  });
  const { openBalChecker, openBalErrorStyle } = error2;
  const checkErrors2 = () => {
    if (!bankGridDeposit) {
      setError2({
        ...error2,
        openBalErrorStyle: { color: "#F00" },
      });
      return false;
    } else {
      setError2({
        ...error2,
        openBalChecker: true,
        openBalErrorStyle: { color: "#000" },
      });
    }

    return true;
  };

  //validation for select bank
  const [errors, setErrors] = useState({
    bnkChecker: false,
    bnkErrorStyle: {},
  });
  const { bnkChecker, bnkErrorStyle } = errors;

  const checkError = () => {
    if (!bnkChecker) {
      setErrors({
        ...errors,
        bnkErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };

  const [AddedDetails, AddDetails] = useState([]);
  const onAdd = (e) => {
    e.preventDefault();
    if (otherLoanType) {
      const loanList = AddedDetails.filter(
        (AddDetails) =>
          AddDetails.otherLoanTypeId === otherLoanType.otherLoanTypeId
      );

      if (loanList.length === 0) {
        const addData = {
          otherLoanType: otherLoanType.value,
          otherLoanTypeId: otherLoanType.otherLoanTypeId,
          otherLoanSanctionedToShg: otherLoanSanctionedToShg,
          subsidyReceived: subsidyReceived,
          subsidyDistributed: subsidyDistributed,
          batchOtherLoanAmts: batchOtherLoanAmts,
          otherLoanPaidToShg: otherLoanPaidToShg,
          otherLoanPaidToDonor: otherLoanPaidToDonor,
          batchOtherLoanInterestShg: batchOtherLoanInterestShg,
          batchOtherLoanInterestDonor: batchOtherLoanInterestDonor,
        };
        setFormData({
          ...formData,
          otherLoanType: "",
          otherLoanSanctionedToShg: "",
          batchOtherLoanAmts: "",
          otherLoanPaidToShg: "",
          subsidyReceived: "",
          subsidyDistributed: "",
          otherLoanPaidToDonor: "",
          batchOtherLoanInterestShg: "",
          batchOtherLoanInterestDonor: "",
        });
        let temp = [];
        temp.push(...AddedDetails, addData);
        AddDetails(temp);
        getOtherLoanTypeData("");
      }
    }
  };

  const onRemoveChange = (otherLoanTypeId) => {
    const removeList = AddedDetails.filter(
      (AddDetails) => AddDetails.otherLoanTypeId !== otherLoanTypeId
    );
    AddDetails(removeList);
  };

  const [addBankData, setFormBankData] = useState({
    bankGridId: "",
    bankGridName: "",
    bankGridInterest: "",
    bankGridCommission: "",
    bankGridDeposit: "",
    gridcashInHand: "",
    bankGridwithdrawal: "",
  });
  const {
    bankGridId,
    bankGridName,
    bankGridInterest,
    bankGridCommission,
    bankGridDeposit,
    gridcashInHand,
    bankGridwithdrawal,
  } = addBankData;
  const [AddedBankDetails, AddBankDetails] = useState([]);
  const onAddBank = (e) => {
    const bankList = AddedBankDetails.filter(
      (AddBankDetails) => AddBankDetails.batchBankNameId === batchBankNameId
    );
    e.preventDefault();
    if (checkError()) {
      if (bankList.length === 0) {
        // if (checkErrors()) {
        const addBankData = {
          batchBankNameId: batchBankNameId,
          batchBankName: batchBankName,
          indBankInterest: bankGridInterest,
          indBankCommission: bankGridCommission,
          indBatchBankDeposit: bankGridDeposit,
          indcashInHand: gridcashInHand,
          indBatchBankWithdrawals: bankGridwithdrawal,
        };
        setFormBankData({
          ...addBankData,
          bankGridInterest: "",
          bankGridCommission: "",
          bankGridDeposit: "",
          gridcashInHand: "",
          bankGridwithdrawal: "",
        });
        let temp = [];
        temp.push(...AddedBankDetails, addBankData);
        AddBankDetails(temp);
        getBank("");
      }
    }
    // }
  };
  const onRemoveChangeBank = (bankGridName) => {
    const removeList = AddedBankDetails.filter(
      (AddBankDetails) => AddBankDetails.bankGridName !== bankGridName
    );
    AddBankDetails(removeList);
  };

  const onAddOLRSingle = () => {
    // e.preventDefault();
    const addData = {
      otherLoanType: activeOtherLoan[0] ? activeOtherLoan[0].value : "",
      otherLoanTypeId: activeOtherLoan[0]
        ? activeOtherLoan[0].otherLoanTypeId
        : "",
      otherLoanPaidToShg: otherLoanPaidToShg,
      batchOtherLoanAmts: batchOtherLoanAmts,
      batchOtherLoanInterestShg: batchOtherLoanInterestShg,
      subsidyReceived: subsidyReceived,
      otherLoanSanctionedToShg: otherLoanSanctionedToShg,
      batchOtherLoanInterestDonor: batchOtherLoanInterestDonor,
      subsidyDistributed: subsidyDistributed,
      otherLoanPaidToDonor: otherLoanPaidToDonor,
    };
    let temp = [];
    temp.push(addData);
    AddDetails(temp);
    NextBackBtn(4);
  };

  const onAddBankSingle = (e) => {
    e.preventDefault();
    const addBankData = {
      batchBankNameId:
        activeBank && activeBank[0] ? activeBank[0].bankId : null,
      batchBankName: activeBank && activeBank[0] ? activeBank[0].value : "",
      indBankInterest: bankGridInterest ? bankGridInterest : 0,
      indBankCommission: bankGridCommission ? bankGridCommission : 0,
      indBatchBankDeposit: bankGridDeposit ? bankGridDeposit : 0,
      indBatchBankWithdrawals: bankGridwithdrawal ? bankGridwithdrawal : 0,
    };

    setFormBankData({
      ...addBankData,
      bankGridInterest: "",
      bankGridCommission: "",
      bankGridDeposit: "",
      bankGridwithdrawal: "",
    });
    let temp = [];
    temp.push(addBankData);
    AddBankDetails(temp);

    return temp;
  };

  const [bank, getBank] = useState("");
  const [batchBankNameId, setBatchBankNameId] = useState("");
  const [batchBankName, setBatchBankName] = useState("");
  const activeBank = [];
  activeBankData &&
    activeBankData.banks &&
    activeBankData.banks.map((bank) =>
      activeBank.push({
        bankId: bank._id,
        label: bank.batchBankName,
        value: bank.batchBankName,
      })
    );

  const onBankChange = (e) => {
    setErrors({
      ...errors,
      bnkChecker: true,
      bnkErrorStyle: { color: "#000" },
    });

    setError({
      ...error,
      bankIdChecker: true,
      bankIdErrorStyle: { color: "#000" },
    });
    getBank(e);
    setBatchBankNameId(e.bankId ? e.bankId : "");
    setBatchBankName(e.value ? e.value : "");
  };

  const onInputChange2 = (e) => {
    setFormBankData({ ...addBankData, [e.target.name]: e.target.value });
  };

  const [otherLoanType, getOtherLoanTypeData] = useState("");
  const [otherLoanTypeId, setOtherLoanTypeId] = useState("");
  const [otherLoanTypeName, setOtherLoanTypeName] = useState("");
  const [error, setError] = useState({
    otherLoanTypeIdChecker: false,
    otherLoanTypeIdErrorStyle: {},
  });
  const { otherLoanTypeIdChecker, otherLoanTypeIdErrorStyle } = error;

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  const onotherLoanTypeChange = (e) => {
    setError({
      ...error,
      otherLoanTypeIdChecker: true,
      otherLoanTypeIdErrorStyle: { color: "#000" },
    });
    getOtherLoanTypeData(e);
    setOtherLoanTypeId(e.otherLoanTypeId ? e.otherLoanTypeId : "");
    setOtherLoanTypeName(e.value ? e.value : "");
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const onSubmit = (e) => {
    if (bankGridDeposit !== "") {
      setError2({
        ...error2,
        openBalChecker: true,
        openBalErrorStyle: { color: "#000" },
      });
    }

    let bankTran = [];
    if (activeBank.length <= 1) {
      bankTran = onAddBankSingle(e);
    } else {
      bankTran = AddedBankDetails;
    }
    e.preventDefault();
    // if (checkErrors2()) {
    let otherLoanSanctionedToShgSum = 0,
      otherLoanSanctionedToMemberSum = 0,
      otherLoanPaidToShgSum = 0,
      otherLoanPaidToDonorSum = 0,
      batchOtherLoanInterestShgSum = 0,
      batchOtherLoanInterestDonorSum = 0,
      subsidyReceivedSum = 0,
      indSubsidyDistributedSum = 0;

    AddedDetails.map((AddDetail, idx) => {
      otherLoanSanctionedToShgSum += parseFloat(
        AddDetail.otherLoanSanctionedToShg || 0
      );
      otherLoanSanctionedToMemberSum += parseFloat(
        AddDetail.batchOtherLoanAmts || 0
      );
      otherLoanPaidToShgSum += parseFloat(AddDetail.otherLoanPaidToShg || 0);
      otherLoanPaidToDonorSum += parseFloat(
        AddDetail.otherLoanPaidToDonor || 0
      );
      batchOtherLoanInterestShgSum += parseFloat(
        AddDetail.batchOtherLoanInterestShg || 0
      );
      batchOtherLoanInterestDonorSum += parseFloat(
        AddDetail.batchOtherLoanInterestDonor || 0
      );
      subsidyReceivedSum += parseFloat(AddDetail.subsidyReceived || 0);
      indSubsidyDistributedSum += parseFloat(AddDetail.subsidyDistributed || 0);
    });

    let batchBankDepositSum = 0,
      batchBankWithdrawalsSum = 0,
      bankInterestSum = 0,
      bankCommissionSum = 0;

    bankTran.map((AddDetail) => {
      batchBankDepositSum += parseFloat(AddDetail.indBatchBankDeposit || 0);
      batchBankWithdrawalsSum += parseFloat(
        AddDetail.indBatchBankWithdrawals || 0
      );
      bankInterestSum += parseFloat(AddDetail.indBankInterest || 0);
      bankCommissionSum += parseFloat(AddDetail.indBankCommission || 0);
    });
    var dateVal = new Date(OpBalDate.mdDate);

    const finalData = {
      batchId: batchIdValue.batchIdVal,
      batchName: selectedBatchData[0].batchName,
      batchMeetingHeldOnDate: OpBalDate.mdDate,
      batchMeetingMonth: dateVal.getMonth() + 1,
      batchMeetingYear: dateVal.getFullYear(),
      batchTransactionType: selectedBatchData[0].batchTransactionType,
      meetingCategory: "OP",
      //INCOME
      batchSavingAmt: batchSavingAmt,
      batchSubAmt: batchSubAmt,
      batchOtherContribution: batchOtherContribution
        ? batchOtherContribution
        : 0,
      batchMembershipFees: batchMembershipFees ? batchMembershipFees : 0,
      bankInterest: bankInterestSum ? Number(bankInterestSum).toFixed(2) : 0, //sumB
      deactiveMemberSaving: deactiveMemberSaving ? deactiveMemberSaving : 0,
      subsidyReceived: subsidyReceivedSum
        ? Number(subsidyReceivedSum).toFixed(2)
        : 0, //sum,
      batchOtherIncome: batchOtherIncome ? batchOtherIncome : 0,
      seedMoney: seedMoney ? seedMoney : 0,
      batchSecurityDepositMemToShg: batchSecurityDepositMemToShg
        ? batchSecurityDepositMemToShg
        : 0,
      sdUnionToSHG: sdUnionToSHG ? sdUnionToSHG : 0,
      loanRepaidByOtherSHG: loanRepaidByOtherSHG ? loanRepaidByOtherSHG : 0,
      //EXPENSE
      travellingExpenses: travellingExpenses ? travellingExpenses : 0,
      stationaryExpenses: stationaryExpenses ? stationaryExpenses : 0,
      paidToMahasangha: paidToMahasangha ? paidToMahasangha : 0,
      bankCommission: bankCommissionSum
        ? Number(bankCommissionSum).toFixed(2)
        : 0, //sumB
      serviceCharges: serviceCharges ? serviceCharges : 0,
      batchDividendDistributed: batchDividendDistributed
        ? batchDividendDistributed
        : 0,
      otherExpenses: otherExpenses ? otherExpenses : 0,
      batchSavingWithdrawals: batchSavingWithdrawals
        ? batchSavingWithdrawals
        : 0,
      subsidyDistributed: indSubsidyDistributedSum
        ? Number(indSubsidyDistributedSum).toFixed(2)
        : 0, //sum
      batchSecurityDeposit: batchSecurityDeposit ? batchSecurityDeposit : 0,
      donationToOutsider: donationToOutsider ? donationToOutsider : 0,

      sdSHGToUnion: sdSHGToUnion ? sdSHGToUnion : 0,
      loanGivenToOtherSHG: loanGivenToOtherSHG ? loanGivenToOtherSHG : 0,
      //LOAN DETAILS
      batchLoanAmt: batchLoanSanctionedAmt ? batchLoanSanctionedAmt : 0,
      batchLoanPaid: batchLoanPaid ? batchLoanPaid : 0,
      batchInterestPaid: batchInterestPaid ? batchInterestPaid : 0,
      //OTHER LOAN DETAILS
      otherLoanSanctionedToShg: otherLoanSanctionedToShgSum
        ? Number(otherLoanSanctionedToShgSum).toFixed(2)
        : 0, //sum
      otherLoanSanctionedToMember: otherLoanSanctionedToMemberSum
        ? Number(otherLoanSanctionedToMemberSum).toFixed(2)
        : 0, //sum
      otherLoanPaidToShg: otherLoanPaidToShgSum
        ? Number(otherLoanPaidToShgSum).toFixed(2)
        : 0, //sum
      otherLoanPaidToDonor: otherLoanPaidToDonorSum
        ? Number(otherLoanPaidToDonorSum).toFixed(2)
        : 0, //sum
      batchOtherLoanInterestShg: batchOtherLoanInterestShgSum
        ? Number(batchOtherLoanInterestShgSum).toFixed(2)
        : 0, //sum
      batchOtherLoanInterestDonor: batchOtherLoanInterestDonorSum
        ? Number(batchOtherLoanInterestDonorSum).toFixed(2)
        : 0, //sum
      otherLoanBatchTransaction: AddedDetails,
      //BANK DETAILS
      cashInHand: cashInHand || 0,
      batchBankDeposit: batchBankDepositSum
        ? Number(batchBankDepositSum).toFixed(2)
        : 0, //sumB
      bankTransactions: bankTran,
      //OTHER
      btEnteredById: user && user._id,
      btEnteredByName: user && user.userName,
      btEnteredDate: new Date().toISOString().split("T")[0],
      institutionId: user && user.institutionId,
    };
    BatchTransaction(finalData);

    const meetingData = {
      mdDate: OpBalDate.mdDate,
      mdBatchId: batchIdValue.batchIdVal,
      mdStatus: "Submitted",
      mdMonth: dateVal.getMonth() + 1,
      mdYear: dateVal.getFullYear(),
      mdEnteredById: user && user._id,
      mdEnteredByName: user && user.userFullName,
      institutionId: user && user.institutionId,
    };
    meetingDates(meetingData);
    setFormData({
      ...formData,
      isSubmitted: true,
    });
    // }
  };
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (isSubmitted) {
    return <Redirect to="/landing-page" />;
  }
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="row col-lg-12 col-md-11 col-sm-12 col-12">
          <h2 className="">
            Add Opening Balance Details for {batchIdValue.batchNameVal}
          </h2>
          <hr />
        </div>

        <section className="sub_reg">
          <Tabs selectedIndex={tabIndex}>
            <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
              <TabList>
                <Tab tabfor="0">Income</Tab>
                <Tab tabfor="1">Expense</Tab>
                <Tab tabfor="2">Loan Details</Tab>
                <Tab tabfor="3">Other Loan Details</Tab>
                <Tab tabfor="4">Bank Details</Tab>
              </TabList>
            </div>

            <TabPanel tabId="0">
              <form onSubmit={(e) => NextBackBtn(1)}>
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                    <div className="row card-new  py-3">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5>Income</h5>
                      </div>

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Total SHG Saving Amount* :
                        </label>
                        <input
                          type="number"
                          name="batchSavingAmt"
                          value={batchSavingAmt}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Total Subscription* :
                        </label>
                        <input
                          type="number"
                          name="batchSubAmt"
                          value={batchSubAmt}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Total Other Contribution :
                        </label>
                        <input
                          type="number"
                          name="batchOtherContribution"
                          value={batchOtherContribution}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Membership Fees :
                        </label>
                        <input
                          type="number"
                          name="batchMembershipFees"
                          value={batchMembershipFees}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      {/* <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Total Bank Interest :
                        </label>
                        <input
                          type="number"
                          name="bankInterest"
                          value={bankInterest}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div> */}
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Deactive member Saving :
                        </label>
                        <input
                          type="number"
                          name="deactiveMemberSaving"
                          value={deactiveMemberSaving}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      {/* <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Subsidy Received To SHG :
                        </label>
                        <input
                          type="number"
                          name="subsidyReceived"
                          value={subsidyReceived}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                         onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div> */}

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">Other Income :</label>
                        <input
                          type="number"
                          name="batchOtherIncome"
                          value={batchOtherIncome}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">Seed Money :</label>
                        <input
                          type="number"
                          name="seedMoney"
                          value={seedMoney}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Security Deposit Member To SHG :
                        </label>
                        <input
                          type="number"
                          name="batchSecurityDepositMemToShg"
                          value={batchSecurityDepositMemToShg}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          SD Union To SHG :
                        </label>
                        <input
                          type="number"
                          name="sdUnionToSHG"
                          value={sdUnionToSHG}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      {allPermission &&
                      allPermission.includes("SHG-to-SHGLoan") ? (
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Loan Repaid By Other SHG:
                          </label>
                          <input
                            type="number"
                            name="loanRepaidByOtherSHG"
                            value={loanRepaidByOtherSHG}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <input
                          type="submit"
                          name="submit"
                          value="Next"
                          className="btn sub_form btn_continue Save float-right"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </TabPanel>
            <TabPanel tabId="1">
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                  <div className="row card-new  py-3">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h5>Expense</h5>
                    </div>

                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Travelling Expense :
                      </label>
                      <input
                        type="number"
                        name="travellingExpenses"
                        value={travellingExpenses}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Stationary Expense :
                      </label>
                      <input
                        type="number"
                        name="stationaryExpenses"
                        value={stationaryExpenses}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Paid to Union 
                        <inline
                            //className="cstm-hint"
                            id="pass_admin_help"
                            className="mr-4"
                            // style={{
                            //   top: "19px",
                            //    right: "185px",
                            //   cursor: "pointer",
                              
                            // }}
                            >&nbsp;
                            <img
                              src={require("../../static/images/info.png")}
                              style={{height:"18px",width:"18px"}}
                              alt="help"
                              id="img_tool_admin"
                              className="pass_admin_help_icon_question "
                            />
                      
                      <div
                              id="tooltipPassAdmin"
                              className="syle-hint "
                              style={passwrdTooltip}
                              data-hint="Mahasanga/Federation/General HUB"
                            ></div>
                            <inline>&nbsp;:</inline>
                          </inline>
                          </label>
                      <input
                        type="number"
                        name="paidToMahasangha"
                        value={paidToMahasangha}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    {/* <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">Bank Commission :</label>
                      <input
                        type="number"
                        name="bankCommission"
                        value={bankCommission}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div> */}
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">Service Charge :</label>
                      <input
                        type="number"
                        name="serviceCharges"
                        value={serviceCharges}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Dividend Distributed :
                      </label>
                      <input
                        type="number"
                        name="batchDividendDistributed"
                        value={batchDividendDistributed}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">Other Expense :</label>
                      <input
                        type="number"
                        name="otherExpenses"
                        value={otherExpenses}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>

                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Savings Withdrawal :
                      </label>
                      <input
                        type="number"
                        name="batchSavingWithdrawals"
                        value={batchSavingWithdrawals}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Security Deposit SHG To Member:
                      </label>
                      <input
                        type="number"
                        name="batchSecurityDeposit"
                        value={batchSecurityDeposit}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) =>
                          (e.keyCode === 69 || e.keyCode === 190) &&
                          e.preventDefault()
                        }
                      />
                    </div>
                    {/* <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Donation To Outsider:
                      </label>
                      <input
                        type="number"
                        name="donationToOutsider"
                        value={donationToOutsider}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) =>
                          (e.keyCode === 69 || e.keyCode === 190) &&
                          e.preventDefault()
                        }
                      />
                    </div> */}

                    <div className="col-lg-3 col-md-12 col-sm-12 col-12 ">
                      <label className="label-control">SD SHG To Union :</label>
                      <input
                        type="number"
                        name="sdSHGToUnion"
                        value={sdSHGToUnion}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>

                    {allPermission &&
                    allPermission.includes("SHG-to-SHGLoan") ? (
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12 ">
                        <label className="label-control">
                          Loan Given To Other SHG :
                        </label>
                        <input
                          type="number"
                          name="loanGivenToOtherSHG"
                          value={loanGivenToOtherSHG}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Subsidy Distributed :
                      </label>
                      <input
                        type="number"
                        name="subsidyDistributed"
                        value={subsidyDistributed}  
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div> */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <button
                        className="btn sub_form btn_continue Save float-right"
                        onClick={() => NextBackBtn(2)}
                      >
                        Next
                      </button>
                      <button
                        className="btn sub_form btn_continue Save float-right"
                        onClick={() => NextBackBtn(0)}
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel tabId="2">
              <div className="row col-md-12 col-lg-11 col-sm-12 col-12 ">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                  <div className="row card-new  py-3">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h5>Loan Details</h5>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Total Loan Sanctioned to Member :
                      </label>
                      <input
                        type="number"
                        name="batchLoanSanctionedAmt"
                        value={batchLoanSanctionedAmt}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Total Loan Repayment to SHG :
                      </label>
                      <input
                        type="number"
                        name="batchLoanPaid"
                        value={batchLoanPaid}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Total Loan Interest Paid to SHG :
                      </label>
                      <input
                        type="number"
                        name="batchInterestPaid"
                        value={batchInterestPaid}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    {/* <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        {" "}
                        Total Other Loan Sanctioned To SHG :
                      </label>
                      <input
                        type="number"
                        name="otherLoanSanctionedToShg"
                        value={otherLoanSanctionedToShg}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div> */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <button
                        className="btn sub_form btn_continue Save float-right"
                        onClick={() => NextBackBtn(3)}
                      >
                        Next
                      </button>
                      <button
                        className="btn sub_form btn_continue Save float-right"
                        onClick={() => NextBackBtn(1)}
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="3">
              <div className="row col-md-12 col-lg-11 col-sm-12 col-12 ">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                  {activeOtherLoan.length > 1 ? (
                    <form onSubmit={(e) => onAdd(e)}>
                      <div className="row card-new  py-3">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h5>Other Loan Details</h5>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                            Other Loan Types* :
                          </label>

                          <Select
                            name="otherLoanTypes"
                            options={activeOtherLoan}
                            isSearchable={true}
                            value={otherLoanType}
                            placeholder="Select Mode"
                            onChange={(e) => onotherLoanTypeChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Total OL Sanctioned To SHG :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="otherLoanSanctionedToShg"
                            value={otherLoanSanctionedToShg}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Total OL Sanctioned To Member :{" "}
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="batchOtherLoanAmts"
                            value={batchOtherLoanAmts}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Total OL Paid To SHG :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="otherLoanPaidToShg"
                            value={otherLoanPaidToShg}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            {" "}
                            Total OL Paid To Donor :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="otherLoanPaidToDonor"
                            value={otherLoanPaidToDonor}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Total OL Interest Paid To SHG :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="batchOtherLoanInterestShg"
                            value={batchOtherLoanInterestShg}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Total OL Interest Paid To Donor :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="batchOtherLoanInterestDonor"
                            value={batchOtherLoanInterestDonor}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Subsidy Received To SHG :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="subsidyReceived"
                            value={subsidyReceived}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Subsidy Distributed :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="subsidyDistributed"
                            value={subsidyDistributed}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <input
                            type="submit"
                            name="Submit"
                            value="ADD"
                            className="btn sub_form btn_continue blackbrd Save float-right"
                          />
                        </div>
                        <div className=" body-inner no-padding  table-responsive">
                          <table
                            className="tabllll table table-bordered table-striped table-hover"
                            id="datatable2"
                          >
                            <thead>
                              <tr>
                                <th>OL Type</th>
                                <th>OL Sanctioned To SHG</th>
                                <th>Loan Amts</th>
                                <th>OL Paid To SHG</th>
                                <th>OL Paid To Donor</th>
                                <th>OL Interest SHG</th>
                                <th>Subsidy Received</th>
                                <th>Subsidy Distributed</th>
                                <th>OL Interest Donor</th>
                                <th>Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                              {AddedDetails &&
                                AddedDetails.map((AddDetail, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>{AddDetail.otherLoanType}</td>
                                      <td>
                                        {AddDetail.otherLoanSanctionedToShg}
                                      </td>
                                      <td>{AddDetail.batchOtherLoanAmts}</td>
                                      <td>{AddDetail.otherLoanPaidToShg}</td>
                                      <td>{AddDetail.otherLoanPaidToDonor}</td>
                                      <td>
                                        {AddDetail.batchOtherLoanInterestShg}
                                      </td>
                                      <td>{AddDetail.subsidyReceived}</td>
                                      <td>{AddDetail.subsidyDistributed}</td>
                                      <td>
                                        {AddDetail.batchOtherLoanInterestDonor}
                                      </td>
                                      <td>
                                        <img
                                          className="img_icon_size log"
                                          onClick={() =>
                                            onRemoveChange(
                                              AddDetail.otherLoanTypeId
                                            )
                                          }
                                          src={require("../../static/images/close-buttonRed.png")}
                                          alt="Remove"
                                          title="Remove"
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <button
                            className="btn sub_form btn_continue Save float-right"
                            onClick={() => NextBackBtn(4)}
                          >
                            Next
                          </button>
                          <button
                            className="btn sub_form btn_continue Save float-right"
                            onClick={() => NextBackBtn(2)}
                          >
                            Previous
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div className="row card-new  py-3">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5>Other Loan Details</h5>
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Total OL Sanctioned To SHG :
                        </label>
                        <input
                          type="number"
                          name="otherLoanSanctionedToShg"
                          value={otherLoanSanctionedToShg}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Total OL Sanctioned To Member :{" "}
                        </label>
                        <input
                          type="number"
                          name="batchOtherLoanAmts"
                          value={batchOtherLoanAmts}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Total OL Paid To SHG :
                        </label>
                        <input
                          type="number"
                          name="otherLoanPaidToShg"
                          value={otherLoanPaidToShg}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          {" "}
                          Total OL Paid To Donor :
                        </label>
                        <input
                          type="number"
                          name="otherLoanPaidToDonor"
                          value={otherLoanPaidToDonor}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Total OL Interest Paid To SHG :
                        </label>
                        <input
                          type="number"
                          name="batchOtherLoanInterestShg"
                          value={batchOtherLoanInterestShg}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Total OL Interest Paid To Donor :
                        </label>
                        <input
                          type="number"
                          name="batchOtherLoanInterestDonor"
                          value={batchOtherLoanInterestDonor}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Subsidy Received To SHG :
                        </label>
                        <input
                          type="number"
                          name="subsidyReceived"
                          value={subsidyReceived}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Subsidy Distributed :
                        </label>
                        <input
                          type="number"
                          name="subsidyDistributed"
                          value={subsidyDistributed}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <button
                          className="btn sub_form btn_continue Save float-right"
                          onClick={() => onAddOLRSingle(4)}
                        >
                          Next
                        </button>
                        <button
                          className="btn sub_form btn_continue Save float-right"
                          onClick={() => NextBackBtn(2)}
                        >
                          Previous
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="4">
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                  {activeBank.length > 1 ? (
                    <>
                      <form onSubmit={(e) => onAddBank(e)}>
                        {/* <form onSubmit={(e) => onSubmit(e)}> */}
                        <div className="row card-new  py-3">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h5>Bank Details</h5>
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Cash In Hand :
                            </label>
                            <input
                              type="number"
                              name="cashInHand"
                              value={cashInHand}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label
                              className="label-control"
                              style={bnkErrorStyle}
                            >
                              Banks* :
                            </label>
                            <Select
                              name="bankNames"
                              options={activeBank}
                              isSearchable={true}
                              value={bank}
                              placeholder="Select Mode"
                              onChange={(e) => onBankChange(e)}
                              theme={(theme) => ({
                                ...theme,
                                height: 26,
                                minHeight: 26,
                                borderRadius: 1,
                                colors: {
                                  ...theme.colors,
                                  primary: "black",
                                },
                              })}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Total Bank Interest :
                            </label>
                            <input
                              type="number"
                              name="bankGridInterest"
                              value={bankGridInterest}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Total Bank Commission :
                            </label>
                            <input
                              type="number"
                              name="bankGridCommission"
                              value={bankGridCommission}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>

                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Opening Bank Balance :
                            </label>
                            <input
                              type="number"
                              name="bankGridDeposit"
                              value={bankGridDeposit}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                              required
                            />
                          </div>

                          {/* <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Cash In Hand :
                            </label>
                            <input
                              type="number"
                              name="gridcashInHand"
                              value={gridcashInHand}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div> */}
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <input
                              type="submit"
                              name="Submit"
                              value="ADD"
                              className="btn sub_form btn_continue blackbrd Save float-right"
                            />
                          </div>
                        </div>
                      </form>
                      <div className=" body-inner no-padding  table-responsive">
                        <table
                          className="tabllll table table-bordered table-striped table-hover"
                          id="datatable2"
                        >
                          <thead>
                            <tr>
                              <th>Bank Name</th>
                              <th>Total Bank Interest</th>
                              <th>Total Bank Commission</th>
                              <th>Opening Bank Balance</th>
                              {/* <th>Cash In Hand</th> */}
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {AddedBankDetails &&
                              AddedBankDetails.map((BankDetail, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{BankDetail.batchBankName}</td>
                                    <td>{BankDetail.indBankInterest}</td>
                                    <td>{BankDetail.indBankCommission}</td>
                                    <td>{BankDetail.indBatchBankDeposit}</td>
                                    {/* <td>{BankDetail.indcashInHand}</td> */}
                                    <td>
                                      <img
                                        className="img_icon_size log"
                                        onClick={() =>
                                          onRemoveChangeBank(
                                            BankDetail.bankGridName
                                          )
                                        }
                                        src={require("../../static/images/close-buttonRed.png")}
                                        alt="Remove"
                                        title="Remove"
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : activeBank.length === 1 ? (
                    <>
                      <div className="row card-new  py-3">
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Cash In Hand :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="cashInHand"
                            value={cashInHand}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Total Bank Interest :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="bankGridInterest"
                            value={bankGridInterest}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange2(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Total Bank Commission :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="bankGridCommission"
                            value={bankGridCommission}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange2(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label
                            className="label-control"
                            style={openBalErrorStyle}
                          >
                            Opening Bank Balance* :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="bankGridDeposit"
                            value={bankGridDeposit}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange2(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                            required
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row card-new  py-3">
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Cash In Hand :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="cashInHand"
                            value={cashInHand}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    {loading ? (
                      <button
                        className="btn sub_form btn_continue blackbrd Save float-right"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <input
                        type="submit"
                        name="Save"
                        value="Submit"
                        onClick={(e) => onSubmit(e)}
                        className="btn sub_form btn_continue Save float-right"
                      />
                    )}

                    <button
                      className="btn sub_form btn_continue Save float-right"
                      onClick={() => NextBackBtn(3)}
                    >
                      Previous
                    </button>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </section>

        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <label className="label-control colorRed">
            * Indicates mandatory fields, Please fill mandatory fields before
            Submit
          </label>
        </div>
      </div>
    </Fragment>
  );
};

OpeningBalforBatchMeetingDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  BatchTransaction: PropTypes.func.isRequired,
  meetingDates: PropTypes.func.isRequired,
  getParticularLoanReason: PropTypes.func.isRequired,
  getMembersOfSelectedBatch: PropTypes.func.isRequired,
  checkAvailableDate: PropTypes.func.isRequired,
  removeMessage: PropTypes.func.isRequired,
  getMeetingSummary: PropTypes.func.isRequired,
  AddLoanSanctionedDetails: PropTypes.func.isRequired,
  getActiveOtherLoanType: PropTypes.func.isRequired,
  getActiveBankData: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  BatchTransaction,
  meetingDates,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  checkAvailableDate,
  removeMessage,
  getMeetingSummary,
  AddLoanSanctionedDetails,
  getActiveOtherLoanType,
  getActiveBankData,
})(OpeningBalforBatchMeetingDetails);
