import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getActiveInstitutions } from "../../actions/shg";
import AllOcccupation from "./AllOccupation";
import AllSubOccupation from "./AllSubOccupation";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Spinner from "../layout/Spinner";

const AllOccupationSettings = ({
  auth: { isAuthenticated, user, users },
  getActiveInstitutions,
}) => {
  useEffect(() => {
    getActiveInstitutions();
  }, [getActiveInstitutions]);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">All Occupations Setting </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <Tabs>
            <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
              <TabList>
                <Tab style={{ cursor: "pointer" }}>Occupations</Tab>
                {allPermission && allPermission.includes("Sub Occupation") && (
                  <Tab style={{ cursor: "pointer" }}>Sub Occupations</Tab>
                )}
              </TabList>
            </div>
            <TabPanel>
              <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                <AllOcccupation />
              </div>
            </TabPanel>
            <TabPanel>
              {allPermission && allPermission.includes("Sub Occupation") && (
                <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                  <AllSubOccupation />
                </div>
              )}
            </TabPanel>
          </Tabs>
        </section>
      </div>
    </Fragment>
  );
};

AllOccupationSettings.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getActiveInstitutions: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getActiveInstitutions,
})(AllOccupationSettings);
