import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllLoanReason } from "../../actions/shg";
import AddPurpose from "./AddPurpose";
import EditLoanPurpose from "./EditLoanPurpose";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import DeactiveLoanPurpose from "./DeactiveLoanPurpose";
const AllLoanPurpose = ({
  auth: { allUser, isAuthenticated, user, users },
  shg: { allloanreason },
  getAllLoanReason,
}) => {
  useEffect(() => {
    getAllLoanReason();
  }, [getAllLoanReason]);

  const [showAddPurposeModal, setShowAddLoanPurposeModal] = useState(false);
  const handleAddLoanPurposeModalClose = () =>
    setShowAddLoanPurposeModal(false);
  const onClickHandler = () => {
    setShowAddLoanPurposeModal(true);
  };

  const onAddLoanPurposeModalChange = (e) => {
    if (e) {
      handleAddLoanPurposeModalClose();
    }
  };
  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (loanpurposedata, idx) => {
    setShowUpdateModal(true);
    setUserData(loanpurposedata);
  };

  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const [userDatas, setUserDatas] = useState(null);
  const onUpdate = (allloanreason, idx) => {
    setShowDeactiveModal(true);
    setUserDatas(allloanreason);
  };
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">All Loan Purpose </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-11 col-md-11 col-sm-12 col-11 py-3">
              <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <img
                className="img_icon_size log float-right"
                onClick={() => onClickHandler()}
                src={require("../../static/images/add-icon.png")}
                alt="Add Loan Purpose"
                title="Add Loan Purpose"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Loan Purpose</th>

                        <th>Op</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allloanreason &&
                        allloanreason.map((allloanreason, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{allloanreason.loanPurpose}</td>

                              <td>
                                {allloanreason.loanReasonStatus &&
                                allloanreason.loanReasonStatus !==
                                  "Deactive" ? (
                                  <img
                                    className="img_icon_size log"
                                    onClick={() => onUpdate(allloanreason, idx)}
                                    src={require("../../static/images/delete.png")}
                                    alt="Deactivate"
                                    title="Deactivate"
                                  />
                                ) : (
                                  <Fragment></Fragment>
                                )}
                                &nbsp;
                                <img
                                  className="img_icon_size log"
                                  onClick={() => onEdit(allloanreason, idx)}
                                  src={require("../../static/images/edit_icon.png")}
                                  alt="Edit"
                                  title="Edit"
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </section>
              <Modal
                show={showAddPurposeModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <div className="col-lg-10">
                    <h3 className="modal-title text-center">
                      Add Loan Purpose
                    </h3>
                  </div>
                  <div className="col-lg-2">
                    <button
                      onClick={handleAddLoanPurposeModalClose}
                      className="close"
                    >
                      <img
                        src={require("../../static/images/close.png")}
                        alt="X"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <AddPurpose
                    onAddLoanPurposeModalChange={onAddLoanPurposeModalChange}
                  />
                </Modal.Body>
              </Modal>
              <Modal
                show={showUpdateModal}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <div className="col-lg-10">
                    <h3 className="modal-title text-center">
                      Edit Loan Purpose{" "}
                    </h3>
                  </div>
                  <div className="col-lg-2">
                    <button onClick={handleUpdateModalClose} className="close">
                      <img
                        src={require("../../static/images/close.png")}
                        alt="X"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <EditLoanPurpose
                    loanpurposedata={userData}
                    onUpdateModalChange={onUpdateModalChange}
                  />
                </Modal.Body>
              </Modal>
              <Modal
                show={showDeactiveModal}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <div className="col-lg-10">
                    <h3 className="modal-title text-center">Deactivate</h3>
                  </div>
                  <div className="col-lg-2">
                    <button
                      onClick={handleDeactiveModalClose}
                      className="close"
                    >
                      <img
                        src={require("../../static/images/close.png")}
                        alt="X"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <DeactiveLoanPurpose
                    allloanreason={userDatas}
                    onDeactiveModalChange={onDeactiveModalChange}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </section>
        <div className="row">
          <div className="col-lg-11 col-md-6 col-sm-12 col-12 align_right">
            <label className="float-right ">
              No. of Loan Purposes : {allloanreason.length}
            </label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AllLoanPurpose.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getAllLoanReason: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getAllLoanReason,
})(AllLoanPurpose);
