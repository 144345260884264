import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
const ImgPreview = ({
  auth: { isAuthenticated, user, users, loading },
  show,
  setShow,
  data,
}) => {
  return !isAuthenticated || !user || !users ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">Image Preview</h3>
          </div>
          <div className="col-lg-2">
            <button
              onClick={(e) => {
                e.preventDefault();
                setShow(false);
              }}
              className="close"
            >
              <img style={{height :"20px",width:"20px"}} src={require("../../static/images/close.png")} alt="X" />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <img
            alt="Preview"
            style={{ width: "100%", height: "100%" }}
            src={`${data}`}
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

ImgPreview.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(ImgPreview);
