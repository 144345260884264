import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { getAllInstiLoan, addWaveOffNotes } from "../../actions/shg";
import Pagination from "../layout/Pagination";
import Spinner from "../layout/Spinner";
import { CSVLink } from "react-csv";
import { getAllCoordinators, getAllIncharge } from "../../actions/user";

export const SeeAllMemLoan = ({
  getAllInstiLoan,
  getAllCoordinators,
  getAllIncharge,
  addWaveOffNotes,
  shg: { allInstLoan },
  user: { allcoordinator, allincharge },
}) => {
  useEffect(() => {
    getAllInstiLoan();
    getAllCoordinators();
    getAllIncharge();
  }, []);

  const [waieOffForm, setWaieOffForm] = useState({
    waieOffAmount: 0,
    waieOffNotes: "",
    loanStatus: { label: "Closed", value: "Closed" },
  });

  const [filterForm, setFilterForm] = useState({
    coordinator: "",
    incharge: "",
    isLoanType: "",
  });

  let { waieOffAmount, waieOffNotes, loanStatus } = waieOffForm;
  let { coordinator, incharge, isLoanType } = filterForm;
  const onInputIncomeChange = (e, labelName) => {
    if (!labelName) {
      setWaieOffForm({
        ...waieOffForm,
        [e.target.name]: e.target.value,
      });
    } else {
      setWaieOffForm({ ...waieOffForm, [labelName]: e });
    }
  };

  const allCoordinatorList = allcoordinator.map((ele) => {
    return { label: ele.userFullName, value: ele._id };
  });
  const allinchargeList = allincharge.map((ele) => {
    return { label: ele.userFullName, value: ele._id };
  });

  //total payable Amt
  const totalAddAmt = (val1, Val2) => {
    return Number(val1) + Number(Val2);
  };

  //total Due principal Amt
  const totalSubAmt = (Val1, Val2) => {
    return Number(Val1) - Number(Val2);
  };

  //Modal State
  const [showWaveOffModal, setShowWaveOffModal] = useState({
    status: false,
    data: null,
  });

  //pagination code
  const [currentData, setCurrentData] = useState(1);
  const [dataPerPage] = useState(9);
  //Get Current Data
  const indexOfLastData = currentData * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentDatas =
    allInstLoan && allInstLoan.slice(indexOfFirstData, indexOfLastData);
  //change page
  const paginate = (nmbr) => {
    setCurrentData(nmbr);
  };
  //pagination code ends

  const dateFormat = (data) => {
    try {
      return data
        .split("-")
        .reverse()
        .join("-");
    } catch (er) {
      return 0;
    }
  };

  const onSubmitWaveOff = async (e) => {
    e.preventDefault();

    const waieOffData = {
      _id: showWaveOffModal.data._id,
      waieOffAmt: waieOffAmount,
      notes: waieOffNotes,
    };

    await addWaveOffNotes(waieOffData);
    setShowWaveOffModal(false);
  };

  const sumOf = (array, value) => {
    try {
      return array.reduce((acu, cur) => (acu += Number(cur[value] || 0)), 0);
    } catch (er) {
      return 0;
    }
  };

  const onFilterChange = (e, labelName) => {
    if (labelName == "coordinator") {
      setFilterForm({ ...filterForm, incharge: "", [labelName]: e });
      getAllInstiLoan({ ...filterForm, incharge: "", [labelName]: e });
    } else if (labelName == "incharge") {
      setFilterForm({ ...filterForm, coordinator: "", [labelName]: e });
      getAllInstiLoan({ ...filterForm, coordinator: "", [labelName]: e });
    } else {
      getAllInstiLoan({ ...filterForm, [labelName]: e });
    }
  };

  const csvData = [
    ["Loan Status Report"],
    [
      // "SL No",
      "Member Name",
      "SHG Name",
      "Loan Type",
      "Sanctioned Date",
      "Loan Status(Principal)",
      "Sanctioned Principal",
      "Sanctioned Interest",
      "Total Sanctioned",
      "Paid Principal",
      "Paid Interest",
      "Total paid",
      "Due Principal",
      "Due Interest",
      "Total Due",
      "Waive Off Amount",
      "Final Due",
      "Notes",
    ],
  ];

  allInstLoan.map((allInstLoan) => {
    return csvData.push([
      allInstLoan.memberName,
      allInstLoan.batch.batchName,
      allInstLoan.isLoanType,
      allInstLoan.loanTakenDate,
      allInstLoan.loanStatus,
      allInstLoan.loanPayablePrincipal,
      allInstLoan.loanPayableInterest,
      totalAddAmt(
        allInstLoan.loanPayablePrincipal,
        allInstLoan.loanPayableInterest
      ),
      sumOf(allInstLoan.loanRepayment, "loanPrincipalPaid"),
      sumOf(allInstLoan.loanRepayment, "loanInterestPaid"),
      sumOf(allInstLoan.loanRepayment, "loanPrincipalPaid") +
        sumOf(allInstLoan.loanRepayment, "loanInterestPaid"),
      totalSubAmt(
        allInstLoan.loanPayablePrincipal,
        sumOf(allInstLoan.loanRepayment, "loanPrincipalPaid")
      ),
      totalSubAmt(
        allInstLoan.loanPayableInterest,
        sumOf(allInstLoan.loanRepayment, "loanInterestPaid")
      ),
      totalSubAmt(
        totalAddAmt(
          allInstLoan.loanPayablePrincipal,
          allInstLoan.loanPayableInterest
        ),
        sumOf(allInstLoan.loanRepayment, "loanPrincipalPaid") +
          sumOf(allInstLoan.loanRepayment, "loanInterestPaid")
      ),
      allInstLoan.waieOffAmt,
      totalSubAmt(
        allInstLoan.loanPayableInterest,
        sumOf(allInstLoan.loanRepayment, "loanInterestPaid")
      ) - Number(allInstLoan.waieOffAmt || 0),
      allInstLoan.notes,
    ]);
  });

  // if (allInstLoan.length == 0) {
  //   return <Spinner />;
  // } else {

  return (
    <div>
      <div className="container container_align">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color"> Loan Status Report</h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding">
            <>
              <div className="col-lg-2 col-md-3 col-sm-3 col-11 mt-1">
                <Select
                  name="coordinator"
                  options={allCoordinatorList}
                  isSearchable={true}
                  placeholder="Select Cordinator"
                  value={coordinator}
                  onChange={(e) => {
                    // setFilterForm({ ...filterForm, incharge: "" });
                    onFilterChange(e, "coordinator");
                  }}
                />
              </div>
            </>

            <div className="col-lg-2 col-md-3 col-sm-3 col-11 mt-1">
              <Select
                name="incharge"
                options={allinchargeList}
                isSearchable={true}
                placeholder="Select Incharge"
                onChange={(e) => {
                  // setFilterForm({ ...filterForm, coordinator: "" });
                  onFilterChange(e, "incharge");
                }}
                value={incharge}
                menuPortalTarget={document.body} // Use this to control z-index
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Set the desired z-index
                }}
              />
            </div>

            <div className="col-lg-2 col-md-3 col-sm-3 col-11 mt-1">
              <Select
                name="isLoanType"
                options={[
                  { label: "Internal", value: "Internal" },
                  { label: "otherLoan", value: "otherLoan" },
                ]}
                isSearchable={true}
                placeholder="Select Loan Type"
                value={isLoanType}
                onChange={(e) => onFilterChange(e, "isLoanType")}
              />
            </div>

            <div className="col-lg-2 col-md-3 col-sm-3 col-11 mt-1"></div>

            <div className="col-lg-12 col-md-3 col-sm-4 col-11 py-3 ">
              <Link to="/all-reports">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>

              <img
                className="img_icon_size log float-right ml-3"
                onClick={() => {
                  getAllInstiLoan();
                  setFilterForm({
                    coordinator: "",
                    incharge: "",
                    isLoanType: "",
                  });
                }}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
              <CSVLink data={csvData}>
                <img
                  className="img_icon_size log float-right ml-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="Excel-Export"
                  title="Excel-Export"
                />
              </CSVLink>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive table-container mt-5">
                  <table
                    className="table table-bordered table-striped table-hover  "
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th style={{ borderRight: "1px solid white" }}>
                          SL No
                        </th>
                        <th
                          className="sticky-column"
                          style={{
                            zIndex: "999",
                            borderRight: "1px solid white",
                          }}
                        >
                          Member Name
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          SHG Name
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Loan Type
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Sanctioned Date
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Loan Status(principal)
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Sanctioned Principal
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Sanctioned Interest
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Total Sanctioned
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Paid Principal
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Paid Interest
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Total Paid
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Due Principal
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Due Interest
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Total Due
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Waive Off Amount
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Final Due
                        </th>
                        <th style={{ borderRight: "1px solid white" }}>
                          Notes
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr style={{ borderBottom: "1px solid black" }}>
                        <td></td>
                        <td className="sticky-column" style={{ zIndex: "999" }}>
                          <b>Total</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{sumOf(currentDatas, "loanPayablePrincipal")}</b>
                        </td>
                        <td>
                          <b>{sumOf(currentDatas, "loanPayableInterest")}</b>
                        </td>
                        <td>
                          <b>
                            {" "}
                            {sumOf(currentDatas, "loanPayablePrincipal") +
                              sumOf(currentDatas, "loanPayableInterest")}
                          </b>
                        </td>
                        <td>
                          <b>
                            {sumOf(
                              currentDatas.map((e) => e.loanRepayment).flat(),
                              "loanPrincipalPaid"
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {" "}
                            {sumOf(
                              currentDatas.map((e) => e.loanRepayment).flat(),
                              "loanInterestPaid"
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {sumOf(
                              currentDatas.map((e) => e.loanRepayment).flat(),
                              "loanPrincipalPaid"
                            ) +
                              sumOf(
                                currentDatas.map((e) => e.loanRepayment).flat(),
                                "loanInterestPaid"
                              )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {totalSubAmt(
                              sumOf(currentDatas, "loanPayablePrincipal"),
                              sumOf(
                                currentDatas.map((e) => e.loanRepayment).flat(),
                                "loanPrincipalPaid"
                              )
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {totalSubAmt(
                              sumOf(currentDatas, "loanPayableInterest"),
                              sumOf(
                                currentDatas.map((e) => e.loanRepayment).flat(),
                                "loanInterestPaid"
                              )
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {totalSubAmt(
                              sumOf(currentDatas, "loanPayablePrincipal") +
                                sumOf(currentDatas, "loanPayableInterest"),
                              sumOf(
                                currentDatas.map((e) => e.loanRepayment).flat(),
                                "loanPrincipalPaid"
                              ) +
                                sumOf(
                                  currentDatas
                                    .map((e) => e.loanRepayment)
                                    .flat(),
                                  "loanInterestPaid"
                                )
                            )}
                          </b>
                        </td>
                        <td>
                          <b>{sumOf(currentDatas, "waieOffAmt")}</b>
                        </td>
                        <td>
                          <b>
                            {" "}
                            {totalSubAmt(
                              sumOf(currentDatas, "loanPayableInterest"),
                              sumOf(
                                currentDatas.map((e) => e.loanRepayment).flat(),
                                "loanInterestPaid"
                              )
                            ) - sumOf(currentDatas, "waieOffAmt")}
                          </b>
                        </td>
                        <td></td>
                      </tr>
                    </thead>

                    <tbody>
                      {currentDatas &&
                        currentDatas.map((loan, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td
                                className="sticky-column"
                                style={{ zIndex: "999" }}
                                onClick={() => {
                                  setShowWaveOffModal({
                                    status: true,
                                    data: loan,
                                  });
                                  setWaieOffForm({
                                    waieOffNotes: loan.notes || "",
                                    waieOffAmount: loan.waieOffAmt || "",
                                    loanStatus: {
                                      label: loan.loanStatus,
                                      value: loan.loanStatus,
                                    },
                                  });
                                }}
                              >
                                <span className="memberName">
                                  {loan.memberName}
                                </span>
                              </td>
                              <td>{loan.batch.batchName}</td>
                              <td>
                                {loan.isLoanType === "Internal"
                                  ? loan.isLoanType +
                                    "(" +
                                    loan.intLoanType +
                                    ")"
                                  : loan.isLoanType}
                              </td>
                              <td>{dateFormat(loan.loanTakenDate)}</td>
                              <td>{loan.loanStatus}</td>
                              <td>{loan.loanPayablePrincipal}</td>
                              <td>{loan.loanPayableInterest}</td>
                              <td>
                                {totalAddAmt(
                                  loan.loanPayablePrincipal,
                                  loan.loanPayableInterest
                                )}
                              </td>

                              <td>
                                {sumOf(loan.loanRepayment, "loanPrincipalPaid")}
                              </td>
                              <td>
                                {sumOf(loan.loanRepayment, "loanInterestPaid")}
                              </td>
                              <td>
                                {sumOf(
                                  loan.loanRepayment,
                                  "loanPrincipalPaid"
                                ) +
                                  sumOf(loan.loanRepayment, "loanInterestPaid")}
                              </td>
                              <td>
                                {totalSubAmt(
                                  loan.loanPayablePrincipal,
                                  sumOf(loan.loanRepayment, "loanPrincipalPaid")
                                )}
                              </td>
                              <td>
                                {totalSubAmt(
                                  loan.loanPayableInterest,
                                  sumOf(loan.loanRepayment, "loanInterestPaid")
                                )}
                              </td>
                              <td>
                                {totalSubAmt(
                                  totalAddAmt(
                                    loan.loanPayablePrincipal,
                                    loan.loanPayableInterest
                                  ),
                                  sumOf(
                                    loan.loanRepayment,
                                    "loanPrincipalPaid"
                                  ) +
                                    sumOf(
                                      loan.loanRepayment,
                                      "loanInterestPaid"
                                    )
                                )}
                              </td>
                              <td>{loan.waieOffAmt || 0}</td>
                              <td>
                                {totalSubAmt(
                                  loan.loanPayableInterest,
                                  sumOf(loan.loanRepayment, "loanInterestPaid")
                                ) - Number(loan.waieOffAmt || 0)}
                              </td>
                              <td>{loan.notes || ""}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {allInstLoan && allInstLoan.length !== 0 ? (
                  <Pagination
                    dataPerPage={dataPerPage}
                    totalData={allInstLoan.length}
                    paginate={paginate}
                    currentPage={currentData}
                  />
                ) : (
                  <></>
                )}
              </section>
            </div>
          </div>
        </section>
      </div>

      <Modal
        show={showWaveOffModal.status}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">Waive off Notes</h3>
          </div>
          <div className="col-lg-2">
            <button
              onClick={() =>
                setShowWaveOffModal({
                  status: false,
                  data: showWaveOffModal.data,
                })
              }
              className="close"
            >
              <img
                src={require("../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => onSubmitWaveOff(e)}>
            <div className="row col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <label className="label-control">Member Name:</label>&nbsp;
                {showWaveOffModal.data && showWaveOffModal.data.memberName}
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <label className="label-control">Loan Type:</label>&nbsp;
                {showWaveOffModal.data && showWaveOffModal.data.isLoanType}
              </div>
            </div>
            {/* <div className="row col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <label className="label-control">Principal Amt:</label>&nbsp;
                {showWaveOffModal.data && showWaveOffModal.data.isLoanType}
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <label className="label-control">Interest Amt:</label>&nbsp;
                {showWaveOffModal.data && showWaveOffModal.data.isLoanType}
              </div>
            </div> */}
            <hr></hr>
            <div className="container">
              <div className="row col-lg-12">
                <div className="col-lg-6">
                  <label htmlFor="deactiveMemberSavingCash">
                    Waive off Amount
                  </label>
                  <input
                    type="number"
                    name="waieOffAmount"
                    value={waieOffAmount}
                    onChange={(e) => onInputIncomeChange(e)}
                    className="form-control"
                    disabled={loanStatus.label === "Open" ? false : true}
                    required
                  />
                  <label htmlFor="deactiveMemberSavingCash">loanStatus</label>
                  <Select
                    type="number"
                    name="loanStatus"
                    value={loanStatus}
                    options={[{ label: "Open", value: "Open" }]}
                    onChange={(e) => onInputIncomeChange(e, "loanStatus")}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="deactiveMemberSavingCash">Notes</label>
                  <textarea
                    name="waieOffNotes"
                    value={waieOffNotes}
                    id="institutionAddress"
                    className="textarea form-control"
                    rows="4"
                    placeholder="Notes"
                    onChange={(e) => onInputIncomeChange(e)}
                    style={{ width: "100%" }}
                    disabled={loanStatus.label === "Open" ? false : true}
                    required
                  ></textarea>
                </div>
              </div>
            </div>

            <button
              type="Submit"
              className="btn sub_form btn_continue Save float-right"
              disabled={loanStatus.label === "Open" ? false : true}
            >
              Update
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
  // }
};

SeeAllMemLoan.propTypes = {
  //   second: PropTypes.third
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  user: state.user,
});

const mapDispatchToProps = {
  getAllInstiLoan,
  getAllCoordinators,
  getAllIncharge,
  addWaveOffNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeeAllMemLoan);
