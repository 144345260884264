import React, { useState } from "react";
import Select from "react-select";
import { editSancLoanDB } from "../../actions/shg";
import { useDispatch } from "react-redux";
const EditCurLoan = ({ ILData, setEditCurLoan, user }) => {
  const dispatch = useDispatch();

  const updateCurILLoan = () => {
    const finalData = {
      _id: ILData._id,
      batchId: ILData.batchId,
      memberId: ILData.memberId,
      meetingHeldOnDate: ILData.meetingHeldOnDate,
      newLoanSancPrinAmt: LoanSansPrin,
      newLoanSancIntAmt: LoanSancInt,
      newLoanSancStatus: LoanStatus.value,
      newLoanSanDur: LoanDuration,
      ILlOanType: ILData.intLoanType,
      institutionId: user && user.institutionId,
      editdByName: user.userFullName,
      editedDateAndTime: new Date(),
    };

    dispatch(editSancLoanDB(finalData));
    setEditCurLoan(false);
  };

  const LoanStatusList = [
    { label: "Active", value: "Active" },
    { label: "Closed", value: "Closed" },
    { label: "Oclosed", value: "Oclosed" },
  ];

  const [LoanStatus, setLoanStatus] = useState({
    label: ILData.loanStatus,
    value: ILData.loanStatus,
  });

  const [LoanSansPrin, setLoanSansPrin] = useState(ILData.loanPayablePrincipal);
  const [LoanSancInt, setLoanSancInt] = useState(ILData.loanPayableInterest);
  const [LoanDuration, setLoanDuration] = useState(ILData.duration);

  return (
    <div>
      <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <input
            type="button"
            name="Back"
            className="btn sub_form btn_continue Save float-left"
            value="Back"
            onClick={() => setEditCurLoan(false)}
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <span className=" text-bold h4 mt-5">
            Edit For LoanType:{" "}
            <span className="text-danger">{ILData.intLoanType}</span>
          </span>
        </div>
      </div>
      <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <label className="label-control">Sanc Principal:</label>
          <input
            type="number"
            className="form-control"
            value={LoanSansPrin}
            onChange={(e) => setLoanSansPrin(e.target.value)}
            // disabled
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <label className="label-control">Sanc Interest:</label>
          <input
            type="number"
            className="form-control"
            value={LoanSancInt}
            onChange={(e) => setLoanSancInt(e.target.value)}
            // disabled
          />
        </div>
      </div>

      <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <label className="label-control">Duration:</label>
          <input
            type="number"
            className="form-control"
            value={LoanDuration}
            onChange={(e) => setLoanDuration(e.target.value)}
          />
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <label className="label-control">Status:</label>
          <Select
            name="LoanStatus"
            options={LoanStatusList}
            value={LoanStatus}
            placeholder="Select Cash or Bank"
            onChange={(e) => setLoanStatus(e)}
            theme={(theme) => ({
              ...theme,
              height: 26,
              minHeight: 26,
              borderRadius: 1,
              colors: {
                ...theme.colors,
                primary: "black",
              },
            })}
          />
        </div>
      </div>

      <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          {/* <input
            // type="submit"
            type="button"
            name="Back"
            className="btn sub_form btn_continue Save float-left"
            value="Back"
            onClick={() => setEditCurLoan(false)}
          /> */}
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <input
            // type="submit"
            type="button"
            name="submit"
            className="btn sub_form btn_continue Save float-right"
            value="Submit"
            onClick={() => updateCurILLoan()}
          />
        </div>
      </div>
    </div>
  );
};

export default EditCurLoan;
