import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllIncharge } from "../../actions/user";
import {
  getAllBatches,
  getAllBatchesDropdown,
  getShgMembers,
  getAllMembers,
  getActiveInstitutions,
  getBatchOfParish,
  addImportBatchtData,
  unsetCheckCode,
  getActiveShgCategory,
  getAllBatchesCount,
} from "../../actions/shg";
import {
  getVarados,
  getStates,
  getDistrict,
  getParish,
  getAllParish,
  getAllBlock,
} from "../../actions/area";
import { Link } from "react-router-dom";
import EditBatchDetails from "./EditBatchDetails";
import Pagination from "../layout/Pagination";
import Spinner from "../layout/Spinner";
import DeactiveBatch from "./DeactiveBatch";
import ChangeBatchCode from "./ChangeBatchCode";
import { CSVLink } from "react-csv";

const AllBatchDetails = ({
  auth: { isAuthenticated, user, users },
  shg: {
    allbatches,
    allbatchesdropdown,
    activeInstitutions,
    activeShgCategories,
    batchMemberCount,
    allDeactiveBatches,
    allActiveBatches,
    allMembers,
  },
  user: { allincharge },
  area: { parishData, allBlock },

  getActiveShgCategory,
  getAllBatches,
  getShgMembers,
  addImportBatchtData,
  getAllBatchesDropdown,
  getActiveInstitutions,
  getVarados,
  getStates,
  getDistrict,
  getAllParish,
  getBatchOfParish,
  unsetCheckCode,
  getAllBlock,
  getAllIncharge,
  getAllBatchesCount,
  getAllMembers,
}) => {
  let selBatchDetails = JSON.parse(localStorage.getItem("selBatchDetails"));
  let selshgBatchDeactiveIdData = JSON.parse(
    localStorage.getItem("selshgBatchDeactiveIdData")
  );
  // let selshgBatchId = JSON.parse(localStorage.getItem("selshgBatchId"));
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );
  useEffect(() => {
    getAllBatches(selBatchDetails);
  }, [getAllBatches]);
  useEffect(() => {
    // const reqData = {
    //   institutionId: loggedUserInstitutionData && loggedUserInstitutionData._id,
    // };
    // getAllBatchesCount(reqData);
    getAllBatchesCount();
  }, [getAllBatchesCount]);
  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);
  useEffect(() => {
    getActiveInstitutions();
  }, [getActiveInstitutions]);
  useEffect(() => {
    getVarados();
  }, [getVarados]);
  useEffect(() => {
    getActiveShgCategory();
  }, [getActiveShgCategory]);

  useEffect(() => {
    getAllBlock({ institutionIdVal: loggedUserInstitutionData._id });
  }, []);

  useEffect(() => {
    getStates();
  }, [getStates]);
  useEffect(() => {
    getAllParish();
  }, [getAllParish]);

  useEffect(() => {
    getAllIncharge();
  }, [getAllIncharge]);
  const [showHide, setShowHide] = useState({
    showBatchSection:
      loggedUserInstitutionData &&
      loggedUserInstitutionData.institutionType === "Zoned"
        ? true
        : false,
  });

  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };
  const allbatchesList = [];

  allbatchesdropdown.map((batchs) =>
    allbatchesList.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );
  const [batchs, getbatchsData] = useState(
    selBatchDetails &&
      selBatchDetails.batchIdVal && {
        batchId: selBatchDetails.batchIdVal,
        label: selBatchDetails.batchName,
        value: selBatchDetails.batchName,
      }
  );
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();

  const [exportModel, setexportModel] = useState(false);

  const onBatchChange = (e) => {
    setCurrentData(1);
    var batchId = "";
    var batchName = "";
    getbatchsData(e);
    batchId = e.batchId;
    batchName = e.value;
    setbatchId(batchId);
    setbatchName(batchName);

    const changeData = {
      institutionIdVal: institutionId,
      parishId: parish && parish.pId,
      parishName: parish && parish.label,
      batchIdVal: e.batchId,
      batchName: e.value,
    };

    getAllBatches(changeData);
    setInchargeData("");
    getShgcategoryData("");
    getbatchscodeData("");
  };
  //bacth code dropdown
  const allbatchescodeList = [];
  allbatchesdropdown.map((batchs) =>
    allbatchescodeList.push({
      batchId: batchs._id,
      label: batchs.batchCode,
      value: batchs.batchCode,
    })
  );

  const [batchscode, getbatchscodeData] = useState(
    selBatchDetails &&
      selBatchDetails.batchIdVal && {
        batchId: selBatchDetails.batchIdVal,
        label: selBatchDetails.batchName,
        value: selBatchDetails.batchName,
      }
  );
  // const [batchId, setbatchId] = useState();
  const [batchCode, setbatchCode] = useState();
  const onBatchCodeChange = (e) => {
    setCurrentData(1);
    var batchId = "";
    var batchCode = "";
    getbatchscodeData(e);
    batchId = e.batchId;
    batchCode = e.value;
    setbatchId(batchId);
    setbatchCode(batchCode);

    const changeData = {
      institutionIdVal: institutionId,
      parishId: parish && parish.pId,
      parishName: parish && parish.label,
      batchIdVal: e.batchId,
      batchCode: e.value,
    };

    getAllBatches(changeData);
    setInchargeData("");
    getShgcategoryData("");
    getbatchsData("");
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (allbatch, idx) => {
    let varadoVal = {
      varadoInfo: allbatch.vId,
    };
    getParish(varadoVal);
    let stateVal = {
      stateInfo: allbatch.stateId,
    };
    getDistrict(stateVal);
    localStorage.setItem("batchesdata", JSON.stringify(allbatch));

    // setShowUpdateModal(true);
    // setUserData(allbatch);
  };

  const [showChangeModal, setShowChangeModal] = useState(false);
  const handleChangeModalClose = () => setShowChangeModal(false);

  const onChangeModalChange = (e) => {
    if (e) {
      handleChangeModalClose();
    }
  };
  const [userData1, setUserData1] = useState(null);
  const onChangeBatchCode = (allbatch, idx) => {
    unsetCheckCode(userData1);
    setShowChangeModal(true);
    setUserData1(allbatch);
  };

  // const [isSubmitted, setSubmitted] = useState(false);
  const handleGoToAllMember = (allbatch) => {
    localStorage.setItem("ShgVillage", JSON.stringify(allbatch));

    const finalData = {
      batchId: allbatch._id,
      batchName: allbatch.batchName,
      parishName: allbatch.parishName,
    };
    getShgMembers(finalData);

    // setSubmitted(true);
  };

  if (JSON.parse(localStorage.getItem("PageIndex")) === null) {
    localStorage.setItem("PageIndex", 1);
  }

  //pagination code
  const [currentData, setCurrentData] = useState(
    JSON.parse(localStorage.getItem("PageIndex"))
  );
  const [dataPerPage] = useState(8);
  //Get Current Data
  const indexOfLastData = currentData * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentDatas =
    allbatches && allbatches.slice(indexOfFirstData, indexOfLastData);
  //change page
  const paginate = (nmbr) => {
    localStorage.setItem("PageIndex", nmbr);
    setCurrentData(nmbr);
  };

  //pagination code ends

  const allinstitution = [];
  activeInstitutions.map((institution) =>
    allinstitution.push({
      institutionId: institution._id,
      label: institution.institutionName,
      value: institution.institutionName,
    })
  );
  const [institution, getinstitutionData] = useState();

  const [institutionId, setinstitutionId] = useState();
  const [institutionName, setinstitutionName] = useState();

  const onInstitutionChange = (e) => {
    var institutionId = "";
    var institutionName = "";
    getinstitutionData(e);

    institutionId = e.institutionId;
    institutionName = e.value;

    setinstitutionId(institutionId);
    setinstitutionName(institutionName);
    const changeData = {
      institutionIdVal: e.institutionId,
    };
    getAllBatches(changeData);
    getAllBatchesDropdown(changeData);
    getAllParish(changeData);
    setInchargeData("");
    getbatchsData("");
    getParishData("");
    getbatchscodeData("");
  };
  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const [userDatas, setUserDatas] = useState(null);
  const onUpdate = (allbatch, idx) => {
    setShowDeactiveModal(true);
    setUserDatas(allbatch);
  };

  ////blockDropDown////

  const [allBlockDetails, setallBlockDetails] = useState([]);
  useEffect(() => {
    setallBlockDetails(
      allBlock.map((ele) => {
        return { label: ele.blockName, value: ele._id };
      })
    );
  }, [allBlock]);

  const [seletedBlock, setSeletedBlock] = useState("");

  const onShgBlockChange = (e) => {
    setSeletedBlock(e);
    const changeData = {
      institutionIdVal: institutionId,
      blockId: e.value,
    };
    getAllBatches(changeData);
  };

  ////////////////

  const allSHGCategory = [];
  activeShgCategories.map((shgcategory) =>
    allSHGCategory.push({
      shgCategoryId: shgcategory._id,
      label: shgcategory.shgCategoryName,
      value: shgcategory.shgCategoryName,
    })
  );
  const [shgcategory, getShgcategoryData] = useState(
    selBatchDetails &&
      selBatchDetails.shgCategoryIdVal && {
        shgCategoryId: selBatchDetails.shgCategoryIdVal,
        label: selBatchDetails.shgCategoryName,
        value: selBatchDetails.shgCategoryName,
      }
  );
  const [shgCategoryId, setShgCategoryID] = useState();
  const [shgCategoryName, setShgCategoryName] = useState();

  const onShgcategoryChange = (e) => {
    getShgcategoryData(e);
    setShgCategoryID(e.shgCategoryId ? e.shgCategoryId : null);
    setShgCategoryName(e.value ? e.value : "");
    const changeData = {
      institutionIdVal: institutionId,
      shgCategoryIdVal: e.shgCategoryId,
      shgCategoryName: e.label,
    };
    getbatchsData("");
    getParishData("");
    setInchargeData("");
    getbatchscodeData("");
    getAllBatches(changeData);
  };
  const [inchargeData, setInchargeData] = useState(
    selBatchDetails &&
      selBatchDetails.shgCategoryIdVal && {
        batchInchargeId: selBatchDetails.batchInchargeId,
        label: selBatchDetails.batchInchargeName,
        value: selBatchDetails.batchInchargeName,
      }
  );
  const [batchInchargeId, setBatchInchargeId] = useState();
  const [batchInchargeName, setBatchInchargeName] = useState();

  const allincharges = [];
  allincharge.map((incharge) =>
    allincharges.push({
      batchInchargeId: incharge._id,
      label: incharge.userFullName,
      value: incharge.userFullName,
    })
  );
  const onInchargeChange = (e) => {
    var batchInchargeId = "";
    var batchInchargeName = "";
    setInchargeData(e);
    batchInchargeId = e.batchInchargeId;
    batchInchargeName = e.value;
    setBatchInchargeId(batchInchargeId);
    setBatchInchargeName(batchInchargeName);
    const changeData = {
      batchInchargeId: e.batchInchargeId,
      batchInchargeName: e.value,
      institutionIdVal: institutionId,
    };
    getbatchsData("");
    getAllBatches(changeData);
    getbatchscodeData("");
  };
  const allparish = [];

  parishData.map((parish) =>
    allparish.push({
      pId: parish._id,
      pCode: parish.parishCode,
      bCounter: parish.batchCounter,
      label: parish.parishName,
      value: parish.parishName,
    })
  );
  const [parish, getParishData] = useState(
    selBatchDetails &&
      selBatchDetails.parishId && {
        pId: selBatchDetails.parishId,
        label: selBatchDetails.parishName,
        value: selBatchDetails.parishName,
      }
  );
  const [pId, setParishID] = useState();
  const [parishName, setParishName] = useState();
  const [parishCode, setParishCode] = useState("");
  const [batchCounter, setBatchCounter] = useState("");

  const onParishChange = (e) => {
    var pId = "";
    var parishName = "";
    var parishCode = "";
    var batchCounter = "";

    getParishData(e);
    pId = e.pId;
    parishName = e.value;
    parishCode = e.pCode;
    batchCounter = e.bCounter;
    setParishID(pId);
    setParishName(parishName);
    setParishCode(parishCode);
    setBatchCounter(batchCounter);

    const changeData = {
      pIdVal: e.pId,
      parishName: e.value,
      parishId: pId,
      institutionIdVal: institutionId,
    };
    getAllBatches(changeData);
    getBatchOfParish(changeData);
    setInchargeData("");
    getbatchsData("");
    getShgcategoryData("");
    getbatchscodeData("");
  };

  const [formData, setFormData] = useState({
    batchCsvPath: "",
    isSubmitted: false,
  });

  const { batchCsvPath } = formData;
  const { showBatchSection } = showHide;

  const handleImg = (e) => {
    setFormData({
      ...formData,
      batchCsvPath: e.target.files[0].name,
    });
  };

  const [showPathSettingModal, setShowPathModal] = useState(false);
  const handleShowPathModalClose = () => setShowPathModal(false);
  const onClickHandler = () => {
    setShowPathModal(true);
  };

  const onFileUpload = (e) => {
    e.preventDefault();
    const finalData = {
      filePathName: batchCsvPath,
    };
    addImportBatchtData(finalData);
    handleShowPathModalClose();
  };
  const onClickReset = () => {
    setCurrentData(1);
    getAllBatches("");
    getinstitutionData("");
    getbatchsData("");
    getShgcategoryData("");
    getParishData("");
    getAllBatchesDropdown();
    setInchargeData("");
    getbatchscodeData("");
    setSeletedBlock("");
  };
  const csvData = [["SHG Name", "Member Count"]];
  batchMemberCount.map((batchMemberCount) => {
    csvData.push([batchMemberCount.batchName, batchMemberCount.count]);
  });
  const CsvShgData = [
    [
      "SHG Name",
      "SHG Code",
      "Incharge Name",
      "Phone",
      "Address",
      "State",
      "District",
    ],
  ];

  allbatches.map((batchdata) =>
    CsvShgData.push([
      batchdata.batchName,
      batchdata.batchCode,
      batchdata.batchInchargeName,
      batchdata.batchPhone,
      batchdata.batchAddressLine1 +
        " " +
        batchdata.batchAddressLine2 +
        " " +
        batchdata.pincode,
      batchdata.stateName,
      batchdata.districtName,
    ])
  );

  //All memebers

  // allMembers;
  const CsvAllMembersData = [
    [
      "Member Name",
      "Member Code",
      "SHG Name",
      "Joining Date",
      "Member Designation",
    ],
  ];
  allMembers &&
    allMembers.map((ele) => {
      CsvAllMembersData.push([
        ele.memberName,
        ele.memberCode,
        ele.batchName,
        ele.memberJoiningDate,
        ele.memberDesg,
      ]);
    });

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color">All SHG Details </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding py-2">
            <div className="row col-lg-9 col-md-10 col-sm-12 col-12 no_padding">
              {user.userGroupName === "Super Admin" && (
                <div className="col-lg-3 col-md-4 col-sm-6 col-11 mt-1">
                  <Select
                    name="institutionName"
                    options={allinstitution}
                    isSearchable={true}
                    value={institution}
                    placeholder="Select Institution"
                    onChange={(e) => onInstitutionChange(e)}
                  />
                </div>
              )}
              <div className="col-lg-3 col-md-4 col-sm-6 col-11 mt-1">
                <Select
                  name="shgcategory"
                  options={allSHGCategory}
                  isSearchable={false}
                  value={shgcategory}
                  placeholder="Select Category"
                  onChange={(e) => onShgcategoryChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
              {allPermission &&
                allPermission.includes("Incharge dropdown") &&
                (user.userGroupName === "Admin" ||
                  user.userGroupName === "Auditor" ||
                  user.userGroupName === "Co-ordinator") && (
                  <div className="col-lg-3 col-md-4 col-sm-4 col-12 py-2">
                    <Select
                      name="userFullName"
                      options={allincharges}
                      isSearchable={true}
                      value={inchargeData}
                      placeholder="Select Incharge"
                      onChange={(e) => onInchargeChange(e)}
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                )}
              {showBatchSection && (
                <div className="col-lg-3 col-md-4 col-sm-6 col-11 mt-1">
                  <Select
                    name="parishName"
                    options={allparish}
                    isSearchable={true}
                    value={parish}
                    placeholder="Select Parish"
                    onChange={(e) => onParishChange(e)}
                  />
                </div>
              )}

              <div className="col-lg-3 col-md-4 col-sm-6 col-11 mt-1">
                <Select
                  name="batchName"
                  options={allbatchesList}
                  isSearchable={true}
                  value={batchs}
                  placeholder="Select SHG"
                  onChange={(e) => onBatchChange(e)}
                />
              </div>
              {allPermission && allPermission.includes("Batch Dropdown") ? (
                <div className="col-lg-3 col-md-4 col-sm-6 col-11 mt-1">
                  <Select
                    name="batchName"
                    options={allbatchescodeList}
                    isSearchable={true}
                    value={batchscode}
                    placeholder="Select Batch Code"
                    onChange={(e) => onBatchCodeChange(e)}
                  />
                </div>
              ) : (
                <></>
              )}

              {allPermission && allPermission.includes("Donor_Report") ? (
                <div className="col-lg-3 col-md-4 col-sm-6 col-11 mt-1">
                  <Select
                    name="blockName"
                    options={allBlockDetails}
                    isSearchable={true}
                    value={seletedBlock}
                    placeholder="Select Block Name"
                    onChange={(e) => onShgBlockChange(e)}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="col-lg-3 col-md-2 col-sm-12 col-12 py-3">
              <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              {user.userGroupName !== "Super Admin" && (
                <Link to="/add-batch-details">
                  <img
                    className="img_icon_size log float-right ml-3"
                    src={require("../../static/images/add-icon.png")}
                    alt="Add SHG"
                    title="Add SHG"
                  />
                </Link>
              )}
              {user.userGroupName === "Super Admin" && (
                <>
                  <CSVLink data={csvData}>
                    <img
                      className="img_icon_size log float-right ml-4"
                      src={require("../../static/images/excel_icon.png")}
                      alt="Members count Excel-Export"
                      title="Members count Excel-Export"
                    />
                  </CSVLink>
                  <img
                    className="img_icon_size log float-right ml-3"
                    onClick={() => onClickHandler()}
                    src={require("../../static/images/import.png")}
                    alt="Add User"
                    title="Add Import Data"
                  />
                </>
              )}

              <img
                className="img_icon_size log  float-right"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
              {allPermission && allPermission.includes("BatchExcel_Export") ? (
                // <CSVLink data={CsvShgData}>
                <img
                  className="img_icon_size log float-right mr-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="SHG  Excel-Export"
                  title="SHG  Excel-Export"
                  onClick={() => {
                    setexportModel(true);
                    getAllMembers({});
                  }}
                />
              ) : (
                // </CSVLink>
                <></>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className="firstrowsticky body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover new1 "
                    id="datatable2"
                  >
                    <thead>
                      <tr style={{ height: "auto" }}>
                        <th
                          className="headcolstatic"
                          style={{ height: "-10px !important", zIndex: "1" }}
                        >
                          SHG Name
                        </th>
                        <th>SHG Code</th>
                        <th>Incharge</th>
                        {/* <th> Phone</th> */}
                        {allPermission &&
                        allPermission.includes(
                          "ExtraField_Phone_Name_AWAKE"
                        ) ? (
                          <>
                            {" "}
                            <th> Phone</th> <th>Phone1</th>
                          </>
                        ) : (
                          <>
                            <th> Phone</th>{" "}
                          </>
                        )}

                        <th> Address</th>
                        {showBatchSection && (
                          <>
                            <th> Varado</th>
                            <th> Parish</th>
                          </>
                        )}
                        <th> State</th>
                        <th> District</th>
                        {user.userGroupName !== "Super Admin" && <th>Op</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {currentDatas &&
                        currentDatas.map((allbatch, idx) => {
                          return (
                            <tr key={idx}>
                              {allbatch.batchStatus &&
                              allbatch.batchStatus === "Deactive" ? (
                                <td
                                  className="headcolstatic secondlinebreak1"
                                  style={{ backgroundColor: "#dda6a6" }}
                                >
                                  {allbatch.batchName}
                                </td>
                              ) : (
                                <td className="headcolstatic secondlinebreak1">
                                  <Link
                                    to="/all-members"
                                    className="btnLink"
                                    onClick={() =>
                                      handleGoToAllMember(allbatch)
                                    }
                                  >
                                    {allbatch.batchName}
                                  </Link>
                                </td>
                              )}
                              <td>{allbatch.batchCode}</td>
                              <td>{allbatch.batchInchargeName}</td>

                              {/* <td>{allbatch.batchPhone}</td> */}
                              {allPermission &&
                              allPermission.includes(
                                "ExtraField_Phone_Name_AWAKE"
                              ) ? (
                                <>
                                  {" "}
                                  <td>{allbatch.batchPhone}</td>{" "}
                                  <td>{allbatch.batchPhone1}</td>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <td>{allbatch.batchPhone}</td>{" "}
                                </>
                              )}

                              <td>
                                {allbatch.batchAddressLine1}&nbsp;
                                {allbatch.batchAddressLine2}&nbsp;
                                {allbatch.pincode}
                              </td>
                              {showBatchSection && (
                                <>
                                  <td>{allbatch.varadoName}</td>
                                  <td>{allbatch.parishName}</td>
                                </>
                              )}
                              <td>{allbatch.stateName}</td>
                              <td>{allbatch.districtName}</td>
                              {user.userGroupName !== "Super Admin" && (
                                <td>
                                  {allbatch.batchStatus &&
                                  allbatch.batchStatus !== "Deactive" ? (
                                    <>
                                      <img
                                        className="img_icon_size log"
                                        onClick={() => onUpdate(allbatch, idx)}
                                        src={require("../../static/images/delete.png")}
                                        alt="Deactivate SHG"
                                        title="Deactivate SHG"
                                      />
                                      &nbsp;
                                      <Link
                                        className="img_icon_size log"
                                        src={require("../../static/images/edit_icon.png")}
                                        alt="Edit SHG"
                                        title="Edit SHG"
                                        to="/edit-batch-details"
                                        onClick={() => onEdit(allbatch, idx)}
                                      >
                                        <img
                                          className="img_icon_size log"
                                          src={require("../../static/images/edit_icon.png")}
                                          alt="Edit SHG"
                                          title="Edit SHG"
                                        />
                                      </Link>
                                      &nbsp;
                                      {showBatchSection ? (
                                        <></>
                                      ) : (
                                        <img
                                          className="img_icon_size log"
                                          onClick={() =>
                                            onChangeBatchCode(allbatch, idx)
                                          }
                                          src={require("../../static/images/changecode.png")}
                                          alt="Change Batch Code"
                                          title="Change Batch Code"
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <Fragment>
                                      Deactivated &nbsp;
                                      <img
                                        className="img_icon_size log "
                                        src={require("../../static/images/info.png")}
                                        alt="Reason"
                                        title={allbatch.batchDeactiveReason}
                                      />
                                    </Fragment>
                                  )}
                                  &nbsp;
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      {/* {allDeactiveBatches &&
                        allDeactiveBatches.map((allbatch, idx) => {
                         
                          return (
                            <tr key={idx}>
                            {allbatch.batchStatus &&
                            allbatch.batchStatus === "Deactive" ? (
                              <td
                                className="headcolstatic secondlinebreak1"
                                style={{ backgroundColor: "#dda6a6" }}
                              >
                                {allbatch.batchName}
                              </td>
                            ) : (
                              <td className="headcolstatic secondlinebreak1">
                                <Link
                                  to="/all-members"
                                  className="btnLink"
                                  onClick={() =>
                                    handleGoToAllMember(allbatch)
                                  }
                                >
                                  {allbatch.batchName}
                                </Link>
                              </td>
                            )}
                            <td>{allbatch.batchCode}</td>
                            <td>{allbatch.batchInchargeName}</td>

                            <td>{allbatch.batchPhone}</td>
                            <td>
                              {allbatch.batchAddressLine1}&nbsp;
                              {allbatch.batchAddressLine2}&nbsp;
                              {allbatch.pincode}
                            </td>
                            {showBatchSection && (
                              <>
                                <td>{allbatch.varadoName}</td>
                                <td>{allbatch.parishName}</td>
                              </>
                            )}
                            <td>{allbatch.stateName}</td>
                            <td>{allbatch.districtName}</td>
                            {user.userGroupName !== "Super Admin" && (
                              <td>
                                {allbatch.batchStatus &&
                                allbatch.batchStatus !== "Deactive" ? (
                                  <>
                                    <img
                                      className="img_icon_size log"
                                      onClick={() => onUpdate(allbatch, idx)}
                                      src={require("../../static/images/delete.png")}
                                      alt="Deactivate SHG"
                                      title="Deactivate SHG"
                                    />
                                    &nbsp;
                                    <Link
                                      className="img_icon_size log"
                                      src={require("../../static/images/edit_icon.png")}
                                      alt="Edit SHG"
                                      title="Edit SHG"
                                      to="/edit-batch-details"
                                      onClick={() => onEdit(allbatch, idx)}
                                    >
                                      <img
                                        className="img_icon_size log"
                                        src={require("../../static/images/edit_icon.png")}
                                        alt="Edit SHG"
                                        title="Edit SHG"
                                      />
                                    </Link>
                                    &nbsp;
                                    {showBatchSection ? (
                                      <></>
                                    ) : (
                                      <img
                                        className="img_icon_size log"
                                        onClick={() =>
                                          onChangeBatchCode(allbatch, idx)
                                        }
                                        src={require("../../static/images/changecode.png")}
                                        alt="Change Batch Code"
                                        title="Change Batch Code"
                                      />
                                    )}
                                  </>
                                ) : (
                                  <Fragment>
                                    Deactivated &nbsp;
                                    <img
                                      className="img_icon_size log "
                                      src={require("../../static/images/info.png")}
                                      alt="Reason"
                                      title={allbatch.batchDeactiveReason}
                                    />
                                  </Fragment>
                                )}
                                &nbsp;
                              </td>
                            )}
                          </tr>
                          );
                        })}       */}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-11 col-11 no_padding">
                    {allbatches && allbatches.length !== 0 ? (
                      <Pagination
                        dataPerPage={dataPerPage}
                        totalData={allbatches.length}
                        paginate={paginate}
                        currentPage={currentData}
                      />
                    ) : (
                      <Fragment />
                    )}
                  </div>

                  <div className="col-lg-6  col-md-12 col-sm-12 col-12 align_right">
                    <label>
                      Active : {allActiveBatches && allActiveBatches.length}
                    </label>{" "}
                    &nbsp;
                    <label>
                      Inactive :{" "}
                      {allDeactiveBatches && allDeactiveBatches.length}
                    </label>
                    &nbsp;
                    <label>Total:{allbatches.length}</label>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

        <Modal
          show={showUpdateModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10 col-md-10 col-sm-10 col-10">
              <h3 className="modal-title text-center">Edit SHG Details </h3>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
              <button onClick={handleUpdateModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditBatchDetails
              batchesdata={userData}
              onUpdateModalChange={onUpdateModalChange}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={showChangeModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10 col-md-10 col-sm-10 col-10">
              <h3 className="modal-title text-center">Change Batch Code </h3>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
              <button onClick={handleChangeModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <ChangeBatchCode
              batchesdata={userData1}
              onChangeModalChange={onChangeModalChange}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showDeactiveModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Deactivate</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleDeactiveModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <DeactiveBatch
              allbatch={userDatas}
              onDeactiveModalChange={onDeactiveModalChange}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showPathSettingModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Import Batch Details</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleShowPathModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <form className="row" onSubmit={(e) => onFileUpload(e)}>
            <Modal.Body>
              {/* <FileBase64
                type="file"
                multiple={false}
                onDone={(e) =>
                  setFormData({
                    ...formData,
                    batchCsvPath: e,
                  })
                }
              /> */}
              <input
                type="file"
                accept=".csv"
                id="photo"
                className="visually-hidden"
                onChange={handleImg}
                required
              />

              <input
                type="submit"
                name="Submit"
                value="Submit"
                className="btn sub_form btn_continue blackbrd Save float-right"
              />
              {/* <AddUser onAddUserModalChange={onAddUserModalChange} /> */}
            </Modal.Body>
          </form>
        </Modal>

        <Modal
          show={exportModel}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Export Model</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={() => setexportModel(false)} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <CSVLink data={CsvShgData}>
                <img
                  className="img_icon_size log float-right mr-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="SHG  Excel-Export"
                  title="SHG  Excel-Export"
                />{" "}
                <h5>Export Batch Details</h5>
              </CSVLink>
            </div>
            <div className="mt-3">
              <CSVLink data={CsvAllMembersData}>
                <img
                  className="img_icon_size log float-right mr-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="SHG  Excel-Export"
                  title="All SHG Excel-Export"
                />{" "}
                <h5>Export All Batch Member Details </h5>
              </CSVLink>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

AllBatchDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getAllBatches: PropTypes.func.isRequired,
  getAllBatchesDropdown: PropTypes.func.isRequired,
  getShgMembers: PropTypes.func.isRequired,
  getParish: PropTypes.func.isRequired,
  getAllParish: PropTypes.func.isRequired,
  addImportBatchtData: PropTypes.func.isRequired,
  getActiveShgCategory: PropTypes.func.isRequired,
  getAllBlock: PropTypes.func.isRequired,

  unsetCheckCode: PropTypes.func.isRequired,
  getAllIncharge: PropTypes.func.isRequired,
  getAllBatchesCount: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  shg: state.shg,
  user: state.user,
});

export default connect(mapStateToProps, {
  getAllParish,
  getAllBatches,
  getAllBatchesDropdown,
  getShgMembers,
  getActiveInstitutions,
  getVarados,
  getStates,
  getParish,
  getDistrict,
  getBatchOfParish,
  addImportBatchtData,
  getActiveShgCategory,
  getAllBlock,
  getAllMembers,
  unsetCheckCode,
  getAllIncharge,
  getAllBatchesCount,
})(AllBatchDetails);
