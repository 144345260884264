import React, { useState } from "react";
import Select from "react-select";
const ExpenseTab = ({
  allPermission,
  formData,
  setFormData,
  activeBank,
  membersMeetingData,
  NextBackBtn,
}) => {
  let dataOfExpenseTab = {
    batchSavingWithdrawalsCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.savingWithdrawals.cash)),
      0
    ),
    batchSavingWithdrawalsBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.savingWithdrawals.bankAmt)),
      0
    ),

    batchSecurityDepositCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.memSecurityDepositToShg.cash)),
      0
    ),

    batchSecurityDepositBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.memSecurityDepositToShg.bankAmt)),
      0
    ),

    batchDividendDistributedCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.dividendDistributed.cash)),
      0
    ),
    batchDividendDistributedBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.dividendDistributed.bankAmt)),
      0
    ),
  };

  const [expenseFormData, setExpenseFormData] = useState({
    travelExpensesBankName:
      formData.travellingExpenses && formData.travellingExpenses.bankName,
    travelExpensesBank:
      formData.travellingExpenses && formData.travellingExpenses.bankAmt,
    travelExpensesCash:
      formData.travellingExpenses && formData.travellingExpenses.cash,

    StationaryBankName:
      formData.stationaryExpenses && formData.stationaryExpenses.bankName,
    StationaryBank:
      formData.stationaryExpenses && formData.stationaryExpenses.bankAmt,
    StationaryCash:
      formData.stationaryExpenses && formData.stationaryExpenses.cash,

    paidToMahasanghaBankName:
      formData.paidToMahasangha && formData.paidToMahasangha.bankName,
    paidToMahasanghaBank:
      formData.paidToMahasangha && formData.paidToMahasangha.bankAmt,
    paidToMahasanghaCash:
      formData.paidToMahasangha && formData.paidToMahasangha.cash,

    serviceChargesBankName:
      formData.serviceCharges && formData.serviceCharges.bankName,
    serviceChargesBank:
      formData.serviceCharges && formData.serviceCharges.bankAmt,
    serviceChargesCash: formData.serviceCharges && formData.serviceCharges.cash,

    otherExpensesBankName:
      formData.otherExpenses && formData.otherExpenses.bankName,
    otherExpensesBank: formData.otherExpenses && formData.otherExpenses.bankAmt,
    otherExpensesCash: formData.otherExpenses && formData.otherExpenses.cash,

    donationToOutsiderBankName:
      formData.donationToOutsider && formData.donationToOutsider.bankName,
    donationToOutsiderBank:
      formData.donationToOutsider && formData.donationToOutsider.bankAmt,
    donationToOutsiderCash:
      formData.donationToOutsider && formData.donationToOutsider.cash,

    sdSHGToUnionBankName:
      formData.sdSHGToUnion && formData.sdSHGToUnion.bankName,
    sdSHGToUnionBank: formData.sdSHGToUnion && formData.sdSHGToUnion.bankAmt,
    sdSHGToUnionCash: formData.sdSHGToUnion && formData.sdSHGToUnion.cash,

    loanGivenToOtherSHGBankName:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.bankName,
    loanGivenToOtherSHGBank:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.bankAmt,
    loanGivenToOtherSHGCash:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.cash,
  });

  // membersMeetingData

  const {
    travelExpensesBankName,
    travelExpensesBank,
    travelExpensesCash,

    StationaryBankName,
    StationaryBank,
    StationaryCash,

    paidToMahasanghaBankName,
    paidToMahasanghaBank,
    paidToMahasanghaCash,

    serviceChargesBankName,
    serviceChargesBank,
    serviceChargesCash,

    otherExpensesBankName,
    otherExpensesBank,
    otherExpensesCash,

    donationToOutsiderBankName,
    donationToOutsiderBank,
    donationToOutsiderCash,

    sdSHGToUnionBankName,
    sdSHGToUnionBank,
    sdSHGToUnionCash,

    loanGivenToOtherSHGBankName,
    loanGivenToOtherSHGBank,
    loanGivenToOtherSHGCash,
  } = expenseFormData;

  const onInputChange = (e, labelName = "") => {
    if (labelName == "") {
      setExpenseFormData({
        ...expenseFormData,
        [e.target.name]: e.target.value,
      });
    } else {
      setExpenseFormData({ ...expenseFormData, [labelName]: e });
    }
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const expenseNextTab = () => {
    const finalExpenseData = {
      travellingExpenses: {
        cash: Number(travelExpensesCash),
        bankAmt: Number(travelExpensesBank),
        bankName: travelExpensesBankName,
      },
      stationaryExpenses: {
        cash: Number(StationaryCash),
        bankAmt: Number(StationaryBank),
        bankName: StationaryBankName,
      },
      paidToMahasangha: {
        cash: Number(paidToMahasanghaCash),
        bankAmt: Number(paidToMahasanghaBank),
        bankName: paidToMahasanghaBankName,
      },
      serviceCharges: {
        cash: Number(serviceChargesCash),
        bankAmt: Number(serviceChargesBank),
        bankName: serviceChargesBankName,
      },
      batchDividendDistributed: {
        cash: dataOfExpenseTab.batchDividendDistributedCash,
        bankAmt: dataOfExpenseTab.batchDividendDistributedBank,
        bankName: "",
      },
      otherExpenses: {
        cash: Number(otherExpensesCash),
        bankAmt: Number(otherExpensesBank),
        bankName: otherExpensesBankName,
      },
      batchSavingWithdrawals: {
        cash: dataOfExpenseTab.batchSavingWithdrawalsCash,
        bankAmt: dataOfExpenseTab.batchSavingWithdrawalsBank,
        bankName: "",
      },
      batchSecurityDeposit: {
        cash: dataOfExpenseTab.batchSecurityDepositCash,
        bankAmt: dataOfExpenseTab.batchSecurityDepositBank,
        bankName: "",
      },

      // req flag
      donationToOutsider: {
        cash: Number(donationToOutsiderCash),
        bankAmt: Number(donationToOutsiderBank),
        bankName: donationToOutsiderBankName,
      },
      sdSHGToUnion: {
        cash: Number(sdSHGToUnionCash),
        bankAmt: Number(sdSHGToUnionBank),
        bankName: sdSHGToUnionBankName,
      },
    };

    setFormData({ ...formData, ...finalExpenseData });

    NextBackBtn(5);
  };

  return (
    <div className="row card-new py-3 col-12">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <h5>Expense</h5>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
        <label className="label-control">Travelling Expense :</label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="travelExpensesBankName"
              options={activeBank}
              isSearchable={true}
              value={travelExpensesBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "travelExpensesBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount
            <input
              type="number"
              name="travelExpensesBank"
              value={travelExpensesBank}
              disabled={travelExpensesBankName ? false : true}
              className="form-control"
              onChange={(e) => onInputChange(e)}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="travelExpensesCash"
              value={travelExpensesCash}
              onChange={(e) => onInputChange(e)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">Stationary Expense :</label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="StationaryBankName"
              options={activeBank}
              isSearchable={true}
              value={StationaryBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "StationaryBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>{" "}
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="StationaryBank"
              value={StationaryBank}
              disabled={StationaryBankName ? false : true}
              onChange={(e) => onInputChange(e)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="StationaryCash"
              value={StationaryCash}
              onChange={(e) => onInputChange(e)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">
          Paid to Union (Mahasangha)
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Amount Paid /Given to the Union/Mahasanga/Federation/General HUB"
          />{" "}
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="paidToMahasanghaBankName"
              options={activeBank}
              isSearchable={true}
              value={paidToMahasanghaBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "paidToMahasanghaBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="paidToMahasanghaBank"
              value={paidToMahasanghaBank}
              disabled={paidToMahasanghaBankName ? false : true}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="paidToMahasanghaCash"
              value={paidToMahasanghaCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">Service Charge : </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="serviceChargesBankName"
              options={activeBank}
              isSearchable={true}
              value={serviceChargesBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "serviceChargesBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="serviceChargesBank"
              value={serviceChargesBank}
              disabled={serviceChargesBankName ? false : true}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="serviceChargesCash"
              value={serviceChargesCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">
          Dividend Distributed{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Profit Distribution to Members"
          />{" "}
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="batchDividendDistributedCash"
              value={Number(dataOfExpenseTab.batchDividendDistributedCash)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
          <div className="col-lg-6">
            Bank
            <input
              type="number"
              name="batchDividendDistributedBank"
              value={Number(dataOfExpenseTab.batchDividendDistributedBank)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              // onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
        <label className="label-control">Other Expense :</label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="otherExpensesBankName"
              options={activeBank}
              isSearchable={true}
              value={otherExpensesBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "otherExpensesBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount
            <input
              type="number"
              name="otherExpensesBank"
              value={otherExpensesBank}
              disabled={otherExpensesBankName ? false : true}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="otherExpensesCash"
              value={otherExpensesCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <label className="label-control">
          Savings Withdrawal{" "}
          <img
            className="img_icon_size log"
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title=" Withdrawal of Saving Amount"
          />{" "}
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="batchSavingWithdrawalsCash"
              value={Number(dataOfExpenseTab.batchSavingWithdrawalsCash)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchSavingWithdrawalsBank"
              value={Number(dataOfExpenseTab.batchSavingWithdrawalsBank)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              // onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">
          SD SHG To Member{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Security Deposit Amount Received from SHG to Members"
          />{" "}
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="batchSecurityDepositCash"
              value={Number(dataOfExpenseTab.batchSecurityDepositCash)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              // onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchSecurityDepositBank"
              value={Number(dataOfExpenseTab.batchSecurityDepositBank)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              // onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>

        <label className="label-control">
          Donation To Outsider{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title=" Donation/Contribution to the Outside from the SHG"
          />
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="donationToOutsiderBankName"
              options={activeBank}
              isSearchable={true}
              value={donationToOutsiderBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "donationToOutsiderBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="donationToOutsiderBank"
              value={donationToOutsiderBank}
              disabled={donationToOutsiderBankName ? false : true}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) =>
                (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
              }
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="donationToOutsiderCash"
              value={donationToOutsiderCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) =>
                (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
              }
            />
          </div>
        </div>

        <label className="label-control">
          SD SHG To Union{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title=" Security Deposit Amount from SHG to Union"
          />
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="sdSHGToUnionBankName"
              options={activeBank}
              isSearchable={true}
              value={sdSHGToUnionBankName}
              placeholder="Select Bank"
              onChange={(e) => onInputChange(e, "sdSHGToUnionBankName")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>{" "}
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="sdSHGToUnionBank"
              value={sdSHGToUnionBank}
              disabled={sdSHGToUnionBankName ? false : true}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              // onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="sdSHGToUnionCash"
              onChange={(e) => onInputChange(e)}
              value={sdSHGToUnionCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              // onChange={(e) => onInputChange(e)}
              // onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>

        {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
          <>
            <label className="label-control">Loan Given To Other SHG :</label>
            <div className="row col-12">
              <div className="col-lg-6">
                Bank List
                <Select
                  name="loanGivenToOtherSHGBankName"
                  options={activeBank}
                  isSearchable={true}
                  value={loanGivenToOtherSHGBankName}
                  placeholder="Select Bank"
                  onChange={(e) => onInputChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
              <div className="col-lg-6">
                Bank Amount{" "}
                <input
                  type="number"
                  name="loanGivenToOtherSHGBank"
                  value={loanGivenToOtherSHGBank}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  // onKeyDown={(e) => funcKeyDown(e)}
                  required
                />
              </div>
            </div>
            <div className="row col-12">
              <div className="col-lg-6">
                Cash
                <input
                  type="number"
                  name="loanGivenToOtherSHGCash"
                  value={loanGivenToOtherSHGCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  // onKeyDown={(e) => funcKeyDown(e)}
                  required
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => expenseNextTab()}
          // onClick={() => NextBackBtn(5)}
        >
          Next
        </button>
        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => NextBackBtn(3)}
        >
          Previous
        </button>
      </div>
    </div>
  );
};

export default ExpenseTab;
