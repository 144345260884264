import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AddPermissionDetails } from "../../actions/setting";
import Spinner from "../layout/Spinner";

const AddPermission = ({
  onAddModalChange,
  auth: { isAuthenticated, user, users, loading },
  AddPermissionDetails,
}) => {
  //formData
  const [formData, setFormData] = useState({
    permissionName: "",
    permissionDescription: "",
    isSubmitted: false,
  });
  const { permissionName, permissionDescription } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      permissionName: permissionName,
      permissionDescription: permissionDescription,
      permissionEnteredById: user._id,
      permissionEnteredDateTime: new Date().toLocaleString("en-GB"),
    };
    AddPermissionDetails(finalData);
    onAddModalChange(true);
    setFormData({
      ...formData,
      permissionName: "",
      permissionDescription: "",
      isSubmitted: true,
    });
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <label className="label-control"> Permission Name* :</label>
              <input
                type="text"
                name="permissionName"
                value={permissionName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <label className="label-control">Description* :</label>
              <textarea
                name="permissionDescription"
                value={permissionDescription}
                id="permissionDescription"
                className="textarea form-control"
                rows="4"
                placeholder="Description"
                onChange={(e) => onInputChange(e)}
                style={{ width: "100%" }}
                required
              ></textarea>
            </div>
          </div>

          <div className="col-md-10 col-lg-10 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Save"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

AddPermission.propTypes = {
  auth: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  AddPermissionDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  setting: state.setting,
});

export default connect(mapStateToProps, {
  AddPermissionDetails,
})(AddPermission);
