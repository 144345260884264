import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
const DeactiveVarado = ({
  auth: { isAuthenticated, user, users },
  varadoData,
}) => {
  const [formData, setFormData] = useState({
    tenantdeactivereason: "",
    isSubmitted: false,
  });

  const { tenantdeactivereason } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (varadoData, idx) => {
    // const finalData = {
    //   recordId: varadoData ? varadoData._id : "",
    //   loanReasonStatus: "Deactive",
    //   loanDeactiveReason: tenantdeactivereason,
    //   loanDeactiveById: user && user._id,
    //   loanDeactiveByName: user && user.userName,
    // };
  };

  return !isAuthenticated || !user || !users ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div className="col-lg-12 col-md-9 col-sm-9 col-12 ">
        <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
          <div className="col-lg-12 col-md-4 col-sm-4 col-12">
            <label>Reason For Deactivation* :</label>
          </div>
          <div className="col-lg-12  col-md-4 col-sm-4 col-12">
            <textarea
              name="tenantdeactivereason"
              id="tenantdeactivereason"
              className="textarea form-control"
              rows="3"
              placeholder="Deactive Reason"
              onChange={(e) => onInputChange(e)}
              style={{ width: "100%" }}
              required
            ></textarea>
          </div>
          <div className="col-lg-12 col-md-4 col-sm-4 col-12 py-2">
            <label>Are You Sure You Want to Deactivate ??</label>
          </div>
          <div
            className="col-lg-12 col-md-9 col-sm-9 col-12 Savebutton"
            size="lg"
          >
            <button
              variant="success"
              className="btn sub_form btn_continue Save float-right "
              onClick={() => onSubmit(varadoData)}
              style={
                tenantdeactivereason !== ""
                  ? { opacity: "1" }
                  : { opacity: "1", pointerEvents: "none" }
              }
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

DeactiveVarado.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(DeactiveVarado);
