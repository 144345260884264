import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  getParticularLoanReason,
  AddMemberTransaction,
  AddLoanSanctionedDetails,
  getMemberPreveiousMeetingData,
  getActiveOtherLoanType,
  getActiveBankData,
  getMembersMeetingData,
  getSanctionedData,
  getMemberPrevMeetingData,
  deleteBankLoanDetail,
} from "../../actions/shg";
import Spinner from "../layout/Spinner";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LoanPayment from "../layout/LoanPayment";
import ShgInternalLoan from "../layout/ShgInternalLoan";
import LoanRepaymentDetails from "../layout/LoanRepaymentDetails";
const AddMembermeetingDetailModal = ({
  auth: { isAuthenticated, user, users, loading },
  shg: {
    loanreason,
    prevData,
    activeOtherLoanTypes,
    activeBankData,
    membersMeetingData,
    membersPreviousMeeting,
    isloansanctioned,
    remaingLoanData,
  },
  membersBatch,
  selectedDate,
  onMemberMeetingModalChange,
  getSanctionedData,
  getParticularLoanReason,
  getMemberPreveiousMeetingData,
  AddMemberTransaction,
  AddLoanSanctionedDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  getMembersMeetingData,
  getMemberPrevMeetingData,
  deleteBankLoanDetail,
}) => {
  let selectedBatchData = JSON.parse(localStorage.getItem("selBatchData"));
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));

  // let finalArray=membersPreviousMeeting.map((ele)=>ele.otherLoan).flat();

  useEffect(() => {
    const reqData = {
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: membersBatch && membersBatch._id,
    };
    getMemberPreveiousMeetingData(reqData);
  }, [getMemberPreveiousMeetingData]);

  useEffect(() => {
    getParticularLoanReason();
  }, [getParticularLoanReason]);
  useEffect(() => {
    const reqData = {
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: membersBatch && membersBatch._id,
    };
    getMemberPrevMeetingData(reqData);
  }, [getMemberPrevMeetingData]);

  useEffect(() => {
    const reqData = {
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: membersBatch && membersBatch._id,
    };

    getMemberPreveiousMeetingData(reqData);
  }, [getMemberPreveiousMeetingData]);
  useEffect(() => {
    const reqData = {
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: membersBatch && membersBatch._id,
    };
    getMembersMeetingData(reqData);
  }, []);
  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);

  useEffect(() => {
    getActiveBankData(batchIdValue);
  }, [getActiveBankData]);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  useEffect(() => {
    getSanctionedData({
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: membersBatch && membersBatch._id,
      institutionId: user && user.institutionId,
    });
  }, []);
  let OtherLoanTypeDropDown = [];
  isloansanctioned &&
    isloansanctioned.map((ele) => {
      OtherLoanTypeDropDown.push({
        label: ele.otherLoanType,
        value: ele.otherLoanTypeId,
      });
    });

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  //attendence
  // const [isPresent, setisPresent] = useState(false);
  // const handlePresent = () => {
  //   setisPresent(!isPresent);
  // };

  ///other loan hide fields for already taken loan members
  let onlyID =
    isloansanctioned && isloansanctioned.map((ele) => ele.otherLoanTypeId);

  let alreadyLoanAvaiable =
    (activeOtherLoanTypes &&
      activeOtherLoanTypes
        .map((ele) => onlyID.includes(ele._id))
        .some((ele) => ele == true)) ||
    false;

  useEffect(() => {}, [alreadyLoanAvaiable]);

  ///internal loan & other loan fields for already taken loan members and display data
  let Internalonly = [];
  let Otheronly = [];

  isloansanctioned &&
    isloansanctioned.map((ele) => {
      if (ele.isLoanType === "Internal") {
        Internalonly.push(ele);
      } else {
        Otheronly.push(ele);
      }
    });

  //remaining loan data start
  let finalArray = membersPreviousMeeting.map((ele) => ele.otherLoan).flat();
  const [othRemaingLoan, setOthRemaingLoan] = useState(0); //othRemaingLoan(value in remaing laon)
  let reLoan = 0;

  //attendance
  // const handlePresent = () => {
  //   setFormData({
  //     ...formData,
  //     attendance: !attendance,
  //   });
  // };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);
  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  // code for next previous tabing ends
  const [formData, setFormData] = useState({
    savingAmt: "",
    loanPaid: "",
    memberName: membersBatch.memberName,
    meetingHeldOnDate: selectedDate,
    batchSavingAmt: selectedBatchData[0] && selectedBatchData[0].batchSavingAmt,
    batchSubscriptionAmt:
      selectedBatchData[0] && selectedBatchData[0].batchSubscriptionAmt,
    interestPaid: "",
    batchId: selectedBatchData[0] && selectedBatchData.batchId,
    institutionId: membersBatch[0] && membersBatch[0].institutionId,
    otherContribution: "",
    loanTakenAmt: "",
    internalNominee: "",
    otherPurpose: "",
    savingWithdrawals: 0,
    dividendDistributed: 0,
    securityDeposit: 0,
    loanPayablePrincipal: "",
    loanPayableInterest: "",
    internalDuration: "",
    PrincipalPaid: "",
    attendance: "",
    InterestPaid: "",
    loanChecker: true,
    internalPenalty: "",
    //other loan
    otherloanPayablePrincipalAmt: "",
    otherloanPayableInterstAmt: "",
    otherLoanDuration: "",
    otherLoanPrincipalPaid: "",
    otherInterestPaid: "",
    OTHloanChecker: false,
    otherPenalty: "",
    isSubmitted: false,
    attendance: "",
  });

  const {
    otherLoanReasons,
    otherLoanPaid,
    meetingHeldOnDate,
    memberName,
    batchSavingAmt,
    batchSubscriptionAmt,
    otherContribution,
    internalNominee,
    otherPurpose,
    indSubsidyDistributed,
    externalNominee,
    membershipFees,
    otherIncome,
    memSeedMoney,
    memSecurityDepositToShg,
    savingWithdrawals,
    dividendDistributed,
    securityDeposit,
    loanPayablePrincipal,
    loanPayableInterest,
    internalDuration,
    PrincipalPaid,
    InterestPaid,
    attendance,
    //other loan
    otherloanPayablePrincipalAmt,
    otherloanPayableInterstAmt,
    otherLoanDuration,
    otherInterestPaid,
    loanChecker,
    OTHloanChecker,
    internalPenalty,
    otherPenalty,
    batchId,
    memberId,
    institutionId,
    otherloanpurpose,
    otherLoanReason,
    otherloanSanctionedAmt,
    otherLoanInterest,
    loanPaid,
    interestPaid,
    subscriptionAmt,
    loanTakenAmt,
    savingAmt,
  } = formData;

  var TotalPayableAmt =
    Number(loanPayableInterest) + Number(loanPayablePrincipal);
  var totalLoanPaid = Number(PrincipalPaid) + Number(InterestPaid);

  //other loan
  var otherloanSanctionedAmtt =
    Number(otherloanPayablePrincipalAmt) + Number(otherloanPayableInterstAmt);

  var TotalOtherloanPaid = Number(otherLoanPaid) + Number(otherInterestPaid);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePresent = () => {
    setFormData({
      ...formData,
      attendance: !attendance,
    });
  };

  // const [loanChecker, setLoanChecker] = useState(false);

  const [otherLoanChecker2, setOtherLoanChecker2] = useState(false);
  const handleOnChange = () => {
    // setLoanChecker(!loanChecker);
    setFormData({
      ...formData,
      PrincipalPaid: "",
      InterestPaid: "",
      totalLoanPaid: "",
      internalPenalty: "",

      loanChecker: !loanChecker,
    });
  };

  let OtherLoanNames = [];
  isloansanctioned &&
    isloansanctioned.map((ele) => {
      OtherLoanNames.push({
        label: ele.otherLoanType,
        value: ele.otherLoanType,
      });
    });

  const handleOnOtherChange2 = () => {
    setFormData({
      ...formData,
      otherLoanPaid: "",
      otherInterestPaid: "",
      TotalOtherloanPaid: "",
      otherPenalty: "",
      OTHloanChecker: !OTHloanChecker,
    });
    setOtherLoanChecker2(!otherLoanChecker2);
  };

  var ED = selectedDate.split(/\D/g);
  var mdDate = [ED[2], ED[1], ED[0]].join("-");

  const [showHide, setShowHide] = useState({
    showotherPurposeSection: false,
  });
  const { showotherPurposeSection } = showHide;

  const onLoanPurposeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        loanPurpose: e,
      });
    }
    if (e.value === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
    }
  };

  const allpurpose = [];

  loanreason.map((purpose) =>
    allpurpose.push({
      loanReasonId: purpose._id,
      label: purpose.loanPurpose,
      value: purpose.loanPurpose,
    })
  );

  const [internalLoanType, setInternalLoanType] = useState([]);
  const [purpose, getloanPurposeData] = useState();
  const [loanReasonId, setloanPurposeID] = useState();
  const [loanPurpose, setloanPurposeName] = useState();

  const onPurposeChange = (e) => {
    getloanPurposeData(e);
    setloanPurposeID(e.loanReasonId ? e.loanReasonId : "");
    setloanPurposeName(e.value ? e.value : "");

    if (e) {
      setFormData({
        ...formData,
        loanPurpose: e,
      });
    }

    if (e.value === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
    }
  };

  const [showHideBank, setbankShowHide] = useState({
    showbankotherPurposeSection: false,
  });
  const { showbankotherPurposeSection } = showHideBank;

  const allbankpurpose = [];

  loanreason.map((otherloanpurposes) =>
    allbankpurpose.push({
      bankloanpuroseId: otherloanpurposes._id,
      label: otherloanpurposes.loanPurpose,
      value: otherloanpurposes.loanPurpose,
    })
  );

  const [otherloanpurposes, getbankpurposeData] = useState();
  const [bankloanpuroseId, setbankloanpuroseId] = useState();
  const [bankloanPurpose, setbankloanPurpose] = useState();

  const onBankLoanPurposeChange = (e) => {
    getbankpurposeData(e);
    setbankloanpuroseId(e.bankloanpuroseId ? e.bankloanpuroseId : "");
    setbankloanPurpose(e.value ? e.value : "");

    if (e) {
      setFormData({
        ...formData,
        bankloanPurpose: e,
      });
    }

    if (e.value === "Other") {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: true,
      });
    } else {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: false,
      });
    }
  };

  //validation for select otherLoanType
  const [errors, setErrors] = useState({
    otherLoanChecker: false,
    otherLoanCheckError: false,
    otherLoanErrorStyle: {},
  });
  const { otherLoanChecker, otherLoanErrorStyle } = errors;
  const checkError = () => {
    if (!otherLoanChecker) {
      setErrors({
        ...errors,
        otherLoanErrorStyle: { color: "#F00" },
      });
      return false;
    }
    return true;
  };

  //to hide has loan in internal Loan and open only if loanSancationedamt>0
  const [hideFieldInternal, sethideFieldInternal] = useState("");
  useEffect(() => {
    if (TotalPayableAmt > 0) {
      sethideFieldInternal("true");
      setFormData({
        ...formData,
        loanChecker: false,
      });
    } else {
      sethideFieldInternal("false");
      setFormData({
        ...formData,
        PrincipalPaid: "",
        InterestPaid: "",
        totalLoanPaid: "",
        internalPenalty: "",
        loanChecker: false,
      });
    }
  }, [TotalPayableAmt]);

  /////////////////////////////////////other loan for 1
  //to hide has loan in 1 other Loan and open only if loanSancationedamt>0
  const [hideFieldOther, sethideFieldOther] = useState("");
  useEffect(() => {
    if (otherloanSanctionedAmtt > 0) {
      sethideFieldOther("true");
      setFormData({
        ...formData,
        OTHloanChecker: false,
      });
    } else {
      sethideFieldOther("false");
      setFormData({
        ...formData,
        otherLoanPaid: "",
        otherInterestPaid: "",
        TotalOtherloanPaid: "",
        otherPenalty: "",
        OTHloanChecker: false,
      });
    }
  }, [otherloanSanctionedAmtt]);

  //////////////////////////////////////////////////////////////
  const [error, setError] = useState({
    otherLoanTypeIdChecker: false,
    otherLoanTypeIdErrorStyle: {},
    bankIdChecker: false,
    bankIdErrorStyle: {},
  });
  const {
    otherLoanTypeIdChecker,
    otherLoanTypeIdErrorStyle,
    bankIdChecker,
    bankIdErrorStyle,
  } = error;

  const [otherLoanType, getOtherLoanTypeData] = useState("");
  const [otherLoanTypeId, setOtherLoanTypeId] = useState("");
  const [otherLoanTypeName, setOtherLoanTypeName] = useState("");

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  //single other loan
  //Other Remaining loan value

  let isloansanctionedOth = isloansanctioned.filter(
    (ele) => ele.isLoanType === "otherLoan"
  );
  let loansanc =
    isloansanctionedOth[0] && isloansanctionedOth[0].loanSanctionedAmt;
  let loantot = isloansanctionedOth[0] && isloansanctionedOth[0].loanCurTot;
  let othsingle = loansanc - loantot || 0;

  // let othsingleremainloan =
  //   (isloansanctioned[0] &&
  //     isloansanctioned[0].loanSanctionedAmt - isloansanctioned[0] &&
  //     isloansanctioned[0].loanCurTot) ||
  //   0;
  //this is for dropdown of otherloan type
  const [dataAvaiable, setdataAvaiable] = useState("");

  const onotherLoanTypeChange = (e) => {
    const isalreadyAvaiable =
      isloansanctioned &&
      isloansanctioned.filter(
        (ele) => ele.otherLoanTypeId === e.otherLoanTypeId
      );
    let othRemLoan = 0;

    isloansanctioned &&
      isloansanctioned.map((other) => {
        if (other.otherLoanTypeId === e.otherLoanTypeId) {
          othRemLoan =
            Number(other.loanPayablePrincipal) - Number(other.loanCurPrincipal);
          //Number(other.loanSanctionedAmt) - Number(other.loanCurTot);
        }
      });
    setOthRemaingLoan(othRemLoan);

    //might be a problrm
    if (isalreadyAvaiable.length > 0) {
      setdataAvaiable("true");
    } else {
      setdataAvaiable("false");
    }
    setErrors({
      ...errors,
      otherLoanChecker: true,
      otherLoanErrorStyle: { color: "#000" },
    });
    setError({
      ...error,
      otherLoanTypeIdChecker: true,
      otherLoanTypeIdErrorStyle: { color: "#000" },
    });
    getOtherLoanTypeData(e);
    setOtherLoanTypeId(e.otherLoanTypeId ? e.otherLoanTypeId : "");
    setOtherLoanTypeName(e.value ? e.value : "");
  };
  //For setting mindate as todays date

  //OLR GRID START==============================================================================
  const [DurationError, setDurationError] = useState({
    color: "black",
  });
  const [AddedDetailsOLR, AddDetailsOLR] = useState([]);
  const onAddOLR = (e) => {
    e.preventDefault();
    if (otherloanSanctionedAmtt > 0 && otherLoanDuration <= 0) {
      setDurationError({ color: "red" });
    } else {
      setDurationError({ color: "black" });
      if (checkError()) {
        if (otherLoanType) {
          const listOLR = AddedDetailsOLR.filter(
            (AddDetails) =>
              AddDetails.otherLoanTypeId === otherLoanType.otherLoanTypeId
          );
          if (listOLR.length === 0) {
            const addData = {
              otherLoanType: otherLoanType.value,
              otherLoanTypeId: otherLoanType.otherLoanTypeId,
              otherLoanReasonId: otherloanpurposes
                ? otherloanpurposes.bankloanpuroseId
                : null,
              otherloanpurpose: otherloanpurposes
                ? otherloanpurposes.value
                : "",
              otherLoanOtherPurpose: otherLoanReasons,
              indSubsidyDistributed: indSubsidyDistributed
                ? parseFloat(Number(indSubsidyDistributed).toFixed(2))
                : 0,
              externalNominee: externalNominee,
              // other loan
              otherloanPayablePrincipal: otherloanPayablePrincipalAmt
                ? parseFloat(Number(otherloanPayablePrincipalAmt).toFixed(2))
                : 0,
              otherloanPayableInterst: otherloanPayableInterstAmt
                ? parseFloat(Number(otherloanPayableInterstAmt).toFixed(2))
                : 0,
              otherLoanDuration: otherLoanDuration
                ? Number(otherLoanDuration)
                : "",
              otherLoanTakenAmt: otherloanSanctionedAmtt
                ? parseFloat(Number(otherloanSanctionedAmtt).toFixed(2))
                : 0,
              otherLoanChecker: OTHloanChecker,
              otherLoanPaid: Number(otherLoanPaid)
                ? parseFloat(Number(otherLoanPaid).toFixed(2))
                : 0,
              otherInterestPaid: Number(otherInterestPaid)
                ? parseFloat(Number(otherInterestPaid).toFixed(2))
                : 0,
              otherTotalPaid: Number(TotalOtherloanPaid)
                ? parseFloat(Number(TotalOtherloanPaid).toFixed(2))
                : 0,
              paidDate: meetingHeldOnDate ? meetingHeldOnDate : "",
              paidMonth: meetingHeldOnDate && meetingHeldOnDate.slice(5, 7),
              paidYear: meetingHeldOnDate && meetingHeldOnDate.slice(0, 4),
              otherPenalty: otherPenalty ? Number(otherPenalty) : 0,

              //////////////////Loan Repayment for other
            };
            setFormData({
              ...formData,
              otherLoanType: "",
              otherLoanTypeId: "",
              otherloanSanctionedAmt: "",
              allbankpurpose: "",
              otherLoanReasons: "",
              indSubsidyDistributed: "",
              externalNominee: "",
              otherloanPayablePrincipalAmt: "",
              otherloanPayableInterstAmt: "",
              otherLoanDuration: "",
              otherLoanPaid: "",
              otherInterestPaid: "",
              otherLoanChecker: "",
              OTHloanStatus: "",
              otherPenalty: "",
              OTHloanChecker: false,
            });
            let temp = [];
            temp.push(...AddedDetailsOLR, addData);
            AddDetailsOLR(temp);
            setOtherLoanChecker2(!otherLoanChecker2);
            getOtherLoanTypeData("");
            getbankpurposeData("");
          }
        }
        return true;
      }
    }
  };

  const onAddOLRSingle = (e) => {
    e.preventDefault();
    if (otherloanSanctionedAmtt > 0 && otherLoanDuration <= 0) {
      setDurationError({ color: "red" });
    } else {
      setDurationError({ color: "black" });

      if (OTHloanChecker) {
        const addData = {
          otherLoanType: activeOtherLoan[0] ? activeOtherLoan[0].value : "",
          otherLoanTypeId: activeOtherLoan[0]
            ? activeOtherLoan[0].otherLoanTypeId
            : null,
          otherLoanReasonId: otherloanpurposes
            ? otherloanpurposes.bankloanpuroseId
            : null,
          otherloanpurpose: otherloanpurposes ? otherloanpurposes.value : "",
          otherLoanOtherPurpose: otherLoanReasons,
          indSubsidyDistributed: indSubsidyDistributed
            ? parseFloat(Number(indSubsidyDistributed).toFixed(2))
            : 0,
          externalNominee: externalNominee,
          otherloanPayablePrincipal: otherloanPayablePrincipalAmt
            ? parseFloat(Number(otherloanPayablePrincipalAmt).toFixed(2))
            : 0,
          otherloanPayableInterst: otherloanPayableInterstAmt
            ? parseFloat(Number(otherloanPayableInterstAmt).toFixed(2))
            : 0,
          otherLoanTakenAmt: parseFloat(
            Number(otherloanSanctionedAmtt).toFixed(2)
          ),
          otherLoanDuration: otherLoanDuration ? Number(otherLoanDuration) : "",
          otherLoanChecker: OTHloanChecker,
          otherTotalPaid: Number(TotalOtherloanPaid)
            ? parseFloat(Number(TotalOtherloanPaid).toFixed(2))
            : 0,
          otherLoanPaid: Number(otherLoanPaid)
            ? parseFloat(Number(otherLoanPaid).toFixed(2))
            : 0,
          otherInterestPaid: Number(otherInterestPaid)
            ? parseFloat(Number(otherInterestPaid).toFixed(2))
            : 0,
          paidDate: meetingHeldOnDate ? meetingHeldOnDate : "",
          paidMonth: meetingHeldOnDate && meetingHeldOnDate.slice(5, 7),
          paidYear: meetingHeldOnDate && meetingHeldOnDate.slice(0, 4),
          otherPenalty: otherPenalty ? Number(otherPenalty) : 0,
        };
        let temp = [];
        temp.push(addData);
        setOtherLoanChecker2(!otherLoanChecker2);
        AddDetailsOLR(temp);
        return temp;
      } else {
        const addData = {
          otherLoanType: activeOtherLoan[0] ? activeOtherLoan[0].value : "",
          otherLoanTypeId: activeOtherLoan[0]
            ? activeOtherLoan[0].otherLoanTypeId
            : null,
          otherloanpurpose: otherloanpurposes ? otherloanpurposes.value : "",
          otherLoanOtherPurpose: otherLoanReasons ? otherLoanReasons : "",
          indSubsidyDistributed: indSubsidyDistributed
            ? parseFloat(Number(indSubsidyDistributed).toFixed(2))
            : 0,
          externalNominee: externalNominee,
          otherloanPayablePrincipal: otherloanPayablePrincipalAmt
            ? parseFloat(Number(otherloanPayablePrincipalAmt).toFixed(2))
            : 0,
          otherloanPayableInterst: otherloanPayableInterstAmt
            ? parseFloat(Number(otherloanPayableInterstAmt).toFixed(2))
            : 0,
          otherLoanTakenAmt: parseFloat(
            Number(otherloanSanctionedAmtt).toFixed(2)
          ),
          otherLoanDuration: otherLoanDuration ? Number(otherLoanDuration) : "",
          otherLoanChecker: OTHloanChecker,
          otherPenalty: otherPenalty ? Number(otherPenalty) : 0,
        };
        let temp = [];
        temp.push(addData);
        AddDetailsOLR(temp);
        return temp;
      }
    }
  };

  const onRemoveChangeOLR = (otherLoanTypeId) => {
    const removeList = AddedDetailsOLR.filter(
      (AddDetails) => AddDetails.otherLoanTypeId !== otherLoanTypeId
    );
    AddDetailsOLR(removeList);
  };

  //OLR GRID END================================================================================

  const onSubmit = (e) => {
    e.preventDefault();

    let OLRes = [];
    if (activeOtherLoan.length <= 1) {
      OLRes = onAddOLRSingle(e);
    } else {
      OLRes = AddedDetailsOLR;
    }

    let otherLoanSanctionedToMemberSum = 0,
      indSubsidyDistributedSum = 0,
      otherloanPayablePrincipalAmtSum = 0,
      otherloanPayableInterstAmtSum = 0,
      otherLoanPaidSum = 0,
      otherInterestPaidSum = 0,
      otherTotalPaidSum = 0,
      otherLoanPaidToShgSum = 0,
      batchOtherLoanInterestShgSum = 0;

    OLRes.map((AddDetail) => {
      otherLoanSanctionedToMemberSum += parseFloat(
        AddDetail.otherLoanTakenAmt || 0
      );
      indSubsidyDistributedSum += parseFloat(
        AddDetail.indSubsidyDistributed || 0
      );
      ///other loan
      otherloanPayablePrincipalAmtSum += parseFloat(
        AddDetail.otherloanPayablePrincipalAmt || 0
      );
      otherloanPayableInterstAmtSum += parseFloat(
        AddDetail.otherloanPayableInterstAmt || 0
      );
      otherLoanPaidSum += parseFloat(AddDetail.otherLoanPaid || 0);
      otherInterestPaidSum += parseFloat(AddDetail.otherInterestPaid || 0);
      otherTotalPaidSum += parseFloat(AddDetail.otherTotalPaid || 0);
      // otherLoanPaidToShgSum += parseFloat(AddDetail.otherLoanPaid || 0);
      // batchOtherLoanInterestShgSum += parseFloat(
      //   AddDetail.otherInterestPaid || 0
      // );
    });

    var dateVal = new Date(selectedDate);

    const finalData = {
      //tab 1
      attendance: attendance && attendance ? attendance : 0,
      meetingHeldOnDate: selectedDate && selectedDate ? selectedDate : "",
      savingAmt: batchSavingAmt && batchSavingAmt ? batchSavingAmt : "",
      subscriptionAmt:
        batchSubscriptionAmt && batchSubscriptionAmt
          ? batchSubscriptionAmt
          : "",
      otherContribution:
        otherContribution && otherContribution ? otherContribution : "",
      membershipFees: membershipFees && membershipFees ? membershipFees : 0,
      otherIncome: otherIncome && otherIncome ? otherIncome : 0,
      memSeedMoney: memSeedMoney && memSeedMoney ? memSeedMoney : 0,
      memSecurityDepositToShg:
        memSecurityDepositToShg & memSecurityDepositToShg
          ? memSecurityDepositToShg
          : 0,

      //tab2
      savingWithdrawals:
        savingWithdrawals && savingWithdrawals ? savingWithdrawals : 0,
      dividendDistributed:
        dividendDistributed && dividendDistributed ? dividendDistributed : 0,
      securityDeposit: securityDeposit && securityDeposit ? securityDeposit : 0,

      //tab3
      internalLoan: NewInternalLoan,
      internalLoanPayment: paymentArray,

      //INST + PRI
      loanTakenAmt:
        NewInternalLoan.reduce(
          (acu, cur) => (acu += Number(cur.loanPayablePrincipal)),
          0
        ) +
        NewInternalLoan.reduce(
          (acu, cur) => (acu += Number(cur.loanPayableInterest)),
          0
        ),
      //pri paid
      loanPaid: paymentArray.reduce(
        (acu, cur) => (acu += Number(cur.internalPrincipalPaid)),
        0
      ),
      //int paid
      interestPaid: paymentArray.reduce(
        (acu, cur) => (acu += Number(cur.internalIntrestPaid)),
        0
      ),

      //tab 4
      otherPurpose: otherPurpose && otherPurpose ? otherPurpose : "",

      otherLoanTakenAmt: otherLoanSanctionedToMemberSum
        ? parseFloat(Number(otherLoanSanctionedToMemberSum).toFixed(2))
        : 0, //sum
      subsidyDistributed: indSubsidyDistributedSum
        ? parseFloat(Number(indSubsidyDistributedSum).toFixed(2))
        : 0, //sum
      /////////other loan
      otherLoanPaid: otherLoanPaidSum
        ? parseFloat(Number(otherLoanPaidSum).toFixed(2))
        : 0, //sum
      otherInterestPaid: otherInterestPaidSum
        ? parseFloat(Number(otherInterestPaidSum).toFixed(2))
        : 0, //sum
      otherLoan: OLRes,

      //All
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: membersBatch && membersBatch._id,
      memberName: membersBatch && membersBatch.memberName,
      meetingMonth: dateVal.getMonth() + 1,
      meetingYear: dateVal.getFullYear(),
      mtEnteredById: user && user._id ? user._id : "",
      mtEnteredByName: user && user.userName ? user.userName : "",
      institutionId:
        selectedBatchData[0] && selectedBatchData[0].institutionId
          ? selectedBatchData[0].institutionId
          : "",

      //////loan sanctioned
      batchId:
        selectedBatchData[0] && selectedBatchData[0]._id
          ? selectedBatchData[0]._id
          : "",
      memberId: membersBatch && membersBatch._id ? membersBatch._id : "",
      memberName:
        membersBatch && membersBatch.memberName ? membersBatch.memberName : "",
      batchMeetingMonth: dateVal.getMonth() + 1,
      batchMeetingYear: dateVal.getFullYear(),
      selectedDate: selectedDate && selectedDate ? selectedDate : "",

      otherPurpose: otherPurpose && otherPurpose ? otherPurpose : "",

      institutionId:
        selectedBatchData[0] && selectedBatchData[0].institutionId
          ? selectedBatchData[0].institutionId
          : "",

      loanSanctionedEnteredById: user && user._id ? user._id : "",
      loanSanctionedEnteredByName: user && user.userName ? user.userName : "",
    };

    AddMemberTransaction(finalData);
    onMemberMeetingModalChange(true);
  };
  ////////////////////////////////////////
  const [showPayment, setShowpayment] = useState(false);
  const [internalTypeName, setInternalTypeName] = useState("");

  const [paymentArray, setPaymentArray] = useState([]);

  const onDeleteInternalPayment = (data) => {
    setPaymentArray(
      paymentArray.filter((e) => e.internalLoanType != data.internalLoanType)
    );
    deleteBankLoanDetail(data);
  };

  const clickPay = (loanName) => {
    setShowpayment(true);
    setInternalTypeName(loanName);
  };

  //123
  const [NewInternalLoan, setNewInternalLoan] = useState([]);

  useEffect(() => {}, [NewInternalLoan], [paymentArray]);

  const [selectedEditLoan, setSelectedEditLoan] = useState({});

  const internalDelete = (loan) => {
    setNewInternalLoan(
      NewInternalLoan.filter((ele) => ele.intLoanType != loan.intLoanType)
    );
  };

  ////////////////////////////////////
  const [showRepaymentModal, setShowRepaymentModal] = useState(false);
  const [loanRepaymentData, setLoanRepaymentData] = useState([]);
  /////////////////////////

  const indianFormat = (dateData) => {
    try {
      return dateData
        .split("-")
        .reverse("")
        .join("-");
    } catch (er) {
      return "";
    }
  };

  ///////////

  const [svngAmtBKName, setSvngAmtBKName] = useState("");

  //////////

  ////////////////////////////////////////////// 123

  const [mainBankToSel, setMainBankToSel] = useState("");
  useEffect(() => {
    if (activeBankData) {
      let allbanks = activeBankData.map((ele) => {
        return { label: ele.batchBankName, value: ele.batchBankName };
      });
      setMainBankToSel(allbanks);
    }
  }, [activeBankData]);
  // activeBankData

  ///////////////////////////////////////////////////

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <Tabs selectedIndex={tabIndex}>
        <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
          <TabList>
            <Tab tabfor="0">Basic</Tab>
            <Tab tabfor="1">Expense</Tab>
            <Tab tabfor="2">New IL Sanc</Tab>
            <Tab tabfor="3">IL Repayment</Tab>
            <Tab tabfor="4">Other Loan</Tab>
          </TabList>
        </div>

        <div className="row col-12">
          <div className="col-lg-3  col-sm-12 ">
            <label className="label-control">Meeting Date : {mdDate}</label>
          </div>
          <div className="col-lg-3  col-sm-12 ">
            <label className="label-control">Member Name : {memberName} </label>
          </div>
          <div className="col-lg-3 col-sm-12 ">
            {/* 123 */}
            <Select
              options={mainBankToSel}
              className="mt-2"
              // value={svngAmtBKName}
              placeholder="Select Bank"
              onChange={(e) => setSvngAmtBKName(e)}
            />
          </div>
          <div className="col-lg-3   col-sm-12 ">
            {" "}
            {allPermission && allPermission.includes("Attendance") ? (
              <>
                {" "}
                <label className="label-control">Is Present ? :</label>
                <input
                  style={{
                    // height: "10px",
                    // width: "10px",
                    // borderRadius: "50%",
                    display: "inline-block",
                  }}
                  className="ml-2"
                  type="checkbox"
                  id="Unconfirmed"
                  checked={attendance}
                  onChange={handlePresent}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <TabPanel tabId="0">
          <form onSubmit={(e) => NextBackBtn(1)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              {" "}
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                <label className="label-control">
                  Savings Amount*{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="	Savings amount Cash and Bank Total Value"
                  />{" "}
                  :(1000){" "}
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchbankSavingAmt"
                        value="500"
                        placeholder="Enter Bank Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      {" "}
                      <input
                        type="number"
                        name="batchCashSavingAmt"
                        value="500"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <label className="label-control">
                  Subscription Amount*{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Subscription amount Cash and Bank Total Value"
                  />{" "}
                  :(90)
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchBankSubscriptionAmt"
                        value="90"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash </div>{" "}
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchBankSubscriptionAmt"
                        value="0"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        // disabled={svngAmtBKName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </div>

                <label className="label-control">
                  Other Contribution{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Other contribution Cash and Bank Total Value"
                  />{" "}
                  :(200)
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchBankOtherContribution"
                        value="0"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    {" "}
                    <div className="col-lg-2 mt-2">Cash</div>{" "}
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchCashOtherContribution"
                        value="200"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        // disabled={svngAmtBKName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </div>
                <label className="label-control">
                  Other Income{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Other Income	Cash and Bank Total Value"
                  />{" "}
                  :(0)
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchBankOtherIncome"
                        value="0"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchCashOtherIncome"
                        value="0"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        // disabled={svngAmtBKName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                <label className="label-control">
                  Total Savings{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="	
                    Total Savings amount"
                  />{" "}
                  :
                </label>
                <div className="row col-12">
                  <div className="col-lg-12">
                    <input
                      type="text"
                      value="1000"
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      disabled
                    />
                  </div>
                </div>
                <label className="label-control">
                  {" "}
                  Membership Fees{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Membership Fees	Cash and Bank Total Value"
                  />{" "}
                  :(350)
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchBankMembershipFees"
                        value="300"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchCashMembershipFees"
                        value="50"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        // disabled={svngAmtBKName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </div>

                <label className="label-control">
                  Seed Money{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Seed Money	Cash and Bank Total Value"
                  />{" "}
                  :(10000)
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchBankSeedMoney"
                        value="5000"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>{" "}
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchCashSeedMoney"
                        value="5000"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        // disabled={svngAmtBKName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </div>

                <label className="label-control">
                  Security Deposit Member To SHG{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Security deposit	Cash and Bank Total Value"
                  />{" "}
                  :(0)
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchBankSecurityDepositToShg"
                        value="0"
                        className="form-control"
                        placeholder="Enter Bank Amount"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchCashSecurityDepositToShg"
                        value="0"
                        placeholder="Enter Cash Amount"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        // disabled={svngAmtBKName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>

            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="1">
          <form onSubmit={(e) => NextBackBtn(2)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className=" col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate ">
                <label className="label-control">
                  Savings Withdrawal{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="	Savings withdrawal Cash and Bank Total Value"
                  />{" "}
                  :(200)
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchBankSavingWithdrawals"
                        value={savingWithdrawals}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      {" "}
                      <input
                        type="number"
                        name="batchCashSavingWithdrawals"
                        value={savingWithdrawals}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                </div>

                <label className="label-control">
                  Dividend Distributed{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="Subscription amount Cash and Bank Total Value"
                  />{" "}
                  :(90):
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchBankDividendDistributed"
                        value={dividendDistributed}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash </div>{" "}
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchCashDividendDistributed"
                        value={dividendDistributed}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                <label className="label-control">
                  Security Deposit SHG To Member{" "}
                  <img
                    className="img_icon_size log "
                    src={require("../../static/images/info.png")}
                    alt="Reason"
                    style={{ height: "18px", width: "18px" }}
                    title="	Savings amount Cash and Bank Total Value"
                  />{" "}
                  :(1000) :
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="batchBankSecurityDeposit"
                        value={securityDeposit}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      {" "}
                      <input
                        type="number"
                        name="batchCashBankSecurityDeposit"
                        value={securityDeposit}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>

            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(0)}
              >
                Previous
              </button>
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="2">
          <form onSubmit={(e) => NextBackBtn(3)}>
            <div className="body-inner no-padding table-responsive">
              <table
                className="tabllll table table-bordered table-striped table-hover mt-2"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>IL Type</th>
                    <th>Sanc On</th>
                    <th>Sanc Prin</th>
                    <th>Sanc Int</th>
                    <th>Loan Status</th>
                    <th>Operation</th>
                  </tr>
                </thead>
                <tbody>
                  {NewInternalLoan &&
                    NewInternalLoan.map((loan, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{loan.intLoanType}</td>
                          <td>{mdDate}</td>
                          <td>{loan.loanPayablePrincipal}</td>
                          <td>{loan.loanPayableInterest}</td>
                          <td>Active</td>
                          <td>
                            <span
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => {
                                internalDelete(loan);
                              }}
                            >
                              <img
                                src={require("../../static/images/delete.png")}
                                alt="delete"
                                id="img_tool_admin"
                                className="pass_admin_help_icon_question"
                              />
                            </span>
                            &nbsp;&nbsp;
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <ShgInternalLoan
              Internalonly={Internalonly}
              setNewInternalLoan={setNewInternalLoan}
              internalLoanData={selectedEditLoan}
              NewInternalLoan={NewInternalLoan}
              allpurpose={allpurpose}
            />

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(1)}
              >
                Previous
              </button>
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="3">
          <form onSubmit={(e) => NextBackBtn(4)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <h5>Active Loans</h5>
            </div>
            <div className="body-inner no-padding table-responsive">
              <table
                className="tabllll table table-bordered table-striped table-hover mt-2"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>Info</th>
                    <th>IL Type</th>
                    <th>Sanc On</th>
                    <th>Sanc Prin</th>
                    <th>Total Prin Paid</th>
                    <th>Remaining Prin</th>
                    <th>Operation</th>
                  </tr>
                </thead>
                <tbody>
                  {NewInternalLoan &&
                    NewInternalLoan.map((loan, idx) => {
                      return (
                        <tr key={idx}>
                          <td></td>
                          <td>{loan.intLoanType}</td>
                          <td>{mdDate}</td>
                          <td>{Number(loan.loanPayablePrincipal)}</td>
                          <td>0</td>
                          <td>{Number(loan.loanPayablePrincipal)}</td>
                          <td>
                            {paymentArray.some(
                              (paid) =>
                                paid.internalLoanType == loan.intLoanType
                            ) ? (
                              <>Paid</>
                            ) : (
                              <>
                                <span
                                  className="mt-5 ml-3 "
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    clickPay(loan.intLoanType);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    className="bi bi-cash-coin"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                                    />
                                    <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                                    <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                                    <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                                  </svg>
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}

                  {Internalonly &&
                    Internalonly.map((loan, idx) => {
                      let remaingLoan =
                        loan.loanPayablePrincipal -
                        (loan.loanCurPrincipal || 0);
                      // let [year, month, day] = loan.meetingHeldOnDate.split(
                      //   "-"
                      // );
                      return (
                        <tr key={idx}>
                          <td>
                            <span
                              className="ml-1"
                              onClick={() => {
                                setShowRepaymentModal(true);
                                setLoanRepaymentData(loan.loanRepayment || []);
                              }}
                            >
                              {
                                <img
                                  className="img_icon_size log mb-1"
                                  src={require("../../static/images/information.png")}
                                  alt="Repayment Info"
                                />
                              }
                            </span>
                          </td>
                          <td>{loan.intLoanType}</td>
                          <td>{indianFormat(loan.meetingHeldOnDate)}</td>
                          <td>{loan.loanPayablePrincipal}</td>
                          <td>{loan.loanCurPrincipal}</td>
                          <td>{remaingLoan}</td>
                          <td>
                            {paymentArray.some(
                              (item) =>
                                item.internalLoanType == loan.intLoanType
                            ) ? (
                              <>Paid</>
                            ) : (
                              <>
                                {
                                  <>
                                    <span
                                      className="ml-4"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        clickPay(loan.intLoanType);
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        fill="currentColor"
                                        class="bi bi-cash-coin"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                                        />
                                        <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                                        <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                                        <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                                      </svg>
                                    </span>
                                  </>
                                }
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {showPayment ? (
              <LoanPayment
                setShowpayment={setShowpayment}
                internalTypeName={internalTypeName}
                setPaymentArray={setPaymentArray}
                paymentArray={paymentArray}
                meetingHeldOnDate={meetingHeldOnDate}
              />
            ) : (
              <></>
            )}
            <div className=" body-inner no-padding table-responsive">
              <label
                className="form-control-label"
                style={{ marginTop: "10px" }}
              >
                Repayment Details:
              </label>
              <table
                className="tabllll table table-bordered table-striped table-hover mt-2"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>IL Type</th>
                    <th>Paid Principal</th>
                    <th>Paid Interest</th>
                    <th>Penalty</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentArray &&
                    paymentArray.map((paid, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{paid.internalLoanType}</td>
                          <td>{paid.internalPrincipalPaid}</td>
                          <td>{paid.internalIntrestPaid}</td>
                          <td>{paid.penalty}</td>
                          <td>
                            <span onClick={() => onDeleteInternalPayment(paid)}>
                              <img
                                src={require("../../static/images/delete.png")}
                                alt="help"
                                id="img_tool_admin"
                                className="pass_admin_help_icon_question"
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(2)}
              >
                Previous
              </button>
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="4">
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              Active Loans :{" "}
              {OtherLoanNames &&
                OtherLoanNames.map((ele, idx) => {
                  return (
                    <label key={idx} className="label-control">
                      {ele.label} ,
                    </label>
                  );
                })}
            </div>
          </div>
          {activeOtherLoan.length > 1 ? (
            <>
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control" style={otherLoanErrorStyle}>
                    {/* */}
                    Other Loan Types*:
                  </label>
                  <Select
                    name="otherLoanTypes"
                    options={activeOtherLoan}
                    isSearchable={true}
                    value={otherLoanType}
                    placeholder="Select Mode"
                    onChange={(e) => onotherLoanTypeChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
                {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Other Loan Sanctioned Amount :{" "}
                  </label>
                  <input
                    type="number"
                    name="otherloanSanctionedAmt"
                    value={otherloanSanctionedAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div> */}
                {dataAvaiable === "true" ? (
                  <>
                    <>
                      <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
                        <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                          <label className="label-control font-weight-bold">
                            Other Loan Status :
                          </label>
                          <label className="label-control font-weight-bold">
                            {" "}
                            {Otheronly &&
                              Otheronly[0] &&
                              Otheronly[0].loanStatus}
                          </label>
                        </div>
                        {/* <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                          <label className="label-control font-weight-bold">
                            other Loan Sancationed Amount :
                          </label>
                          <label className="label-control font-weight-bold">
                            {" "}
                            {Otheronly &&
                              Otheronly[0] &&
                              Otheronly[0].loanSanctionedAmt}
                          </label>
                        </div> */}
                      </div>
                    </>
                  </>
                ) : (
                  <>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Loan Sanctioned Principal :{" "}
                      </label>
                      <input
                        type="number"
                        name="otherloanPayablePrincipalAmt"
                        value={otherloanPayablePrincipalAmt}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Loan Sanctioned Interest:
                      </label>
                      <input
                        type="number"
                        name="otherloanPayableInterstAmt"
                        value={otherloanPayableInterstAmt}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Total Sanctioned Amount :
                      </label>
                      <input
                        type="number"
                        name="otherloanSanctionedAmtt"
                        value={otherloanSanctionedAmtt}
                        className="form-control"
                        // onWheel={() => document.activeElement.blur()}
                        // onChange={(e) => onInputChange(e)}
                        // onKeyDown={(e) => funcKeyDown(e)}
                        disabled={true}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control" style={DurationError}>
                        Duration (In Months) :{" "}
                      </label>
                      <input
                        type="number"
                        name="otherLoanDuration"
                        value={otherLoanDuration}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">Loan Purpose :</label>
                      <Select
                        name="bankloanPurpose"
                        options={allbankpurpose}
                        isSearchable={true}
                        value={otherloanpurposes}
                        placeholder="Select Purpose"
                        onChange={(e) => onBankLoanPurposeChange(e)}
                      />
                    </div>
                    {showbankotherPurposeSection && (
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Loan Purpose :
                        </label>
                        <input
                          type="text"
                          name="otherLoanReasons"
                          value={otherLoanReasons}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    )}

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Subsidy Distributed :
                      </label>
                      <input
                        type="number"
                        name="indSubsidyDistributed"
                        value={indSubsidyDistributed}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">Nominee :</label>
                      <input
                        type="text"
                        name="externalNominee"
                        value={externalNominee}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </>
                )}

                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">Has Repayment ? :</label>
                    <input
                      style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "50%",
                        display: "block",
                      }}
                      type="checkbox"
                      id="Unconfirmed"
                      checked={OTHloanChecker}
                      onChange={handleOnOtherChange2}
                    />
                  </div>
                </div>

                {OTHloanChecker && (
                  <>
                    <div className="row col-lg-12 col-md-9 col-sm-9 col-12 py-4 my-1">
                      {/* other loan start */}

                      {/* other loan end */}

                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Principal Paid :
                        </label>
                        <input
                          type="number"
                          name="otherLoanPaid"
                          value={otherLoanPaid}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Interest Paid:
                        </label>
                        <input
                          type="number"
                          name="otherInterestPaid"
                          value={otherInterestPaid}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                    </div>

                    <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                      <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Total Paid :
                        </label>
                        <input
                          type="number"
                          name="TotalOtherloanPaid"
                          value={TotalOtherloanPaid}
                          className="form-control"
                          disabled={true}
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Remaining Loan(Principal) :
                        </label>
                        <input
                          type="text"
                          value={othRemaingLoan}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          disabled
                        />
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">Penalty fees :</label>
                        <input
                          type="text"
                          name="otherPenalty"
                          value={otherPenalty}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <button
                    onClick={(e) => onAddOLR(e)}
                    className="btn sub_form btn_continue blackbrd Save float-right"
                  >
                    ADD
                  </button>
                </div>
              </div>
              <div className=" body-inner no-padding  table-responsive">
                <table
                  className="tabllll table table-bordered table-striped table-hover mt-2"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>OL Type</th>
                      <th>OL Sanctioned Principal</th>
                      <th>OL Sanctioned Interest</th>
                      <th>OL Total Sanctioned Amount</th>
                      <th>Duration</th>
                      <th>OL Purpose</th>
                      <th>Subsidy Distributed</th>
                      <th>Nominee</th>
                      <th>OL Principal Paid</th>
                      <th>OL Interest Paid</th>
                      <th>OL Total Paid</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AddedDetailsOLR &&
                      AddedDetailsOLR.map((AddDetail, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{AddDetail.otherLoanType}</td>
                            <td>{AddDetail.otherloanPayablePrincipal}</td>
                            <td>{AddDetail.otherloanPayableInterst}</td>

                            <td>{AddDetail.otherLoanTakenAmt}</td>
                            <td>{AddDetail.otherLoanDuration}</td>
                            <td>
                              {AddDetail.otherloanpurpose}{" "}
                              {AddDetail.otherLoanOtherPurpose
                                ? AddDetail.otherLoanOtherPurpose
                                : ""}
                            </td>
                            <td>{AddDetail.indSubsidyDistributed}</td>
                            <td>{AddDetail.externalNominee}</td>
                            <td>{AddDetail.otherLoanPaid}</td>
                            <td>{AddDetail.otherInterestPaid}</td>
                            <td>{AddDetail.otherTotalPaid}</td>
                            <td>
                              <img
                                className="img_icon_size log"
                                onClick={() =>
                                  onRemoveChangeOLR(AddDetail.otherLoanTypeId)
                                }
                                src={require("../../static/images/close-buttonRed.png")}
                                alt="Remove"
                                title="Remove"
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                {alreadyLoanAvaiable === true ? (
                  <>
                    <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
                      <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                        <label className="label-control font-weight-bold">
                          Other Loan Status:
                        </label>
                        <label className="label-control font-weight-bold">
                          {" "}
                          {Otheronly && Otheronly[0] && Otheronly[0].loanStatus}
                        </label>
                      </div>
                      <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                        <label className="label-control font-weight-bold">
                          other Loan Sancationed Amount :
                        </label>
                        <label className="label-control font-weight-bold">
                          {" "}
                          {Otheronly &&
                            Otheronly[0] &&
                            Otheronly[0].loanSanctionedAmt}
                        </label>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Loan Sanctioned Principal :{" "}
                      </label>
                      <input
                        type="number"
                        name="otherloanPayablePrincipalAmt"
                        value={otherloanPayablePrincipalAmt}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Loan Sanctioned Interest:{" "}
                      </label>
                      <input
                        type="number"
                        name="otherloanPayableInterstAmt"
                        value={otherloanPayableInterstAmt}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Total Sanctioned Amount :{" "}
                      </label>
                      <input
                        type="number"
                        name="otherloanSanctionedAmtt"
                        value={otherloanSanctionedAmtt}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Duration (In Months) :{" "}
                      </label>
                      <input
                        type="number"
                        name="otherLoanDuration"
                        value={otherLoanDuration}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">Loan Purpose :</label>
                      <Select
                        name="bankloanPurpose"
                        options={allbankpurpose}
                        isSearchable={true}
                        value={otherloanpurposes}
                        placeholder="Select Purpose"
                        onChange={(e) => onBankLoanPurposeChange(e)}
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    {showbankotherPurposeSection && (
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Other Loan Purpose :
                        </label>
                        <input
                          type="text"
                          name="otherLoanReasons"
                          value={otherLoanReasons}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    )}
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Subsidy Distributed :
                      </label>
                      <input
                        type="number"
                        name="indSubsidyDistributed"
                        value={indSubsidyDistributed}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">Nominee :</label>
                      <input
                        type="text"
                        name="externalNominee"
                        value={externalNominee}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </>
                )}
                {(Otheronly &&
                  Otheronly[0] &&
                  Otheronly[0].loanStatus === "Active" &&
                  Otheronly[0].isLoanType === "otherLoan") ||
                (Otheronly &&
                  Otheronly[0] &&
                  Otheronly[0].loanStatus === "Overdue" &&
                  Otheronly[0].isLoanType === "otherLoan") ||
                hideFieldOther === "true" ? (
                  <>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Has Repayment ? :
                        </label>
                        <input
                          style={{
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                            display: "block",
                          }}
                          type="checkbox"
                          id="Unconfirmed"
                          checked={OTHloanChecker}
                          onChange={handleOnOtherChange2}
                        />
                      </div>
                    </div>
                    {OTHloanChecker && (
                      <>
                        <div className="row col-lg-12 col-md-9 col-sm-9 col-12 py-4 my-1">
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Principal Paid :
                            </label>
                            <input
                              type="number"
                              name="otherLoanPaid"
                              value={otherLoanPaid}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>

                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Interest Paid :
                            </label>
                            <input
                              type="number"
                              name="otherInterestPaid"
                              value={otherInterestPaid}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                        </div>

                        <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                          <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Total Paid :
                            </label>
                            <input
                              type="number"
                              name="TotalOtherloanPaid"
                              value={TotalOtherloanPaid}
                              className="form-control"
                              disabled={true}
                            />
                          </div>
                          {activeOtherLoan.length > 1 ? (
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                Other Remaining Loan :
                              </label>
                              <input
                                type="text"
                                value={
                                  prevData[0] &&
                                  Number(
                                    prevData[0].otherRemainingLoan
                                  ).toFixed(2)
                                }
                                // value={othsingle}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                disabled
                              />
                            </div>
                          ) : (
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                Other Remaining Loan :
                              </label>
                              <input
                                type="text"
                                // value={
                                //   prevData[0] &&
                                //   Number(
                                //     prevData[0].otherRemainingLoan
                                //   ).toFixed(2)
                                // }
                                // value={othsingle}
                                className="form-control"
                                value={othsingle}
                                onChange={(e) => onInputChange(e)}
                                disabled
                              />
                            </div>
                          )}
                          {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Remaining Loan :
                            </label>
                            <input
                              type="text"
                              value={
                                prevData[0] &&
                                Number(prevData[0].otherRemainingLoan).toFixed(
                                  2
                                )
                              }
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                              disabled
                            />
                          </div> */}
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Penalty fees :
                            </label>
                            <input
                              type="text"
                              name="otherPenalty"
                              value={otherPenalty}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}

          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label className="label-control colorRed">
              * Indicates mandatory fields, Please fill mandatory fields before
              Submit
            </label>
          </div>

          <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue blackbrd Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Submit"
                onClick={(e) => onSubmit(e)}
                className="btn sub_form btn_continue Save float-right"
              />
            )}
            <button
              className="btn sub_form btn_continue Save float-right"
              onClick={() => NextBackBtn(3)}
            >
              Previous
            </button>
          </div>
        </TabPanel>
      </Tabs>

      <Modal
        show={showRepaymentModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">Repayment Details</h3>
          </div>
          <div className="col-lg-2">
            <button
              onClick={() => setShowRepaymentModal(false)}
              className="close"
            >
              <img
                src={require("../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <LoanRepaymentDetails
            loanRepaymentData={loanRepaymentData}
            watchingDate={mdDate}
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

AddMembermeetingDetailModal.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getParticularLoanReason: PropTypes.func.isRequired,
  AddMemberTransaction: PropTypes.func.isRequired,
  AddLoanSanctionedDetails: PropTypes.func.isRequired,
  getMemberPreveiousMeetingData: PropTypes.func.isRequired,
  getActiveOtherLoanType: PropTypes.func.isRequired,
  getActiveBankData: PropTypes.func.isRequired,
  getMembersMeetingData: PropTypes.func.isRequired,
  getMemberPrevMeetingData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getParticularLoanReason,
  AddMemberTransaction,
  AddLoanSanctionedDetails,
  getMemberPreveiousMeetingData,
  getActiveOtherLoanType,
  getActiveBankData,
  getMembersMeetingData,
  getSanctionedData,
  getMemberPrevMeetingData,
  deleteBankLoanDetail,
})(AddMembermeetingDetailModal);
