import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import {
  getAllVerification,
  batchAndMeetingDetails,
  getAllVerificationDropdown,
  getAllVerificationFilter,
  getMeetingTransactionData,
} from "../../actions/shg";
import Spinner from "../layout/Spinner";
import Pagination from "../layout/Pagination";

const AllVerifications = ({
  auth: { isAuthenticated, user, users },
  shg: { verification, verificationDropdown },
  getAllVerification,
  batchAndMeetingDetails,
  getAllVerificationDropdown,
  getAllVerificationFilter,
  getMeetingTransactionData,
}) => {
  let selVerfyBatchDetails = JSON.parse(
    localStorage.getItem("selVerfyBatchDetails")
  );
  useEffect(() => {
    getAllVerification(selVerfyBatchDetails);
  }, [getAllVerification]);
  useEffect(() => {
    getAllVerificationDropdown();
  }, [getAllVerificationDropdown]);
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const [showHide, setShowHide] = useState({
    showBatchSection:
      loggedUserInstitutionData &&
      loggedUserInstitutionData.institutionType === "Zoned"
        ? true
        : false,
  });
  const { showBatchSection } = showHide;

  //pagination code

  const [currentData, setCurrentData] = useState(1);
  const [dataPerPage] = useState(8);
  //Get Current Data
  const indexOfLastData = currentData * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentDatas =
    verification && verification.slice(indexOfFirstData, indexOfLastData);
  //change page
  const paginate = (nmbr) => {
    setCurrentData(nmbr);
  };
  //pagination code ends

  const handleVerifyBatch = (verification) => {
    localStorage.removeItem("batchTransactionData");

    const batchIdData = {
      batchId: verification.mdBatchId,
      batchTransactionType: verification.output.batchTransactionType,
      mdDate: verification.mdDate,
      mdBatchId: verification.mdBatchId,
      callFrom: "Verification",
      batchIdVal: verification.mdBatchId,
      batchName: verification.output.batchName,
    };
    let meetingBatchInfo = batchIdData;
    getMeetingTransactionData(meetingBatchInfo);
    batchAndMeetingDetails(batchIdData);
  };

  const onClickReset = () => {
    getAllVerification("");
    getbatchsData("");
  };

  const allbatchesList = [];

  verificationDropdown.map((batchs) =>
    allbatchesList.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState(
    selVerfyBatchDetails &&
      selVerfyBatchDetails.batchIdVal && {
        batchId: selVerfyBatchDetails.batchIdVal,
        label: selVerfyBatchDetails.batchName,
        value: selVerfyBatchDetails.batchName,
      }
  );
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();

  const onBatchChange = (e) => {
    var batchId = "";
    var batchName = "";
    getbatchsData(e);
    batchId = e.batchId;
    batchName = e.value;
    setbatchId(batchId);
    setbatchName(batchName);
    const changeData = {
      selBatchId: batchId,
      batchName: batchName,
    };
    setCurrentData(1);
    getAllVerification(changeData);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">All Verifications </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
            {user.userGroupName === "Super Admin" && (
              <div className="col-lg-3 col-md-7 col-sm-5 col-5 mt-1">
                <Select
                  name="institutionName"
                  // options={allinstitution}
                  isSearchable={true}
                  // value={institution}
                  placeholder="Select Institution"
                  //onChange={(e) => onInchargeChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
            )}
            <div className="col-lg-3 col-md-7 col-sm-5 col-11 mt-1">
              <Select
                name="batchName"
                options={allbatchesList}
                isSearchable={true}
                value={batchs}
                placeholder="Select SHG"
                onChange={(e) => onBatchChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>

            <div className="col-lg-8 col-md-5 col-sm-6 col-11 py-3">
              <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>

              <img
                className="img_icon_size log float-right "
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>SHG Name</th>
                        <th>SHG Code</th>
                        {showBatchSection && (
                          <>
                            <th>Parish</th>
                          </>
                        )}
                        <th>Submitted Meetings</th>
                        <th>Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentDatas &&
                        currentDatas.map((verification, idx) => {
                          var ED = verification.mdDate.split(/\D/g);
                          var mdDate = [ED[2], ED[1], ED[0]].join("-");
                          return (
                            <tr
                              key={idx}
                              style={
                                verification.mdStatus === "Rechecked"
                                  ? { background: "#ffdbb0" }
                                  : { background: "none" }
                              }
                            >
                              {/* <td>
                                {" "}
                                <Link
                                  to={{
                                    pathname:
                                      verification &&
                                      verification.output
                                        .batchTransactionType === "Memberwise"
                                        ? "/verification-batch-membermeeting"
                                        : "/verification-batchmeeting",
                                  }}
                                  onClick={() =>
                                    handleVerifyBatch(verification)
                                  }
                                >
                                  {verification.output.batchName}
                                </Link>
                              </td> */}
                              <td>
                                {" "}
                                {idx === 0 ? (
                                  <>
                                    <Link
                                      to={{
                                        pathname:
                                          verification &&
                                          verification.output
                                            .batchTransactionType ===
                                            "Memberwise"
                                            ? "/verification-batch-membermeeting"
                                            : "/verification-batchmeeting",
                                      }}
                                      onClick={() =>
                                        handleVerifyBatch(verification)
                                      }
                                    >
                                      <u>{verification.output.batchName}</u>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <i
                                    // to={{
                                    //   pathname:
                                    //     verification &&
                                    //     verification.output
                                    //       .batchTransactionType ===
                                    //       "Memberwise"
                                    //       ? "/verification-batch-membermeeting"
                                    //       : "/verification-batchmeeting",
                                    // }}

                                    // onClick={

                                    //   () =>
                                    //     handleVerifyBatch(verification)
                                    // }
                                    >
                                      {verification.output.batchName}
                                    </i>
                                  </>
                                )}
                              </td>
                              <td>{verification.output.batchCode}</td>
                              {showBatchSection && (
                                <>
                                  <td>{verification.output.parishName}</td>
                                </>
                              )}
                              <td>{mdDate}</td>
                              <td>{verification.recheckReason}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
                    {verification && verification.length !== 0 ? (
                      <Pagination
                        dataPerPage={dataPerPage}
                        totalData={verification.length}
                        paginate={paginate}
                        currentPage={currentData}
                      />
                    ) : (
                      <Fragment />
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 align_right">
                    <label>No. of verifications : {verification.length}</label>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

AllVerifications.propTypes = {
  auth: PropTypes.object.isRequired,
  getAllVerification: PropTypes.func.isRequired,
  batchAndMeetingDetails: PropTypes.func.isRequired,
  getAllVerificationDropdown: PropTypes.func.isRequired,
  getAllVerificationFilter: PropTypes.func.isRequired,
  getMeetingTransactionData: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getAllVerification,
  batchAndMeetingDetails,
  getAllVerificationDropdown,
  getAllVerificationFilter,
  getMeetingTransactionData,
})(AllVerifications);
