import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import Select from "react-select";
import { EditBatchLoanDetails } from "../../actions/shg";
const EditLoanDetails = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { loanreason },
  loanpurposedata,
  onUpdateModalChange,
  EditBatchLoanDetails,
}) => {
  //formData
  const [formData, setFormData] = useState({
    memberName:
      loanpurposedata && loanpurposedata.memberName
        ? loanpurposedata.memberName
        : "",
    loanSanctionedAmt:
      loanpurposedata && loanpurposedata.loanSanctionedAmt
        ? loanpurposedata.loanSanctionedAmt
        : "",
    otherPurpose:
      loanpurposedata && loanpurposedata.otherPurpose
        ? loanpurposedata.otherPurpose
        : "",
    internalNominee:
      loanpurposedata && loanpurposedata.internalNominee
        ? loanpurposedata.internalNominee
        : "",
    isSubmitted: false,
  });

  const {
    otherPurpose,
    loanSanctionedAmt,
    memberName,
    internalNominee,
  } = formData;
  const [showHide, setShowHide] = useState({
    showotherPurposeSection:
      loanpurposedata && loanpurposedata.loanPurpose === "Other" ? true : false,
    showotherLoanSanctionedToShgSection: "",
    showSubsidyReceivedSection: "",
  });
  const {
    showotherPurposeSection,
    showotherLoanSanctionedToShgSection,
    showSubsidyReceivedSection,
  } = showHide;
  const allpurpose = [];
  loanreason.map((purpose) =>
    allpurpose.push({
      loanReasonId: purpose._id,
      label: purpose.loanPurpose,
      value: purpose.loanPurpose,
    })
  );

  const [purpose, getloanPurposeData] = useState(
    loanpurposedata && loanpurposedata
      ? allpurpose &&
          allpurpose.filter(
            (x) => x.loanReasonId === loanpurposedata.loanReasonId
          )[0]
      : ""
  );
  const [loanReasonId, setloanPurposeID] = useState(
    loanpurposedata && loanpurposedata.loanReasonId
  );
  const [loanPurpose, setloanPurposeName] = useState(
    loanpurposedata && loanpurposedata.loanPurpose
  );

  const onPurposeChange = (e) => {
    var loanReasonId = "";
    var loanPurpose = "";
    getloanPurposeData(e);

    loanReasonId = e.loanReasonId;
    loanPurpose = e.value;

    setloanPurposeID(loanReasonId);
    setloanPurposeName(loanPurpose);

    if (e) {
      setFormData({
        ...formData,
        loanPurpose: e,
      });
    }

    if (e.value === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
      setFormData({ ...formData, otherPurpose: "" });
    }
  };

  const onInputChange1 = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const [showInformationModal, setShowInformation] = useState(false);

  const handleInformationModalClose = () => setShowInformation(false);
  const LogoutModalClose = () => {
    handleInformationModalClose();
  };
  const onUpdate = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: loanpurposedata ? loanpurposedata._id : "",
      batchId: loanpurposedata.batchId,
      memberId: loanpurposedata.memberId,
      meetingHeldOnDate: loanpurposedata.meetingHeldOnDate,
      loanSanctionedAmt: loanSanctionedAmt,
      otherPurpose: otherPurpose,
      loanReasonId: loanReasonId,
      loanPurpose: loanPurpose,
      internalNominee: internalNominee,
      otherLoanSanctionedAmt: null,
      otherLoanReasonId: null,
      otherLoanReason: null,
      otherloanotherpurpose: null,
      loanSanctionedEditedById: user && user._id,
      loanSanctionedEditedByName: user && user.userFullName,
      loanSanctionedEditedDateTime: Date.now(),
      institutionId: loanpurposedata.institutionId,
      memberEditData: loanpurposedata,
    };
    EditBatchLoanDetails(finalData);
    onUpdateModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
        <form onSubmit={(e) => onUpdate(e)}>
          <div className="row card-new  py-3">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control">
                Loan Sanctioned-Member : {memberName}
              </label>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control">Loan Sanctioned Amount* :</label>
              <input
                type="number"
                name="loanSanctionedAmt"
                value={loanSanctionedAmt}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => onInputChange1(e)}
                onKeyDown={(e) => funcKeyDown(e)}
                required
              />
            </div>
            <div className="col-lg-6  col-md-12 col-sm-12 col-12">
              <label className="label-control">Nominee :</label>
              <input
                type="text"
                name="internalNominee"
                value={internalNominee}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => onInputChange1(e)}
              />
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label
                className="label-control"
                // style={PurposeErrorStyle}
              >
                Loan Sanctioned Purpose :
              </label>
              <Select
                name="loanPurpose"
                options={allpurpose}
                isSearchable={true}
                value={purpose}
                placeholder="Select Purpose"
                onChange={(e) => onPurposeChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>

            {showotherPurposeSection && (
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label className="label-control">Other Reason*</label>
                <input
                  type="text"
                  name="otherPurpose"
                  value={otherPurpose}
                  className="form-control"
                  onChange={(e) => onInputChange1(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                  required
                />
              </div>
            )}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <input
                type="submit"
                name="Submit"
                value="Update"
                className="btn sub_form btn_continue blackbrd Save float-right"
              />
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditLoanDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, { EditBatchLoanDetails })(
  EditLoanDetails
);
