import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getCompletedMeetingDetails } from "../../actions/shg";
import Spinner from "../layout/Spinner";

const AddMemberwisebatchMeetingDetails = ({
  auth: { isAuthenticated, user, users },
  shg: { completedmeetingdetails },
  getCompletedMeetingDetails,
}) => {
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  useEffect(() => {
    getCompletedMeetingDetails(batchIdValue);
  }, [getCompletedMeetingDetails]);
  const onCompletedMeetingClick = (completedMeeting) => {
    const finalData = {
      mdDate: completedMeeting.mdDate,
      mdBatchId: completedMeeting.mdBatchId,
      mdBatchName: completedMeeting.batchName,
    };
    localStorage.setItem("meetingBatchInfo", JSON.stringify(finalData));
  };
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">
            {" "}
            Meetings of {batchIdValue.batchNameVal}
          </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 py-3">
              <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Meeting Date</th>
                        <th>Entered By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {completedmeetingdetails &&
                        completedmeetingdetails.map((completedMeeting, idx) => {
                          var ED = completedMeeting.mdDate.split(/\D/g);
                          var mdDate = [ED[2], ED[1], ED[0]].join("-");
                          return (
                            <tr key={idx}>
                              <td>
                                {" "}
                                <Link
                                  to="/memberwise-batchdetails-meeting"
                                  className="btnLink"
                                  onClick={() =>
                                    onCompletedMeetingClick(completedMeeting)
                                  }
                                >
                                  {mdDate}
                                </Link>
                              </td>
                              <td>{completedMeeting.mdEnteredByName}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

AddMemberwisebatchMeetingDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getCompletedMeetingDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getCompletedMeetingDetails,
})(AddMemberwisebatchMeetingDetails);
