import React from "react";

const LoanDetTab = ({
  membersMeetingData,
  NextBackBtn,
  setFormData,
  formData,
}) => {
  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const totalLoans = (MemberLoanArray) => {
    try {
      let intLoanBank = 0;
      let intLoanCash = 0;
      let othLoanBank = 0;
      let othLoanCash = 0;

      let totInternalPriBank = 0;
      let totInternalPriCash = 0;

      let totInternalIntBank = 0;
      let totInternalIntCash = 0;

      //Internal Loan

      //other Loan
      let totOtherIntBank = 0;
      let totOtherIntCash = 0;

      let totOtherPriBank = 0;
      let totOtherPriCash = 0;

      MemberLoanArray.map((ele) => {
        //internal
        totInternalPriBank += ele.internalLoan.reduce(
          (acu, cur) => (acu += Number(cur.loanPayablePrincipal.bankAmt)),
          0
        );
        totInternalPriCash += ele.internalLoan.reduce(
          (acu, cur) => (acu += Number(cur.loanPayablePrincipal.cash)),
          0
        );

        totInternalIntBank += ele.internalLoan.reduce(
          (acu, cur) => (acu += Number(cur.loanPayableInterest.bankAmt)),
          0
        );
        totInternalIntCash += ele.internalLoan.reduce(
          (acu, cur) => (acu += Number(cur.loanPayableInterest.cash)),
          0
        );

        //other
        totOtherPriBank += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherloanPayablePrincipal.bankAmt)),
          0
        );
        totOtherPriCash += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherloanPayablePrincipal.cash)),
          0
        );

        totOtherIntBank += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherloanPayableInterst.bankAmt)),
          0
        );
        totOtherIntCash += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherloanPayableInterst.cash)),
          0
        );

        intLoanBank += ele.loanTakenAmt.bankAmt;
        intLoanCash += ele.loanTakenAmt.cash;
        othLoanBank += ele.otherLoan.reduce(
          (acu, cur) => (acu += cur.otherLoanTakenAmt.bankAmt),
          0
        );
        othLoanCash += ele.otherLoan.reduce(
          (acu, cur) => (acu += cur.otherLoanTakenAmt.cash),
          0
        );
      });

      return {
        intLoanBank: intLoanBank,
        intLoanCash: intLoanCash,

        othLoanBank: othLoanBank,
        othLoanCash: othLoanCash,

        //internal
        totInternalPriBank: totInternalPriBank,
        totInternalPriCash: totInternalPriCash,

        totInternalIntBank: totInternalIntBank,
        totInternalIntCash: totInternalIntCash,

        totInternalPri: totInternalPriBank + totInternalPriCash,
        totInternalInt: totInternalIntBank + totInternalIntCash,

        //other
        totOtherIntBank: totOtherIntBank,
        totOtherIntCash: totOtherIntCash,

        totOtherPriCash: totOtherPriCash,
        totOtherPriBank: totOtherPriBank,

        totOtherInt: totOtherIntBank + totOtherIntCash,
        totOtherPri: totOtherPriBank + totOtherPriCash,
      };
      //   MemberLoanArray.
    } catch (er) {
      console.log(er);
    }
  };

  const Loandata = totalLoans(membersMeetingData);

  const loanDetNext = () => {
    let finalLoanData = {
      batchLoanAmt: {
        cash: Loandata.totInternalPriCash,
        bankAmt: Loandata.totInternalPriBank,
        bankName: "",
      },
      otherLoanSanctionedToMember: {
        cash: Loandata.totOtherPriCash,
        bankAmt: Loandata.totOtherPriBank,
        bankName: "",
      },
    };

    setFormData({ ...formData, ...finalLoanData });
    NextBackBtn(6);
  };

  return (
    <>
      <div className="row card-new py-3">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <h5>Loan Details</h5>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <label className="label-control h6">IL Sanctioned to Member :</label>
          <div className="row col-lg-12 col-md-12 col-sm-12">
            <div className="col-lg-4 col-md-12 col-sm-12">
              Principal:{Loandata.totInternalPri}
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              Interest:{Loandata.totInternalInt}
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12">
              {" "}
              Total: {Loandata.intLoanCash + Loandata.intLoanBank}
            </div>
          </div>

          <div className="row col-12 mt-2">
            <div className="col-lg-6">
              Cash
              <input
                type="number"
                name="batchLoanSanctionedAmtCash"
                value={Loandata.totInternalPriCash}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                disabled
                onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
            <div className="col-lg-6">
              Bank{" "}
              <input
                type="number"
                name="batchLoanSanctionedAmtBank"
                value={Loandata.totInternalPriBank}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                disabled
                onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <label className="label-control h6">OL Sanctioned To Member : </label>
          <div className="row col-lg-12 col-md-12 col-sm-12">
            <div className="col-lg-4 col-md-12 col-sm-12">
              Principal:{Loandata.totOtherPri}
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              Interest:{Loandata.totOtherInt}
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              Total: {Loandata.othLoanCash + Loandata.othLoanBank}
            </div>
          </div>
          <div className="row col-12 mt-2">
            <div className="col-lg-6">
              Cash
              <input
                type="number"
                name="batchOtherLoanAmtsCash"
                value={Loandata.totOtherPriCash}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                disabled
                onKeyDown={(e) => funcKeyDown(e)}
                required
              />
            </div>
            <div className="col-lg-6">
              Bank{" "}
              <input
                type="number"
                name="batchOtherLoanAmtsBank"
                value={Loandata.totOtherPriBank}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                disabled
                onKeyDown={(e) => funcKeyDown(e)}
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => loanDetNext()}
          type="button"
        >
          Next
        </button>
        <button
          type="button"
          className="btn sub_form btn_continue Save float-right"
          onClick={() => NextBackBtn(4)}
        >
          Previous
        </button>
      </div>
    </>
  );
};

export default LoanDetTab;
