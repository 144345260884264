import React from "react";

const SummaryMemTab = ({
  memSummaryData,
  allPermission,
  memberClosingAmtAll,
  setPrevBankBalance,
}) => {
  const sumOf = (value) => {
    try {
      return value.cash + value.bankAmt;
    } catch (er) {
      return 0;
    }
  };

  const totalIncome = (memSummaryData) => {
    let {
      batchSavingAmt = 0,
      batchSubAmt = 0,
      batchOtherContribution = 0,
      batchInterestPaid = 0,
      bankInterest = 0,
      subsidyReceived = 0,
      batchMembershipFees = 0,
      batchOtherIncome = 0,
      deactiveMemberSaving = 0,
      seedMoney = 0,
      batchSecurityDepositMemToShg = 0,
      batchLoanPaid = 0,
      otherLoanSanctionedToShg = 0,
      loanRepaidByOtherSHG = 0,
    } = memSummaryData;

    return (
      batchSavingAmt +
      batchSubAmt +
      batchOtherContribution +
      batchInterestPaid +
      bankInterest +
      subsidyReceived +
      batchMembershipFees +
      batchOtherIncome +
      deactiveMemberSaving +
      seedMoney +
      batchSecurityDepositMemToShg +
      batchLoanPaid +
      otherLoanSanctionedToShg +
      loanRepaidByOtherSHG
    );
  };

  const totalExpense = (memSummaryData) => {
    let {
      travellingExpenses = 0,
      stationaryExpenses = 0,
      paidToMahasangha = 0,
      bankCommission = 0,
      serviceCharges = 0,
      batchDividendDistributed = 0,
      otherExpenses = 0,
      batchSavingWithdrawals = 0,
      batchSecurityDeposit = 0,
      donationToOutsider = 0,
      subsidyDistributed = 0,
      otherLoanPaidToDonor = 0,
      batchOtherLoanInterestDonor = 0,
      batchLoanAmt = 0,
      batchOtherLoanAmt = 0,
      loanGivenToOtherSHG = 0,
    } = memSummaryData;

    return (
      travellingExpenses +
      stationaryExpenses +
      paidToMahasangha +
      bankCommission +
      serviceCharges +
      batchDividendDistributed +
      otherExpenses +
      batchSavingWithdrawals +
      batchSecurityDeposit +
      donationToOutsider +
      subsidyDistributed +
      otherLoanPaidToDonor +
      batchOtherLoanInterestDonor +
      batchLoanAmt +
      batchOtherLoanAmt +
      loanGivenToOtherSHG
    );
  };

  const bankBalance = (memSummaryData) => {
    let {
      batchBankDeposit,
      batchBankWithdrawals,
      bankInterest,
      bankCommission,
      subsidyReceivedBank,
      otherLoanSanctionedToShgBank,
    } = memSummaryData;
    return Number(
      Number(batchBankDeposit) -
        Number(batchBankWithdrawals) +
        Number(bankInterest) -
        Number(bankCommission) +
        Number(subsidyReceivedBank) +
        Number(otherLoanSanctionedToShgBank)
    ).toFixed(2);
  };

  const groupByBankId = (bankTransactions) => {
    let data = bankTransactions.flat();
    return Object.values(
      data.reduce((result, current) => {
        const key = current.batchBankNameId;
        const balance =
          Number(current.indBatchBankDeposit) +
          Number(current.indBankInterest) -
          Number(current.indBatchBankWithdrawals) -
          Number(current.indBankCommission);

        // const bankWithdrawal=current.indBatchBankWithdrawals;

        result[key] = result[key] || {
          bankName: current.batchBankName,
          bankWithdrawal: 0,
          totalBalance: 0,
        };
        result[key].bankWithdrawal += Number(current.indBatchBankWithdrawals);
        result[key].totalBalance += Number(balance);

        return result;
      }, {})
    );
  };

  const bankArray = groupByBankId(memSummaryData.bankTransactions || []);

  setPrevBankBalance(
    bankArray.reduce((acu, cur) => (acu += Number(cur.totalBalance)), 0)
  );

  return (
    <>
      {memSummaryData ? (
        <>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <h6>Total Savings : {memSummaryData.batchSavingAmt}</h6>
            <h6>Total Subscription : {memSummaryData.batchSubAmt}</h6>
            <h6>
              Total Other Contribution :{memSummaryData.batchOtherContribution}
            </h6>
            <h6>
              Total IL Interest Paid to SHG :
              {memSummaryData.batchInterestPaid || 0}
            </h6>
            <h6>Total Bank Interest : {memSummaryData.bankInterest || 0}</h6>
            <h6>Subsidy Received : {memSummaryData.subsidyReceived || 0}</h6>
            <h6>
              Total Membership Fees : {memSummaryData.batchMembershipFees || 0}
            </h6>
            <h6>Total Other Income : {memSummaryData.batchOtherIncome || 0}</h6>
            <h6>
              Total Deactive Member charity Amount :
              {memSummaryData.deactiveMemberSaving || 0}
            </h6>
            <h6>Total Seed Money : {memSummaryData.seedMoney || 0}</h6>
            <h6>
              Total Security Deposit Member To Shg :
              {memSummaryData.batchSecurityDepositMemToShg}
            </h6>
            <h6>
              Total IL Repayment to SHG : {memSummaryData.batchLoanPaid || 0}
            </h6>
            <h6>
              Total Other Loan Sanctioned To SHG :
              {memSummaryData.otherLoanSanctionedToMember || 0}
            </h6>
            {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
              <h6>
                Total Loan Repaid By Other SHG :
                {memSummaryData.loanRepaidByOtherSHG || 0}
              </h6>
            ) : (
              <></>
            )}
            <h6>{/* <b>Total Income : {totalIncome(memSummaryData)}</b> */}</h6>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <h6>
              Total Travelling Expenses : {memSummaryData.travellingExpenses}
            </h6>
            <h6>
              Total Stationary Expenses : {memSummaryData.stationaryExpenses}
            </h6>
            <h6>Total Paid To Union : {memSummaryData.paidToMahasangha}</h6>
            <h6>Total Bank Commission : {memSummaryData.bankCommission}</h6>
            <h6>Total Service Charges : {memSummaryData.serviceCharges}</h6>
            <h6>
              Total Dividend Distributed :
              {memSummaryData.batchDividendDistributed}
            </h6>
            <h6>Total Other Expenses : {memSummaryData.otherExpenses}</h6>
            <h6>
              Total Saving Withdrawals : {memSummaryData.batchSavingWithdrawals}
            </h6>
            <h6>
              Total Security Deposit : {memSummaryData.batchSecurityDeposit}
            </h6>
            <h6>
              Total Donation To Outsider : {memSummaryData.donationToOutsider}
            </h6>
            <h6>
              Total Subsidy Distributed : {memSummaryData.subsidyDistributed}
            </h6>
            <h6>
              Total OL Principal To Donor :{" "}
              {memSummaryData.otherLoanPaidToDonor}
            </h6>
            <h6>
              Total OL Interest To Donor :{" "}
              {memSummaryData.batchOtherLoanInterestDonor || 0}
            </h6>
            <h6>
              Total Internal Loan Amount Given : {memSummaryData.batchLoanAmt}
            </h6>
            <h6>
              Total Other Loan Amount Given :
              {/* {memSummaryData.batchOtherLoanAmt} */}
              {memSummaryData.otherLoanSanctionedToMember}
            </h6>
            {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
              <h6>
                Total Loan Given To Other SHG:{" "}
                {memSummaryData.loanGivenToOtherSHG}
              </h6>
            ) : (
              <></>
            )}
            <h6>
              {/* <b>Total Expense : {totalExpense(memSummaryData)}</b> */}
            </h6>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <h6>
              <b> Savings </b>
            </h6>
            <h6>
              {" "}
              After Deactivating Member:&nbsp;
              {Number(memSummaryData.batchSavingAmt) -
                (memberClosingAmtAll
                  ? memberClosingAmtAll.memberClossingAmt
                  : 0)}
            </h6>
            <h6>
              <b>Cash In Hand :{memSummaryData.cashInHand || 0}</b>
            </h6>
            <h6>
              <b>Bank Balance</b>
            </h6>
            {bankArray.map((ele, index) => {
              return (
                <div key={index}>
                  <span>{ele.bankName}:</span>
                  {ele.totalBalance}
                </div>
              );
            })}

            {/* <h6 class="my-1">
              <b> SHG Balance :0</b>
            </h6> */}
            <h6>
              <b>Bank Withdrawals </b>
            </h6>

            <h6>
              {bankArray.map((ele, index) => {
                return (
                  <div key={index}>
                    <span>{ele.bankName}:</span>
                    {ele.bankWithdrawal}
                  </div>
                );
              })}
            </h6>

            <h6>
              <b> Outstanding Amount </b>(Principal + Interest)
            </h6>
            <h6>
              Internal Loan Amount :{" "}
              {Number(memSummaryData.OutstandingInternalLoan).toFixed(2)}
            </h6>
            <h6>
              Other Loan Amount :{" "}
              {Number(memSummaryData.OutstandingOtherLoan).toFixed(2)}
            </h6>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            {/* <h6>
              <b>
                Total Savings after Deactivating Member:
                {Number(memSummaryData.batchSavingAmt) -
                  (memberClosingAmtAll
                    ? memberClosingAmtAll.memberClossingAmt
                    : 0)}
              </b>
            </h6> */}
            {/* <h6>
              <b>Cash In Hand : {memSummaryData.cashInHandVal || 0}</b>
            </h6> */}
            {/* <h6><b>Bank Balance : {bankBalance(memSummaryData)}</b></h6> */}
            {/* <h6>
              <b>
                Bank Withdrawals : {memSummaryData.batchBankWithdrawalsVal || 0}
              </b>
            </h6> */}
            {/* <h6>
              <b>
                Total Outstanding Internal Loan Amount :
                {Number(memSummaryData.OutstandingBatchLoanAmt).toFixed(2)}
              </b>
            </h6> */}
            {/* <h6>
              <b>
                Total Outstanding Other Loan Amount :{" "}
                {Number(memSummaryData.OutstandingBatchOtherLoanAmt).toFixed(2)}
              </b>
            </h6> */}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SummaryMemTab;
