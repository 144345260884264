import React, { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import {
  getAllFamilyMembers,
  getAllSubOccupation,
  getOcccupationsDropdown,
  getSubOccupation,
} from "../../actions/shg";
import Spinner from "../layout/Spinner";

const OccupationReports = ({
  auth: { isAuthenticated, user, users },
  shg: {
    familyMembers,
    activeOccupation,
    occupationMember,
    getAllSubOccupation,
  },
  getAllFamilyMembers,
  getOcccupationsDropdown,
  getSubOccupation,
}) => {
  useEffect(() => {
    getAllFamilyMembers();
  }, [getAllFamilyMembers]);
  useEffect(() => {
    getOcccupationsDropdown();
  }, [getOcccupationsDropdown]);
  // useEffect(() => {
  //   getAllSubOccupation();
  // }, [allSubOccupation]);
  const allOccupation = [];
  activeOccupation.map((occupation) =>
    allOccupation.push({
      oId: occupation._id,
      label: occupation.occcupationName,
      value: occupation.occcupationName,
    })
  );
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );
  const [Suboccupation, SetSubOccupation] = useState();

  const [occupation, setOccupationData] = useState();
  const onOccupationChange = (e) => {
    setOccupationData(e);
    // let temp = [];
    // allSubOccupation &&
    //   allSubOccupation.map((ele) => {
    //     if (e.oId === ele.occupationId) {
    //       temp.push({
    //         label: ele.subOccupationName,
    //         value: ele._id,
    //       });
    //     }
    //     SetSubOccupation(temp);
    //   });
    getAllFamilyMembers({ occupationId: e.oId });
    const changeData = {
      institutionIdVal: e.oId,
    };
    // getAllSubOccupation(changeData);
    getSubOccupation({
      occupationInfo: e.oId,
    });
    setFamilyMemb("");
    setMemb("");
    setSubOccupation("");
  };

  const [allmemberSubOccupation, setAllmemberSubOccupation] = useState();

  useEffect(() => {
    setAllmemberSubOccupation(
      getAllSubOccupation.map((subOccupation) => {
        return {
          subOccupationId: subOccupation._id,
          label: subOccupation.subOccupationName,
          value: subOccupation.subOccupationName,
        };
      })
    );
  }, [getAllSubOccupation]);

  const allSubOccupation = [];
  getAllSubOccupation.map((subOccupation) =>
    allSubOccupation.push({
      subOccupationId: subOccupation._id,
      label: subOccupation.subOccupationName,
      value: subOccupation.subOccupationName,
    })
  );

  const [membersubOccupation, setSubOccupation] = useState();
  const [familyMemb, setFamilyMemb] = useState("");
  const [Memb, setMemb] = useState("");

  const onSubOccupationChange = (e) => {
    setSubOccupation(e);
    setFamilyMemb(
      familyMembers.filter((famMem) => {
        if (famMem.familyMembers.familyMemberSubOccupation === e.value) {
          return famMem;
        }
      })
    );

    setMemb(
      occupationMember.filter((famMem) => {
        if (famMem.memberSubOccupation === e.value) {
          return famMem;
        }
      })
    );
    // );
    // familyMembers = familyMemb;
    // familyMembers
    // getAllFamilyMembers({
    //   subOccupationId: e.subOccupationId,
    //   occupationId: occupation.oId,
    // });
    // setSubOccupationID(e.subOccupationId ? e.subOccupationId : null);
    // setSubOccupationName(e.value ? e.value : "");
  };

  const onClickReset = () => {
    setOccupationData("");
    getAllFamilyMembers();
    setFamilyMemb("");
    setMemb("");
    setSubOccupation("");
    setAllmemberSubOccupation([]);
  };
  //Excel Export
  const csvData = [
    [
      "SHG Member Name",
      "Family Member Name",
      "Phone No",
      "Batch",
      "SHG Member Occupation",
      ...(allPermission && allPermission.includes("Sub Occupation")
        ? ["SHG Member SubOccupation"]
        : []),
    ],
  ];
  //Iterate over SHG Members
  if (Memb === "") {
    occupationMember.forEach((member) => {
      csvData.push([
        member.memberName,
        "-",
        member.memberPhone,
        member.batchName,
        member.memberOcupation,
        ...(allPermission && allPermission.includes("Sub Occupation")
          ? [member.memberSubOccupation || ""]
          : []),
      ]);
    });
  } else {
    Memb.forEach((member) => {
      csvData.push([
        member.memberName,
        "-",
        member.memberPhone,
        member.batchName,
        member.memberOcupation,
        ...(allPermission && allPermission.includes("Sub Occupation")
          ? [member.memberSubOccupation || ""]
          : []),
      ]);
    });
  }

  //Iterate over family members
  if (familyMemb === "") {
    familyMembers.forEach((familyMember) => {
      let member = familyMember.familyMembers;
      csvData.push([
        familyMember.memberName,
        member.familyMemberName,
        familyMember.familyMemberPhone,
        familyMember.batchName,
        member.familyMemberOcupation,
        ...(allPermission && allPermission.includes("Sub Occupation")
          ? [familyMember.familyMemberSubOccupation || ""]
          : []),
      ]);
    });
  } else {
    familyMemb.forEach((familyMember) => {
      let member = familyMember.familyMembers;
      csvData.push([
        familyMember.memberName,
        member.familyMemberName,
        familyMember.familyMemberPhone,
        familyMember.batchName,
        member.familyMemberOcupation,
        ...(allPermission && allPermission.includes("Sub Occupation")
          ? [familyMember.familyMemberSubOccupation || ""]
          : []),
      ]);
    });
  }

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color">Occupation Report</h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-11 col-11 no_padding">
            <div className="col-lg-3 col-md-3 col-sm-3 col-11 mt-1">
              <Select
                name="occupationName"
                options={allOccupation}
                isSearchable={true}
                value={occupation}
                placeholder="Select Occupation"
                onChange={(e) => onOccupationChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-11 mt-1">
              {allPermission && allPermission.includes("Sub Occupation") && (
                <Select
                  name="membersubOccupation"
                  // options={allSubOccupation}
                  options={allmemberSubOccupation}
                  isSearchable={true}
                  value={membersubOccupation}
                  placeholder="Select Sub Occupation"
                  onChange={(e) => onSubOccupationChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              )}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-11 py-3 "></div>

            <div className="col-lg-3 col-md-3 col-sm-4 col-11 py-3 d-flex justify-content-center align-items center">
              <CSVLink data={csvData}>
                <img
                  className="img_icon_size log float-right ml-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="Excel-Export"
                  title="Excel-Export"
                />
              </CSVLink>
              <img
                className="img_icon_size log float-right mx-2"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
              <Link to="/all-reports">
                <img
                  className="img_icon_size log ml-3 float-right"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>SHG Member Name</th>
                        <th>Family Member Name</th>
                        <th>Phone No.</th>
                        <th>Batch</th>
                        <th>SHG Member Occupation</th>

                        {allPermission &&
                          allPermission.includes("Sub Occupation") && (
                            <th>SHG Member SubOccupation</th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {Memb === "" ? (
                        <>
                          {occupationMember &&
                            occupationMember.map((occupationMember, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>{occupationMember.memberName}</td>
                                  <td>-</td>
                                  <td>{occupationMember.memberPhone}</td>
                                  <td>{occupationMember.batchName}</td>
                                  <td>{occupationMember.memberOcupation}</td>
                                  {allPermission &&
                                    allPermission.includes(
                                      "Sub Occupation"
                                    ) && (
                                      <td>
                                        {occupationMember.memberSubOccupation ||
                                          ""}
                                      </td>
                                    )}
                                </tr>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {Memb &&
                            Memb.map((occupationMember, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>{occupationMember.memberName}</td>
                                  <td>-</td>
                                  <td>{occupationMember.memberPhone}</td>
                                  <td>{occupationMember.batchName}</td>
                                  <td>{occupationMember.memberOcupation}</td>
                                  {allPermission &&
                                    allPermission.includes(
                                      "Sub Occupation"
                                    ) && (
                                      <td>
                                        {occupationMember.memberSubOccupation ||
                                          ""}
                                      </td>
                                    )}
                                </tr>
                              );
                            })}
                        </>
                      )}
                      {familyMemb === "" ? (
                        <>
                          {familyMembers &&
                            familyMembers.map((familyMembers, idx) => {
                              let member = familyMembers.familyMembers;
                              return (
                                <tr key={idx}>
                                  <td>{familyMembers.memberName}</td>
                                  <td>{member.familyMemberName}</td>
                                  <td>{familyMembers.familyMemberPhone}</td>
                                  <td>{familyMembers.batchName}</td>
                                  <td>{member.familyMemberOcupation}</td>

                                  {allPermission &&
                                    allPermission.includes(
                                      "Sub Occupation"
                                    ) && (
                                      <td>
                                        {occupationMember.familyMemberSubOccupation ||
                                          ""}
                                      </td>
                                    )}
                                </tr>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {familyMemb &&
                            familyMemb.map((familyMembers, idx) => {
                              let member = familyMembers.familyMembers;
                              return (
                                <tr key={idx}>
                                  <td>{familyMembers.memberName}</td>
                                  <td>{member.familyMemberName}</td>
                                  <td>{familyMembers.familyMemberPhone}</td>
                                  <td>{familyMembers.batchName}</td>
                                  <td>{member.familyMemberOcupation}</td>
                                  {allPermission &&
                                    allPermission.includes(
                                      "Sub Occupation"
                                    ) && (
                                      <td>
                                        {occupationMember.familyMemberSubOccupation ||
                                          ""}
                                      </td>
                                    )}
                                </tr>
                              );
                            })}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
            <div className="col-lg-11 col-md-6 col-sm-11 col-11 align_right">
              <label className="mr-3">
                {/* <b>Count : {familyMembers.length + occupationMember.length}</b> */}
              </label>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

OccupationReports.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getAllFamilyMembers,
  getAllSubOccupation,
  getOcccupationsDropdown,
  getSubOccupation,
})(OccupationReports);
