import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { AddParish, editParishDetails } from "../../actions/area";
import Spinner from "../layout/Spinner";

const EditParishDetails = ({
  auth: { isAuthenticated, user, users, loading },
  area: { varadosData },
  parishes,
  AddParish,
  editParishDetails,
}) => {
  //formData
  const [formData, setFormData] = useState({
    isSubmitted: false,
    parishName: parishes && parishes.parishName ? parishes.parishName : "",
    parishCode: parishes && parishes.parishCode ? parishes.parishCode : "",
    varadoId: parishes && parishes.vId ? parishes.vId : "",
  });
  const { parishName, parishCode } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  let selVaradosData = JSON.parse(localStorage.getItem("selVaradosData"));

  const allvarado = [];
  selVaradosData &&
    selVaradosData.map((varado) =>
      allvarado.push({
        varadoId: varado._id,
        label: varado.varadoName,
        value: varado.varadoName,
      })
    );

  const [varado, setVaradoData] = useState(
    allvarado && parishes
      ? allvarado && allvarado.filter((x) => x.varadoId === parishes.vId)[0]
      : ""
  );

  const [varadoId, setVaradoID] = useState(parishes.vId);

  const onVaradoChange = (e) => {
    setVaradoData(e);
    var varadoId = e.varadoId;
    setVaradoID(varadoId);
  };

  const onUpdate = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: parishes ? parishes._id : "",
      parishName: parishName,
      parishCode: parishCode,
      vId: varadoId,
      parishEnteredById: user._id,
      institutionId: user.institutionId,
    };
    editParishDetails(finalData);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onUpdate(e)}>
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control"> Parish Name* :</label>
              <input
                type="text"
                name="parishName"
                value={parishName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control">Parish Code* :</label>
              <input
                type="text"
                name="parishCode"
                value={parishCode}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
              <label className="label-control">Varado* :</label>
              <Select
                name="varadoName"
                options={allvarado}
                isSearchable={true}
                value={varado}
                placeholder="Select Varado"
                onChange={(e) => onVaradoChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
          </div>

          <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditParishDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  AddParish: PropTypes.func.isRequired,
  editParishDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
});

export default connect(mapStateToProps, {
  AddParish,
  editParishDetails,
})(EditParishDetails);
