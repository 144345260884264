import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  changeBatchCodeDetails,
  checkBatchCode,
  getMeetingSummary,
  checkAvailableMeetingDate,
  changeBatchMeetingDetails,
} from "../../actions/shg";
const ChangeMeetingDate = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { shgcodeCheck, meetingCheckResponse },
  batchesdata,
  checkAvailableMeetingDate,
  changeBatchCodeDetails,
  checkBatchCode,
  onChangeModalChange,
  changeBatchMeetingDetails,
  previousDate,
  aheadDate,
}) => {
  const [formData, setFormData] = useState({
    batchId: "",
    oldMeetingDate: "",
    selectedMeetingdate: "",
    mdhReason: "",
    mdhEnteredById: "",
    mdhEnteredByName: "",
    mdhEnteredDateTime: "",
    isSubmitted: false,
  });

  const {
    batchId,
    oldMeetingDate,
    selectedMeetingdate,
    mdhReason,
    mdhEnteredById,
    mdhEnteredByName,
    mdhEnteredDateTime,
    isSubmitted,
  } = formData;

  const [selectedDate, setSelectedDate] = useState("");
  const onDateChange = (e) => {
    setSelectedDate(e.target.value);
    if (e.target.value !== "") {
      const finalData = {
        mdDate: e.target.value,
        mdBatchId: batchesdata ? batchesdata.mdBatchId : "",
      };
      checkAvailableMeetingDate(finalData);
    }
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const oncheck = () => {
    if (selectedDate !== "") {
      const finalData = {
        mdDate: selectedDate,
        mdBatchId: batchesdata ? batchesdata.mdBatchId : "",
      };
      checkAvailableMeetingDate(finalData);
    }
  };

  var dateVal = new Date(selectedDate);
  const onSubmit = (e) => {
    e.preventDefault();
    // if (checkErrors()) {
    const finalData = {
      mdhBatchId: batchesdata ? batchesdata.mdBatchId : "",
      mdhDate: batchesdata && batchesdata.mdDate,
      newMeetingDate: selectedDate,
      mdhReason: mdhReason,
      mdhEnteredById: user && user._id,
      mdhEnteredByName: user && user.userName,
      mdhEnteredDateTime: new Date().toLocaleString("en-GB"),
      institutionId: user && user.institutionId,
    };
    changeBatchMeetingDetails(finalData);
    onChangeModalChange(true);
    // }
  };

  var ED = batchesdata && batchesdata.mdDate.split(/\D/g);
  var mdDate = [ED[2], ED[1], ED[0]].join("-");

  return !isAuthenticated || !user || !users ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div className="col-lg-12 col-md-9 col-sm-9 col-12 ">
        <form className="row" onSubmit={(e) => onSubmit(e)}>
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12 ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
              <label>Selected Meeting Date* : {mdDate}</label>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
              <label>New Meeting Date* :</label>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 ">
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="selectedDate"
                min={previousDate.mdDate}
                max={aheadDate.mdDate}
                value={selectedDate}
                onChange={(e) => onDateChange(e)}
                style={{
                  width: "100%",
                }}
                required
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              {/* <button
                className="btn sub_form "
                onClick={(e) => oncheck(e)}
                type="button"
              >
                Check
              </button> */}
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {meetingCheckResponse &&
              meetingCheckResponse === "Meeting Date Available" ? (
                <label style={{ color: "green" }}>{meetingCheckResponse}</label>
              ) : (
                <label className="colorRed">{meetingCheckResponse}</label>
              )}
            </div>

            {meetingCheckResponse === "Meeting Date Available" && (
              <div
                className="col-lg-12 col-md-9 col-sm-9 col-12 Savebutton"
                size="lg"
              >
                {loading ? (
                  <button
                    className="btn sub_form btn_continue blackbrd Save float-right"
                    disabled
                  >
                    Loading...
                  </button>
                ) : (
                  <>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 no_padding">
                      <label className="label-control">
                        Date changing reason* :
                      </label>
                      <textarea
                        name="mdhReason"
                        value={mdhReason}
                        id="mdhReason"
                        className="textarea form-control"
                        rows="4"
                        placeholder="Reason"
                        onChange={(e) => onInputChange(e)}
                        style={{ width: "100%" }}
                        required
                      ></textarea>
                    </div>
                    <input
                      type="submit"
                      name="Save"
                      value="Submit"
                      className="btn sub_form btn_continue Save float-right"
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

ChangeMeetingDate.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  changeBatchCodeDetails: PropTypes.func.isRequired,
  checkAvailableMeetingDate: PropTypes.func.isRequired,
  changeBatchMeetingDetails: PropTypes.func.isRequired,
  checkBatchCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  changeBatchCodeDetails,
  checkBatchCode,
  checkAvailableMeetingDate,
  changeBatchMeetingDetails,
})(ChangeMeetingDate);
