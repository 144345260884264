import {
  SAVED,
  ALL_PROGRAMS_DETAILS,
  ALL_ACTIVE_PROGRAMS_DETAILS,
  ALL_SKILL_REPORT_DETAILS,
  ALL_SKILL_DETAILS,
  SHG_MEMBERS_LIST,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  users: [],
  errorResponse: "",
  successResponse: "",
  savedMessage: "",
  programData: [],
  activeprogram: [],
  skillReportData: [],
  allskillData: [],
  shgMembersList: [],
};

const skill = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SAVED:
      return {
        ...state,
        savedMessage: payload,
        loading: false,
      };

    case ALL_PROGRAMS_DETAILS:
      return {
        ...state,
        programData: payload,
      };
    case ALL_SKILL_REPORT_DETAILS:
      return {
        ...state,
        skillReportData: payload,
      };

    case ALL_ACTIVE_PROGRAMS_DETAILS:
      return {
        ...state,
        activeprogram: payload,
      };
    case ALL_SKILL_DETAILS:
      return {
        ...state,
        allskillData: payload,
      };
    case SHG_MEMBERS_LIST:
      return {
        ...state,
        shgMembersList: payload,
      };

    default:
      return state;
  }
};

export default skill;
