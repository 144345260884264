import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getShgMembersForMembers, getBatchInfo } from "../../actions/shg";
import Spinner from "../layout/Spinner";
import EditMemberDetails from "./EditMemberDetails";
import DeactiveMember from "./DeactiveMember";
const MemberloginAllMembers = ({
  auth: { allUser, isAuthenticated, user, users },
  shg: { shgMembersForMembers, isSet },
  getBatchInfo,
  batchId,
  getShgMembersForMembers,
}) => {
  useEffect(() => {
    getShgMembersForMembers();
  }, [getShgMembersForMembers]);
  // const onUpdateModalChange = (e) => {
  //   if (e) {
  //     handleUpdateModalClose();
  //   }
  // };

  // const [showUpdateModal, setShowUpdateModal] = useState(false);
  // const handleUpdateModalClose = () => setShowUpdateModal(false);

  // const [userData, setUserData] = useState(null);
  // const onEdit = (membersdata, idx) => {
  //   setShowUpdateModal(true);
  //   setUserData(membersdata);
  // };
  // let selectedBatchIdData = JSON.parse(localStorage.getItem("selBatchIdData"));
  // let selBatchId = JSON.parse(localStorage.getItem("selBatchId"));
  // // const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  // const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  // const onDeactiveModalChange = (e) => {
  //   if (e) {
  //     handleDeactiveModalClose();
  //   }
  // };
  // const [userDatas, setUserDatas] = useState(null);
  // const onUpdate = (shgMembers, idx) => {
  //   setShowDeactiveModal(true);
  //   setUserDatas(shgMembers);
  // };
  // const [isSubmitted, setSubmitted] = useState(false);
  // const onClickHandler = () => {
  //   localStorage.removeItem("selBatchInfo");
  //   const finalData = {
  //     batchId: selBatchId.batchId,
  //   };
  //   getBatchInfo(finalData);
  //   setSubmitted(true);
  // };

  // if (isSet && isSubmitted) {
  //   return <Redirect to="/add-member-details" />;
  // }

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2>
            All Member Details of:&emsp;
            {shgMembersForMembers[0] && shgMembersForMembers[0].batchName}
          </h2>
          <hr />
        </div>

        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
            {/* <div className="col-lg-11 col-md-2 col-sm-2 col-2 py-3">
              <Link to="/all-batches">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <Link to="#">
                <img
                  className="img_icon_size log float-right"
                  onClick={() => onClickHandler()}
                  src={require("../../static/images/add-icon.png")}
                  alt="Add Member"
                  title="Add Member"
                />
              </Link>
            </div> */}
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className="body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Member Name</th>
                        <th>Member Code</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Aadhaar</th>
                        <th>Joining Date</th>
                        {/* <th>Op</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {shgMembersForMembers &&
                        shgMembersForMembers.map((shgMembers, idx) => {
                          var ED = shgMembers.memberJoiningDate.split(/\D/g);
                          var memberJoiningDate = [ED[2], ED[1], ED[0]].join(
                            "-"
                          );
                          return (
                            <tr key={idx}>
                              <td>{shgMembers.memberName}</td>
                              <td>{shgMembers.memberCode}</td>
                              <td>{shgMembers.memberPhone}</td>
                              <td>{shgMembers.memberAddress}</td>
                              <td>{shgMembers.memberAadharNo}</td>
                              <td>{memberJoiningDate}</td>
                              {/* <td>
                                {shgMembers.memberStatus &&
                                shgMembers.memberStatus !== "Deactive" ? (
                                  <img
                                    className="img_icon_size log"
                                    onClick={() => onUpdate(shgMembers, idx)}
                                    src={require("../../static/images/delete.png")}
                                    alt="Deactivate"
                                    title="Deactivate"
                                  />
                                ) : (
                                  <Fragment></Fragment>
                                )}
                                &nbsp;
                                <img
                                  className="img_icon_size log"
                                  onClick={() => onEdit(shgMembers, idx)}
                                  src={require("../../static/images/edit_icon.png")}
                                  alt="Edit"
                                  title="Edit"
                                />
                              </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </section>

        {/* <Modal
          show={showUpdateModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Edit Member Details </h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleUpdateModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditMemberDetails
              membersdata={userData}
              onUpdateModalChange={onUpdateModalChange}
            />
          </Modal.Body>
        </Modal> */}
        {/* <Modal
          show={showDeactiveModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Deactivate Member</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleDeactiveModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <DeactiveMember
              shgMembers={userDatas}
              onDeactiveModalChange={onDeactiveModalChange}
            />
          </Modal.Body>
        </Modal>  */}
      </div>
    </Fragment>
  );
};

MemberloginAllMembers.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getShgMembersForMembers: PropTypes.func.isRequired,
  getBatchInfo: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getShgMembersForMembers,
  getBatchInfo,
})(MemberloginAllMembers);
