import {
  SAVED,
  STATES,
  VARADOS,
  ALL_VARADOS,
  ALL_PARISH,
  ALL_STATE,
  ALL_DISTRICT,
  ALL_BLOCK,
  DISTRICT,
  PARISH,
  BLOCK_DISTRICT,
  GET_BLOCK,
  STATECHECKMSG,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  users: [],
  errorResponse: "",
  successResponse: "",
  savedMessage: "",
  statesData: [],
  varadosData: [],
  varadoData: [],
  parishData: [],
  stateData: [],
  villageData: [],
  tankNameData: [],
  districtData: [],
  activeDistrict: [],
  activeParish: [],
  allBlock: [],
  blockDistrict: [],
  getBlock: [],
  stateCheckResponse: [],
};

const area = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SAVED:
      return {
        ...state,
        savedMessage: payload,
        loading: false,
      };
    case STATES:
      return {
        ...state,
        statesData: payload,
      };
    case "VILLAGE":
      return {
        ...state,
        villageData: payload,
      };
    case "TANKNAME":
      return {
        ...state,
        tankNameData: payload,
      };
    case VARADOS:
      return {
        ...state,
        varadosData: payload,
      };
    case ALL_VARADOS:
      return {
        ...state,
        varadoData: payload,
      };
    case ALL_PARISH:
      return {
        ...state,
        parishData: payload,
      };
    case ALL_STATE:
      return {
        ...state,
        stateData: payload,
      };
    case ALL_DISTRICT:
      return {
        ...state,
        districtData: payload,
      };
    case DISTRICT:
      return {
        ...state,
        activeDistrict: payload,
      };
    case PARISH:
      return {
        ...state,
        activeParish: payload,
      };
    case ALL_BLOCK:
      return {
        ...state,
        allBlock: payload,
      };
    case BLOCK_DISTRICT:
      return {
        ...state,
        blockDistrict: payload,
      };
    case GET_BLOCK:
      return {
        ...state,
        getBlock: payload,
      };
    case STATECHECKMSG:
      return {
        ...state,
        stateCheckResponse: payload,
      };
    default:
      return state;
  }
};

export default area;
