import React from "react";
import { Link } from "react-router-dom";
const kannada = () => {
  return (
    <>
      <div
        className="container container_align "
        style={{ marginLeft: "12px" }}
      >
        <div className=" row col-lg-12 col-md-11 col-sm-12 col-12">
          <div className="col-lg-9 col-md-11 col-sm-12 col-12">
            <h3 className="heading_color1">
              SHG பயிற்சி வீடியோக்கள்
              <h6>(SHG Training Videos)</h6>
            </h3>
          </div>
          <div className="col-lg-2 col-md-11 col-sm-12 col-12 m-3 d-flex justify-content-end align-items-center">
            <Link to="/shgLangList">
              <img
                className="img_icon_size log float-right ml-3"
                src={require("../../static/images/back.png")}
                alt="Back"
                title="Back"
              />
            </Link>
          </div>
        </div>
        <hr style={{ width: "98%", marginLeft: "-0.5%" }} />
        <section className="row scstyle">
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-1">
              <center>
                <Link to="/TamilIntroSHG">
                  <div class="image-container">
                    <img
                      className="ReportImg log"
                      src={require("../../static/images/introTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="Introduction to SHG Application">
                    SHG அப்ளிகேஷன் <br></br>அறிமுகம்
                  </h6>
                </Link>
              </center>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-1">
              <center>
                <Link to="/TamilCreateBatch">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/createBatchTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Create a Batch?">
                    எப்படி குழு உருவாக்க <br></br>வேண்டும் ?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-1 ">
              <center>
                <Link to="/TamilAddMem">
                  <div class="image-container">
                    <img
                      className="ReportImg log"
                      src={require("../../static/images/addMemTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to add the Members?">
                    உறுப்பினர்களை சேர்க்க <br></br>எப்படி ?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-1 ">
              <center>
                <Link to="/TamilDeactiveMem">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/deactiveMemTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Deactivate a Member?">
                    ஒரு உறுப்பினரை செயலிழக்க செய்வது எப்படி?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
        </section>
        <section className="row mt-5 scstyle">
          {" "}
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-1 ">
              <center>
                <Link to="/TamilCreateUser">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/createUserTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Create Users?">
                    {" "}
                    உசேர்ஸ் உருவாக்குவது <br></br> எப்படி?{" "}
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-1 ">
              <center>
                <Link to="/TamilCreateArea">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/createAreaTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Create Area?">
                    எப்படி ஏரியா டீடெயில்ஸ் என்ட்ரி பண்ணனும்?{" "}
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-1 ">
              <center>
                <Link to="/TamilUpdateParMeet">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/upParMeetTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="	How to Update The Partial SHG Meeting?">
                    பார்ஷியல் மீட்டிங் போடுவது <br></br>எப்படி ?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-1">
              <center>
                <Link to="/TamilCreateMeet">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/createMeetTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Create a Meeting?">
                    எப்படி ஒபெநிங் மீட்டிங் என்ட்ரி பண்ண வேண்டும் ?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
        </section>
        <section className="row mt-5 scstyle">
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-5 ">
              <center>
                <Link to="/TamilCreateRegMeet">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/addRegMeetTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to add the Regular Meeting?">
                    ரெகுலர் மீட்டிங் போடுவது <br></br>எப்படி ?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-3 ">
              <center>
                <Link to="/TamilAddOLCat">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/olcatTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to add Other Loan Types & SHG Category?">
                    வெளி கடன் பெயர் சேர்ப்பது எப்படி ? மற்றும் SHG கேடெகோரி
                    சேர்ப்பது எப்படி ?
                  </h6>
                </Link>
              </center>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-3 ">
              <center>
                <Link to="/TamilUpdateSHGMeet">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/upShgMeetTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Update SHG Meeting After Partial Meetings Steps?">
                    எப்படி SHG மீட்டிங் போடணும், பார்ஷியல் மீட்டிங் ஸ்டெப்ஸ்
                    முடிந்த பிறகு. ?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-5">
              <center>
                <Link to="/TamilVerifySHGMeet">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/verifyRepTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Verify SHG Meetings?">
                    எப்படி SHG மீட்டிங் சரிபார்க்க <br></br>வேண்டும் ?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
        </section>

        <section className="row mt-5 scstyle">
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>
                <Link to="/TamilCheckMeetRep">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/ckMeetRepTam.jpg")}
                      alt="tamil"
                      title="tamil"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Check the Meeting in the Report Section?">
                    எப்படி மீட்டிங் செக் பண்றது ரிப்போர்ட் பட்டியல் யில் ?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default kannada;
