import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { getReligions, editCasteDetails } from "../../actions/religionSetting";
import Spinner from "../layout/Spinner";

const EditCasteDetails = ({
  auth: { isAuthenticated, user, users, loading },
  getReligions,
  editCasteDetails,
  castes,
  onUpdateModalChange,
}) => {
  useEffect(() => {
    getReligions();
  }, [getReligions]);

  //formData
  const [formData, setFormData] = useState({
    casteName: castes && castes.casteName ? castes.casteName : "",
    isSubmitted: false,
  });

  const { casteName } = formData;
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const allreligions = [];
  let selReligionData = JSON.parse(localStorage.getItem("selReligionData"));
  selReligionData &&
    selReligionData.map((religion) => {
      allreligions.push({
        religionId: religion._id,
        label: religion.religionName,
        value: religion.religionName,
      });
    });

  const [religion, setReligionData] = useState(
    castes
      ? allreligions.length !== 0
        ? allreligions &&
          allreligions.filter((x) => x.religionId === castes.religionId)[0]
        : ""
      : ""
  );
  if (selReligionData && selReligionData.length !== 0) {
    let hi = castes
      ? allreligions.length !== 0
        ? allreligions &&
          allreligions.filter((x) => x.religionId === castes.religionId)[0]
        : ""
      : "";
  }
  const [religionId, setReligionID] = useState(castes.religionId);

  const onReligionChange = (e) => {
    var religionId = "";
    setReligionData(e);
    religionId = e.religionId;
    setReligionID(religionId);
  };

  const onUpdate = (castes, idx) => {
    const finalData = {
      recordId: castes ? castes._id : "",
      casteName: casteName,
      religionId: religionId,
      casteEnteredById: user._id,
      casteEnteredByName: user.userName,
      institutionId: user.institutionId,
    };
    editCasteDetails(finalData);
    onUpdateModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <label className="label-control"> Caste Name* :</label>
            <input
              type="text"
              name="casteName"
              value={casteName}
              className="form-control"
              onChange={(e) => onInputChange(e)}
              required
            />
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <label className="label-control">Religion* :</label>

            <Select
              name="religionName"
              options={allreligions}
              isSearchable={true}
              value={religion}
              placeholder="Select Religion"
              onChange={(e) => onReligionChange(e)}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
        </div>

        <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
          {loading ? (
            <button
              className="btn sub_form btn_continue Save float-right"
              disabled
            >
              Loading...
            </button>
          ) : (
            <button
              variant="success"
              className="btn sub_form btn_continue Save float-right"
              onClick={() => onUpdate(castes)}
              style={
                casteName !== ""
                  ? { opacity: "1" }
                  : { opacity: "1", pointerEvents: "none" }
              }
            >
              Update
            </button>
          )}
        </div>
      </div>
    </Fragment>
  );
};

EditCasteDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  editCasteDetails: PropTypes.func.isRequired,
  getReligions: PropTypes.func.isRequired,
};

const mapReligionToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  religionSetting: state.religionSetting,
});

export default connect(mapReligionToProps, {
  getReligions,
  editCasteDetails,
})(EditCasteDetails);
