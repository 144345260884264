import {
  SAVED,
  RELIGIONS,
  ALL_RELIGION,
  ALL_CASTE,
  ALL_SUBCASTE,
  CASTE,
  SUBCASTE_CASTE,
  GET_SUBCASTE,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  users: [],
  errorResponse: "",
  successResponse: "",
  savedMessage: "",
  religionsData: [],
  religionData: [],
  casteData: [],
  activeCaste: [],
  allSubcaste: [],
  subcasteCaste: [],
  getSubcaste: [],
};

const religionSetting = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SAVED:
      return {
        ...state,
        savedMessage: payload,
        loading: false,
      };

    case RELIGIONS:
      return {
        ...state,
        religionsData: payload,
      };

    case ALL_RELIGION:
      return {
        ...state,
        religionData: payload,
      };
    case ALL_CASTE:
      return {
        ...state,
        casteData: payload,
      };
    case CASTE:
      return {
        ...state,
        activeCaste: payload,
      };

    case ALL_SUBCASTE:
      return {
        ...state,
        allSubcaste: payload,
      };
    case SUBCASTE_CASTE:
      return {
        ...state,
        subcasteCaste: payload,
      };
    case GET_SUBCASTE:
      return {
        ...state,
        getSubcaste: payload,
      };

    default:
      return state;
  }
};

export default religionSetting;
