import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Link, Redirect } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import { RadioGroup, RadioButton } from "react-radio-buttons";
import {
  BatchTransaction,
  meetingDates,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  checkAvailableDate,
  removeMessage,
  getMeetingSummary,
  AddLoanSanctionedDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  sendMettingFile,
} from "../../actions/shg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Spinner from "../layout/Spinner";
import FileBase64 from "react-file-base64";
import PdfPreview from "./PdfPreview";
import ImgPreview from "./ImgPreview";
import { PDFDocument, PDFImage, PDFCompressionQuality } from "pdf-lib";

const AddBatchMeetingTest = ({
  auth: { isAuthenticated, user, users, loading },
  shg: {
    loanreason,
    meetingResponse,
    summaryData,
    summaryDataOp,
    selBatchMem,
    memberClosingAmt,
    activeOtherLoanTypes,
    activeBankData,
  },
  BatchTransaction,
  meetingDates,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  checkAvailableDate,
  removeMessage,
  getMeetingSummary,
  AddLoanSanctionedDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  sendMettingFile,
}) => {
  let remainingAmt = 0,
    otherLoanRemainingAmt = 0,
    bankBalance = 0,
    cashInHandVal = 0,
    outstandingInternalLoanAmt = 0,
    outstandinOtherLoanAmt = 0;
  let lastMeetingData = JSON.parse(localStorage.getItem("lastMeeingDate"));
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  let selectedBatchData = JSON.parse(localStorage.getItem("selBatchData"));
  useEffect(() => {
    removeMessage();
  }, [removeMessage]);
  useEffect(() => {
    getParticularLoanReason();
  }, [getParticularLoanReason]);
  useEffect(() => {
    getMembersOfSelectedBatch(batchIdValue);
  }, [getMembersOfSelectedBatch]);
  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);
  useEffect(() => {
    getActiveBankData(batchIdValue);
  }, [getActiveBankData]);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const [formData, setFormData] = useState({
    batchOtherLoanAmts: "",
    externalNominee: "",
    bankloanOtherReason: "",
    batchSavingAmt: "",
    batchLoanPaid: "",
    batchInterestPaid: "",
    batchOtherLoanInterestShg: "",
    otherLoanPaidToShg: "",
    batchOtherLoanInterestDonor: "",
    subsidyDistributed: "",
    otherLoanPaidToDonor: "",
    bankInterest: "",
    batchSubAmt: "",
    stationaryExpenses: "",
    paidToMahasangha: "",
    otherExpenses: "",
    travellingExpenses: "",
    serviceCharges: "",
    bankCommission: "",
    batchOtherContribution: "",
    subsidyReceived: "",
    batchOtherIncome: "",
    batchMembershipFees: "",
    deactiveMemberSaving: "",
    seedMoney: "",
    batchSecurityDepositMemToShg: "",
    otherLoanSanctionedToShg: "",
    batchSavingWithdrawals: "",
    WithdrawalPerson: "",
    batchSecurityDeposit: "",
    donationToOutsider: "",
    batchDividendDistributed: "",
    batchBankDeposit: "",
    batchBankWithdrawals: "",
    otherPurpose: "",
    cashInHand: "",
    otherLoanSanctionedToShgType: "",
    // OLIPDType: "",
    // OLPDType: "",
    subsidyReceivedType: "",
    isBankDataSubmitted: false,
    isSubmitted: false,
    meetingNotes: "",
    meetingPhotoUpload1: "",
    meetingPhotoUpload2: "",
    meetingPhotoUpload3: "",
    meetingPhotoUpload4: "",
    meetingPhotoUpload5: "",
    meetingPhotoUpload6: "",
    meetingPhotoUpload7: "",
    meetingFileName: "",
    sdUnionToSHG: "",
    sdSHGToUnion: "",
    loanRepaidByOtherSHG: "",
    loanGivenToOtherSHG: "",
  });

  const {
    batchOtherLoanAmts,
    externalNominee,
    bankloanOtherReason,
    batchSavingAmt,
    batchLoanPaid,
    cashInHand,
    batchInterestPaid,
    batchOtherLoanInterestShg,
    otherLoanPaidToShg,
    batchOtherLoanInterestDonor,
    subsidyDistributed,
    otherLoanPaidToDonor,
    bankInterest,
    batchSubAmt,
    stationaryExpenses,
    paidToMahasangha,
    otherExpenses,
    travellingExpenses,
    serviceCharges,
    bankCommission,
    batchOtherContribution,
    subsidyReceived,
    batchOtherIncome,
    batchMembershipFees,
    deactiveMemberSaving,
    seedMoney,
    batchSecurityDepositMemToShg,
    otherLoanSanctionedToShg,
    batchSavingWithdrawals,
    WithdrawalPerson,
    batchSecurityDeposit,
    donationToOutsider,
    batchDividendDistributed,
    batchBankDeposit,
    batchBankWithdrawals,
    otherPurpose,
    otherLoanSanctionedToShgType,
    // OLIPDType,
    // OLPDType,
    subsidyReceivedType,
    isBankDataSubmitted,
    isSubmitted,
    meetingNotes,
    meetingFileName,
    meetingPhotoUpload1,
    meetingPhotoUpload2,
    meetingPhotoUpload3,
    meetingPhotoUpload4,
    meetingPhotoUpload5,
    meetingPhotoUpload6,
    meetingPhotoUpload7,
    sdUnionToSHG,
    sdSHGToUnion,
    loanRepaidByOtherSHG,
    loanGivenToOtherSHG,
  } = formData;
  const [fileType, setFileType] = useState("");

  const [addData, setFormDatas] = useState({
    batchLoanAmt: "",
    internalNominee: "",
  });
  const { batchLoanAmt, internalNominee } = addData;

  const [AddedDetails, AddDetails] = useState([]);
  const [selectionType, setSelectionType] = useState();
  const [selectionType1, setSelectionType1] = useState();
  //Required Validation Starts
  const [error, setError] = useState({
    MemberIdChecker: false,
    MemberIdErrorStyle: {},
    PurposeIdChecker: false,
    PurposeErrorStyle: {},
    OtherMemberIdChecker: false,
    OtherMemberIdErrorStyle: {},
    otherPurposeIdChecker: false,
    otherPurposeErrorStyle: {},
  });
  const {
    MemberIdChecker,
    MemberIdErrorStyle,
    PurposeIdChecker,
    PurposeErrorStyle,
    OtherMemberIdChecker,
    OtherMemberIdErrorStyle,
    otherPurposeIdChecker,
    otherPurposeErrorStyle,
  } = error;

  const [addBankData, setFormBankData] = useState({
    bankGridInterest: "",
    bankGridCommission: "",
    bankGridDeposit: "",
    gridcashInHand: "",
    bankGridwithdrawal: "",
  });
  const {
    bankGridInterest,
    bankGridCommission,
    bankGridDeposit,
    gridcashInHand,
    bankGridwithdrawal,
  } = addBankData;
  const [AddedBankDetails, AddBankDetails] = useState([]);
  const onAddBank = (e) => {
    e.preventDefault();
    if (checkError3()) {
      const bankList = AddedBankDetails.filter(
        (AddBankDetails) => AddBankDetails.batchBankNameId === batchBankNameId
      );
      e.preventDefault();
      if (bankList.length === 0) {
        // if (checkErrors()) {
        const addBankData = {
          batchBankNameId: batchBankNameId,
          batchBankName: batchBankName,
          indBankInterest: bankGridInterest,
          indBankCommission: bankGridCommission,
          indBatchBankDeposit: bankGridDeposit,
          indcashInHand: gridcashInHand,
          indBatchBankWithdrawals: bankGridwithdrawal,
        };
        setFormBankData({
          ...addBankData,
          bankGridInterest: "",
          bankGridCommission: "",
          bankGridDeposit: "",
          gridcashInHand: "",
          bankGridwithdrawal: "",
        });
        let temp = [];
        temp.push(...AddedBankDetails, addBankData);
        AddBankDetails(temp);
        getBank("");
        setFormData({
          ...formData,
          isBankDataSubmitted: true,
        });
      }
    }
  };
  let passwrdTooltip = {
    marginLeft: "-16em",
    position: "absolute",
    marginTop: "1.5em",
    pointerEvents: "none",
    zIndex: "999",
    width: "300px",
  };
  const onAddBankSingle = (e) => {
    e.preventDefault();
    const addBankData = {
      batchBankNameId:
        activeBank && activeBank[0] ? activeBank[0].bankId : null,
      batchBankName: activeBank && activeBank[0] ? activeBank[0].value : "",
      indBankInterest: bankGridInterest ? bankGridInterest : 0,
      indBankCommission: bankGridCommission ? bankGridCommission : 0,
      indBatchBankDeposit: bankGridDeposit ? bankGridDeposit : 0,
      indBatchBankWithdrawals: bankGridwithdrawal ? bankGridwithdrawal : 0,
    };
    setFormBankData({
      ...addBankData,
      bankGridInterest: "",
      bankGridCommission: "",
      bankGridDeposit: "",
      bankGridwithdrawal: "",
    });
    let temp = [];
    temp.push(addBankData);
    AddBankDetails(temp);
    setFormData({
      ...formData,
      isBankDataSubmitted: true,
    });
    return temp;
  };

  const onRemoveChangeBank = (bankGridName) => {
    const removeList = AddedBankDetails.filter(
      (AddBankDetails) => AddBankDetails.bankGridName !== bankGridName
    );
    AddBankDetails(removeList);
  };
  const [showHide, setShowHide] = useState({
    showotherPurposeSection: false,
    showotherLoanSanctionedToShgSection: false,
    showSubsidyReceivedSection: false,
    showOLIPDSection: false,
    showOLPDSection: false,
    showSDUSSection: false,
    showSDSUSection: false,
  });
  const {
    showotherPurposeSection,
    showotherLoanSanctionedToShgSection,
    showSubsidyReceivedSection,
    showOLIPDSection,
    showOLPDSection,
    showSDUSSection,
    showSDSUSection,
  } = showHide;

  const [showHideBank, setbankShowHide] = useState({
    showbankotherPurposeSection: false,
  });

  const { showbankotherPurposeSection } = showHideBank;

  const allpurpose = [];
  loanreason.map((purpose) =>
    allpurpose.push({
      loanReasonId: purpose._id,
      label: purpose.loanPurpose,
      value: purpose.loanPurpose,
    })
  );

  const [purpose, getloanPurposeData] = useState();
  const [loanReasonId, setloanPurposeID] = useState();
  const [loanPurpose, setloanPurposeName] = useState();

  const onPurposeChange = (e) => {
    setError({
      ...error,
      PurposeIdChecker: true,
      PurposeErrorStyle: { color: "#000" },
    });
    getloanPurposeData(e);
    setloanPurposeID(e.loanReasonId ? e.loanReasonId : null);
    setloanPurposeName(e.value ? e.value : "");

    if (e) {
      setFormData({
        ...formData,
        loanPurpose: e,
      });
    }

    if (e.value === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
    }
  };

  const onRadioSubsidyReceivedChange = (e) => {
    setSelectionType(e);
    setFormData({ ...formData, subsidyReceivedType: e });
    setShowHide({
      ...showHide,
      showSubsidyReceivedSection: true,
    });
    getBank("");
  };

  const onRadioOtherLoanSanctionedToShgChange = (e) => {
    setSelectionType1(e);
    setFormData({ ...formData, otherLoanSanctionedToShgType: e });
    setShowHide({
      ...showHide,
      showotherLoanSanctionedToShgSection: true,
    });
    getBank2("");
  };

  const [selectionOLIPDType, setSelectionOLIPDType] = useState();
  const onRadioOLIPDChange = (e) => {
    setSelectionOLIPDType(e);
    setFormData({ ...formData, OLIPDType: e });
    setShowHide({
      ...showHide,
      showOLIPDSection: true,
    });
    getBankOLIPD("");
  };

  const [selectionOLPDType, setSelectionOLPDType] = useState();
  const onRadioOLPDChange = (e) => {
    setSelectionOLPDType(e);
    setFormData({ ...formData, OLPDType: e });
    setShowHide({
      ...showHide,
      showOLPDSection: true,
    });
    getBankOLPD("");
  };

  const [selectionSDUSType, setSelectionSDUSType] = useState();
  const onRadioSDUSChange = (e) => {
    setSelectionSDUSType(e);
    setFormData({ ...formData, SDUSType: e });
    setShowHide({
      ...showHide,
      showSDUSSection: true,
    });
    getBankSDUS("");
  };

  const [selectionSDSUType, setSelectionSDSUType] = useState();
  const onRadioSDSUChange = (e) => {
    setSelectionSDSUType(e);
    setFormData({ ...formData, SDUSType: e });
    setShowHide({
      ...showHide,
      showSDSUSection: true,
    });
    getBankSDSU("");
  };

  const allbankpurpose = [];
  loanreason.map((bankpurpose) =>
    allbankpurpose.push({
      bankloanpuroseId: bankpurpose._id,
      label: bankpurpose.loanPurpose,
      value: bankpurpose.loanPurpose,
    })
  );

  const [otherloanpurpose, getbankpurposeData] = useState();
  const [bankloanpuroseId, setbankloanpuroseId] = useState();
  const [bankloanPurpose, setbankloanPurpose] = useState();

  const onBankLoanPurposeChange = (e) => {
    setError({
      ...error,
      otherPurposeIdChecker: true,
      otherPurposeErrorStyle: { color: "#000" },
    });
    getbankpurposeData(e);
    setbankloanpuroseId(e.bankloanpuroseId ? e.bankloanpuroseId : null);
    setbankloanPurpose(e.value ? e.value : "");

    if (e) {
      setFormData({
        ...formData,
        bankloanPurpose: e,
      });
    }

    if (e.value === "Other") {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: true,
      });
    } else {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: false,
      });
    }
  };

  const checkErrors = () => {
    if (!MemberIdChecker) {
      setError({
        ...error,
        MemberIdErrorStyle: { color: "#F00" },
      });
      return false;
    }
    if (!PurposeIdChecker) {
      setError({
        ...error,
        PurposeErrorStyle: { color: "#F00" },
      });
      return false;
    }
    return true;
  };

  const othercheckErrors = () => {
    if (!OtherMemberIdChecker) {
      setError({
        ...error,
        OtherMemberIdErrorStyle: { color: "#F00" },
      });
      return false;
    }
    if (!otherPurposeIdChecker) {
      setError({
        ...error,
        otherPurposeErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };

  const onAdd = (e) => {
    const loanList = AddedDetails.filter(
      (AddDetails) => AddDetails.loanSanctionedMemberId === memberId
    );
    e.preventDefault();
    if (loanList.length === 0) {
      if (checkErrors()) {
        const addData = {
          loanSanctionedMemberId: memberId,
          loanSanctionedMember: memberName,
          batchLoanAmt: batchLoanAmt,
          internalNominee: internalNominee,
          loanReasonId: loanReasonId,
          loanSanctionedPurpose: loanPurpose,
          loanSanctionedotherPurpose: otherPurpose,
        };
        setFormDatas({
          ...addData,
          batchLoanAmt: "",
          internalNominee: "",
          loanReasonId: "",
          loanSanctionedPurpose: "",
          loanSanctionedotherPurpose: "",
          otherPurpose: "",
        });
        setFormData({ ...formData, otherPurpose: "" });
        let temp = [];
        temp.push(...AddedDetails, addData);
        AddDetails(temp);
        getloanPurposeData("");
        getmemberData("");
      }
    }
  };

  const onRemoveChange = (loanSanctionedMember) => {
    const removeList = AddedDetails.filter(
      (AddDetails) => AddDetails.loanSanctionedMember !== loanSanctionedMember
    );
    AddDetails(removeList);
  };

  const [otherLoanType, getOtherLoanTypeData] = useState("");
  const [otherLoanTypeId, setOtherLoanTypeId] = useState(null);
  const [otherLoanTypeName, setOtherLoanTypeName] = useState("");

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  //NEW START
  const [bank, getBank] = useState("");
  const [batchBankNameId, setBatchBankNameId] = useState(null);
  const [batchBankName, setBatchBankName] = useState("");
  const activeBank = [];
  activeBankData &&
    activeBankData.banks &&
    activeBankData.banks.map((bank) =>
      activeBank.push({
        bankId: bank._id,
        label: bank.batchBankName,
        value: bank.batchBankName,
      })
    );

  const onBankChange = (e) => {
    setErrors3({
      ...errors3,
      bankChecker: true,
      bankErrorStyle: { color: "#000" },
    });
    setError({
      ...error,
      bankIdChecker: true,
      bankIdErrorStyle: { color: "#000" },
    });
    getBank(e);
    setBatchBankNameId(e.bankId ? e.bankId : null);
    setBatchBankName(e.value ? e.value : "");
  };

  const onInputChange2 = (e) => {
    setFormBankData({ ...addBankData, [e.target.name]: e.target.value });
  };

  const [bank2, getBank2] = useState("");
  const onBankChange2 = (e) => {
    setError({
      ...error,
      bankIdChecker: true,
      bankIdErrorStyle: { color: "#000" },
    });
    getBank2(e);
  };

  const [bankOLIPD, getBankOLIPD] = useState("");
  const onBankChangeOLIPD = (e) => {
    setError({
      ...error,
      bankIdChecker: true,
      bankIdErrorStyle: { color: "#000" },
    });
    getBankOLIPD(e);
  };

  const [bankOLPD, getBankOLPD] = useState("");
  const onBankChangeOLPD = (e) => {
    setError({
      ...error,
      bankIdChecker: true,
      bankIdErrorStyle: { color: "#000" },
    });
    getBankOLPD(e);
  };

  const [bankSDUS, getBankSDUS] = useState("");
  const onBankChangeSDUS = (e) => {
    getBankSDUS(e);
  };
  const [bankSDSU, getBankSDSU] = useState("");
  const onBankChangeSDSU = (e) => {
    getBankSDSU(e);
  };
  //validation for select add bank
  const [errors3, setErrors3] = useState({
    bankChecker: false,
    bankErrorStyle: {},
  });
  const { bankChecker, bankErrorStyle } = errors3;

  const checkError3 = () => {
    if (!bankChecker) {
      setErrors3({
        ...errors3,
        bankErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };
  //validation for select other loan
  const [errors2, setErrors2] = useState({
    otherloantypeChecker: false,
    otherloantypeErrorStyle: {},
  });
  const { otherloantypeChecker, otherloantypeErrorStyle } = errors2;

  const checkError2 = () => {
    if (!otherloantypeChecker) {
      setErrors2({
        ...errors2,
        otherloantypeErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };

  //validation for select other loan repayment
  const [errors1, setErrors1] = useState({
    otherLoanChecker: false,
    OtherLoanErrorStyle: {},
  });
  const { otherLoanChecker, OtherLoanErrorStyle } = errors1;

  const checkError1 = () => {
    if (!otherLoanChecker) {
      setErrors1({
        ...errors1,
        OtherLoanErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };

  //NEW END

  const onotherLoanTypeChange = (e) => {
    setErrors2({
      ...errors2,
      otherloantypeChecker: true,
      otherloantypeErrorStyle: { color: "#000" },
    });

    setErrors1({
      ...errors1,
      otherLoanChecker: true,
      OtherLoanErrorStyle: { color: "#000" },
    });
    setError({
      ...error,
      otherLoanTypeIdChecker: true,
      otherLoanTypeIdErrorStyle: { color: "#000" },
    });
    getOtherLoanTypeData(e);
    setOtherLoanTypeId(e.otherLoanTypeId ? e.otherLoanTypeId : "");
    setOtherLoanTypeName(e.value ? e.value : "");
  };

  const [BankLoanAddedDetails, BankLoanAddDetails] = useState([]);

  const onAddBankLoan = (e) => {
    e.preventDefault();

    const loanBankList = BankLoanAddedDetails.filter(
      (BankLoanAddDetails) =>
        BankLoanAddDetails.otherloanSanctionedMemberId === memberIds
    );
    e.preventDefault();
    if (loanBankList.length === 0) {
      if (othercheckErrors()) {
        if (checkError2()) {
          const addDatas = {
            otherLoanTypeId: otherLoanTypeId,
            otherLoanType: otherLoanTypeName,
            otherloanSanctionedMemberId: memberIds,
            otherloanSanctionedMember: memberName,
            otherbatchOtherLoanAmt: batchOtherLoanAmts,
            externalNominee: externalNominee,
            otherLoanReasonId: loanReasonId,
            otherloanSanctionedPurpose: bankloanPurpose,
            otherloanSanctionedotherPurpose: bankloanOtherReason,
          };
          setFormDatas({
            ...addDatas,
            otherbatchOtherLoanAmt: "",
            batchOtherLoanAmts: "",
            externalNominee: "",
            otherLoanReasonId: "",
            otherloanSanctionedPurpose: "",
            bankloanOtherReason: "",
            otherLoanTypeId: "",
            otherLoanType: "",
          });

          setFormData({
            ...formData,
            batchOtherLoanAmts: "",
            externalNominee: "",
            bankloanOtherReason: "",
          });
          getbankpurposeData("");
          getmemberDatas("");
          getOtherLoanTypeData("");
          let temp = [];
          temp.push(...BankLoanAddedDetails, addDatas);
          BankLoanAddDetails(temp);
        }
      }
    }
  };

  const onRemoveChangeBankLoan = (otherloanSanctionedMember) => {
    const removeList = BankLoanAddedDetails.filter(
      (BankLoanAddDetails) =>
        BankLoanAddDetails.otherloanSanctionedMember !==
        otherloanSanctionedMember
    );
    BankLoanAddDetails(removeList);
  };

  const onInputChange1 = (e) => {
    setFormDatas({ ...addData, [e.target.name]: e.target.value });
  };

  const [tabIndex, setTabIndex] = useState(0);
  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  //OLR GRID START==============================================================================
  const [AddedDetailsOLR, AddDetailsOLR] = useState([]);
  const onAddOLR = (e) => {
    e.preventDefault();
    if (checkError1()) {
      e.preventDefault();
      if (otherLoanType) {
        const listOLR = AddedDetailsOLR.filter(
          (AddDetails) =>
            AddDetails.otherLoanTypeId === otherLoanType.otherLoanTypeId
        );
        if (listOLR.length === 0) {
          const addData = {
            otherLoanType: otherLoanType.value,
            otherLoanTypeId: otherLoanType.otherLoanTypeId,
            otherLoanPaidToShg: otherLoanPaidToShg,
            batchOtherLoanInterestShg: batchOtherLoanInterestShg,
            subsidyReceived: subsidyReceived,
            otherLoanSanctionedToShg: otherLoanSanctionedToShg,
            batchOtherLoanInterestDonor: batchOtherLoanInterestDonor,
            subsidyDistributed: subsidyDistributed,
            otherLoanPaidToDonor: otherLoanPaidToDonor,
            selectionType: selectionType,
            selectionType1: selectionType1,
            selectionOLIPDType: selectionOLIPDType,
            selectionOLPDType: selectionOLPDType,
            bank: bank ? bank.value : "",
            bankId: bank ? bank.bankId : null,
            bank2: bank2 ? bank2.value : "",
            bankId2: bank2 ? bank2.bankId : null,
            bankOLIPD: bankOLIPD ? bankOLIPD.value : "",
            bankOLIPDId: bankOLIPD ? bankOLIPD.bankId : null,
            bankOLPD: bankOLPD ? bankOLPD.value : "",
            bankOLPDId: bankOLPD ? bankOLPD.bankId : null,
          };

          setFormData({
            ...formData,
            otherLoanType: "",
            otherLoanPaidToShg: "",
            batchOtherLoanInterestShg: "",
            subsidyReceived: "",
            otherLoanSanctionedToShg: "",
            batchOtherLoanInterestDonor: "",
            subsidyDistributed: "",
            otherLoanPaidToDonor: "",
            selectionType: "",
            selectionType1: "",
            selectionOLIPDType: "",
            selectionOLPDType: "",
          });
          let temp = [];
          temp.push(...AddedDetailsOLR, addData);
          AddDetailsOLR(temp);
          getOtherLoanTypeData("");
          setSelectionType();
          setSelectionType1();
          setSelectionOLIPDType();
          setSelectionOLPDType();
          getBank();
          getBank2();
          getBankOLIPD();
          getBankOLPD();
          setShowHide({
            ...showHide,
            showotherLoanSanctionedToShgSection: false,
            showSubsidyReceivedSection: false,
            showOLIPDSection: false,
            showOLPDSection: false,
          });
        }
      }
    }
  };

  const onAddOLRSingle = (nxtTab) => {
    // e.preventDefault();
    let defaultBank = activeBank[0] ? activeBank[0].value : "";
    let defaultBankID = activeBank[0] ? activeBank[0].bankId : null;
    const addData = {
      otherLoanType: activeOtherLoan[0] ? activeOtherLoan[0].value : "",
      otherLoanTypeId: activeOtherLoan[0]
        ? activeOtherLoan[0].otherLoanTypeId
        : null,
      otherLoanPaidToShg: otherLoanPaidToShg,
      batchOtherLoanInterestShg: batchOtherLoanInterestShg,
      subsidyReceived: subsidyReceived,
      otherLoanSanctionedToShg: otherLoanSanctionedToShg,
      batchOtherLoanInterestDonor: batchOtherLoanInterestDonor,
      subsidyDistributed: subsidyDistributed,
      otherLoanPaidToDonor: otherLoanPaidToDonor,
      selectionType: selectionType,
      selectionType1: selectionType1,
      selectionOLIPDType: selectionOLIPDType,
      selectionOLPDType: selectionOLPDType,
      bank: selectionType === "bank" ? defaultBank : "",
      bankId: selectionType === "bank" ? defaultBankID : null,
      bank2: selectionType1 === "bank" ? defaultBank : "",
      bankId2: selectionType1 === "bank" ? defaultBankID : null,
      bankOLIPD: selectionOLIPDType === "bank" ? defaultBank : "",
      bankOLIPDId: selectionOLIPDType === "bank" ? defaultBankID : null,
      bankOLPD: selectionOLPDType === "bank" ? defaultBank : "",
      bankOLPDId: selectionOLPDType === "bank" ? defaultBankID : null,
    };
    let temp = [];
    temp.push(addData);
    AddDetailsOLR(temp);
    NextBackBtn(nxtTab);
  };

  const onRemoveChangeOLR = (otherLoanTypeId) => {
    const removeList = AddedDetailsOLR.filter(
      (AddDetails) => AddDetails.otherLoanTypeId !== otherLoanTypeId
    );
    AddDetailsOLR(removeList);
  };

  //OLR GRID END================================================================================

  const [selectedDate, setSelectedDate] = useState("");
  const [isRedirectStatus, setRedirectStatus] = useState(false);
  const onDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const oncheck = () => {
    if (selectedDate !== "") {
      const finalData = {
        mdDate: selectedDate,
        mdBatchId: batchIdValue.batchIdVal,
      };
      checkAvailableDate(finalData);
      const meetingBatchInfo = finalData;
      getMeetingSummary(meetingBatchInfo);

      if (lastMeetingDate.length === 0) {
        setRedirectStatus(true);
        localStorage.setItem("selDate", JSON.stringify(finalData));
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let bankTran = [];
    if (activeBank.length <= 1) {
      bankTran = onAddBankSingle(e);
    } else {
      bankTran = AddedBankDetails;
    }
    if (bankTran.length > 0 || cashInHand > 0) {
      let otherLoanSanctionedToShgSum = 0,
        otherLoanPaidToShgSum = 0,
        otherLoanPaidToDonorSum = 0,
        batchOtherLoanInterestShgSum = 0,
        batchOtherLoanInterestDonorSum = 0,
        subsidyReceivedSum = 0,
        indSubsidyDistributedSum = 0;

      AddedDetailsOLR.map((AddDetail, idx) => {
        otherLoanSanctionedToShgSum += parseFloat(
          AddDetail.otherLoanSanctionedToShg || 0
        );
        otherLoanPaidToShgSum += parseFloat(AddDetail.otherLoanPaidToShg || 0);
        otherLoanPaidToDonorSum += parseFloat(
          AddDetail.otherLoanPaidToDonor || 0
        );
        batchOtherLoanInterestShgSum += parseFloat(
          AddDetail.batchOtherLoanInterestShg || 0
        );
        batchOtherLoanInterestDonorSum += parseFloat(
          AddDetail.batchOtherLoanInterestDonor || 0
        );
        subsidyReceivedSum += parseFloat(AddDetail.subsidyReceived || 0);
        indSubsidyDistributedSum += parseFloat(
          AddDetail.subsidyDistributed || 0
        );
      });

      let batchBankDepositSum = 0,
        batchBankWithdrawalsSum = 0,
        bankInterestSum = 0,
        bankCommissionSum = 0;

      bankTran.map((AddDetail) => {
        batchBankDepositSum += parseFloat(AddDetail.indBatchBankDeposit || 0);
        batchBankWithdrawalsSum += parseFloat(
          AddDetail.indBatchBankWithdrawals || 0
        );
        bankInterestSum += parseFloat(AddDetail.indBankInterest || 0);
        bankCommissionSum += parseFloat(AddDetail.indBankCommission || 0);
      });

      var loanSanctionedAmtTot = 0,
        otherLoanSanctionedAmtTot = 0;
      var dateVal = new Date(selectedDate);

      AddedDetails.map((addedLoanData) => {
        if (addedLoanData.batchLoanAmt && addedLoanData.batchLoanAmt > 0) {
          const loanSanctionedData = {
            batchId: batchIdValue.batchIdVal,
            memberId: addedLoanData.loanSanctionedMemberId,
            memberName: addedLoanData.loanSanctionedMember,
            loanSanctionedAmt: addedLoanData.batchLoanAmt,
            internalNominee: addedLoanData.internalNominee,
            batchMeetingMonth: dateVal.getMonth() + 1,
            batchMeetingYear: dateVal.getFullYear(),
            meetingHeldOnDate: selectedDate,
            loanReasonId: addedLoanData.loanReasonId
              ? addedLoanData.loanReasonId
              : null,
            loanPurpose: addedLoanData.loanSanctionedPurpose,
            otherPurpose: addedLoanData.loanSanctionedotherPurpose,
            loanSanctionedEnteredById: user && user._id,
            loanSanctionedEnteredByName: user && user.userName,
            institutionId: user && user.institutionId,
          };
          loanSanctionedAmtTot += Number(addedLoanData.batchLoanAmt);
          AddLoanSanctionedDetails(loanSanctionedData);
        }
      });

      BankLoanAddedDetails.map((addedOtherLoanData) => {
        if (
          addedOtherLoanData.otherbatchOtherLoanAmt &&
          addedOtherLoanData.otherbatchOtherLoanAmt > 0
        ) {
          const otherLoanSanctionedData = {
            batchId: batchIdValue.batchIdVal,
            memberId: addedOtherLoanData.otherloanSanctionedMemberId,
            memberName: addedOtherLoanData.otherloanSanctionedMember,
            otherLoanSanctionedAmt: addedOtherLoanData.otherbatchOtherLoanAmt,
            externalNominee: addedOtherLoanData.externalNominee,
            batchMeetingMonth: dateVal.getMonth() + 1,
            batchMeetingYear: dateVal.getFullYear(),
            meetingHeldOnDate: selectedDate,
            otherLoanReasonId: addedOtherLoanData.otherLoanReasonId
              ? addedOtherLoanData.otherLoanReasonId
              : null,
            otherLoanReason: addedOtherLoanData.otherloanSanctionedPurpose,
            otherloanotherpurpose:
              addedOtherLoanData.otherloanSanctionedotherPurpose,
            loanSanctionedEnteredById: user && user._id,
            loanSanctionedEnteredByName: user && user.userName,
            institutionId: user && user.institutionId,
            otherLoanTypeId: addedOtherLoanData.otherLoanTypeId
              ? addedOtherLoanData.otherLoanTypeId
              : null,
            otherLoanType: addedOtherLoanData.otherLoanType
              ? addedOtherLoanData.otherLoanType
              : "",
          };
          otherLoanSanctionedAmtTot += Number(
            addedOtherLoanData.otherbatchOtherLoanAmt
          );
          AddLoanSanctionedDetails(otherLoanSanctionedData);
        }
      });

      const finalData = {
        batchId: batchIdValue.batchIdVal,
        batchName: selectedBatchData[0].batchName,
        batchMeetingHeldOnDate: selectedDate,
        batchMeetingMonth: dateVal.getMonth() + 1,
        batchMeetingYear: dateVal.getFullYear(),
        batchTransactionType: selectedBatchData[0].batchTransactionType,
        meetingCategory: "M",
        //INCOME
        batchSavingAmt: batchSavingAmt,
        batchSubAmt: batchSubAmt,
        batchOtherContribution: batchOtherContribution
          ? batchOtherContribution
          : 0,
        batchMembershipFees: batchMembershipFees ? batchMembershipFees : 0,
        bankInterest: bankInterestSum ? Number(bankInterestSum).toFixed(2) : 0, //sumB
        deactiveMemberSaving: deactiveMemberSaving ? deactiveMemberSaving : 0,
        subsidyReceived: subsidyReceivedSum
          ? Number(subsidyReceivedSum).toFixed(2)
          : 0, //sum
        batchOtherIncome: batchOtherIncome ? batchOtherIncome : 0,
        seedMoney: seedMoney ? seedMoney : 0,
        batchSecurityDepositMemToShg: batchSecurityDepositMemToShg
          ? batchSecurityDepositMemToShg
          : 0,
        sdUnionToSHG: sdUnionToSHG ? sdUnionToSHG : 0,
        bankSDUSId: bankSDUS ? bankSDUS.bankId : null,
        bankSDUS: bankSDUS ? bankSDUS.value : "",
        selectionSDUSType: selectionSDUSType ? selectionSDUSType : "",
        loanRepaidByOtherSHG: loanRepaidByOtherSHG ? loanRepaidByOtherSHG : 0,
        //EXPENSE
        travellingExpenses: travellingExpenses ? travellingExpenses : 0,
        stationaryExpenses: stationaryExpenses ? stationaryExpenses : 0,
        paidToMahasangha: paidToMahasangha ? paidToMahasangha : 0,
        bankCommission: bankCommissionSum
          ? Number(bankCommissionSum).toFixed(2)
          : 0, //sumB
        serviceCharges: serviceCharges ? serviceCharges : 0,
        batchDividendDistributed: batchDividendDistributed
          ? batchDividendDistributed
          : 0,
        otherExpenses: otherExpenses ? otherExpenses : 0,
        loanGivenToOtherSHG: loanGivenToOtherSHG ? loanGivenToOtherSHG : 0,
        batchSavingWithdrawals: batchSavingWithdrawals
          ? batchSavingWithdrawals
          : 0,
        WithdrawalPerson: WithdrawalPerson ? WithdrawalPerson : "",

        batchSecurityDeposit: batchSecurityDeposit ? batchSecurityDeposit : 0,
        donationToOutsider: donationToOutsider ? donationToOutsider : 0,
        subsidyDistributed: indSubsidyDistributedSum
          ? Number(indSubsidyDistributedSum).toFixed(2)
          : 0, //sum
        sdSHGToUnion: sdSHGToUnion ? sdSHGToUnion : 0,
        bankSDSUId: bankSDSU ? bankSDSU.bankId : null,
        bankSDSU: bankSDSU ? bankSDSU.value : "",
        selectionSDSUType: selectionSDSUType ? selectionSDSUType : "",
        //LOAN DETAILS
        batchLoanAmt: loanSanctionedAmtTot,
        batchLoanPaid: batchLoanPaid ? batchLoanPaid : 0,
        batchInterestPaid: batchInterestPaid ? batchInterestPaid : 0,
        //OTHER LOAN DETAILS
        otherLoanSanctionedToShg: otherLoanSanctionedToShgSum
          ? Number(otherLoanSanctionedToShgSum).toFixed(2)
          : 0, //sum
        otherLoanSanctionedToMember: otherLoanSanctionedAmtTot
          ? Number(otherLoanSanctionedAmtTot).toFixed(2)
          : 0, //tot
        otherLoanPaidToShg: otherLoanPaidToShgSum
          ? Number(otherLoanPaidToShgSum).toFixed(2)
          : 0, //sum
        otherLoanPaidToDonor: otherLoanPaidToDonorSum
          ? Number(otherLoanPaidToDonorSum).toFixed(2)
          : 0, //sum
        batchOtherLoanInterestDonor: batchOtherLoanInterestDonorSum
          ? Number(batchOtherLoanInterestDonorSum).toFixed(2)
          : 0, //sum
        batchOtherLoanInterestShg: batchOtherLoanInterestShgSum
          ? Number(batchOtherLoanInterestShgSum).toFixed(2)
          : 0, //sum
        otherLoanBatchTransaction: AddedDetailsOLR, //new
        //BANK DETAILS
        cashInHand: cashInHand || 0,
        batchBankDeposit: batchBankDepositSum
          ? Number(batchBankDepositSum).toFixed(2)
          : 0, //sumB
        batchBankWithdrawals: batchBankWithdrawalsSum
          ? Number(batchBankWithdrawalsSum).toFixed(2)
          : 0, //sumB
        bankTransactions: bankTran, //new
        //OTHER
        meetingNotes: meetingNotes,
        meetingFileName: meetingFileName,

        // meetingScreenShot: meetingPhotoUpload1,
        // meetingScreenShot2: meetingPhotoUpload2,
        // meetingScreenShot3: meetingPhotoUpload3,
        // meetingScreenShot4: meetingPhotoUpload4,
        // meetingScreenShot5: meetingPhotoUpload5,
        // meetingScreenShot6: meetingPhotoUpload6,
        // meetingScreenShot7: meetingPhotoUpload7,
        // meetingPhotoUpload3: "",
        // meetingPhotoUpload4: "",
        // meetingPhotoUpload5: "",
        // meetingPhotoUpload6: "",
        // meetingPhotoUpload7: "",

        btEnteredById: user && user._id,
        btEnteredByName: user && user.userName,
        btEnteredDate: new Date().toISOString().split("T")[0],
        institutionId: user && user.institutionId,
      };
      BatchTransaction(finalData);
      sendMettingFile(pdfFormData);
      const meetingData = {
        mdDate: selectedDate,
        mdBatchId: batchIdValue.batchIdVal,
        mdStatus: "Submitted",
        mdMonth: dateVal.getMonth() + 1,
        mdYear: dateVal.getFullYear(),
        mdEnteredById: user && user._id,
        mdEnteredByName: user && user.userFullName,
        institutionId: user && user.institutionId,
      };
      meetingDates(meetingData);
      setFormData({
        ...formData,
        isSubmitted: true,
      });
    }
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  var lastMeetingDate = "";
  var lastMeetingDateYMD = "";
  if (lastMeetingData && lastMeetingData.length !== 0) {
    var LMD =
      lastMeetingData &&
      lastMeetingData[0] &&
      lastMeetingData[0].mdDate.split(/\D/g);
    lastMeetingDate = [LMD[2], LMD[1], LMD[0]].join("-");
    lastMeetingDateYMD =
      lastMeetingData && lastMeetingData[0] && lastMeetingData[0].mdDate;
  }

  const allMember = [];
  selBatchMem.map((member) =>
    allMember.push({
      memberId: member._id,
      label: member.memberName,
      value: member.memberName,
    })
  );

  const [member, getmemberData] = useState();
  const [memberId, setmemberId] = useState();
  const [memberName, setmemberName] = useState();
  const onMemberChange = (e) => {
    setError({
      ...error,
      MemberIdChecker: true,
      MemberIdErrorStyle: { color: "#000" },
    });
    getmemberData(e);
    setmemberId(e.memberId ? e.memberId : null);
    setmemberName(e.value ? e.value : "");
  };

  const allMembers = [];
  selBatchMem.map((members) =>
    allMembers.push({
      memberIds: members._id,
      label: members.memberName,
      value: members.memberName,
    })
  );

  const [members, getmemberDatas] = useState();
  const [memberIds, setmemberIds] = useState();
  const onMemberChanged = (e) => {
    setError({
      ...error,
      OtherMemberIdChecker: true,
      OtherMemberIdErrorStyle: { color: "#000" },
    });
    getmemberDatas(e);
    setmemberIds(e.memberIds ? e.memberIds : null);
    setmemberName(e.value ? e.value : "");
  };

  const [photoSizeAlert, setPhotoSizeAlert] = useState(false);
  const [photoSizeAlert1, setPhotoSizeAlert1] = useState(false);
  const [photoSizeAlert2, setPhotoSizeAlert2] = useState(false);
  const [photoSizeAlert3, setPhotoSizeAlert3] = useState(false);
  const [photoSizeAlert4, setPhotoSizeAlert4] = useState(false);
  const [photoSizeAlert5, setPhotoSizeAlert5] = useState(false);
  const [photoSizeAlert6, setPhotoSizeAlert6] = useState(false);
  const [photoSize, setPhotoSize] = useState("");

  ///////////////////////////////////////////REDUCE AND CHECK SIZE (70)
  const checksize = async (file, index) => {
    const resizeFile = (file) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          400,
          400,
          "JPEG",
          90,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
        );
      });
    const base64 = await resizeFile(file);
    const type = base64.split(";")[0].split("/")[1];
    setFileType(type);
    var stringLength = base64.length - "data:image/png;base64,".length;
    var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    var sizeInKb = sizeInBytes / 1000;
    setPhotoSize(sizeInKb.toFixed(2) + "KB");
    if (sizeInKb >= 70) {
      if (index === 1) {
        setFormData({
          ...formData,
          meetingPhotoUpload1: "",
        });
        setPhotoSizeAlert(true);
      }

      if (index === 2) {
        setFormData({
          ...formData,
          meetingPhotoUpload2: "",
        });
        setPhotoSizeAlert1(true);
      }
      if (index === 3) {
        setFormData({
          ...formData,
          meetingPhotoUpload3: "",
        });
        setPhotoSizeAlert2(true);
      }
      if (index === 4) {
        setFormData({
          ...formData,
          meetingPhotoUpload4: "",
        });
        setPhotoSizeAlert3(true);
      }
      if (index === 5) {
        setFormData({
          ...formData,
          meetingPhotoUpload5: "",
        });
        setPhotoSizeAlert4(true);
      }
      if (index === 6) {
        setFormData({
          ...formData,
          meetingPhotoUpload6: "",
        });
        setPhotoSizeAlert5(true);
      }
      if (index === 7) {
        setFormData({
          ...formData,
          meetingPhotoUpload7: "",
        });
        setPhotoSizeAlert6(true);
      }

      // setPhotoSizeAlert(true);
      // setFormData({
      //   ...formData,
      //   meetingPhotoUpload1: "",
      //   meetingPhotoUpload2: "",
      //   meetingPhotoUpload3: "",
      //   meetingPhotoUpload4: "",
      //   meetingPhotoUpload5: "",
      //   meetingPhotoUpload6: "",
      //   meetingPhotoUpload7: "",
      //   // meetingPhotoUpload: "",
      // });
    } else {
      if (index === 1) {
        setFormData({
          ...formData,
          meetingPhotoUpload1: base64,
        });
        setPhotoSizeAlert(false);
      }
      if (index === 2) {
        setFormData({
          ...formData,
          meetingPhotoUpload2: base64,
        });
        setPhotoSizeAlert1(false);
      }

      if (index === 3) {
        setFormData({
          ...formData,
          meetingPhotoUpload3: base64,
        });
        setPhotoSizeAlert2(false);
      }

      if (index === 4) {
        setFormData({
          ...formData,
          meetingPhotoUpload4: base64,
        });
        setPhotoSizeAlert3(false);
      }

      if (index === 5) {
        setFormData({
          ...formData,
          meetingPhotoUpload5: base64,
        });
        setPhotoSizeAlert4(false);
      }

      if (index === 6) {
        setFormData({
          ...formData,
          meetingPhotoUpload6: base64,
        });
        setPhotoSizeAlert5(false);
      }

      if (index === 7) {
        setFormData({
          ...formData,
          meetingPhotoUpload7: base64,
        });
        setPhotoSizeAlert6(false);
      }
    }
  };
  /////////////////////////////////////////////

  //NEW BANK BALANCE START

  let BankDepositSum = 0,
    BankWithdrawalsSum = 0,
    BankInterestSum = 0,
    BankCommissionSum = 0;

  AddedBankDetails &&
    AddedBankDetails.map((AddDetail) => {
      BankDepositSum += parseFloat(AddDetail.indBatchBankDeposit || 0);
      BankWithdrawalsSum += parseFloat(AddDetail.indBatchBankWithdrawals || 0);
      BankInterestSum += parseFloat(AddDetail.indBankInterest || 0);
      BankCommissionSum += parseFloat(AddDetail.indBankCommission || 0);
    });

  let OLSToShgSum = 0,
    SRSum = 0,
    OLIPDSum = 0,
    OLPDSum = 0;

  AddedDetailsOLR &&
    AddedDetailsOLR.map((AddDetail) => {
      OLSToShgSum +=
        AddDetail.selectionType === "bank"
          ? parseFloat(AddDetail.otherLoanSanctionedToShg || 0)
          : 0;
      SRSum +=
        AddDetail.selectionType1 === "bank"
          ? parseFloat(AddDetail.subsidyReceived || 0)
          : 0;
      OLIPDSum +=
        AddDetail.selectionOLIPDType === "bank"
          ? parseFloat(AddDetail.batchOtherLoanInterestDonor || 0)
          : 0;
      OLPDSum +=
        AddDetail.selectionOLPDType === "bank"
          ? parseFloat(AddDetail.otherLoanPaidToDonor || 0)
          : 0;
    });

  let newBankBalance = 0;
  //BANK SECTION
  if (activeBank.length > 1) {
    newBankBalance +=
      BankInterestSum - BankCommissionSum + BankDepositSum - BankWithdrawalsSum;
  }
  // ON KEY PRESS OR SINGLE BANK CASE
  newBankBalance +=
    Number(bankGridInterest) -
    Number(bankGridCommission) +
    Number(bankGridDeposit) -
    Number(bankGridwithdrawal);

  //OTHER LOAN SECTION
  if (activeOtherLoan.length > 1) {
    newBankBalance += OLSToShgSum + SRSum - OLIPDSum - OLPDSum;
  }
  // ON KEY PRESS OR SINGLE OTHER LOAN TYPE CASE
  newBankBalance +=
    Number(selectionType === "bank" ? subsidyReceived : 0) +
    Number(selectionType1 === "bank" ? otherLoanSanctionedToShg : 0) +
    Number(selectionOLIPDType === "bank" ? batchOtherLoanInterestDonor : 0) +
    Number(selectionOLPDType === "bank" ? otherLoanPaidToDonor : 0);

  if (selectionSDUSType === "bank")
    newBankBalance += Number(sdUnionToSHG ? sdUnionToSHG : 0);
  if (selectionSDSUType === "bank")
    newBankBalance += Number(sdSHGToUnion ? sdSHGToUnion : 0);

  //NEW BANK BALANCE END
  let oldBankBalance = 0;
  summaryData[0] &&
    summaryData[0].otherLoanData.map((OLD) => {
      OLD.map((sub) => {
        oldBankBalance +=
          (sub.selectionType === "bank"
            ? parseFloat(sub.otherLoanSanctionedToShg || 0)
            : 0) +
          (sub.selectionType1 === "bank"
            ? parseFloat(sub.subsidyReceived || 0)
            : 0) -
          (sub.selectionOLIPDType === "bank"
            ? parseFloat(sub.batchOtherLoanInterestDonor || 0)
            : 0) -
          (sub.selectionOLPDType === "bank"
            ? parseFloat(sub.otherLoanPaidToDonor || 0)
            : 0);
      });
    });

  const [showImage, setShowImage] = useState(false);
  const [pertImg, setpertImg] = useState("");
  const onShowImage = (e, img) => {
    setpertImg(img);
    e.preventDefault();
    setShowImage(true);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfFormData, setPdfFormData] = useState("");

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
    var ED = selectedDate.split(/\D/g);
    var selDate = [ED[2], ED[1], ED[0]].join("-");
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("batchName", selectedBatchData[0].batchName);
    formData.append("batchMeetingHeldOnDate", selDate);
    setPdfFormData(formData);
  };

  //////////////////////////////

  const funcKeyDown = (e) => {
    (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault();
  };

  if (isRedirectStatus) {
    return <Redirect to="/add-opening-balance-for-batchmeeting-Details" />;
  }
  if (isSubmitted) {
    return <Redirect to="/landing-page" />;
  }

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="row col-lg-12 col-md-11 col-sm-12 col-12">
          <h2 className="">
            Add SHG Meeting Details for {batchIdValue.batchNameVal}
          </h2>
          <div className="col-lg-6 col-md-11 col-sm-12 col-12">
            <h5 className="heading_color float-right">
              Last Meeting Held on : {lastMeetingDate}
            </h5>
          </div>
          <hr />
        </div>

        <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding">
          <div className="col-lg-2 col-md-4 col-sm-11 col-11 ">
            <h4 className="heading_color">Meeting Date* </h4>
          </div>

          <div className="row col-lg-7 col-md-8 col-sm-12 col-12 ">
            <div className="col-lg-3 col-md-6 col-sm-6 col-8 pt-3">
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="selectedDate"
                value={selectedDate}
                onChange={(e) => onDateChange(e)}
                style={{
                  width: "100%",
                }}
                required
              />
              &nbsp;
              {meetingResponse && (
                <p style={{ color: "red" }}>{meetingResponse}</p>
              )}
              {selectedDate && selectedDate < lastMeetingDateYMD && (
                <p style={{ color: "red" }}>
                  Date selected cannot be less than last meeting date
                </p>
              )}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-8">
              <button
                className="btn sub_form btn_continue "
                onClick={() => oncheck()}
              >
                Check
              </button>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-11 col-11 ">
            <Link to="/select-add-meeting">
              <img
                className="img_icon_size log float-right"
                src={require("../../static/images/back.png")}
                alt="Back"
                title="Back"
              />
            </Link>
          </div>
        </div>
        {meetingResponse &&
        meetingResponse === "Meeting Available" &&
        selectedDate > lastMeetingDateYMD ? (
          <section className="sub_reg">
            <Tabs selectedIndex={tabIndex}>
              <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
                <TabList>
                  <Tab tabfor="0">Summary </Tab>
                  {allPermission && allPermission.includes("Screenshot") ? (
                    <Tab tabfor="1">MOM</Tab>
                  ) : (
                    <Tab tabfor="1" style={{ display: "none" }}>
                      MOM
                    </Tab>
                  )}
                  <Tab tabfor="2">Income</Tab>
                  <Tab tabfor="3">Expense</Tab>
                  <Tab tabfor="4">OL Repayment</Tab>
                  <Tab tabfor="5">Loan Sanctioned</Tab>
                  <Tab tabfor="6">OL Sanctioned</Tab>
                  <Tab tabfor="7">Bank Details</Tab>
                </TabList>
              </div>

              <TabPanel tabId="0">
                <form
                  onSubmit={(e) =>
                    NextBackBtn(allPermission.includes("Screenshot") ? 1 : 2)
                  }
                >
                  <div className="row col-lg-11 col-md-11 col-sm-11 col-11 ">
                    {summaryDataOp &&
                      summaryDataOp.map((summaryDataOp) => {
                        let summaryDataCheck = false;
                        summaryDataCheck =
                          summaryData && summaryData.length > 0 ? true : false;

                        //INCOME
                        let batchSavingAmount =
                          summaryDataOp.batchSavingAmt +
                          (summaryDataCheck
                            ? summaryData[0].batchSavingAmt
                            : 0);
                        let batchSubAmount =
                          summaryDataOp.batchSubAmt +
                          (summaryDataCheck ? summaryData[0].batchSubAmt : 0);
                        let batchOtherContribution =
                          summaryDataOp.batchOtherContribution +
                          (summaryDataCheck
                            ? summaryData[0].batchOtherContribution
                            : 0);
                        let batchInterestPaid =
                          summaryDataOp.batchInterestPaid +
                          (summaryDataCheck
                            ? summaryData[0].batchInterestPaid
                            : 0);
                        let bankInterest =
                          summaryDataOp.bankInterest +
                          (summaryDataCheck ? summaryData[0].bankInterest : 0);
                        let subsidyReceived =
                          summaryDataOp.subsidyReceived +
                          (summaryDataCheck
                            ? summaryData[0].subsidyReceived
                            : 0);
                        let batchMembershipFees =
                          summaryDataOp.batchMembershipFees +
                          (summaryDataCheck
                            ? summaryData[0].batchMembershipFees
                            : 0);
                        let batchOtherIncome =
                          summaryDataOp.batchOtherIncome +
                          (summaryDataCheck
                            ? summaryData[0].batchOtherIncome
                            : 0);
                        let deactiveMemberSaving =
                          summaryDataOp.deactiveMemberSaving +
                          (summaryDataCheck
                            ? summaryData[0].deactiveMemberSaving
                            : 0);
                        let seedMoney =
                          summaryDataOp.seedMoney +
                          (summaryDataCheck ? summaryData[0].seedMoney : 0);
                        let batchSecurityDepositMemToShg =
                          summaryDataOp.batchSecurityDepositMemToShg +
                          (summaryDataCheck
                            ? summaryData[0].batchSecurityDepositMemToShg
                            : 0);
                        let batchLoanPaid =
                          summaryDataOp.batchLoanPaid +
                          (summaryDataCheck ? summaryData[0].batchLoanPaid : 0);
                        let otherLoanSanctionedToShg =
                          summaryDataOp.otherLoanSanctionedToShg +
                          (summaryDataCheck
                            ? summaryData[0].otherLoanSanctionedToShg
                            : 0);
                        let loanRepaidByOtherSHG =
                          summaryDataOp.loanRepaidByOtherSHG +
                          (summaryDataCheck
                            ? summaryData[0].loanRepaidByOtherSHG
                            : 0);

                        let totalIncome =
                          batchSavingAmount +
                          batchSubAmount +
                          batchOtherContribution +
                          batchInterestPaid +
                          bankInterest +
                          subsidyReceived +
                          batchMembershipFees +
                          batchOtherIncome +
                          deactiveMemberSaving +
                          seedMoney +
                          batchSecurityDepositMemToShg +
                          batchLoanPaid +
                          otherLoanSanctionedToShg +
                          loanRepaidByOtherSHG;

                        //EXPENSE
                        let travellingExpenses =
                          summaryDataOp.travellingExpenses +
                          (summaryDataCheck
                            ? summaryData[0].travellingExpenses
                            : 0);
                        let stationaryExpenses =
                          summaryDataOp.stationaryExpenses +
                          (summaryDataCheck
                            ? summaryData[0].stationaryExpenses
                            : 0);
                        let paidToMahasangha =
                          summaryDataOp.paidToMahasangha +
                          (summaryDataCheck
                            ? summaryData[0].paidToMahasangha
                            : 0);
                        let bankCommission =
                          summaryDataOp.bankCommission +
                          (summaryDataCheck
                            ? summaryData[0].bankCommission
                            : 0);
                        let serviceCharges =
                          summaryDataOp.serviceCharges +
                          (summaryDataCheck
                            ? summaryData[0].serviceCharges
                            : 0);
                        let batchDividendDistributed =
                          summaryDataOp.batchDividendDistributed +
                          (summaryDataCheck
                            ? summaryData[0].batchDividendDistributed
                            : 0);
                        let otherExpenses =
                          summaryDataOp.otherExpenses +
                          (summaryDataCheck ? summaryData[0].otherExpenses : 0);

                        let loanGivenToOtherSHG =
                          summaryDataOp.loanGivenToOtherSHG +
                          (summaryDataCheck
                            ? summaryData[0].loanGivenToOtherSHG
                            : 0);

                        let batchSavingWithdrawals =
                          summaryDataOp.batchSavingWithdrawals +
                          (summaryDataCheck
                            ? summaryData[0].batchSavingWithdrawals
                            : 0);
                        let batchSecurityDeposit =
                          summaryDataOp.batchSecurityDeposit +
                          (summaryDataCheck
                            ? summaryData[0].batchSecurityDeposit
                            : 0);
                        let donationToOutsider =
                          summaryDataOp.donationToOutsider +
                          (summaryDataCheck
                            ? summaryData[0].donationToOutsider
                            : 0);
                        let subsidyDistributed =
                          summaryDataOp.subsidyDistributed +
                          (summaryDataCheck
                            ? summaryData[0].subsidyDistributed
                            : 0);
                        let otherLoanPaidToDonor =
                          summaryDataOp.otherLoanPaidToDonor +
                          (summaryDataCheck
                            ? summaryData[0].otherLoanPaidToDonor
                            : 0);
                        let batchOtherLoanInterestDonor =
                          summaryDataOp.batchOtherLoanInterestDonor +
                          (summaryDataCheck
                            ? summaryData[0].batchOtherLoanInterestDonor
                            : 0);
                        let batchLoanAmt =
                          summaryDataOp.batchLoanAmt +
                          (summaryDataCheck ? summaryData[0].batchLoanAmt : 0);
                        let batchOtherLoanAmt =
                          summaryDataOp.otherLoanSanctionedToMember +
                          (summaryDataCheck
                            ? summaryData[0].otherLoanSanctionedToMember
                            : 0);

                        let totalExpense =
                          travellingExpenses +
                          stationaryExpenses +
                          paidToMahasangha +
                          bankCommission +
                          serviceCharges +
                          batchDividendDistributed +
                          otherExpenses +
                          loanGivenToOtherSHG +
                          batchSavingWithdrawals +
                          batchSecurityDeposit +
                          donationToOutsider +
                          subsidyDistributed +
                          otherLoanPaidToDonor +
                          batchOtherLoanInterestDonor +
                          batchLoanAmt +
                          batchOtherLoanAmt;

                        remainingAmt =
                          Number(
                            summaryDataCheck ? summaryData[0].batchLoanAmt : 0
                          ) +
                          Number(summaryDataOp.batchLoanAmt) -
                          (Number(
                            summaryDataCheck ? summaryData[0].batchLoanPaid : 0
                          ) +
                            Number(summaryDataOp.batchLoanPaid));

                        otherLoanRemainingAmt =
                          Number(
                            summaryDataCheck
                              ? summaryData[0].otherLoanSanctionedToShg
                              : 0
                          ) +
                          Number(summaryDataOp.otherLoanSanctionedToShg) -
                          (Number(
                            summaryDataCheck
                              ? summaryData[0].otherLoanPaidToDonor
                              : 0
                          ) +
                            Number(summaryDataOp.otherLoanPaidToDonor));

                        bankBalance = (
                          Number(summaryDataOp.batchBankDeposit) -
                          Number(summaryDataOp.batchBankWithdrawals) +
                          Number(
                            summaryDataOp.bankInterest
                              ? summaryDataOp.bankInterest
                              : 0
                          ) -
                          Number(
                            summaryDataOp.bankCommission
                              ? summaryDataOp.bankCommission
                              : 0
                          ) +
                          Number(
                            summaryDataOp.subsidyReceivedBank
                              ? summaryDataOp.subsidyReceivedBank
                              : 0
                          ) +
                          Number(
                            summaryDataOp.otherLoanSanctionedToShgBank
                              ? summaryDataOp.otherLoanSanctionedToShgBank
                              : 0
                          ) -
                          Number(
                            memberClosingAmt && memberClosingAmt[0]
                              ? memberClosingAmt[0].memberClossingAmt
                              : 0
                          ) +
                          Number(
                            summaryDataCheck
                              ? summaryData[0].batchBankDeposit
                              : 0
                          ) +
                          Number(
                            summaryDataCheck ? summaryData[0].bankInterest : 0
                          ) -
                          Number(
                            summaryDataCheck
                              ? summaryData[0].batchBankWithdrawals
                              : 0
                          ) -
                          Number(
                            summaryDataCheck ? summaryData[0].bankCommission : 0
                          ) +
                          Number(
                            summaryDataCheck
                              ? summaryData[0].subsidyReceivedBank
                              : 0
                          ) +
                          Number(
                            summaryDataCheck
                              ? summaryData[0].otherLoanSanctionedToShgBank
                              : 0
                          ) +
                          oldBankBalance
                        ).toFixed(2);
                        cashInHandVal = summaryDataCheck
                          ? summaryData[0].cashInHand
                          : summaryDataOp.cashInHand;

                        let batchBankWithdrawalsVal = summaryDataCheck
                          ? summaryData[0].batchBankWithdrawals
                          : 0;
                        outstandingInternalLoanAmt =
                          Number(summaryDataOp.OutstandingBatchLoanAmt) +
                          Number(
                            summaryDataCheck
                              ? summaryData[0].OutstandingBatchLoanAmt
                              : 0
                          );
                        outstandinOtherLoanAmt =
                          Number(summaryDataOp.OutstandingBatchOtherLoanAmt) +
                          Number(
                            summaryDataCheck
                              ? summaryData[0].OutstandingBatchOtherLoanAmt
                              : 0
                          );
                        return (
                          <>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                              <h6>Total Savings : {batchSavingAmount}</h6>
                              <h6>Total Subscription : {batchSubAmount}</h6>
                              <h6>
                                Total Other Contribution :{" "}
                                {batchOtherContribution}
                              </h6>
                              <h6>
                                Total Loan Interest Paid to SHG :{" "}
                                {batchInterestPaid}
                              </h6>
                              <h6>Total Bank Interest : {bankInterest}</h6>
                              <h6>Subsidy Received : {subsidyReceived}</h6>
                              <h6>
                                Total Membership Fees : {batchMembershipFees}
                              </h6>
                              <h6>Total Other Income : {batchOtherIncome}</h6>
                              <h6>
                                Total Deactive Member Saving :{" "}
                                {deactiveMemberSaving}
                              </h6>
                              <h6>Total Seed Money : {seedMoney}</h6>
                              {/* <h6>
                                Total Security Deposit Member To Shg :{" "}
                                {batchSecurityDepositMemToShg}
                              </h6> */}
                              <h6>
                                Total Loan Repayment to SHG : {batchLoanPaid}
                              </h6>
                              <h6>
                                Total Other Loan Sanctioned To SHG :{" "}
                                {otherLoanSanctionedToShg}
                              </h6>
                              {allPermission &&
                              allPermission.includes("SHG-to-SHGLoan") ? (
                                <h6>
                                  Total Loan Repaid By Other SHG :{" "}
                                  {loanRepaidByOtherSHG}
                                </h6>
                              ) : (
                                <></>
                              )}

                              <h6>
                                <b>Total Income : {totalIncome}</b>
                              </h6>
                            </div>

                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                              <h6>
                                Total Travelling Expenses : {travellingExpenses}
                              </h6>
                              <h6>
                                Total Stationary Expenses : {stationaryExpenses}
                              </h6>
                              <h6>Total Paid To Union : {paidToMahasangha}</h6>
                              <h6>Total Bank Commission : {bankCommission}</h6>
                              <h6>Total Service Charges : {serviceCharges}</h6>
                              <h6>
                                Total Dividend Distributed :{" "}
                                {batchDividendDistributed}
                              </h6>
                              <h6>Total Other Expenses : {otherExpenses}</h6>
                              <h6>
                                Total Saving Withdrawals :{" "}
                                {batchSavingWithdrawals}
                              </h6>
                              <h6>
                                Total Security Deposit : {batchSecurityDeposit}
                              </h6>
                              <h6>
                                Total Donation To Outsider :{" "}
                                {donationToOutsider}
                              </h6>

                              <h6>
                                Total Subsidy Distributed : {subsidyDistributed}
                              </h6>
                              <h6>
                                Total Loan Paid To Donor :{" "}
                                {otherLoanPaidToDonor}
                              </h6>
                              <h6>
                                Total Other Loan Interest to Donor :{" "}
                                {batchOtherLoanInterestDonor}
                              </h6>
                              <h6>
                                Total Internal Loan Amount Given :{" "}
                                {batchLoanAmt}
                              </h6>
                              <h6>
                                Total Other Loan Amount Given :{" "}
                                {batchOtherLoanAmt}
                              </h6>
                              {allPermission &&
                              allPermission.includes("SHG-to-SHGLoan") ? (
                                <h6>
                                  Total Loan Given To Other SHG :{" "}
                                  {loanGivenToOtherSHG}
                                </h6>
                              ) : (
                                <></>
                              )}

                              <h6>
                                <b>Total Expense : {totalExpense}</b>
                              </h6>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                              <h6>
                                <b>Cash In Hand : {cashInHandVal}</b>
                              </h6>
                              <h6>
                                <b>Bank Balance : {bankBalance}</b>
                              </h6>
                              <h6>
                                <b>
                                  Bank Withdrawals : {batchBankWithdrawalsVal}
                                </b>
                              </h6>
                              <h6>
                                <b>
                                  Total Outstanding Internal Loan Amount :{" "}
                                  {Number(outstandingInternalLoanAmt).toFixed(
                                    2
                                  )}
                                </b>
                              </h6>
                              <h6>
                                <b>
                                  Total Outstanding Other Loan Amount :{" "}
                                  {Number(outstandinOtherLoanAmt).toFixed(2)}
                                </b>
                              </h6>
                            </div>
                          </>
                        );
                      })}

                    <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                      <input
                        type="submit"
                        name="submit"
                        value="Next"
                        className="btn sub_form btn_continue Save float-right"
                      />
                    </div>
                  </div>
                </form>
              </TabPanel>

              {tabIndex !== 0 && (
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row card-new  py-1">
                      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>
                          <b>Previous Bank Balance : {bankBalance}</b>
                        </label>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>
                          <b>
                            New Bank Balance :
                            {(
                              Number(bankBalance) + Number(newBankBalance)
                            ).toFixed(2)}
                          </b>
                        </label>
                      </div>

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>
                          <b>Previous Cash in Hand : {cashInHandVal}</b>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <TabPanel tabId="1">
                <form onSubmit={(e) => NextBackBtn(2)}>
                  <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="row card-new  py-3 ">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h5>MOM</h5>
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Meeting Notes* :
                          </label>
                          <textarea
                            name="meetingNotes"
                            value={meetingNotes}
                            id="meetingNotes"
                            className="textarea form-control"
                            rows="4"
                            placeholder="Meeting Notes"
                            onChange={(e) => onInputChange(e)}
                            style={{ width: "100%" }}
                            required
                          ></textarea>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">Upload PDF :</label>

                          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                            {/* <FileBase64
                              type="file"
                              multiple={false}
                              onDone={({ base64 }) => {
                                setFormData({
                                  ...formData,
                                  meetingPhotoUpload: base64,
                                });
                                checksize(base64);
                              }}
                            /> */}
                            <input
                              // photoSizeAlert1
                              className={
                                photoSizeAlert1
                                  ? "btn btn-danger font-weight-normal"
                                  : "btn btn-block font-weight-normal"
                              }
                              type="file"
                              accept="application/pdf"
                              multiple={false}
                              onChange={(event) => handleFileChange(event)}

                              // onChange={(e) =>
                              //   checksize(e.target.files[0], 2)
                              // }
                              // style={{ color: "transparent" }}
                            />

                            {photoSizeAlert ? (
                              "Size should be less than 70KB"
                            ) : fileType === "pdf" ? (
                              <PdfPreview
                                // baseData={meetingPhotoUpload}
                                meetData={{
                                  batchName: selectedBatchData[0].batchName,
                                  mdDate: selectedDate,
                                }}
                              />
                            ) : (
                              <img
                                className="meetingPhoto_size"
                                alt="Preview"
                                onClick={(e) => {
                                  onShowImage(e);
                                }}
                                style={{ cursor: "pointer" }}
                                // src={`${meetingPhotoUpload}`}
                              />
                            )}
                          </div>
                        </div>

                        {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                Meeting Notes* :
                              </label>
                              <textarea
                                name="meetingNotes"
                                value={meetingNotes}
                                id="meetingNotes"
                                className="textarea form-control"
                                rows="4"
                                placeholder="Meeting Notes"
                                onChange={(e) => onInputChange(e)}
                                style={{ width: "100%" }}
                                required
                              ></textarea>
                            </div>

                            <div className="col-lg-4 col-md-12 col-sm-12">
                              <div className="row">
                                <div className="col-3">
                                  <div className="row">
                                    <img
                                      className="mt-2"
                                      style={{ cursor: "pointer" }}
                                      width="30px"
                                      height="30px"
                                      src={require("../../static/images/eye.png")}
                                      alt="img"
                                      onClick={(e) => {
                                        onShowImage(e, meetingPhotoUpload1);
                                      }}
                                    />
                                  </div>
                                  <div className="row">
                                    <label className="">
                                      {meetingPhotoUpload1 ? "File-1" : ""}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-9">
                                  <div className="row">
                                    <input
                                      photoSizeAlert
                                      className={
                                        photoSizeAlert
                                          ? "btn btn-danger font-weight-normal"
                                          : "btn btn-block font-weight-normal"
                                      }
                                      type="file"
                                      multiple={false}
                                      onChange={(e) =>
                                        checksize(e.target.files[0], 1)
                                      }
                                      // style={{ color: "transparent" }}
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* {photoSizeAlert ? (
                                  "Size should be less than 70KB"
                                ) : fileType === "pdf" ? (
                                  <PdfPreview
                                    baseData={meetingPhotoUpload1}
                                    meetData={{
                                      batchName: meetingBatchInfo.mdBatchName,
                                      mdDate: meetingBatchInfo.mdDate,
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="meetingPhoto_size"
                                    alt="Preview"
                                    onClick={(e) => {
                                      onShowImage(e, meetingPhotoUpload1);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    src={`${meetingPhotoUpload1}`}
                                  />
                                )} */}
                        {/* <div className="row">
                                <div className="col-3">
                                  <div className="row">
                                    <img
                                      className="mt-2"
                                      width="30px"
                                      height="30px"
                                      style={{ cursor: "pointer" }}
                                      src={require("../../static/images/eye.png")}
                                      alt="img"
                                      onClick={(e) => {
                                        onShowImage(e, meetingPhotoUpload2);
                                      }}
                                    />
                                  </div>
                                  <div className="row">
                                    <label className="">
                                      {meetingPhotoUpload2 ? "File-2" : ""}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-9">
                                  <div className="row">
                                    <input
                                      photoSizeAlert1
                                      className={
                                        photoSizeAlert1
                                          ? "btn btn-danger font-weight-normal"
                                          : "btn btn-block font-weight-normal"
                                      }
                                      type="file"
                                      multiple={false}
                                      onChange={(e) =>
                                        checksize(e.target.files[0], 2)
                                      }
                                      // style={{ color: "transparent" }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-3">
                                  <div className="row">
                                    <img
                                      className="mt-2"
                                      width="30px"
                                      height="30px"
                                      style={{ cursor: "pointer" }}
                                      src={require("../../static/images/eye.png")}
                                      alt="img"
                                      onClick={(e) => {
                                        onShowImage(e, meetingPhotoUpload3);
                                      }}
                                    />
                                  </div>
                                  <div className="row">
                                    <label className="">
                                      {meetingPhotoUpload3 ? "File-3" : ""}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-9">
                                  <div className="row">
                                    <input
                                      photoSizeAlert2
                                      className={
                                        photoSizeAlert2
                                          ? "btn btn-danger font-weight-normal"
                                          : "btn btn-block font-weight-normal"
                                      }
                                      type="file"
                                      multiple={false}
                                      onChange={(e) =>
                                        checksize(e.target.files[0], 3)
                                      }
                                      // style={{ color: "transparent" }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-3">
                                  <div className="row">
                                    <img
                                      className="mt-2"
                                      width="30px"
                                      height="30px"
                                      style={{ cursor: "pointer" }}
                                      src={require("../../static/images/eye.png")}
                                      alt="img"
                                      onClick={(e) => {
                                        onShowImage(e, meetingPhotoUpload4);
                                      }}
                                    />
                                  </div>
                                  <div className="row">
                                    <label className="">
                                      {meetingPhotoUpload4 ? "File-4" : ""}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-9">
                                  <div className="row">
                                    <input
                                      photoSizeAlert3
                                      className={
                                        photoSizeAlert3
                                          ? "btn btn-danger font-weight-normal"
                                          : "btn btn-block font-weight-normal"
                                      }
                                      type="file"
                                      multiple={false}
                                      onChange={(e) =>
                                        checksize(e.target.files[0], 4)
                                      }
                                      // style={{ color: "transparent" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                              <div className="row">
                                <div className="col-3">
                                  <div className="row">
                                    <img
                                      className="mt-2"
                                      width="30px"
                                      height="30px"
                                      style={{ cursor: "pointer" }}
                                      src={require("../../static/images/eye.png")}
                                      alt="img"
                                      onClick={(e) => {
                                        onShowImage(e, meetingPhotoUpload5);
                                      }}
                                    />
                                  </div>
                                  <div className="row">
                                    <label className="">
                                      {meetingPhotoUpload5 ? "File-5" : ""}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-9">
                                  <div className="row">
                                    <input
                                      photoSizeAlert4
                                      className={
                                        photoSizeAlert4
                                          ? "btn btn-danger font-weight-normal"
                                          : "btn btn-block font-weight-normal"
                                      }
                                      type="file"
                                      multiple={false}
                                      onChange={(e) =>
                                        checksize(e.target.files[0], 5)
                                      }
                                      // style={{ color: "transparent" }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-3">
                                  <div className="row">
                                    <img
                                      className="mt-2"
                                      width="30px"
                                      height="30px"
                                      style={{ cursor: "pointer" }}
                                      src={require("../../static/images/eye.png")}
                                      alt="img"
                                      onClick={(e) => {
                                        onShowImage(e, meetingPhotoUpload6);
                                      }}
                                    />
                                  </div>
                                  <div className="row">
                                    <label className="">
                                      {meetingPhotoUpload6 ? "File-6" : ""}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-9">
                                  <div className="row">
                                    <input
                                      photoSizeAlert5
                                      className={
                                        photoSizeAlert5
                                          ? "btn btn-danger font-weight-normal"
                                          : "btn btn-block font-weight-normal"
                                      }
                                      type="file"
                                      multiple={false}
                                      onChange={(e) =>
                                        checksize(e.target.files[0], 6)
                                      }
                                      // style={{ color: "transparent" }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-3">
                                  <div className="row">
                                    <img
                                      className="mt-2"
                                      width="30px"
                                      height="30px"
                                      style={{ cursor: "pointer" }}
                                      src={require("../../static/images/eye.png")}
                                      alt="img"
                                      onClick={(e) => {
                                        onShowImage(e, meetingPhotoUpload7);
                                      }}
                                    />
                                  </div>
                                  <div className="row">
                                    <label className="">
                                      {meetingPhotoUpload7 ? "File-7" : ""}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-9">
                                  <div className="row">
                                    <input
                                      photoSizeAlert6
                                      className={
                                        photoSizeAlert6
                                          ? "btn btn-danger font-weight-normal"
                                          : "btn btn-block font-weight-normal"
                                      }
                                      type="file"
                                      multiple={false}
                                      onChange={(e) =>
                                        checksize(e.target.files[0], 7)
                                      }
                                      // style={{ color: "transparent" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <input
                            type="submit"
                            name="submit"
                            value="Next"
                            className="btn sub_form btn_continue Save float-right"
                          />
                          <button
                            className="btn sub_form btn_continue Save float-right"
                            onClick={() => NextBackBtn(0)}
                          >
                            Previous
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </TabPanel>
              <TabPanel tabId="2">
                <form onSubmit={(e) => NextBackBtn(3)}>
                  <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="row card-new  py-3">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h5>Income</h5>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            SHG Saving Amount* :
                          </label>
                          <input
                            type="number"
                            name="batchSavingAmt"
                            value={batchSavingAmt}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                            required
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Subscription* :
                          </label>
                          <input
                            type="number"
                            name="batchSubAmt"
                            value={batchSubAmt}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                            required
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Other Contribution :
                          </label>
                          <input
                            type="number"
                            name="batchOtherContribution"
                            value={batchOtherContribution}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Membership Fees :
                          </label>
                          <input
                            type="number"
                            name="batchMembershipFees"
                            value={batchMembershipFees}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            IL Repayment to SHG{" "}
                            <img
                              className="img_icon_size log "
                              src={require("../../static/images/info.png")}
                              alt="Reason"
                              style={{ height: "18px", width: "18px" }}
                              title="Internal Loan Repayment to SHG from the Members"
                            />{" "}
                            :
                          </label>
                          <input
                            type="number"
                            name="batchLoanPaid"
                            value={batchLoanPaid}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            IL Interest Paid to SHG{" "}
                            <img
                              className="img_icon_size log "
                              src={require("../../static/images/info.png")}
                              alt="Reason"
                              style={{ height: "18px", width: "18px" }}
                              title="Internal Loan Interest Repayment to SHG from the Members"
                            />{" "}
                            :
                          </label>
                          <input
                            type="number"
                            name="batchInterestPaid"
                            value={batchInterestPaid}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Deactive Member Saving{" "}
                            <img
                              className="img_icon_size log "
                              src={require("../../static/images/info.png")}
                              alt="Reason"
                              style={{ height: "18px", width: "18px" }}
                              title="	The Saving Amount Received from the Deactivated Member"
                            />{" "}
                            :
                          </label>
                          <input
                            type="number"
                            name="deactiveMemberSaving"
                            value={deactiveMemberSaving}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Other Income :
                          </label>
                          <input
                            type="number"
                            name="batchOtherIncome"
                            value={batchOtherIncome}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Seed Money{" "}
                            <img
                              className="img_icon_size log "
                              src={require("../../static/images/info.png")}
                              alt="Reason"
                              style={{ height: "18px", width: "18px" }}
                              title="	Government Grant For Shg Development (Non-Returnable)"
                            />{" "}
                            :
                          </label>
                          <input
                            type="number"
                            name="seedMoney"
                            value={seedMoney}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            SD Member To SHG{" "}
                            <img
                              className="img_icon_size log "
                              src={require("../../static/images/info.png")}
                              alt="Reason"
                              style={{ height: "18px", width: "18px" }}
                              title="Security Deposit Amount Collected from the Members to SHG"
                            />{" "}
                            :
                          </label>
                          <input
                            type="number"
                            name="batchSecurityDepositMemToShg"
                            value={batchSecurityDepositMemToShg}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            SD Union To SHG{" "}
                            <img
                              className="img_icon_size log "
                              src={require("../../static/images/info.png")}
                              alt="Reason"
                              style={{ height: "18px", width: "18px" }}
                              title="	Security Deposit Amount Received from the Union to the SHG"
                            />{" "}
                            :
                          </label>
                          <RadioGroup
                            onChange={(e) => onRadioSDUSChange(e)}
                            className="radio-group patient-page"
                            horizontal
                            value={selectionSDUSType}
                            required
                          >
                            <RadioButton
                              padding={10}
                              rootColor="#144c5a"
                              pointColor="#144c5a"
                              iconSize={20}
                              iconInnerSize={10}
                              value="cash"
                            >
                              Cash
                            </RadioButton>
                            <RadioButton
                              padding={10}
                              rootColor="#144c5a"
                              pointColor="#144c5a"
                              iconSize={20}
                              iconInnerSize={10}
                              value="bank"
                            >
                              Bank
                            </RadioButton>
                          </RadioGroup>

                          {showSDUSSection && selectionSDUSType === "cash" && (
                            <input
                              type="number"
                              name="sdUnionToSHG"
                              value={sdUnionToSHG}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          )}
                          {showSDUSSection && selectionSDUSType === "bank" && (
                            <div className="row pt-2">
                              <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
                                <Select
                                  name="bankNames"
                                  options={activeBank}
                                  isSearchable={true}
                                  value={bankSDUS}
                                  placeholder="Select Bank"
                                  onChange={(e) => onBankChangeSDUS(e)}
                                />
                              </div>
                              <div className="col-md-6 col-lg-6 col-sm-12 col-12 no_padding ">
                                <input
                                  type="number"
                                  name="sdUnionToSHG"
                                  value={sdUnionToSHG}
                                  className="form-control"
                                  onWheel={() => document.activeElement.blur()}
                                  onChange={(e) => onInputChange(e)}
                                  onKeyDown={(e) => funcKeyDown(e)}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        {allPermission &&
                        allPermission.includes("SHG-to-SHGLoan") ? (
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Loan Repaid By Other SHG :
                            </label>
                            <input
                              type="number"
                              name="loanRepaidByOtherSHG"
                              value={loanRepaidByOtherSHG}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <input
                            type="submit"
                            name="submit"
                            value="Next"
                            className="btn sub_form btn_continue Save float-right"
                          />
                          <button
                            className="btn sub_form btn_continue Save float-right"
                            onClick={() =>
                              NextBackBtn(
                                allPermission.includes("Screenshot") ? 1 : 0
                              )
                            }
                          >
                            Previous
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </TabPanel>
              <TabPanel tabId="3">
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <div className="row card-new  py-3">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5>Expense</h5>
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Travelling Expense :
                        </label>
                        <input
                          type="number"
                          name="travellingExpenses"
                          value={travellingExpenses}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Stationary Expense :
                        </label>
                        <input
                          type="number"
                          name="stationaryExpenses"
                          value={stationaryExpenses}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Paid to Union (Mahasangha){" "}
                          <img
                            className="img_icon_size log "
                            src={require("../../static/images/info.png")}
                            alt="Reason"
                            style={{ height: "18px", width: "18px" }}
                            title="	Amount Paid /Given to the Union/Mahasanga/Federation/General HUB"
                          />{" "}
                          :
                        </label>

                        <input
                          type="number"
                          name="paidToMahasangha"
                          value={paidToMahasangha}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Service Charge :
                        </label>
                        <input
                          type="number"
                          name="serviceCharges"
                          value={serviceCharges}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Dividend Distributed{" "}
                          <img
                            className="img_icon_size log "
                            src={require("../../static/images/info.png")}
                            alt="Reason"
                            style={{ height: "18px", width: "18px" }}
                            title="Profit Distribution to Members"
                          />{" "}
                          :
                        </label>
                        <input
                          type="number"
                          name="batchDividendDistributed"
                          value={batchDividendDistributed}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">Other Expense :</label>
                        <input
                          type="number"
                          name="otherExpenses"
                          value={otherExpenses}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Savings Withdrawal{" "}
                          <img
                            className="img_icon_size log "
                            src={require("../../static/images/info.png")}
                            alt="Reason"
                            style={{ height: "18px", width: "18px" }}
                            title="Withdrawal of Saving Amount"
                          />{" "}
                          :
                        </label>
                        <input
                          type="number"
                          name="batchSavingWithdrawals"
                          value={batchSavingWithdrawals}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>

                      {allPermission &&
                      allPermission.includes("WithdrawalPersonName") ? (
                        <>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Withdrawal Person Name :
                            </label>
                            <input
                              type="text"
                              name="WithdrawalPerson"
                              value={WithdrawalPerson}
                              className="form-control"
                              // onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              // onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          SD SHG To Member{" "}
                          <img
                            className="img_icon_size log "
                            src={require("../../static/images/info.png")}
                            alt="Reason"
                            style={{ height: "18px", width: "18px" }}
                            title="Security Deposit Amount Received from SHG to Members"
                          />{" "}
                          :
                        </label>
                        <input
                          type="number"
                          name="batchSecurityDeposit"
                          value={batchSecurityDeposit}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) =>
                            (e.keyCode === 69 || e.keyCode === 190) &&
                            e.preventDefault()
                          }
                        />
                      </div>
                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Donation To Outsider{" "}
                          <img
                            className="img_icon_size log "
                            src={require("../../static/images/info.png")}
                            alt="Reason"
                            style={{ height: "18px", width: "18px" }}
                            title="	Donation/Contribution to the Outside from the SHG"
                          />{" "}
                          :
                        </label>
                        <input
                          type="number"
                          name="donationToOutsider"
                          value={donationToOutsider}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) =>
                            (e.keyCode === 69 || e.keyCode === 190) &&
                            e.preventDefault()
                          }
                        />
                      </div>

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          SD SHG To Union{" "}
                          <img
                            className="img_icon_size log "
                            src={require("../../static/images/info.png")}
                            alt="Reason"
                            style={{ height: "18px", width: "18px" }}
                            title="	Security Deposit Amount from SHG to Union"
                          />{" "}
                          :
                        </label>
                        <RadioGroup
                          onChange={(e) => onRadioSDSUChange(e)}
                          className="radio-group patient-page"
                          horizontal
                          value={selectionSDSUType}
                          required
                        >
                          <RadioButton
                            padding={10}
                            rootColor="#144c5a"
                            pointColor="#144c5a"
                            iconSize={20}
                            iconInnerSize={10}
                            value="cash"
                          >
                            Cash
                          </RadioButton>
                          <RadioButton
                            padding={10}
                            rootColor="#144c5a"
                            pointColor="#144c5a"
                            iconSize={20}
                            iconInnerSize={10}
                            value="bank"
                          >
                            Bank
                          </RadioButton>
                        </RadioGroup>

                        {showSDSUSection && selectionSDSUType === "cash" && (
                          <input
                            type="number"
                            name="sdSHGToUnion"
                            value={sdSHGToUnion}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        )}
                        {showSDSUSection && selectionSDSUType === "bank" && (
                          <div className="row pt-2">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
                              <Select
                                name="bankNames"
                                options={activeBank}
                                isSearchable={true}
                                value={bankSDSU}
                                placeholder="Select Bank"
                                onChange={(e) => onBankChangeSDSU(e)}
                              />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-12 no_padding ">
                              <input
                                type="number"
                                name="sdSHGToUnion"
                                value={sdSHGToUnion}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {allPermission &&
                      allPermission.includes("SHG-to-SHGLoan") ? (
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Loan Given To Other SHG :
                          </label>
                          <input
                            type="number"
                            name="loanGivenToOtherSHG"
                            value={loanGivenToOtherSHG}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <button
                          className="btn sub_form btn_continue Save float-right"
                          onClick={() => NextBackBtn(4)}
                        >
                          Next
                        </button>
                        <button
                          className="btn sub_form btn_continue Save float-right"
                          onClick={() => NextBackBtn(2)}
                        >
                          Previous
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel tabId="4">
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                    {activeOtherLoan.length > 1 ? (
                      <>
                        <form onSubmit={(e) => onAddOLR(e)}>
                          <div className="row card-new  py-3">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                              <h5>Other Loan Repayment</h5>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <label
                                className="label-control"
                                style={OtherLoanErrorStyle}
                              >
                                OL Types* :
                              </label>

                              <Select
                                name="otherLoanTypes"
                                options={activeOtherLoan}
                                isSearchable={true}
                                value={otherLoanType}
                                placeholder="Select Mode"
                                onChange={(e) => onotherLoanTypeChange(e)}
                                theme={(theme) => ({
                                  ...theme,
                                  height: 26,
                                  minHeight: 26,
                                  borderRadius: 1,
                                  colors: {
                                    ...theme.colors,
                                    primary: "black",
                                  },
                                })}
                              />
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                OL Paid To SHG{" "}
                                <img
                                  className="img_icon_size log "
                                  src={require("../../static/images/info.png")}
                                  alt="Reason"
                                  style={{ height: "18px", width: "18px" }}
                                  title="	Other Loan Repayment to SHG from Members"
                                />{" "}
                                :
                              </label>
                              <input
                                type="number"
                                name="otherLoanPaidToShg"
                                value={otherLoanPaidToShg}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                              />
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                OL Interest Paid To SHG{" "}
                                <img
                                  className="img_icon_size log "
                                  src={require("../../static/images/info.png")}
                                  alt="Reason"
                                  style={{ height: "18px", width: "18px" }}
                                  title="Other Loan Interest Repayment to SHG from Members"
                                />{" "}
                                :
                              </label>
                              <input
                                type="number"
                                name="batchOtherLoanInterestShg"
                                value={batchOtherLoanInterestShg}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                              />
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                Subsidy Received To SHG :
                              </label>
                              <RadioGroup
                                // onChange={(e) => setSelectionType(e)}
                                onChange={(e) =>
                                  onRadioSubsidyReceivedChange(e)
                                }
                                className="radio-group patient-page"
                                horizontal
                                value={selectionType}
                                required
                              >
                                <RadioButton
                                  padding={10}
                                  rootColor="#144c5a"
                                  pointColor="#144c5a"
                                  iconSize={20}
                                  iconInnerSize={10}
                                  value="cash"
                                  // disabled={isInputDisabled}
                                >
                                  Cash
                                </RadioButton>
                                <RadioButton
                                  padding={10}
                                  rootColor="#144c5a"
                                  pointColor="#144c5a"
                                  iconSize={20}
                                  iconInnerSize={10}
                                  value="bank"
                                  //disabled={isInputDisabled}
                                >
                                  Bank
                                </RadioButton>
                              </RadioGroup>

                              {showSubsidyReceivedSection &&
                                selectionType === "cash" && (
                                  <input
                                    type="number"
                                    name="subsidyReceived"
                                    value={subsidyReceived}
                                    className="form-control"
                                    onWheel={() =>
                                      document.activeElement.blur()
                                    }
                                    onChange={(e) => onInputChange(e)}
                                    onKeyDown={(e) => funcKeyDown(e)}
                                  />
                                )}
                              {showSubsidyReceivedSection &&
                                selectionType === "bank" && (
                                  <div className="row pt-2">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
                                      <Select
                                        name="bankNames"
                                        options={activeBank}
                                        isSearchable={true}
                                        value={bank}
                                        placeholder="Select Bank"
                                        onChange={(e) => onBankChange(e)}
                                      />
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12 col-12 no_padding ">
                                      <input
                                        type="number"
                                        name="subsidyReceived"
                                        value={subsidyReceived}
                                        className="form-control"
                                        onWheel={() =>
                                          document.activeElement.blur()
                                        }
                                        onChange={(e) => onInputChange(e)}
                                        onKeyDown={(e) => funcKeyDown(e)}
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                OL Sanctioned To SHG{" "}
                                <img
                                  className="img_icon_size log "
                                  src={require("../../static/images/info.png")}
                                  alt="Reason"
                                  style={{ height: "18px", width: "18px" }}
                                  title="	Other Loan Sanctioned to SHG"
                                />{" "}
                                :
                              </label>
                              <RadioGroup
                                // onChange={(e) => setSelectionType1(e)}
                                onChange={(e) =>
                                  onRadioOtherLoanSanctionedToShgChange(e)
                                }
                                className="radio-group patient-page"
                                horizontal
                                value={selectionType1}
                                required
                              >
                                <RadioButton
                                  padding={10}
                                  rootColor="#144c5a"
                                  pointColor="#144c5a"
                                  iconSize={20}
                                  iconInnerSize={10}
                                  value="cash"
                                  // disabled={isInputDisabled}
                                >
                                  Cash
                                </RadioButton>
                                <RadioButton
                                  padding={10}
                                  rootColor="#144c5a"
                                  pointColor="#144c5a"
                                  iconSize={20}
                                  iconInnerSize={10}
                                  value="bank"
                                  //disabled={isInputDisabled}
                                >
                                  Bank
                                </RadioButton>
                              </RadioGroup>
                              {showotherLoanSanctionedToShgSection &&
                                selectionType1 === "cash" && (
                                  <input
                                    type="number"
                                    name="otherLoanSanctionedToShg"
                                    value={otherLoanSanctionedToShg}
                                    className="form-control"
                                    onWheel={() =>
                                      document.activeElement.blur()
                                    }
                                    onChange={(e) => onInputChange(e)}
                                    onKeyDown={(e) => funcKeyDown(e)}
                                  />
                                )}
                              {showotherLoanSanctionedToShgSection &&
                                selectionType1 === "bank" && (
                                  <div className="row pt-2">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
                                      <Select
                                        name="bankNames"
                                        options={activeBank}
                                        isSearchable={true}
                                        value={bank2}
                                        placeholder="Select Bank"
                                        onChange={(e) => onBankChange2(e)}
                                      />
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12 col-12 no_padding ">
                                      <input
                                        type="number"
                                        name="otherLoanSanctionedToShg"
                                        value={otherLoanSanctionedToShg}
                                        className="form-control"
                                        onWheel={() =>
                                          document.activeElement.blur()
                                        }
                                        onChange={(e) => onInputChange(e)}
                                        onKeyDown={(e) => funcKeyDown(e)}
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                OL Interest Paid To Donor{" "}
                                <img
                                  className="img_icon_size log "
                                  src={require("../../static/images/info.png")}
                                  alt="Reason"
                                  style={{ height: "18px", width: "18px" }}
                                  title="	Other Loan Interest Repaid to the Donors"
                                />{" "}
                                :
                              </label>
                              <RadioGroup
                                onChange={(e) => onRadioOLIPDChange(e)}
                                className="radio-group patient-page"
                                horizontal
                                value={selectionOLIPDType}
                                required
                              >
                                <RadioButton
                                  padding={10}
                                  rootColor="#144c5a"
                                  pointColor="#144c5a"
                                  iconSize={20}
                                  iconInnerSize={10}
                                  value="cash"
                                >
                                  Cash
                                </RadioButton>
                                <RadioButton
                                  padding={10}
                                  rootColor="#144c5a"
                                  pointColor="#144c5a"
                                  iconSize={20}
                                  iconInnerSize={10}
                                  value="bank"
                                >
                                  Bank
                                </RadioButton>
                              </RadioGroup>
                              {showOLIPDSection &&
                                selectionOLIPDType === "cash" && (
                                  <input
                                    type="number"
                                    name="batchOtherLoanInterestDonor"
                                    value={batchOtherLoanInterestDonor}
                                    className="form-control"
                                    onWheel={() =>
                                      document.activeElement.blur()
                                    }
                                    onChange={(e) => onInputChange(e)}
                                    onKeyDown={(e) => funcKeyDown(e)}
                                  />
                                )}
                              {showOLIPDSection &&
                                selectionOLIPDType === "bank" && (
                                  <div className="row pt-2">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
                                      <Select
                                        name="bankNames"
                                        options={activeBank}
                                        isSearchable={true}
                                        value={bankOLIPD}
                                        placeholder="Select Bank"
                                        onChange={(e) => onBankChangeOLIPD(e)}
                                      />
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12 col-12 no_padding ">
                                      <input
                                        type="number"
                                        name="batchOtherLoanInterestDonor"
                                        value={batchOtherLoanInterestDonor}
                                        className="form-control"
                                        onWheel={() =>
                                          document.activeElement.blur()
                                        }
                                        onChange={(e) => onInputChange(e)}
                                        onKeyDown={(e) => funcKeyDown(e)}
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                Subsidy Distributed :
                              </label>
                              <input
                                type="number"
                                name="subsidyDistributed"
                                value={subsidyDistributed}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                              />
                            </div>

                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                              <label className="label-control">
                                Other Loan Paid To Donor{" "}
                                <img
                                  className="img_icon_size log "
                                  src={require("../../static/images/info.png")}
                                  alt="Reason"
                                  style={{ height: "18px", width: "18px" }}
                                  title="	Other Loan Repaid to the Donors"
                                />{" "}
                                :
                              </label>
                              <RadioGroup
                                onChange={(e) => onRadioOLPDChange(e)}
                                className="radio-group patient-page"
                                horizontal
                                value={selectionOLPDType}
                                required
                              >
                                <RadioButton
                                  padding={10}
                                  rootColor="#144c5a"
                                  pointColor="#144c5a"
                                  iconSize={20}
                                  iconInnerSize={10}
                                  value="cash"
                                >
                                  Cash
                                </RadioButton>
                                <RadioButton
                                  padding={10}
                                  rootColor="#144c5a"
                                  pointColor="#144c5a"
                                  iconSize={20}
                                  iconInnerSize={10}
                                  value="bank"
                                >
                                  Bank
                                </RadioButton>
                              </RadioGroup>
                              {showOLPDSection &&
                                selectionOLPDType === "cash" && (
                                  <input
                                    type="number"
                                    name="otherLoanPaidToDonor"
                                    value={otherLoanPaidToDonor}
                                    className="form-control"
                                    onWheel={() =>
                                      document.activeElement.blur()
                                    }
                                    onChange={(e) => onInputChange(e)}
                                    onKeyDown={(e) => funcKeyDown(e)}
                                  />
                                )}
                              {showOLPDSection && selectionOLPDType === "bank" && (
                                <div className="row pt-2">
                                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
                                    <Select
                                      name="bankNames"
                                      options={activeBank}
                                      isSearchable={true}
                                      value={bankOLPD}
                                      placeholder="Select Bank"
                                      onChange={(e) => onBankChangeOLPD(e)}
                                    />
                                  </div>
                                  <div className="col-md-6 col-lg-6 col-sm-12 col-12 no_padding ">
                                    <input
                                      type="number"
                                      name="otherLoanPaidToDonor"
                                      value={otherLoanPaidToDonor}
                                      className="form-control"
                                      onWheel={() =>
                                        document.activeElement.blur()
                                      }
                                      onChange={(e) => onInputChange(e)}
                                      onKeyDown={(e) => funcKeyDown(e)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                              <input
                                type="submit"
                                name="Submit"
                                value="ADD"
                                className="btn sub_form btn_continue blackbrd Save float-right"
                              />
                            </div>
                          </div>
                          <div className=" body-inner no-padding  table-responsive">
                            <table
                              className="tabllll table table-bordered table-striped table-hover"
                              id="datatable2"
                            >
                              <thead>
                                <tr>
                                  <th>OL Type</th>
                                  <th>OL Paid To SHG</th>
                                  <th>OL Interest SHG</th>
                                  <th>Subsidy Received</th>
                                  <th>OL Sanctioned To SHG</th>
                                  <th>OL Interest Donor</th>
                                  <th>Subsidy Distributed</th>
                                  <th>OL Paid To Donor</th>
                                  <th>Remove</th>
                                </tr>
                              </thead>
                              <tbody>
                                {AddedDetailsOLR &&
                                  AddedDetailsOLR.map((AddDetail, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td>{AddDetail.otherLoanType}</td>
                                        <td>{AddDetail.otherLoanPaidToShg}</td>
                                        <td>
                                          {AddDetail.batchOtherLoanInterestShg}
                                        </td>
                                        <td>
                                          {AddDetail.subsidyReceived} (
                                          {AddDetail.bank
                                            ? AddDetail.bank
                                            : "Cash"}
                                          )
                                        </td>
                                        <td>
                                          {AddDetail.otherLoanSanctionedToShg} (
                                          {AddDetail.bank2
                                            ? AddDetail.bank2
                                            : "Cash"}
                                          )
                                        </td>
                                        <td>
                                          {
                                            AddDetail.batchOtherLoanInterestDonor
                                          }{" "}
                                          (
                                          {AddDetail.bankOLIPD
                                            ? AddDetail.bankOLIPD
                                            : "Cash"}
                                          )
                                        </td>
                                        <td>{AddDetail.subsidyDistributed}</td>
                                        <td>
                                          {AddDetail.otherLoanPaidToDonor} (
                                          {AddDetail.bankOLPD
                                            ? AddDetail.bankOLPD
                                            : "Cash"}
                                          )
                                        </td>

                                        <td>
                                          <img
                                            className="img_icon_size log"
                                            onClick={() =>
                                              onRemoveChangeOLR(
                                                AddDetail.otherLoanTypeId
                                              )
                                            }
                                            src={require("../../static/images/close-buttonRed.png")}
                                            alt="Remove"
                                            title="Remove"
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>

                          <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                              <button
                                className="btn sub_form btn_continue Save float-right"
                                onClick={() => NextBackBtn(5)}
                              >
                                Next
                              </button>
                              <button
                                className="btn sub_form btn_continue Save float-right"
                                onClick={() => NextBackBtn(3)}
                              >
                                Previous
                              </button>
                            </div>
                          </div>
                        </form>
                      </>
                    ) : (
                      <>
                        <div className="row card-new  py-3">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h5>Other Loan Repayment</h5>
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              OL Paid To SHG{" "}
                              <img
                                className="img_icon_size log "
                                src={require("../../static/images/info.png")}
                                alt="Reason"
                                style={{ height: "18px", width: "18px" }}
                                title="	Other Loan Repayment to SHG from Members"
                              />{" "}
                              :
                            </label>
                            <input
                              type="number"
                              name="otherLoanPaidToShg"
                              value={otherLoanPaidToShg}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              OL Interest Paid To SHG{" "}
                              <img
                                className="img_icon_size log "
                                src={require("../../static/images/info.png")}
                                alt="Reason"
                                style={{ height: "18px", width: "18px" }}
                                title="	Other Loan Interest Repayment to SHG from Members"
                              />{" "}
                              :
                            </label>
                            <input
                              type="number"
                              name="batchOtherLoanInterestShg"
                              value={batchOtherLoanInterestShg}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Subsidy Received To SHG :
                            </label>
                            <RadioGroup
                              // onChange={(e) => setSelectionType(e)}
                              onChange={(e) => onRadioSubsidyReceivedChange(e)}
                              className="radio-group patient-page"
                              horizontal
                              value={selectionType}
                              required
                            >
                              <RadioButton
                                padding={10}
                                rootColor="#144c5a"
                                pointColor="#144c5a"
                                iconSize={20}
                                iconInnerSize={10}
                                value="cash"
                                // disabled={isInputDisabled}
                              >
                                Cash
                              </RadioButton>
                              <RadioButton
                                padding={10}
                                rootColor="#144c5a"
                                pointColor="#144c5a"
                                iconSize={20}
                                iconInnerSize={10}
                                value="bank"
                                //disabled={isInputDisabled}
                              >
                                Bank
                              </RadioButton>
                            </RadioGroup>

                            {showSubsidyReceivedSection && (
                              <input
                                type="number"
                                name="subsidyReceived"
                                value={subsidyReceived}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                              />
                            )}
                          </div>

                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              OL Sanctioned To SHG{" "}
                              <img
                                className="img_icon_size log "
                                src={require("../../static/images/info.png")}
                                alt="Reason"
                                style={{ height: "18px", width: "18px" }}
                                title="		Other Loan Sanctioned to SHG"
                              />
                              :
                            </label>
                            <RadioGroup
                              // onChange={(e) => setSelectionType1(e)}
                              onChange={(e) =>
                                onRadioOtherLoanSanctionedToShgChange(e)
                              }
                              className="radio-group patient-page"
                              horizontal
                              value={selectionType1}
                              required
                            >
                              <RadioButton
                                padding={10}
                                rootColor="#144c5a"
                                pointColor="#144c5a"
                                iconSize={20}
                                iconInnerSize={10}
                                value="cash"
                                // disabled={isInputDisabled}
                              >
                                Cash
                              </RadioButton>
                              <RadioButton
                                padding={10}
                                rootColor="#144c5a"
                                pointColor="#144c5a"
                                iconSize={20}
                                iconInnerSize={10}
                                value="bank"
                                //disabled={isInputDisabled}
                              >
                                Bank
                              </RadioButton>
                            </RadioGroup>

                            {showotherLoanSanctionedToShgSection && (
                              <input
                                type="number"
                                name="otherLoanSanctionedToShg"
                                value={otherLoanSanctionedToShg}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                              />
                            )}
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              OL Interest Paid To Donor{" "}
                              <img
                                className="img_icon_size log "
                                src={require("../../static/images/info.png")}
                                alt="Reason"
                                style={{ height: "18px", width: "18px" }}
                                title="	Other Loan Interest Repaid to the Donors"
                              />{" "}
                              :
                            </label>
                            <RadioGroup
                              onChange={(e) => onRadioOLIPDChange(e)}
                              className="radio-group patient-page"
                              horizontal
                              value={selectionOLIPDType}
                              required
                            >
                              <RadioButton
                                padding={10}
                                rootColor="#144c5a"
                                pointColor="#144c5a"
                                iconSize={20}
                                iconInnerSize={10}
                                value="cash"
                              >
                                Cash
                              </RadioButton>
                              <RadioButton
                                padding={10}
                                rootColor="#144c5a"
                                pointColor="#144c5a"
                                iconSize={20}
                                iconInnerSize={10}
                                value="bank"
                              >
                                Bank
                              </RadioButton>
                            </RadioGroup>
                            {showOLIPDSection && (
                              <input
                                type="number"
                                name="batchOtherLoanInterestDonor"
                                value={batchOtherLoanInterestDonor}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                              />
                            )}
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Subsidy Distributed :
                            </label>
                            <input
                              type="number"
                              name="subsidyDistributed"
                              value={subsidyDistributed}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              OL Paid To Donor{" "}
                              <img
                                className="img_icon_size log "
                                src={require("../../static/images/info.png")}
                                alt="Reason"
                                style={{ height: "18px", width: "18px" }}
                                title="	Other Loan Repaid to the Donors"
                              />
                              :
                            </label>
                            <RadioGroup
                              onChange={(e) => onRadioOLPDChange(e)}
                              className="radio-group patient-page"
                              horizontal
                              value={selectionOLPDType}
                              required
                            >
                              <RadioButton
                                padding={10}
                                rootColor="#144c5a"
                                pointColor="#144c5a"
                                iconSize={20}
                                iconInnerSize={10}
                                value="cash"
                              >
                                Cash
                              </RadioButton>
                              <RadioButton
                                padding={10}
                                rootColor="#144c5a"
                                pointColor="#144c5a"
                                iconSize={20}
                                iconInnerSize={10}
                                value="bank"
                              >
                                Bank
                              </RadioButton>
                            </RadioGroup>
                            {showOLPDSection && (
                              <input
                                type="number"
                                name="otherLoanPaidToDonor"
                                value={otherLoanPaidToDonor}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                              />
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <button
                              className="btn sub_form btn_continue Save float-right"
                              onClick={() => onAddOLRSingle(5)}
                            >
                              Next
                            </button>
                            <button
                              className="btn sub_form btn_continue Save float-right"
                              onClick={() => NextBackBtn(3)}
                            >
                              Previous
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel tabId="5">
                <div className="row col-md-12 col-lg-11 col-sm-12 col-12 ">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <form onSubmit={(e) => onAdd(e)}>
                      <div className="row card-new  py-3">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <h5>Loan Details</h5>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <h5>Remaining Loan : {remainingAmt.toFixed(2)} </h5>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label
                            className="label-control"
                            style={MemberIdErrorStyle}
                          >
                            Loan Sanctioned-Member* :
                          </label>
                          <Select
                            name="MemberName"
                            options={allMember}
                            isSearchable={true}
                            value={member}
                            placeholder="Select member"
                            onChange={(e) => onMemberChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                            required
                          />
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            IL Sanctioned To Member*{" "}
                            <img
                              className="img_icon_size log "
                              src={require("../../static/images/info.png")}
                              alt="Reason"
                              style={{ height: "18px", width: "18px" }}
                              title="Internal Loan Sanctioned to Member"
                            />{" "}
                            :{" "}
                          </label>
                          <input
                            type="number"
                            name="batchLoanAmt"
                            value={batchLoanAmt}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange1(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                            required
                          />
                        </div>
                        <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                          <label className="label-control">Nominee :</label>
                          <input
                            type="text"
                            name="internalNominee"
                            value={internalNominee}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange1(e)}
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label
                            className="label-control"
                            style={PurposeErrorStyle}
                          >
                            Loan Sanctioned Purpose :
                          </label>
                          <Select
                            name="loanPurpose"
                            options={allpurpose}
                            isSearchable={true}
                            value={purpose}
                            placeholder="Select Purpose"
                            onChange={(e) => onPurposeChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>
                        {showotherPurposeSection && (
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Reason* :
                            </label>
                            <input
                              type="text"
                              name="otherPurpose"
                              value={otherPurpose}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                              required
                            />
                          </div>
                        )}
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          {/* <button
                            variant="success"
                            className="btn sub_form btn_continue Save float-right"
                            onClick={() => onAdd()}
                          >
                            ADD
                          </button> */}

                          <input
                            type="submit"
                            name="Submit"
                            value="ADD"
                            className="btn sub_form btn_continue blackbrd Save float-right"
                          />
                        </div>
                      </div>
                    </form>
                    <div className=" body-inner no-padding  table-responsive">
                      <table
                        className="tabllll table table-bordered table-striped table-hover"
                        id="datatable2"
                      >
                        <thead>
                          <tr>
                            <th>Loan Sanctioned-Member</th>
                            <th>Loan Sanctioned Amount to Member</th>
                            <th>Nominee</th>
                            <th>Loan Sanctioned Purpose </th>
                            <th>Loan Other Purpose </th>
                            <th>Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {AddedDetails &&
                            AddedDetails.map((AddDetail, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>{AddDetail.loanSanctionedMember}</td>
                                  <td>{AddDetail.batchLoanAmt}</td>
                                  <td>{AddDetail.internalNominee}</td>
                                  <td>{AddDetail.loanSanctionedPurpose}</td>

                                  <td>
                                    {AddDetail.loanSanctionedotherPurpose}
                                  </td>

                                  <td>
                                    <img
                                      className="img_icon_size log"
                                      onClick={() =>
                                        onRemoveChange(
                                          AddDetail.loanSanctionedMember
                                        )
                                      }
                                      src={require("../../static/images/close-buttonRed.png")}
                                      alt="Remove"
                                      title="Remove"
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <button
                        className="btn sub_form btn_continue Save float-right"
                        onClick={() => NextBackBtn(6)}
                      >
                        Next
                      </button>
                      <button
                        className="btn sub_form btn_continue Save float-right"
                        onClick={() => NextBackBtn(4)}
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel tabId="6">
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <form onSubmit={(e) => onAddBankLoan(e)}>
                      <div className="row card-new  py-3">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <h5>Other Loan Details</h5>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <h5>
                            Remaining Other Loan : {otherLoanRemainingAmt}{" "}
                          </h5>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label
                            className="label-control"
                            style={otherloantypeErrorStyle}
                          >
                            OL Types* :
                          </label>

                          <Select
                            name="otherLoanTypes"
                            options={activeOtherLoan}
                            isSearchable={true}
                            value={otherLoanType}
                            placeholder="Select Mode"
                            onChange={(e) => onotherLoanTypeChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label
                            className="label-control"
                            style={OtherMemberIdErrorStyle}
                          >
                            OL Sanctioned To Member*{" "}
                            <img
                              className="img_icon_size log "
                              src={require("../../static/images/info.png")}
                              alt="Reason"
                              style={{ height: "18px", width: "18px" }}
                              title="Other Loan Sancationed to Member"
                            />{" "}
                            :
                          </label>
                          <Select
                            name="MemberName2"
                            options={allMembers}
                            isSearchable={true}
                            value={members}
                            placeholder="Select member"
                            onChange={(e) => onMemberChanged(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            OL Sanctioned Amount*{" "}
                            <img
                              className="img_icon_size log "
                              src={require("../../static/images/info.png")}
                              alt="Reason"
                              style={{ height: "18px", width: "18px" }}
                              title="Other Loan Sanctioned Amount to Member"
                            />{" "}
                            :{" "}
                          </label>
                          <input
                            type="number"
                            name="batchOtherLoanAmts"
                            value={batchOtherLoanAmts}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">Nominee :</label>
                          <input
                            type="text"
                            name="externalNominee"
                            value={externalNominee}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label
                            className="label-control"
                            style={otherPurposeErrorStyle}
                          >
                            Other Loan Sanctioned Purpose* :
                          </label>

                          <Select
                            name="loanPurpose"
                            options={allpurpose}
                            isSearchable={true}
                            value={otherloanpurpose}
                            placeholder="Select Purpose"
                            onChange={(e) => onBankLoanPurposeChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>
                        {showbankotherPurposeSection && (
                          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Other Reason* :
                            </label>
                            <input
                              type="text"
                              name="bankloanOtherReason"
                              value={bankloanOtherReason}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                              required
                            />
                          </div>
                        )}
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          {/* <button
                            variant="success"
                            className="btn sub_form btn_continue Save float-right"
                            onClick={() => onAddBankLoan()}
                          >
                            ADD
                          </button> */}
                          <input
                            type="submit"
                            name="Submit"
                            value="ADD"
                            className="btn sub_form btn_continue blackbrd Save float-right"
                          />
                        </div>
                      </div>
                    </form>
                    <div className=" body-inner no-padding  table-responsive">
                      <table
                        className="tabllll table table-bordered table-striped table-hover"
                        id="datatable2"
                      >
                        <thead>
                          <tr>
                            <th>Other Loan Type </th>
                            <th>Loan Sanctioned-Member</th>
                            <th>Loan Sanctioned Amount to Member</th>
                            <th>Nominee</th>
                            <th>Loan Sanctioned Purpose </th>
                            <th>Loan Sanctioned Other Purpose </th>
                            <th>Op</th>
                          </tr>
                        </thead>
                        <tbody>
                          {BankLoanAddedDetails &&
                            BankLoanAddedDetails.map(
                              (BankLoanAddDetails, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{BankLoanAddDetails.otherLoanType}</td>
                                    <td>
                                      {
                                        BankLoanAddDetails.otherloanSanctionedMember
                                      }
                                    </td>
                                    <td>
                                      {
                                        BankLoanAddDetails.otherbatchOtherLoanAmt
                                      }
                                    </td>
                                    <td>
                                      {BankLoanAddDetails.externalNominee}
                                    </td>
                                    <td>
                                      {
                                        BankLoanAddDetails.otherloanSanctionedPurpose
                                      }
                                    </td>
                                    <td>
                                      {
                                        BankLoanAddDetails.otherloanSanctionedotherPurpose
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      <img
                                        className="img_icon_size log"
                                        onClick={() =>
                                          onRemoveChangeBankLoan(
                                            BankLoanAddDetails.otherloanSanctionedMember
                                          )
                                        }
                                        src={require("../../static/images/close-buttonRed.png")}
                                        alt="Remove"
                                        title="Remove"
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                    {/* </form> */}
                    {/* <form className="row" onSubmit={(e) => NextBackBtn(4)}> */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <input
                        type="submit"
                        name="submit"
                        value="Next"
                        onClick={() => NextBackBtn(7)}
                        className="btn sub_form btn_continue Save float-right"
                      />
                      <button
                        className="btn sub_form btn_continue Save float-right"
                        onClick={() => NextBackBtn(5)}
                      >
                        Previous
                      </button>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </TabPanel>
              <TabPanel tabId="7">
                {/* <form className="row" onSubmit={(e) => onSubmit(e)}> */}
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    {activeBank.length > 1 ? (
                      <form onSubmit={(e) => onAddBank(e)}>
                        {/* */}
                        <div className="row col-lg-12 col-md-12 col-sm-12 col-12 card-new py-3">
                          <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <h5> Add Bank Details</h5>
                          </div>
                          <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
                            <span>
                              Income : {batchSavingAmt ? batchSavingAmt : ""}
                              {batchSubAmt ? "+" + batchSubAmt : ""}
                              {batchOtherContribution
                                ? "+" + batchOtherContribution
                                : ""}
                              {batchMembershipFees
                                ? "+" + batchMembershipFees
                                : ""}
                              {batchLoanPaid ? "+" + batchLoanPaid : ""}
                              {batchInterestPaid ? "+" + batchInterestPaid : ""}
                              {otherLoanPaidToShg
                                ? "+" + otherLoanPaidToShg
                                : ""}
                              {batchOtherLoanInterestShg
                                ? "+" + batchOtherLoanInterestShg
                                : ""}
                              {deactiveMemberSaving
                                ? "+" + deactiveMemberSaving
                                : ""}
                              {subsidyReceived && subsidyReceivedType === "cash"
                                ? "+" + subsidyReceived
                                : ""}
                              {batchOtherIncome ? "+" + batchOtherIncome : ""}
                              {seedMoney ? "+" + seedMoney : ""}
                              {loanRepaidByOtherSHG
                                ? "+" + loanRepaidByOtherSHG
                                : ""}
                              {batchSecurityDepositMemToShg
                                ? "+" + batchSecurityDepositMemToShg
                                : ""}
                              {otherLoanSanctionedToShg &&
                              otherLoanSanctionedToShgType === "cash"
                                ? "+" + otherLoanSanctionedToShg
                                : ""}
                              {selectionSDUSType === "cash" && sdUnionToSHG
                                ? "+" + sdUnionToSHG
                                : ""}
                              ={" "}
                              {Number(batchSavingAmt) +
                                Number(batchSubAmt) +
                                Number(batchOtherContribution) +
                                Number(batchMembershipFees) +
                                Number(batchLoanPaid) +
                                Number(batchInterestPaid) +
                                Number(otherLoanPaidToShg) +
                                Number(batchOtherLoanInterestShg) +
                                Number(deactiveMemberSaving) +
                                Number(
                                  subsidyReceivedType === "cash"
                                    ? subsidyReceived
                                    : 0
                                ) +
                                Number(batchOtherIncome) +
                                Number(seedMoney) +
                                Number(loanRepaidByOtherSHG) +
                                Number(batchSecurityDepositMemToShg) +
                                Number(
                                  otherLoanSanctionedToShgType === "cash"
                                    ? otherLoanSanctionedToShg
                                    : 0
                                ) +
                                Number(
                                  selectionSDUSType === "cash"
                                    ? sdUnionToSHG
                                    : 0
                                )}
                            </span>
                          </div>
                          <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
                            <span>
                              Expense :
                              {travellingExpenses ? travellingExpenses : ""}
                              {stationaryExpenses
                                ? "+" + stationaryExpenses
                                : ""}
                              {paidToMahasangha ? "+" + paidToMahasangha : ""}
                              {serviceCharges ? "+" + serviceCharges : ""}
                              {batchDividendDistributed
                                ? "+" + batchDividendDistributed
                                : ""}
                              {otherExpenses ? "+" + otherExpenses : ""}
                              {loanGivenToOtherSHG
                                ? "+" + loanGivenToOtherSHG
                                : ""}
                              {batchSavingWithdrawals
                                ? "+" + batchSavingWithdrawals
                                : ""}
                              {batchSecurityDeposit
                                ? "+" + batchSecurityDeposit
                                : ""}
                              {batchOtherLoanInterestDonor
                                ? "+" + batchOtherLoanInterestDonor
                                : ""}
                              {subsidyDistributed
                                ? "+" + subsidyDistributed
                                : ""}
                              {otherLoanPaidToDonor
                                ? "+" + otherLoanPaidToDonor
                                : ""}
                              {selectionSDSUType === "cash" && sdSHGToUnion
                                ? "+" + sdSHGToUnion
                                : ""}
                              ={" "}
                              {Number(travellingExpenses) +
                                Number(stationaryExpenses) +
                                Number(paidToMahasangha) +
                                Number(serviceCharges) +
                                Number(batchDividendDistributed) +
                                Number(otherExpenses) +
                                Number(loanGivenToOtherSHG) +
                                Number(batchSavingWithdrawals) +
                                Number(batchSecurityDeposit) +
                                Number(batchOtherLoanInterestDonor) +
                                Number(subsidyDistributed) +
                                Number(otherLoanPaidToDonor) +
                                Number(
                                  selectionSDSUType === "cash"
                                    ? sdSHGToUnion
                                    : 0
                                )}
                            </span>
                          </div>
                          <div className="col-lg-2 col-md-12 col-sm-12 col-12 py-2">
                            <span>
                              Donation :
                              {donationToOutsider ? donationToOutsider : ""}
                            </span>
                          </div>

                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Cash In Hand :
                            </label>
                            <input
                              type="number"
                              name="cashInHand"
                              value={cashInHand}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label
                              className="label-control"
                              style={bankErrorStyle}
                            >
                              Banks* :
                            </label>
                            <Select
                              name="bankNames"
                              options={activeBank}
                              isSearchable={true}
                              value={bank}
                              placeholder="Select Mode"
                              onChange={(e) => onBankChange(e)}
                              theme={(theme) => ({
                                ...theme,
                                height: 26,
                                minHeight: 26,
                                borderRadius: 1,
                                colors: {
                                  ...theme.colors,
                                  primary: "black",
                                },
                              })}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Bank Interest :
                            </label>
                            <input
                              type="number"
                              name="bankGridInterest"
                              value={bankGridInterest}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Bank Commission :
                            </label>
                            <input
                              type="number"
                              name="bankGridCommission"
                              value={bankGridCommission}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12 ">
                            <label className="label-control">
                              Bank Deposit* :
                            </label>
                            <input
                              type="number"
                              name="bankGridDeposit"
                              value={bankGridDeposit}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                              required
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Bank Withdrawal :
                            </label>
                            <input
                              type="number"
                              name="bankGridwithdrawal"
                              value={bankGridwithdrawal}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <input
                              type="submit"
                              name="Submit"
                              value="ADD"
                              className="btn sub_form btn_continue blackbrd Save float-right"
                            />
                          </div>
                        </div>
                        <div className=" body-inner no-padding  table-responsive">
                          <table
                            className="tabllll table table-bordered table-striped table-hover"
                            id="datatable2"
                          >
                            <thead>
                              <tr>
                                <th>Bank Name</th>
                                <th>Bank Interest</th>
                                <th>Bank Commission</th>
                                <th>Bank Deposit Amount</th>
                                {/* <th>Cash In Hand</th> */}
                                <th>Bank Withdrawal Amount</th>
                                <th>Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                              {AddedBankDetails &&
                                AddedBankDetails.map((BankDetail, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>{BankDetail.batchBankName}</td>
                                      <td>{BankDetail.indBankInterest}</td>
                                      <td>{BankDetail.indBankCommission}</td>
                                      <td>{BankDetail.indBatchBankDeposit}</td>
                                      {/* <td>{BankDetail.indcashInHand}</td> */}
                                      <td>
                                        {BankDetail.indBatchBankWithdrawals}
                                      </td>
                                      <td>
                                        <img
                                          className="img_icon_size log"
                                          onClick={() =>
                                            onRemoveChangeBank(
                                              BankDetail.bankGridName
                                            )
                                          }
                                          src={require("../../static/images/close-buttonRed.png")}
                                          alt="Remove"
                                          title="Remove"
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </form>
                    ) : activeBank.length === 1 ? (
                      <div className="row card-new  py-3">
                        <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <h5> Add Bank Details</h5>
                          </div>
                          <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
                            <span>
                              Income : {batchSavingAmt ? batchSavingAmt : ""}
                              {batchSubAmt ? "+" + batchSubAmt : ""}
                              {batchOtherContribution
                                ? "+" + batchOtherContribution
                                : ""}
                              {batchMembershipFees
                                ? "+" + batchMembershipFees
                                : ""}
                              {batchLoanPaid ? "+" + batchLoanPaid : ""}
                              {batchInterestPaid ? "+" + batchInterestPaid : ""}
                              {otherLoanPaidToShg
                                ? "+" + otherLoanPaidToShg
                                : ""}
                              {batchOtherLoanInterestShg
                                ? "+" + batchOtherLoanInterestShg
                                : ""}
                              {deactiveMemberSaving
                                ? "+" + deactiveMemberSaving
                                : ""}
                              {subsidyReceived && subsidyReceivedType === "cash"
                                ? "+" + subsidyReceived
                                : ""}
                              {batchOtherIncome ? "+" + batchOtherIncome : ""}
                              {seedMoney ? "+" + seedMoney : ""}
                              {loanRepaidByOtherSHG
                                ? "+" + loanRepaidByOtherSHG
                                : ""}
                              {batchSecurityDepositMemToShg
                                ? "+" + batchSecurityDepositMemToShg
                                : ""}
                              {otherLoanSanctionedToShg &&
                              otherLoanSanctionedToShgType === "cash"
                                ? "+" + otherLoanSanctionedToShg
                                : ""}
                              {selectionSDUSType === "cash" && sdUnionToSHG
                                ? "+" + sdUnionToSHG
                                : ""}
                              ={" "}
                              {Number(batchSavingAmt) +
                                Number(batchSubAmt) +
                                Number(batchOtherContribution) +
                                Number(batchMembershipFees) +
                                Number(batchLoanPaid) +
                                Number(batchInterestPaid) +
                                Number(otherLoanPaidToShg) +
                                Number(batchOtherLoanInterestShg) +
                                Number(deactiveMemberSaving) +
                                Number(
                                  subsidyReceivedType === "cash"
                                    ? subsidyReceived
                                    : 0
                                ) +
                                Number(batchOtherIncome) +
                                Number(seedMoney) +
                                Number(loanRepaidByOtherSHG) +
                                Number(batchSecurityDepositMemToShg) +
                                Number(
                                  otherLoanSanctionedToShgType === "cash"
                                    ? otherLoanSanctionedToShg
                                    : 0
                                ) +
                                Number(
                                  selectionSDUSType === "cash"
                                    ? sdUnionToSHG
                                    : 0
                                )}
                            </span>
                          </div>
                          <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
                            <span>
                              Expense :
                              {travellingExpenses ? travellingExpenses : ""}
                              {stationaryExpenses
                                ? "+" + stationaryExpenses
                                : ""}
                              {paidToMahasangha ? "+" + paidToMahasangha : ""}
                              {serviceCharges ? "+" + serviceCharges : ""}
                              {batchDividendDistributed
                                ? "+" + batchDividendDistributed
                                : ""}
                              {otherExpenses ? "+" + otherExpenses : ""}
                              {loanGivenToOtherSHG
                                ? "+" + loanGivenToOtherSHG
                                : ""}
                              {batchSavingWithdrawals
                                ? "+" + batchSavingWithdrawals
                                : ""}
                              {batchSecurityDeposit
                                ? "+" + batchSecurityDeposit
                                : ""}
                              {batchOtherLoanInterestDonor
                                ? "+" + batchOtherLoanInterestDonor
                                : ""}
                              {subsidyDistributed
                                ? "+" + subsidyDistributed
                                : ""}
                              {otherLoanPaidToDonor
                                ? "+" + otherLoanPaidToDonor
                                : ""}
                              {selectionSDSUType === "cash" && sdSHGToUnion
                                ? "+" + sdSHGToUnion
                                : ""}
                              ={" "}
                              {Number(travellingExpenses) +
                                Number(stationaryExpenses) +
                                Number(paidToMahasangha) +
                                Number(serviceCharges) +
                                Number(batchDividendDistributed) +
                                Number(otherExpenses) +
                                Number(loanGivenToOtherSHG) +
                                Number(batchSavingWithdrawals) +
                                Number(batchSecurityDeposit) +
                                Number(batchOtherLoanInterestDonor) +
                                Number(subsidyDistributed) +
                                Number(otherLoanPaidToDonor) +
                                Number(
                                  selectionSDSUType === "cash"
                                    ? sdSHGToUnion
                                    : 0
                                )}
                            </span>
                          </div>
                          <div className="col-lg-2 col-md-12 col-sm-12 col-12 py-2">
                            <span>
                              Donation :
                              {donationToOutsider ? donationToOutsider : ""}
                            </span>
                          </div>

                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Cash In Hand :
                            </label>
                            <input
                              type="number"
                              name="cashInHand"
                              value={cashInHand}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Bank Interest :
                            </label>
                            <input
                              type="number"
                              name="bankGridInterest"
                              value={bankGridInterest}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Bank Commission :
                            </label>
                            <input
                              type="number"
                              name="bankGridCommission"
                              value={bankGridCommission}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12 ">
                            <label className="label-control">
                              Bank Deposit* :
                            </label>
                            <input
                              type="number"
                              name="bankGridDeposit"
                              value={bankGridDeposit}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                              required
                            />
                          </div>
                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Bank Withdrawal :
                            </label>
                            <input
                              type="number"
                              name="bankGridwithdrawal"
                              value={bankGridwithdrawal}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange2(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                          {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <input
                              type="submit"
                              name="Submit"
                              value="ADD"
                              className="btn sub_form btn_continue blackbrd Save float-right"
                            />
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <div className="row card-new  py-3">
                        <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <h5> Add Bank Details</h5>
                          </div>
                          <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
                            <span>
                              Income : {batchSavingAmt ? batchSavingAmt : ""}
                              {batchSubAmt ? "+" + batchSubAmt : ""}
                              {batchOtherContribution
                                ? "+" + batchOtherContribution
                                : ""}
                              {batchMembershipFees
                                ? "+" + batchMembershipFees
                                : ""}
                              {batchLoanPaid ? "+" + batchLoanPaid : ""}
                              {batchInterestPaid ? "+" + batchInterestPaid : ""}
                              {otherLoanPaidToShg
                                ? "+" + otherLoanPaidToShg
                                : ""}
                              {batchOtherLoanInterestShg
                                ? "+" + batchOtherLoanInterestShg
                                : ""}
                              {deactiveMemberSaving
                                ? "+" + deactiveMemberSaving
                                : ""}
                              {subsidyReceived && subsidyReceivedType === "cash"
                                ? "+" + subsidyReceived
                                : ""}
                              {batchOtherIncome ? "+" + batchOtherIncome : ""}
                              {seedMoney ? "+" + seedMoney : ""}
                              {loanRepaidByOtherSHG
                                ? "+" + loanRepaidByOtherSHG
                                : ""}
                              {batchSecurityDepositMemToShg
                                ? "+" + batchSecurityDepositMemToShg
                                : ""}
                              {otherLoanSanctionedToShg &&
                              otherLoanSanctionedToShgType === "cash"
                                ? "+" + otherLoanSanctionedToShg
                                : ""}
                              {selectionSDUSType === "cash" && sdUnionToSHG
                                ? "+" + sdUnionToSHG
                                : ""}
                              ={" "}
                              {Number(batchSavingAmt) +
                                Number(batchSubAmt) +
                                Number(batchOtherContribution) +
                                Number(batchMembershipFees) +
                                Number(batchLoanPaid) +
                                Number(batchInterestPaid) +
                                Number(otherLoanPaidToShg) +
                                Number(batchOtherLoanInterestShg) +
                                Number(deactiveMemberSaving) +
                                Number(
                                  subsidyReceivedType === "cash"
                                    ? subsidyReceived
                                    : 0
                                ) +
                                Number(batchOtherIncome) +
                                Number(seedMoney) +
                                Number(loanRepaidByOtherSHG) +
                                Number(
                                  otherLoanSanctionedToShgType === "cash"
                                    ? otherLoanSanctionedToShg
                                    : 0
                                ) +
                                Number(
                                  selectionSDUSType === "cash"
                                    ? sdUnionToSHG
                                    : 0
                                )}
                            </span>
                          </div>
                          <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
                            <span>
                              Expense :
                              {travellingExpenses ? travellingExpenses : ""}
                              {stationaryExpenses
                                ? "+" + stationaryExpenses
                                : ""}
                              {paidToMahasangha ? "+" + paidToMahasangha : ""}
                              {serviceCharges ? "+" + serviceCharges : ""}
                              {batchDividendDistributed
                                ? "+" + batchDividendDistributed
                                : ""}
                              {otherExpenses ? "+" + otherExpenses : ""}
                              {loanGivenToOtherSHG
                                ? "+" + loanGivenToOtherSHG
                                : ""}
                              {batchSavingWithdrawals
                                ? "+" + batchSavingWithdrawals
                                : ""}
                              {batchSecurityDeposit
                                ? "+" + batchSecurityDeposit
                                : ""}
                              {batchOtherLoanInterestDonor
                                ? "+" + batchOtherLoanInterestDonor
                                : ""}
                              {subsidyDistributed
                                ? "+" + subsidyDistributed
                                : ""}
                              {otherLoanPaidToDonor
                                ? "+" + otherLoanPaidToDonor
                                : ""}
                              {selectionSDSUType === "cash" && sdSHGToUnion
                                ? "+" + sdSHGToUnion
                                : ""}
                              ={" "}
                              {Number(travellingExpenses) +
                                Number(stationaryExpenses) +
                                Number(paidToMahasangha) +
                                Number(serviceCharges) +
                                Number(batchDividendDistributed) +
                                Number(otherExpenses) +
                                Number(loanGivenToOtherSHG) +
                                Number(batchSavingWithdrawals) +
                                Number(batchSecurityDeposit) +
                                Number(batchOtherLoanInterestDonor) +
                                Number(subsidyDistributed) +
                                Number(otherLoanPaidToDonor) +
                                Number(
                                  selectionSDSUType === "cash"
                                    ? sdSHGToUnion
                                    : 0
                                )}
                            </span>
                          </div>
                          <div className="col-lg-2 col-md-12 col-sm-12 col-12 py-2">
                            <span>
                              Donation :
                              {donationToOutsider ? donationToOutsider : ""}
                            </span>
                          </div>

                          <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Cash In Hand :
                            </label>
                            <input
                              type="number"
                              name="cashInHand"
                              value={cashInHand}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      {loading ? (
                        <button
                          className="btn sub_form btn_continue blackbrd Save float-right"
                          disabled
                        >
                          Loading...
                        </button>
                      ) : (
                        <input
                          type="submit"
                          name="Save"
                          value="Submit"
                          onClick={(e) => onSubmit(e)}
                          className="btn sub_form btn_continue Save float-right"
                          style={
                            meetingResponse === "Meeting Available"
                              ? { opacity: "1" }
                              : {
                                  opacity: "1",
                                  pointerEvents: "none",
                                  color: "red",
                                }
                          }
                        />
                      )}
                      <button
                        className="btn sub_form btn_continue Save float-right"
                        onClick={() => NextBackBtn(6)}
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                </div>
                {/* </form> */}
              </TabPanel>
            </Tabs>
          </section>
        ) : (
          <Fragment />
        )}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <label className="label-control colorRed">
            * Indicates mandatory fields, Please fill mandatory fields before
            Submit
          </label>
        </div>
      </div>
      {showImage && (
        <ImgPreview
          show={showImage}
          setShow={setShowImage}
          //data={meetingPhotoUpload1}
          data={pertImg}
        />
      )}
    </Fragment>
  );
};

AddBatchMeetingTest.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  BatchTransaction,
  meetingDates,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  checkAvailableDate,
  removeMessage,
  getMeetingSummary,
  AddLoanSanctionedDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  sendMettingFile,
})(AddBatchMeetingTest);
