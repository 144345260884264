import React from "react";
import { Modal } from "react-bootstrap";

const GovtCards = ({
  showCardsModal,
  handleCardsModalClose,
  setSelectedCards,
  SelectedCards,
}) => {
  const cradsList = [
    "Aadhaar card",
    "Ration card",
    "Voters ID",
    "Pan card",
    "Ayushman card",
    "Abha card",
    "E shrama card",
    "Disability Identity card",
    "Labor card",
    "Smart card",
    "Domestic workers identity card",
    "Employment guarantee card",
    "Senior citizens identity card",
    "Bus pass",
  ];

  const onCardCheckSelect = (e, card) => {
    if (e.target.checked) {
      let temp = [];
      temp.push(...SelectedCards, card);
      setSelectedCards(temp);
    } else {
      const removeList = SelectedCards.filter((selCard) => selCard !== card);
      setSelectedCards(removeList);
    }
  };

  return (
    <Modal
      show={showCardsModal}
      backdrop="static"
      keyboard={false}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          {cradsList.map((card) => {
            return (
              <div class="form-check form-switch pl-5">
                <input
                  style={{
                    display: "block",
                  }}
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  checked={SelectedCards.includes(card)}
                  onChange={(e) => onCardCheckSelect(e, card)}
                />
                <label class="form-check-label" for="flexSwitchCheckDefault">
                  {card}
                </label>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn" onClick={handleCardsModalClose}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default GovtCards;
