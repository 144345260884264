import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

export const SummaryBatchTab = ({ memSummaryData, allPermission }) => {
  let {
    batchSubAmt = 0,
    batchSavingAmt = 0,
    batchOtherContribution = 0,
    batchInterestPaid = 0,
    bankInterest = 0,
    subsidyReceived = 0,
    batchMembershipFees = 0,
    batchOtherIncome = 0,
    deactiveMemberSaving = 0,
    seedMoney = 0,
    batchSecurityDepositMemToShg = 0,
    batchLoanPaid = 0,
    otherLoanSanctionedToShg = 0,
    loanRepaidByOtherSHG = 0,

    batchBankDeposit = 0,
    //expense
    travellingExpenses = 0,
    stationaryExpenses = 0,
    paidToMahasangha = 0,
    bankCommission = 0,
    serviceCharges = 0,
    batchDividendDistributed = 0,
    otherExpenses = 0,
    batchSavingWithdrawals = 0,
    batchSecurityDeposit = 0,
    donationToOutsider = 0,
    subsidyDistributed = 0,
    otherLoanPaidToDonor = 0,
    batchOtherLoanInterestDonor = 0,
    batchLoanAmt = 0,
    batchOtherLoanAmt = 0,

    loanGivenToOtherSHG = 0,
    otherLoanSanctionedToMember = 0,
    //3rd tab
    batchBankWithdrawals = 0,
    cashInHand = 0,
    OutstandingBatchLoanAmt = 0,
    OutstandingBatchOtherLoanAmt = 0,
  } = memSummaryData;

  let bankBalance =
      Number(batchBankDeposit) +
      Number(bankInterest) -
      Number(bankCommission) -
      Number(batchBankWithdrawals),
    totIncome =
      Number(batchSavingAmt) +
      Number(batchSubAmt) +
      Number(batchOtherContribution) +
      Number(batchInterestPaid) +
      Number(bankInterest) +
      Number(subsidyReceived) +
      Number(batchMembershipFees) +
      Number(batchOtherIncome) +
      Number(deactiveMemberSaving) +
      Number(seedMoney) +
      Number(batchSecurityDepositMemToShg) +
      Number(batchLoanPaid) +
      Number(otherLoanSanctionedToShg) +
      Number(loanRepaidByOtherSHG),
    totExpense =
      Number(travellingExpenses) +
      Number(stationaryExpenses) +
      Number(paidToMahasangha) +
      Number(bankCommission) +
      Number(serviceCharges) +
      Number(batchDividendDistributed) +
      Number(otherExpenses) +
      Number(batchSavingWithdrawals) +
      Number(batchSecurityDeposit) +
      Number(donationToOutsider) +
      Number(subsidyDistributed) +
      Number(otherLoanPaidToDonor) +
      Number(batchOtherLoanInterestDonor) +
      Number(batchLoanAmt || 0) +
      Number(batchOtherLoanAmt) +
      Number(loanGivenToOtherSHG) +
      Number(otherLoanSanctionedToMember);

  return (
    <>
      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
        <h6>Total Savings :{batchSavingAmt} </h6>
        <h6>Total Subscription : {batchSubAmt} </h6>
        <h6>Total Other Contribution : {batchOtherContribution} </h6>
        <h6>Total Loan Interest Paid to SHG : {batchInterestPaid}</h6>
        <h6>Total Bank Interest : {bankInterest} </h6>
        <h6>Subsidy Received : {subsidyReceived} </h6>
        <h6>Total Membership Fees : {batchMembershipFees}</h6>
        <h6>Total Other Income : {batchOtherIncome} </h6>
        <h6>Total Deactive Member Charity Amount : {deactiveMemberSaving}</h6>
        <h6>Total Seed Money : {seedMoney}</h6>
        <h6>
          Total Security Deposit Member To Shg :{batchSecurityDepositMemToShg}
        </h6>
        <h6>Total Loan Repayment to SHG : {batchLoanPaid}</h6>
        <h6>Total Other Loan Sanctioned To SHG : {otherLoanSanctionedToShg}</h6>
        {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
          <h6>Total Loan Repaid By Other SHG :{loanRepaidByOtherSHG}</h6>
        ) : (
          <></>
        )}

        <h6>
          <b>Total Income : {totIncome} </b>
        </h6>
      </div>

      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
        <h6>Total Travelling Expenses : {travellingExpenses} </h6>
        <h6>Total Stationary Expenses : {stationaryExpenses} </h6>
        <h6>Total Paid To Union : {paidToMahasangha} </h6>
        <h6>Total Bank Commission : {bankCommission}</h6>
        <h6>Total Service Charges :{serviceCharges}</h6>
        <h6>Total Dividend Distributed :{batchDividendDistributed}</h6>
        <h6>Total Other Expenses :{otherExpenses}</h6>
        <h6>Total Saving Withdrawals :{batchSavingWithdrawals}</h6>
        <h6>Total Security Deposit :{batchSecurityDeposit}</h6>
        <h6>Total Donation To Outsider :{donationToOutsider}</h6>
        <h6>Total Subsidy Distributed :{subsidyDistributed}</h6>
        <h6>Total Loan Paid To Donor : {otherLoanPaidToDonor} </h6>
        <h6>
          Total Other Loan Interest to Donor :{batchOtherLoanInterestDonor}
        </h6>
        <h6>Total Internal Loan Amount Given :{batchLoanAmt}</h6>
        <h6>Total Other Loan Amount Given : {otherLoanSanctionedToMember}</h6>
        {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
          <h6>Total Loan Given To Other SHG :{loanGivenToOtherSHG}</h6>
        ) : (
          <></>
        )}

        <h6>
          <b>Total Expense :{totExpense}</b>
        </h6>
      </div>

      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
        <h6>
          <b>Cash In Hand : {cashInHand}</b>
        </h6>
        <h6>
          <b>Bank Balance: {bankBalance.toFixed(2)}</b>
        </h6>
        <h6>
          <b>Bank Withdrawals : {batchBankWithdrawals} </b>
        </h6>
        <h6>
          <b>
            Total Outstanding Internal Loan Amount :
            {Number(OutstandingBatchLoanAmt).toFixed(2)}
          </b>
        </h6>
        <h6>
          <b>
            Total Outstanding Other Loan Amount :
            {Number(OutstandingBatchOtherLoanAmt).toFixed(2)}
          </b>
        </h6>
      </div>
    </>
  );
};

SummaryBatchTab.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryBatchTab);
