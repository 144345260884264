import axios from "axios";

import {
  AUTH_ERROR,
  SAVED,
  STATES,
  VARADOS,
  ALL_VARADOS,
  ALL_PARISH,
  ALL_STATE,
  ALL_DISTRICT,
  ALL_BLOCK,
  DISTRICT,
  VILLAGE,
  PARISH,
  BLOCK_DISTRICT,
  GET_BLOCK,
  STATECHECKMSG,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
} from "./types";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

var linkPath = process.env.REACT_APP_BASE_URL;
// var linkPath = "";

//ADD

export const AddVarado = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/area/add-varado`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllVarado(reqData));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddParish = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/area/add-parish`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllParish(reqData));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const checkState = (finalData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/area/check-available-state`,
      finalData,
      config
    );
    dispatch({
      type: STATECHECKMSG,
      payload: res.data.msg,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const unsetCheckState = () => async (dispatch) => {
  try {
    dispatch({
      type: STATECHECKMSG,
      payload: "",
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddState = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    const res = await axios.post(
      `${linkPath}/api/area/add-state`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllState(reqData));
    dispatch({
      type: SAVED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddDistrict = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/area/add-district`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllDistrict(reqData));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddBlock = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/area/add-Block`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllBlock());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//ADD VILLAGE
export const AddVillage = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    // dispatch({
    //   type: SET_LOADING_TRUE,
    // });
    await axios.post(`${linkPath}/api/area/add-Village`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllVillages());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//ADD TANKNAME
export const AddTankName = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    // dispatch({
    //   type: SET_LOADING_TRUE,
    // });
    await axios.post(`${linkPath}/api/area/add-Tankname`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllTankName());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//EDIT

export const editBlockDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/area/edit-block`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllBlock());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editVaradoDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/area/edit-varado`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllVarado());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editParishDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/area/edit-parish`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllParish());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editStateDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/area/edit-state`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllState());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editVillageDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/area/edit-village`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllVillage());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editTankNameDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/area/edit-tankname`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllTankName());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editDistrictDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/area/edit-district`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllDistrict());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//DEACTIVE
export const deactiveVarado = (finalData) => async (dispatch) => {
  try {
    await axios.post(`${linkPath}/api/area/deactive-varado`, finalData, config);
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
//SELECT

//FOR Dropdown
export const getStates = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/area/get-state`);
    localStorage.setItem("selStateData", JSON.stringify(res.data));
    dispatch({
      type: STATES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//For Village
export const getAllVillages = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/area/get-village`);

    localStorage.setItem("village", JSON.stringify(res.data));
    dispatch({
      type: "VILLAGE",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//For Tank
export const getAllTankName = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/area/get-tankName`);

    localStorage.setItem("tankName", JSON.stringify(res.data));
    dispatch({
      type: "TANKNAME",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getVarados = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/area/get-varado`);

    localStorage.setItem("selVaradosData", JSON.stringify(res.data));
    dispatch({
      type: VARADOS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//For all Views

export const getAllVarado = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/area/get-all-varados`,
      changeData
    );
    dispatch({
      type: ALL_VARADOS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllParish = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/area/get-all-parish`,
      changeData
    );
    dispatch({
      type: ALL_PARISH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllState = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/area/get-all-state`,
      changeData
    );
    dispatch({
      type: ALL_STATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllVillage = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/area/get-all-village`,
      changeData
    );
    dispatch({
      type: ALL_STATE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllDistrict = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/area/get-all-district`,
      changeData
    );
    dispatch({
      type: ALL_DISTRICT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getDistrict = (stateVal) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/area/get-district`, stateVal);
    localStorage.setItem("activeDistrictLocal", JSON.stringify(res.data));
    dispatch({
      type: DISTRICT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getVillage = (stateVal) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/area/get-village`, stateVal);
    localStorage.setItem("activeVillageLocal", JSON.stringify(res.data));
    dispatch({
      type: "VILLAGE",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBlockDetails = (districtVal) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/area/get-block`, districtVal);
    localStorage.setItem("activeBlockLocal", JSON.stringify(res.data));
    dispatch({
      type: GET_BLOCK,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBlockDistrict = (districtVal) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/area/get-block-district`,
      districtVal
    );
    localStorage.setItem("selDistrictData", JSON.stringify(res.data));
    dispatch({
      type: BLOCK_DISTRICT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getParish = (varadoVal) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/area/get-parish`, varadoVal);
    localStorage.setItem("activeParishLocal", JSON.stringify(res.data));
    dispatch({
      type: PARISH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
export const getAllBlock = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/area/get-all-block`,
      changeData
    );
    dispatch({
      type: ALL_BLOCK,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
