import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { RadioGroup, RadioButton } from "react-radio-buttons";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import FileBase64 from "react-file-base64";
import {
  editBatchTransaction,
  getParticularLoanReason,
  getMembersMeetingData,
  getMeetingSummary,
  UpdateMeetingStatus,
  getEditMemberMeetingData,
  getSeletedBatchDetails,
  getActiveBankData,
  getActiveOtherLoanType,
  getExistingOLData,
  getExistingBankData,
  deleteBatchOtherLoan,
  deleteBankData,
  addNewOtherLoanBatchTransaction,
  addNewBankTransaction,
  getSelMeetingDateData,
  editMeetingBatchTransaction,
} from "../../actions/shg";

import EditMembermeetingDetailModal from "./EditMembermeetingDetailModal";
import Spinner from "../layout/Spinner";
//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
//import RecheckReasonModal from "./RecheckReasonModal";
//import PdfPreview from "./PdfPreview";
//import ImgPreview from "./ImgPreview";

const EditIndivisualMemberMeeting = ({
  auth: { isAuthenticated, user, users, loading },
  shg: {
    loanreason,
    membersMeetingData,
    summaryData,
    summaryDataOp,
    memberClosingAmt,
    activeBankData,
    activeOtherLoanTypes,
    exstOLData,
    exstBankData,
    selMeetingData,
  },
  editMeetingBatchTransaction,
  editBatchTransaction,
  getParticularLoanReason,
  getMembersMeetingData,
  getMeetingSummary,
  UpdateMeetingStatus,
  getEditMemberMeetingData,
  getSeletedBatchDetails,
  getActiveBankData,
  getActiveOtherLoanType,
  getExistingOLData,
  getExistingBankData,
  deleteBatchOtherLoan,
  deleteBankData,
  addNewOtherLoanBatchTransaction,
  addNewBankTransaction,
  getSelMeetingDateData,
}) => {
  const history = useHistory();
  const [myerror, setMyError] = useState({ color: "black" });

  let bankBalance = 0,
    cashInHandVal = 0,
    outstandingInternalLoanAmt = 0,
    outstandinOtherLoanAmt = 0;
  let selBatchData = JSON.parse(localStorage.getItem("selBatchData"));
  let batchTransactionData = JSON.parse(
    localStorage.getItem("batchTransactionData")
  );
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const allRights = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.groupRights &&
    loggedUserInstitutionData.groupRights.map((groupRights) =>
      allRights.push(groupRights)
    );

  const meetingBatchInfo = {
    mdDate: batchIdValue.mdDate,
    mdBatchId: batchIdValue.batchId,
  };
  useEffect(() => {
    getParticularLoanReason();
    getSelMeetingDateData(meetingBatchInfo);
  }, [getParticularLoanReason]);
  useEffect(() => {
    getMembersMeetingData(meetingBatchInfo);
  }, [getMembersMeetingData]);
  useEffect(() => {
    getMeetingSummary(meetingBatchInfo);
  }, [getMeetingSummary]);
  useEffect(() => {
    getActiveBankData(batchIdValue);
  }, [getActiveBankData]);
  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);
  useEffect(() => {
    getExistingOLData(meetingBatchInfo);
  }, [getExistingOLData]);
  useEffect(() => {
    getExistingBankData(meetingBatchInfo);
  }, [getExistingBankData]);

  const [
    showMemberMeetingDetailsModals,
    setMemberMeetingDetailModals,
  ] = useState(false);

  const [formData, setFormData] = useState({
    batchSavingAmt: "",
    batchLoanPaid: "",
    batchInterestPaid: "",
    batchOtherLoanInterestShg: "",
    batchOtherLoanInterestDonor: "",
    batchOtherLoanPaid: "",
    batchOtherLoanInterest: "",
    subsidyDistributed: "",
    otherLoanPaidToDonor: "",
    bankInterest: "",
    batchSubAmt: "",
    stationaryExpenses: "",
    paidToMahasangha: "",
    otherExpenses: "",
    travellingExpenses: "",
    serviceCharges: "",
    bankCommission: "",
    batchOtherContribution: "",
    subsidyReceived: "",
    batchOtherIncome: "",
    batchMembershipFees: "",
    seedMoney: "",
    batchSecurityDepositMemToShg: "",
    otherLoanPaidToShg: "",
    batchLoanSanctionedAmt: "",
    batchOtherLoanAmts: "",
    deactiveMemberSaving: "",
    otherLoanSanctionedToShg: "",
    batchSavingWithdrawals: "",
    batchSecurityDeposit: "",
    donationToOutsider: "",
    batchDividendDistributed: "",
    batchBankDeposit: "",
    batchBankWithdrawals: "",
    cashInHand: "",
    subsidyReceivedType: "",
    otherLoanSanctionedToShgType: "",
    batchMeetingHeldOnDate: "",
    isSubmitted: false,
    batchOtherLoanInterestShgG: "",
    batchOtherLoanInterestDonorG: "",
    subsidyDistributedG: "",
    otherLoanPaidToDonorG: "",
    subsidyReceivedG: "",
    otherLoanPaidToShgG: "",
    otherLoanSanctionedToShgG: "",
    meetingNotes: "",
    sdUnionToSHG: "",
    sdSHGToUnion: "",
  });

  const {
    batchSavingAmt,
    batchLoanPaid,
    cashInHand,
    batchInterestPaid,
    batchOtherLoanInterestShg,
    batchOtherLoanInterestDonor,
    subsidyDistributed,
    otherLoanPaidToDonor,
    bankInterest,
    batchSubAmt,
    stationaryExpenses,
    paidToMahasangha,
    otherExpenses,
    travellingExpenses,
    serviceCharges,
    bankCommission,
    batchOtherContribution,
    subsidyReceived,
    batchOtherIncome,
    batchMembershipFees,
    seedMoney,
    batchSecurityDepositMemToShg,
    otherLoanPaidToShg,
    batchLoanSanctionedAmt,
    batchOtherLoanAmts,
    deactiveMemberSaving,
    otherLoanSanctionedToShg,
    batchSavingWithdrawals,
    batchSecurityDeposit,
    donationToOutsider,
    batchDividendDistributed,
    batchBankDeposit,
    batchBankWithdrawals,
    subsidyReceivedType,
    otherLoanSanctionedToShgType,
    batchMeetingHeldOnDate,
    isSubmitted,
    batchOtherLoanInterestShgG,
    batchOtherLoanInterestDonorG,
    subsidyDistributedG,
    otherLoanPaidToDonorG,
    subsidyReceivedG,
    otherLoanPaidToShgG,
    otherLoanSanctionedToShgG,
    meetingNotes,
    meetingPhotoUpload,
    sdUnionToSHG,
    sdSHGToUnion,
  } = formData;
  const [showHide, setShowHide] = useState({
    showotherLoanSanctionedToShgSection: "",
    showSubsidyReceivedSection: "",
    showOLIPDSection: "",
    showOLPDSection: "",
    showSDUSSection: "",
    showSDSUSection: "",
  });
  const {
    showotherLoanSanctionedToShgSection,
    showSubsidyReceivedSection,
    showOLIPDSection,
    showOLPDSection,
    showSDUSSection,
    showSDSUSection,
  } = showHide;
  const [fileType, setFileType] = useState("");
  if (
    batchTransactionData &&
    batchTransactionData.batchMeetingHeldOnDate &&
    !batchMeetingHeldOnDate
  ) {
    setFormData({
      ...formData,
      batchSavingAmt:
        batchTransactionData && batchTransactionData.batchSavingAmt,
      batchLoanPaid: batchTransactionData && batchTransactionData.batchLoanPaid,
      batchInterestPaid:
        batchTransactionData && batchTransactionData.batchInterestPaid,
      batchOtherLoanInterestShg:
        batchTransactionData && batchTransactionData.batchOtherLoanInterestShg,
      batchOtherLoanInterestDonor:
        batchTransactionData &&
        batchTransactionData.batchOtherLoanInterestDonor,
      batchOtherLoanPaid:
        batchTransactionData && batchTransactionData.batchOtherLoanPaid,
      batchOtherLoanInterest:
        batchTransactionData && batchTransactionData.batchOtherLoanInterest,
      subsidyDistributed:
        batchTransactionData && batchTransactionData.subsidyDistributed,
      otherLoanPaidToDonor:
        batchTransactionData && batchTransactionData.otherLoanPaidToDonor,
      bankInterest: batchTransactionData && batchTransactionData.bankInterest,
      batchSubAmt: batchTransactionData && batchTransactionData.batchSubAmt,
      stationaryExpenses:
        batchTransactionData && batchTransactionData.stationaryExpenses,
      paidToMahasangha:
        batchTransactionData && batchTransactionData.paidToMahasangha,
      otherExpenses: batchTransactionData && batchTransactionData.otherExpenses,
      travellingExpenses:
        batchTransactionData && batchTransactionData.travellingExpenses,
      serviceCharges:
        batchTransactionData && batchTransactionData.serviceCharges,
      bankCommission:
        batchTransactionData && batchTransactionData.bankCommission,
      batchOtherContribution:
        batchTransactionData && batchTransactionData.batchOtherContribution,
      subsidyReceived:
        batchTransactionData && batchTransactionData.subsidyReceived,
      batchOtherIncome:
        batchTransactionData && batchTransactionData.batchOtherIncome,
      batchMembershipFees:
        batchTransactionData && batchTransactionData.batchMembershipFees,
      seedMoney: batchTransactionData && batchTransactionData.seedMoney,
      batchSecurityDepositMemToShg:
        batchTransactionData &&
        batchTransactionData.batchSecurityDepositMemToShg,
      otherLoanPaidToShg:
        batchTransactionData && batchTransactionData.otherLoanPaidToShg,
      batchLoanSanctionedAmt:
        batchTransactionData && batchTransactionData.batchLoanAmt,
      batchOtherLoanAmts:
        batchTransactionData &&
        batchTransactionData.otherLoanSanctionedToMember,
      deactiveMemberSaving:
        batchTransactionData && batchTransactionData.deactiveMemberSaving,
      otherLoanSanctionedToShg:
        batchTransactionData && batchTransactionData.otherLoanSanctionedToShg,
      batchSavingWithdrawals:
        batchTransactionData && batchTransactionData.batchSavingWithdrawals,
      batchSecurityDeposit:
        batchTransactionData && batchTransactionData.batchSecurityDeposit,
      donationToOutsider:
        batchTransactionData && batchTransactionData.donationToOutsider,
      batchDividendDistributed:
        batchTransactionData && batchTransactionData.batchDividendDistributed,
      batchBankDeposit:
        batchTransactionData && batchTransactionData.batchBankDeposit,
      batchBankWithdrawals:
        batchTransactionData && batchTransactionData.batchBankWithdrawals
          ? batchTransactionData.batchBankWithdrawals
          : 0,
      otherLoanSanctionedToShgType:
        batchTransactionData &&
        batchTransactionData.otherLoanSanctionedToShgType,
      subsidyReceivedType:
        batchTransactionData && batchTransactionData.subsidyReceivedType,
      meetingNotes: batchTransactionData && batchTransactionData.meetingNotes,
      cashInHand: batchTransactionData && batchTransactionData.cashInHand,
      batchMeetingHeldOnDate:
        batchTransactionData && batchTransactionData.batchMeetingHeldOnDate,
      meetingPhotoUpload:
        batchTransactionData && batchTransactionData.meetingScreenShot,
      sdUnionToSHG: batchTransactionData && batchTransactionData.sdUnionToSHG,
      sdSHGToUnion: batchTransactionData && batchTransactionData.sdSHGToUnion,
      isSubmitted: false,
    });
    setFileType(
      batchTransactionData.meetingScreenShot
        ? batchTransactionData.meetingScreenShot.split(";")[0].split("/")[1]
        : ""
    );
  }

  //validation for select for otherLoan
  const [errors, setErrors] = useState({
    otherLoanChecker: false,
    otherLoanErrorStyle: {},
  });
  const { otherLoanChecker, otherLoanErrorStyle } = errors;

  const checkError = () => {
    if (!otherLoanChecker) {
      setErrors({
        ...errors,
        otherLoanErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };
  // validation for select bank
  const [errors1, setErrors1] = useState({
    bankChecker: false,
    bankErrorStyle: {},
  });
  const { bankChecker, bankErrorStyle } = errors1;

  const checkError1 = () => {
    if (!bankChecker) {
      setErrors1({
        ...errors1,
        bankErrorStyle: { color: "#F00" },
      });
      return false;
    }
    return true;
  };

  const [error, setError] = useState({
    MemberIdChecker: false,
    MemberIdErrorStyle: {},
    OtherMemberIdChecker: false,
    OtherMemberIdErrorStyle: {},
    purpidchecker: false,
    purpidErrorStyle: {},
    otherLoanTypeIdChecker: false,
    otherLoanTypeIdErrorStyle: {},
    bankIdChecker: false,
    bankIdErrorStyle: {},
  });

  const [photoSizeAlert, setPhotoSizeAlert] = useState(false);
  const [photoSize, setPhotoSize] = useState("");
  const checksize = (base64) => {
    const type = base64.split(";")[0].split("/")[1];
    setFileType(type);
    var stringLength = base64.length - "data:image/png;base64,".length;
    var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    var sizeInKb = sizeInBytes / 1000;

    setPhotoSize(sizeInKb.toFixed(2) + "KB");
    if (sizeInKb >= 500) {
      setPhotoSizeAlert(true);
      setFormData({
        ...formData,
        meetingPhotoUpload: "",
      });
    } else {
      setFormData({
        ...formData,
        meetingPhotoUpload: base64,
      });
      setPhotoSizeAlert(false);
    }
  };
  const [otherLoanType, getOtherLoanTypeData] = useState("");
  const [otherLoanTypeId, setOtherLoanTypeId] = useState("");
  const [otherLoanTypeName, setOtherLoanTypeName] = useState("");

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  //NEW START
  const [bank, getBank] = useState("");
  const [batchBankNameId, setBatchBankNameId] = useState(null);
  const [batchBankNameName, setBatchBankName] = useState("");
  const activeBank = [];
  activeBankData &&
    activeBankData.banks &&
    activeBankData.banks.map((bank) =>
      activeBank.push({
        bankId: bank._id,
        label: bank.batchBankName,
        value: bank.batchBankName,
      })
    );

  const onBankChange = (e) => {
    setErrors1({
      ...errors1,
      bankChecker: true,
      bankErrorStyle: { color: "#000" },
    });
    setError({
      ...error,
      bankIdChecker: true,
      bankIdErrorStyle: { color: "#000" },
    });
    getBank(e);
    setBatchBankNameId(e.bankNameId ? e.bankNameId : null);
    setBatchBankName(e.value ? e.value : "");
  };

  const onInputChange2 = (e) => {
    setFormBankData({ ...addBankData, [e.target.name]: e.target.value });
  };

  const [bank2, getBank2] = useState("");
  const onBankChange2 = (e) => {
    setError({
      ...error,
      bankIdChecker: true,
      bankIdErrorStyle: { color: "#000" },
    });
    getBank2(e);
  };

  const [bankOLIPD, getBankOLIPD] = useState("");
  const onBankChangeOLIPD = (e) => {
    setError({
      ...error,
      bankIdChecker: true,
      bankIdErrorStyle: { color: "#000" },
    });
    getBankOLIPD(e);
  };

  const [bankOLPD, getBankOLPD] = useState("");
  const onBankChangeOLPD = (e) => {
    setError({
      ...error,
      bankIdChecker: true,
      bankIdErrorStyle: { color: "#000" },
    });
    getBankOLPD(e);
  };
  const [bankSDUS, getBankSDUS] = useState("");
  const onBankChangeSDUS = (e) => {
    getBankSDUS(e);
  };
  const [bankSDSU, getBankSDSU] = useState("");
  const onBankChangeSDSU = (e) => {
    getBankSDSU(e);
  };

  if (
    bankSDUS === "" &&
    activeBank &&
    batchTransactionData &&
    batchTransactionData.selectionSDUSType === "bank" &&
    batchTransactionData.bankSDUSId
  ) {
    getBankSDUS(
      activeBank &&
        activeBank.filter(
          (x) => x.bankId === batchTransactionData.bankSDUSId
        )[0]
    );
  }

  if (
    bankSDSU === "" &&
    activeBank &&
    batchTransactionData &&
    batchTransactionData.selectionSDSUType === "bank" &&
    batchTransactionData.bankSDSUId
  ) {
    getBankSDSU(
      activeBank &&
        activeBank.filter(
          (x) => x.bankId === batchTransactionData.bankSDSUId
        )[0]
    );
  }
  //NEW END

  const [selectionSDUSType, setSelectionSDUSType] = useState("");
  const onRadioSDUSChange = (e) => {
    setSelectionSDUSType(e);
    setFormData({ ...formData, SDUSType: e });
    setShowHide({
      ...showHide,
      showSDUSSection: true,
    });
    getBankSDUS("");
  };
  const callselectionSDUSType = () => {
    if (
      !selectionSDUSType &&
      batchTransactionData &&
      batchTransactionData.selectionSDUSType
    ) {
      setSelectionSDUSType(batchTransactionData.selectionSDUSType);
    }
  };
  useEffect(() => {
    callselectionSDUSType();
  }, []);

  const [selectionSDSUType, setSelectionSDSUType] = useState("");
  const onRadioSDSUChange = (e) => {
    setSelectionSDSUType(e);
    setFormData({ ...formData, SDUSType: e });
    setShowHide({
      ...showHide,
      showSDSUSection: true,
    });
    getBankSDSU(null);
  };

  const callselectionSDSUType = () => {
    if (
      !selectionSDSUType &&
      batchTransactionData &&
      batchTransactionData.selectionSDSUType
    ) {
      setSelectionSDSUType(batchTransactionData.selectionSDSUType);
    }
  };
  useEffect(() => {
    callselectionSDSUType();
  }, []);

  const onotherLoanTypeChange = (e) => {
    setErrors({
      ...errors,
      otherLoanChecker: true,
      otherLoanErrorStyle: { color: "#000" },
    });
    //Required Validation starts
    setError({
      ...error,
      otherLoanTypeIdChecker: true,
      otherLoanTypeIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    getOtherLoanTypeData(e);
    setOtherLoanTypeId(e.otherLoanTypeId ? e.otherLoanTypeId : "");
    setOtherLoanTypeName(e.value ? e.value : "");
  };

  //OLR GRID START==============================================================================
  const [AddedDetailsOLR, AddDetailsOLR] = useState([]);
  const onAddOLR = (e) => {
    e.preventDefault();
    if (checkError()) {
      if (otherLoanType) {
        const listOLR = AddedDetailsOLR.filter(
          (AddDetails) =>
            AddDetails.otherLoanTypeId === otherLoanType.otherLoanTypeId
        );
        const listOLREx =
          exstOLData &&
          exstOLData.otherLoanBatchTransaction.filter(
            (exOLR) => exOLR.otherLoanTypeId === otherLoanType.otherLoanTypeId
          );
        if (listOLR.length === 0 && listOLREx.length === 0) {
          const addData = {
            recordId: batchTransactionData._id,
            otherLoanType: otherLoanType.value,
            otherLoanTypeId: otherLoanType.otherLoanTypeId,
            otherLoanPaidToShg: otherLoanPaidToShgG,
            batchOtherLoanInterestShg: batchOtherLoanInterestShgG,
            subsidyReceived: subsidyReceivedG,
            otherLoanSanctionedToShg: otherLoanSanctionedToShgG,
            batchOtherLoanInterestDonor: batchOtherLoanInterestDonorG,
            subsidyDistributed: subsidyDistributedG,
            otherLoanPaidToDonor: otherLoanPaidToDonorG,
            selectionType: selectionType,
            selectionType1: selectionType1,
            selectionOLIPDType: selectionOLIPDType,
            selectionOLPDType: selectionOLPDType,
            bank: bank ? bank.value : "",
            bankId: bank ? bank.bankId : null,
            bank2: bank2 ? bank2.value : "",
            bankId2: bank2 ? bank2.bankId : null,
            bankOLIPD: bankOLIPD ? bankOLIPD.value : "",
            bankOLIPDId: bankOLIPD ? bankOLIPD.bankId : null,
            bankOLPD: bankOLPD ? bankOLPD.value : "",
            bankOLPDId: bankOLPD ? bankOLPD.bankId : null,
            OLTLength: activeOtherLoan.length,
          };
          setFormData({
            ...formData,
            otherLoanType: "",
            otherLoanPaidToShgG: "",
            batchOtherLoanInterestShgG: "",
            subsidyReceivedG: "",
            otherLoanSanctionedToShgG: "",
            batchOtherLoanInterestDonorG: "",
            subsidyDistributedG: "",
            otherLoanPaidToDonorG: "",
            selectionType: "",
            selectionType1: "",
            selectionOLIPDType: "",
            selectionOLPDType: "",
          });
          let temp = [];
          temp.push(...AddedDetailsOLR, addData);
          AddDetailsOLR(temp);
          getOtherLoanTypeData("");
          setSelectionType();
          setSelectionType1();
          setSelectionOLIPDType();
          setSelectionOLPDType();
          getBank();
          getBank2();
          getBankOLIPD();
          getBankOLPD();
          setShowHide({
            ...showHide,
            showotherLoanSanctionedToShgSection: false,
            showSubsidyReceivedSection: false,
            showOLIPDSection: false,
            showOLPDSection: false,
          });
        }
      }
    }
  };

  const onAddOLRSingle = (nxtTab) => {
    let defaultBank = activeBank[0] ? activeBank[0].value : "";
    let defaultBankID = activeBank[0] ? activeBank[0].bankId : null;
    const addData = {
      OLTId:
        exstOLData &&
        exstOLData.otherLoanBatchTransaction[0] &&
        exstOLData.otherLoanBatchTransaction[0]._id,
      recordId: batchTransactionData && batchTransactionData._id,
      otherLoanType: activeOtherLoan[0] ? activeOtherLoan[0].value : "",
      otherLoanTypeId: activeOtherLoan[0]
        ? activeOtherLoan[0].otherLoanTypeId
        : null,
      otherLoanPaidToShg: otherLoanPaidToShg,
      batchOtherLoanInterestShg: batchOtherLoanInterestShg,
      subsidyReceived: subsidyReceived,
      otherLoanSanctionedToShg: otherLoanSanctionedToShg,
      batchOtherLoanInterestDonor: batchOtherLoanInterestDonor,
      subsidyDistributed: subsidyDistributed,
      otherLoanPaidToDonor: otherLoanPaidToDonor,
      selectionType: selectionType,
      selectionType1: selectionType1,
      selectionOLIPDType: selectionOLIPDType,
      selectionOLPDType: selectionOLPDType,
      bank: selectionType === "bank" ? defaultBank : "",
      bankId: selectionType === "bank" ? defaultBankID : null,
      bank2: selectionType1 === "bank" ? defaultBank : "",
      bankId2: selectionType1 === "bank" ? defaultBankID : null,
      bankOLIPD: selectionOLIPDType === "bank" ? defaultBank : "",
      bankOLIPDId: selectionOLIPDType === "bank" ? defaultBankID : null,
      bankOLPD: selectionOLPDType === "bank" ? defaultBank : "",
      bankOLPDId: selectionOLPDType === "bank" ? defaultBankID : null,
      OLTLength: activeOtherLoan.length,
    };
    let temp = [];
    temp.push(addData);
    AddDetailsOLR(temp);
    NextBackBtn(nxtTab);
  };

  const onRemoveChangeOLR = (otherLoanTypeId) => {
    const removeList = AddedDetailsOLR.filter(
      (AddDetails) => AddDetails.otherLoanTypeId !== otherLoanTypeId
    );
    AddDetailsOLR(removeList);
  };

  //OLR GRID END================================================================================

  //Bank START==============================================================================

  const [addBankData, setFormBankData] = useState({
    bankGridId: "",
    bankGridName: "",
    bankGridInterest: "",
    bankGridCommission: "",
    bankGridDeposit: "",
    bankGridwithdrawal: "",
  });
  const {
    bankGridId,
    bankGridName,
    bankGridInterest,
    bankGridCommission,
    bankGridDeposit,
    bankGridwithdrawal,
  } = addBankData;
  const [AddedBankDetails, AddBankDetails] = useState([]);

  const onAddBank = (e) => {
    e.preventDefault();
    if (checkError1()) {
      const bankList = AddedBankDetails.filter(
        (AddBankDetails) => AddBankDetails.bankGridId === batchBankNameId
      );
      const bankListEx =
        exstBankData &&
        exstBankData.bankTransactions.filter(
          (exBank) => exBank.batchBankNameId === batchBankNameId
        );
      e.preventDefault();
      if (bankList.length === 0 && bankListEx.length === 0) {
        const addBankData = {
          recordId: batchTransactionData._id,
          batchBankNameId: batchBankNameId,
          batchBankName: batchBankNameName,
          indBankInterest: bankGridInterest,
          indBankCommission: bankGridCommission,
          indBatchBankDeposit: bankGridDeposit,
          indBatchBankWithdrawals: bankGridwithdrawal,
          bankLength: activeBank.length,
        };
        setFormBankData({
          ...addBankData,
          bankGridInterest: "",
          bankGridCommission: "",
          bankGridDeposit: "",
          gridcashInHand: "",
          bankGridwithdrawal: "",
        });
        let temp = [];
        temp.push(...AddedBankDetails, addBankData);
        AddBankDetails(temp);
        getBank("");
      }
    }
  };
  const onRemoveChangeBank = (batchBankNameId) => {
    const removeList = AddedBankDetails.filter(
      (AddBankDetails) => AddBankDetails.batchBankNameId !== batchBankNameId
    );
    AddBankDetails(removeList);
  };

  const onAddBankSingle = (e) => {
    e.preventDefault();
    const addBankData = {
      bankId:
        exstBankData &&
        exstBankData.bankTransactions[0] &&
        exstBankData.bankTransactions[0]._id,
      recordId: batchTransactionData && batchTransactionData._id,
      batchBankNameId:
        activeBank && activeBank[0] ? activeBank[0].bankId : null,
      batchBankName: activeBank && activeBank[0] ? activeBank[0].value : "",
      indBankInterest: bankInterest ? bankInterest : 0,
      indBankCommission: bankCommission ? bankCommission : 0,
      indBatchBankDeposit: batchBankDeposit ? batchBankDeposit : 0,
      indBatchBankWithdrawals: batchBankWithdrawals ? batchBankWithdrawals : 0,
      bankLength: activeBank.length,
    };
    setFormBankData({
      ...addBankData,
      bankGridInterest: "",
      bankGridCommission: "",
      bankGridDeposit: "",
      bankGridwithdrawal: "",
    });
    let temp = [];
    temp.push(addBankData);
    AddBankDetails(temp);
    return temp;
  };
  //BANK GRID END================================================================================

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);
  const [userDatas, setUserDatas] = useState(null);
  const [userData2, setUserData2] = useState(null);
  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  // code for next previous tabing ends
  let savingAmtTot = 0,
    loanPaidTot = 0,
    interestPaidTot = 0,
    subscriptionAmtTot = 0,
    otherContributionTot = 0,
    loanTakenAmtTot = 0,
    otherLoanTakenAmtTot = 0,
    otherLoanPaidTot = 0,
    otherInterestPaidTot = 0,
    indSubsidyDistributedTot = 0,
    batchOtherIncomeTot = 0,
    memSeedMoneyTot = 0,
    membershipFeesTot = 0;

  const onSubmit = (e) => {
    if (batchBankDeposit === "") {
      setMyError({ color: "red" });
      // alert("error");
    } else {
      let bankTran = [];
      if (activeBank.length <= 1) {
        bankTran = onAddBankSingle(e);
      } else {
        bankTran = AddedBankDetails;
      }

      let otherLoanSanctionedToShgSum = 0,
        otherLoanSanctionedToMemberSum = 0,
        otherLoanPaidToShgSum = 0,
        otherLoanPaidToDonorSum = 0,
        batchOtherLoanInterestShgSum = 0,
        batchOtherLoanInterestDonorSum = 0,
        subsidyReceivedSum = 0,
        indSubsidyDistributedSum = 0;

      if (activeOtherLoan.length > 1) {
        exstOLData &&
          exstOLData.otherLoanBatchTransaction &&
          exstOLData.otherLoanBatchTransaction.map((AddDetail, idx) => {
            otherLoanSanctionedToShgSum += parseFloat(
              AddDetail.otherLoanSanctionedToShg || 0
            );
            otherLoanSanctionedToMemberSum += parseFloat(
              AddDetail.batchOtherLoanAmts || 0
            );
            otherLoanPaidToShgSum += parseFloat(
              AddDetail.otherLoanPaidToShg || 0
            );
            otherLoanPaidToDonorSum += parseFloat(
              AddDetail.otherLoanPaidToDonor || 0
            );
            batchOtherLoanInterestShgSum += parseFloat(
              AddDetail.batchOtherLoanInterestShg || 0
            );
            batchOtherLoanInterestDonorSum += parseFloat(
              AddDetail.batchOtherLoanInterestDonor || 0
            );
            subsidyReceivedSum += parseFloat(AddDetail.subsidyReceived || 0);
            indSubsidyDistributedSum += parseFloat(
              AddDetail.subsidyDistributed || 0
            );
          });
      }

      AddedDetailsOLR.map((AddDetail, idx) => {
        otherLoanSanctionedToShgSum += parseFloat(
          AddDetail.otherLoanSanctionedToShg || 0
        );
        otherLoanSanctionedToMemberSum += parseFloat(
          AddDetail.batchOtherLoanAmts || 0
        );
        otherLoanPaidToShgSum += parseFloat(AddDetail.otherLoanPaidToShg || 0);
        otherLoanPaidToDonorSum += parseFloat(
          AddDetail.otherLoanPaidToDonor || 0
        );
        batchOtherLoanInterestShgSum += parseFloat(
          AddDetail.batchOtherLoanInterestShg || 0
        );
        batchOtherLoanInterestDonorSum += parseFloat(
          AddDetail.batchOtherLoanInterestDonor || 0
        );
        subsidyReceivedSum += parseFloat(AddDetail.subsidyReceived || 0);
        indSubsidyDistributedSum += parseFloat(
          AddDetail.subsidyDistributed || 0
        );
      });

      let batchBankDepositSum = 0,
        batchBankWithdrawalsSum = 0,
        bankInterestSum = 0,
        bankCommissionSum = 0;

      if (activeBank.length > 1) {
        exstBankData &&
          exstBankData.bankTransactions &&
          exstBankData.bankTransactions.map((AddDetail) => {
            batchBankDepositSum += parseFloat(
              AddDetail.indBatchBankDeposit || 0
            );
            batchBankWithdrawalsSum += parseFloat(
              AddDetail.indBatchBankWithdrawals || 0
            );
            bankInterestSum += parseFloat(AddDetail.indBankInterest || 0);
            bankCommissionSum += parseFloat(AddDetail.indBankCommission || 0);
          });
      }

      bankTran.map((AddDetail) => {
        batchBankDepositSum += parseFloat(AddDetail.indBatchBankDeposit || 0);
        batchBankWithdrawalsSum += parseFloat(
          AddDetail.indBatchBankWithdrawals || 0
        );
        bankInterestSum += parseFloat(AddDetail.indBankInterest || 0);
        bankCommissionSum += parseFloat(AddDetail.indBankCommission || 0);
      });

      e.preventDefault();
      const finalData = {
        //INCOME
        batchSavingAmt: batchSavingAmt,
        batchSubAmt: batchSubAmt,
        batchOtherContribution: batchOtherContribution
          ? batchOtherContribution
          : 0,
        batchMembershipFees: batchMembershipFees ? batchMembershipFees : 0,
        bankInterest: bankInterestSum ? Number(bankInterestSum).toFixed(2) : 0, //sumB
        deactiveMemberSaving: deactiveMemberSaving ? deactiveMemberSaving : 0,
        subsidyReceived: subsidyReceivedSum
          ? Number(subsidyReceivedSum).toFixed(2)
          : 0, //sum
        batchOtherIncome: batchOtherIncome ? batchOtherIncome : 0,
        seedMoney: seedMoney ? seedMoney : 0,
        batchSecurityDepositMemToShg: batchSecurityDepositMemToShg
          ? batchSecurityDepositMemToShg
          : 0,
        sdUnionToSHG: sdUnionToSHG ? sdUnionToSHG : 0,
        bankSDUSId: bankSDUS ? bankSDUS.bankId : null,
        bankSDUS: bankSDUS ? bankSDUS.value : "",
        selectionSDUSType: selectionSDUSType ? selectionSDUSType : "",
        //EXPENSE
        travellingExpenses: travellingExpenses ? travellingExpenses : 0,
        stationaryExpenses: stationaryExpenses ? stationaryExpenses : 0,
        paidToMahasangha: paidToMahasangha ? paidToMahasangha : 0,
        bankCommission: bankCommissionSum
          ? Number(bankCommissionSum).toFixed(2)
          : 0, //sumB
        serviceCharges: serviceCharges ? serviceCharges : 0,
        batchDividendDistributed: batchDividendDistributed
          ? batchDividendDistributed
          : 0,
        otherExpenses: otherExpenses ? otherExpenses : 0,
        batchSavingWithdrawals: batchSavingWithdrawals
          ? batchSavingWithdrawals
          : 0,
        batchSecurityDeposit: batchSecurityDeposit ? batchSecurityDeposit : 0,
        donationToOutsider: donationToOutsider ? donationToOutsider : 0,
        subsidyDistributed: indSubsidyDistributedSum
          ? Number(indSubsidyDistributedSum).toFixed(2)
          : 0, //sum
        sdSHGToUnion: sdSHGToUnion ? sdSHGToUnion : 0,
        bankSDSUId: bankSDSU ? bankSDSU.bankId : null,
        bankSDSU: bankSDSU ? bankSDSU.value : "",
        selectionSDSUType: selectionSDSUType ? selectionSDSUType : 0,
        //LOAN DETAILS
        batchLoanAmt: batchLoanSanctionedAmt ? batchLoanSanctionedAmt : 0,
        batchLoanPaid: batchLoanPaid ? batchLoanPaid : 0,
        batchInterestPaid: batchInterestPaid ? batchInterestPaid : 0,
        //OTHER LOAN DETAILS
        otherLoanSanctionedToShg: otherLoanSanctionedToShgSum
          ? Number(otherLoanSanctionedToShgSum).toFixed(2)
          : 0, //sum
        otherLoanSanctionedToMember: batchOtherLoanAmts
          ? batchOtherLoanAmts
          : 0,
        otherLoanPaidToShg: otherLoanPaidToShgSum
          ? Number(otherLoanPaidToShgSum).toFixed(2)
          : 0, //sum
        otherLoanPaidToDonor: otherLoanPaidToDonorSum
          ? Number(otherLoanPaidToDonorSum).toFixed(2)
          : 0, //sum
        batchOtherLoanInterestDonor: batchOtherLoanInterestDonorSum
          ? Number(batchOtherLoanInterestDonorSum).toFixed(2)
          : 0, //sum
        batchOtherLoanInterestShg: batchOtherLoanInterestShgSum
          ? Number(batchOtherLoanInterestShgSum).toFixed(2)
          : 0, //sum
        //BANK DETAILS

        cashInHand: cashInHand || 0,
        batchBankDeposit: batchBankDepositSum
          ? Number(batchBankDepositSum).toFixed(2)
          : 0, //sumB
        batchBankWithdrawals: batchBankWithdrawalsSum
          ? Number(batchBankWithdrawalsSum).toFixed(2)
          : 0, //sumB
        //OTHER
        meetingNotes: meetingNotes,
        meetingScreenShot: meetingPhotoUpload,
        verifiedById:
          (e.target.value === "Verify" || batchTransactionData.verifiedById) &&
          user &&
          user._id,
        recordId: batchTransactionData._id,
        batchTransactionData: batchTransactionData,
      };
      editBatchTransaction(finalData);

      if (e.target.value === "Verify" || e.target.value === "Update") {
        const meetingUpdateData = {
          mdDate: meetingBatchInfo.mdDate,
          mdBatchId: meetingBatchInfo.mdBatchId,
          mdStatus:
            selMeetingData.mdStatus === "Rechecked" ? "Submitted" : "Verified",
        };
        UpdateMeetingStatus(meetingUpdateData);
      }
      AddedDetailsOLR &&
        AddedDetailsOLR.map((data) => {
          addNewOtherLoanBatchTransaction(data);
        });
      bankTran &&
        bankTran.map((data) => {
          addNewBankTransaction(data);
        });
      setFormData({
        ...formData,
        isSubmitted: true,
      });
    }

    //ok
  };

  const handleMemberMeetingModalCloses = () =>
    setMemberMeetingDetailModals(false);
  const onOpenEditModal = (memTransactionData, idx) => {
    localStorage.removeItem("memberEditData");
    const meetingInfo = {
      mdDate: memTransactionData.meetingHeldOnDate,
      mdBatchId: memTransactionData.batchId,
      memberId: memTransactionData.memberId,
    };
    const batchIdData = {
      batchIdVal: memTransactionData.batchId,
    };
    getSeletedBatchDetails(batchIdData);
    getEditMemberMeetingData(meetingInfo);
    setMemberMeetingDetailModals(true);
    setUserDatas(memTransactionData);
    setUserData2(meetingInfo);
  };
  const onMemberMeetingModalChanges = (e) => {
    if (e) {
      handleMemberMeetingModalCloses();
    }
  };

  if (
    activeOtherLoan &&
    activeOtherLoan.length <= 1 &&
    batchTransactionData &&
    batchTransactionData.otherLoanBatchTransaction &&
    batchTransactionData.otherLoanBatchTransaction[0]
  ) {
    if (
      showotherLoanSanctionedToShgSection === "" &&
      batchTransactionData.otherLoanBatchTransaction[0].selectionType1
    ) {
      setShowHide({
        ...showHide,
        showotherLoanSanctionedToShgSection:
          batchTransactionData.otherLoanBatchTransaction[0].selectionType1 ===
            "cash" ||
          batchTransactionData.otherLoanBatchTransaction[0].selectionType1 ===
            "bank"
            ? true
            : false,
      });
    }
    if (
      showSubsidyReceivedSection === "" &&
      batchTransactionData.otherLoanBatchTransaction[0].selectionType
    ) {
      setShowHide({
        ...showHide,
        showSubsidyReceivedSection:
          batchTransactionData.otherLoanBatchTransaction[0].selectionType ===
            "cash" ||
          batchTransactionData.otherLoanBatchTransaction[0].selectionType ===
            "bank"
            ? true
            : false,
      });
    }
    if (
      showOLIPDSection === "" &&
      batchTransactionData.otherLoanBatchTransaction[0].selectionOLIPDType
    ) {
      setShowHide({
        ...showHide,
        showOLIPDSection:
          batchTransactionData.otherLoanBatchTransaction[0]
            .selectionOLIPDType === "cash" ||
          batchTransactionData.otherLoanBatchTransaction[0]
            .selectionOLIPDType === "bank"
            ? true
            : false,
      });
    }
    if (
      showOLPDSection === "" &&
      batchTransactionData.otherLoanBatchTransaction[0].selectionOLPDType
    ) {
      setShowHide({
        ...showHide,
        showOLPDSection:
          batchTransactionData.otherLoanBatchTransaction[0]
            .selectionOLPDType === "cash" ||
          batchTransactionData.otherLoanBatchTransaction[0]
            .selectionOLPDType === "bank"
            ? true
            : false,
      });
    }
  }

  if (
    showSDUSSection === "" &&
    batchTransactionData &&
    batchTransactionData.selectionSDUSType
  ) {
    setShowHide({
      ...showHide,
      showSDUSSection:
        batchTransactionData.selectionSDUSType === "cash" ||
        batchTransactionData.selectionSDUSType === "bank"
          ? true
          : false,
    });
  }
  if (
    showSDSUSection === "" &&
    batchTransactionData &&
    batchTransactionData.selectionSDSUType
  ) {
    setShowHide({
      ...showHide,
      showSDSUSection:
        batchTransactionData.selectionSDSUType === "cash" ||
        batchTransactionData.selectionSDSUType === "bank"
          ? true
          : false,
    });
  }

  const [selectionType, setSelectionType] = useState();
  if (
    !selectionType &&
    batchTransactionData &&
    batchTransactionData.otherLoanBatchTransaction &&
    batchTransactionData.otherLoanBatchTransaction[0] &&
    batchTransactionData.otherLoanBatchTransaction[0].selectionType
  ) {
    setSelectionType(
      batchTransactionData.otherLoanBatchTransaction[0].selectionType
    );
  }

  const [selectionType1, setSelectionType1] = useState();
  if (
    !selectionType1 &&
    batchTransactionData &&
    batchTransactionData.otherLoanBatchTransaction &&
    batchTransactionData.otherLoanBatchTransaction[0] &&
    batchTransactionData.otherLoanBatchTransaction[0].selectionType1
  ) {
    setSelectionType1(
      batchTransactionData.otherLoanBatchTransaction[0].selectionType1
    );
  }
  const onRadioSubsidyReceivedChange = (e) => {
    setSelectionType(e);
    setFormData({ ...formData, subsidyReceivedType: e });
    setShowHide({
      ...showHide,
      showSubsidyReceivedSection: true,
    });
    getBank("");
  };

  const onRadioOtherLoanSanctionedToShgChange = (e) => {
    setSelectionType1(e);
    setFormData({ ...formData, otherLoanSanctionedToShgType: e });
    setShowHide({
      ...showHide,
      showotherLoanSanctionedToShgSection: true,
    });

    getBank2("");
  };

  const [selectionOLIPDType, setSelectionOLIPDType] = useState();
  const onRadioOLIPDChange = (e) => {
    setSelectionOLIPDType(e);
    setFormData({ ...formData, OLIPDType: e });
    setShowHide({
      ...showHide,
      showOLIPDSection: true,
    });
    getBankOLIPD("");
    // getBank("");
  };

  const [selectionOLPDType, setSelectionOLPDType] = useState();
  const onRadioOLPDChange = (e) => {
    setSelectionOLPDType(e);

    setFormData({ ...formData, OLPDType: e });
    setShowHide({
      ...showHide,
      showOLPDSection: true,
    });
    getBankOLPD("");
  };
  const onDelete = (data) => {
    deleteBatchOtherLoan({
      otherLoanId: data._id,
      transactionId: batchTransactionData._id,
      mdDate: batchIdValue.mdDate,
      mdBatchId: batchIdValue.batchId,
    });
  };

  const onDeleteBank = (BankData) => {
    deleteBankData({
      bankId: BankData._id,
      transactionId: batchTransactionData._id,
      mdDate: batchIdValue.mdDate,
      mdBatchId: batchIdValue.batchId,
    });
  };
  //NEW BANK BALANCE START
  let BankDepositSum = 0,
    BankWithdrawalsSum = 0,
    BankInterestSum = 0,
    BankCommissionSum = 0;
  exstBankData &&
    exstBankData.bankTransactions &&
    exstBankData.bankTransactions.map((AddDetail) => {
      BankDepositSum += parseFloat(AddDetail.indBatchBankDeposit || 0);
      BankWithdrawalsSum += parseFloat(AddDetail.indBatchBankWithdrawals || 0);
      BankInterestSum += parseFloat(AddDetail.indBankInterest || 0);
      BankCommissionSum += parseFloat(AddDetail.indBankCommission || 0);
    });

  AddedBankDetails &&
    AddedBankDetails.map((AddDetail) => {
      BankDepositSum += parseFloat(AddDetail.indBatchBankDeposit || 0);
      BankWithdrawalsSum += parseFloat(AddDetail.indBatchBankWithdrawals || 0);
      BankInterestSum += parseFloat(AddDetail.indBankInterest || 0);
      BankCommissionSum += parseFloat(AddDetail.indBankCommission || 0);
    });

  let OLSToShgSum = 0,
    SRSum = 0,
    OLIPDSum = 0,
    OLPDSum = 0;

  exstOLData &&
    exstOLData.otherLoanBatchTransaction &&
    exstOLData.otherLoanBatchTransaction.map((AddDetail, idx) => {
      OLSToShgSum +=
        AddDetail.selectionType === "bank"
          ? parseFloat(AddDetail.otherLoanSanctionedToShg || 0)
          : 0;
      SRSum +=
        AddDetail.selectionType1 === "bank"
          ? parseFloat(AddDetail.subsidyReceived || 0)
          : 0;
      OLIPDSum +=
        AddDetail.selectionOLIPDType === "bank"
          ? parseFloat(AddDetail.batchOtherLoanInterestDonor || 0)
          : 0;
      OLPDSum +=
        AddDetail.selectionOLPDType === "bank"
          ? parseFloat(AddDetail.otherLoanPaidToDonor || 0)
          : 0;
    });
  AddedDetailsOLR &&
    AddedDetailsOLR.map((AddDetail) => {
      OLSToShgSum +=
        AddDetail.selectionType === "bank"
          ? parseFloat(AddDetail.otherLoanSanctionedToShg || 0)
          : 0;
      SRSum +=
        AddDetail.selectionType1 === "bank"
          ? parseFloat(AddDetail.subsidyReceived || 0)
          : 0;
      OLIPDSum +=
        AddDetail.selectionOLIPDType === "bank"
          ? parseFloat(AddDetail.batchOtherLoanInterestDonor || 0)
          : 0;
      OLPDSum +=
        AddDetail.selectionOLPDType === "bank"
          ? parseFloat(AddDetail.otherLoanPaidToDonor || 0)
          : 0;
    });

  let newBankBalance = 0;
  //BANK SECTION
  if (activeBank.length > 1) {
    newBankBalance +=
      BankInterestSum - BankCommissionSum + BankDepositSum - BankWithdrawalsSum;
    newBankBalance +=
      Number(bankGridInterest) -
      Number(bankGridCommission) +
      Number(bankGridDeposit) -
      Number(bankGridwithdrawal);
  } else {
    //ON KEY PRESS OR SINGLE BANK CASE
    newBankBalance +=
      Number(bankInterest) -
      Number(bankCommission) +
      Number(batchBankDeposit) -
      Number(batchBankWithdrawals);
  }
  //OTHER LOAN SECTION
  if (activeOtherLoan.length > 1) {
    newBankBalance += OLSToShgSum + SRSum - OLIPDSum - OLPDSum;
    newBankBalance +=
      Number(selectionType === "bank" ? subsidyReceivedG : 0) +
      Number(selectionType1 === "bank" ? otherLoanSanctionedToShgG : 0) -
      Number(selectionOLIPDType === "bank" ? batchOtherLoanInterestDonorG : 0) -
      Number(selectionOLPDType === "bank" ? otherLoanPaidToDonorG : 0);
  } else {
    //ON KEY PRESS OR SINGLE OTHER LOAN TYPE CASE
    newBankBalance +=
      Number(selectionType === "bank" ? subsidyReceived : 0) +
      Number(selectionType1 === "bank" ? otherLoanSanctionedToShg : 0) -
      Number(selectionOLIPDType === "bank" ? batchOtherLoanInterestDonor : 0) -
      Number(selectionOLPDType === "bank" ? otherLoanPaidToDonor : 0);
  }

  if (selectionSDUSType === "bank")
    newBankBalance += Number(sdUnionToSHG ? sdUnionToSHG : 0);
  if (selectionSDSUType === "bank")
    newBankBalance += Number(sdSHGToUnion ? sdSHGToUnion : 0);
  //NEW BANK BALANCE END

  //RECHECK START
  const [showRecheckModal, setShowRecheckModal] = useState(false);
  const handleRecheckModalClose = () => setShowRecheckModal(false);

  const onRecheckModalChange = (e) => {
    if (e) {
      handleRecheckModalClose();
    }
  };
  const onRecheck = () => {
    setShowRecheckModal(true);
  };
  //RECHECK END

  const [showImage, setShowImage] = useState(false);
  const onShowImage = (e) => {
    e.preventDefault();
    setShowImage(true);
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  //////////////////////////////////
  //Get total of All the spefied Loan Type from DB

  let output = [];

  //Flat the array of array
  membersMeetingData
    .map((ele) => ele.otherLoan)
    .flat()
    .forEach((item) => {
      let found = false;

      // Check if _id already exists in output
      for (let i = 0; i < output.length; i++) {
        if (output[i][0].otherLoanTypeId === item.otherLoanTypeId) {
          output[i][0].otherLoanPaid += item.otherLoanPaid;
          output[i][0].otherInterestPaid += item.otherInterestPaid;
          found = true;
          break;
        }
      }

      // If _id does not exist in output, add a new object
      if (!found) {
        output.push([
          {
            otherLoanTypeId: item.otherLoanTypeId,
            otherLoanPaid: item.otherLoanPaid,
            otherInterestPaid: item.otherInterestPaid,
          },
        ]);
      }
    });

  let otherLoanBatchTrans = output.flat();

  //////////////////////////////////////////////
  const onfinalSubmit = () => {
    handleLogoutModalShow();
  };
  const onSubmitMeeting = () => {
    // alert();
    const finalBatchData = {
      batchLoanAmt: loanTakenAmtTot,
      batchLoanPaid: loanPaidTot,
      batchInterestPaid: interestPaidTot,
      batchId: batchIdValue.batchId,
      batchMeetingHeldOnDate: batchIdValue.mdDate,
      otherLoanSanctionedToMember: otherLoanTakenAmtTot,
      otherLoanPaidToShg: otherLoanPaidTot,
      batchOtherLoanInterestShg: otherInterestPaidTot,
      otherLoanBatchTrans: otherLoanBatchTrans,
    };
    editMeetingBatchTransaction(finalBatchData);
    history.push("/edit-verified-meeting");
    handleLogoutModalClose();
  };

  if (isSubmitted) {
    return <Redirect to="/landing-page" />;
  }
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  var ED = batchIdValue.mdDate.split(/\D/g);
  var mdDate = [ED[2], ED[1], ED[0]].join("-");

  const [submitmeeting, setsubmitmeeting] = useState(false);
  const handleLogoutModalClose = () => setsubmitmeeting(false);
  const handleLogoutModalShow = () => setsubmitmeeting(true);
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">
            Edit Batch Meeting Details of {batchIdValue.batchName}{" "}
          </h2>
          <hr />
        </div>
        <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding">
          <div className="col-lg-6 col-md-11 col-sm-11 col-11 ">
            <h4 className="heading_color">Meeting Date: {mdDate && mdDate} </h4>
          </div>
          <div className="col-lg-4 col-md-11 col-sm-11 col-11 ">
            {(user.userGroupName === "Admin" ||
              user.userGroupName === "Co-ordinator") &&
              batchTransactionData &&
              !batchTransactionData.verifiedById && (
                <button className="btn float-right" onClick={() => onRecheck()}>
                  Recheck
                </button>
              )}
          </div>
          <div className="col-lg-2 col-md-11 col-sm-11 col-11 ">
            <Link
              to={{
                pathname: "/edit-verified-meeting",
                // batchIdValue.callFrom === "Verification"
                //   ? "/all-meetings-verification"
                //   : "/all-verified-meeting",
              }}
            >
              <img
                className="img_icon_size log"
                src={require("../../static/images/back.png")}
                alt="Back"
                title="Back"
              />
            </Link>
          </div>
        </div>
        <section className="sub_reg">
          <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
            <section className="body">
              <div className="body-inner no-padding table-responsive ">
                <table
                  className="table table-bordered table-striped table-hover"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>Edit</th>
                      <th>Members Name</th>
                      <th>IL Payable Pri</th>
                      <th>IL Payable Int</th>
                      <th>IL Tot Payable</th>
                      <th>IL Pri Paid</th>
                      <th>IL Int Paid</th>
                      <th>Oth Loan</th>
                      <th>Oth Pri Paid</th>
                      <th>Oth Int Paid</th>
                    </tr>
                  </thead>
                  <tbody>
                    {membersMeetingData &&
                      membersMeetingData.map((memTransactionData, idx) => {
                        savingAmtTot += memTransactionData.savingAmt;
                        loanPaidTot += memTransactionData.loanPaid;
                        interestPaidTot += memTransactionData.interestPaid;
                        otherLoanPaidTot += memTransactionData.otherLoanPaid;
                        otherInterestPaidTot +=
                          memTransactionData.otherInterestPaid;
                        subscriptionAmtTot +=
                          memTransactionData.subscriptionAmt;
                        otherContributionTot +=
                          memTransactionData.otherContribution;
                        loanTakenAmtTot += memTransactionData.loanTakenAmt;
                        otherLoanTakenAmtTot +=
                          memTransactionData.otherLoanTakenAmt;
                        indSubsidyDistributedTot +=
                          memTransactionData.subsidyDistributed;
                        batchOtherIncomeTot += memTransactionData.otherIncome;
                        membershipFeesTot += memTransactionData.membershipFees;
                        memSeedMoneyTot += memTransactionData.memSeedMoney;
                        return (
                          <tr key={idx}>
                            <td>
                              <img
                                className="img_icon_size log"
                                onClick={() =>
                                  onOpenEditModal(memTransactionData, idx)
                                }
                                src={require("../../static/images/edit_icon.png")}
                                alt="Edit"
                                title="Edit"
                              />
                            </td>
                            {memTransactionData.isEdited == true ? (
                              <td className="text-danger">
                                {memTransactionData.memberName}
                              </td>
                            ) : (
                              <td>{memTransactionData.memberName}</td>
                            )}

                            <td>
                              {memTransactionData.loanPayablePrincipal || 0}
                            </td>
                            <td>
                              {memTransactionData.loanPayableInterest || 0}
                            </td>
                            <td>{memTransactionData.loanTakenAmt}</td>
                            <td>{memTransactionData.loanPaid}</td>
                            <td>{memTransactionData.interestPaid}</td>
                            <td>{memTransactionData.otherLoanTakenAmt}</td>
                            <td>{memTransactionData.otherLoanPaid}</td>
                            <td>{memTransactionData.otherInterestPaid}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
                <button
                  className="btn sub_form btn_continue Save float-right"
                  onClick={onfinalSubmit}
                >
                  SUBMIT
                </button>
              </div>
              {/* <div>Tot Internal loan:{loanTakenAmtTot}</div>
              <div>Tot other loan:{otherLoanTakenAmtTot}</div>
              <div>loanPrincipalPaidTot(Internal):{loanPaidTot}</div>
              <div>loanIntrestPaidTot(Internal):{interestPaidTot}</div>
              <div>otherLoanPrincipalPaidTot(OL):{otherLoanPaidTot}</div>
              <div>otherInterestPaidTot(OL):{otherInterestPaidTot}</div> */}
            </section>
          </div>
        </section>

        <Modal
          show={showMemberMeetingDetailsModals}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Edit Meeting Details</h3>
            </div>
            <div className="col-lg-2">
              <button
                onClick={handleMemberMeetingModalCloses}
                className="close"
              >
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditMembermeetingDetailModal
              membersBatch={userDatas}
              userData2={userData2}
              selectedDate={batchIdValue.mdDate}
              onMemberMeetingModalChanges={onMemberMeetingModalChanges}
            />
          </Modal.Body>
        </Modal>
        {showRecheckModal && <></>}
      </div>
      <Modal
        show={submitmeeting}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="logout-modal"
      >
        <Modal.Header className="confirmbox-heading">
          <h4 className="mt-0">Confirmation</h4>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Are you sure want to submit a meeting held on {mdDate && mdDate}?
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn_green_bg"
            onClick={() => onSubmitMeeting()}
          >
            YES
          </button>
          <button
            className="btn btn_green_bg"
            onClick={() => handleLogoutModalClose(false)}
          >
            NO
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

EditIndivisualMemberMeeting.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  editMeetingBatchTransaction,
  editBatchTransaction,
  getParticularLoanReason,
  getMembersMeetingData,
  getMeetingSummary,
  UpdateMeetingStatus,
  getEditMemberMeetingData,
  getSeletedBatchDetails,
  getActiveBankData,
  getActiveOtherLoanType,
  getExistingOLData,
  getExistingBankData,
  deleteBatchOtherLoan,
  deleteBankData,
  addNewOtherLoanBatchTransaction,
  addNewBankTransaction,
  getSelMeetingDateData,
})(EditIndivisualMemberMeeting);
