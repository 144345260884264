import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import ChangeMeetingDate from "./ChangeMeetingDate";

import {
  getVerifiedMeetingDetails,
  getMeetingTransactionData,
  batchAndMeetingDetails,
  unsetCheckAvailableMeetingDate,
  getLastMeetingDate,
} from "../../actions/shg";

const AllVerfiedMeetings = ({
  auth: { isAuthenticated, user, users },
  shg: { verifiedmeetingdetails },
  getVerifiedMeetingDetails,
  getMeetingTransactionData,
  batchAndMeetingDetails,
  unsetCheckAvailableMeetingDate,
  getLastMeetingDate,
}) => {
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  useEffect(() => {
    getVerifiedMeetingDetails(batchIdValue);
    // getLastMeetingDate();
  }, [getVerifiedMeetingDetails]);

  const handleVerifyEditBatch = (verifiedmeetingdetails) => {
    localStorage.removeItem("batchTransactionData");
    const batchIdData = {
      batchId: verifiedmeetingdetails.mdBatchId,
      batchTransactionType: verifiedmeetingdetails.batchTransactionType,
      mdDate: verifiedmeetingdetails.mdDate,
      mdBatchId: verifiedmeetingdetails.mdBatchId,
      batchIdVal: batchIdValue.batchIdVal,
      batchName: batchIdValue.batchName,
      callFrom: "Report",
    };
    let meetingBatchInfo = batchIdData;
    getMeetingTransactionData(meetingBatchInfo);
    batchAndMeetingDetails(batchIdData);
  };

  // To change meeting date option

  const [userData1, setUserData1] = useState(null);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const handleChangeModalClose = () => setShowChangeModal(false);

  const onChangeModalChange = (e) => {
    if (e) {
      handleChangeModalClose();
    }
  };
  const MIN_DATE = "2021-01-01";

  const [previousData, setPreviousDate] = useState("");
  const [aheadDate, setAheadDate] = useState("");

  const onChangeMeetingDate = (verifiedmeeting, idx) => {
    if (idx != 0 && idx !== verifiedmeetingdetails.length - 1) {
      setPreviousDate(verifiedmeetingdetails[idx - 1]);
      setAheadDate(verifiedmeetingdetails[idx + 1]);

      unsetCheckAvailableMeetingDate();
      setShowChangeModal(true);
      setUserData1(verifiedmeeting);
    } else if (idx === 0) {
      setPreviousDate({ mdDate: MIN_DATE.toString() });
      setAheadDate(
        verifiedmeetingdetails[idx + 1] || new Date().toISOString().slice(0, 10)
      );
      unsetCheckAvailableMeetingDate();
      setShowChangeModal(true);
      setUserData1(verifiedmeeting);
    } else {
      setPreviousDate(verifiedmeetingdetails[idx - 1]);
      setAheadDate(verifiedmeetingdetails[idx]);
      unsetCheckAvailableMeetingDate();
      setShowChangeModal(true);
      setUserData1(verifiedmeeting);
    }
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">
            {" "}
            Meetings of {batchIdValue.batchName}
          </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 py-3">
              <Link to="/batchwise-report">
                <img
                  className="img_icon_size log float-right"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Meeting Date</th>
                        <th>Entered By</th>
                        <th>Change Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {verifiedmeetingdetails &&
                        verifiedmeetingdetails.map(
                          (verifiedmeetingdetails, idx) => {
                            var ED = verifiedmeetingdetails.mdDate.split(/\D/g);
                            var mdDate = [ED[2], ED[1], ED[0]].join("-");
                            return (
                              <tr key={idx}>
                                <td>
                                  {" "}
                                  <Link
                                    to={{
                                      pathname:
                                        verifiedmeetingdetails &&
                                        verifiedmeetingdetails.batchTransactionType ===
                                          "Memberwise"
                                          ? "/verification-batch-membermeeting"
                                          : "/verification-batchmeeting",
                                    }}
                                    className="btnLink"
                                    onClick={() =>
                                      handleVerifyEditBatch(
                                        verifiedmeetingdetails
                                      )
                                    }
                                  >
                                    {mdDate}
                                  </Link>
                                </td>
                                <td>
                                  {verifiedmeetingdetails.mdEnteredByName}
                                </td>

                                <td>
                                  {user && user.userGroupName == "Admin" ? (
                                    <img
                                      className="img_icon_size log"
                                      onClick={() =>
                                        onChangeMeetingDate(
                                          verifiedmeetingdetails,
                                          idx
                                        )
                                      }
                                      src={require("../../static/images/changecode.png")}
                                      alt="Change Meeting Date"
                                      title="Change Meeting Date"
                                    />
                                  ) : (
                                    "Only Admin Can Change Date"
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-md-6 col-sm-11 col-11 align_right">
                  <label>
                    <b>Meeting Count: {verifiedmeetingdetails.length}</b>
                  </label>
                </div>
              </section>
            </div>
          </div>
        </section>

        <Modal
          show={showChangeModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10 col-md-10 col-sm-10 col-10">
              <h3 className="modal-title text-center">Change Meeting Date </h3>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
              <button onClick={handleChangeModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <ChangeMeetingDate
              batchesdata={userData1}
              onChangeModalChange={onChangeModalChange}
              previousDate={previousData}
              aheadDate={aheadDate}
            />
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

AllVerfiedMeetings.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getVerifiedMeetingDetails: PropTypes.func.isRequired,
  getMeetingTransactionData: PropTypes.func.isRequired,
  batchAndMeetingDetails: PropTypes.func.isRequired,
  unsetCheckAvailableMeetingDate: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getVerifiedMeetingDetails,
  getMeetingTransactionData,
  batchAndMeetingDetails,
  unsetCheckAvailableMeetingDate,
  getLastMeetingDate,
})(AllVerfiedMeetings);
