import React, { useState } from "react";
import Select from "react-select";
const BatchExpenseTab = ({
  NextBackBtn,
  formData,
  setFormData,
  activeBank,
  allPermission,
}) => {
  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const [expenseFormData, setExpenseFormData] = useState({
    travelExpensesCash:
      formData.travellingExpenses && formData.travellingExpenses.cash,
    travelExpensesBank:
      formData.travellingExpenses && formData.travellingExpenses.bankAmt,
    travelExpensesBankName: formData.travellingExpenses.bankName
      ? {
          label: formData.travellingExpenses.bankName,
          value: formData.travellingExpenses.bankName,
        }
      : "",

    StationaryCash:
      formData.stationaryExpenses && formData.stationaryExpenses.cash,
    StationaryBank:
      formData.stationaryExpenses && formData.stationaryExpenses.bankAmt,
    StationaryBankName:
      formData.stationaryExpenses && formData.stationaryExpenses.bankName
        ? {
            label: formData.stationaryExpenses.bankName,
            value: formData.stationaryExpenses.bankName,
          }
        : "",

    paidToMahasanghaCash:
      formData.paidToMahasangha && formData.paidToMahasangha.cash,
    paidToMahasanghaBank:
      formData.paidToMahasangha && formData.paidToMahasangha.bankAmt,
    paidToMahasanghaBankName:
      formData.paidToMahasangha && formData.paidToMahasangha.bankName
        ? {
            label: formData.paidToMahasangha.bankName,
            value: formData.paidToMahasangha.bankName,
          }
        : "",

    serviceChargesCash: formData.serviceCharges && formData.serviceCharges.cash,
    serviceChargesBank:
      formData.serviceCharges && formData.serviceCharges.bankAmt,
    serviceChargesBankName:
      formData.serviceCharges && formData.serviceCharges.bankName
        ? {
            label: formData.serviceCharges.bankName,
            value: formData.serviceCharges.bankName,
          }
        : "",

    batchDividendDistributedCash:
      formData.batchDividendDistributed &&
      formData.batchDividendDistributed.cash,

    batchDividendDistributedBank:
      formData.batchDividendDistributed &&
      formData.batchDividendDistributed.bankAmt,

    batchDividendDistributedBankName:
      formData.batchDividendDistributed &&
      formData.batchDividendDistributed.bankName
        ? {
            label: formData.batchDividendDistributed.bankName,
            value: formData.batchDividendDistributed.bankName,
          }
        : "",

    otherExpensesCash: formData.otherExpenses && formData.otherExpenses.cash,

    otherExpensesBank: formData.otherExpenses && formData.otherExpenses.bankAmt,

    otherExpensesBankName:
      formData.otherExpenses && formData.otherExpenses.bankName
        ? {
            label: formData.otherExpenses.bankName,
            value: formData.otherExpenses.bankName,
          }
        : "",

    batchSavingWithdrawalsCash:
      formData.batchSavingWithdrawals && formData.batchSavingWithdrawals.cash,

    batchSavingWithdrawalsBank:
      formData.batchSavingWithdrawals &&
      formData.batchSavingWithdrawals.bankAmt,

    batchSavingWithdrawalsBankName:
      formData.batchSavingWithdrawals &&
      formData.batchSavingWithdrawals.bankName
        ? {
            label: formData.batchSavingWithdrawals.bankName,
            value: formData.batchSavingWithdrawals.bankName,
          }
        : "",

    batchSecurityDepositCash:
      formData.batchSecurityDeposit && formData.batchSecurityDeposit.cash,

    batchSecurityDepositBank:
      formData.batchSecurityDeposit && formData.batchSecurityDeposit.bankAmt,

    batchSecurityDepositBankName:
      formData.batchSecurityDeposit && formData.batchSecurityDeposit.bankName
        ? {
            label: formData.batchSecurityDeposit.bankName,
            value: formData.batchSecurityDeposit.bankName,
          }
        : "",

    donationToOutsiderCash:
      formData.donationToOutsider && formData.donationToOutsider.cash,

    donationToOutsiderBank:
      formData.donationToOutsider && formData.donationToOutsider.bankAmt,

    donationToOutsiderBankName:
      formData.donationToOutsider && formData.donationToOutsider.bankName
        ? {
            label: formData.donationToOutsider.bankName,
            value: formData.donationToOutsider.bankName,
          }
        : "",

    sdSHGToUnionCash: formData.sdSHGToUnion && formData.sdSHGToUnion.cash,
    sdSHGToUnionBank: formData.sdSHGToUnion && formData.sdSHGToUnion.bankAmt,

    sdSHGToUnionBankName:
      formData.sdSHGToUnion && formData.sdSHGToUnion.bankName
        ? {
            label: formData.sdSHGToUnion.bankName,
            value: formData.sdSHGToUnion.bankName,
          }
        : "",

    loanGivenToOtherSHGCash:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.cash,

    loanGivenToOtherSHGBank:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.bankAmt,

    loanGivenToOtherSHGBankName:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.bankName
        ? {
            label: formData.loanGivenToOtherSHG.bankName,
            value: formData.loanGivenToOtherSHG.bankName,
          }
        : "",
    internalBankExpense: formData.internalBankExpense,
  });

  const {
    travelExpensesBankName,
    travelExpensesBank,
    travelExpensesCash,

    StationaryBankName,
    StationaryBank,
    StationaryCash,

    paidToMahasanghaBankName,
    paidToMahasanghaBank,
    paidToMahasanghaCash,

    serviceChargesBankName,
    serviceChargesBank,
    serviceChargesCash,

    batchDividendDistributedBankName,
    batchDividendDistributedBank,
    batchDividendDistributedCash,

    otherExpensesBankName,
    otherExpensesBank,
    otherExpensesCash,

    batchSavingWithdrawalsBankName,
    batchSavingWithdrawalsBank,
    batchSavingWithdrawalsCash,

    batchSecurityDepositBankName,
    batchSecurityDepositBank,
    batchSecurityDepositCash,

    donationToOutsiderBankName,
    donationToOutsiderBank,
    donationToOutsiderCash,

    sdSHGToUnionBankName,
    sdSHGToUnionBank,
    sdSHGToUnionCash,

    loanGivenToOtherSHGBankName,
    loanGivenToOtherSHGBank,
    loanGivenToOtherSHGCash,

    internalBankExpense,
  } = expenseFormData;

  const onInputChange = (e, labelName = "") => {
    if (labelName == "") {
      setExpenseFormData({
        ...expenseFormData,
        [e.target.name]: e.target.value,
      });
    } else {
      setExpenseFormData({ ...expenseFormData, [labelName]: e });
    }
  };

  const expenseNextTab = (e) => {
    e.preventDefault();
    const finalExpenseData = {
      travellingExpenses: {
        cash: Number(travelExpensesCash),
        bankAmt: Number(travelExpensesBank),
        bankName: travelExpensesBankName.label
          ? travelExpensesBankName.label
          : "",
      },

      stationaryExpenses: {
        cash: Number(StationaryCash),
        bankAmt: Number(StationaryBank),
        bankName: StationaryBankName.label ? StationaryBankName.label : "",
      },

      paidToMahasangha: {
        cash: Number(paidToMahasanghaCash),
        bankAmt: Number(paidToMahasanghaBank),
        bankName: paidToMahasanghaBankName.label
          ? paidToMahasanghaBankName.label
          : "",
      },

      serviceCharges: {
        cash: Number(serviceChargesCash),
        bankAmt: Number(serviceChargesBank),
        bankName: serviceChargesBankName.label
          ? serviceChargesBankName.label
          : "",
      },

      batchDividendDistributed: {
        cash: Number(batchDividendDistributedCash),
        bankAmt: Number(batchDividendDistributedBank),
        bankName: batchDividendDistributedBankName.label
          ? batchDividendDistributedBankName.label
          : "",
      },

      otherExpenses: {
        cash: Number(otherExpensesCash),
        bankAmt: Number(otherExpensesBank),
        bankName: otherExpensesBankName.label
          ? otherExpensesBankName.label
          : "",
      },

      batchSavingWithdrawals: {
        cash: Number(batchSavingWithdrawalsCash),
        bankAmt: Number(batchSavingWithdrawalsBank),
        bankName: batchSavingWithdrawalsBankName.label
          ? batchSavingWithdrawalsBankName.label
          : "",
      },

      batchSecurityDeposit: {
        cash: Number(batchSecurityDepositCash),
        bankAmt: Number(batchSecurityDepositBank),
        bankName: batchSecurityDepositBankName.label
          ? batchSecurityDepositBankName.label
          : "",
      },

      // req flag
      donationToOutsider: {
        cash: Number(donationToOutsiderCash),
        bankAmt: Number(donationToOutsiderBank),
        bankName: donationToOutsiderBankName.label
          ? donationToOutsiderBankName.label
          : "",
      },

      sdSHGToUnion: {
        cash: Number(sdSHGToUnionCash),
        bankAmt: Number(sdSHGToUnionBank),
        bankName: sdSHGToUnionBankName.label ? sdSHGToUnionBankName.label : "",
      },

      loanGivenToOtherSHG: {
        cash: Number(loanGivenToOtherSHGCash),
        bankAmt: Number(loanGivenToOtherSHGBank),
        bankName: loanGivenToOtherSHGBankName.label
          ? loanGivenToOtherSHGBankName.label
          : "",
      },

      internalBankExpense: internalBankExpense,
    };

    setFormData({ ...formData, ...finalExpenseData });
    NextBackBtn(4);
  };

  return (
    <>
      <form onSubmit={(e) => expenseNextTab(e)}>
        <div className="row card-new  py-3 col-12">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <h5>Expense</h5>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">Travelling Expense :</label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="travelExpensesBankName"
                      options={activeBank}
                      value={travelExpensesBankName}
                      onChange={(e) =>
                        onInputChange(e, "travelExpensesBankName")
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount
                    <input
                      type="number"
                      name="travelExpensesBank"
                      value={travelExpensesBank}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      disabled={travelExpensesBankName ? false : true}
                      // onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="travelExpensesCash"
                  value={travelExpensesCash}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <label className="label-control">Stationary Expense :</label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {" "}
                    Bank List
                    <Select
                      name="StationaryBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={StationaryBankName}
                      placeholder="Select Bank"
                      onChange={(e) => onInputChange(e, "StationaryBankName")}
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>{" "}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="StationaryBank"
                      value={StationaryBank}
                      disabled={StationaryBankName ? false : true}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="StationaryCash"
                  value={StationaryCash}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <label className="label-control">
              Paid to Union (Mahasangha) &nbsp;
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title="Amount Paid /Given to the Union/Mahasanga/Federation/General HUB"
              />{" "}
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {" "}
                    Bank List
                    <Select
                      name="paidToMahasanghaBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={paidToMahasanghaBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "paidToMahasanghaBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="paidToMahasanghaBank"
                      value={paidToMahasanghaBank}
                      disabled={paidToMahasanghaBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="paidToMahasanghaCash"
                  value={paidToMahasanghaCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <label className="label-control">Service Charge : </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {" "}
                    Bank List
                    <Select
                      name="serviceChargesBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={serviceChargesBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "serviceChargesBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="serviceChargesBank"
                      value={serviceChargesBank}
                      disabled={serviceChargesBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="serviceChargesCash"
                  value={serviceChargesCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            {/* 123 */}
            <label className="label-control">
              Dividend Distributed{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title="Profit Distribution to Members"
              />{" "}
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchDividendDistributedBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={batchDividendDistributedBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "batchDividendDistributedBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>{" "}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchDividendDistributedBank"
                      value={batchDividendDistributedBank}
                      disabled={batchDividendDistributedBankName ? false : true}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      // onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              {" "}
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="batchDividendDistributedCash"
                  value={batchDividendDistributedCash}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            {allPermission && allPermission.includes("SHG-to-SHGLoan") && (
              <>
                <label className="label-control">Sugamya Bank savings:</label>
                <div className="row col-12  ">
                  <div className="col-lg-6 col-sm-12">
                    <input
                      type="number"
                      name="internalBankExpense"
                      value={internalBankExpense}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">Other Expense :</label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {" "}
                    Bank List
                    <Select
                      name="otherExpensesBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={otherExpensesBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "otherExpensesBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>{" "}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="otherExpensesBank"
                      value={otherExpensesBank}
                      disabled={otherExpensesBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="otherExpensesCash"
                  value={otherExpensesCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <label className="label-control">
              Savings Withdrawal{" "}
              <img
                className="img_icon_size log"
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title=" Withdrawal of Saving Amount"
              />{" "}
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchSavingWithdrawalsBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={batchSavingWithdrawalsBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "batchSavingWithdrawalsBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchSavingWithdrawalsBank"
                      value={batchSavingWithdrawalsBank}
                      disabled={batchSavingWithdrawalsBankName ? false : true}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      // onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="batchSavingWithdrawalsCash"
                  value={batchSavingWithdrawalsCash}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            <label className="label-control">
              SD SHG To Member{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title="Security Deposit Amount Received from SHG to Members"
              />{" "}
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchSecurityDepositBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={batchSecurityDepositBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "batchSecurityDepositBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchSecurityDepositBank"
                      value={batchSecurityDepositBank}
                      disabled={batchSecurityDepositBankName ? false : true}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      // onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="batchSecurityDepositCash"
                  value={batchSecurityDepositCash}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  // onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            <label className="label-control">
              Donation To Outsider{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title=" Donation/Contribution to the Outside from the SHG"
              />
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="donationToOutsiderBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={donationToOutsiderBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "donationToOutsiderBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="donationToOutsiderBank"
                      value={donationToOutsiderBank}
                      disabled={donationToOutsiderBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      // onKeyDown={(e) =>
                      //   (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
                      // }
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="donationToOutsiderCash"
                  value={donationToOutsiderCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 || e.keyCode === 190) &&
                    e.preventDefault()
                  }
                />
              </div>
            </div>

            <label className="label-control">
              SD SHG To Union{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title=" Security Deposit Amount from SHG to Union"
              />{" "}
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  {" "}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="sdSHGToUnionBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={sdSHGToUnionBankName}
                      placeholder="Select Bank"
                      onChange={(e) => onInputChange(e, "sdSHGToUnionBankName")}
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>{" "}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="sdSHGToUnionBank"
                      value={sdSHGToUnionBank}
                      disabled={sdSHGToUnionBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      // onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="sdSHGToUnionCash"
                  onChange={(e) => onInputChange(e)}
                  value={sdSHGToUnionCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  // onChange={(e) => onInputChange(e)}
                  // onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
              <>
                <label className="label-control">
                  Loan Given To Other SHG :
                </label>

                {allPermission && allPermission.includes("batchCashBank") && (
                  <>
                    <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        Bank List
                        <Select
                          name="loanGivenToOtherSHGBankName"
                          options={activeBank}
                          isSearchable={true}
                          value={loanGivenToOtherSHGBankName}
                          placeholder="Select Bank"
                          onChange={(e) =>
                            onInputChange(e, "loanGivenToOtherSHGBankName")
                          }
                          theme={(theme) => ({
                            ...theme,
                            height: 26,
                            minHeight: 26,
                            borderRadius: 1,
                            colors: {
                              ...theme.colors,
                              primary: "black",
                            },
                          })}
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        Bank Amount{" "}
                        <input
                          type="number"
                          name="loanGivenToOtherSHGBank"
                          value={loanGivenToOtherSHGBank}
                          disabled={loanGivenToOtherSHGBankName ? false : true}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          // onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Cash
                    <input
                      type="number"
                      name="loanGivenToOtherSHGCash"
                      value={loanGivenToOtherSHGCash}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      // onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <button
            type="submit"
            className="btn sub_form btn_continue Save float-right"
            // onClick={() => expenseNextTab()}
          >
            Next
          </button>

          <button
            type="button"
            className="btn sub_form btn_continue Save float-right"
            onClick={() => NextBackBtn(2)}
          >
            Previous
          </button>
        </div>
      </form>
    </>
  );
};

export default BatchExpenseTab;
