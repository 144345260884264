export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const ALL_USERS = "ALL_USERS";
export const TENANT_ADD_INIT = "TENANT_ADD_INIT";
export const SHOP_ADD_INIT = "SHOP_ADD_INIT";
export const AGREEMENT_ADD_INIT = "AGREEMENT_ADD_INIT";
export const FORGOT_PWD_SUCCESS = "FORGOT_PWD_SUCCESS";
export const FORGOT_PWD_FAIL = "FORGOT_PWD_FAIL";
export const CHANGE_PWD_FAIL = "CHANGE_PWD_FAIL";
export const TENANT_FEEDBACK_ERROR = "TENANT_FEEDBACK_ERROR";
export const GET_ALL_SHOPS = "GET_ALL_SHOPS";
export const GET_ALL_TENANTS = "GET_ALL_TENANTS";
export const GET_ALL_SETTINGS = "GET_ALL_SETTINGS";
export const GET_DOORNOS = "GET_DOORNOS";
export const NEW_TENENTDETAILS = "NEW_TENENTDETAILS";
export const MONTH_EXP_CNT = "MONTH_EXP_CNT";
export const YEAR_EXP_CNT = "YEAR_EXP_CNT";
export const EXP_REPORT = "EXP_REPORT";
export const GET_DELAYS = "GET_DELAYS";
export const GET_DOORNUMBER = "GET_DOORNUMBER";
export const ADD_USER_INIT = "ADD_USER_INIT";
export const GET_ALL_USER = "GET_ALL_USER";
export const OTP_SENT = "OTP_SENT";
export const SET_LOADING_TRUE = "SET_LOADING_TRUE";
export const FINAL_DATA_REP = "FINAL_DATA_REP";

//For SHG
export const AREA_ADD_INIT = "AREA_ADD_INIT";
export const USER_ADD_INIT = "USER_ADD_INIT";
export const SHG_ADD_INIT = "SHG_ADD_INIT";
export const ALL_INSTITUTIONS = "ALL_INSTITUTIONS";
export const ACTIVE_INSTITUTIONS = "ACTIVE_INSTITUTIONS";
export const USER_GROUPS = "USER_GROUPS";
export const SAVED = "SAVED";
export const STATES = "STATES";
export const ALL_VARADOS = "ALL_VARADOS";
export const ALL_PARISH = "ALL_PARISH";
export const ALL_STATE = "ALL_STATE";
export const ALL_DISTRICT = "ALL_DISTRICT";
export const ISCORDINATOR = "ISCORDINATOR";
export const ALL_USER_DETAILS = "ALL_USER_DETAILS";
export const ALL_LOAN_REASON = "ALL_LOAN_REASON";
export const VARADOS = "VARADOS";
export const ALL_BATCHES = "ALL_BATCHES";
export const MEMBER_COUNT = "MEMBER_COUNT";
export const MEMBER_GEN_COUNT = "MEMBER_GEN_COUNT";
export const BATCH_COUNT = "BATCH_COUNT";
export const YEAR_COUNT = "YEAR_COUNT";
export const INSTITUTION_PROFILE = "INSTITUTION_PROFILE";
export const USER_BATCH = "USER_BATCH";
export const SHG_MEMBERS = "SHG_MEMBERS";
export const ALL_INCHARGE_DETAILS = "ALL_INCHARGE_DETAILS";
export const ALL_COORDINATOR_DETAILS = "ALL_COORDINATOR_DETAILS";
export const DISTRICT = "DISTRICT";
export const PARISH = "PARISH";
export const LOAN_REASON = "LOAN_REASON";
export const ALL_MEMBER_MEETING_BATCHES = "ALL_MEMBER_MEETING_BATCHES";
export const ALL_BATCHES_DROPDOWN = "ALL_BATCHES_DROPDOWN";
export const ALL_MEMBER_OF_BATCH = "ALL_MEMBER_OF_BATCH";
export const SELECTED_BATCH_DATA = "SELECTED_BATCH_DATA";
export const MEETINGMSG = "MEETINGMSG";
export const MEETINGCHECKMSG = "MEETINGCHECKMSG";
export const LAST_MEETING = "LAST_MEETING";
export const PARTIAL_MEETINGS = "PARTIAL_MEETINGS";
export const BATCH_ID_DATA = "BATCH_ID_DATA";
export const VERIFICATION = "VERIFICATION";
export const VERIFICATION_DROPDOWN = "VERIFICATION_DROPDOWN";
export const PARTIAL_MEETINGS_DROPDOWN = "PARTIAL_MEETINGS_DROPDOWN";
export const BATCH_DROPDOWN = "BATCH_DROPDOWN";
export const INSTITUTION_DATA = "INSTITUTION_DATA";
export const MEMBERS_MEETING = "MEMBERS_MEETING";
export const COMPLETED_MEETINGS = "COMPLETED_MEETINGS";
export const MEMBER_OR_BATCHWISE_BATCHES = "MEMBER_OR_BATCHWISE_BATCHES";
export const MEMBER_OR_BATCH_BATCHES_DD = "MEMBER_OR_BATCH_BATCHES_DD";
export const SHG_BATCH_INFO = "SHG_BATCH_INFO";
export const PREVDATA = "PREVDATA";
export const SET_LOADING_FALSE = "SET_LOADING_FALSE";
export const PARTIAL_MEETING_COUNT = "PARTIAL_MEETING_COUNT";
export const UNVERIFIED_MEETING_COUNT = "UNVERIFIED_MEETING_COUNT";
export const SUMMARY_DATA = "SUMMARY_DATA";
export const SELECT_BATCH_MEMBER = "SELECT_BATCH_MEMBER";
export const BATCHWISE_REPORT = "BATCHWISE_REPORT";
export const BATCHWISE_REPORT_SUM = "BATCHWISE_REPORT_SUM";
export const INCOME_EXPENSE_REPORT = "INCOME_EXPENSE_REPORT";
export const BATCH_SAVING_AMOUNT_COUNT = "BATCH_SAVING_AMOUNT_COUNT";
export const COMPLETED_MEETING_DETAILS = "COMPLETED_MEETING_DETAILS";
export const BATCHTRANSACTION = "BATCHTRANSACTION";
export const LOANSANCTIONEDDATA = "LOANSANCTIONEDDATA";
export const MEMBERS_EDIT_DATA = "MEMBERS_EDIT_DATA";
export const BATCH_OF_PARISH = "BATCH_OF_PARISH";
export const SHG_MEMBERS_FOR_MEMBERS = "SHG_MEMBERS_FOR_MEMBERS";
export const INCOME_EXPENSE_REPORT_FINYEAR = "INCOME_EXPENSE_REPORT_FINYEAR";
export const VERIFIED_MEETING_DETAILS = "VERIFIED_MEETING_DETAILS";
export const CLOSINGAMT_DATA = "CLOSINGAMT_DATA";
export const CHECK_USER = "CHECK_USER";
export const OTP_LOGIN_SUCCESS = "OTP_LOGIN_SUCCESS";
export const BATCH_OF_DISTRICT = "BATCH_OF_DISTRICT";
export const ALL_INSTITUTIONS_AGGREMENT_DETAILS =
  "ALL_INSTITUTIONS_AGGREMENT_DETAILS";
export const INCOME_EXPENSE_OPBAL = "INCOME_EXPENSE_OPBAL";
export const SUMMARY_DATA_OP = "SUMMARY_DATA_OP";
export const MEETING_CATEGORY = "MEETING_CATEGORY";
export const INCHARGEREPORT = "INCHARGEREPORT";
export const SHG_CODE_CHECK = "SHG_CODE_CHECK";
export const ALL_LOAN_TYPES = "ALL_LOAN_TYPES";
export const ACTIVE_OTHER_LOANTYPE = "ACTIVE_OTHER_LOANTYPE";
export const GET_BANK_DATA = "GET_BANK_DATA";
export const EXST_OL_DATA = "EXST_OL_DATA";
export const EXST_MEM_OL_DATA = "EXST_MEM_OL_DATA";
export const EXST_BANK_DATA = "EXST_BANK_DATA";
export const ALL_BLOCK = "ALL_BLOCK";
export const BLOCK_DISTRICT = "BLOCK_DISTRICT";
export const GET_BLOCK = "GET_BLOCK";
export const MEMBERS_FAMILY = "MEMBERS_FAMILY";
export const MEMBERS_FAMILY_OCCUPATION = "MEMBERS_FAMILY_OCCUPATION";
export const FAMILY_MEMBERS = "FAMILY_MEMBERS";
export const ALL_OCCUPATION = "ALL_OCCUPATION";
export const DROPDOWN_OCCUPATION = "DROPDOWN_OCCUPATION";
export const OCC_MEMBERS = "OCC_MEMBERS";
export const ALL_AGGREMENT_DETAILS = "ALL_AGGREMENT_DETAILS";
export const ALL_SHG_CATEGORY = "ALL_SHG_CATEGORY";
export const DROPDOWN_ACTIVE_SHG_CATEGORY = "DROPDOWN_ACTIVE_SHG_CATEGORY";
export const BATCHES_MEMBER_COUNT = "BATCHES_MEMBER_COUNT";
export const COUNT_INSTITUTIONS = "COUNT_INSTITUTIONS";
export const MEETING_DATA = "MEETING_DATA";
export const STATECHECKMSG = "STATECHECKMSG";
export const INCHARGE_COUNT = "INCHARGE_COUNT";
export const CORDINATOR_COUNT = "CORDINATOR_COUNT";
export const OTHER_LOAN_TYPE_COUNT = "OTHER_LOAN_TYPE_COUNT";
export const ALL_SUB_OCCUPATION = "ALL_SUB_OCCUPATION ";
export const SUB_OCCUPATION = "SUB_OCCUPATION";
export const FAMILY_SUB_OCCUPATION = "FAMILY_SUB_OCCUPATION";
export const VERIFIED_MEETING_DETAILS_SORT = "VERIFIED_MEETING_DETAILS_SORT";
export const ALL_DEACTIVE_BATCHES = "ALL_DEACTIVE_BATCHES";
export const ALL_ACTIVE_BATCHES = "ALL_ACTIVE_BATCHES";
export const EXST_MEM_LOAN_DATA = "EXST_MEM_LOAN_DATA";
export const EDIT_OTHER_LOAN_TYPE_COUNT = "EDIT_OTHER_LOAN_TYPE_COUNT";
export const BATCH_DETAILS = "BATCH_DETAILS";
export const MEMBERS_PREVIOUS_MEETING = "MEMBERS_PREVIOUS_MEETING";
//Skill
export const ALL_PROGRAMS_DETAILS = "ALL_PROGRAMS_DETAILS";
export const ALL_ACTIVE_PROGRAMS_DETAILS = "ALL_ACTIVE_PROGRAMS_DETAILS";
export const ALL_SKILL_REPORT_DETAILS = "ALL_SKILL_REPORT_DETAILS";
export const ALL_SKILL_DETAILS = "ALL_SKILL_DETAILS";
export const SHG_MEMBERS_LIST = "SHG_MEMBERS_LIST";
export const ALL_ACCESS_DETAILS = "ALL_ACCESS_DETAILS";
export const ALL_PERMISSION_DETAILS = "ALL_PERMISSION_DETAILS";
export const ALL_ACTIVE_MODULE_DETAILS = "ALL_ACTIVE_MODULE_DETAILS";
export const ALL_ACTIVE_PERMISSIONS_DETAILS = "ALL_ACTIVE_PERMISSIONS_DETAILS";

// Religion Setting
export const RELIGIONS = "RELIGIONS";
export const ALL_RELIGION = "ALL_RELIGION";
export const ALL_CASTE = "ALL_CASTE";
export const ALL_SUBCASTE = "ALL_SUBCASTE";
export const CASTE = "CASTE";
export const SUBCASTE_CASTE = "SUBCASTE_CASTE";
export const GET_SUBCASTE = "GET_SUBCASTE";

//loan sancationed data
export const ISSANCTIONED = "ISSANCTIONED";
