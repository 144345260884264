import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  AddInstitutionDetailsform,
  tempUpdate,
  tempUpdate2,
  tempUpdate3,
  tempUpdateBankArray,
} from "../../actions/shg";
import {
  editOnUpdateInstitution,
  getInstitutionData,
  loadUser,
  editOnUpdatepersonal,
} from "../../actions/auth";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import Spinner from "../layout/Spinner";

const MyProfile = ({
  auth: { isAuthenticated, user, users, institutionData, loading },
  getInstitutionData,
  editOnUpdatepersonal,
  editOnUpdateInstitution,
  loadUser,
  tempUpdate,
  tempUpdate2,
  tempUpdate3,
  tempUpdateBankArray,
}) => {
  useEffect(() => {
    getInstitutionData();
  }, [getInstitutionData]);
  useEffect(() => {
    loadUser();
  }, [loadUser]);
  //formData
  const [showInformationModal, setShowInformation] = useState(false);

  const handleInformationModalClose = () => setShowInformation(false);
  const LogoutModalClose = () => {
    handleInformationModalClose();
  };

  const [showUpdate, setShowUpdate] = useState(false);
  const [formData, setFormData] = useState({
    userName: user && user.userName ? user.userName : "",
    userFullName: user && user.userFullName ? user.userFullName : "",
    userEmail: user && user.userEmail ? user.userEmail : "",
    userAddress: user && user.userAddress ? user.userAddress : "",
    institutionName: user && user.institutionName ? user.institutionName : "",
    userGroupName: user && user.userGroupName ? user.userGroupName : "",
    userPhone: user && user.userPhone ? user.userPhone : "",
    institutionId:
      institutionData && institutionData._id ? institutionData._id : "",
    institutionLogo:
      institutionData && institutionData.institutionProfile
        ? institutionData.institutionProfile
        : "",
    institutionName:
      institutionData && institutionData.institutionName
        ? institutionData.institutionName
        : "",
    institutionAddress:
      institutionData && institutionData.institutionAddress
        ? institutionData.institutionAddress
        : "",
    missionStatement:
      institutionData && institutionData.missionStatement
        ? institutionData.missionStatement
        : "",
    aboutOrganization:
      institutionData && institutionData.aboutOrganization
        ? institutionData.aboutOrganization
        : "",
    institutionDesc:
      institutionData && institutionData.institutionDesc
        ? institutionData.institutionDesc
        : "",

    institutionType:
      institutionData && institutionData.institutionType
        ? {
            value: institutionData.institutionType,
            label: institutionData.institutionType,
          }
        : "",

    institutionInterestRate:
      institutionData && institutionData.institutionInterestRate
        ? institutionData.institutionInterestRate
        : "",
    userEmails: "",
    isSubmitted: false,
  });

  const {
    userEmails,
    userName,
    userFullName,
    userEmail,
    userPhone,
    userAddress,
    userGroupName,
    institutionId,
    institutionName,
    institutionAddress,
    missionStatement,
    aboutOrganization,
    institutionDesc,
    institutionType,
    institutionInterestRate,
    institutionLogo,
  } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const UserZone = [
    { value: "Zoned", label: "Zoned" },
    { value: "FreeZone", label: "FreeZone" },
  ];

  const onInstituteTypeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        institutionType: e,
      });
    }
  };

  const onUpdateInsti = () => {
    const finalData = {
      recordId: institutionId,
      institutionName: institutionName,
      institutionAddress: institutionAddress,
      institutionType: institutionType.value,
      institutionInterestRate: institutionInterestRate,
      missionStatement: missionStatement,
      aboutOrganization: aboutOrganization,
      institutionDesc: institutionDesc,
    };
    editOnUpdateInstitution(finalData);
  };

  const onUpdatepersonal = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: user ? user._id : "",
      userFullName: userFullName,
      userEmail: userEmail,
      userPhone: userPhone,
      userAddress: userAddress,
    };

    editOnUpdatepersonal(finalData);
    setShowUpdate(true);
  };
  const onClickReset = () => {
    getInstitutionData("");
  };

  const onTempUpdate = () => {
    tempUpdate();
  };

  const onTempUpdate2 = () => {
    tempUpdate2();
  };
  const onTempUpdate3 = () => {
    tempUpdate3();
  };
  const onTempUpdate0 = () => {
    tempUpdateBankArray();
  };


  const onUpdateProfile = () => {
    setShowUpdate(false);
  
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color"> Profile </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <form  onSubmit={(e) => onUpdatepersonal(e)}>
          <div className="row card-new col-lg-11 col-md-11 col-sm-12 col-12 py-3">
            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label>User Name* :</label>
                <input
                  type="text"
                  name="userName"
                  value={user && user.userName ? user.userName : ""}
                  className="form-control"
                  onChange={(e) => onInputChange(e)}
                  disabled
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Full Name :</label>
                <input
                  type="text"
                  name="userFullName"
                  defaultValue={
                    user && user.userFullName
                      ? user.userFullName
                      : "" || userFullName
                  }
                  className="form-control"
                  onChange={(e) => onInputChange(e)}
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Email :</label>
                <input
                  type="text"
                  name="userEmail"
                  defaultValue={
                    user && user.userEmail ? user.userEmail : "" || userEmail
                  }
                  className="form-control"
                  onChange={(e) => onInputChange(e)}
                                  />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Phone* :</label>
                <input
                  type="text"
                  name="userPhone"
                  defaultValue={
                    user && user.userPhone ? user.userPhone : "" || userPhone
                  }
                  className="form-control"
                  onChange={(e) => onInputChange(e)}
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label>User Group :</label>
                <input
                  type="text"
                  name="userGroupName"
                  value={user && user.userGroupName ? user.userGroupName : ""}
                  className="form-control"
                  onChange={(e) => onInputChange(e)}
                  disabled
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Institution :</label>
                <input
                  type="text"
                  name="institutionName"
                  // value={institutionName}
                  value={
                    institutionData && institutionData.institutionName
                      ? institutionData.institutionName
                      : ""
                  }
                  className="form-control"
                  onChange={(e) => onInputChange(e)}
                  disabled
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label> Address* :</label>
              <textarea
                name="userAddress"
                defaultValue={
                  user && user.userAddress
                    ? user.userAddress
                    : "" || userAddress
                }
                id="institutionAddress"
                className="textarea form-control"
                rows="4"
                placeholder="Address"
                onChange={(e) => onInputChange(e)}
                style={{ width: "100%" }}
                required
              ></textarea>
            </div>
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-12 Savebutton "
              size="lg"
            >
              <button
                variant="success"
                className="btn sub_form btn_continue blackbrd Save float-right"
                // onClick={() => onUpdatepersonal()}
              >
                Update
              </button>
            </div>
          </div>
          </form>
          <div className="row card-new col-lg-11 col-md-11 col-sm-12 col-12 py-3">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              {" "}
              <h2 className="heading_color">Institution Profile </h2>
            </div>

            <div className="row col-lg-6 col-md-12 col-sm-12 col-12 py-3">
              {(user && user.userGroupName === "Admin") ||
              (user && user.userGroupName === "Super Admin") ? (
                <>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Institution Name* :</label>
                    <input
                      type="text"
                      name="institutionName"
                      defaultValue={
                        institutionData && institutionData.institutionName
                          ? institutionData.institutionName
                          : "" || institutionName
                      }
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Interest Rate :</label>
                    <input
                      type="text"
                      name="institutionInterestRate"
                      defaultValue={
                        institutionData &&
                        institutionData.institutionInterestRate
                          ? institutionData.institutionInterestRate
                          : "" || institutionInterestRate
                      }
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </div>
                  {/* {user.userGroupName === "Super Admin" && (
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label>Institution belongs to:</label>
                      <Select
                        name="institutionType"
                        defaultValue={
                          institutionData && institutionData.institutionType
                            ? institutionData.institutionType
                            : "" || institutionType
                        }
                        options={UserZone}
                        isSearchable={false}
                        placeholder="Select Type"
                        onChange={(e) => onInstituteTypeChange(e)}
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                  )} */}

                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <label> Address* :</label>
                    <textarea
                      name="institutionAddress"
                      defaultValue={
                        institutionData && institutionData.institutionAddress
                          ? institutionData.institutionAddress
                          : "" || institutionAddress
                      }
                      id="institutionAddress"
                      className="textarea form-control"
                      rows="4"
                      placeholder="Address"
                      onChange={(e) => onInputChange(e)}
                      style={{ width: "100%" }}
                      required
                    ></textarea>
                  </div>
                  <div className="row col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                    <label>Institution Logo :</label>
                    <div className="form__img-input-container">
                      <img
                        src={`${
                          institutionData && institutionData.institutionProfile
                            ? institutionData.institutionProfile
                            : ""
                        }`}
                        alt=""
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Institution Name* :</label>
                    <input
                      type="text"
                      name="institutionName"
                      defaultValue={
                        institutionData && institutionData.institutionName
                          ? institutionData.institutionName
                          : "" || institutionName
                      }
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      required
                      disabled
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Interest Rate :</label>
                    <input
                      type="text"
                      name="institutionInterestRate"
                      defaultValue={
                        institutionData &&
                        institutionData.institutionInterestRate
                          ? institutionData.institutionInterestRate
                          : "" || institutionInterestRate
                      }
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      required
                      disabled
                    />
                  </div>
                  {user.userGroupName === "Super Admin" && (
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label>Institution belongs to :</label>
                      <Select
                        name="institutionType"
                        defaultValue={
                          institutionData && institutionData.institutionType
                            ? institutionData.institutionType
                            : "" || institutionType
                        }
                        options={UserZone}
                        isSearchable={false}
                        placeholder="Select Type"
                        onChange={(e) => onInstituteTypeChange(e)}
                        disabled
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                  )}

                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <label> Address* :</label>
                    <textarea
                      name="institutionAddress"
                      defaultValue={
                        institutionData && institutionData.institutionAddress
                          ? institutionData.institutionAddress
                          : "" || institutionAddress
                      }
                      id="institutionAddress"
                      className="textarea form-control"
                      rows="4"
                      placeholder="Address"
                      onChange={(e) => onInputChange(e)}
                      style={{ width: "100%" }}
                      required
                      disabled
                    ></textarea>
                  </div>
                  <div className="row col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                    <label>Institution Logo :</label>
                    <div className="form__img-input-container">
                      <img
                        src={`${
                          institutionData && institutionData.institutionProfile
                            ? institutionData.institutionProfile
                            : ""
                        }`}
                        alt=""
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className=" col-lg-6 col-md-12 col-sm-12 col-12 py-3">
              {(user && user.userGroupName === "Admin") ||
              (user && user.userGroupName === "Super Admin") ? (
                <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Mission* :</label>
                    <textarea
                      name="missionStatement"
                      defaultValue={
                        institutionData && institutionData.missionStatement
                          ? institutionData.missionStatement
                          : "" || missionStatement
                      }
                      id="missionStatement"
                      className="textarea form-control"
                      rows="3"
                      placeholder="Mission"
                      onChange={(e) => onInputChange(e)}
                      style={{ width: "100%" }}
                      required
                    ></textarea>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>About :</label>
                    <textarea
                      name="aboutOrganization"
                      defaultValue={
                        institutionData && institutionData.aboutOrganization
                          ? institutionData.aboutOrganization
                          : "" || aboutOrganization
                      }
                      id="aboutOrganization"
                      className="textarea form-control"
                      rows="3"
                      placeholder="About"
                      onChange={(e) => onInputChange(e)}
                      style={{ width: "100%" }}
                      required
                    ></textarea>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Additional Info :</label>
                    <textarea
                      name="institutionDesc"
                      defaultValue={
                        institutionData && institutionData.institutionDesc
                          ? institutionData.institutionDesc
                          : "" || institutionDesc
                      }
                      id="institutionDesc"
                      className="textarea form-control"
                      rows="3"
                      placeholder="Additional Info"
                      onChange={(e) => onInputChange(e)}
                      style={{ width: "100%" }}
                      required
                    ></textarea>
                  </div>

                  {(user.userGroupName &&
                    user.userGroupName === "Super Admin") ||
                  user.userGroupName === "Admin" ? (
                    <div
                      className="col-lg-12 col-md-12 col-sm-12 col-12 Savebutton "
                      size="lg"
                    >
                      <button
                        variant="success"
                        className="btn sub_form btn_continue blackbrd Save float-right"
                        onClick={() => onUpdateInsti()}
                        style={
                          
                          institutionName !== "" &&
                          institutionAddress !== "" &&
                          institutionInterestRate !== "" &&
                          institutionDesc !== "" &&
                          missionStatement !== "" &&
                          aboutOrganization !== ""
                            ? { opacity: "1" }
                            : { opacity: "1", pointerEvents: "none",cursor:"pointer" }
                        }
                      >
                        Update
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <>
                  <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <label>Mission* :</label>
                      <textarea
                        name="missionStatement"
                        defaultValue={
                          institutionData && institutionData.missionStatement
                            ? institutionData.missionStatement
                            : "" || missionStatement
                        }
                        id="missionStatement"
                        className="textarea form-control"
                        rows="3"
                        placeholder="Mission"
                        onChange={(e) => onInputChange(e)}
                        style={{ width: "100%" }}
                        required
                        disabled
                      ></textarea>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <label>About :</label>
                      <textarea
                        name="aboutOrganization"
                        defaultValue={
                          institutionData && institutionData.aboutOrganization
                            ? institutionData.aboutOrganization
                            : "" || aboutOrganization
                        }
                        id="aboutOrganization"
                        className="textarea form-control"
                        rows="3"
                        placeholder="About"
                        onChange={(e) => onInputChange(e)}
                        style={{ width: "100%" }}
                        required
                        disabled
                      ></textarea>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <label>Additional Info :</label>
                      <textarea
                        name="institutionDesc"
                        defaultValue={
                          institutionData && institutionData.institutionDesc
                            ? institutionData.institutionDesc
                            : "" || institutionDesc
                        }
                        id="institutionDesc"
                        className="textarea form-control"
                        rows="3"
                        placeholder="Additional Info"
                        onChange={(e) => onInputChange(e)}
                        style={{ width: "100%" }}
                        required
                        disabled
                      ></textarea>
                    </div>

                    {(user.userGroupName &&
                      user.userGroupName === "Super Admin") ||
                    user.userGroupName === "Admin" ? (
                      <div
                        className="col-lg-12 col-md-12 col-sm-12 col-12 Savebutton "
                        size="lg"
                      >
                        <button
                          variant="success"
                          className="btn sub_form btn_continue blackbrd Save float-right"
                          onClick={() => onUpdateInsti()}
                          style={
                            institutionName !== "" &&
                            institutionAddress !== "" &&
                            institutionInterestRate !== "" &&
                            institutionDesc !== "" &&
                            missionStatement !== "" &&
                            aboutOrganization !== ""
                              ? { opacity: "1" }
                              : { opacity: "1", pointerEvents: "none" }
                          }
                        >
                          Update
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        {user && user.userGroupName === "Super Admin" ? (
          <>
            <button
              variant="success"
              className="btn sub_form btn_continue blackbrd Save float-right"
              onClick={() => onTempUpdate0()}
            >
              {loading ? "Loading 0" : "TEMP B"}
            </button>
            <button
              variant="success"
              className="btn sub_form btn_continue blackbrd Save float-right"
              onClick={() => onTempUpdate()}
            >
              {loading ? "Loading" : "TEMP BT"}
            </button>
            <button
              variant="success"
              className="btn sub_form btn_continue blackbrd Save float-right"
              onClick={() => onTempUpdate2()}
            >
              {loading ? "Loading 2" : "TEMP OL"}
            </button>
            <button
              variant="success"
              className="btn sub_form btn_continue blackbrd Save float-right"
              onClick={() => onTempUpdate3()}
            >
              {loading ? "Loading 3" : "TEMP MT"}
            </button>
          </>
        ) : (
          ""
        )}
        <Modal
          show={showInformationModal}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="logout-modal"
        >
          <Modal.Header className="confirmbox-heading">
            <h4 className="mt-0">Information</h4>
          </Modal.Header>
          <Modal.Body>
            <h5>Institution Details Added!!</h5>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn_green_bg"
              onClick={() => LogoutModalClose()}
            >
              OK
            </button>
          </Modal.Footer>
        </Modal>




          {/* on profile chnage show a pop up */}
      <Modal
        show={showUpdate}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="logout-modal"
      >
        <Modal.Header className="confirmbox-heading">
          <h4 className="mt-0">Profile updated</h4>
        </Modal.Header>
        <Modal.Body>
          <h5>Your profile has been Updated</h5>
        </Modal.Body>
        <Modal.Footer>
         
          <button
            className="btn contact_reg "
            style={{backgroundColor:"#144c5a"}}
            onClick={
              () => {
                onUpdateProfile();
              }
            }
          >
            OK
          </button>
        </Modal.Footer>  
      </Modal>
      </div>
    </Fragment>
  );
};

MyProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  AddInstitutionDetailsform: PropTypes.func.isRequired,
  getInstitutionData: PropTypes.func.isRequired,
  editOnUpdateInstitution: PropTypes.func.isRequired,
  editOnUpdatepersonal: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  AddInstitutionDetailsform,
  getInstitutionData,
  editOnUpdateInstitution,
  editOnUpdatepersonal,
  loadUser,
  tempUpdate,
  tempUpdate2,
  tempUpdate3,
  tempUpdateBankArray,
})(MyProfile);
