import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { changeBatchCodeDetails, checkBatchCode } from "../../actions/shg";
const ChangeBatchCode = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { shgcodeCheck },
  batchesdata,
  changeBatchCodeDetails,
  checkBatchCode,
  onChangeModalChange,
}) => {
  const [formData, setFormData] = useState({
    batchCode: "",
    isSubmitted: false,
  });

  const { batchCode } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [error, setError] = useState({
    CheckIdChecker: false,
    CheckErrorStyle: {},
  });
  const { CheckIdChecker, CheckErrorStyle } = error;

  const checkErrors = () => {
    if (!CheckIdChecker) {
      setError({
        ...error,
        CheckErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };

  const [checknullerror, setChecknullerror] = useState("none");
  const onCheck = (e) => {
    if (batchCode === "") {
      setChecknullerror("block");
    } else {
      setChecknullerror("none");

      e.preventDefault();
      setError({
        ...error,
        CheckIdChecker: true,
        CheckErrorStyle: { color: "#000" },
      });

      const finalData = {
        // recordId: batchesdata ? batchesdata._id : "",
        // oldbatchCode: batchesdata.batchCode,
        batchCode: batchCode,
      };

      checkBatchCode(finalData);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (checkErrors()) {
      const finalData = {
        recordId: batchesdata ? batchesdata._id : "",
        oldbatchCode: batchesdata.batchCode,
        batchCode: batchCode,
      };

      changeBatchCodeDetails(finalData);
      localStorage.removeItem("selBatchDetails");
      onChangeModalChange(true);
    }
  };

  return !isAuthenticated || !user || !users ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div className="col-lg-12 col-md-9 col-sm-9 col-12 ">
        <form className="row" onSubmit={(e) => onSubmit(e)}>
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label>
                Batch Name : <b>{batchesdata.batchName}</b>
              </label>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label>
                Current Batch Code : <b>{batchesdata.batchCode}</b>
              </label>
            </div>
            <div className="col-lg-12 col-md-4 col-sm-4 col-12 ">
              <label>New Batch Code* :</label>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 ">
              <input
                type="text"
                name="batchCode"
                value={batchCode}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <button
                style={CheckErrorStyle}
                className="btn sub_form "
                onClick={(e) => onCheck(e)}
                type="submit"
              >
                Check
              </button>
            </div>
            <div className="col-lg-12 col-md-4 col-sm-4 col-12">
              {batchCode !== "" ? (
                <>
                  <label className="text-success">
                    {shgcodeCheck && shgcodeCheck.msg}
                  </label>
                </>
              ) : (
                <></>
              )}
              <label style={{ display: checknullerror }} className="colorRed">
                Please fill SHG code
              </label>
            </div>
            {/* <div className="col-lg-12 col-md-4 col-sm-4 col-12">
              
              {shgcodeCheck && shgcodeCheck.msg === "Batch Code Available" ? (
                <label style={{ color: "green" }}>{shgcodeCheck.msg}</label>
              ) : (
                <label className="colorRed">{shgcodeCheck.msg}</label>
              )}
            </div> */}

            {shgcodeCheck.msg === "Batch Code Available" && (
              <div
                className="col-lg-12 col-md-9 col-sm-9 col-12 Savebutton"
                size="lg"
              >
                {loading ? (
                  <button
                    className="btn sub_form btn_continue blackbrd Save float-right"
                    disabled
                  >
                    Loading...
                  </button>
                ) : (
                  <input
                    type="submit"
                    name="Save"
                    value="Submit"
                    className="btn sub_form btn_continue Save float-right"
                  />
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

ChangeBatchCode.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  changeBatchCodeDetails: PropTypes.func.isRequired,
  checkBatchCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  changeBatchCodeDetails,
  checkBatchCode,
})(ChangeBatchCode);
