import axios from "axios";

import {
  SHG_ADD_INIT,
  AUTH_ERROR,
  ALL_INSTITUTIONS,
  ACTIVE_INSTITUTIONS,
  ALL_LOAN_REASON,
  ALL_BATCHES,
  MEMBER_COUNT,
  MEMBER_GEN_COUNT,
  INSTITUTION_PROFILE,
  SHG_MEMBERS,
  LOAN_REASON,
  ALL_MEMBER_MEETING_BATCHES,
  ALL_BATCHES_DROPDOWN,
  ALL_MEMBER_OF_BATCH,
  SELECTED_BATCH_DATA,
  MEETINGMSG,
  MEETINGCHECKMSG,
  LAST_MEETING,
  PARTIAL_MEETINGS,
  BATCH_ID_DATA,
  VERIFICATION,
  VERIFICATION_DROPDOWN,
  PARTIAL_MEETINGS_DROPDOWN,
  YEAR_COUNT,
  BATCH_COUNT,
  BATCH_DROPDOWN,
  MEMBERS_MEETING,
  COMPLETED_MEETINGS,
  VERIFIED_MEETING_DETAILS,
  MEMBER_OR_BATCHWISE_BATCHES,
  MEMBER_OR_BATCH_BATCHES_DD,
  SHG_BATCH_INFO,
  PREVDATA,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  PARTIAL_MEETING_COUNT,
  UNVERIFIED_MEETING_COUNT,
  SUMMARY_DATA,
  SELECT_BATCH_MEMBER,
  BATCHWISE_REPORT,
  BATCHWISE_REPORT_SUM,
  INCOME_EXPENSE_REPORT,
  BATCH_SAVING_AMOUNT_COUNT,
  COMPLETED_MEETING_DETAILS,
  BATCHTRANSACTION,
  LOANSANCTIONEDDATA,
  MEMBERS_EDIT_DATA,
  BATCH_OF_PARISH,
  SHG_MEMBERS_FOR_MEMBERS,
  INCOME_EXPENSE_REPORT_FINYEAR,
  CLOSINGAMT_DATA,
  BATCH_OF_DISTRICT,
  BATCH_OF_VILLAGE,
  ALL_INSTITUTIONS_AGGREMENT_DETAILS,
  INCOME_EXPENSE_OPBAL,
  SUMMARY_DATA_OP,
  MEETING_CATEGORY,
  INCHARGEREPORT,
  SHG_CODE_CHECK,
  ALL_LOAN_TYPES,
  ACTIVE_OTHER_LOANTYPE,
  GET_BANK_DATA,
  EXST_OL_DATA,
  EXST_MEM_OL_DATA,
  EXST_BANK_DATA,
  MEMBERS_FAMILY,
  MEMBERS_FAMILY_OCCUPATION,
  FAMILY_MEMBERS,
  ALL_OCCUPATION,
  DROPDOWN_OCCUPATION,
  OCC_MEMBERS,
  ALL_AGGREMENT_DETAILS,
  ALL_SHG_CATEGORY,
  DROPDOWN_ACTIVE_SHG_CATEGORY,
  BATCHES_MEMBER_COUNT,
  COUNT_INSTITUTIONS,
  MEETING_DATA,
  INCHARGE_COUNT,
  CORDINATOR_COUNT,
  OTHER_LOAN_TYPE_COUNT,
  ALL_SUB_OCCUPATION,
  SUB_OCCUPATION,
  FAMILY_SUB_OCCUPATION,
  ISCORDINATOR,
  VERIFIED_MEETING_DETAILS_SORT,
  EXST_MEM_LOAN_DATA,
  MEMBERS_PREVIOUS_MEETING,
  ALL_DEACTIVE_BATCHES,
  ALL_ACTIVE_BATCHES,
  EDIT_OTHER_LOAN_TYPE_COUNT,
  ISSANCTIONED,
  BATCH_DETAILS,
} from "./types";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

var linkPath = process.env.REACT_APP_BASE_URL;
// var linkPath = "";

export const AddInstitutionDetailsform = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/add-institution`, finalData, config);
    dispatch(getAllInstitutionDetails());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editInstitutionAggrementDetails = (finalData) => async (
  dispatch
) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/add-new-institution-aggrementDetails`,
      finalData,
      config
    );
    dispatch(getAllInstitutionAndAggrementDetails());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddBatch = (finalData) => async (dispatch) => {
  localStorage.removeItem("selBatchDetails");
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/add-batch`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddMember = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/add-member`, finalData, config);
    let selBatchId = JSON.parse(localStorage.getItem("selBatchId"));
    dispatch(getShgMembers(selBatchId));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddLoanReason = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/add-loan-reason`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllLoanReason(reqData));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddLoanSanctionedDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/transaction/add-loan-details`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
export const deleteOtherLoan = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/transaction/delete-other-loan`,
      finalData,
      config
    );
    dispatch(getExistingMemberOLData(finalData));
    let meetingBatchInfo = JSON.parse(localStorage.getItem("meetingBatchInfo"));
    dispatch(getMembersMeetingData(meetingBatchInfo));

    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const changeBatchMeetingDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/change-batch-meeting-details`,
      finalData,
      config
    );
    dispatch({
      type: MEETINGCHECKMSG,
      payload: "",
    });
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getVerifiedMeetingDetails({ batchIdVal: finalData.mdhBatchId }));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const EditLoanSanctionedDetails = (loanSanctionedData) => async (
  dispatch
) => {
  try {
    await axios.post(
      `${linkPath}/api/transaction/edit-loan-sanctioned-details`,
      loanSanctionedData
    );
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const EditMemberMeetingLoanDetails = (loanSanctionedData) => async (
  dispatch
) => {
  try {
    await axios.post(
      `${linkPath}/api/transaction/edit-member-meeting-loan-sanctioned-details`,
      loanSanctionedData
    );
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const EditBatchLoanDetails = (loanSanctionedData) => async (
  dispatch
) => {
  try {
    await axios.post(
      `${linkPath}/api/transaction/edit-batch-loan-details`,
      loanSanctionedData
    );
    dispatch(
      getLoanSantionedData({
        mdDate: loanSanctionedData.meetingHeldOnDate,
        mdBatchId: loanSanctionedData.batchId,
      })
    );
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//remaining loan code start
export const getMemberPreveiousMeetingData = (meetingBatchInfo) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-members-previous-meeting-data`,
      meetingBatchInfo
    );
    dispatch({
      type: MEMBERS_PREVIOUS_MEETING,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//remaining loan code end

//get loanSanctioned details data
export const getSanctionedData = (finalData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/transaction/get-sanctionedLoan-data`,
      finalData
    );
    dispatch({
      type: ISSANCTIONED,
      payload: res.data,
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const AddMemberTransaction = (finalData) => async (dispatch) => {
  try {
    // dispatch({
    //   type: SET_LOADING_TRUE,
    // });
    await axios.post(
      `${linkPath}/api/transaction/add-member-transaction`,
      finalData,
      config
    );
    const finalData1 = {
      mdDate: finalData.meetingHeldOnDate,
      mdBatchId: finalData.batchId,
    };
    dispatch(getAllMembersOfParticularBatch(finalData1));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const BatchTransaction = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/transaction/add-batch-transaction`,
      finalData,
      config
    );
    localStorage.removeItem("meetingBatchInfo");
    dispatch(getMembersMeetingData());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const meetingDates = (meetingData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    const res = await axios.post(
      `${linkPath}/api/transaction/add-batchwise-meeting-dates`,
      meetingData,
      config
    );
    if (res.data === "Success") {
      dispatch(getNotificationCount());
    }
    // localStorage.removeItem("meetingBatchInfo");
    // dispatch(getMembersMeetingData());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const MemberMeetingDates = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SHG_ADD_INIT,
    });
    await axios.post(
      `${linkPath}/api/transaction/add-meeting-dates`,
      finalData,
      config
    );
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//DEACTIVE

export const deactiveInstitution = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/deactive-institution`,
      finalData,
      config
    );
    dispatch(getAllInstitutionDetails());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deactiveBatch = (finalData) => async (dispatch) => {
  localStorage.removeItem("selBatchDetails");
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/deactive-batch-details`,
      finalData,
      config
    );
    dispatch(getAllBatches(""));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deactiveMember = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/deactive-member-details`,
      finalData,
      config
    );
    let selBatchId = JSON.parse(localStorage.getItem("selBatchId"));
    dispatch(getShgMembers(selBatchId));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deactiveLoanReason = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/deactive-loan-Reason`,
      finalData,
      config
    );
    dispatch(getAllLoanReason());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deleteLoanDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/delete-loan-Details`,
      finalData,
      config
    );
    dispatch(
      getLoanSantionedData({
        mdDate: finalData.meetingHeldOnDate,
        mdBatchId: finalData.batchId,
      })
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const changeBatchCodeDetails = (finalData) => async (dispatch) => {
  localStorage.removeItem("selBatchDetails");
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/change-batchcode-Details`,
      finalData,
      config
    );
    dispatch(getAllBatches(""));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const checkBatchCode = (finalData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/check-batch-code`,
      finalData
    );
    dispatch({
      type: SHG_CODE_CHECK,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const unsetCheckCode = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SHG_CODE_CHECK,
      payload: "",
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//EDIT

export const editInstitution = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/edit-institution`, finalData, config);
    dispatch(getAllInstitutionDetails());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editBatch = (finalData) => async (dispatch) => {
  localStorage.removeItem("selBatchDetails");
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/edit-batch`, finalData, config);
    dispatch(getAllBatches(""));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editMember = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/edit-member`, finalData, config);
    let selBatchId = JSON.parse(localStorage.getItem("selBatchId"));
    dispatch(getShgMembers(selBatchId));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editLoanReason = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/edit-loan-reason`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllLoanReason());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// export const editLoanDetails = (finalData) => async (dispatch) => {
//   try {
//     await axios.post(
//       "/api/transaction/edit-loan-sanctioned-details",
//       finalData,
//       config
//     );
//   } catch (err) {
//     dispatch({
//       type: AUTH_ERROR,
//     });
//   }
// };

export const editMemberTransaction = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/transaction/edit-member-transaction`,
      finalData,
      config
    );
    let meetingBatchInfo = JSON.parse(localStorage.getItem("meetingBatchInfo"));
    dispatch(getMembersMeetingData(meetingBatchInfo));

    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editMemberMeetingTransaction = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/transaction/edit-member-meeting-transaction`,
      finalData,
      config
    );
    let meetingBatchInfo = JSON.parse(localStorage.getItem("meetingBatchInfo"));
    dispatch(getMembersMeetingData(meetingBatchInfo));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editOtherLoanMemberMeetingTransaction = (finalData) => async (
  dispatch
) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/transaction/edit-oth-loan-sanctioned-details`,
      finalData,
      config
    );
    let meetingBatchInfo = JSON.parse(localStorage.getItem("meetingBatchInfo"));
    dispatch(getMembersMeetingData(meetingBatchInfo));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editBatchTransaction = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/transaction/edit-batch-transaction`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

////////////////////////////Update for spliting/////////////////////////

export const editMeetingBatchTransaction = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/transaction/edit-meeting-batch-transaction`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
//////////////////////

//SELECT

export const getAllInstitutionDetails = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-institution-details`
    );
    dispatch({
      type: ALL_INSTITUTIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getCountInstitutionDetails = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-count-institution-details`
    );
    dispatch({
      type: COUNT_INSTITUTIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllInstitutionAndAggrementDetails = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-institution-aggrement-details`
    );
    dispatch({
      type: ALL_INSTITUTIONS_AGGREMENT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllBatchesDropdown = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-batches-dropdown`,
      {
        ...changeData,
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: ALL_BATCHES_DROPDOWN,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getActiveInstitutions = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-active-institutions`);
    localStorage.setItem("activeInstitutionData", JSON.stringify(res.data));
    dispatch({
      type: ACTIVE_INSTITUTIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllLoanReason = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-all-loan-purpose`);
    dispatch({
      type: ALL_LOAN_REASON,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllBatches = (changeData) => async (dispatch) => {
  localStorage.setItem("selBatchDetails", JSON.stringify(changeData));
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-all-batches`, {
      ...changeData,
      loginType: localStorage.getItem("loginType"),
    });

    // localStorage.setItem("selshgBatchDeactiveIdData", JSON.stringify(res1.data));
    // localStorage.setItem("selshgBatchId", JSON.stringify(changeData));
    dispatch({
      type: ALL_BATCHES,
      payload: res.data.allBatchDetails,
    });
    dispatch({
      type: BATCHES_MEMBER_COUNT,
      payload: res.data.BatchMemberCount,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllBatchesCount = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-shgbatch-deactive`, {
      ...changeData,
      loginType: localStorage.getItem("loginType"),
    });
    const res1 = await axios.post(`${linkPath}/api/shg/get-shgbatch-Active`, {
      ...changeData,
      loginType: localStorage.getItem("loginType"),
    });
    dispatch({
      type: ALL_DEACTIVE_BATCHES,
      payload: res.data,
    });

    dispatch({
      type: ALL_ACTIVE_BATCHES,
      payload: res1.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getMemberCount = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-member-count`, {
      loginType: localStorage.getItem("loginType"),
    });
    dispatch({
      type: MEMBER_COUNT,
      payload: res.data.memberCount,
    });
    dispatch({
      type: MEMBER_GEN_COUNT,
      payload: res.data.memberGenderCount,
    });
    dispatch(getBatchCount());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//yearcount
export const getYearcount = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-year-count`, data, {
      loginType: localStorage.getItem("loginType"),
    });
    sessionStorage.setItem("yearDetails", JSON.stringify(res.data));
    dispatch({
      type: YEAR_COUNT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBatchCount = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-batch-count`, {
      loginType: localStorage.getItem("loginType"),
    });
    dispatch({
      type: BATCH_COUNT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBatchSavingAmountCount = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-batch-saving-amount-count`,
      {
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: BATCH_SAVING_AMOUNT_COUNT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getInstitutionProfile = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-institution-profile`);
    dispatch({
      type: INSTITUTION_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// getAllMembers
export const getAllMembers = (finalData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-members`,
      finalData
    );

    dispatch({
      type: "ALL_MEMBERS",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getShgMembers = (finalData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-shg-members`,
      finalData
    );

    const res1 = await axios.post(
      `${linkPath}/api/shg/get-shg-deactivemembers`,
      finalData
    );
    localStorage.setItem("selBatchIdData", JSON.stringify(res.data));
    localStorage.setItem("selBatchDeactiveIdData", JSON.stringify(res1.data));
    localStorage.setItem("selBatchId", JSON.stringify(finalData));
    dispatch({
      type: SHG_MEMBERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getShgMembersForMembers = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-shg-members-for-members`
    );
    // localStorage.setItem("selBatchIdData", JSON.stringify(res.data));
    // localStorage.setItem("selBatchId", JSON.stringify(finalData));
    dispatch({
      type: SHG_MEMBERS_FOR_MEMBERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getParticularLoanReason = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-loan-reason`);
    dispatch({
      type: LOAN_REASON,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllMemberMeetingBatches = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-member-meeting-batches`,
      {
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: ALL_MEMBER_MEETING_BATCHES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllMembersOfParticularBatch = (finalData) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-members-of-particular-batch`,
      finalData
    );
    dispatch({
      type: ALL_MEMBER_OF_BATCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getSeletedBatchDetails = (batchIdData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-selected-batch-details`,
      batchIdData
    );
    localStorage.setItem("selBatchData", JSON.stringify(res.data));
    dispatch({
      type: SELECTED_BATCH_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const checkMeetingDate = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    const res = await axios.post(
      `${linkPath}/api/shg/check-meeting-date`,
      finalData,
      config
    );
    dispatch({
      type: MEETINGMSG,
      payload: res.data,
    });
    if (res.data.saveStatus === "Success") {
      dispatch(getAllMembersOfParticularBatch(finalData));
      dispatch(getNotificationCount());
    }
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getNotificationCount = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/auth/get-partial-meeting-count`,
      {
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: PARTIAL_MEETING_COUNT,
      payload: res.data,
    });
    const res1 = await axios.post(
      `${linkPath}/api/auth/get-unverified-meeting-count`,
      {
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: UNVERIFIED_MEETING_COUNT,
      payload: res1.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const checkAvailableDate = (finalData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/check-available-date`,
      finalData,
      config
    );
    dispatch({
      type: MEETINGMSG,
      payload: res.data.msg,
    });
    if (res.data.saveStatus === "Success") {
      dispatch(getAllMembersOfParticularBatch(finalData));
    }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const checkAvailableMeetingDate = (finalData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/check-available-meeting-date`,
      finalData,
      config
    );
    dispatch({
      type: MEETINGCHECKMSG,
      payload: res.data.msg,
    });
    // if (res.data.saveStatus === "Success") {
    //   dispatch(getAllMembersOfParticularBatch(finalData));
    // }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const unsetCheckAvailableMeetingDate = () => async (dispatch) => {
  try {
    dispatch({
      type: MEETINGCHECKMSG,
      payload: "",
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const removeMessage = () => async (dispatch) => {
  dispatch({ type: MEETINGMSG, payload: "" });
  dispatch({ type: ALL_MEMBER_OF_BATCH, payload: "" });
  dispatch({ type: MEMBER_OR_BATCHWISE_BATCHES, payload: "" });
};

export const getLastMeetingDate = (batchIdData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-last-meeting-date`,
      batchIdData
    );
    localStorage.setItem("lastMeeingDate", JSON.stringify(res.data));
    dispatch({
      type: LAST_MEETING,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllPartialMeetings = (changeData) => async (dispatch) => {
  localStorage.setItem("selParMeetingDetails", JSON.stringify(changeData));
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-partial-meetings`,
      {
        ...changeData,
        loginType: localStorage.getItem("loginType"),
      }
    );

    dispatch({
      type: PARTIAL_MEETINGS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const batchAndMeetingDetails = (batchIdData) => async (dispatch) => {
  localStorage.setItem(
    "selbatchAndMeetingDetails",
    JSON.stringify(batchIdData)
  );
  try {
    dispatch({
      type: BATCH_ID_DATA,
      payload: batchIdData,
    });
    dispatch({
      type: SUMMARY_DATA,
      payload: "",
    });
    localStorage.setItem("batchIdValue", JSON.stringify(batchIdData));
    dispatch(getLastMeetingDate(batchIdData));
    dispatch(getSeletedBatchDetails(batchIdData));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllVerification = (changeData) => async (dispatch) => {
  localStorage.setItem("selVerfyBatchDetails", JSON.stringify(changeData));
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-all-verification`, {
      ...changeData,
      loginType: localStorage.getItem("loginType"),
    });

    dispatch({
      type: VERIFICATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllVerificationFilter = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-verification-filter`,
      {
        ...changeData,
        loginType: localStorage.getItem("loginType"),
      }
    );

    dispatch({
      type: VERIFICATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllPartialMeetingFilter = (changeData) => async (dispatch) => {
  localStorage.setItem("selParMeetingDetails", JSON.stringify(changeData));
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-partial-meeting-filter`,
      {
        ...changeData,
        loginType: localStorage.getItem("loginType"),
      }
    );

    dispatch({
      type: PARTIAL_MEETINGS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllVerificationDropdown = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-verification-dropdown`,
      {
        loginType: localStorage.getItem("loginType"),
      }
    );

    dispatch({
      type: VERIFICATION_DROPDOWN,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllPartialMeetingsDropdown = (changeData) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-partial-meetings-dropdown`,
      {
        loginType: localStorage.getItem("loginType"),
      }
    );

    dispatch({
      type: PARTIAL_MEETINGS_DROPDOWN,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const UpdateMeetingStatus = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    localStorage.setItem("meetingBatchInfo", JSON.stringify(finalData));
    const res = await axios.post(
      `${linkPath}/api/shg/update-meeting-status`,
      finalData
    );
    if (res.data === "Success") {
      dispatch(getNotificationCount());
    }
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBatchDropdown = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-batches`, {
      loginType: localStorage.getItem("loginType"),
    });

    dispatch({
      type: BATCH_DROPDOWN,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getMembersMeetingData = (meetingBatchInfo) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-members-meeting-data`,
      meetingBatchInfo
    );
    dispatch({
      type: MEMBERS_MEETING,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// export const getMemberPreveiousMeetingData = (meetingBatchInfo) => async (
//   dispatch
// ) => {
//   try {
//     const res = await axios.post(
//       `${linkPath}/api/shg/get-members-previous-meeting-data`,
//       meetingBatchInfo
//     );
//     dispatch({
//       type: MEMBERS_PREVIOUS_MEETING,
//       payload: res.data,
//     });
//   } catch (err) {
//     dispatch({
//       type: AUTH_ERROR,
//     });
//   }
// };

export const getAllCompletedMeetings = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-completed-meetings`,
      {
        loginType: localStorage.getItem("loginType"),
      }
    );

    dispatch({
      type: COMPLETED_MEETINGS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getMemberOrBatchwiseBatches = (transactionTypeChange) => async (
  dispatch
) => {
  localStorage.setItem(
    "selMemBatchMeetingDetails",
    JSON.stringify(transactionTypeChange)
  );
  try {
    dispatch({
      type: "MEMBER_OR_BATCHWISE_BATCHES_LOADING",
      payload: null,
    });
    const res = await axios.post(
      `${linkPath}/api/shg/get-member-or-batchwise-batches`,
      {
        ...transactionTypeChange,
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: MEMBER_OR_BATCHWISE_BATCHES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBatchesOnTransTypeChange = (transactionTypeChange) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-batches-on-transtype-change`,
      {
        ...transactionTypeChange,
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: MEMBER_OR_BATCH_BATCHES_DD,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBatchInfo = (finalData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-batch-info`,
      finalData
    );
    localStorage.setItem("selBatchInfo", JSON.stringify(res.data));
    dispatch({
      type: SHG_BATCH_INFO,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getMemberPrevMeetingData = (reqData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-member-prev-meeting-data`,
      reqData
    );

    dispatch({
      type: PREVDATA,
      payload: res.data.MemberTransactionData,
    });

    dispatch({
      type: "REMAINING_LOAN",
      payload: res.data.resRemaing,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getMeetingSummary = (meetingBatchInfo) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-meeting-summary`,
      meetingBatchInfo
    );
    dispatch({
      type: SUMMARY_DATA,
      payload: res.data,
    });

    const res1 = await axios.post(
      `${linkPath}/api/shg/get-meeting-summary_op`,
      meetingBatchInfo
    );
    dispatch({
      type: SUMMARY_DATA_OP,
      payload: res1.data,
    });

    const res2 = await axios.post(
      `${linkPath}/api/shg/get-member-closing-amt`,
      meetingBatchInfo
    );
    dispatch({
      type: CLOSINGAMT_DATA,
      payload: res2.data,
    });

    const res3 = await axios.post(
      `${linkPath}/api/shg/get-member-closing-amtAll`,
      meetingBatchInfo
    );

    dispatch({
      type: "CLOSINGAMT_DATA_ALL",
      payload: res3.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getMembersOfSelectedBatch = (batchIdValue) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-membersof-selected-batch`,
      batchIdValue
    );
    dispatch({
      type: SELECT_BATCH_MEMBER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBatchwiseReport = (batchIdValue) => async (dispatch) => {
  localStorage.setItem("selBatchwiseReport", JSON.stringify(batchIdValue));
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-batchwise-report`, {
      ...batchIdValue,
      loginType: localStorage.getItem("loginType"),
    });
    dispatch({
      type: BATCHWISE_REPORT,
      payload: res.data.BatchwiseReportData,
    });

    dispatch({
      type: BATCHWISE_REPORT_SUM,
      payload: res.data.BatchwiseReportSum,
    });
    
    dispatch({
      type: "PRINCIPALINTEREST_REPORT_SUM",
      payload: res.data.principalAndInterestSum,
    });
    dispatch({
      type: "OTHER_INTEREST_REPORT_SUM",
      payload: res.data.otherInterestSum,
      
    })
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// getASSAAssetsAndLiabilityReport
export const getASSAAssetsAndLiabilityReport = (changeData) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-ASSA-Assets-Liability-report`,
      {
        ...changeData,
        loginType: localStorage.getItem("loginType"),
      }
    );

    dispatch({
      type: "ASSA_ASSETS_LIABILITY_REPORT",
      payload: res.data[0] || null,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// getASSAIncomeAndExpenseReport
export const getASSAIncomeAndExpenseReport = (changeData) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-ASSA-Income-Expense-report`,
      {
        ...changeData,
        loginType: localStorage.getItem("loginType"),
      }
    );

    dispatch({
      type: "ASSA_INCOME_EXPENSE_REPORT",
      payload: res.data[0] || null,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getIncomeAndExpenseReport = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-income-expense-report`,
      {
        ...changeData,
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: INCOME_EXPENSE_REPORT,
      payload: res.data.IncomeExpenseReportData,
    });
    dispatch({
      type: "CLOSING_BANK_AMT",
      payload: res.data.closingAmt,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const addImportBatchtData = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/add-import--batch-data`,
      changeData
    );
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const addImportMemberData = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/add-import-member-data`,
      changeData
    );
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getIncomeAndExpenseOpBal = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-income-expense-op-bal`,
      {
        ...changeData,
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: INCOME_EXPENSE_OPBAL,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getIncomeAndExpenseReportFinacialYear = (changeData) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-income-expense-report-financialyear`,
      {
        ...changeData,
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: INCOME_EXPENSE_REPORT_FINYEAR,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getCompletedMeetingDetails = (batchIdValue) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-completed-meeting-details`,
      {
        ...batchIdValue,
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: COMPLETED_MEETING_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getMeetingTransactionData = (meetingBatchInfo) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-meeting-transaction-data`,
      meetingBatchInfo
    );
    localStorage.setItem("batchTransactionData", JSON.stringify(res.data));
    dispatch({
      type: BATCHTRANSACTION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getInchargeReport = (finalData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-incharge-report`,
      finalData
    );
    // localStorage.setItem("batchTransactionData", JSON.stringify(res.data));
    dispatch({
      type: INCHARGEREPORT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getLoanSantionedData = (meetingBatchInfo) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-loan-santioned-data`,
      meetingBatchInfo
    );
    dispatch({
      type: LOANSANCTIONEDDATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getEditMemberMeetingData = (meetingInfo) => async (dispatch) => {
  try {
    localStorage.setItem("meetingBatchInfo", JSON.stringify(meetingInfo));
    const res = await axios.post(
      `${linkPath}/api/shg/get-edit-member-meeting-data`,
      meetingInfo
    );
    localStorage.setItem("memberEditData", JSON.stringify(res.data));
    dispatch({
      type: MEMBERS_EDIT_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBatchOfParish = (filterData) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-batch-of-parish`, {
      ...filterData,
      loginType: localStorage.getItem("loginType"),
    });
    dispatch({
      type: BATCH_OF_PARISH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
export const getBatchOfDistrict = (filterData) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-batch-of-district`, {
      ...filterData,
      loginType: localStorage.getItem("loginType"),
    });
    dispatch({
      type: BATCH_OF_DISTRICT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
export const getBatchOfVillage = (filterData) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-batch-of-village`, {
      ...filterData,
      loginType: localStorage.getItem("loginType"),
    });
    dispatch({
      type: "BATCH_OF_VILLAGE",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getVerifiedMeetingDetails = (batchIdValue) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-verified-meeting-details`,
      {
        ...batchIdValue,
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: VERIFIED_MEETING_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getVerifiedMeetingDetailsSort = (batchIdValue) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-verified-meeting-details-sort`,
      {
        ...batchIdValue,
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: VERIFIED_MEETING_DETAILS_SORT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getMeetingCategory = (meetingBatchInfo) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-meeting-category`,
      meetingBatchInfo
    );
    dispatch({
      type: MEETING_CATEGORY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddOtherLoanType = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/add-other-loan-type`,
      finalData,
      config
    );
    dispatch(getAllLoanTypes());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getActiveOtherLoanType = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-active-other-loantype`
    );
    // localStorage.setItem("activeInstitutionData", JSON.stringify(res.data));
    dispatch({
      type: ACTIVE_OTHER_LOANTYPE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllLoanTypes = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-loan-types`,
      changeData
    );
    dispatch({
      type: ALL_LOAN_TYPES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editOtherLoanDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/edit-other-loan-details`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllLoanTypes());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deactiveOtherLoanDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/deactive-other-loan-details`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllLoanTypes());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const tempUpdate = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    const res = await axios.post(`${linkPath}/api/shg/temp-update`);
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const tempUpdate2 = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    const res = await axios.post(`${linkPath}/api/shg/temp-update-OL`);
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const tempUpdate3 = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    const res = await axios.post(`${linkPath}/api/shg/temp-update-MM`);
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const tempUpdateBankArray = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    const res = await axios.post(`${linkPath}/api/shg/temp-update-bank-array`);
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editBankDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/edit-bank`, finalData, config);
    dispatch(getActiveBankData(finalData));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const addNewBankDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/add-new-bank`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getActiveBankData = (batchIdValue) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-active-bank-data`,
      batchIdValue,
      config
    );
    dispatch({
      type: GET_BANK_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const addNewOtherLoanDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/add-new-other-Loan`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getExistingOLData = (meetingBatchInfo) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-existing-otherLoan-data`,
      meetingBatchInfo
    );
    dispatch({
      type: EXST_OL_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//checking is user related to any batch before changing the usergroup
export const UserCoordinator = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-isUser-coordiantor`,
      data
    );
    dispatch({
      type: ISCORDINATOR,
      payload: res.data,
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const getExistingMemberOLData = (meetingBatchInfo) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-existing-Member-otherLoan-data`,
      meetingBatchInfo
    );
    dispatch({
      type: EXST_MEM_OL_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getExistingMemberILData = (meetingBatchInfo) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-existing-Member-InternalloanLoan-data`,
      meetingBatchInfo
    );
    dispatch({
      type: "EXST_MEM_IL_DATA",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getExistingMemberLoanData = (meetingBatchInfo) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-existing-loan-details`,
      meetingBatchInfo
    );
    dispatch({
      type: EXST_MEM_LOAN_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getExistingBankData = (meetingBatchInfo) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-existing-bank-data`,
      meetingBatchInfo
    );
    dispatch({
      type: EXST_BANK_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deleteBankData = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/transaction/delete-bank-data`,
      finalData,
      config
    );
    dispatch(getExistingBankData(finalData));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deleteBatchOtherLoan = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/transaction/delete-batch-other-loan`,
      finalData,
      config
    );
    dispatch(getExistingOLData(finalData));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editOtherLoanData = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/update-otherLoan-data`,
      finalData,
      config
    );
    // dispatch(getExistingOLData(finalData.staffFilter));
    // dispatch(refreshLead(finalData));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editBankData = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/update-bank-data`, finalData, config);
    // dispatch(getExistingOLData(finalData.staffFilter));
    // dispatch(refreshLead(finalData));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const addNewOtherLoanBatchTransaction = (finalData) => async (
  dispatch
) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/add-new-other-loan-batch-transaction`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const addNewBankTransaction = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/add-new-bank-transaction`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getShgMembersFamily = (searchData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-shg-members-family`,
      searchData
    );
    dispatch({
      type: MEMBERS_FAMILY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllFamilyMembers = (searchData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-family-members`,
      searchData
    );
    const res2 = await axios.post(
      `${linkPath}/api/shg/get-occ-report-members`,
      searchData
    );

    dispatch({
      type: FAMILY_MEMBERS,
      payload: res.data,
    });
    dispatch({
      type: OCC_MEMBERS,
      payload: res2.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editFamilyDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/edit-family-details`,
      finalData,
      config
    );
    dispatch(getShgMembersFamily(finalData));
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getShgMembersFamilyOccupation = (searchData) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-shg-members-family-occupation`,
      searchData
    );
    dispatch({
      type: MEMBERS_FAMILY_OCCUPATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const addNewFamilyDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/add-new-family-details`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddOccupation = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/add-occupation`, finalData, config);
    dispatch(getAllOccupation());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllOccupation = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-occupation`,
      changeData
    );
    dispatch({
      type: ALL_OCCUPATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editOcccupation = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/edit-occupation`, finalData, config);
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllOccupation());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddSubO = (finalData) => async (dispatch) => {
  try {
  } catch (error) {
    console.log(error.message);
  }
};

export const deactiveOccupation = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/deactive-occupation`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllOccupation());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAggrementDetails = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-aggrement-details`,
      changeData
    );
    dispatch({
      type: ALL_AGGREMENT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getOcccupationsDropdown = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-occcupations-dropdown`
    );
    localStorage.setItem("activeOccupationData", JSON.stringify(res.data));
    dispatch({
      type: DROPDOWN_OCCUPATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddSHGCategory = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/shg/add-shg-category`, finalData, config);
    dispatch(getAllSHGCategory());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllSHGCategory = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-shg-category`,
      changeData
    );
    dispatch({
      type: ALL_SHG_CATEGORY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editShgCategories = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/edit-shg-categories`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllSHGCategory());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deactiveShgCategory = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/deactive-shg-category`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllSHGCategory());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getActiveShgCategory = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-active-shg-category`);
    dispatch({
      type: DROPDOWN_ACTIVE_SHG_CATEGORY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const recheckMeetingStatus = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    const res = await axios.post(
      `${linkPath}/api/shg/recheck-meeting-status`,
      finalData
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getSelMeetingDateData = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-sel-meeting-date-data`,
      data
    );
    dispatch({
      type: MEETING_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getInchargeCount = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-incharge-count`, {
      loginType: localStorage.getItem("loginType"),
    });
    dispatch({
      type: INCHARGE_COUNT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getCordinatorCount = () => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/get-cordinator-count`, {
      loginType: localStorage.getItem("loginType"),
    });
    dispatch({
      type: CORDINATOR_COUNT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getOtherLoanTypeCount = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-other-loan-type-count`,
      {
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: OTHER_LOAN_TYPE_COUNT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//SubOccupation

export const getAllSubOccupation = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-sub-occupation`,
      changeData
    );
    dispatch({
      type: ALL_SUB_OCCUPATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddSubOccupation = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/add-sub-occupation`,
      finalData,
      config
    );
    dispatch(getAllSubOccupation());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editSubOcccupation = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/edit-sub-occupation`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllSubOccupation());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
export const deactiveSubOccupation = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/shg/deactive-sub-occupation`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllSubOccupation());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getSubOccupation = (religionVal) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-sub-occupation`,
      religionVal
    );
    // localStorage.setItem("getSubOccupationData", JSON.stringify(res.data));
    dispatch({
      type: SUB_OCCUPATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getFamilySubOccupation = (religionVal) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-family-sub-occupation`,
      religionVal
    );
    // localStorage.setItem("getSubOccupationData", JSON.stringify(res.data));
    dispatch({
      type: FAMILY_SUB_OCCUPATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editOLTypeCount = () => async (dispatch) => {
  try {
    // localStorage.setItem("getSubOccupationData", JSON.stringify(res.data));
    dispatch({
      type: EDIT_OTHER_LOAN_TYPE_COUNT,
      // payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getMemberWiseLoanReport = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/transaction/get-memberwise-loan-report`,
      changeData
      // religionVal
    );
    // localStorage.setItem("getSubOccupationData", JSON.stringify(res.data));
    dispatch({
      type: "GET_MEM_LOAN_REP",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getDonorLoanReport = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/transaction/get-donor-loan-report`,
      changeData
      // religionVal
    );
    // localStorage.setItem("getSubOccupationData", JSON.stringify(res.data));
    dispatch({
      type: "GET_DONOR_LOAN_REP",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBatchDetails = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-batch-details`,
      changeData
    );
    // localStorage.setItem("getSubOccupationData", JSON.stringify(res.data));
    dispatch({
      type: BATCH_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const sendMettingFile = (fileData) => async (dispatch) => {
  try {
    dispatch({
      type: "FILE_UPLOAD_SPINNER",
      payload: true,
    });
    const res = await axios.post(
      `${linkPath}/api/transaction/getPDFFile`,
      fileData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    dispatch({
      type: "GET_FILE_BASE64",
      payload: res.data.base64,
    });
    dispatch({
      type: "FILE_UPLOAD_SUCCESSFUL",
      payload: res.data.success,
    });
    dispatch({
      type: "FILE_UPLOAD_SPINNER",
      payload: false,
    });
    
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBatchEditMeeting = (InstID) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/getonly-editmeeting-batch-details`,
      InstID
    );

    dispatch({
      type: "EDIT_BATCH_LIST",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getattendanceReport = (data) => async (dispatch) => {
  try {
    // const res = await axios.post(
    //   `${linkPath}/api/shg/get-attendance-report`,
    //   data
    // );
    // dispatch({
    //   type: "GET_ATTENDANCE_REPORT",
    //   payload: res.data,
    // });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deleteBankLoanDetail = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/transaction/deleteBankFromLoan`,
      data
    );
    dispatch({
      type: "DELETED_INT_LOAN",
      payload: res.data,
    });
    const meetingBatchInfo = {
      meetingHeldOnDate: data.paidDate,
      batchId: data.batchId,
      memberId: data.memberId,
    };

    dispatch(getExistingMemberILData(meetingBatchInfo));
  } catch (error) {
    console.log(error.message);
  }
};

export const getCurMemMettingLoanSanc = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/getCurMemberMettingLoanSanc`,
      data
    );

    dispatch({
      type: "GET_CUR_MEM_METTING_LOANS",
      payload: res.data,
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const editSancLoanDB = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/editSancLoanDB`, data);
    const meetingBatchInfo = {
      mdBatchId: data.batchId,
      memberId: data.memberId,
      mdDate: data.meetingHeldOnDate,
    };

    dispatch(
      getCurMemMettingLoanSanc({
        memberId: data.memberId,
        meetingBatchInfo: meetingBatchInfo,
      })
    );

    dispatch(
      getSanctionedData({
        institutionId: data.institutionId,
        batchId: data.batchId,
        memberId: data.memberId,
      })
    );
  } catch (error) {
    console.log(error.message);
  }
};

export const getToBeDeativeMemberData = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/getToBeDeativeMemberData`,
      data
    );

    dispatch({
      type: "GET_TOBE_DEV_MEMDATA",
      payload: res.data,
    });
  } catch (error) {
    console.log(error.message);
  }
};

///////////////////////////////////////////////////////// 123

export const getMemberMeetingSummary = (meetingInfo) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-member-meeting-summary`,
      meetingInfo
    );

    const storageInfo = await axios.get(`${linkPath}/api/transaction/get-storage-info`)
    // console.log("storageInfo",storageInfo)

    dispatch({
      type: "MEM_SUMMARY_DATA",
      payload: res.data[0],
    });

    dispatch({
      type: "STORAGE_INFO",
      payload: storageInfo.data,
    });

  } catch (er) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getIndividualMemberMeetingData = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/getIndividualMemberMeetingData`,
      data
    );

    dispatch({
      type: "INDI_MEM_DATA",
      payload: res.data,
    });
  } catch (er) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getBatchMeetingData = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/getBatchMeetingData`,
      data
    );

    dispatch({
      type: "BATCH_DATA",
      payload: res.data,
    });
  } catch (er) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
///////////////////////Cash and bank

export const addMemberMeetingData = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/addMemberMeetingData`,
      data
    );
    dispatch({
      type: "ADD_INDI_MEMBER_DATA",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editMemberMeetingData = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/editMemberMeetingData`,
      data
    );
    dispatch({
      type: "EDIT_INDI_MEMBER_DATA",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const addBatchMeetingData = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/add-batchTrans`, data);

    if (data.meetingFile) {
      dispatch(sendMettingFile(data.meetingFile));
    }

    // dispatch({
    //   type: "EDIT_BATCH_MEETING_DATA",
    //   payload: res.data,
    // });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editBatchMeetingData = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/edit-batchTrans`, data);

    // dispatch({
    //   type: "EDIT_BATCH_MEETING_DATA",
    //   payload: res.data,
    // });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//

export const addOnlyBatchWiseMeetingData = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/onlyAdd-batchTrans`,
      data
    );
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editOnlyBatchWiseMeetingData = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/onlyEdit-batchTrans`,
      data
    );
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deleteOtherLoanDB = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/deleteOtherLoanLoanDB`,
      data
    );
    const newData = {
      memberId: data.memberId,
      batchId: data.batchId,
      meetingHeldOnDate: data.meetingHeldOnDate,
    };
    dispatch(getIndividualMemberMeetingData(newData));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deleteInternalLoanDB = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/deleteInternalLoanDB`,
      data
    );
    const newData = {
      memberId: data.memberId,
      batchId: data.batchId,
      meetingHeldOnDate: data.meetingHeldOnDate,
    };

    dispatch(getCurMemMettingLoanSanc(newData));

    dispatch(getExistingMemberILData(newData));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// /add-Batch-Int-LoanSanc-Only

export const addBatchLoanSancOnly = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/add-Batch-Int-LoanSanc-Only`,
      data
    );
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const deleteBatchLoanSancOnly = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/delete-Batch-Int-LoanSanc-Only`,
      data
    );
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllInstiLoan = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/get-all-insti-Loan`,
      data
    );

    dispatch({
      type: "ALL_INST_LOAN",
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const addWaveOffNotes = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${linkPath}/api/shg/add-waveoff-notes`, data);
    dispatch(getAllInstiLoan());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
