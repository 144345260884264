import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  AddInstitutionDetailsform,
  editInstitutionAggrementDetails,
} from "../../actions/shg";
import Select from "react-select";
import Spinner from "../layout/Spinner";
const PaymentMethods = [
  { value: "Cash", label: "Cash" },
  { value: "Cheque", label: "Cheque" },
  { value: "Online Transaction", label: "Online Transaction" },
];
const RenewalInstitutionAgreement = ({
  auth: { isAuthenticated, user, users, loading },
  AddInstitutionDetailsform,
  RenewalData,
  onRenewalModalChange,
  editInstitutionAggrementDetails,
}) => {
  //formData
  const [formData, setFormData] = useState({
    aggrementAmount:
      RenewalData.output && RenewalData.output.aggrementAmount
        ? RenewalData.output.aggrementAmount
        : "",
    ChequeNoOrDDNo: "",
    aggrementBankName: "",
    aggrementChequeDate: "",
    aggrementPaymentMode: PaymentMethods[0],
    isSubmitted: false,
  });
  const {
    institutionName,
    institutionAddress,
    missionStatement,
    aboutOrganization,
    institutionDesc,
    institutionType,
    institutionInterestRate,
    aggrementPaymentMode,
    ChequeNoOrDDNo,
    aggrementBankName,
    aggrementAmount,
    institutionLogo,
    isSubmitted,
  } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const UserZone = [
    { value: "Zoned", label: "Zoned" },
    { value: "FreeZone", label: "FreeZone" },
  ];

  const onInstituteTypeChange = (e) => {
    //Required Validation Starts
    setError({
      ...error,
      sIdChecker: true,
      sIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    if (e) {
      setFormData({
        ...formData,
        institutionType: e,
      });
    }
  };
  const [aggrementStartDate, setStartDate] = useState();
  const [aggrementEndDate, setEndDate] = useState();
  const [aggrementChequeDate, setChequeDate] = useState();

  const onStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const onEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const onChequeDateChange = (e) => {
    setChequeDate(e.target.value);
  };

  const [showHide, setShowHide] = useState(false);

  const { showChequenoSection } = showHide;
  //Required Validation Starts
  const [error, setError] = useState({
    sIdChecker: false,
    sIdErrorStyle: {},
  });
  const { sIdChecker, sIdErrorStyle } = error;

  const checkErrors = () => {
    if (!sIdChecker) {
      setError({
        ...error,
        sIdErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };
  //Required Validation ends
  const onUpdate = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: RenewalData ? RenewalData.output && RenewalData.output._id : "",
      institutionId: RenewalData
        ? RenewalData.output && RenewalData.output.institutionId
        : "",
      aggrementAmount: aggrementAmount,
      aggrementStartDate: aggrementStartDate,
      aggrementEndDate: aggrementEndDate,
      aggrementPaymentMode: aggrementPaymentMode.value,
      ChequeNoOrDDNo: ChequeNoOrDDNo,
      aggrementBankName: aggrementBankName,
      aggrementChequeDate: aggrementChequeDate,
      aggrementStatus: "Active",
    };

    editInstitutionAggrementDetails(finalData);
    onRenewalModalChange(true);
  };

  if (isSubmitted) {
    return <Redirect to="/all-institution" />;
  }

  const onPaymentModeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        aggrementPaymentMode: e,
      });
    }
    if (e.value === "Cheque") {
      setShowHide({
        ...showHide,
        showChequenoSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showChequenoSection: false,
      });
    }
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onUpdate(e)}>
          <div className="col-lg-11 col-md-11 col-sm-12 col-12  py-3">
            <h6 className="heading_color">
              <b>Institution Name: {RenewalData.institutionName}</b>
            </h6>
          </div>
          <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 py-3">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                <label>Agreement Start Date</label>
                <input
                  type="date"
                  placeholder="dd/mm/yyyy"
                  className="form-control cpp-input datevalidation"
                  name="instistartDate"
                  value={aggrementStartDate}
                  onChange={(e) => onStartDateChange(e)}
                  style={{
                    width: "75%",
                  }}
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                <label>Agreement End Date</label>
                <input
                  type="date"
                  placeholder="dd/mm/yyyy"
                  className="form-control cpp-input datevalidation"
                  name="instiendDate"
                  value={aggrementEndDate}
                  onChange={(e) => onEndDateChange(e)}
                  style={{
                    width: "75%",
                  }}
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                <label>Agreement Amount</label>
                <input
                  type="text"
                  name="aggrementAmount"
                  value={aggrementAmount}
                  className="form-control"
                  onChange={(e) => onInputChange(e)}
                  required
                />
              </div>
              <div className="col-lg-6 col-md-4 col-sm-4 col-12 py-3">
                <label>Mode Of Payment *:</label>
                <Select
                  name="aggrementPaymentMode"
                  options={PaymentMethods}
                  isSearchable={false}
                  value={aggrementPaymentMode}
                  placeholder="Select"
                  onChange={(e) => onPaymentModeChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
              {showChequenoSection && (
                <>
                  <div className="col-lg-6  col-md-4 col-sm-4 col-12 py-3">
                    <label>Cheque No/DD No :</label>
                    <input
                      type="number"
                      name="ChequeNoOrDDNo"
                      value={ChequeNoOrDDNo}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) =>
                        (e.keyCode === 69 || e.keyCode === 190) &&
                        e.preventDefault()
                      }
                      required
                    />
                  </div>

                  <div className="col-lg-6  col-md-4 col-sm-4 col-12 py-3">
                    <label>Bank Name :</label>
                    <input
                      type="text"
                      name="aggrementBankName"
                      value={aggrementBankName}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      required
                    />
                  </div>

                  <div className="col-lg-6  col-md-4 col-sm-4 col-12 py-3">
                    <label>ChequeDate:</label>
                    <br />
                    <input
                      type="date"
                      placeholder="dd/mm/yyyy"
                      className="form-control cpp-input datevalidation"
                      name="aggrementChequeDate"
                      value={aggrementChequeDate}
                      onChange={(e) => onChequeDateChange(e)}
                      style={{
                        width: "75%",
                      }}
                      required
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label className="label-control colorRed">
              * Indicates mandatory fields, Please fill mandatory fields before
              Submit
            </label>
          </div>
          <div
            className="col-lg-11 col-md-12 col-sm-12 col-12 Savebutton "
            size="lg"
          >
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <>
                <input
                  type="submit"
                  name="Submit"
                  value="Submit"
                  className="btn sub_form btn_continue blackbrd Save float-right"
                />
              </>
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

RenewalInstitutionAgreement.propTypes = {
  auth: PropTypes.object.isRequired,
  AddInstitutionDetailsform: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  AddInstitutionDetailsform,
  editInstitutionAggrementDetails,
})(RenewalInstitutionAgreement);
