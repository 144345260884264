import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";

import { getAllUser, getUserGroups } from "../../actions/user";
import {
  getActiveInstitutions,
  getAllBatchesDropdown,
} from "../../actions/shg";
import AddUser from "./AddUser";
import EditUserDetails from "./EditUserDetails";
import Pagination from "../layout/Pagination";
import Spinner from "../layout/Spinner";
import DeactiveUser from "./DeactiveUser";
import ChangeUsersPwd from "../auth/ChangeUsersPwd";

const AllUserDetails = ({
  auth: { isAuthenticated, user, users },
  user: { getalluserdetails },
  shg :{activeInstitutions},
  getAllUser,
  getUserGroups,
  getActiveInstitutions,
  getAllBatchesDropdown,
}) => {
  useEffect(() => {
    getAllUser({institutionId : ""});
  }, [getAllUser]);
  useEffect(() => {
    getUserGroups();
  }, [getUserGroups]);
  useEffect(() => {
    getActiveInstitutions();
  }, [getActiveInstitutions]);
  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);

  const [showAddUserSettingModal, setShowEditModal] = useState(false);
  const handleAddUserModalClose = () => setShowEditModal(false);
  const onClickHandler = () => {
    setShowEditModal(true);
  };

  const onAddUserModalChange = (e) => {
    if (e) {
      handleAddUserModalClose();
    }
  };

  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (allusersdata, idx) => {
    setShowUpdateModal(true);
    setUserData(allusersdata);
  };

  //pagination code
  const [currentData, setCurrentData] = useState(1);
  const [dataPerPage] = useState(8);
  //Get Current Data
  const indexOfLastData = currentData * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentDatas =
    getalluserdetails &&
    getalluserdetails.slice(indexOfFirstData, indexOfLastData);
  //change page
  const paginate = (nmbr) => {
    setCurrentData(nmbr);
  };
  //pagination code ends
  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const [userDatas, setUserDatas] = useState(null);
  const onUpdate = (getalluser, idx) => {
    setShowDeactiveModal(true);
    setUserDatas(getalluser);
  };

  //changepwd

  const [showChangePwdModal, setShowChangePwdModal] = useState(false);
  const handleChangePwdModalClose = () => setShowChangePwdModal(false);
  const onChangePwdModalChange = (e) => {
    if (e) {
      handleChangePwdModalClose();
    }
  };
  const [userDataChangePwd, setUserDataChangePwd] = useState(null);
  const onChangePwd = (getalluser, idx) => {
    setShowChangePwdModal(true);
    setUserDataChangePwd(getalluser);
  };



  const allinstitution = [];
  activeInstitutions.map((institution) =>
    allinstitution.push({
      institutionId: institution._id,
      label: institution.institutionName,
      value: institution.institutionName,
    })
  );

  const [institution, getinstitutionData] = useState();
  const [institutionId, setinstitutionId] = useState();
  const [institutionName, setinstitutionName] = useState();

  const onInstitutionChange = (e) => {
    getinstitutionData(e);
   
    getAllUser({institutionId : e.institutionId});
    setinstitutionName(e.value);
  };
 


  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">All User Details </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12  no_padding py-3">
            <div className="col-lg-4 col-md-4 col-sm-4 ">
              {user && user.userName === "super" ? ( <Select
                    name="institutionName"
                    options={allinstitution}
                    isSearchable={true}
                    value={institution}
                    placeholder="Select Institution"
                    onChange={(e) => onInstitutionChange(e)}
            />) : (<></>)}
           
            </div>
            <div className="col-lg-3 col-md-5 col-sm-5 "></div>
            <div className="col-lg-4 col-md-4 col-sm-4 ">
              <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <img
                className="img_icon_size log float-right "
                onClick={() => onClickHandler()}
                src={require("../../static/images/add-icon.png")}
                alt="Add User"
                title="Add User"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>User Name</th>
                        <th>Full Name</th>
                        <th>Address</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Group</th>
                        <th>Institution</th>
                        <th>Op</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentDatas &&
                        currentDatas.map((getalluser, idx) => {
                         
                            return (
                          
                              <tr key={idx}>
                                {getalluser.userStatus &&
                                getalluser.userStatus === "Deactive" ? (
                                  <td
                                    className=" secondlinebreak1"
                                    style={{ backgroundColor: "#dda6a6" }}
                                  >
                                    {getalluser.userName}
                                  </td>
                                ) : (
                                  <td className=" secondlinebreak1">
                                    {getalluser.userName}
                                  </td>
                                )}
                                <td>{getalluser.userFullName}</td>
                                <td>{getalluser.userAddress}</td>
                                <td>{getalluser.userEmail}</td>
                                <td>{getalluser.userPhone}</td>
                                <td>{getalluser.userGroupName}</td>
                                <td>{getalluser.institutionName}</td>
                                <td>
                                  {getalluser.userStatus &&
                                  getalluser.userStatus !== "Deactive" ? (
                                    <>
                                      <img
                                        className="img_icon_size log"
                                        onClick={() => onUpdate(getalluser, idx)}
                                        src={require("../../static/images/delete.png")}
                                        alt="Deactivate User"
                                        title="Deactivate User"
                                      />
                                      <img
                                        className="img_icon_size log ml-1"
                                        onClick={() => onEdit(getalluser, idx)}
                                        src={require("../../static/images/edit_icon.png")}
                                        alt="Edit User"
                                        title="Edit User"
                                      />
                                      <img
                                        className="img_icon_size log ml-1"
                                        onClick={() =>
                                          onChangePwd(getalluser, idx)
                                        }
                                        src={require("../../static/images/key.png")}
                                        alt="Change User Password"
                                        title="Change User Password"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      Deactivated &nbsp;
                                      <img
                                        className="img_icon_size log "
                                        src={require("../../static/images/info.png")}
                                        alt="Reason"
                                        title={getalluser.userDeactiveReason}
                                      />
                                    </>
                                  )}
                                  &nbsp;
                                </td>
                              </tr>
                            );
                         
                         
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
                    {getalluserdetails && getalluserdetails.length !== 0 ? (
                      <Pagination
                        dataPerPage={dataPerPage}
                        totalData={getalluserdetails.length}
                        paginate={paginate}
                        currentPage={currentData}
                      />
                    ) : (
                      <Fragment />
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 align_right">
                    <label>No. of Users : {getalluserdetails.length}</label>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Modal
            show={showAddUserSettingModal}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <div className="col-lg-10">
                <h3 className="modal-title text-center">Add User Details</h3>
              </div>
              <div className="col-lg-2">
                <button onClick={handleAddUserModalClose} className="close">
                  <img
                    src={require("../../static/images/close.png")}
                    alt="X"
                    style={{ height: "20px", width: "20px" }}
                  />
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <AddUser onAddUserModalChange={onAddUserModalChange} />
            </Modal.Body>
          </Modal>

          <Modal
            show={showUpdateModal}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <div className="col-lg-10">
                <h3 className="modal-title text-center">Edit User Details </h3>
              </div>
              <div className="col-lg-2">
                <button onClick={handleUpdateModalClose} className="close">
                  <img
                    src={require("../../static/images/close.png")}
                    alt="X"
                    style={{ height: "20px", width: "20px" }}
                  />
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <EditUserDetails
                allusersdata={userData}
                onUpdateModalChange={onUpdateModalChange}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={showDeactiveModal}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <div className="col-lg-10">
                <h3 className="modal-title text-center">Deactivate User</h3>
              </div>
              <div className="col-lg-2">
                <button onClick={handleDeactiveModalClose} className="close">
                  <img
                    src={require("../../static/images/close.png")}
                    alt="X"
                    style={{ height: "20px", width: "20px" }}
                  />
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <DeactiveUser
                getalluser={userDatas}
                onDeactiveModalChange={onDeactiveModalChange}
              />
            </Modal.Body>
          </Modal>
          <Modal
            show={showChangePwdModal}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <div className="col-lg-10">
                <h3 className="modal-title text-center">Change Password</h3>
              </div>
              <div className="col-lg-1">
                <button onClick={handleChangePwdModalClose} className="close">
                  <img
                    src={require("../../static/images/close.png")}
                    alt="X"
                    style={{ height: "20px", width: "20px" }}
                  />
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <ChangeUsersPwd
                onChangePwdModalChange={onChangePwdModalChange}
                usersChangePwddata={userDataChangePwd}
              />
            </Modal.Body>
          </Modal>
        </section>
      </div>
    </Fragment>
  );
};

AllUserDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getAllUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getAllUser,
  getUserGroups,
  getActiveInstitutions,
  getAllBatchesDropdown,
})(AllUserDetails);
