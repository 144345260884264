import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AddAccess from "./AddAccess";
import EditAccess from "./EditAccess";
import DeactiveAccess from "./DeactiveAccess";
import { getALLAccessDetails } from "../../actions/setting";
import Pagination from "../layout/Pagination";
import Spinner from "../layout/Spinner";
const AllAccess = ({
  auth: { isAuthenticated, user, users },
  setting: { accessData },
  getALLAccessDetails,
}) => {
  useEffect(() => {
    getALLAccessDetails();
  }, [getALLAccessDetails]);
  const [showAddModal, setShowAddModal] = useState(false);
  const handleAddModalClose = () => setShowAddModal(false);
  const onClickHandler = () => {
    setShowAddModal(true);
  };

  const onAddModalChange = (e) => {
    if (e) {
      handleAddModalClose();
    }
  };
  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (accessData, idx) => {
    setShowUpdateModal(true);
    setUserData(accessData);
  };

  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const [userDatas, setUserDatas] = useState(null);
  const onUpdate = (accessData, idx) => {
    setShowDeactiveModal(true);
    setUserDatas(accessData);
  };

  //pagination code
  const [currentData, setCurrentData] = useState(1);
  const [dataPerPage] = useState(8);
  //Get Current Data
  const indexOfLastData = currentData * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentDatas =
    accessData && accessData.slice(indexOfFirstData, indexOfLastData);
  //change page
  const paginate = (nmbr) => {
    setCurrentData(nmbr);
  };
  //pagination code ends
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-12 col-sm-12 col-12 no_padding">
            <div className="row col-lg-5 col-md-11 col-sm-10 col-10">
              <h5 className="heading_color">All Access </h5>
            </div>

            <div className="col-lg-7 col-md-1 col-sm-1 col-1 py-4">
              <img
                className="img_icon_size log float-right"
                onClick={() => onClickHandler()}
                src={require("../../static/images/add-icon.png")}
                alt="Add Access"
                title="Add Access"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 text-center py-2">
              <section className="body">
                <div className=" body-inner no-padding  table-responsive">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Module Name</th>
                        <th>Description</th>
                        <th>Op</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentDatas &&
                        currentDatas.map((accessData, idx) => {
                          return (
                            <tr key={idx}>
                              {accessData.accessStatus &&
                              accessData.accessStatus === "Deactive" ? (
                                <td style={{ backgroundColor: "#dda6a6" }}>
                                  {" "}
                                  {accessData.accessName}{" "}
                                </td>
                              ) : (
                                <td> {accessData.accessName} </td>
                              )}

                              <td>{accessData.accessDescription} </td>
                              <td>
                                {accessData.accessStatus &&
                                accessData.accessStatus !== "Deactive" ? (
                                  <>
                                    <img
                                      className="img_icon_size log"
                                      onClick={() => onUpdate(accessData, idx)}
                                      src={require("../../static/images/delete.png")}
                                      alt="Deactivate Access"
                                      title="Deactivate Access"
                                    />{" "}
                                    &nbsp;
                                    <img
                                      className="img_icon_size log"
                                      onClick={() => onEdit(accessData, idx)}
                                      src={require("../../static/images/edit_icon.png")}
                                      alt="Edit Access"
                                      title="Edit Access"
                                    />
                                  </>
                                ) : (
                                  <label>
                                    Deactivated &nbsp;
                                    <img
                                      className="img_icon_size log "
                                      src={require("../../static/images/info.png")}
                                      alt="Reason"
                                      title={accessData.accessDeactiveReason}
                                    />
                                  </label>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </section>
        <Modal
          show={showAddModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Add Module</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleAddModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <AddAccess onAddModalChange={onAddModalChange} />
          </Modal.Body>
        </Modal>
        <Modal
          show={showUpdateModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Edit Module</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleUpdateModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditAccess
              editmoduledata={userData}
              onUpdateModalChange={onUpdateModalChange}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={showDeactiveModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Deactivate Module</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleDeactiveModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <DeactiveAccess
              moduleDeactiveData={userDatas}
              onDeactiveModalChange={onDeactiveModalChange}
            />
          </Modal.Body>
        </Modal>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
            {accessData && accessData.length !== 0 ? (
              <Pagination
                dataPerPage={dataPerPage}
                totalData={accessData.length}
                paginate={paginate}
                currentPage={currentData}
              />
            ) : (
              <Fragment />
            )}
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 col-12 align_right">
            <label>No. of Accesses : {accessData.length}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AllAccess.propTypes = {
  auth: PropTypes.object.isRequired,
  setting: PropTypes.object.isRequired,
  getALLAccessDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  setting: state.setting,
});

export default connect(mapStateToProps, {
  getALLAccessDetails,
})(AllAccess);
