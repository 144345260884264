import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AddProgram from "./AddProgram";
import EditProgram from "./EditProgram";
import DeactiveProgram from "./DeactiveProgram";
import { getALLProgramDetails } from "../../actions/skill";
import Spinner from "../layout/Spinner";
const AllPrograms = ({
  auth: { isAuthenticated, user, users },
  skill: { programData },
  shg: { activeInstitutions },
  getALLProgramDetails,
}) => {
  useEffect(() => {
    getALLProgramDetails();
  }, [getALLProgramDetails]);
  const [showAddModal, setShowAddModal] = useState(false);
  const handleAddModalClose = () => setShowAddModal(false);
  const onClickHandler = () => {
    setShowAddModal(true);
  };

  const onAddModalChange = (e) => {
    if (e) {
      handleAddModalClose();
    }
  };
  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (programData, idx) => {
    setShowUpdateModal(true);
    setUserData(programData);
  };

  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const [userDatas, setUserDatas] = useState(null);
  const onUpdate = (programData, idx) => {
    setShowDeactiveModal(true);
    setUserDatas(programData);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-12 col-sm-12 col-12 no_padding">
            <div className="row col-lg-5 col-md-11 col-sm-10 col-10">
              <h5 className="heading_color">All Programs </h5>
            </div>
            {/* {user.userGroupName !== "Super Admin" && ( */}
            <div className="col-lg-7 col-md-1 col-sm-1 col-1 py-4">
              <img
                className="img_icon_size log float-right"
                onClick={() => onClickHandler()}
                src={require("../../static/images/add-icon.png")}
                alt="Add Training Type"
                title="Add Training Type"
              />
            </div>
            {/* )} */}
          </div>

          <div className="row">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 text-center py-2">
              <section className="body">
                <div className=" body-inner no-padding  table-responsive">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Training Type Name</th>
                        <th>Description</th>
                        <th>Op</th>
                      </tr>
                    </thead>
                    <tbody>
                      {programData &&
                        programData.map((programData, idx) => {
                          return (
                            <tr key={idx}>
                              <td> {programData.programName} </td>
                              <td>{programData.programDescription} </td>
                              <td>
                                {programData.programStatus &&
                                programData.programStatus !== "Deactive" ? (
                                  <>
                                    <img
                                      className="img_icon_size log"
                                      onClick={() => onUpdate(programData, idx)}
                                      src={require("../../static/images/delete.png")}
                                      alt="Deactivate Training Type"
                                      title="Deactivate Training Type"
                                    />{" "}
                                    &nbsp;
                                    <img
                                      className="img_icon_size log"
                                      onClick={() => onEdit(programData, idx)}
                                      src={require("../../static/images/edit_icon.png")}
                                      alt="Edit Training Type"
                                      title="Edit Training Type"
                                    />
                                  </>
                                ) : (
                                  <label>Deactivated</label>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-6 col-sm-12 col-12 align_right">
                    <label>No. of Programs : {programData.length}</label>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        <Modal
          show={showAddModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">
                Add Training Type Details
              </h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleAddModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <AddProgram onAddModalChange={onAddModalChange} />
          </Modal.Body>
        </Modal>
        <Modal
          show={showUpdateModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">
                Edit Training Type Details{" "}
              </h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleUpdateModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditProgram
              programs={userData}
              onUpdateModalChange={onUpdateModalChange}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={showDeactiveModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Deactivate Training</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleDeactiveModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <DeactiveProgram
              programDeactiveData={userDatas}
              onDeactiveModalChange={onDeactiveModalChange}
            />
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

AllPrograms.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  skill: PropTypes.object.isRequired,
  getALLProgramDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  shg: state.shg,
  skill: state.skill,
});

export default connect(mapStateToProps, {
  getALLProgramDetails,
})(AllPrograms);
