import React, { useState, Fragment, useRef, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import {
  getBatchwiseReport,
  getBatchDetails,
  getActiveOtherLoanType,
  getMemberWiseLoanReport,
} from "../../actions/shg";

import { getAllCoordinators, getAllIncharge } from "../../actions/user";

const Memberwiseloanreport = ({
  user: { allcoordinator, allincharge },
  shg: { batchwisereport, activeOtherLoanTypes, batchDetails, memLoanReport },
  getAllCoordinators,
  getAllIncharge,
  getBatchwiseReport,
  getBatchDetails,
  getActiveOtherLoanType,
  getMemberWiseLoanReport,
}) => {
  useEffect(() => {
    getAllCoordinators();
  }, [getAllCoordinators]);
  useEffect(() => {
    getAllIncharge();
  }, [getAllIncharge]);
  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);
  useEffect(() => {
    getMemberWiseLoanReport();
  }, [getMemberWiseLoanReport]);

  let selBatchwiseReport = JSON.parse(
    localStorage.getItem("selBatchwiseReport")
  );

  useEffect(() => {
    getBatchwiseReport(selBatchwiseReport);
  }, [getBatchwiseReport]);
  //permision
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const allLoanType = [
    { value: "Internal", label: "Internal Loan" },
    { value: "otherLoan", label: "Other Loan" },
  ];
  const allLoanStatus = [
    { value: "Active", label: "Active" },
    { value: "Closed", label: "Closed" },
    { value: "Overdue", label: "Overdue" },
    { value: "Oclosed", label: "Oclosed" },
  ];

  const csvData = [
    [
      "Member Name",
      "SHG Name",
      "Loan Sanctioned Date",
      "Loan Sanctioned Principal",
      "Loan Sanctioned Interest",
      "Total Loan Sanctioned Amount",
      "Duration",
      "Meetings Not Paid",
      "Paid principal",
      "Paid intrest",
      "Tot paid",
      "Due Amount Principal",
      "Due Amount Interest",
      "Total Due Amount",
      "Loan Type",
      "Loan Status",
      "Loan Name",
      "Incharge",
      "Cordinator",
    ],
  ];

  memLoanReport.map((memLoanReport) => {
    return csvData.push([
      memLoanReport.memberName,
      memLoanReport.batchName,
      memLoanReport.meetingHeldOnDate,
      memLoanReport.loanPayablePrincipal,
      memLoanReport.loanPayableInterest,
      memLoanReport.loanSanctionedAmt,
      memLoanReport.duration,
      memLoanReport.notPaidCount,
      memLoanReport.loanCurPrincipal || 0,
      memLoanReport.loanCurIntrest || 0,
      memLoanReport.loanCurTot || 0,
      memLoanReport.duePrincipal,
      memLoanReport.dueIntrest,
      memLoanReport.dueTotal,
      memLoanReport.otherLoanType ? memLoanReport.otherLoanType : "Internal",
      memLoanReport.loanStatus,
      memLoanReport.intLoanType || "OtherLoan",
      memLoanReport.batchInchargeName,
      memLoanReport.CoordinatorName,
    ]);
  });

  //print
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

    documentTitle: "Memberwise Loan Report",
    // onAfterPrint: () => alert("print success"),
    //    setShowPrint("black");
  });

  //Cordinator
  const allcoordinators = [];
  allcoordinator.map((coordinator) =>
    allcoordinators.push({
      batchcoordinatorId: coordinator._id,
      label: coordinator.userFullName,
      value: coordinator.userFullName,
    })
  );
  const [coordinator, getcoordinatorData] = useState();
  const [batchcoordinatorId, setcoordinatorID] = useState();
  const [userFullName1, setcoordinatorName] = useState();

  const onCoordinatorsChange = (e) => {
    getcoordinatorData(e);
    getinchargeData("");
    setcoordinatorID(e.batchcoordinatorId ? e.batchcoordinatorId : null);
    setcoordinatorName(e.value ? e.value : "");
    const changeData = {
      batchcoordinatorId: e.batchcoordinatorId,
    };
    getBatchDetails(changeData);
  };
  //Incharge
  const allincharges = [];
  allincharge.map((incharge) =>
    allincharges.push({
      batchInchargeId: incharge._id,
      label: incharge.userFullName,
      value: incharge.userFullName,
    })
  );
  const [incharge, getinchargeData] = useState();
  const [batchInchargeId, setinchargeID] = useState();
  const [userFullName, setinchargeName] = useState();

  const onInchargeChange = (e) => {
    getinchargeData(e);
    getcoordinatorData("");
    setinchargeID(e.batchInchargeId ? e.batchInchargeId : null);
    setinchargeName(e.value ? e.value : "");
    const changeData = {
      batchInchargeId: e.batchInchargeId,
    };
    getBatchDetails(changeData);
  };
  //loanStatus
  const [LoanstatusType, getLoanstatusType] = useState();
  const [LoanstatusTypeName, getLoanstatusTypeName] = useState();
  const onLoanStatusChange = (e) => {
    getLoanstatusType(e);
    getLoanstatusTypeName(e.value);
    const changeData = {
      batchId: batchId,
      isLoanType: LoanTypeName ? LoanTypeName : "",
      otherLoanTypeId: otherLoanTypeId ? otherLoanTypeId : "",
      loanStatus: e.value,
    };
    getMemberWiseLoanReport(changeData);
  };

  //loan types
  const [LoanType, getLoanType] = useState();
  const [LoanTypeName, getLoanTypeName] = useState();
  const onLoanTypeChange = (e) => {
    getLoanType(e);
    getLoanTypeName(e.value);
    if (e.value === "otherLoan") {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: true,
      });
    } else {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: false,
      });
    }
    const changeData = {
      batchId: batchId,
      isLoanType: e.value,
    };
    getMemberWiseLoanReport(changeData);
    getLoanstatusType("");
  };
  //otherloan
  const [otherLoanType, getOtherLoanTypeData] = useState("");
  const [otherLoanTypeId, setOtherLoanTypeId] = useState("");
  const [otherLoanTypeName, setOtherLoanTypeName] = useState("");

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );
  const onotherLoanTypeChange = (e) => {
    getOtherLoanTypeData(e);
    setOtherLoanTypeId(e.otherLoanTypeId ? e.otherLoanTypeId : "");
    setOtherLoanTypeName(e.value ? e.value : "");
    const changeData = {
      batchId: batchId,
      isLoanType: LoanTypeName,
      otherLoanTypeId: e.otherLoanTypeId,
    };
    getMemberWiseLoanReport(changeData);
    getLoanstatusType("");
  };
  //Hide other loan
  const [showHideBank, setbankShowHide] = useState({
    showbankotherPurposeSection: false,
  });
  const { showbankotherPurposeSection } = showHideBank;
  //SHG
  const allbatchesList = [];
  batchDetails.map((batchs) =>
    allbatchesList.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );
  const [batchs, getbatchsData] = useState();
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();

  const onBatchChange = (e) => {
    setbatchId(e.batchId ? e.batchId : null);
    setbatchName(e.value ? e.value : "");
    const changeData = {
      batchId: e.batchId,
    };
    getbatchsData(e);
    getMemberWiseLoanReport(changeData);
  };
  //Refresh
  const onClickReset = () => {
    getcoordinatorData("");
    getinchargeData("");
    getLoanType("");
    getMemberWiseLoanReport("");
    getbatchsData("");
    getOtherLoanTypeData("");
    getLoanstatusType("");
  };

  return (
    <div>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color">Memberwise Loan Report </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding">
            {allPermission && allPermission.includes("Co-ordinator") ? (
              <>
                <div className="col-lg-2 col-md-3 col-sm-3 col-11 mt-1">
                  <Select
                    name="userFullName1"
                    options={allcoordinators}
                    isSearchable={true}
                    value={coordinator}
                    placeholder="Select Cordinator"
                    onChange={(e) => onCoordinatorsChange(e)}
                  />
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="col-lg-2 col-md-3 col-sm-3 col-11 mt-1">
              <Select
                name="userFullName"
                options={allincharges}
                isSearchable={true}
                value={incharge}
                placeholder="Select Incharge"
                onChange={(e) => onInchargeChange(e)}
                menuPortalTarget={document.body} // Use this to control z-index
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Set the desired z-index
                }}
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-3 col-11 mt-1">
              <Select
                name="batchName"
                options={allbatchesList}
                isSearchable={true}
                value={batchs}
                placeholder="Select SHG"
                onChange={(e) => onBatchChange(e)}
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-3 col-11 mt-1">
              <Select
                name="LoanType"
                options={allLoanType}
                isSearchable={true}
                value={LoanType}
                placeholder="Select Loan Type"
                onChange={(e) => onLoanTypeChange(e)}
              />
            </div>
            {/* {showbankotherPurposeSection && (
              <div className="col-lg-2 col-md-3 col-sm-3 col-11 mt-1">
                <Select
                  name="otherLoanTypes"
                  options={activeOtherLoan}
                  //  isSearchable={true}
                  value={otherLoanType}
                  placeholder="Select Other Loan"
                  onChange={(e) => onotherLoanTypeChange(e)}
                />
              </div>
            )} */}

            {/* <div className="col-lg-2 col-md-3 col-sm-3 col-11 mt-1">
              <Select
                name="LoanstatusType"
                options={allLoanStatus}
                isSearchable={true}
                value={LoanstatusType}
                placeholder="Select Loan Status"
                onChange={(e) => onLoanStatusChange(e)}
              />
            </div> */}
            <div className="col-lg-12 col-md-3 col-sm-4 col-11 py-3 ">
              <Link to="/all-reports">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>

              <img
                className="img_icon_size log float-right ml-3"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
              {/* <img
                className="img_icon_size log float-right ml-4"
                src={require("../../static/images/print.png")}
                onClick={handlePrint}
                alt="print"
                title="print"
              /> */}

              <CSVLink data={csvData}>
                <img
                  className="img_icon_size log float-right ml-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="Excel-Export"
                  title="Excel-Export"
                />
              </CSVLink>
            </div>
          </div>
          <div className="row" ref={componentRef}>
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive table-container mt-5">
                  <table
                    className="table table-bordered table-striped table-hover  "
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th className="sticky-column" style={{ zIndex: "999" }}>
                          Member Name
                        </th>
                        {/* <th>SHG Name</th> */}
                        <th>Loan Date</th>
                        <th>Loan Principle</th>
                        <th>Loan Interest</th>
                        <th>Loan Total</th>
                        <th>Loan Duration</th>
                        {/* <th>Unpaid Meetings</th> */}
                        <th>Paid Principle</th>
                        <th>Paid Interest</th>
                        <th>Paid Total</th>
                        <th>Due Principal</th>
                        {/* <th>Due Interest</th> */}
                        {/* <th>Due Total</th> */}
                        <th>Loan Type</th>
                        <th>Loan Status</th>
                        <th>Loan Name</th>
                        {/* <th>OP</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {memLoanReport &&
                        memLoanReport.map((memLoanReport, idx) => {
                          var ED =
                            memLoanReport &&
                            memLoanReport.loanTakenDate &&
                            memLoanReport.loanTakenDate.split(/\D/g);
                          var meetingHeldOnDate =
                            ED && ED.length === 3
                              ? [ED[2], ED[1], ED[0]].join("-")
                              : "Date N/A";

                          return (
                            <tr key={idx}>
                              <td
                                className={
                                  Number(memLoanReport.notPaidCount) > 0
                                    ? "colorchange sticky-column "
                                    : " sticky-column"
                                }
                              >
                                {memLoanReport.memberName}
                              </td>

                              <td>{meetingHeldOnDate}</td>
                              <td>{memLoanReport.loanPayablePrincipal}</td>
                              <td>{memLoanReport.loanPayableInterest}</td>
                              <td>{memLoanReport.loanSanctionedAmt}</td>
                              <td>{memLoanReport.duration}</td>

                              <td>
                                {memLoanReport.loanRepayment.reduce(
                                  (acu, cur) =>
                                    (acu += Number(cur.loanPrincipalPaid)),
                                  0
                                ) || 0}
                              </td>
                              <td>
                                {memLoanReport.loanRepayment.reduce(
                                  (acu, cur) =>
                                    (acu += Number(cur.loanInterestPaid)),
                                  0
                                ) || 0}
                              </td>
                              <td>
                                {memLoanReport.loanRepayment.reduce(
                                  (acu, cur) =>
                                    (acu +=
                                      Number(cur.loanPrincipalPaid) +
                                      Number(cur.loanInterestPaid)),
                                  0
                                ) || 0}
                              </td>

                              <td>
                                {Number(memLoanReport.loanPayablePrincipal) -
                                  Number(
                                    memLoanReport.loanRepayment.reduce(
                                      (acu, cur) =>
                                        (acu += Number(cur.loanPrincipalPaid)),
                                      0
                                    )
                                  )}
                              </td>

                              <td>
                                {memLoanReport.otherLoanType
                                  ? memLoanReport.otherLoanType
                                  : "Internal"}
                              </td>

                              <td>{memLoanReport.loanStatus}</td>

                              <td>
                                {memLoanReport.intLoanType || "OtherLoan"}
                              </td>
                              {/* <td>
                                <img
                                  className="img_icon_size log mx-2"
                                  src={require("../../static/images/edit_icon.png")}
                                  alt="Edit"
                                  title="Edit"
                                  style={{ cursor: "pointer" }}
                                />
                              </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

Memberwiseloanreport.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  getAllCoordinators: PropTypes.func.isRequired,
  getAllIncharge: PropTypes.func.isRequired,
  getBatchDetails: PropTypes.func.isRequired,
  getActiveOtherLoanType: PropTypes.func.isRequired,
  getMemberWiseLoanReport: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  user: state.user,
  area: state.area,
});

export default connect(mapStateToProps, {
  getBatchwiseReport,
  getAllCoordinators,
  getAllIncharge,
  getBatchDetails,
  getActiveOtherLoanType,
  getMemberWiseLoanReport,
})(Memberwiseloanreport);
