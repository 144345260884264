import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import Select from "react-select";
import { EditBatchLoanDetails } from "../../actions/shg";

const EditOtherLoanDetails = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { loanreason },
  otherloanpurposedata,
  onotherloanUpdateModalChange,
  EditBatchLoanDetails,
}) => {
  //formData
  const [formData, setFormData] = useState({
    memberName:
      otherloanpurposedata && otherloanpurposedata.memberName
        ? otherloanpurposedata.memberName
        : "",
    otherLoanSanctionedAmt:
      otherloanpurposedata && otherloanpurposedata.otherLoanSanctionedAmt
        ? otherloanpurposedata.otherLoanSanctionedAmt
        : "",
    bankloanOtherReason:
      otherloanpurposedata && otherloanpurposedata.otherloanotherpurpose
        ? otherloanpurposedata.otherloanotherpurpose
        : "",
    externalNominee:
      otherloanpurposedata && otherloanpurposedata.externalNominee
        ? otherloanpurposedata.externalNominee
        : "",
    isSubmitted: false,
  });

  const {
    otherPurpose,
    otherLoanSanctionedAmt,
    memberName,
    externalNominee,
    bankloanOtherReason,
  } = formData;

  const [showHideBank, setbankShowHide] = useState({
    showbankotherPurposeSection:
      otherloanpurposedata && otherloanpurposedata.otherLoanReason === "Other"
        ? true
        : false,
  });
  const { showbankotherPurposeSection } = showHideBank;
  const allbankpurpose = [];
  loanreason.map((bankpurpose) =>
    allbankpurpose.push({
      bankloanpuroseId: bankpurpose._id,
      label: bankpurpose.loanPurpose,
      value: bankpurpose.loanPurpose,
    })
  );

  const [otherloanpurpose, getbankpurposeData] = useState(
    otherloanpurposedata && otherloanpurposedata
      ? allbankpurpose &&
          allbankpurpose.filter(
            (x) => x.bankloanpuroseId === otherloanpurposedata.otherLoanReasonId
          )[0]
      : ""
  );
  const [bankloanpuroseId, setbankloanpuroseId] = useState(
    otherloanpurposedata && otherloanpurposedata.otherLoanReasonId
  );
  const [bankloanPurpose, setbankloanPurpose] = useState(
    otherloanpurposedata && otherloanpurposedata.otherLoanReason
  );

  const onBankLoanPurposeChange = (e) => {
    // //Required Validation starts
    // setError({
    //   ...error,
    //   otherPurposeIdChecker: true,
    //   otherPurposeErrorStyle: { color: "#000" },
    // });
    // //Required Validation ends

    var bankloanpuroseId = "";
    var bankloanPurpose = "";
    getbankpurposeData(e);

    bankloanpuroseId = e.bankloanpuroseId;
    bankloanPurpose = e.value;

    setbankloanpuroseId(bankloanpuroseId);
    setbankloanPurpose(bankloanPurpose);

    if (e) {
      setFormData({
        ...formData,
        bankloanPurpose: e,
      });
    }

    if (e.value === "Other") {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: true,
      });
    } else {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: false,
      });
      setFormData({ ...formData, bankloanOtherReason: "" });
    }
  };
  const onInputChange1 = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [showInformationModal, setShowInformation] = useState(false);

  const handleInformationModalClose = () => setShowInformation(false);
  const LogoutModalClose = () => {
    handleInformationModalClose();
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const onUpdate = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: otherloanpurposedata ? otherloanpurposedata._id : "",
      batchId: otherloanpurposedata.batchId,
      memberId: otherloanpurposedata.memberId,
      meetingHeldOnDate: otherloanpurposedata.meetingHeldOnDate,
      loanSanctionedAmt: null,
      otherPurpose: null,
      loanReasonId: null,
      loanPurpose: null,
      otherLoanSanctionedAmt: otherLoanSanctionedAmt,
      otherLoanReasonId: bankloanpuroseId,
      otherLoanReason: bankloanPurpose,
      otherloanotherpurpose: bankloanOtherReason,
      externalNominee: externalNominee,
      loanSanctionedEditedById: user && user._id,
      loanSanctionedEditedByName: user && user.userFullName,
      loanSanctionedEditedDateTime: Date.now(),
      institutionId: otherloanpurposedata.institutionId,
      memberEditData: otherloanpurposedata,
    };
    EditBatchLoanDetails(finalData);
    onotherloanUpdateModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
        <form onSubmit={(e) => onUpdate(e)}>
          <div className="row card-new  py-3">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control">
                Loan Sanctioned-Member : {memberName}
              </label>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control">Loan Sanctioned Amount* :</label>
              <input
                type="number"
                name="otherLoanSanctionedAmt"
                value={otherLoanSanctionedAmt}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => onInputChange1(e)}
                onKeyDown={(e) => funcKeyDown(e)}
                required
              />
            </div>
            <div className="col-lg-6  col-md-12 col-sm-12 col-12">
              <label className="label-control">Nominee :</label>
              <input
                type="text"
                name="externalNominee"
                value={externalNominee}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => onInputChange1(e)}
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label
                className="label-control"
                // style={PurposeErrorStyle}
              >
                Loan Sanctioned Purpose :
              </label>

              <Select
                name="loanPurpose"
                options={allbankpurpose}
                isSearchable={true}
                value={otherloanpurpose}
                placeholder="Select Purpose"
                onChange={(e) => onBankLoanPurposeChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
            {showbankotherPurposeSection && (
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label className="label-control">Other Reason*</label>
                <input
                  type="text"
                  name="bankloanOtherReason"
                  value={bankloanOtherReason}
                  className="form-control"
                  onChange={(e) => onInputChange1(e)}
                  required
                />
              </div>
            )}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <input
                type="submit"
                name="Submit"
                value="Update"
                className="btn sub_form btn_continue blackbrd Save float-right"
              />
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditOtherLoanDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, { EditBatchLoanDetails })(
  EditOtherLoanDetails
);
