import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { AddParish, getVarados } from "../../actions/area";
import Spinner from "../layout/Spinner";

const AddParishDetails = ({
  auth: { isAuthenticated, user, users, loading },
  area: { varadosData },
  getVarados,
  AddParish,
}) => {
  useEffect(() => {
    getVarados();
  }, [getVarados]);

  //formData
  const [formData, setFormData] = useState({
    parishName: "",
    parishCode: "",
    isSubmitted: false,
  });

  const { parishName, parishCode } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const allvarado = [];
  varadosData.map((varado) =>
    allvarado.push({
      varadoId: varado._id,
      label: varado.varadoName,
      value: varado.varadoName,
    })
  );

  const [varado, getVaradoData] = useState();
  const [varadoId, setVaradoID] = useState();

  const onVaradoChange = (e) => {
    //Required Validation starts
    setError({
      ...error,
      vIdChecker: true,
      vIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends

    var varadoId = "";
    getVaradoData(e);
    varadoId = e.varadoId;
    setVaradoID(varadoId);
  };

  //Required Validation Starts
  const [error, setError] = useState({
    vIdChecker: false,
    vIdErrorStyle: {},
  });
  const { vIdChecker, vIdErrorStyle } = error;

  const checkErrors = () => {
    if (!vIdChecker) {
      setError({
        ...error,
        vIdErrorStyle: { color: "#F00" },
      });
      return false;
    }
    return true;
  };
  //Required Validation ends

  const onSubmit = (e) => {
    e.preventDefault();
    //Required Validation Starts
    if (checkErrors()) {
      const finalData = {
        parishName: parishName,
        parishCode: parishCode,
        vId: varadoId,
        parishEnteredById: user._id,
        institutionId: user.institutionId,
        batchCounter: "01",
        userData: user,
      };
      AddParish(finalData);
      setFormData({
        ...formData,
        parishName: "",
        parishCode: "",
        isSubmitted: true,
      });
      getVaradoData("");
    }
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control"> Parish Name* :</label>
              <input
                type="text"
                name="parishName"
                value={parishName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control">Parish Code* :</label>
              <input
                type="text"
                name="parishCode"
                value={parishCode}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
              <label className="label-control" style={vIdErrorStyle}>
                Varado* :
              </label>
              <Select
                name="varadoName"
                options={allvarado}
                isSearchable={true}
                value={varado}
                placeholder="Select Varado"
                onChange={(e) => onVaradoChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
                required
              />
            </div>
          </div>

          <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Save"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

AddParishDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  AddParish: PropTypes.func.isRequired,
  getVarados: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
});

export default connect(mapStateToProps, {
  AddParish,
  getVarados,
})(AddParishDetails);
