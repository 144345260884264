import React, { useState } from "react";
import Select from "react-select";
const IncomeTab = ({
  membersMeetingData,
  activeBank,
  allPermission,
  formData,
  setFormData,
  NextBackBtn,
}) => {
  let { sdUnionToSHG, deactiveMemberSaving, loanRepaidByOtherSHG } = formData;

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  let dataOfIncomeTab = {
    batchSavingAmtCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.savingAmt.cash || 0)),
      0
    ),
    batchSavingAmtBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.savingAmt.bankAmt || 0)),
      0
    ),

    //sub amt

    batchSubAmtCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.subscriptionAmt.cash || 0)),
      0
    ),
    batchSubAmtBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.subscriptionAmt.bankAmt || 0)),
      0
    ),

    //batchOtherContribution

    batchOtherContributionCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.otherContribution.cash || 0)),
      0
    ),
    batchOtherContributionBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.otherContribution.bankAmt || 0)),
      0
    ),

    //batchMembershipFees

    batchMembershipFeesCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.membershipFees.cash || 0)),
      0
    ),
    batchMembershipFeesBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.membershipFees.bankAmt || 0)),
      0
    ),

    //batchLoanPaid
    batchLoanPaidCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.loanPaid.cash || 0)),
      0
    ),

    batchLoanPaidBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.loanPaid.bankAmt || 0)),
      0
    ),
    batchInterestPaidCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.interestPaid.cash || 0)),
      0
    ),
    batchInterestPaidBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.interestPaid.bankAmt || 0)),
      0
    ),

    batchOtherIncomeCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.otherIncome.cash || 0)),
      0
    ),

    batchOtherIncomeBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.otherIncome.bankAmt || 0)),
      0
    ),

    seedMoneyCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.memSeedMoney.cash || 0)),
      0
    ),

    seedMoneyBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.memSeedMoney.bankAmt || 0)),
      0
    ),

    batchSecurityDepositMemToShgCash: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.memSecurityDepositToShg.cash || 0)),
      0
    ),
    batchSecurityDepositMemToShgBank: membersMeetingData.reduce(
      (acu, cur) => (acu += Number(cur.memSecurityDepositToShg.bankAmt || 0)),
      0
    ),
  };

  const {
    batchSavingAmtCash,
    batchSavingAmtBank,
    batchSubAmtCash,
    batchSubAmtBank,
    batchOtherContributionCash,
    batchOtherContributionBank,
    batchMembershipFeesCash,
    batchMembershipFeesBank,
    batchLoanPaidCash,
    batchLoanPaidBank,
    //sd
    batchInterestPaidCash,
    batchInterestPaidBank,
    batchOtherIncomeCash,
    batchOtherIncomeBank,
    seedMoneyCash,
    seedMoneyBank,
    batchSecurityDepositMemToShgCash,
    batchSecurityDepositMemToShgBank,
  } = dataOfIncomeTab;


  const[newBatchOtherContributionBank,setNewBatchOtherContributionBank] = useState();
  const[newBatchOtherContributionCash,setBatchOtherContributionCash] = useState();
  
  const[newBatchOtherIncomeBank,setBatchOtherIncomeBank] = useState();
  const[newBatchOtherIncomeCash,setBatchOtherIncomeCash] = useState();
  
  const[newSeedMoneyBank,setSeedMoneyBank] =useState();
  const[newSeedMoneyCash,setSeedMoneyCash] =useState();

  const[otherContributionBankName,setsOtherContributionBankName] = useState("");
  const[otherIncomeBankName,setsOtherIncomeBankName] = useState("")
  const[seedMoneyBankName,setsSeedMoneyBankName] = useState("");
  // console.log("otherContributionBankName",otherContributionBankName)

  const [sdUnionToSHGBank, setsdUnionToSHGBank] = useState(
    sdUnionToSHG ? sdUnionToSHG.bankAmt : 0
  );

  const [sdUnionToSHGBankName, setsdUnionToSHGBankName] = useState(
    sdUnionToSHG.bankName
      ? {
          label: sdUnionToSHG.bankName,
          value: sdUnionToSHG.bankName,
        }
      : ""
  );

  const [sdUnionToSHGCash, setsdUnionToSHGCash] = useState(
    sdUnionToSHG ? sdUnionToSHG.cash : 0
  );

  //deactive memberSaving

  const [
    deactiveSavingMemberAmtCash,
    setdeactiveSavingMemberAmtCash,
  ] = useState(deactiveMemberSaving ? deactiveMemberSaving.cash : 0);

  const [
    deactiveSavingMemberAmtBank,
    setdeactiveSavingMemberAmtBank,
  ] = useState(deactiveMemberSaving ? deactiveMemberSaving.bankAmt : 0);

  const [
    deactiveSavingMemberAmtBankName,
    setdeactiveSavingMemberAmtBankName,
  ] = useState(
    deactiveMemberSaving.bankName
      ? {
          label: deactiveMemberSaving.bankName,
          value: deactiveMemberSaving.bankName,
        }
      : ""
  );

  //loan
  const [
    loanRepaidByOtherSHGBankName,
    setloanRepaidByOtherSHGBankName,
  ] = useState(
    loanRepaidByOtherSHG
      ? {
          label: loanRepaidByOtherSHG.bankName,
          value: loanRepaidByOtherSHG.bankName,
        }
      : ""
  );

  const [loanRepaidByOtherSHGBank, setloanRepaidByOtherSHGBank] = useState(
    loanRepaidByOtherSHG ? loanRepaidByOtherSHG.bankAmt : 0
  );
  const [loanRepaidByOtherSHGCash, setloanRepaidByOtherSHGCash] = useState(
    loanRepaidByOtherSHG ? loanRepaidByOtherSHG.cash : 0
  );

  // setFormData;

  const incomeNextTab = () => {
    let finalIncomeData = {
      batchSavingAmt: {
        cash: batchSavingAmtCash,
        bankAmt: batchSavingAmtBank,
        bankName: "",
      },

      batchSubAmt: {
        cash: batchSubAmtCash,
        bankAmt: batchSubAmtBank,
        bankName: "",
      },

      batchLoanPaid: {
        cash: batchLoanPaidCash,
        bankAmt: batchLoanPaidBank,
        bankName: "",
      },
      batchOtherIncome: {
        cash: newBatchOtherIncomeCash,
        bankAmt: newBatchOtherIncomeBank,
        bankName: otherIncomeBankName.value,
      },
      batchOtherContribution: {
        cash: newBatchOtherContributionCash,
        bankAmt: newBatchOtherContributionBank,
        bankName: otherContributionBankName.value,
      },

      batchMembershipFees: {
        cash: batchMembershipFeesCash,
        bankAmt: batchMembershipFeesBank,
        bankName: "",
      },

      seedMoney: {
        cash: newSeedMoneyCash,
        bankAmt: newSeedMoneyBank,
        bankName: seedMoneyBankName.value,
      },

      deactiveMemberSaving: {
        bankName: deactiveSavingMemberAmtBankName.label || "",
        bankAmt: Number(deactiveSavingMemberAmtBank),
        cash: Number(deactiveSavingMemberAmtCash),
      },

      batchInterestPaid: {
        cash: batchInterestPaidCash,
        bankAmt: batchInterestPaidBank,
        bankName: "",
      },
      batchSecurityDepositMemToShg: {
        cash: batchSecurityDepositMemToShgCash,
        bankAmt: batchSecurityDepositMemToShgBank,
        bankName: "",
      },

      // sdUnionToSHGBankName
      sdUnionToSHG: {
        cash: sdUnionToSHGCash,
        bankAmt: sdUnionToSHGBank,
        bankName: sdUnionToSHGBankName.label || "",
      },

      loanRepaidByOtherSHG: {
        cash: loanRepaidByOtherSHGCash,
        bankAmt: loanRepaidByOtherSHGBank,
        bankName: loanRepaidByOtherSHGBankName.label || "", // req flag
      },
    };

    setFormData({ ...formData, ...finalIncomeData });

    NextBackBtn(4);
  };

  return (
    <div className="row card-new py-3 col-12">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <h5>Income</h5>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
        <label className="label-control">SHG Saving Amount* :</label>

        <div className="row col-12">
          <div className="col-lg-6">
            Bank
            <input
              type="number"
              name="batchSavingAmtBank"
              value={batchSavingAmtBank}
              className="form-control"
              onKeyDown={(e) => funcKeyDown(e)}
              disabled
              required
            />
          </div>
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="batchSavingAmtCash"
              value={batchSavingAmtCash}
              className="form-control"
              onKeyDown={(e) => funcKeyDown(e)}
              disabled
              required
            />
          </div>
        </div>
        <label className="label-control">Subscription *:</label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank
            <input
              type="number"
              name="batchSubAmtBank"
              value={batchSubAmtBank}
              className="form-control"
              onKeyDown={(e) => funcKeyDown(e)}
              disabled
              required
            />
          </div>
          <div className="col-lg-6">
            Cash
            <input
              type="number"
              name="batchSubAmtCash"
              value={batchSubAmtCash}
              className="form-control"
              onKeyDown={(e) => funcKeyDown(e)}
              disabled
              required
            />
          </div>
        </div>
        <label className="label-control">Other Contribution :</label>
          {/* 1 */}
        <div className="row col-12">
         <div className="col-lg-6">
            Bank List
            <Select
              name="bankList"
              options={activeBank}
              value={otherContributionBankName}
              onChange={(e) => setsOtherContributionBankName(e)}
              isSearchable={true}
              placeholder="Select Bank"
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          {batchOtherContributionCash != 0 || batchOtherContributionBank !=0 ? (
             <div className="col-lg-6">
             Bank{" "}
             
             <input
               type="number"
               name="batchOtherContributionBank"
               value={batchOtherContributionBank}
               className="form-control"
               onChange={(e)=>setNewBatchOtherContributionBank(e.target.value)}
               onKeyDown={(e) => funcKeyDown(e)}
              disabled
             />
           </div>
          ) : (
            <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchOtherContributionBank"
              value={newBatchOtherContributionBank}
              className="form-control"
              onChange={(e)=>setNewBatchOtherContributionBank(e.target.value)}
              onKeyDown={(e) => funcKeyDown(e)}
             
            />
          </div>
          )}
         
        </div>
        <div className="col-lg-6">
          {batchOtherContributionCash != 0 || batchOtherContributionBank !=0  ? ( 
          <div className="col-lg-12">
           Cash{" "}
            <input
              type="number"
              name="batchOtherContributionCash"
              value={batchOtherContributionCash}
              className="form-control"
              onChange={(e)=>setBatchOtherContributionCash(e.target.value)}
              onKeyDown={(e) => funcKeyDown(e)}
              disabled
            />
           </div>) : (
             <div className="col-lg-12">
             Cash{" "}
              <input
                type="number"
                name="batchOtherContributionCash"
                value={newBatchOtherContributionCash}
                className="form-control"
                onChange={(e)=>setBatchOtherContributionCash(e.target.value)}
                onKeyDown={(e) => funcKeyDown(e)}
               
              />
             </div>
           ) }
          
          </div>
         
        <label className="label-control">Membership Fees :</label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchMembershipFeesBank"
              value={batchMembershipFeesBank}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
          <div className="col-lg-6">
            Cash{" "}
            <input
              type="number"
              name="batchMembershipFeesCash"
              value={batchMembershipFeesCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
        <label className="label-control">
          IL Repayment to SHG{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Internal Loan Repayment to SHG from the Members"
          />{" "}
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchLoanPaidBank"
              value={batchLoanPaidBank}
              className="form-control"
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
          <div className="col-lg-6">
            Cash{" "}
            <input
              type="number"
              name="batchLoanPaidCash"
              value={batchLoanPaidCash}
              className="form-control"
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
        <label className="label-control">
          SD Union To SHG{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title=" Security Deposit Amount Received from the Union to the SHG
            "
          />{" "}
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank List
            <Select
              name="bankList"
              options={activeBank}
              value={sdUnionToSHGBankName}
              onChange={(e) => setsdUnionToSHGBankName(e)}
              isSearchable={true}
              placeholder="Select Bank"
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank Amount{" "}
            <input
              type="number"
              name="sdUnionToSHGBank"
              value={sdUnionToSHGBank}
              disabled={sdUnionToSHGBankName ? false : true}
              onChange={(e) => {
                setsdUnionToSHGBank(e.target.value);
              }}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-lg-6">
            Cash{" "}
            <input
              type="number"
              name="sdUnionToSHGCash"
              value={sdUnionToSHGCash}
              onChange={(e) => setsdUnionToSHGCash(e.target.value)}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
        <label className="label-control">
          IL Interest Paid to SHG{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Internal Loan Interest Repayment to SHG from the Members"
          />{" "}
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchInterestPaidBank"
              value={batchInterestPaidBank}
              className="form-control"
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
          <div className="col-lg-6">
            Cash{" "}
            <input
              type="number"
              name="batchInterestPaidCash"
              value={batchInterestPaidCash}
              className="form-control"
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
{/* 2 */}
        <label className="label-control">Other Income :</label>
        <div className="row col-12">
        <div className="col-lg-6">
            Bank List
            <Select
              name="bankList"
              options={activeBank}
              value={otherIncomeBankName}
              onChange={(e) => setsOtherIncomeBankName(e)}
              isSearchable={true}
              placeholder="Select Bank"
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <div className="col-lg-6">
            Bank{" "}
            {batchOtherIncomeBank != 0 || batchOtherIncomeCash != 0 ? ( 
              <input
              type="number"
              name="batchOtherIncomeBank"
              value={batchOtherIncomeBank}
              className="form-control"
              disabled
              // onChange={(e)=>setBatchOtherIncomeBank(e.target.value)}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
            />) : (
              <input
              type="number"
              name="batchOtherIncomeBank"
              value={newBatchOtherIncomeBank}
              className="form-control"
              onChange={(e)=>setBatchOtherIncomeBank(e.target.value)}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
            />
            ) }
           
          </div>
        </div>
        <div className="row col-6">
        <div className="col-lg-12">
            Cash{" "}
            {batchOtherIncomeBank != 0 || batchOtherIncomeCash != 0 ? (  <input
              type="number"
              name="batchOtherIncomeCash"
              value={batchOtherIncomeCash}
              className="form-control"
              // onChange={(e)=>setBatchOtherIncomeCash(e.target.value)}
              onWheel={() => document.activeElement.blur()}
             disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />) :(  <input
              type="number"
              name="batchOtherIncomeCash"
              value={newBatchOtherIncomeCash}
              className="form-control"
              onChange={(e)=>setBatchOtherIncomeCash(e.target.value)}
              onWheel={() => document.activeElement.blur()}
             
              onKeyDown={(e) => funcKeyDown(e)}
            />) }
          
          </div>
        </div>
        {/* 3 */}
        <label className="label-control">
          Seed Money{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Government Grant For Shg Development (Non-Returnable)
            "
          />{" "}
          :
        </label>
        <div className="row col-12">
        <div className="col-lg-6">
            Bank List
            <Select
              name="bankList"
              options={activeBank}
              value={seedMoneyBankName}
              onChange={(e) => setsSeedMoneyBankName(e)}
              isSearchable={true}
              placeholder="Select Bank"
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>

          <div className="col-lg-6">
            Bank{" "}
            {seedMoneyBank !=0 || seedMoneyCash != 0 ? ( <input
              type="number"
              name="seedMoneyBank"
              value={seedMoneyBank}
              className="form-control"
              // onChange={(e)=>setSeedMoneyBank(e.target.value)}
               disabled
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
            />) :( <input
              type="number"
              name="seedMoneyBank"
              value={newSeedMoneyBank}
              className="form-control"
              onChange={(e)=>setSeedMoneyBank(e.target.value)}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
            />) }
           
          </div>
        </div>
        <div className="row col-6">
        <div className="col-lg-12">
            Cash{" "}
            {seedMoneyBank !=0 || seedMoneyCash != 0 ? ( <input
              type="number"
              name="seedMoneyCash"
              value={seedMoneyCash}
              className="form-control"
              // onChange={(e)=>setSeedMoneyCash(e.target.value)}
             disabled
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
            />):( <input
              type="number"
              name="seedMoneyCash"
              value={newSeedMoneyCash}
              className="form-control"
              onChange={(e)=>setSeedMoneyCash(e.target.value)}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
            />)}
           
          </div>
        </div>
        <label className="label-control">
          SD Member To SHG{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Security Deposit Amount Collected from the Members to SHG"
          />{" "}
          :
        </label>
        <div className="row col-12">
          <div className="col-lg-6">
            Bank{" "}
            <input
              type="number"
              name="batchSecurityDepositMemToShgBank"
              value={batchSecurityDepositMemToShgBank}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
          <div className="col-lg-6">
            Cash{" "}
            <input
              type="number"
              name="batchSecurityDepositMemToShgCash"
              value={batchSecurityDepositMemToShgCash}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              disabled
              onKeyDown={(e) => funcKeyDown(e)}
            />
          </div>
        </div>
        <label className="label-control">
          Deactive Member charity Amount{" "}
          <img
            className="img_icon_size log "
            src={require("../../../static/images/info.png")}
            alt="Reason"
            style={{ height: "18px", width: "18px" }}
            title="Any Amount Received from the Deactivated Member"
          />
          :
        </label>
        <div className="container">
          <div className="row">
            <div className="col-lg-6  d-flex flex-column">
              <label htmlFor="bankList">Bank List</label>
              <Select
                name="bankList"
                options={activeBank}
                value={deactiveSavingMemberAmtBankName}
                onChange={(e) => setdeactiveSavingMemberAmtBankName(e)}
                isSearchable={true}
                placeholder="Select Bank"
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
            <div className="col-lg-6  d-flex flex-column">
              <label htmlFor="deactiveMemberSavingBank">Bank Amount</label>
              <input
                type="number"
                name="deactiveMemberSavingBank"
                value={deactiveSavingMemberAmtBank}
                onChange={(e) => {
                  setdeactiveSavingMemberAmtBank(e.target.value);
                }}
                className="form-control"
                disabled={deactiveSavingMemberAmtBankName ? false : true}
                onWheel={() => document.activeElement.blur()}
                // disabled
                onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6  d-flex flex-column">
              <label htmlFor="deactiveMemberSavingCash">Cash</label>
              <input
                type="number"
                name="deactiveSavingMemberAmtCash"
                value={deactiveSavingMemberAmtCash}
                onChange={(e) => {
                  setdeactiveSavingMemberAmtCash(e.target.value);
                }}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                // disabled
                onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
          </div>
        </div>

        {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
          <>
            <label className="label-control">Loan Repaid By Other SHG :</label>
            <div className="row col-12">
              <label htmlFor="bankList">Bank List</label>
              <Select
                name="bankList"
                options={activeBank}
                value={loanRepaidByOtherSHGBankName}
                onChange={(e) => setloanRepaidByOtherSHGBankName(e)}
                isSearchable={true}
                placeholder="Select Bank"
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />

              <div className="col-lg-6">
                Bank{" "}
                <input
                  type="number"
                  name="loanRepaidByOtherSHGBank"
                  value={loanRepaidByOtherSHGBank}
                  onChange={(e) => {
                    setloanRepaidByOtherSHGBank(e.target.value);
                  }}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  disabled
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
              <div className="col-lg-6">
                Cash
                <input
                  type="number"
                  name="loanRepaidByOtherSHGCash"
                  value={loanRepaidByOtherSHGCash}
                  onChange={(e) => setloanRepaidByOtherSHGCash(e.target.value)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  disabled
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <input
          // onClick={() => NextBackBtn(4)}
          onClick={() => incomeNextTab()}
          type="button"
          name="submit"
          value="Next"
          className="btn sub_form btn_continue Save float-right"
        />
        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => NextBackBtn(2)}
        >
          Previous
        </button>
      </div>
    </div>
  );
};

export default IncomeTab;
