import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Spinner from "../layout/Spinner";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getStates, getDistrict, getBlockDetails } from "../../actions/area";
import {
  getBatchOfDistrict,
  getAllBatchesDropdown,
  getASSAIncomeAndExpenseReport,
} from "../../actions/shg";
import { CSVLink } from "react-csv";

const AssaIncomeExpenseReport = ({
  auth: { isAuthenticated, user, users },
  shg: { batchOfDistrict, ASSAincomeandexpensereport },
  area: { statesData, activeDistrict, getBlock },
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getBlockDetails,
  getAllBatchesDropdown,
  getASSAIncomeAndExpenseReport,
}) => {
  useEffect(() => {
    getStates();
  }, [getStates]);

  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);

  useEffect(() => {
    getASSAIncomeAndExpenseReport();
  }, [getASSAIncomeAndExpenseReport]);

  ///////////////////Permission////////////////
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );
  ///////////State Dropdown/////////////
  const allstates = [];
  statesData.map((state) =>
    allstates.push({
      sId: state._id,
      label: state.stateName,
      value: state.stateName,
    })
  );
  const [state, getStateData] = useState();
  const [stateId, setStateID] = useState();
  const [stateName, setStateName] = useState();

  const onStateChange = (e) => {
    var stateId = "";
    var stateName = "";
    getStateData(e);

    stateId = e.sId;
    stateName = e.value;

    setStateID(stateId);
    setStateName(stateName);
    let stateVal = {
      // userInfo: user,
      stateInfo: stateId,
    };
    getDistrict(stateVal);
    getdistrictData("");
    getBatchOfDistrict("");
    getbatchesData("");
    setBlock("");
  };
  /////////////District Dropdown///////////////
  const alldistrict = [];

  activeDistrict.map((district) =>
    alldistrict.push({
      districtId: district._id,
      label: district.districtName,
      value: district.districtName,
    })
  );
  const [district, getdistrictData] = useState();
  const [districtId, setdistrictID] = useState();
  const [districtName, setdistrictName] = useState();
  const ondistrictChange = (e) => {
    var districtId = "";
    var districtName = "";
    getdistrictData(e);
    getBlockDetails({ districtIdVal: e.districtId });
    districtId = e.districtId;
    districtName = e.value;

    setdistrictID(districtId);
    setdistrictName(districtName);
    var filterData = {
      districtId: districtId,
    };
    getBatchOfDistrict(filterData);
    getbatchesData("");
    setBlock("");
  };
  //////////////Block DropDown/////////////
  const allBlocks = [];
  getBlock &&
    getBlock.map((block) =>
      allBlocks.push({
        label: block.blockName,
        value: block._id,
      })
    );

  const [block, setBlock] = useState();
  const onBlockChange = (e) => {
    setBlock(e);
    getBatchOfDistrict({
      blockId: e.value,
    });
    getbatchesData("");

    getASSAIncomeAndExpenseReport({ blockId: e.value });
  };

  ////////////////Batch DropDown//////////////////
  const allbatchesList = [];
  batchOfDistrict.map((batches) =>
    allbatchesList.push({
      batchesId: batches._id,
      label: batches.batchName,
      value: batches.batchName,
    })
  );
  const [batches, getbatchesData] = useState();
  const [batchesId, setbatchesId] = useState();
  const [batchesName, setbatchesName] = useState();

  const onBatchChange = (e) => {
    var batchesId = "";
    var batchesName = "";
    getbatchesData();
    batchesId = e.batchesId;
    batchesName = e.value;
    setbatchesId(batchesId);

    const changeData = {
      batchIdVal: e.batchesId,
    };

    getASSAIncomeAndExpenseReport(changeData);
  };
  /////////////Date DropDown////////////////////
  const [batchs, getbatchsData] = useState();
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();
  let changeData = "";

  // const onBatchesChange = (e) => {
  //   getbatchsData(e);
  //   setbatchId(e.batchId);
  //   setbatchName(e.batchName);
  //   changeData = {
  //     batchIdVal: e.batchId,
  //   };

  //   getASSAIncomeAndExpenseReport({ changeData });
  // };

  let fiscalyearstart = "",
    fiscalyearend = "";
  let today = new Date();

  if (today.getMonth() + 1 <= 3) {
    fiscalyearstart = today.getFullYear() - 1 + "-04-01";
    fiscalyearend = today.getFullYear() + "-03-31";
  } else {
    fiscalyearstart = today.getFullYear() + "-04-01";
    fiscalyearend = today.getFullYear() + 1 + "-03-31";
  }
  const [fromSelectedDate, setBatchDate] = useState(fiscalyearstart);
  const [toSelectedDate, setBatchDates] = useState(fiscalyearend);
  //srtart Date
  const onDateChange = (e) => {
    setBatchDate(e.target.value);
    changeData = {
      batchIdVal: batchId || batchesId,
      fromDate: e.target.value,
      toDate: toSelectedDate,
    };
    getASSAIncomeAndExpenseReport(changeData);
  };

  //End Date
  const onDateChanges = (e) => {
    setBatchDates(e.target.value);
    changeData = {
      batchIdVal: batchesId || batchesId,
      fromDate: fromSelectedDate,
      toDate: e.target.value,
    };
    getASSAIncomeAndExpenseReport(changeData);
  };
  /////////////Refresh Button/////////////////
  const onClickReset = () => {
    getASSAIncomeAndExpenseReport();
    getbatchsData("");
    getbatchesData("");
    getStates("");
    getDistrict("");
    getStateData("");
    getdistrictData("");
    getBatchOfDistrict("");
    setBatchDate(fiscalyearstart);
    setBatchDates(fiscalyearend);
    setBlock("");
  };
  /////////Income Total/////////////
  const incomeTotal = (incomeTotal) => {
    return (
      Number(incomeTotal.batchInterestPaid) +
      Number(incomeTotal.batchOtherLoanInterestShg) +
      Number(incomeTotal.batchSubAmt) +
      Number(incomeTotal.subsidyReceived) +
      Number(incomeTotal.batchOtherContribution) +
      Number(incomeTotal.batchOtherIncome) +
      Number(incomeTotal.bankInterest) +
      Number(incomeTotal.seedMoney)
    );
  };
  /////////////Expense Total/////////////////
  const expenseTotal = (expenseTotal) => {
    return (
      Number(expenseTotal.batchOtherLoanInterestDonor) +
      Number(expenseTotal.bankCommission) +
      Number(expenseTotal.donationToOutsider) +
      Number(expenseTotal.stationaryExpenses) +
      Number(expenseTotal.otherExpenses) +
      Number(expenseTotal.travellingExpenses) +
      Number(expenseTotal.batchDividendDistributed)
    );
  };
  ////////////////////Excel report//////////////////////

  const csvData = [
    [
      "Interest On Internal Loan (Members)",
      "Interest On Other Loans (Members)",
      "Members Subscription",
      "Bank Subsidy",
      "Donations Received",
      "Other Income",
      "SB Interest",
      "Seed Money",
      "",
      "Interest On OL (SHG)",
      "Bank Charges",
      "Donations Given",
      "Printing and Stationery",
      "Other Expenses",
      "Travel and Misc expenses",
      "Dividend Distributed",
    ],
  ];

  if (ASSAincomeandexpensereport)
    csvData.push([
      ASSAincomeandexpensereport.batchInterestPaid,
      ASSAincomeandexpensereport.batchOtherLoanInterestShg,
      ASSAincomeandexpensereport.batchSubAmt,
      ASSAincomeandexpensereport.subsidyReceived,
      ASSAincomeandexpensereport.batchOtherContribution,
      ASSAincomeandexpensereport.batchOtherIncome,
      ASSAincomeandexpensereport.bankInterest,
      ASSAincomeandexpensereport.seedMoney,
      "",
      //expense
      ASSAincomeandexpensereport.batchOtherLoanInterestDonor,
      ASSAincomeandexpensereport.bankCommission,
      ASSAincomeandexpensereport.donationToOutsider,
      ASSAincomeandexpensereport.stationaryExpenses,
      ASSAincomeandexpensereport.otherExpenses,
      ASSAincomeandexpensereport.travellingExpenses,
      ASSAincomeandexpensereport.batchDividendDistributed,
    ]);

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color">Income & Expenses Report</h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding">
            <>
              <div className="col-lg-3 col-md-5 col-sm-4 col-11 mt-1">
                <Select
                  name="stateName"
                  options={allstates}
                  isSearchable={true}
                  value={state}
                  placeholder="Select State"
                  onChange={(e) => onStateChange(e)}
                />
              </div>
              <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                <Select
                  name="districtName"
                  options={alldistrict}
                  isSearchable={true}
                  value={district}
                  placeholder="Select District"
                  onChange={(e) => ondistrictChange(e)}
                />
              </div>
              {allPermission && allPermission.includes("Blockwise") && (
                <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                  <Select
                    name="blockName"
                    options={allBlocks}
                    isSearchable={true}
                    value={block}
                    placeholder="Select Block"
                    onChange={(e) => onBlockChange(e)}
                  />
                </div>
              )}
              <div className="col-lg-3 col-md-5 col-sm-4 col-3 mt-1">
                <Select
                  name="batchName"
                  options={allbatchesList}
                  isSearchable={true}
                  value={batches}
                  placeholder="Select SHG"
                  onChange={(e) => onBatchChange(e)}
                />
              </div>
            </>
          </div>
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding mt-2">
            <div className="col-lg-2 col-md-3 col-sm-3 col-11 ">
              {/* <label className="label-control">From Date:</label> */}
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="fromDate"
                value={fromSelectedDate}
                onChange={(e) => onDateChange(e)}
                style={{
                  width: "75%",
                }}
              />
            </div>
            <div className="col-lg-2 col-md-5 col-sm-4 col-11 ">
              {/* <label className="label-control">To Date:</label> */}
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="toDate"
                value={toSelectedDate}
                onChange={(e) => onDateChanges(e)}
                style={{
                  width: "75%",
                }}
              />
            </div>
            <div className="col-lg-8 col-md-3 col-sm-4 col-11 py-3">
              <Link to="/all-reports">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <img
                className="img_icon_size log float-right ml-3"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
              <CSVLink data={csvData}>
                <img
                  className="img_icon_size log float-right ml-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="Excel-Export"
                  title="Excel-Export"
                />
              </CSVLink>
            </div>
          </div>

          {ASSAincomeandexpensereport ? (
            <>
              <div className="row col-lg-11 col-md-11 col-sm-11 col-11 "></div>
              <div className="row">
                <div className="col-lg-6 col-md-11 col-sm-11 col-11">
                  <div className=" body-inner no-padding table-responsive ">
                    <h4>
                      <center>
                        <b> INCOME</b>
                      </center>
                    </h4>
                    <table
                      className="table table-bordered table-striped table-hover"
                      id="datatable2"
                    >
                      <thead>
                        <tr>
                          <th>Income</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          <tr>
                            <td>Interest On Internal Loan (Members)</td>
                            <td>
                              {ASSAincomeandexpensereport.batchInterestPaid}
                            </td>
                          </tr>
                          <tr>
                            <td>Interest On Other Loans (Members)</td>
                            <td>
                              {
                                ASSAincomeandexpensereport.batchOtherLoanInterestShg
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Members Subscription</td>
                            <td>{ASSAincomeandexpensereport.batchSubAmt}</td>
                          </tr>
                          <tr>
                            <td>Bank Subsidy</td>
                            <td>
                              {ASSAincomeandexpensereport.subsidyReceived}
                            </td>
                          </tr>
                          <tr>
                            <td>Donations Received</td>
                            <td>
                              {
                                ASSAincomeandexpensereport.batchOtherContribution
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Other Income</td>
                            <td>
                              {ASSAincomeandexpensereport.batchOtherIncome}
                            </td>
                          </tr>
                          <tr>
                            <td>SB Interest</td>
                            <td>{ASSAincomeandexpensereport.bankInterest}</td>
                          </tr>
                          <tr>
                            <td>Seed Money</td>
                            <td>{ASSAincomeandexpensereport.seedMoney}</td>
                          </tr>

                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              <b>{incomeTotal(ASSAincomeandexpensereport)}</b>
                            </td>
                          </tr>
                        </>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-6 col-md-11 col-sm-11 col-11 ">
                  <h4>
                    <center>
                      <b> EXPENSE </b>
                    </center>
                  </h4>

                  <div className=" body-inner no-padding table-responsive ">
                    <table
                      className="table table-bordered table-striped table-hover"
                      id="datatable2"
                    >
                      <thead>
                        <tr>
                          <th width="60%">Expense</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          <tr>
                            <td> Interest On OL (SHG)</td>
                            <td>
                              {
                                ASSAincomeandexpensereport.batchOtherLoanInterestDonor
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>Bank Charges</td>
                            <td>{ASSAincomeandexpensereport.bankCommission}</td>
                          </tr>
                          <tr>
                            <td> Donations Given</td>
                            <td>
                              {ASSAincomeandexpensereport.donationToOutsider}
                            </td>
                          </tr>
                          <tr>
                            <td> Printing and Stationery</td>
                            <td>
                              {ASSAincomeandexpensereport.stationaryExpenses}
                            </td>
                          </tr>
                          <tr>
                            <td> Other Expenses</td>
                            <td>{ASSAincomeandexpensereport.otherExpenses}</td>
                          </tr>
                          <tr>
                            <td>Travel and Misc expenses</td>
                            <td>
                              {ASSAincomeandexpensereport.travellingExpenses}
                            </td>
                          </tr>
                          <tr>
                            <td> Dividend Distributed</td>
                            <td>
                              {
                                ASSAincomeandexpensereport.batchDividendDistributed
                              }
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              <b>{expenseTotal(ASSAincomeandexpensereport)}</b>
                            </td>
                          </tr>
                        </>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {/* <div className="row col-lg-11 col-md-11 col-sm-11 col-11 "></div>
          <div className="row">
            <div className="col-lg-6 col-md-11 col-sm-11 col-11">
              <div className=" body-inner no-padding table-responsive ">
                <h4>
                  <center>
                    <b> INCOME</b>
                  </center>
                </h4>
                <table
                  className="table table-bordered table-striped table-hover"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>Income</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      <tr>
                        <td>Interest On Internal Loan (Members)</td>
                        <td>120000</td>
                      </tr>
                      <tr>
                        <td>Interest On Other Loans (Members)</td>
                        <td>500</td>
                      </tr>
                      <tr>
                        <td>Members Subscription</td>
                        <td>89000</td>
                      </tr>
                      <tr>
                        <td>Bank Subsidy</td>
                        <td>77777</td>
                      </tr>
                      <tr>
                        <td>Donations Received</td>
                        <td>5555</td>
                      </tr>
                      <tr>
                        <td>Other Income</td>
                        <td>5555</td>
                      </tr>
                      <tr>
                        <td>SB Interest</td>
                        <td>5555</td>
                      </tr>
                      <tr>
                        <td>Seed Money</td>
                        <td>5555</td>
                      </tr>

                      <tr>
                        <td>
                          <b>Total</b>
                        </td>
                        <td>
                          <b>5555</b>
                        </td>
                      </tr>
                    </>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6 col-md-11 col-sm-11 col-11 ">
              <h4>
                <center>
                  <b> EXPENSE </b>
                </center>
              </h4>

              <div className=" body-inner no-padding table-responsive ">
                <table
                  className="table table-bordered table-striped table-hover"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th width="60%">Expense</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      <tr>
                        <td> Interest On OL (SHG)</td>
                        <td>8888</td>
                      </tr>
                      <tr>
                        <td>Bank Charges</td>
                        <td>3000</td>
                      </tr>
                      <tr>
                        <td> Donations Given</td>
                        <td>8888</td>
                      </tr>
                      <tr>
                        <td> Printing and Stationery</td>
                        <td>8888</td>
                      </tr>
                      <tr>
                        <td> Other Expenses</td>
                        <td>8888</td>
                      </tr>
                      <tr>
                        <td>Travel and Misc expenses</td>
                        <td>8888</td>
                      </tr>
                      <tr>
                        <td> Dividend Distributed</td>
                        <td>8888</td>
                      </tr>

                      <tr>
                        <td>
                          <b>Total</b>
                        </td>
                        <td>
                          <b>5000</b>
                        </td>
                      </tr>
                    </>
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </section>
      </div>
    </Fragment>
  );
};
AssaIncomeExpenseReport.prototypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  getAllBatchesDropdown: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  shg: state.shg,
});
export default connect(mapStateToProps, {
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getBlockDetails,
  getAllBatchesDropdown,
  getASSAIncomeAndExpenseReport,
})(AssaIncomeExpenseReport);
