import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { connect } from "react-redux";
import {
  getParticularLoanReason,
  AddMemberTransaction,
  AddLoanSanctionedDetails,
  getMemberPrevMeetingData,
  getActiveOtherLoanType,
  getActiveBankData,
  deleteOtherLoan,
  getExistingMemberOLData,
} from "../../actions/shg";
import Spinner from "../layout/Spinner";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const EditmemberMeetingopBalModal = ({
  auth: { isAuthenticated, user, users, loading },
  shg: {
    loanreason,
    prevData,
    activeOtherLoanTypes,
    activeBankData,
    exstMemOLData,
  },
  userData2,
  membersBatch,
  selectedDate,
  onMemberMeetingOPModalChanges,
  getParticularLoanReason,
  AddMemberTransaction,
  AddLoanSanctionedDetails,
  getMemberPrevMeetingData,
  EditLoanSanctionedDetails,
  getSeletedBatchDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  deleteOtherLoan,
  getExistingMemberOLData,
}) => {
  let selectedBatchData = JSON.parse(localStorage.getItem("selBatchData"));
  let memberEditData = JSON.parse(localStorage.getItem("memberEditData"));
  const meetingBatchInfo = {
    mdDate: userData2 && userData2.mdDate,
    mdBatchId: userData2 && userData2.mdBatchId,
    memberId: userData2 && userData2.memberId,
  };
  useEffect(() => {
    getParticularLoanReason();
  }, [getParticularLoanReason]);
  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);
  useEffect(() => {
    getExistingMemberOLData(meetingBatchInfo);
  }, [getExistingMemberOLData]);

  useEffect(() => {
    const reqData = {
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: membersBatch && membersBatch._id,
    };
    getMemberPrevMeetingData(reqData);
  }, [getMemberPrevMeetingData]);

  const [formData, setFormData] = useState({
    savingAmt: 0,
    loanPaid: 0,
    memberName: membersBatch.memberName,
    meetingHeldOnDate: selectedDate,
    batchSavingAmt: 0,
    batchSubscriptionAmt: 0,
    interestPaid: "",
    batchId: selectedBatchData[0] && selectedBatchData.batchId,
    institutionId: membersBatch[0] && membersBatch[0].institutionId,
    otherContribution: "",
    otherIncome: 0,
    memSeedMoney: 0,
    membershipFees: 0,
    loanTakenAmt: 0,
    otherPurpose: "",
    otherloanSanctionedAmount: 0,
    remainingLoan: 0,
    otherRemainingLoan: 0,
    isSubmitted: false,
  });

  const {
    otherloanpurpose,
    otherLoanReasons,
    otherLoanPaid,
    otherLoanInterest,
    meetingHeldOnDate,
    batchId,
    memberId,

    remainingLoan,
    otherRemainingLoan,
    memberName,
    savingAmt,
    batchSavingAmt,
    batchSubscriptionAmt,
    loanPaid,
    interestPaid,
    subscriptionAmt,
    otherContribution,
    otherIncome,
    memSeedMoney,
    membershipFees,
    loanTakenAmt,
    otherPurpose,
    otherloanSanctionedAmount,
    indSubsidyDistributed,
    otherLoanReason,
    institutionId,
  } = formData;

  const [purpose, getloanPurposeData] = useState();
  const [loanReasonId, setloanPurposeID] = useState();
  const [loanPurpose, setloanPurposeName] = useState();

  const [otherloanpurposes, getbankpurposeData] = useState();
  const [bankloanpuroseId, setbankloanpuroseId] = useState();
  const [bankloanPurpose, setbankloanPurpose] = useState();

  const [takeloandate, setloantakenDate] = useState("");
  const [internaltakeloandate, setinternalloantakenDate] = useState("");

  if (memberEditData && !batchSavingAmt) {
    setFormData({
      ...formData,
      memberName: memberEditData && memberEditData.memberName,
      batchSavingAmt: memberEditData && memberEditData.savingAmt,
      batchSubscriptionAmt: memberEditData && memberEditData.subscriptionAmt,
      otherContribution: memberEditData && memberEditData.otherContribution,
      loanTakenAmt: memberEditData && memberEditData.loanTakenAmt,
      loanPaid: memberEditData && memberEditData.loanPaid,
      interestPaid: memberEditData && memberEditData.interestPaid,
      otherPurpose: memberEditData && memberEditData.otherPurpose,
      loanReasonId: memberEditData && memberEditData.loanReasonId,
      otherloanSanctionedAmount:
        memberEditData && memberEditData.otherLoanTakenAmt,
      otherLoanInterest: memberEditData && memberEditData.otherInterestPaid,
      otherLoanPaid: memberEditData && memberEditData.otherLoanPaid,
      otherLoanReasonId: memberEditData && memberEditData.otherLoanReasonId,
      otherLoanReasons: memberEditData && memberEditData.otherLoanOtherPurpose,
      indSubsidyDistributed:
        memberEditData && memberEditData.indSubsidyDistributed,
      membershipFees: memberEditData && memberEditData.membershipFees,
      otherIncome: memberEditData && memberEditData.otherIncome,
      memSeedMoney: memberEditData && memberEditData.memSeedMoney,
      remainingLoan:
        Number(memberEditData && memberEditData.loanTakenAmt) -
        Number(memberEditData && memberEditData.loanPaid),

      otherRemainingLoan:
        Number(memberEditData && memberEditData.otherLoanTakenAmt) -
        Number(memberEditData && memberEditData.otherLoanPaid),
    });
    setloanPurposeID(memberEditData && memberEditData.loanReasonId);
    setloanPurposeName(memberEditData && memberEditData.loanPurpose);

    setbankloanpuroseId(memberEditData && memberEditData.otherLoanReasonId);
    setbankloanPurpose(memberEditData && memberEditData.otherLoanOtherPurpose);

    setloantakenDate(memberEditData && memberEditData.otherLoanTakenDate);
    setinternalloantakenDate(
      memberEditData && memberEditData.internalLoanTakenDate
    );
  }
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //For setting mindate as todays date
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  var todayDateymd = yyyy + "-" + mm + "-" + dd;

  const [showHide, setShowHide] = useState({
    showotherPurposeSection: false,
  });
  const { showotherPurposeSection } = showHide;

  const onLoanPurposeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        loanPurpose: e,
      });
    }
    if (e.value === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
    }
  };

  const allpurpose = [];

  loanreason.map((purpose) =>
    allpurpose.push({
      loanReasonId: purpose._id,
      label: purpose.loanPurpose,
      value: purpose.loanPurpose,
    })
  );
  if (memberEditData && memberEditData.loanReasonId && !purpose) {
    getloanPurposeData(
      allpurpose && memberEditData
        ? allpurpose &&
            allpurpose.filter(
              (x) => x.loanReasonId === memberEditData.loanReasonId
            )[0]
        : ""
    );
    if (memberEditData.loanPurpose === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
    }
  }
  const onPurposeChange = (e) => {
    var loanReasonId = "";
    var loanPurpose = "";
    getloanPurposeData(e);

    loanReasonId = e.loanReasonId;
    loanPurpose = e.value;

    setloanPurposeID(loanReasonId);
    setloanPurposeName(loanPurpose);

    if (e) {
      setFormData({
        ...formData,
        loanPurpose: e,
      });
    }

    if (e.value === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
    }
  };

  const [showHideBank, setbankShowHide] = useState({
    showbankotherPurposeSection: false,
  });
  const { showbankotherPurposeSection } = showHideBank;

  const allbankpurpose = [];

  loanreason.map((otherloanpurposes) =>
    allbankpurpose.push({
      bankloanpuroseId: otherloanpurposes._id,
      label: otherloanpurposes.loanPurpose,
      value: otherloanpurposes.loanPurpose,
    })
  );

  const onloantakenDateChange = (e) => {
    setloantakenDate(e.target.value);
  };

  const onloantakenDate = (e) => {
    setinternalloantakenDate(e.target.value);
  };
  if (
    memberEditData &&
    memberEditData.otherLoanReasonId &&
    !otherloanpurposes
  ) {
    getbankpurposeData(
      allbankpurpose && memberEditData
        ? allbankpurpose &&
            allbankpurpose.filter(
              (x) => x.bankloanpuroseId === memberEditData.otherLoanReasonId
            )[0]
        : ""
    );
    if (memberEditData.otherloanpurpose === "Other") {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: true,
      });
    } else {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: false,
      });
    }
  }

  const onBankLoanPurposeChange = (e) => {
    var bankloanpuroseId = "";
    var bankloanPurpose = "";
    getbankpurposeData(e);

    bankloanpuroseId = e.bankloanpuroseId;
    bankloanPurpose = e.value;

    setbankloanpuroseId(bankloanpuroseId);
    setbankloanPurpose(bankloanPurpose);

    if (e) {
      setFormData({
        ...formData,
        bankloanPurpose: e,
      });
    }

    if (e.value === "Other") {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: true,
      });
    } else {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: false,
      });
    }
  };

  //For setting mindate as todays date

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);

  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };
  // code for next previous tabing ends

  const [error, setError] = useState({
    otherLoanTypeIdChecker: false,
    otherLoanTypeIdErrorStyle: {},
    bankIdChecker: false,
    bankIdErrorStyle: {},
  });

  const [otherLoanType, getOtherLoanTypeData] = useState("");
  const [otherLoanTypeId, setOtherLoanTypeId] = useState("");
  const [otherLoanTypeName, setOtherLoanTypeName] = useState("");

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  const onotherLoanTypeChange = (e) => {
    setError({
      ...error,
      otherLoanTypeIdChecker: true,
      otherLoanTypeIdErrorStyle: { color: "#000" },
    });
    getOtherLoanTypeData(e);
    setOtherLoanTypeId(e.otherLoanTypeId ? e.otherLoanTypeId : "");
    setOtherLoanTypeName(e.value ? e.value : "");
  };

  //OLR GRID START==============================================================================
  const [AddedDetailsOLR, AddDetailsOLR] = useState([]);
  const onAddOLR = (e) => {
    e.preventDefault();
    if (otherLoanType) {
      const listOLR = AddedDetailsOLR.filter(
        (AddDetails) =>
          AddDetails.otherLoanTypeId === otherLoanType.otherLoanTypeId
      );
      if (listOLR.length === 0) {
        const addData = {
          otherLoanType: otherLoanType.value,
          otherLoanTypeId: otherLoanType.otherLoanTypeId,
          otherLoanPaid: otherLoanPaid,
          otherInterestPaid: otherLoanInterest,
          otherLoanTakenAmt: otherloanSanctionedAmount,
          otherLoanReasonId: otherloanpurposes
            ? otherloanpurposes.bankloanpuroseId
            : null,
          otherloanpurpose: otherloanpurposes ? otherloanpurposes.value : "",
          otherLoanOtherPurpose: otherLoanReasons,
          indSubsidyDistributed: indSubsidyDistributed,
        };
        setFormData({
          ...formData,
          otherLoanType: "",
          otherLoanTypeId: "",
          otherLoanPaid: "",
          otherLoanInterest: "",
          otherloanSanctionedAmount: "",
          allbankpurpose: "",
          otherLoanReasons: "",
          indSubsidyDistributed: "",
        });
        let temp = [];
        temp.push(...AddedDetailsOLR, addData);
        AddDetailsOLR(temp);
        getOtherLoanTypeData("");
        getbankpurposeData("");
      }
    }
    return true;
  };
  const onDelete = (memberData) => {
    deleteOtherLoan({
      otherLoanId: memberData._id,
      transactionId: memberEditData._id,
      mdDate: userData2 && userData2.mdDate,
      mdBatchId: userData2 && userData2.mdBatchId,
      memberId: userData2 && userData2.memberId,
    });
  };

  const onAddOLRSingle = (e) => {
    const addData = {
      otherLoanType: activeOtherLoan[0] ? activeOtherLoan[0].value : "",
      otherLoanTypeId: activeOtherLoan[0]
        ? activeOtherLoan[0].otherLoanTypeId
        : null,
      otherLoanPaid: otherLoanPaid,
      otherInterestPaid: otherLoanInterest,
      otherLoanTakenAmt: otherloanSanctionedAmount,
      otherLoanReasonId: otherloanpurposes
        ? otherloanpurposes.bankloanpuroseId
        : null,
      otherloanpurpose: otherloanpurposes ? otherloanpurposes.value : "",
      otherLoanOtherPurpose: otherLoanReasons,
      indSubsidyDistributed: indSubsidyDistributed,
    };
    let temp = [];
    temp.push(addData);
    AddDetailsOLR(temp);
    return temp;
  };

  const onRemoveChangeOLR = (otherLoanTypeId) => {
    const removeList = AddedDetailsOLR.filter(
      (AddDetails) => AddDetails.otherLoanTypeId !== otherLoanTypeId
    );
    AddDetailsOLR(removeList);
  };

  //OLR GRID END================================================================================

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const onUpdate = (e) => {
    e.preventDefault();
    var dateVal = new Date(meetingHeldOnDate);
    const finalData = {
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: membersBatch && membersBatch._id,
      memberName: membersBatch && membersBatch.memberName,
      savingAmt: batchSavingAmt && batchSavingAmt ? batchSavingAmt : 0,
      subscriptionAmt:
        batchSubscriptionAmt && batchSubscriptionAmt ? batchSubscriptionAmt : 0,
      otherContribution:
        otherContribution && otherContribution ? otherContribution : 0,
      meetingMonth: dateVal.getMonth() + 1,
      meetingYear: dateVal.getFullYear(),
      meetingHeldOnDate:
        meetingHeldOnDate && meetingHeldOnDate ? meetingHeldOnDate : "",

      loanPaid: Number(loanTakenAmt) - Number(remainingLoan),
      interestPaid: interestPaid && interestPaid ? interestPaid : 0,
      loanTakenAmt: loanTakenAmt && loanTakenAmt ? loanTakenAmt : 0,
      loanReasonId: loanReasonId ? loanReasonId : null,
      loanPurpose: loanPurpose && loanPurpose ? loanPurpose : "",
      otherPurpose: otherPurpose && otherPurpose ? otherPurpose : "",

      otherLoanPaid:
        Number(otherloanSanctionedAmount) - Number(otherRemainingLoan),
      otherInterestPaid:
        otherLoanInterest && otherLoanInterest ? otherLoanInterest : 0,
      otherLoanTakenAmt:
        otherloanSanctionedAmount && otherloanSanctionedAmount
          ? otherloanSanctionedAmount
          : 0,
      otherLoanReasonId: bankloanpuroseId ? bankloanpuroseId : null,
      otherloanpurpose:
        otherloanpurposes && otherloanpurposes.value
          ? otherloanpurposes.value
          : "",
      otherLoanOtherPurpose:
        otherLoanReasons && otherLoanReasons ? otherLoanReasons : "",

      mtEnteredById: user && user._id ? user._id : "",
      mtEnteredByName: user && user.userName ? user.userName : "",
      institutionId:
        selectedBatchData[0] && selectedBatchData[0].institutionId
          ? selectedBatchData[0].institutionId
          : "",
    };
    AddMemberTransaction(finalData);

    if (
      (loanTakenAmt && loanTakenAmt > 0) ||
      (otherloanSanctionedAmount && otherloanSanctionedAmount > 0)
    ) {
      const loanSanctionedData = {
        batchId:
          selectedBatchData[0] && selectedBatchData[0]._id
            ? selectedBatchData[0]._id
            : "",
        memberId: membersBatch && membersBatch._id ? membersBatch._id : "",
        memberName:
          membersBatch && membersBatch.memberName
            ? membersBatch.memberName
            : "",
        batchMeetingMonth: dateVal.getMonth() + 1,
        batchMeetingYear: dateVal.getFullYear(),
        meetingHeldOnDate:
          meetingHeldOnDate && meetingHeldOnDate ? meetingHeldOnDate : "",

        loanSanctionedAmt: loanTakenAmt && loanTakenAmt ? loanTakenAmt : 0,
        loanReasonId: loanReasonId ? loanReasonId : null,
        loanPurpose: loanPurpose && loanPurpose ? loanPurpose : "",
        otherPurpose: otherPurpose && otherPurpose ? otherPurpose : "",

        otherLoanSanctionedAmt:
          otherloanSanctionedAmount && otherloanSanctionedAmount
            ? otherloanSanctionedAmount
            : 0,
        otherLoanReasonId: bankloanpuroseId ? bankloanpuroseId : null,
        otherLoanReason:
          otherloanpurposes && otherloanpurposes.value
            ? otherloanpurposes.value
            : "",
        otherloanotherpurpose:
          otherLoanReasons && otherLoanReasons ? otherLoanReasons : "",

        internalLoanTakenDate: internaltakeloandate ? internaltakeloandate : "",
        otherLoanTakenDate: takeloandate ? takeloandate : "",

        loanSanctionedEnteredById: user && user._id ? user._id : "",
        loanSanctionedEnteredByName: user && user.userName ? user.userName : "",
        institutionId:
          selectedBatchData[0] && selectedBatchData[0].institutionId
            ? selectedBatchData[0].institutionId
            : "",
      };
      AddLoanSanctionedDetails(loanSanctionedData);
    }
    onMemberMeetingOPModalChanges(true);
  };

  var ED = meetingHeldOnDate.split(/\D/g);
  var mdDate = [ED[2], ED[1], ED[0]].join("-");
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <Tabs
        selectedIndex={tabIndex}
        //   onSelect={(index) => setTabIndex(index)}
      >
        <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
          <TabList>
            <Tab tabfor="0">Basic</Tab>
            <Tab tabfor="1">Other Loan</Tab>
          </TabList>
        </div>

        <TabPanel tabId="0">
          <form onSubmit={(e) => NextBackBtn(1)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">Meeting Date : {mdDate}</label>
              </div>
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">
                  Member Name : {memberName}{" "}
                </label>
              </div>
              <div className="row col-lg-12 card-new py-2 ">
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Savings Amount* :{" "}
                  </label>
                  <input
                    type="number"
                    name="batchSavingAmt"
                    value={batchSavingAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                    required
                  />
                </div>
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Subscription Amount* :
                  </label>

                  <input
                    type="number"
                    name="batchSubscriptionAmt"
                    value={batchSubscriptionAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                    required
                  />
                </div>
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Other Contribution Amount :
                  </label>
                  <input
                    type="number"
                    name="otherContribution"
                    value={otherContribution}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">Other Income :</label>
                  <input
                    type="number"
                    name="otherIncome"
                    value={otherIncome}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">Seed Money :</label>
                  <input
                    type="number"
                    name="memSeedMoney"
                    value={memSeedMoney}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Total Interest Paid :</label>
                  <input
                    type="number"
                    name="interestPaid"
                    value={interestPaid}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Loan Taken Amount :
                  </label>
                  <input
                    type="number"
                    name="loanTakenAmt"
                    value={loanTakenAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Loan Taken Date :</label>
                  <br />
                  <input
                    type="date"
                    placeholder="dd/mm/yyyy"
                    className="form-control cpp-input datevalidation"
                    name="internaltakeloandate"
                    value={internaltakeloandate}
                    onChange={(e) => onloantakenDate(e)}
                    style={{
                      width: "75%",
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Purpose :</label>
                  <Select
                    name="loanPurpose"
                    options={allpurpose}
                    isSearchable={true}
                    value={purpose}
                    placeholder="Select Purpose"
                    onChange={(e) => onPurposeChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
                {showotherPurposeSection && (
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">Purpose Reason :</label>
                    <input
                      type="text"
                      name="otherPurpose"
                      value={otherPurpose}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                )}
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Remaining Loan : </label>
                  <input
                    type="number"
                    name="remainingLoan"
                    value={remainingLoan}
                    className="form-control"
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">Membership Fees :</label>
                  <input
                    type="number"
                    name="membershipFees"
                    value={membershipFees}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Loan Paid:{" "}
                    {Number(loanTakenAmt) - Number(remainingLoan)}
                  </label>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <label className="label-control colorRed">
                    * Indicates mandatory fields, Please fill mandatory fields
                    before Submit
                  </label>
                </div>
                <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                  <input
                    type="submit"
                    name="submit"
                    value="Next"
                    className="btn sub_form btn_continue Save float-right"
                  />
                </div>
              </div>
            </div>
          </form>
        </TabPanel>
        <TabPanel tabId="1">
          {activeOtherLoan.length > 1 ? (
            <>
              {/* <form onSubmit={(e) => onSubmit(e)}> */}
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Meeting Date : {mdDate}
                  </label>
                </div>

                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Member Name :{memberName}{" "}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                  <label className="label-control">Other Loan Types :</label>
                  <Select
                    name="otherLoanTypes"
                    options={activeOtherLoan}
                    isSearchable={true}
                    value={otherLoanType}
                    placeholder="Select Mode"
                    onChange={(e) => onotherLoanTypeChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Other Loan Taken Amount :
                  </label>
                  <input
                    type="number"
                    name="otherloanSanctionedAmount"
                    value={otherloanSanctionedAmount}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Other Loan Interest Paid :
                  </label>
                  <input
                    type="number"
                    name="otherLoanInterest"
                    value={otherLoanInterest}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Other Loan Taken Date :
                  </label>
                  <br />
                  <input
                    type="date"
                    placeholder="dd/mm/yyyy"
                    className="form-control cpp-input datevalidation"
                    name="takeloandate"
                    value={takeloandate}
                    onChange={(e) => onloantakenDateChange(e)}
                    style={{
                      width: "75%",
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Loan Purpose :</label>
                  <Select
                    name="bankloanPurpose"
                    options={allbankpurpose}
                    isSearchable={true}
                    value={otherloanpurposes}
                    placeholder="Select Purpose"
                    onChange={(e) => onBankLoanPurposeChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>

                {showbankotherPurposeSection && (
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      Other Loan Purpose :
                    </label>
                    <input
                      type="text"
                      name="otherLoanReasons"
                      value={otherLoanReasons}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                )}
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Other Remaining Loan:</label>
                  <input
                    type="text"
                    name="otherRemainingLoan"
                    value={otherRemainingLoan}
                    className="form-control"
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Subsidy Distributed :</label>
                  <input
                    type="number"
                    name="indSubsidyDistributed"
                    value={indSubsidyDistributed}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Other Loan Paid :{" "}
                    {(
                      Number(otherloanSanctionedAmount) -
                      Number(otherRemainingLoan)
                    ).toFixed(2)}
                  </label>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <button
                    onClick={(e) => onAddOLR(e)}
                    className="btn sub_form btn_continue blackbrd Save float-right"
                  >
                    ADD
                  </button>
                </div>
              </div>
              <div className=" body-inner no-padding  table-responsive">
                <table
                  className="tabllll table table-bordered table-striped table-hover mt-2"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>OL Type</th>
                      <th>OL Paid</th>
                      <th>OL Interest</th>
                      <th>OL Sanctioned</th>
                      <th>OL Purpose</th>
                      <th>Subsidy Distributed</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exstMemOLData &&
                      exstMemOLData.otherLoan &&
                      exstMemOLData.otherLoan.map((memberData, idx) => {
                        if (memberData.otherLoanTypeId) {
                          return (
                            <tr key={idx}>
                              <td>{memberData.otherLoanType}</td>
                              <td>{memberData.otherLoanPaid}</td>
                              <td>{memberData.otherInterestPaid}</td>
                              <td>{memberData.otherLoanTakenAmt}</td>
                              <td>{memberData.otherloanpurpose}</td>
                              <td>{memberData.indSubsidyDistributed}</td>

                              <td>
                                <img
                                  className="img_icon_size log"
                                  onClick={() => onDelete(memberData, idx)}
                                  src={require("../../static/images/delete.png")}
                                  alt="Deactivate"
                                  title="Deactivate"
                                />{" "}
                              </td>
                            </tr>
                          );
                        }
                      })}
                    {AddedDetailsOLR &&
                      AddedDetailsOLR.map((AddDetail, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{AddDetail.otherLoanType}</td>
                            <td>{AddDetail.otherLoanPaid}</td>
                            <td>{AddDetail.otherInterestPaid}</td>
                            <td>{AddDetail.otherLoanTakenAmt}</td>
                            <td>
                              {AddDetail.otherloanpurpose}{" "}
                              {AddDetail.otherLoanOtherPurpose
                                ? AddDetail.otherLoanOtherPurpose
                                : ""}
                            </td>
                            <td>{AddDetail.indSubsidyDistributed}</td>
                            <td>
                              <img
                                className="img_icon_size log"
                                onClick={() =>
                                  onRemoveChangeOLR(AddDetail.otherLoanTypeId)
                                }
                                src={require("../../static/images/close-buttonRed.png")}
                                alt="Remove"
                                title="Remove"
                              />
                            </td>
                            *{" "}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Other Loan Taken Amount :
                  </label>
                  <input
                    type="number"
                    name="otherloanSanctionedAmount"
                    value={otherloanSanctionedAmount}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Other Loan Interest Paid :
                  </label>
                  <input
                    type="number"
                    name="otherLoanInterest"
                    value={otherLoanInterest}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Other Loan Taken Date :
                  </label>
                  <br />
                  <input
                    type="date"
                    placeholder="dd/mm/yyyy"
                    className="form-control cpp-input datevalidation"
                    name="takeloandate"
                    value={takeloandate}
                    onChange={(e) => onloantakenDateChange(e)}
                    style={{
                      width: "75%",
                    }}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Loan Purpose :</label>
                  <Select
                    name="bankloanPurpose"
                    options={allbankpurpose}
                    isSearchable={true}
                    value={otherloanpurposes}
                    placeholder="Select Purpose"
                    onChange={(e) => onBankLoanPurposeChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
                {showbankotherPurposeSection && (
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      Other Loan Purpose :
                    </label>
                    <input
                      type="text"
                      name="otherLoanReasons"
                      value={otherLoanReasons}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                )}
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Other Remaining Loan:</label>
                  <input
                    type="text"
                    name="otherRemainingLoan"
                    value={otherRemainingLoan}
                    className="form-control"
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Subsidy Distributed :</label>
                  <input
                    type="number"
                    name="indSubsidyDistributed"
                    value={indSubsidyDistributed}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Other Loan Paid :{" "}
                    {(
                      Number(otherloanSanctionedAmount) -
                      Number(otherRemainingLoan)
                    ).toFixed(2)}
                  </label>
                </div>
              </div>
            </>
          )}
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>

            <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
              {loading ? (
                <button
                  className="btn sub_form btn_continue blackbrd Save float-right"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <input
                  type="submit"
                  name="Submit"
                  value="Submit"
                  onClick={(e) => onUpdate(e)}
                  className="btn sub_form btn_continue Save float-right"
                />
              )}
              <button
                className="btn sub_form btn_continue Save float-right"
                onClick={() => NextBackBtn(0)}
              >
                Previous
              </button>
            </div>
          </div>
          {/* </form> */}
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

EditmemberMeetingopBalModal.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getParticularLoanReason: PropTypes.func.isRequired,
  AddMemberTransaction: PropTypes.func.isRequired,
  AddLoanSanctionedDetails: PropTypes.func.isRequired,
  getMemberPrevMeetingData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getParticularLoanReason,
  AddMemberTransaction,
  AddLoanSanctionedDetails,
  getMemberPrevMeetingData,
})(EditmemberMeetingopBalModal);
