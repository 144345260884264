import React, { Fragment, useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { forgotPwd, removeError, otpLogin } from "../../actions/auth";

const ForgotPwd = ({
  forgotPwd,
  isAuthenticated,
  otpAuthenticated,
  errorResponse,
  successResponse,
  removeError,
  loading,
  goBackToLogin,
  otpLogin,
}) => {
  useEffect(() => {
    removeError();
  }, [removeError]);

  let modalTitle = { marginTop: "-30px", marginBottom: "55px" };

  const [formData, setFormData] = useState({
    userName: "",
    otpVal: "",
    isSubmitted: false,
  });

  const { userName, otpVal } = formData;
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // if (checkErrors(formData)) {
    forgotPwd(userName);
    // }
    setFormData({ ...formData, submitted: true });
  };

  const onLoginSubmit = async (e) => {
    e.preventDefault();
    otpLogin(userName, otpVal);
    setFormData({ ...formData, submitted: true });
  };

  if (otpAuthenticated) {
    return <Redirect to="/route-driver" />;
  }

  return successResponse ? (
    <Fragment>
      <div className="forgotPwdModal col-md-12 col-lg-12 col-sm-12 col-12">
        <div className="modal-header">
          <h2 className="modal-title " id="myModalLabel">
            OTP LOGIN
          </h2>
        </div>
        {successResponse && <p>{successResponse}</p>}
        <form onSubmit={(e) => onLoginSubmit(e)}>
          <div className="form-group ">
            <input
              type="text"
              name="userName"
              value={userName}
              className="form-control form_contct"
              onChange={(e) => onInputChange(e)}
              autoComplete="flase"
            />

            <label className="pop_up">
              <span className="label-content">User*</span>
            </label>

            <input
              type="text"
              name="otpVal"
              value={otpVal}
              className="form-control form_contct"
              onChange={(e) => onInputChange(e)}
              autoComplete="flase"
            />

            <label className="pop_up">
              <span className="label-content">OTP*</span>
            </label>
          </div>

          <div className="col-md-12 col-sm-12 col-lg-12 col-12 text-center">
            <button className="btn contact_reg">SUBMIT</button>
          </div>
        </form>
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="col-md-12 col-lg-12 col-sm-12 col-12 py-5">
        <div className="modal-header">
          {loading ? (
            <h2 className="modal-title " id="myModalLabel" style={modalTitle}>
              Please Wait...
            </h2>
          ) : (
            <h2 className="modal-title " id="myModalLabel" style={modalTitle}>
              FORGOT PASSWORD?
            </h2>
          )}
        </div>
        {errorResponse && <p style={{ color: "red" }}>{errorResponse}</p>}
        {/* <!-- form --> */}
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group form_top">
            <input
              type="text"
              name="userName"
              value={userName}
              className="form-control form_contct"
              onChange={(e) => onInputChange(e)}
              autoComplete="flase"
            />

            <label className="pop_up">
              <span className="label-content">User*</span>
            </label>
          </div>

          <div className="col-md-12 col-sm-12 col-lg-12 col-12 text-center">
            {loading ? (
              <button className="btn contact_reg" disabled>
                Loading...
              </button>
            ) : (
              <button className="btn contact_reg">Get OTP</button>
            )}
          </div>
        </form>
      </div>
      <div
        className="col-md-12 col-sm-12 col-lg-12 col-12 text-center"
        // style={{ marginBottom: "100px" }}
      >
        <p className="create">
          <Link to="#" onClick={() => goBackToLogin(false)}>
            Back to LOGIN
          </Link>
        </p>
      </div>
    </Fragment>
  );
};

ForgotPwd.propTypes = {
  forgotPwd: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  otpAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
  errorResponse: PropTypes.string,
  successResponse: PropTypes.string,
  removeError: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  otpAuthenticated: state.auth.otpAuthenticated,
  loading: state.auth.loading,
  errorResponse: state.auth.errorResponse,
  successResponse: state.auth.successResponse,
  user: state.auth.user,
});

export default connect(mapStateToProps, { forgotPwd, removeError, otpLogin })(
  ForgotPwd
);
