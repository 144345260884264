import React from "react";
import { XLg } from "react-bootstrap-icons";

export default function Popup({ setPopup, base64Data, url, filenameVal }) {
  const filename = filenameVal || "Meeting Document.pdf";
  function handleDownload() {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <div
      onClick={() => setPopup(false)}
      style={{
        position: "fixed",
        background: "rgba(0,0,0,0.6)",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          position: "relative",
          background: "white",
          width: "50em",
          padding: "40px 10px 20px 10px",
          animation: "dropTop 0.3s linear",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() => setPopup(false)}
        >
          <XLg />
        </div>

        {/* With toolbar: */}
        {/* <iframe type="application/pdf" style={{height: "50em", width: "100%"}} src={base64Data} title="base64"></iframe> */}
        {/* <object width="400" height="500" type="application/pdf" data="/my_pdf.pdf?#zoom=85&scrollbar=0&toolbar=0&navpanes=0"> */}
        <object
          width="100%"
          height="500"
          type="application/pdf"
          data={`data:application/pdf;base64,${base64Data}`}
        >
          <p>
            Your browser cannot display the PDF. Please download it from{" "}
            <button
              onClick={handleDownload}
              style={{
                background: "none",
                border: "none",
                fontSize: "1rem",
                color: "blue",
                fontStyle: "italic",
                padding: "0",
              }}
            >
              here
            </button>{" "}
          </p>
        </object>

        {/* Without toolbar: */}
        {/* <iframe type="application/pdf" style={{height: "50em", width: "100%"}} src={`${base64Data}#toolbar=0`} title="base64"></iframe> */}
      </div>
    </div>
  );
}
