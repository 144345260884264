import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { connect } from "react-redux";
import {
  getParticularLoanReason,
  editMemberMeetingTransaction,
  editOtherLoanMemberMeetingTransaction,
  AddLoanSanctionedDetails,
  getMemberPrevMeetingData,
  EditMemberMeetingLoanDetails,
  getSeletedBatchDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  deleteOtherLoan,
  getExistingMemberOLData,
  getExistingMemberLoanData,
  addNewOtherLoanDetails,
  getSanctionedData,
  editOLTypeCount,
} from "../../actions/shg";
import Spinner from "../layout/Spinner";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const EditMembermeetingDetailModal = ({
  auth: { isAuthenticated, user, users, loading },
  shg: {
    loanreason,
    activeOtherLoanTypes,
    isloansanctioned,
    exstMemOLData,
    prevData,
    activeBankData,
  },
  membersBatch,
  selectedDate,
  onMemberMeetingModalChanges,
  userData2,
  getSanctionedData,
  getParticularLoanReason,
  editMemberMeetingTransaction,
  editOtherLoanMemberMeetingTransaction,
  getMemberPrevMeetingData,
  getActiveOtherLoanType,
  deleteOtherLoan,
  getExistingMemberOLData,
  getExistingMemberLoanData,
  AddLoanSanctionedDetails,
  EditMemberMeetingLoanDetails,
  getSeletedBatchDetails,
  getActiveBankData,
  addNewOtherLoanDetails,
  editOLTypeCount,
  onDeactiveModalChange,
  onMemberMeetingModalChange,
}) => {
  let selectedBatchData = JSON.parse(localStorage.getItem("selBatchData"));
  let memberEditData = JSON.parse(localStorage.getItem("memberEditData"));
  const [othloanList, setothloanList] = useState([{}]);
  useEffect(() => {
    setothloanList(exstMemOLData.otherLoan || [{}]);
  }, [exstMemOLData]);

  const meetingBatchInfo = {
    mdDate: userData2 && userData2.mdDate,
    mdBatchId: userData2 && userData2.mdBatchId,
    memberId: userData2 && userData2.memberId,
  };

  useEffect(() => {
    getParticularLoanReason();
  }, [getParticularLoanReason]);

  useEffect(() => {
    const reqData = {
      batchId: selectedBatchData[0] && selectedBatchData[0]._id,
      memberId: userData2 && userData2.memberId,
    };
    getMemberPrevMeetingData(reqData);
  }, [getMemberPrevMeetingData]);
  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);

  let { loanCurTot = 0, loanSanctionedAmt = 0, loanStatus = "" } =
    isloansanctioned.filter((ele) => ele.isLoanType == "Internal")[0] || 0;
  let RemainingIntLoan = loanSanctionedAmt - loanCurTot;

  useEffect(() => {
    getSanctionedData({
      batchId: userData2 && userData2.mdBatchId,
      memberId: userData2 && userData2.memberId,
      institutionId: membersBatch && membersBatch.institutionId,
    });
  }, []);

  useEffect(() => {
    getExistingMemberOLData(meetingBatchInfo);
  }, [getExistingMemberOLData]);

  useEffect(() => {
    getExistingMemberLoanData(meetingBatchInfo);
  }, [getExistingMemberLoanData]);

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);

  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };
  // code for next previous tabing ends
  const [formData, setFormData] = useState({
    savingAmt: "",
    meetingHeldOnDate: selectedDate,
    batchSavingAmt: "",
    batchSubscriptionAmt: "",
    batchId: selectedBatchData[0] && selectedBatchData.batchId,
    institutionId: membersBatch && membersBatch.institutionId,
    otherContribution: "",
    loanTakenAmt: "",
    internalNominee: "",
    loanPaid: "",
    interestPaid: "",
    otherPurpose: "",
    loanReasonId: "",
    otherloanSanctionedAmount: "",
    otherLoanInterest: "",
    otherLoanPaid: "",
    otherLoanReasonId: "",
    otherLoanOtherPurpose: "",
    indSubsidyDistributed: "",
    membershipFees: "",
    otherIncome: "",
    memSeedMoney: "",
    memSecurityDepositToShg: "",
    savingWithdrawals: "",
    dividendDistributed: "",
    securityDeposit: "",
    isSubmitted: false,
    batchIdTemp: null,
    loanPayablePrincipal: "",
    loanPayableInterest: "",
    PrincipalPaid: "",
    InterestPaid: "",
    otherloanPayablePrincipalAmt: "",
    otherloanPayableInterstAmt: "",
    otherLoanInterestPaid: "",
    otherLoanPrincipalPaid: "",
    othDuration: "",
    internalDuration: "",
    loanChecker: false,
    otherLoanChecker: false,
    //intPenalty:"",
    // othPenalty:"",
    // otherloanPayablePrincipalAmt:"",
    // otherloanPayableInterstAmt:"",
  });

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const [showHideBank, setbankShowHide] = useState({
    showbankotherPurposeSection: false,
  });

  const [otherLoanType, getOtherLoanTypeData] = useState("");
  const [purpose, getloanPurposeData] = useState();
  const [otherloanpurposes, getbankpurposeData] = useState();
  const { showbankotherPurposeSection } = showHideBank;
  const [loanPurpose, setloanPurposeName] = useState();
  const [bankloanpuroseId, setbankloanpuroseId] = useState();
  const [bankloanPurpose, setbankloanPurpose] = useState();
  const [otherLoanTypeId, setOtherLoanTypeId] = useState("");
  const [otherLoanTypeName, setOtherLoanTypeName] = useState("");
  const [loanReasonId, setloanPurposeID] = useState();

  const {
    otherLoanReasons,
    otherLoanPaid,
    otherLoanInterest,
    meetingHeldOnDate,
    memberName,
    loanPaid,
    interestPaid,
    loanTakenAmt,
    otherloanSanctionedAmount,
    indSubsidyDistributed,
    externalNominee,
    batchIdTemp,
    loanPayablePrincipal,
    loanPayableInterest,
    PrincipalPaid,
    InterestPaid,
    otherloanPayablePrincipalAmt,
    otherloanPayableInterstAmt,
    otherLoanInterestPaid,
    otherLoanPrincipalPaid,
    othDuration,
    internalDuration,
    loanTakenDate,
    loanChecker,
    otherLoanChecker,
    internalNominee,
    otherPurpose,
    subscriptionAmt,
    otherContribution,
    otherLoanReason,
    batchId,
    memberId,
    savingAmt,
    batchSavingAmt,
    batchSubscriptionAmt,
    membershipFees,
    otherIncome,
    memSeedMoney,
    memSecurityDepositToShg,
    savingWithdrawals,
    dividendDistributed,
    securityDeposit,
    institutionId,
    othLoanTakenDate,
    otherloanpurpose,
    //intPenalty,
    //othPenalty,
  } = formData;

  var TotalPayableAmt =
    Number(loanPayableInterest) + Number(loanPayablePrincipal);
  var totalLoanPaid = Number(PrincipalPaid) + Number(InterestPaid);
  var otherloanSanctionedAmt =
    Number(otherloanPayablePrincipalAmt) + Number(otherloanPayableInterstAmt);
  var TotalOtherloanPaid =
    Number(otherLoanInterestPaid) + Number(otherLoanPrincipalPaid);

  const [takeloandate, setloantakenDate] = useState("");
  const [internaltakeloandate, setinternalloantakenDate] = useState("");
  const onloantakenDateChange = (e) => {
    setloantakenDate(e.target.value);
  };

  const onloantakenDate = (e) => {
    setinternalloantakenDate(e.target.value);
  };

  if (memberEditData && !batchIdTemp) {
    let OLTlen = activeOtherLoanTypes && activeOtherLoanTypes.length;
    setFormData({
      ...formData,
      memberName: memberEditData && memberEditData.memberName,
      batchSavingAmt: memberEditData && memberEditData.savingAmt,
      batchSubscriptionAmt: memberEditData && memberEditData.subscriptionAmt,
      otherContribution: memberEditData && memberEditData.otherContribution,
      loanTakenAmt: memberEditData && memberEditData.loanTakenAmt,
      internalNominee: memberEditData && memberEditData.internalNominee,
      loanPaid: memberEditData && memberEditData.loanPaid,
      interestPaid: memberEditData && memberEditData.interestPaid,
      otherPurpose: memberEditData && memberEditData.otherPurpose,
      loanReasonId: memberEditData && memberEditData.loanReasonId,
      otherloanSanctionedAmount:
        OLTlen > 1 ? "" : memberEditData && memberEditData.otherLoanTakenAmt,
      otherLoanInterest:
        OLTlen > 1 ? "" : memberEditData && memberEditData.otherInterestPaid,
      otherLoanPaid:
        OLTlen > 1 ? "" : memberEditData && memberEditData.otherLoanPaid,
      otherLoanReasonId: memberEditData && memberEditData.otherLoanReasonId,
      otherLoanReasons: memberEditData && memberEditData.otherLoanOtherPurpose,
      indSubsidyDistributed:
        OLTlen > 1 ? "" : memberEditData && memberEditData.subsidyDistributed,
      externalNominee: memberEditData && memberEditData.externalNominee,
      membershipFees: memberEditData && memberEditData.membershipFees,
      otherIncome: memberEditData && memberEditData.otherIncome,
      memSeedMoney: memberEditData && memberEditData.memSeedMoney,
      memSecurityDepositToShg:
        memberEditData && memberEditData.memSecurityDepositToShg,
      savingWithdrawals: memberEditData && memberEditData.savingWithdrawals,
      dividendDistributed: memberEditData && memberEditData.dividendDistributed,
      securityDeposit: memberEditData && memberEditData.securityDeposit,
      batchIdTemp: memberEditData && memberEditData.batchId,
    });
    setloanPurposeID(memberEditData && memberEditData.loanReasonId);
    setloanPurposeName(memberEditData && memberEditData.loanPurpose);
    setbankloanpuroseId(memberEditData && memberEditData.otherLoanReasonId);
    setbankloanPurpose(memberEditData && memberEditData.otherLoanOtherPurpose);
  }

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onGridInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [showHide, setShowHide] = useState({
    showotherPurposeSection: false,
  });

  const { showotherPurposeSection } = showHide;
  const onLoanPurposeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        loanPurpose: e,
      });
    }
    if (e.value === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
    }
  };

  const allpurpose = [];
  loanreason.map((purpose) =>
    allpurpose.push({
      loanReasonId: purpose._id,
      label: purpose.loanPurpose,
      value: purpose.loanPurpose,
    })
  );

  if (memberEditData && memberEditData.loanReasonId && !purpose) {
    getloanPurposeData(
      allpurpose && memberEditData
        ? allpurpose &&
            allpurpose.filter(
              (x) => x.loanReasonId === memberEditData.loanReasonId
            )[0]
        : ""
    );
    if (memberEditData.loanPurpose === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
    }
  }

  const onPurposeChange = (e) => {
    getloanPurposeData(e);
    setloanPurposeID(e.loanReasonId ? e.loanReasonId : "");
    setloanPurposeName(e.value ? e.value : "");

    if (e) {
      setFormData({
        ...formData,
        loanPurpose: e,
      });
    }
    if (e.value === "Other") {
      setShowHide({
        ...showHide,
        showotherPurposeSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showotherPurposeSection: false,
      });
      setFormData({
        ...formData,
        otherPurpose: "",
      });
    }
  };

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  const [error, setError] = useState({
    otherLoanTypeIdChecker: false,
    otherLoanTypeIdErrorStyle: {},
    bankIdChecker: false,
    bankIdErrorStyle: {},
  });
  const {
    otherLoanTypeIdChecker,
    otherLoanTypeIdErrorStyle,
    bankIdChecker,
    bankIdErrorStyle,
  } = error;
  //validation for select
  const [errors, setErrors] = useState({
    otherLoanTypesChecker: false,
    otherLoanTypesErrorStyle: {},
  });
  const { otherLoanTypesChecker, otherLoanTypesErrorStyle } = errors;

  const checkError = () => {
    if (!otherLoanTypesChecker) {
      setErrors({
        ...errors,
        otherLoanTypesErrorStyle: { color: "#F00" },
      });
      return false;
    }
    return true;
  };

  const onotherLoanTypeChange = (e) => {
    setErrors({
      ...errors,
      otherLoanTypesChecker: true,
      otherLoanTypesErrorStyle: { color: "#000" },
    });
    //Required Validation starts

    setError({
      ...error,
      otherLoanTypeIdChecker: true,
      otherLoanTypeIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    getOtherLoanTypeData(e);
    setOtherLoanTypeId(e.otherLoanTypeId ? e.otherLoanTypeId : "");
    setOtherLoanTypeName(e.value ? e.value : "");
  };
  const allbankpurpose = [];
  loanreason.map((otherloanpurposes) =>
    allbankpurpose.push({
      bankloanpuroseId: otherloanpurposes._id,
      label: otherloanpurposes.loanPurpose,
      value: otherloanpurposes.loanPurpose,
    })
  );

  if (
    memberEditData &&
    memberEditData.otherLoanReasonId &&
    !otherloanpurposes
  ) {
    getbankpurposeData(
      allbankpurpose && memberEditData
        ? allbankpurpose &&
            allbankpurpose.filter(
              (x) => x.bankloanpuroseId === memberEditData.otherLoanReasonId
            )[0]
        : ""
    );
    if (memberEditData.otherloanpurpose === "Other") {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: true,
      });
    } else {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: false,
      });
    }
  }

  const onBankLoanPurposeChange = (e) => {
    getbankpurposeData(e);
    setbankloanpuroseId(e.bankloanpuroseId ? e.bankloanpuroseId : "");
    setbankloanPurpose(e.value ? e.value : "");

    if (e) {
      setFormData({
        ...formData,
        bankloanPurpose: e,
      });
    }
    if (e.value === "Other") {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: true,
      });
    } else {
      setbankShowHide({
        ...showHideBank,
        showbankotherPurposeSection: false,
      });
      setFormData({
        ...formData,
        otherLoanReasons: "",
      });
    }
  };
  //OLR GRID START============================================================================================
  const [AddedDetailsOLR, AddDetailsOLR] = useState([]);

  const onAddOLR = (e) => {
    if (checkError()) {
      e.preventDefault();
      if (otherLoanType) {
        const listOLR = AddedDetailsOLR.filter(
          (AddDetails) =>
            AddDetails.otherLoanTypeId === otherLoanType.otherLoanTypeId
        );
        const listOLREx =
          exstMemOLData &&
          exstMemOLData.otherLoan.filter(
            (exOLR) => exOLR.otherLoanTypeId === otherLoanType.otherLoanTypeId
          );
        if (listOLR.length === 0 && listOLREx.length === 0) {
          const addData = {
            recordId: memberEditData && memberEditData._id,
            otherLoanType: otherLoanType.value,
            otherLoanTypeId: otherLoanType.otherLoanTypeId,
            otherLoanPaid: otherLoanPaid ? Number(otherLoanPaid).toFixed(2) : 0,
            otherInterestPaid: otherLoanInterest
              ? Number(otherLoanInterest).toFixed(2)
              : 0,
            otherLoanTakenAmt: otherloanSanctionedAmount
              ? Number(otherloanSanctionedAmount).toFixed(2)
              : 0,
            otherLoanReasonId: otherloanpurposes
              ? otherloanpurposes.bankloanpuroseId
              : null,
            otherloanpurpose: otherloanpurposes ? otherloanpurposes.value : "",
            otherLoanOtherPurpose: otherLoanReasons,
            indSubsidyDistributed: indSubsidyDistributed
              ? Number(indSubsidyDistributed).toFixed(2)
              : 0,
            externalNominee: externalNominee,
          };
          setFormData({
            ...formData,
            otherLoanType: "",
            otherLoanTypeId: "",
            otherLoanPaid: "",
            otherLoanInterest: "",
            otherloanSanctionedAmount: "",
            bankloanpuroseId: "",
            otherloanpurposes: "",
            allbankpurpose: "",
            otherLoanReasons: "",
            indSubsidyDistributed: "",
            externalNominee: "",
          });
          let temp = [];
          temp.push(...AddedDetailsOLR, addData);
          AddDetailsOLR(temp);
          getOtherLoanTypeData("");
          getbankpurposeData("");
        }
      }
      return true;
    }
  };
  const [memOtherLoanData, setMemOtherLoanData] = useState(null);

  const onAddOthLoan = () => {
    if (memOtherLoanData != null) {
      let temp = othloanList.filter((ele) => ele._id !== memOtherLoanData._id);
      let TempmemOtherLoanData = memOtherLoanData;
      //  setMemOtherLoanData({... memOtherLoanData,  otherLoanDuration otherloanPayablePrincipalAmt
      //   otherLoanTakenAmt: otherloanSanctionedAmt})
      TempmemOtherLoanData = {
        ...TempmemOtherLoanData,
        otherLoanCheker: otherLoanChecker,
        otherLoanTakenAmt: Number(otherloanSanctionedAmt),
        otherLoanDuration: Number(othDuration),
        otherloanPayablePrincipal: Number(otherloanPayablePrincipalAmt),
        otherloanPayableInterst: Number(otherloanPayableInterstAmt),
        otherInterestPaid: Number(otherLoanInterestPaid),
        otherLoanPaid: Number(otherLoanPrincipalPaid),
        otherTotalPaid: TotalOtherloanPaid,
        status: true,
        loanTakenDate: takeloandate ? takeloandate : meetingHeldOnDate,
        // otherPenalty:Number(othPenalty) || 0,
        //  otherPenalty:Number(othPenalty), 123
      };
      setothloanList([TempmemOtherLoanData, ...temp]);
      setFormData({
        ...formData,
        othDuration: "",
        otherloanPayablePrincipalAmt: "",
        otherloanPayableInterstAmt: "",
        otherLoanInterestPaid: "",
        otherLoanPrincipalPaid: "",
        otherTotalPaid: "",
        otherLoanChecker: false,
        takeloandate: "",
      });
      setOtherLoanTypeDisp("");
      setloantakenDate("");
    } else {
      // alert();
    }
  };
  //=============================================================================

  const onAddOLRSingle = (e) => {
    e.preventDefault();
    const addData = {
      recordId: memberEditData && memberEditData._id,
      otherLoanType: activeOtherLoan[0] ? activeOtherLoan[0].value : "",
      otherLoanTypeId: activeOtherLoan[0]
        ? activeOtherLoan[0].otherLoanTypeId
        : null,
      otherLoanPaid: otherLoanPaid ? Number(otherLoanPaid).toFixed(2) : 0,
      otherInterestPaid: otherLoanInterest
        ? Number(otherLoanInterest).toFixed(2)
        : 0,
      otherLoanTakenAmt: otherloanSanctionedAmount
        ? Number(otherloanSanctionedAmount).toFixed(2)
        : 0,
      otherLoanReasonId: otherloanpurposes
        ? otherloanpurposes.bankloanpuroseId
        : null,
      otherloanpurpose: otherloanpurposes ? otherloanpurposes.value : "",
      otherLoanOtherPurpose: otherLoanReasons,
      indSubsidyDistributed: indSubsidyDistributed
        ? Number(indSubsidyDistributed).toFixed(2)
        : 0,
      externalNominee: externalNominee,
    };
    let temp = [];
    temp.push(addData);
    AddDetailsOLR(temp);
    return temp;
  };

  const onRemoveChangeOLR = (otherLoanTypeId) => {
    const removeList = AddedDetailsOLR.filter(
      (AddDetails) => AddDetails.otherLoanTypeId !== otherLoanTypeId
    );
    AddDetailsOLR(removeList);
  };

  //OLR GRID END================================================================================

  //For setting mindate as todays date

  const onDelete = (memberData) => {
    deleteOtherLoan({
      otherLoanId: memberData._id,
      transactionId: memberEditData._id,
      mdDate: userData2 && userData2.mdDate,
      mdBatchId: userData2 && userData2.mdBatchId,
      memberId: userData2 && userData2.memberId,
    });
  };

  const getData = (memberData, index) => {
    setMemOtherLoanData(memberData);
    setOtherLoanSansAmtDisp(memberData.otherLoanTakenAmt);
    setOtherLoanTypeDisp(memberData.otherLoanType);
    // setOtherLoanPurposeDisp(memberData.otherLoanOtherPurpose);
    //setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //////////////////////////////////////////////////////////////////////////////
  const onUpdate = (e) => {
    var dateVal = new Date(meetingHeldOnDate);
    e.preventDefault();

    let OLRes = [];
    if (activeOtherLoan.length <= 1) {
      OLRes = onAddOLRSingle(e);
    } else {
      OLRes = AddedDetailsOLR;
    }

    let otherLoanSanctionedToMemberSum = 0,
      otherLoanPaidToShgSum = 0,
      batchOtherLoanInterestShgSum = 0,
      indSubsidyDistributedSum = 0;

    if (activeOtherLoan.length > 1) {
      othloanList &&
        othloanList.map((AddDetail, idx) => {
          otherLoanSanctionedToMemberSum += parseFloat(
            AddDetail.otherLoanTakenAmt || 0
          );
          otherLoanPaidToShgSum += parseFloat(AddDetail.otherLoanPaid || 0);
          batchOtherLoanInterestShgSum += parseFloat(
            AddDetail.otherInterestPaid || 0
          );
          indSubsidyDistributedSum += parseFloat(
            AddDetail.indSubsidyDistributed || 0
          );
        });
    }

    OLRes.map((AddDetail, idx) => {
      otherLoanSanctionedToMemberSum += parseFloat(
        AddDetail.otherLoanTakenAmt || 0
      );
      otherLoanPaidToShgSum += parseFloat(AddDetail.otherLoanPaid || 0);
      batchOtherLoanInterestShgSum += parseFloat(
        AddDetail.otherInterestPaid || 0
      );
      indSubsidyDistributedSum += parseFloat(
        AddDetail.indSubsidyDistributed || 0
      );
    });

    //Final data Internal Loan
    const finalDataInt = {
      loanPaid:
        PrincipalPaid && PrincipalPaid
          ? parseFloat(Number(PrincipalPaid).toFixed(2))
          : 0,
      interestPaid:
        InterestPaid && InterestPaid
          ? parseFloat(Number(InterestPaid).toFixed(2))
          : 0,
      totalLoanPaid: totalLoanPaid
        ? parseFloat(Number(totalLoanPaid).toFixed(2))
        : 0,
      loanTakenAmt: TotalPayableAmt
        ? parseFloat(Number(TotalPayableAmt).toFixed(2))
        : 0,
      loanPayablePrincipal: loanPayablePrincipal
        ? parseFloat(Number(loanPayablePrincipal).toFixed(2))
        : 0,
      loanPayableInterest: loanPayableInterest
        ? parseFloat(Number(loanPayableInterest).toFixed(2))
        : 0,
      internalDuration: internalDuration ? internalDuration : 0,
      loanTakenDate: internaltakeloandate
        ? internaltakeloandate
        : meetingHeldOnDate, //meetingHeldOnDate,
      recordId: memberEditData._id,
      memberEditData: memberEditData,
      //internalPenalty:intPenalty ? Number(intPenalty) : 0,

      //loan sanctioned collection
      loanStatus: "Active", //TotalPayableAmt == totalLoanPaid ? "Closed" : "Active",
      batchId:
        selectedBatchData[0] && selectedBatchData[0]._id
          ? selectedBatchData[0]._id
          : "",
      memberId:
        membersBatch && membersBatch.memberId ? membersBatch.memberId : "",
      memberName:
        membersBatch && membersBatch.memberName ? membersBatch.memberName : "",
      batchMeetingMonth: dateVal.getMonth() + 1,
      batchMeetingYear: dateVal.getFullYear(),
      loanSanctionedAmt:
        TotalPayableAmt && TotalPayableAmt
          ? parseFloat(Number(TotalPayableAmt).toFixed(2))
          : 0,
      loanSanctionedEnteredById: user && user._id ? user._id : "",
      loanSanctionedEnteredByName: user && user.userName ? user.userName : "",
      isLoanChecker: loanChecker,
      meetingHeldOnDate:
        meetingHeldOnDate && meetingHeldOnDate ? meetingHeldOnDate : "",
      institutionId: user && user.institutionId ? user.institutionId : "",
      loanRepayment: {
        loanPrincipalPaid: PrincipalPaid
          ? parseFloat(Number(PrincipalPaid).toFixed(2))
          : 0,
        loanInterestPaid: InterestPaid
          ? parseFloat(Number(InterestPaid).toFixed(2))
          : 0,
        totalLoanPaid: totalLoanPaid
          ? parseFloat(Number(totalLoanPaid).toFixed(2))
          : 0,
        paidDate: meetingHeldOnDate ? meetingHeldOnDate : "",
        paidMonth: meetingHeldOnDate && meetingHeldOnDate.slice(5, 7),
        paidYear: meetingHeldOnDate && meetingHeldOnDate.slice(0, 4),
        // penalty:intPenalty ? Number(intPenalty) :0,
      },
    };

    //Final data Other Loan
    const finalDataOth = {
      otherLoanPaid: otherLoanPaidToShgSum
        ? parseFloat(Number(otherLoanPaidToShgSum).toFixed(2))
        : 0, //sum
      otherInterestPaid: batchOtherLoanInterestShgSum
        ? parseFloat(Number(batchOtherLoanInterestShgSum).toFixed(2))
        : 0, //sum
      otherLoanTakenAmt: otherLoanSanctionedToMemberSum
        ? parseFloat(Number(otherLoanSanctionedToMemberSum).toFixed(2))
        : 0, //sum
      recordId: memberEditData._id,
      memberEditData: memberEditData,
      otherLoan: othloanList,

      //loan sanctioned
      batchId:
        selectedBatchData[0] && selectedBatchData[0]._id
          ? selectedBatchData[0]._id
          : "",
      memberId:
        membersBatch && membersBatch.memberId ? membersBatch.memberId : "",
      memberName:
        membersBatch && membersBatch.memberName ? membersBatch.memberName : "",
      batchMeetingMonth: dateVal.getMonth() + 1,
      batchMeetingYear: dateVal.getFullYear(),
      meetingHeldOnDate:
        meetingHeldOnDate && meetingHeldOnDate ? meetingHeldOnDate : "",
      isLoanType: "otherLoan",
      loanSanctionedEnteredById: user && user._id ? user._id : "",
      loanSanctionedEnteredByName: user && user.userName ? user.userName : "",
      institutionId: user && user.institutionId ? user.institutionId : "",
    };
    if (
      othloanList.every(
        (ele) => ele.status === true || othloanList.length === 0
      )
    ) {
      if ((loanTakenAmt > 0 && TotalPayableAmt > 0) || loanChecker === true) {
        editMemberMeetingTransaction(finalDataInt);
      }
      editOtherLoanMemberMeetingTransaction(finalDataOth);
      onMemberMeetingModalChanges(true);
      settouchOthLoan("hidden");
    } else {
      settouchOthLoan("visible");
    }
  };
  var ED = meetingHeldOnDate.split(/\D/g);
  var mdDate = [ED[2], ED[1], ED[0]].join("-");

  const [OtherLoanSansAmtDisp, setOtherLoanSansAmtDisp] = useState("");
  const [OtherLoanTypeDisp, setOtherLoanTypeDisp] = useState("");
  // const [loanChecker, setLoanChecker] = useState(false);
  // const [otherLoanChecker, setOtherLoanChecker] = useState(false);
  const handleOnChange = () => {
    setFormData({
      ...formData,
      loanChecker: !loanChecker,
    });
  };
  const handleOnOtherChange = () => {
    setFormData({
      ...formData,
      otherLoanChecker: !otherLoanChecker,
    });
    // setOtherLoanChecker(!otherLoanChecker);
  };

  //////////////////////////
  // State for oth loan display validation
  const [touchOthLoan, settouchOthLoan] = useState("hidden");

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <Tabs selectedIndex={tabIndex}>
        <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
          <TabList>
            <Tab tabfor="0">Internal Loan</Tab>
            <Tab tabfor="1">Other Loan</Tab>
          </TabList>
        </div>

        <TabPanel tabId="0">
          <form onSubmit={(e) => NextBackBtn(1)}>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 text-center">
                <label className="label-control font-weight-bold">
                  Meeting Date : {mdDate}
                </label>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12 col-12 text-center">
                <label className="label-control font-weight-bold">
                  Member Name : {memberName}{" "}
                </label>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12 col-12 ">
                <label className="label-control font-weight-bold">
                  Total Loan Sans Amt : {loanTakenAmt ? loanTakenAmt : 0}{" "}
                </label>
              </div>
            </div>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 text-center">
                <label className="label-control font-weight-bold">
                  Principal Paid Amt : {loanPaid ? loanPaid : 0}{" "}
                </label>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12 ">
                <label className="label-control font-weight-bold">
                  Interest Paid Amt : {interestPaid ? interestPaid : 0}{" "}
                </label>
              </div>

              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                <label className="label-control text-danger font-weight-bold">
                  Remaining Amt: {RemainingIntLoan || 0}
                </label>
              </div>
            </div>

            {loanTakenAmt > 0 ? (
              <>
                <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                  <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                    <label className="label-control ">
                      {" "}
                      Loan Total Sanctioned Principal:
                    </label>
                    <input
                      type="number"
                      name="loanPayablePrincipal"
                      value={loanPayablePrincipal}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                      required="true"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      Loan Sanctioned Interest:
                    </label>
                    <input
                      type="number"
                      name="loanPayableInterest"
                      value={loanPayableInterest}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                      required="true"
                    />
                  </div>
                </div>

                <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                  <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      {" "}
                      Loan Sanctioned Duration (In Months) :
                    </label>
                    <input
                      type="text"
                      name="internalDuration"
                      value={internalDuration}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                      required="true"
                      pattern="^[1-9][0-9]*$"
                      title="Please Enter Number Greater than Zero"
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      Loan Total Sanctioned Amount:
                    </label>
                    <input
                      type="number"
                      name="TotalPayableAmt"
                      value={TotalPayableAmt}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">Loan Taken Date:</label>
                    <input
                      type="date"
                      name="loanTakenDate"
                      value={loanTakenDate}
                      className="form-control"
                      onChange={(e) => onloantakenDate(e)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                  <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                    <label className="label-control ">
                      {" "}
                      Loan Total Payable Principal:
                    </label>
                    <input
                      type="number"
                      name="loanPayablePrincipal"
                      disabled
                      value={loanPayablePrincipal}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      Loan Total Payable Interest:
                    </label>
                    <input
                      type="number"
                      disabled
                      name="loanPayableInterest"
                      value={loanPayableInterest}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>

                <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                  <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      {" "}
                      Duration (in meeting) :
                    </label>
                    <input
                      type="number"
                      name="internalDuration"
                      value={internalDuration}
                      disabled
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      Loan Total Payable Amount:
                    </label>
                    <input
                      type="number"
                      name="TotalPayableAmt"
                      disabled
                      value={TotalPayableAmt}
                      className="form-control"
                    />
                  </div>
                  <div className="col-lg-2 col-md-12 col-sm-12 col-12"></div>
                </div> */}
              </>
            )}
            {/* {loanPaid > 0 ? ( */}
            <>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Has Repayment ? :</label>
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "block",
                    }}
                    type="checkbox"
                    id="Unconfirmed"
                    checked={loanChecker}
                    onChange={handleOnChange}
                  />
                </div>
              </div>
            </>
            {/* ) : (
              <></>
            )} */}
            {loanChecker && (
              <>
                <div className="row col-lg-12 col-md-9 col-sm-9 col-12 py-4 my-1">
                  <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      Principal Paid in this Meeting:
                    </label>
                    <input
                      type="number"
                      name="PrincipalPaid"
                      value={PrincipalPaid}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      Interest Paid in this Meeting:
                    </label>
                    <input
                      type="number"
                      name="InterestPaid"
                      value={InterestPaid}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>

                <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                  <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                      Total Paid in this Meeting:
                    </label>
                    <input
                      type="number"
                      name="totalLoanPaid"
                      value={totalLoanPaid}
                      className="form-control"
                      disabled={true}
                    />
                  </div>

                  {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">
                     Penalty 123
                    </label>
                    <input
                      type="number"
                      name="intPenalty"
                      value={intPenalty}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div> */}
                </div>
              </>
            )}

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="1">
          {/* <form onSubmit={(e) => onUpdate(e)}> */}
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
              <label className="label-control font-weight-bold bg-danger text-light p-1 h4">
                Other Loan Type : {OtherLoanTypeDisp}{" "}
              </label>
            </div>
            <div className="col-lg-4  col-md-12 col-sm-12 col-12 text-center">
              <label className="label-control font-weight-bold">
                Meeting Date :&nbsp; {mdDate}
              </label>
            </div>

            <div className="col-lg-4  col-md-12 col-sm-12 col-12 text-center">
              <label className="label-control font-weight-bold">
                Member Name : {memberName}{" "}
              </label>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-12 text-center">
              <label className="label-control font-weight-bold">
                OtherLoan Sans Amt : {OtherLoanSansAmtDisp}{" "}
              </label>
            </div>
          </div>

          <div className=" body-inner no-padding  table-responsive">
            <table
              className="tabllll table table-bordered table-striped table-hover mt-2"
              id="datatable2"
            >
              <thead>
                <tr>
                  <th>Edit</th>
                  <th>Type</th>
                  <th>Principal</th>
                  <th>Interest</th>
                  <th>Total to Pay</th>
                  <th>Duration</th>
                  <th>Paid Principal</th>
                  <th>Paid Interest</th>
                </tr>
              </thead>
              <tbody>
                {othloanList &&
                  othloanList.map((memberData, idx) => {
                    if (memberData.otherLoanTypeId) {
                      return (
                        <tr key={idx}>
                          <td>
                            <img
                              className="img_icon_size log"
                              onClick={() => getData(memberData, idx)}
                              src={require("../../static/images/edit_icon.png")}
                              alt="Edit Other Loan Data"
                              title="Edit Other Loan Data"
                            />{" "}
                          </td>
                          <td>{memberData.otherLoanType}</td>
                          <td>{memberData.otherloanPayablePrincipal || 0}</td>
                          <td>{memberData.otherloanPayableInterst || 0}</td>
                          <td>{memberData.otherLoanTakenAmt}</td>
                          <td>{memberData.otherLoanDuration || 0}</td>
                          <td>{memberData.otherLoanPaid || 0}</td>
                          <td>{memberData.otherInterestPaid || 0}</td>
                        </tr>
                      );
                    }
                  })}
                {AddedDetailsOLR &&
                  AddedDetailsOLR.map((AddDetail, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{AddDetail.otherLoanType}</td>
                        <td>
                          {AddDetail.otherloanpurpose}{" "}
                          {AddDetail.otherLoanOtherPurpose
                            ? AddDetail.otherLoanOtherPurpose
                            : ""}
                        </td>
                        <td>{AddDetail.otherLoanTakenAmt}</td>
                        <td>{AddDetail.otherLoanPaid}</td>
                        <td>{AddDetail.otherInterestPaid}</td>

                        <td>{AddDetail.indSubsidyDistributed}</td>
                        <td>{AddDetail.externalNominee}</td>
                        <td>
                          <img
                            className="img_icon_size log"
                            onClick={() =>
                              onRemoveChangeOLR(AddDetail.otherLoanTypeId)
                            }
                            src={require("../../static/images/close-buttonRed.png")}
                            alt="Remove"
                            title="Remove"
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="row">
            {othloanList.length != 0 ? (
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Loan Sanctioned Principal :{" "}
                  </label>
                  <input
                    type="number"
                    name="otherloanPayablePrincipalAmt"
                    value={otherloanPayablePrincipalAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Payable Intrest :{" "}
                  </label>
                  <input
                    type="number"
                    name="otherloanPayableInterstAmt"
                    value={otherloanPayableInterstAmt}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Duration (In Months):{" "}
                  </label>
                  <input
                    type="number"
                    name="othDuration"
                    value={othDuration}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                    pattern="^[1-9][0-9]*$"
                    title="Please Enter Number Greater than Zero"
                  />
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">
                    Total Payable Amount:{" "}
                  </label>
                  <input
                    type="number"
                    name="otherloanSanctionedAmt"
                    value={otherloanSanctionedAmt}
                    className="form-control"
                    disabled={true}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Loan Taken Date:</label>
                  <input
                    type="date"
                    name="othLoanTakenDate"
                    value={takeloandate}
                    className="form-control"
                    keydown={() => false}
                    onKeyDown={false}
                    onChange={(e) => onloantakenDateChange(e)}
                    // keydown(function (e) { return false; })
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <label className="label-control">Has Repayment ? :</label>
                    <input
                      style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "50%",
                        display: "block",
                      }}
                      type="checkbox"
                      id="Unconfirmed"
                      checked={otherLoanChecker}
                      onChange={handleOnOtherChange}
                    />
                  </div>
                </div>
                {otherLoanChecker && (
                  <>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        {" "}
                        Other Loan Principal Paid in this meeting:
                      </label>
                      <input
                        type="number"
                        name="otherLoanPrincipalPaid"
                        value={otherLoanPrincipalPaid}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Other Loan Intrest Paid in this meeting:
                      </label>
                      <input
                        type="number"
                        name="otherLoanInterestPaid"
                        value={otherLoanInterestPaid}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <label className="label-control">
                        Total Amount paid in this meeting:
                      </label>
                      <input
                        type="text"
                        name="TotalOtherloanPaid"
                        value={TotalOtherloanPaid}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </>
                )}
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <button
                    // onClick={(e) => onAddOLR(e)}
                    onClick={onAddOthLoan}
                    type="button"
                    className="btn sub_form btn_continue blackbrd Save float-right py-1 mt-5"
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* <div className=" body-inner no-padding  table-responsive">
              <table
                className="tabllll table table-bordered table-striped table-hover mt-2"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>Edit</th>
                    <th>OL Type</th>
                    <th>OL Purpose</th>
                    <th>OL Total Sanctioned</th>
                    <th>OL Payable Principal</th>
                    <th>OL Payable Intrest</th>
                    <th>Duration</th>
                    <th>OL Paid Principal</th>
                    <th>OL Paid Interest</th>
                  </tr>
                </thead>
                <tbody>
                  {othloanList &&
                    othloanList.map((memberData, idx) => {
                      if (memberData.otherLoanTypeId) {
                        return (
                          <tr key={idx}>
                            <td>
                              <img
                                className="img_icon_size log"
                                //onClick={() => onDelete(memberData, idx)}
                                onClick={() => getData(memberData, idx)}
                                src={require("../../static/images/edit_icon.png")}
                                alt="Edit Other Loan Data"
                                title="Edit Other Loan Data"
                              />{" "}
                            </td>
                            <td>{memberData.otherLoanType}</td>
                            <td>{memberData.otherloanpurpose}</td>
                            <td>{memberData.otherLoanTakenAmt}</td>
                            <td>{memberData.otherloanPayablePrincipal || 0}</td>
                            <td>{memberData.otherloanPayableInterst || 0}</td>
                            <td>{memberData.otherLoanDuration || 0}</td>
                            <td>{memberData.otherLoanPaid || 0}</td>
                            <td>{memberData.otherInterestPaid || 0}</td>
                          </tr>
                        );
                      }
                    })}
                  {AddedDetailsOLR &&
                    AddedDetailsOLR.map((AddDetail, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{AddDetail.otherLoanType}</td>
                          <td>
                            {AddDetail.otherloanpurpose}{" "}
                            {AddDetail.otherLoanOtherPurpose
                              ? AddDetail.otherLoanOtherPurpose
                              : ""}
                          </td>
                          <td>{AddDetail.otherLoanTakenAmt}</td>
                          <td>{AddDetail.otherLoanPaid}</td>
                          <td>{AddDetail.otherInterestPaid}</td>

                          <td>{AddDetail.indSubsidyDistributed}</td>
                          <td>{AddDetail.externalNominee}</td>
                          <td>
                            <img
                              className="img_icon_size log"
                              onClick={() =>
                                onRemoveChangeOLR(AddDetail.otherLoanTypeId)
                              }
                              src={require("../../static/images/close-buttonRed.png")}
                              alt="Remove"
                              title="Remove"
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div> */}
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label
              className="label-control colorRed"
              style={{ visibility: touchOthLoan }}
            >
              *Please Edit All The other Loan
            </label>
          </div>
          <div className=" col-md-12 col-lg-12 col-sm-12 col-12 ">
            {loading ? (
              <button
                className="btn sub_form btn_continue blackbrd Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Submit"
                onClick={(e) => onUpdate(e)}
                className="btn sub_form btn_continue Save float-right"
              />
            )}
            <button
              className="btn sub_form btn_continue Save float-right"
              onClick={() => NextBackBtn(0)}
            >
              Previous
            </button>
          </div>
          {/* </form> */}
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

EditMembermeetingDetailModal.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getParticularLoanReason,
  editMemberMeetingTransaction,
  editOtherLoanMemberMeetingTransaction,
  AddLoanSanctionedDetails,
  getMemberPrevMeetingData,
  EditMemberMeetingLoanDetails,
  getSeletedBatchDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  deleteOtherLoan,
  getExistingMemberOLData,
  addNewOtherLoanDetails,
  getExistingMemberLoanData,
  editOLTypeCount,
  getSanctionedData,
})(EditMembermeetingDetailModal);
