import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Redirect } from "react-router-dom";
import { AddInstitutionDetailsform } from "../../actions/shg";
import { getActiveModule, getActivePermissions } from "../../actions/setting";
import Select from "react-select";
import Spinner from "../layout/Spinner";
import FileBase64 from "react-file-base64";

const AddInstitutionDetails = ({
  auth: { isAuthenticated, user, users, loading },
  AddInstitutionDetailsform,
  getActiveModule,
  getActivePermissions,
  setting: { activemoduleData, activepermissionData },
}) => {
  useEffect(() => {
    getActiveModule();
  }, [getActiveModule]);
  useEffect(() => {
    getActivePermissions();
  }, [getActivePermissions]);

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  //formData
  const [formData, setFormData] = useState({
    institutionName: "",
    institutionAddress: "",
    missionStatement: "",
    aboutOrganization: "",
    institutionDesc: "",
    institutionType: "",
    institutionInterestRate: "",
    aggrementPaymentMode: "",
    ChequeNoOrDDNo: "",
    aggrementBankName: "",
    aggrementAmount: "",
    institutionLogo: "",

    isSubmitted: false,
    // intentionalDelay: "",
  });

  const {
    institutionName,
    institutionAddress,
    missionStatement,
    aboutOrganization,
    institutionDesc,
    institutionType,
    institutionInterestRate,
    aggrementPaymentMode,
    ChequeNoOrDDNo,
    aggrementBankName,
    aggrementAmount,
    institutionLogo,
    isSubmitted,
  } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const UserZone = [
    { value: "Zoned", label: "Zoned" },
    { value: "FreeZone", label: "FreeZone" },
  ];

  const onInstituteTypeChange = (e) => {
    //Required Validation Starts
    setError({
      ...error,
      sIdChecker: true,
      sIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    if (e) {
      setFormData({
        ...formData,
        institutionType: e,
      });
    }
  };

  const [startSelectedDate, setStartDate] = useState("");
  const [endSelectedDate, setEndDate] = useState("");
  const [chequeSelectedDate, setChequeDate] = useState("");
  const onStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const onEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const onChequeDateChange = (e) => {
    setChequeDate(e.target.value);
  };

  const [showHide, setShowHide] = useState({
    showChequenoSection: false,
  });
  const { showChequenoSection } = showHide;
  //Required Validation Starts
  const [error, setError] = useState({
    sIdChecker: false,
    sIdErrorStyle: {},
  });
  const { sIdChecker, sIdErrorStyle } = error;

  const checkErrors = () => {
    if (!sIdChecker) {
      setError({
        ...error,
        sIdErrorStyle: { color: "#F00" },
      });
      return false;
    }

    return true;
  };

  const PaymentMethods = [
    { value: "Cash", label: "Cash" },
    { value: "Cheque", label: "Cheque" },
    { value: "Online Transaction", label: "Online Transaction" },
  ];

  const onPaymentModeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        aggrementPaymentMode: e,
      });
    }
    if (e.value === "Cheque") {
      setShowHide({
        ...showHide,
        showChequenoSection: true,
      });
    } else {
      setShowHide({
        ...showHide,
        showChequenoSection: false,
      });
    }
  };

  const [isDemo, setIsDemo] = useState(false);

  //sttrt
  const [addDataInstruction, setInstructionFormDatas] = useState({
    accessId: "",
    accessName: "",
    isChecked: 1,
  });

  const { accessId, isChecked, accessName } = addDataInstruction;

  const [AddedInstructionDetails, AddInstructionDetails] = useState([]);
  const [accessList, AddAccessList] = useState([]);
  //For Checkbox
  const onAccesscheckSelect = (e, activemoduleData) => {
    if (e.target.checked) {
      const addDataInstruction = {
        accessId: activemoduleData._id,
        accessName: activemoduleData.accessName,
        accessStatus: isChecked,
      };
      setInstructionFormDatas({
        ...addDataInstruction,
        accessId: "",
        accessName: "",
        isChecked: 1,
      });
      let temp = [];
      temp.push(...AddedInstructionDetails, addDataInstruction);
      AddInstructionDetails(temp);
      let temp2 = [];
      temp2.push(...accessList, activemoduleData._id);
      AddAccessList(temp2);
    } else {
      onRemoveChange(activemoduleData);
    }
  };

  const onRemoveChange = (activemoduleData) => {
    const removeList = AddedInstructionDetails.filter(
      (AddInstructionDetails) =>
        AddInstructionDetails.accessId !== activemoduleData._id
    );
    AddInstructionDetails(removeList);
    const index = accessList.indexOf(activemoduleData._id);
    if (index > -1) accessList.splice(index, 1);
  };

  //end

  const [addDataInstruction1, setInstructionFormDatas1] = useState({
    permissionId: "",
    permissionName: "",
    isChecked1: 1,
  });

  const { permissionId, isChecked1, permissionName } = addDataInstruction1;
  const [permissionList, AddPermissionList] = useState([]);

  const [AddedInstructionDetails1, AddInstructionDetails1] = useState([]);
  const onPermissioncheckSelect = (e, activepermissionData) => {
    if (e.target.checked) {
      const addDataInstruction1 = {
        permissionId: activepermissionData._id,
        permissionName: activepermissionData.permissionName,
        permissionStatus: isChecked1 || 1,
      };
      setInstructionFormDatas1({
        ...addDataInstruction1,
        permissionId: "",
        permissionName: "",
        isChecked1: 1,
      });
      let temp = [];
      temp.push(...AddedInstructionDetails1, addDataInstruction1);
      AddInstructionDetails1(temp);
      let temp2 = [];
      temp2.push(...permissionList, activepermissionData._id);
      AddPermissionList(temp2);
    } else {
      onRemoveChange1(activepermissionData);
    }
  };

  const onRemoveChange1 = (activepermissionData) => {
    const removeList = AddedInstructionDetails1.filter(
      (AddInstructionDetails1) =>
        AddInstructionDetails1.permissionId !== activepermissionData._id
    );
    AddInstructionDetails1(removeList);
    const index = permissionList.indexOf(activepermissionData._id);
    if (index > -1) permissionList.splice(index, 1);
  };

  const [rightsList, AddRightsList] = useState([]);
  const onRightsCheckSelect = (e) => {
    if (e.target.checked) {
      let temp = [];
      temp.push(...rightsList, e.target.name);
      AddRightsList(temp);
    } else {
      const removeList = rightsList.filter(
        (rights) => rights !== e.target.name
      );
      AddRightsList(removeList);
    }
  };

  //Required Validation ends
  const onSubmit = (e) => {
    e.preventDefault();
    if (checkErrors()) {
      const finalData = {
        institutionName: institutionName,
        institutionAddress: institutionAddress,
        institutionType: institutionType.value,
        institutionInterestRate: institutionInterestRate,
        missionStatement: missionStatement,
        aboutOrganization: aboutOrganization,
        institutionDesc: institutionDesc,
        institutionProfile: institutionLogo,
        aggrementAmount: aggrementAmount,
        aggrementStartDate: startSelectedDate,
        aggrementEndDate: endSelectedDate,
        aggrementPaymentMode: aggrementPaymentMode.value,
        ChequeNoOrDDNo: ChequeNoOrDDNo,
        aggrementBankName: aggrementBankName,
        aggrementChequeDate: chequeSelectedDate,
        access: AddedInstructionDetails,
        permission: AddedInstructionDetails1,
        accountType: isDemo,
        groupRights: rightsList,
      };
      AddInstitutionDetailsform(finalData);
      setFormData({
        ...formData,
        institutionName: "",
        institutionAddress: "",
        institutionType: "",
        institutionLogo: "",
        institutionInterestRate: "",
        missionStatement: "",
        aboutOrganization: "",
        institutionDesc: "",
        aggrementAmount: "",
        aggrementPaymentMode: "",
        ChequeNoOrDDNo: "",
        aggrementBankName: "",
        isSubmitted: true,
      });
      setStartDate("");
      setChequeDate("");
      setEndDate("");
    }
  };

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);

  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };
  if (isSubmitted) {
    return <Redirect to="/all-institution" />;
  }
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">Add Institution Details </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <Tabs selectedIndex={tabIndex}>
            <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
              <TabList>
                <Tab tabfor="0">Company Info</Tab>
                <Tab tabfor="1">Agreement Info</Tab>
                <Tab tabfor="2">Permissions</Tab>
                <Tab tabfor="3">Access</Tab>
                <Tab tabfor="4">Group Rights</Tab>
              </TabList>
            </div>
            <TabPanel tabId="0">
              <div className=" col-md-12 col-lg-12 col-sm-12 col-12 ">
                <form onSubmit={(e) => NextBackBtn(1)}>
                  <div className="row col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12 ">
                      <div className="row card-new">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h5>Basic Info</h5>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label>Institution Name* :</label>
                          <input
                            type="text"
                            name="institutionName"
                            value={institutionName}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label>Institution Interest Rate* :</label>
                          <input
                            type="number"
                            min="0"
                            name="institutionInterestRate"
                            value={institutionInterestRate}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label
                            className="label-control"
                            style={sIdErrorStyle}
                          >
                            Institution belongs to* :
                          </label>
                          <Select
                            name="institutionType"
                            options={UserZone}
                            isSearchable={false}
                            value={institutionType}
                            placeholder="Select Institution Type"
                            onChange={(e) => onInstituteTypeChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Institution Address* :
                          </label>
                          <textarea
                            name="institutionAddress"
                            value={institutionAddress}
                            id="institutionAddress"
                            className="textarea form-control"
                            rows="4"
                            placeholder="Address"
                            onChange={(e) => onInputChange(e)}
                            style={{ width: "100%" }}
                            required
                          ></textarea>
                        </div>
                        <div className="row col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                          <label className="label-control">
                            Institution Logo :
                          </label>

                          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                            <FileBase64
                              type="file"
                              multiple={false}
                              onDone={({ base64 }) =>
                                setFormData({
                                  ...formData,
                                  institutionLogo: base64,
                                })
                              }
                            />

                            <img
                              className="log_size"
                              alt="Preview"
                              src={`${institutionLogo}`}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="row card-new ">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h5>Additional Info</h5>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <label>Mission* :</label>
                          <textarea
                            name="missionStatement"
                            value={missionStatement}
                            id="missionStatement"
                            className="textarea form-control"
                            rows="3"
                            placeholder="Mission"
                            onChange={(e) => onInputChange(e)}
                            style={{ width: "100%" }}
                            required
                          ></textarea>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <label className="label-control">About* :</label>
                          <textarea
                            name="aboutOrganization"
                            value={aboutOrganization}
                            id="aboutOrganization"
                            className="textarea form-control"
                            rows="3"
                            placeholder="About"
                            onChange={(e) => onInputChange(e)}
                            style={{ width: "100%" }}
                            required
                          ></textarea>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                          <label className="label-control">
                            Additional Info* :
                          </label>
                          <textarea
                            name="institutionDesc"
                            value={institutionDesc}
                            id="institutionDesc"
                            className="textarea form-control"
                            rows="3"
                            placeholder="Additional Info"
                            onChange={(e) => onInputChange(e)}
                            style={{ width: "100%" }}
                            required
                          ></textarea>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                          <label className="label-control">
                            Is Demo Account :
                          </label>
                          <input
                            style={{
                              height: "20px",
                              width: "20px",
                              borderRadius: "50%",
                              display: "block",
                            }}
                            name="demoCheck"
                            type="checkbox"
                            id="demoCheck"
                            checked={isDemo}
                            onChange={(e) => setIsDemo(!isDemo)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <input
                        type="submit"
                        name="submit"
                        value="Next"
                        className="btn sub_form btn_continue Save float-right"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </TabPanel>
            <TabPanel tabId="1">
              <form onSubmit={(e) => NextBackBtn(2)}>
                <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                      <div className="row card-new py-3">
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label>Agreement Start Date* :</label>
                          <input
                            type="date"
                            placeholder="dd/mm/yyyy"
                            className="form-control cpp-input datevalidation"
                            name="instistartDate"
                            value={startSelectedDate}
                            onChange={(e) => onStartDateChange(e)}
                            style={{
                              width: "75%",
                            }}
                            required
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label>Agreement End Date* :</label>
                          <input
                            type="date"
                            placeholder="dd/mm/yyyy"
                            className="form-control cpp-input datevalidation"
                            name="instiendDate"
                            value={endSelectedDate}
                            onChange={(e) => onEndDateChange(e)}
                            style={{
                              width: "75%",
                            }}
                            required
                          />
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label>Agreement Amount* :</label>
                          <input
                            type="number"
                            min="0"
                            name="aggrementAmount"
                            value={aggrementAmount}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            required
                            onKeyDown={(e) => funcKeyDown(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-4 col-12">
                          <label>Mode Of Payment *:</label>
                          <Select
                            name="aggrementPaymentMode"
                            options={PaymentMethods}
                            isSearchable={false}
                            value={aggrementPaymentMode}
                            placeholder="Select"
                            onChange={(e) => onPaymentModeChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>
                        {showChequenoSection && (
                          <>
                            <div className="col-lg-4  col-md-4 col-sm-4 col-12 py-3">
                              <label>Cheque No/DD No :</label>
                              <input
                                type="number"
                                name="ChequeNoOrDDNo"
                                value={ChequeNoOrDDNo}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                                //  required
                              />
                            </div>

                            <div className="col-lg-4  col-md-4 col-sm-4 col-12 py-3">
                              <label>Bank Name :</label>
                              <input
                                type="text"
                                name="aggrementBankName"
                                value={aggrementBankName}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                //  required
                              />
                            </div>

                            <div className="col-lg-4  col-md-4 col-sm-4 col-12 py-3">
                              <label>ChequeDate :</label>
                              <br />
                              <input
                                type="date"
                                placeholder="dd/mm/yyyy"
                                className="form-control cpp-input datevalidation"
                                name="aggrementChequeDate"
                                value={chequeSelectedDate}
                                onChange={(e) => onChequeDateChange(e)}
                                style={{
                                  width: "75%",
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <input
                      type="submit"
                      name="submit"
                      value="Next"
                      className="btn sub_form btn_continue Save float-right"
                    />
                    <button
                      className="btn sub_form btn_continue Save float-right"
                      onClick={() => NextBackBtn(0)}
                    >
                      Previous
                    </button>
                  </div>
                </div>
              </form>
            </TabPanel>
            <TabPanel tabId="2">
              <form onSubmit={(e) => NextBackBtn(3)}>
                <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                    <div className="row card-new  py-3">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5>Give Permissions</h5>
                      </div>
                      <div className="col-lg-10 col-md-12 col-sm-12 col-12 body-inner no-padding  table-responsive">
                        <table
                          className="table table-bordered table-striped table-hover"
                          id="datatable2"
                        >
                          <thead>
                            <tr>
                              <th>Permissions Name</th>
                              <th>Description</th>
                              <th>Give Permissions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {activepermissionData &&
                              activepermissionData.map(
                                (activepermissionData, idx) => {
                                  let isChecked = 0;
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        {" "}
                                        {
                                          activepermissionData.permissionName
                                        }{" "}
                                      </td>
                                      <td>
                                        {
                                          activepermissionData.permissionDescription
                                        }{" "}
                                      </td>
                                      <td>
                                        <center>
                                          <input
                                            style={{
                                              height: "40px",
                                              width: "40px",
                                              borderRadius: "50%",
                                              display: "block",
                                            }}
                                            name="permissionId"
                                            type="checkbox"
                                            className="permissionclass"
                                            id="permissionId"
                                            checked={permissionList.includes(
                                              activepermissionData._id
                                            )}
                                            onChange={(e) =>
                                              onPermissioncheckSelect(
                                                e,
                                                activepermissionData
                                              )
                                            }
                                          />
                                        </center>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <input
                      type="submit"
                      name="submit"
                      value="Next"
                      className="btn sub_form btn_continue Save float-right"
                    />
                    <button
                      className="btn sub_form btn_continue Save float-right"
                      onClick={() => NextBackBtn(1)}
                    >
                      Previous
                    </button>
                  </div>
                </div>
              </form>
            </TabPanel>
            <TabPanel tabId="3">
              <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                  <div className="row card-new  py-3">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h5>Give Access</h5>
                    </div>
                    <div className="col-lg-10 col-md-12 col-sm-12 col-12 body-inner no-padding  table-responsive">
                      <table
                        className="table table-bordered table-striped table-hover"
                        id="datatable2"
                      >
                        <thead>
                          <tr>
                            <th>Module Name</th>
                            <th>Description</th>
                            <th>Give Access</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activemoduleData &&
                            activemoduleData.map((activemoduleData, idx) => {
                              return (
                                <tr key={idx}>
                                  <td> {activemoduleData.accessName} </td>
                                  <td>{activemoduleData.accessDescription} </td>
                                  <td>
                                    <center>
                                      <input
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: "50%",
                                          display: "block",
                                        }}
                                        name="moduleaccessId"
                                        type="checkbox"
                                        className="moduleaccessclass"
                                        id="moduleaccessId"
                                        checked={accessList.includes(
                                          activemoduleData._id
                                        )}
                                        onChange={(e) =>
                                          onAccesscheckSelect(
                                            e,
                                            activemoduleData
                                          )
                                        }
                                      />
                                    </center>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <form className="row" onSubmit={(e) => NextBackBtn(4)}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <input
                    type="submit"
                    name="submit"
                    value="Next"
                    className="btn sub_form btn_continue Save float-right"
                  />
                  <button
                    className="btn sub_form btn_continue Save float-right"
                    onClick={() => NextBackBtn(2)}
                  >
                    Previous
                  </button>
                </div>
              </form>
            </TabPanel>
            <TabPanel tabId="4">
              <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                <div className="col-lg-11 col-md-12 col-sm-12 col-12 py-3">
                  <div className="row card-new  py-3">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h5>Group Rights</h5>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h4> Admin</h4>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                        name="AdminEditMeeting"
                        type="checkbox"
                        id="AdminEditMeeting"
                        checked={rightsList.includes("AdminEditMeeting")}
                        onChange={(e) => onRightsCheckSelect(e)}
                      />{" "}
                      <label>Edit Meeting</label>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                        name="AdminVerifyMeeting"
                        type="checkbox"
                        id="AdminVerifyMeeting"
                        checked={rightsList.includes("AdminVerifyMeeting")}
                        onChange={(e) => onRightsCheckSelect(e)}
                      />{" "}
                      <label>Verify Meeting </label>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                        name="AdminEditReport"
                        type="checkbox"
                        id="AdminEditReport"
                        checked={rightsList.includes("AdminEditReport")}
                        onChange={(e) => onRightsCheckSelect(e)}
                      />{" "}
                      <label>Edit Report </label>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h4> Co-ordinator</h4>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                        name="Co-ordinatorEditMeeting"
                        type="checkbox"
                        id="Co-ordinatorEditMeeting"
                        checked={rightsList.includes("Co-ordinatorEditMeeting")}
                        onChange={(e) => onRightsCheckSelect(e)}
                      />{" "}
                      <label>Edit Meeting </label>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                        name="Co-ordinatorVerifyMeeting"
                        type="checkbox"
                        id="Co-ordinatorVerifyMeeting"
                        checked={rightsList.includes(
                          "Co-ordinatorVerifyMeeting"
                        )}
                        onChange={(e) => onRightsCheckSelect(e)}
                      />{" "}
                      <label>Verify Meeting </label>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                        name="Co-ordinatorEditReport"
                        type="checkbox"
                        id="Co-ordinatorEditReport"
                        checked={rightsList.includes("Co-ordinatorEditReport")}
                        onChange={(e) => onRightsCheckSelect(e)}
                      />{" "}
                      <label>Edit Report </label>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h4> Incharge</h4>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                        name="InchargeEditMeeting"
                        type="checkbox"
                        id="InchargeEditMeeting"
                        checked={rightsList.includes("InchargeEditMeeting")}
                        onChange={(e) => onRightsCheckSelect(e)}
                      />{" "}
                      <label>Edit Meeting </label>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                        name="InchargeVerifyMeeting"
                        type="checkbox"
                        id="InchargeVerifyMeeting"
                        checked={rightsList.includes("InchargeVerifyMeeting")}
                        onChange={(e) => onRightsCheckSelect(e)}
                      />{" "}
                      <label>Verify Meeting </label>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                        name="InchargeEditReport"
                        type="checkbox"
                        id="InchargeEditReport"
                        checked={rightsList.includes("InchargeEditReport")}
                        onChange={(e) => onRightsCheckSelect(e)}
                      />{" "}
                      <label>Edit Report </label>
                    </div>
                  </div>
                </div>
              </div>
              <form className="row" onSubmit={(e) => onSubmit(e)}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <input
                    type="submit"
                    name="Save"
                    value="Submit"
                    className="btn sub_form btn_continue Save float-right"
                  />
                  <button
                    className="btn sub_form btn_continue Save float-right"
                    onClick={() => NextBackBtn(3)}
                  >
                    Previous
                  </button>
                </div>
              </form>
            </TabPanel>
          </Tabs>
        </section>
        <section className="sub_reg">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label className="label-control colorRed">
              * Indicates mandatory fields, Please fill mandatory fields before
              Submit
            </label>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

AddInstitutionDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  AddInstitutionDetailsform: PropTypes.func.isRequired,
  getActiveModule: PropTypes.func.isRequired,
  getActivePermissions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  setting: state.setting,
});

export default connect(mapStateToProps, {
  AddInstitutionDetailsform,
  getActiveModule,
  getActivePermissions,
})(AddInstitutionDetails);
