import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getActiveInstitutions } from "../../actions/shg";
import AllVarado from "./AllVarado";
import AllParish from "./AllParish";
import AllStates from "./AllStates";
import AllDistricts from "./AllDistricts";
import AllBlocks from "./AllBlocks.js";
import AllVillages from "./AllVillages.js";
import AllTankName from "./AllTankName.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Spinner from "../layout/Spinner";

const AllArea = ({
  auth: { isAuthenticated, user, users },
  shg: { activeInstitutions },
  getActiveInstitutions,
}) => {
  useEffect(() => {
    getActiveInstitutions();
  }, [getActiveInstitutions]);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">All Area </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <Tabs>
            <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
              <TabList>
                {loggedUserInstitutionData.institutionType === "Zoned" && (
                  <Fragment>
                    <Tab style={{ cursor: "pointer" }}>Varado</Tab>
                    <Tab style={{ cursor: "pointer" }}>Parish</Tab>
                  </Fragment>
                )}

                <Tab style={{ cursor: "pointer" }}>State</Tab>
                <Tab style={{ cursor: "pointer" }}>District</Tab>
                {allPermission && allPermission.includes("Blockwise") && (
                  <Tab style={{ cursor: "pointer" }}>Blocks</Tab>
                )}
                {allPermission && allPermission.includes("Villagewise") && (
                  <Tab style={{ cursor: "pointer" }}>Villages</Tab>
                )}
                {allPermission && allPermission.includes("Donor_Report") && (
                  <Tab style={{ cursor: "pointer" }}>Tank Name</Tab>
                )}
              </TabList>
            </div>
            {loggedUserInstitutionData.institutionType === "Zoned" && (
              <Fragment>
                <TabPanel>
                  <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                    <AllVarado />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                    <AllParish />
                  </div>
                </TabPanel>
              </Fragment>
            )}
            <TabPanel>
              <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                <AllStates />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                <AllDistricts />
              </div>
            </TabPanel>

            {allPermission && allPermission.includes("Blockwise") && (
              <TabPanel>
                <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                  <AllBlocks />
                </div>
              </TabPanel>
            )}

            {allPermission && allPermission.includes("Villagewise") && (
              <TabPanel>
                <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                  <AllVillages />
                </div>
              </TabPanel>
            )}

            {allPermission && allPermission.includes("Donor_Report") && (
              <TabPanel>
                <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                  <AllTankName />
                </div>
              </TabPanel>
            )}
          </Tabs>
        </section>
      </div>
    </Fragment>
  );
};

AllArea.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getActiveInstitutions: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getActiveInstitutions,
})(AllArea);
