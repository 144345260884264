import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import { getAllVarado } from "../../actions/area";
import Spinner from "../layout/Spinner";
import {
  getAllMemberMeetingBatches,
  batchAndMeetingDetails,
} from "../../actions/shg";

const MemberMeetings = ({
  auth: { isAuthenticated, user, users },
  area: { varadoData },
  shg: { allmembermeetingbatches, batchIdValue },
  getAllVarado,
  getAllMemberMeetingBatches,
  batchAndMeetingDetails,
}) => {
  let selbatchAndMeetingDetails = JSON.parse(
    localStorage.getItem("selbatchAndMeetingDetails")
  );
  useEffect(() => {
    getAllVarado();
  }, [getAllVarado]);
  useEffect(() => {
    getAllMemberMeetingBatches(selbatchAndMeetingDetails);
  }, [getAllMemberMeetingBatches]);

  const [error, setError] = useState({
    addmeetingBtnStyle: { opacity: "0.5", pointerEvents: "none" },
  });
  const { addmeetingBtnStyle } = error;

  const allbatches = [];

  allmembermeetingbatches.map((batchs) =>
    allbatches.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState(
    selbatchAndMeetingDetails &&
      selbatchAndMeetingDetails.batchIdVal && {
        batchId: selbatchAndMeetingDetails.batchIdVal,
        label: selbatchAndMeetingDetails.batchNameVal,
        value: selbatchAndMeetingDetails.batchNameVal,
      }
  );
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();
  const [isSubmitted, setSubmitted] = useState(false);

  const onBatchesChange = (e) => {
    setError({
      ...error,
      addmeetingBtnStyle: { opacity: "1" },
    });

    var batchId = "";
    var batchName = "";
    getbatchsData(e);

    batchId = e.batchId;
    batchName = e.value;

    setbatchId(batchId);
    setbatchName(batchName);
  };

  const addMeetingClick = () => {
    const batchIdData = {
      batchIdVal: batchId,
      batchNameVal: batchName,
    };
    batchAndMeetingDetails(batchIdData);
    setSubmitted(true);
  };
  if (isSubmitted) {
    return <Redirect to="/add-membermeeting-details" />;
  }
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">Meetings </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-3 col-md-11 col-sm-11 col-11 py-4">
              <Select
                name="batchName"
                options={allbatches}
                isSearchable={true}
                value={batchs}
                placeholder="Select SHG"
                onChange={(e) => onBatchesChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
            <div className="col-lg-2 col-md-11 col-sm-11 col-11">
              <button
                variant="success"
                className="btn sub_form btn_continue Save "
                style={addmeetingBtnStyle}
                onClick={() => addMeetingClick()}
              >
                Add Meeting
              </button>
            </div>
            <div className="col-lg-6 col-md-11 col-sm-11 col-11 py-4">
              <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

MemberMeetings.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  getAllVarado: PropTypes.func.isRequired,
  getAllMemberMeetingBatches: PropTypes.func.isRequired,
  batchAndMeetingDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
});

export default connect(mapStateToProps, {
  getAllVarado,
  getAllMemberMeetingBatches,
  batchAndMeetingDetails,
})(MemberMeetings);
