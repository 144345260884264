import React, { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getBatchEditMeeting,
  getBatchwiseReport,
  getBatchOfParish,
  getAllBatchesDropdown,
  batchAndMeetingDetails,
  getBatchOfDistrict,
} from "../../actions/shg";
import {
  getVarados,
  getParish,
  getStates,
  getDistrict,
  getBlockDetails,
} from "../../actions/area";
import Spinner from "../layout/Spinner";
import { CSVLink } from "react-csv";

const EditMeeting = ({
  auth: { isAuthenticated, user, users },
  shg: {
    batchwisereport,
    batchwisereportSum,
    batchOfParish,
    allbatchesdropdown,
    batchOfDistrict,
    editBatchDetailslist,
  },
  area: { varadosData, activeParish, statesData, activeDistrict, getBlock },
  getBatchwiseReport,
  getVarados,
  getParish,
  getBatchOfParish,
  getAllBatchesDropdown,
  batchAndMeetingDetails,
  getStates,
  getDistrict,
  getBlockDetails,
  getBatchOfDistrict,
  getBatchEditMeeting,
}) => {
  let selBatchwiseReport = JSON.parse(
    localStorage.getItem("selBatchwiseReport")
  );

  useEffect(() => {
    getBatchwiseReport(selBatchwiseReport);
  }, [getBatchwiseReport]);
  useEffect(() => {
    getVarados();
  }, [getVarados]);
  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);
  useEffect(() => {
    getStates();
  }, [getStates]);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  useEffect(() => {
    getBatchEditMeeting({ InstID: loggedUserInstitutionData._id });
  }, []);

  const [showHide] = useState({
    showBatchSection:
      loggedUserInstitutionData &&
      loggedUserInstitutionData.institutionType === "Zoned"
        ? true
        : false,
  });

  const { showBatchSection } = showHide;
  const allvarado = [];
  varadosData.map((varado) =>
    allvarado.push({
      vId: varado._id,
      vCode: varado.varadoCode,
      label: varado.varadoName,
      value: varado.varadoName,
    })
  );

  const [varado, getVaradoData] = useState(
    selBatchwiseReport &&
      selBatchwiseReport.varadoVal && {
        vId: selBatchwiseReport.varadoVal,
        label: selBatchwiseReport.varadoName,
        value: selBatchwiseReport.varadoName,
      }
  );
  const onVaradoChange = (e) => {
    getVaradoData(e);
    let varadoVal = {
      varadoInfo: e.vId,
    };
    getParish(varadoVal);
    getBatchwiseReport({ varadoVal: e.vId, varadoName: e.value });
    getParishData("");
    getbatchesData("");
    getBatchOfParish("");
  };

  const allparish = [];

  activeParish.map((parish) =>
    allparish.push({
      pId: parish._id,
      pCode: parish.parishCode,
      bCounter: parish.batchCounter,
      label: parish.parishName,
      value: parish.parishName,
    })
  );

  const [parish, getParishData] = useState(
    selBatchwiseReport &&
      selBatchwiseReport.parishId && {
        pId: selBatchwiseReport.parishId,
        label: selBatchwiseReport.parishName,
        value: selBatchwiseReport.parishName,
      }
  );

  const onParishChange = (e) => {
    getParishData(e);
    var filterData = {
      parishId: e.pId,
    };
    getBatchOfParish(filterData);
    getBatchwiseReport({
      varadoVal: varado.vId,
      varadoName: varado.value,
      parishId: e.pId,
      parishName: e.value,
    });
    getbatchsData("");
  };

  const onClickReset = () => {
    getParishData("");
    getBatchwiseReport("");
    getVaradoData("");
    getbatchsData("");
    getbatchesData("");
    getParish("");
    getBatchOfParish("");
    getStates("");
    getDistrict("");
    getStateData("");
    getdistrictData("");
    getBatchOfDistrict("");
    setBlock("");
  };

  const allbatches = [];
  batchOfParish.map((batchs) =>
    allbatches.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState(
    selBatchwiseReport && selBatchwiseReport.batchIdVal
      ? {
          batchId: selBatchwiseReport.batchIdVal,
          label: selBatchwiseReport.batchName,
          value: selBatchwiseReport.batchName,
        }
      : ""
  );
  const onBatchesChange = (e) => {
    getbatchsData(e);

    let transactionTypeChange = {
      varadoVal: varado.vId,
      varadoName: varado.value,
      parishId: parish.pId,
      parishName: parish.value,
      batchIdVal: e.batchId,
      batchName: e.value,
    };
    getBatchwiseReport(transactionTypeChange);
  };

  const allbatchesList = [];
  batchOfDistrict.map((batches) =>
    allbatchesList.push({
      batchesId: batches._id,
      label: batches.batchName,
      value: batches.batchName,
    })
  );
  const [batches, getbatchesData] = useState(
    selBatchwiseReport && selBatchwiseReport.batchIdVal
      ? {
          batchesId: selBatchwiseReport.batchIdVal,
          label: selBatchwiseReport.batchName,
          value: selBatchwiseReport.batchName,
        }
      : ""
  );
  const onBatchChange = (e) => {
    getbatchesData();
    const changeData = {
      stateInfo: state.sId,
      stateName: state.label,
      districtIdVal: district.districtId,
      districtName: district.label,
      blockId: block.value,
      blockName: block.label,
      batchIdVal: e.batchesId,
      batchName: e.value,
    };
    getBatchwiseReport(changeData);
  };

  const handleGoToAllVerifiedMeeting = (batchReport) => {
    const batchIdData = {
      batchIdVal: batchReport._id,
      batchName: batchReport.batchName,
    };
    // verifiedMeetingList(batchIdData);
    batchAndMeetingDetails(batchIdData);
  };
  const allstates = [];
  statesData.map((state) =>
    allstates.push({
      sId: state._id,
      label: state.stateName,
      value: state.stateName,
    })
  );

  const [state, getStateData] = useState(
    selBatchwiseReport && selBatchwiseReport.stateInfo
      ? {
          sId: selBatchwiseReport.stateInfo,
          label: selBatchwiseReport.stateName,
          value: selBatchwiseReport.stateName,
        }
      : ""
  );

  const onStateChange = (e) => {
    getStateData(e);
    let stateVal = {
      stateInfo: e.sId,
      stateName: e.label,
    };
    getDistrict(stateVal);
    getBatchwiseReport(stateVal);
    getdistrictData("");
    getBatchOfDistrict("");
    getbatchesData("");
    setBlock("");
  };

  const alldistrict = [];

  activeDistrict.map((district) =>
    alldistrict.push({
      districtId: district._id,
      label: district.districtName,
      value: district.districtName,
    })
  );

  const [district, getdistrictData] = useState(
    selBatchwiseReport && selBatchwiseReport.districtIdVal
      ? {
          districtId: selBatchwiseReport.districtIdVal,
          label: selBatchwiseReport.districtName,
          value: selBatchwiseReport.stateName,
        }
      : ""
  );
  const ondistrictChange = (e) => {
    getdistrictData(e);
    getBlockDetails({ districtIdVal: e.districtId });
    getBatchOfDistrict({
      districtId: e.districtId,
    });
    getBatchwiseReport({
      stateInfo: state.sId,
      stateName: state.label,
      districtIdVal: e.districtId,
      districtName: e.label,
    });
    getbatchesData("");
    setBlock("");
  };

  const allBlocks = [];
  getBlock &&
    getBlock.map((block) =>
      allBlocks.push({
        label: block.blockName,
        value: block._id,
      })
    );

  const [block, setBlock] = useState(
    selBatchwiseReport && selBatchwiseReport.blockId
      ? {
          label: selBatchwiseReport.blockName,
          value: selBatchwiseReport.blockId,
        }
      : ""
  );
  const onBlockChange = (e) => {
    setBlock(e);
    getBatchOfDistrict({
      blockId: e.value,
    });
    getBatchwiseReport({
      stateInfo: state.sId,
      stateName: state.label,
      districtIdVal: district.districtId,
      districtName: district.label,
      blockId: e.value,
      blockName: e.label,
    });
    getbatchesData("");
  };

  const csvData = [
    [
      "SHG Name",
      "SHG Code",
      "Parish",
      "Total Saving",
      "Total Internal Loan Amount Given",
      "Total Other Loan Amount Given",
      "Outstanding Internal Loan Amount",
      "Outstanding Other Loan Amount",
      "Total No. of Meetings Conducted",
    ],
  ];
  batchwisereport.map((batchwisereport) => {
    return csvData.push([
      batchwisereport.batchName,
      batchwisereport.batchCode,
      batchwisereport.parishName,
      batchwisereport.batchSavingAmt,
      batchwisereport.batchLoanAmt,
      batchwisereport.otherLoanSanctionedToMember,
      batchwisereport.OutstandingBatchLoanAmt,
      batchwisereport.OutstandingBatchOtherLoanAmt,
      batchwisereport.batchMeetingHeldOnDate,
    ]);
  });

  if (batchwisereportSum && batchwisereportSum[0]) {
    csvData.push([
      "Total",
      "",
      "",
      batchwisereportSum[0].batchSavingAmt,

      batchwisereportSum[0].batchLoanAmt,

      batchwisereportSum[0].otherLoanSanctionedToMember,

      batchwisereportSum[0].OutstandingBatchLoanAmt,

      batchwisereportSum[0].OutstandingBatchOtherLoanAmt,
    ]);
  }
  const csvFreeZonedData = [
    [
      "SHG Name",
      "SHG Code",
      "Saving",
      "Internal Loan Amount Given",
      "Other Loan Amount Given",
      "Outstanding Internal Loan Amount",
      "Outstanding Other Loan Amount",
      "Total No. of Meetings Conducted",
    ],
  ];
  batchwisereport.map((batchwisereport) => {
    return csvFreeZonedData.push([
      batchwisereport.batchName,
      batchwisereport.batchCode,
      batchwisereport.batchSavingAmt,
      batchwisereport.batchLoanAmt,
      batchwisereport.otherLoanSanctionedToMember,
      batchwisereport.OutstandingBatchLoanAmt,
      batchwisereport.OutstandingBatchOtherLoanAmt,
      batchwisereport.batchMeetingHeldOnDate,
    ]);
  });
  if (batchwisereportSum && batchwisereportSum[0]) {
    csvFreeZonedData.push([
      "Total",
      "",
      batchwisereportSum[0].batchSavingAmt,
      batchwisereportSum[0].batchLoanAmt,
      batchwisereportSum[0].otherLoanSanctionedToMember,
      batchwisereportSum[0].OutstandingBatchLoanAmt,
      batchwisereportSum[0].OutstandingBatchOtherLoanAmt,
    ]);
  }
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  ///////////////////////////////////////TOTAL

  // const totSUm = batchwisereport.reduce((acu, cur) => {
  //   acu + cur.memberCounter - 1, 0;
  // });
  const MemcountArray = batchwisereport.map(
    (ele) => Number(ele.memberCounter) - 1
  );
  const totalMemCount = MemcountArray.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <>
      {editBatchDetailslist && editBatchDetailslist.length === 0 ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="container container_align ">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11">
              <h2 className="heading_color">Edit MemberWise Meeting</h2>
              <hr />
            </div>
            <section className="sub_reg">
              <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding">
                {showBatchSection && (
                  <>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-11 mt-1">
                      {/* <Select
                  name="varadoName"
                  options={allvarado}
                  isSearchable={true}
                  value={varado}
                  placeholder="Select Varado"
                  onChange={(e) => onVaradoChange(e)}
                /> */}
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-11 mt-1">
                      {/* <Select
                  name="parishName"
                  options={allparish}
                  isSearchable={true}
                  value={parish}
                  placeholder="Select Parish"
                  onChange={(e) => onParishChange(e)}
                /> */}
                    </div>
                  </>
                )}
                {loggedUserInstitutionData.institutionType === "Zoned" ? (
                  <div className="col-lg-3 col-md-3 col-sm-3 col-11 mt-1">
                    {/* <Select
                name="batchName"
                options={allbatches}
                isSearchable={true}
                value={batchs}
                placeholder="Select SHG"
                onChange={(e) => onBatchesChange(e)}
              /> */}
                  </div>
                ) : (
                  <>
                    <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                      {/* <Select
                  name="stateName"
                  options={allstates}
                  isSearchable={true}
                  value={state}
                  placeholder="Select State"
                  onChange={(e) => onStateChange(e)}
                /> */}
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                      {/* <Select
                  name="districtName"
                  options={alldistrict}
                  isSearchable={true}
                  value={district}
                  placeholder="Select District"
                  onChange={(e) => ondistrictChange(e)}
                /> */}
                    </div>
                    {allPermission && allPermission.includes("Blockwise") && (
                      <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                        {/* <Select
                    name="blockName"
                    options={allBlocks}
                    isSearchable={true}
                    value={block}
                    placeholder="Select Block"
                    onChange={(e) => onBlockChange(e)}
                  /> */}
                      </div>
                    )}
                    <div className="col-lg-3 col-md-5 col-sm-4 col-11 mt-1">
                      {/* <Select
                  name="batchName"
                  options={allbatchesList}
                  isSearchable={true}
                  value={batches}
                  placeholder="Select SHG"
                  onChange={(e) => onBatchChange(e)}
                /> */}
                    </div>
                  </>
                )}

                <div className="col-lg-3 col-md-3 col-sm-4 col-11 py-3">
                  <Link to="/all-reports">
                    <img
                      className="img_icon_size log float-right ml-3"
                      src={require("../../static/images/back.png")}
                      alt="Back"
                      title="Back"
                    />
                  </Link>
                  <img
                    className="img_icon_size log float-right ml-3"
                    onClick={() => onClickReset()}
                    src={require("../../static/images/refresh-icon.png")}
                    alt="refresh"
                    title="Refresh"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
                  <section className="body">
                    <div className=" body-inner no-padding table-responsive ">
                      <table
                        className="table table-bordered table-striped table-hover"
                        id="datatable2"
                      >
                        <thead>
                          <tr>
                            <th>SHG Name</th>
                            <th>SHG Code</th>
                          </tr>
                        </thead>
                        <tbody>
                          {editBatchDetailslist &&
                            editBatchDetailslist.map((batchReport, idx) => {
                              //var total = Number(batchReport.memberCounter) - 1;

                              return (
                                <tr key={idx}>
                                  <td>
                                    <Link
                                      to="/edit-verified-meeting"
                                      className="btnLink"
                                      onClick={() =>
                                        handleGoToAllVerifiedMeeting(
                                          batchReport
                                        )
                                      }
                                    >
                                      {batchReport.batchName}
                                    </Link>
                                  </td>
                                  <td>{batchReport.batchCode}</td>
                                  {showBatchSection && (
                                    <>
                                      <td>{batchReport.parishName}</td>
                                    </>
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
                <div className="col-lg-11 col-md-6 col-sm-11 col-11 align_right">
                  <label></label>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      )}
    </>
  );
};

EditMeeting.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
});

export default connect(mapStateToProps, {
  getBatchwiseReport,
  getVarados,
  getParish,
  getBatchOfParish,
  getAllBatchesDropdown,
  batchAndMeetingDetails,
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getBlockDetails,
  getBatchEditMeeting,
})(EditMeeting);
