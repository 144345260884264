import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deactiveBatch } from "../../actions/shg";

const DeactiveBatch = ({
  auth: { isAuthenticated, user, users, loading },
  allbatch,
  onDeactiveModalChange,
  deactiveBatch,
}) => {
  const [formData, setFormData] = useState({
    batchdeactivereason: "",
    isSubmitted: false,
  });
  const { batchdeactivereason } = formData;
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: allbatch ? allbatch._id : "",
      batchStatus: "Deactive",
      batchDeactiveReason: batchdeactivereason,
      batchDeactiveById: user && user._id,
    };

    deactiveBatch(finalData);
    localStorage.removeItem("selBatchDetails");
    onDeactiveModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div className="col-lg-12 col-md-9 col-sm-9 col-12 ">
        <form className="row" onSubmit={(e) => onSubmit(e)}>
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
            <div className="col-lg-12 col-md-4 col-sm-4 col-12">
              <label>Reason For Deactivation* :</label>
            </div>
            <div className="col-lg-12  col-md-4 col-sm-4 col-12">
              <textarea
                name="batchdeactivereason"
                id="batchdeactivereason"
                className="textarea form-control"
                rows="3"
                placeholder="Deactive Reason"
                onChange={(e) => onInputChange(e)}
                style={{ width: "100%" }}
                required
              ></textarea>
            </div>
            <div className="col-lg-12 col-md-4 col-sm-4 col-12 py-2">
              <label>Are You Sure You Want to Deactivate ??</label>
            </div>
            <div
              className="col-lg-12 col-md-9 col-sm-9 col-12 Savebutton"
              size="lg"
            >
              {loading ? (
                <button
                  className="btn sub_form btn_continue blackbrd Save float-right"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <input
                  type="submit"
                  name="Save"
                  value="Submit"
                  className="btn sub_form btn_continue Save float-right"
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

DeactiveBatch.propTypes = {
  auth: PropTypes.object.isRequired,
  deactiveBatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  deactiveBatch,
})(DeactiveBatch);
