import React from "react";

const AllMemberTab = ({
  membersMeetingData,
  batchTransactionData,
  allRights,
  user,
  onOpenEditModal,
}) => {
  let savingAmtTot = 0;
  let loanPaidTot = 0;
  let interestPaidTot = 0;
  let otherLoanPaidTot = 0;
  let otherInterestPaidTot = 0;
  let subscriptionAmtTot = 0;
  let otherContributionTot = 0;
  let loanTakenAmtTotPri = 0;
  let loanTakenAmtTotInt = 0;
  let loanTakenAmtTot = 0;
  let otherLoanTakenAmtTot = 0;
  let otherLoanTakenPrincipal = 0;
  let otherLoanTakenIntrest = 0;
  let indSubsidyDistributedTot = 0;
  let batchOtherIncomeTot = 0;
  let membershipFeesTot = 0;
  let memSeedMoneyTot = 0;
  let savingWithdrawalsTot = 0;
  let dividendDistributedTot = 0;
  let securityDepositTot = 0;

  const sumOf = (value) => {
    try {
      return Number(value.cash || 0) + Number(value.bankAmt || 0);
    } catch (er) {
      return 11;
    }
  };

  return (
    <div>
      <div className="body-inner no-padding table-responsive table-container">
        <table
          className="table table-bordered table-striped table-hover"
          id="datatable2"
        >
          <thead>
            <tr>
              <th>SL No</th>
              <th className="sticky-column" style={{ zIndex: "999" }}>
                Members Name
              </th>
              <th>Saving</th>
              <th>IL Sanctioned Pri Amount</th>
              <th>IL Sanctioned Int Amount</th>
              <th>IL Principal Paid</th>
              <th>IL Interest Paid</th>
              <th>OL Sanctioned Principal</th>
              <th>OL Sanctioned Interest</th>
              <th>OL Principal Paid</th>
              <th>OL Interest Paid</th>
              <th>Subscription Amt</th>
              <th>Other Contribution</th>
              <th>Other Income</th>
              <th>Membership Fees</th>
              <th>Seed Money</th>
              <th>Saving Withdrawal</th>
              <th>Dividend Distributed</th>
              <th>Security Deposit</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {membersMeetingData &&
              membersMeetingData.map((memTransactionData, idx) => {
                savingAmtTot += sumOf(memTransactionData.savingAmt);

                loanTakenAmtTotPri += memTransactionData.internalLoan.reduce(
                  (acu, ele) =>
                    (acu +=
                      Number(ele.loanPayablePrincipal.cash) +
                      Number(ele.loanPayablePrincipal.bankAmt)),
                  0
                );

                loanTakenAmtTotInt += memTransactionData.internalLoan.reduce(
                  (acu, ele) =>
                    (acu +=
                      Number(ele.loanPayableInterest.cash) +
                      Number(ele.loanPayableInterest.bankAmt)),
                  0
                );
                loanTakenAmtTot += sumOf(memTransactionData.loanTakenAmt);
                //internal paid

                loanPaidTot += memTransactionData.internalRepayment.reduce(
                  (acu, cur) => (acu += sumOf(cur.internalPrincipalPaid)),
                  0
                );

                interestPaidTot += memTransactionData.internalRepayment.reduce(
                  (acu, cur) => (acu += sumOf(cur.internalIntrestPaid)),
                  0
                );

                otherLoanPaidTot += memTransactionData.otherLoan.reduce(
                  (acu, cur) =>
                    (acu += Number(
                      Number(cur.otherLoanPaid.cash) +
                        Number(cur.otherLoanPaid.bankAmt)
                    )),
                  0
                );
                otherInterestPaidTot += memTransactionData.otherLoan.reduce(
                  (acu, cur) =>
                    (acu += Number(
                      Number(cur.otherInterestPaid.cash) +
                        Number(cur.otherInterestPaid.bankAmt)
                    )),
                  0
                );
                subscriptionAmtTot += sumOf(memTransactionData.subscriptionAmt);
                otherContributionTot += sumOf(
                  memTransactionData.otherContribution
                );

                // otherLoanTakenAmtTot += memTransactionData.otherLoanTakenAmt;
                otherLoanTakenPrincipal += memTransactionData.otherLoan.reduce(
                  (acu, cur) =>
                    (acu += Number(
                      Number(cur.otherloanPayablePrincipal.cash) +
                        Number(cur.otherloanPayablePrincipal.bankAmt)
                    )),
                  0
                );
                otherLoanTakenIntrest += memTransactionData.otherLoan.reduce(
                  (acu, cur) =>
                    (acu += Number(
                      Number(cur.otherloanPayableInterst.cash) +
                        Number(cur.otherloanPayableInterst.bankAmt)
                    )),
                  0
                );

                // otherLoanTakenPrincipal += memTransactionData.otherLoan.reduce(
                //   (acu, cur) => (acu += cur.otherloanPayablePrincipal),
                //   0
                // );
                // otherLoanTakenIntrest += memTransactionData.otherLoan.reduce(
                //   (acu, cur) => (acu += cur.otherloanPayableInterst),
                //   0
                // );
                // indSubsidyDistributedTot +=
                //   memTransactionData.subsidyDistributed;
                batchOtherIncomeTot += sumOf(memTransactionData.otherIncome);
                membershipFeesTot += sumOf(memTransactionData.membershipFees);
                memSeedMoneyTot += sumOf(memTransactionData.memSeedMoney);
                savingWithdrawalsTot += sumOf(
                  memTransactionData.savingWithdrawals
                );
                dividendDistributedTot += sumOf(
                  memTransactionData.dividendDistributed
                );
                securityDepositTot += sumOf(memTransactionData.securityDeposit);

                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td className="sticky-column">
                      {memTransactionData.memberName}
                    </td>
                    <td>{sumOf(memTransactionData.savingAmt)}</td>
                    <td>
                      {memTransactionData.internalLoan.reduce(
                        (acu, ele) =>
                          (acu +=
                            Number(ele.loanPayablePrincipal.cash) +
                            Number(ele.loanPayablePrincipal.bankAmt)),
                        0
                      )}
                    </td>
                    <td>
                      {memTransactionData.internalLoan.reduce(
                        (acu, ele) =>
                          (acu +=
                            Number(ele.loanPayableInterest.cash) +
                            Number(ele.loanPayableInterest.bankAmt)),
                        0
                      )}
                    </td>
                    {/* <td>{sumOf(memTransactionData.loanTakenAmt)}</td> */}
                    {/* <td>{sumOf(memTransactionData.loanPaid)}</td> */}
                    <td>
                      {memTransactionData.internalRepayment.reduce(
                        (acu, cur) => (acu += sumOf(cur.internalPrincipalPaid)),
                        0
                      )}
                    </td>
                    {/* <td>{sumOf(memTransactionData.interestPaid)}</td> */}{" "}
                    <td>
                      {memTransactionData.internalRepayment.reduce(
                        (acu, cur) => (acu += sumOf(cur.internalIntrestPaid)),
                        0
                      )}
                    </td>
                    <td>
                      {memTransactionData.otherLoan.reduce(
                        (acu, cur) =>
                          (acu += Number(
                            Number(cur.otherloanPayablePrincipal.cash) +
                              Number(cur.otherloanPayablePrincipal.bankAmt)
                          )),
                        0
                      )}
                    </td>
                    <td>
                      {memTransactionData.otherLoan.reduce(
                        (acu, cur) =>
                          (acu += Number(
                            Number(cur.otherloanPayableInterst.cash) +
                              Number(cur.otherloanPayableInterst.bankAmt)
                          )),
                        0
                      )}
                    </td>
                    <td>
                      {memTransactionData.otherLoan.reduce(
                        (acu, cur) =>
                          (acu += Number(
                            Number(cur.otherLoanPaid.cash) +
                              Number(cur.otherLoanPaid.bankAmt)
                          )),
                        0
                      )}
                      {/* {memTransactionData.otherLoanPaid || 0} */}
                    </td>
                    <td>
                      {memTransactionData.otherLoan.reduce(
                        (acu, cur) =>
                          (acu += Number(
                            Number(cur.otherInterestPaid.cash) +
                              Number(cur.otherInterestPaid.bankAmt)
                          )),
                        0
                      )}
                      {/* {memTransactionData.otherInterestPaid || 0} */}
                    </td>
                    <td>{sumOf(memTransactionData.subscriptionAmt)}</td>
                    <td>{sumOf(memTransactionData.otherContribution)}</td>
                    <td>{sumOf(memTransactionData.otherIncome)}</td>
                    <td>{sumOf(memTransactionData.membershipFees)}</td>
                    <td>{sumOf(memTransactionData.memSeedMoney)}</td>
                    <td>{sumOf(memTransactionData.savingWithdrawals)}</td>
                    <td>{sumOf(memTransactionData.dividendDistributed)}</td>
                    <td>{sumOf(memTransactionData.securityDeposit)}</td>
                    <td>
                      {batchTransactionData &&
                      batchTransactionData.verifiedById ? (
                        <>
                          {allRights &&
                          allRights.includes(
                            user.userGroupName + "EditReport"
                          ) ? (
                            <>
                              <img
                                className="img_icon_size log"
                                onClick={() =>
                                  onOpenEditModal(memTransactionData, idx)
                                }
                                src={require("../../../static/images/edit_icon.png")}
                                alt="Edit"
                                title="Edit"
                              />
                            </>
                          ) : (
                            <>Not editable</>
                          )}
                        </>
                      ) : (
                        <>
                          <img
                            className="img_icon_size log"
                            onClick={() =>
                              onOpenEditModal(memTransactionData, idx)
                            }
                            src={require("../../../static/images/edit_icon.png")}
                            alt="Edit"
                            title="Edit"
                          />
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td></td>
              <td className="sticky-column">
                <b>TOTAL</b>
              </td>
              <td>{savingAmtTot}</td>
              <td>{loanTakenAmtTotPri}</td>
              <td>{loanTakenAmtTotInt}</td>
              <td>{loanPaidTot || 0}</td>
              <td>{interestPaidTot || 0}</td>
              <td>{otherLoanTakenPrincipal}</td>
              <td>{otherLoanTakenIntrest}</td>
              <td>{otherLoanPaidTot || 0}</td>
              <td>{otherInterestPaidTot || 0}</td>
              <td>{subscriptionAmtTot || 0}</td>
              <td>{otherContributionTot || 0}</td>
              <td>{batchOtherIncomeTot || 0}</td>
              <td>{membershipFeesTot || 0}</td>
              <td>{memSeedMoneyTot || 0}</td>
              <td>{savingWithdrawalsTot || 0}</td>
              <td>{dividendDistributedTot || 0}</td>
              <td>{securityDepositTot || 0}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllMemberTab;
