import axios from "axios";

import {
  AUTH_ERROR,
  ALL_PROGRAMS_DETAILS,
  ALL_ACTIVE_PROGRAMS_DETAILS,
  ALL_SKILL_REPORT_DETAILS,
  ALL_SKILL_DETAILS,
  SHG_MEMBERS_LIST,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
} from "./types";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
var linkPath = process.env.REACT_APP_BASE_URL;
// var linkPath = "";

export const getbatchShgMembers = (finalData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/skill/get-all-shg-members`,
      finalData
    );
    // localStorage.setItem("activeMembersLocal", JSON.stringify(res.data));
    dispatch({
      type: SHG_MEMBERS_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getSkillDetails = (finalData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/skill/get-skill-details`,
      finalData
    );
    // localStorage.setItem("allDesignationData", JSON.stringify(res.data));
    dispatch({
      type: ALL_SKILL_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddSkillProgramDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/skill/add-skill-program`,
      finalData,
      config
    );

    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getALLProgramDetails = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${linkPath}/api/skill/get-all-program-details`
    );
    // localStorage.setItem("allDesignationData", JSON.stringify(res.data));
    dispatch({
      type: ALL_PROGRAMS_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddProgramDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/skill/add-program`, finalData, config);
    dispatch(getALLProgramDetails());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editProgramDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(`${linkPath}/api/skill/edit-program`, finalData);
    dispatch(getALLProgramDetails());

    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
export const programDeactive = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/skill/deactive-program-data`,
      finalData,
      config
    );
    dispatch(getALLProgramDetails());

    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getActivePrograms = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${linkPath}/api/skill/get-all-active-program-details`
    );
    // localStorage.setItem("allDesignationData", JSON.stringify(res.data));
    dispatch({
      type: ALL_ACTIVE_PROGRAMS_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getALLSkillReport = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/skill/get-all-skill-details`,
      changeData
    );
    // localStorage.setItem("allDesignationData", JSON.stringify(res.data));
    dispatch({
      type: ALL_SKILL_REPORT_DETAILS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const EditSkillProgramDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/skill/edit-skill-program-detail`,
      finalData
    );
    dispatch(getALLSkillReport());
    // dispatch(getSkillDetails());
    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const skillTrainingDeactive = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/skill/deactive-skill-training-data`,
      finalData,
      config
    );
    dispatch(getALLSkillReport());

    dispatch({
      type: SET_LOADING_FALSE,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
