import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  getAllPartialMeetings,
  getAllPartialMeetingsDropdown,
  batchAndMeetingDetails,
  getAllMembersOfParticularBatch,
  getAllPartialMeetingFilter,
} from "../../actions/shg";
import Spinner from "../layout/Spinner";
import Pagination from "../layout/Pagination";

const AllPartialMeetings = ({
  auth: { allUser, isAuthenticated, user, users },
  shg: { partialmeeting, partialmeetingdropdown },
  getAllPartialMeetings,
  getAllPartialMeetingsDropdown,
  batchAndMeetingDetails,
  getAllMembersOfParticularBatch,
  getAllPartialMeetingFilter,
}) => {
  let selParMeetingDetails = JSON.parse(
    localStorage.getItem("selParMeetingDetails")
  );
  useEffect(() => {
    getAllPartialMeetings(selParMeetingDetails);
  }, [getAllPartialMeetings]);
  useEffect(() => {
    getAllPartialMeetingsDropdown();
  }, [getAllPartialMeetingsDropdown]);

  //pagination code
  const [currentData, setCurrentData] = useState(1);
  const [dataPerPage] = useState(10);
  //Get Current Data
  const indexOfLastData = currentData * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentDatas =
    partialmeeting && partialmeeting.slice(indexOfFirstData, indexOfLastData);
  //change page
  const paginate = (nmbr) => {
    setCurrentData(nmbr);
  };
  //pagination code ends

  const onpartialmeetings = (partialmeeting) => {
    const finalData = {
      batchIdVal: partialmeeting.output._id,
      mdDate: partialmeeting.mdDate,
      mdBatchId: partialmeeting.output._id,
      callFrom: "PartialMeeting",
      batchNameVal: partialmeeting.output.batchName,
    };
    batchAndMeetingDetails(finalData);
    getAllMembersOfParticularBatch(finalData);
  };

  const onClickReset = () => {
    setCurrentData(1);
    getbatchsData("");
    getAllPartialMeetings("");
  };

  const allbatchesList = [];
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();

  partialmeetingdropdown.map((batchs) =>
    allbatchesList.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState(
    selParMeetingDetails &&
      selParMeetingDetails.selectedBatchId && {
        batchId: selParMeetingDetails.selectedBatchId,
        label: selParMeetingDetails.batchName,
        value: selParMeetingDetails.batchName,
      }
  );

  const onBatchChange = (e) => {
    setCurrentData(1);
    var batchId = "";
    var batchName = "";
    getbatchsData(e);

    batchId = e.batchId;
    batchName = e.value;

    setbatchId(batchId);
    setbatchName(batchName);
    const changeData = {
      selectedBatchId: batchId,
      batchName: batchName,
    };

    getAllPartialMeetings(changeData);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">All Partial Meetings </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-3 col-md-3 col-sm-7 col-7 mt-1">
              <Select
                name="batchName"
                options={allbatchesList}
                isSearchable={true}
                value={batchs}
                placeholder="Select SHG"
                onChange={(e) => onBatchChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>

            <div className="col-lg-8 col-md-2 col-sm-4 col-4 py-3">
              <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>

              <img
                className="img_icon_size log float-right"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>SHG Name</th>
                        <th>SHG Code</th>
                        <th>Partially Submitted Meetings</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentDatas &&
                        currentDatas.map((partialmeeting, idx) => {
                          var ED = partialmeeting.mdDate.split(/\D/g);
                          var mdDate = [ED[2], ED[1], ED[0]].join("-");
                          return (
<tr key={idx}>
                            {idx ===0 ?(<>
                              <td>
                                <Link
                                  to="/add-membermeeting-details"
                                  className="btnLink"
                                  onClick={() =>
                                    onpartialmeetings(partialmeeting, idx)
                                  }
                                >
                                  {partialmeeting.output.batchName}
                                </Link>
                              </td>
                              <td>{partialmeeting.output.batchCode}</td>
                              <td>{mdDate}</td>
                              </>):(<>

                                <td>
                                {/* <Link
                                  to="/add-membermeeting-details"
                                  className="btnLink"
                                  onClick={() =>
                                    onpartialmeetings(partialmeeting, idx)
                                  }
                                >
                                  
                                </Link> */}
                                {partialmeeting.output.batchName}
                              </td>
                              <td>{partialmeeting.output.batchCode}</td>
                              <td>{mdDate}</td>

                                </>)}

                              
                             
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
                    {partialmeeting && partialmeeting.length !== 0 ? (
                      <Pagination
                        dataPerPage={dataPerPage}
                        totalData={partialmeeting.length}
                        paginate={paginate}
                        currentPage={currentData}
                      />
                    ) : (
                      <Fragment />
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 align_right">
                    <label>
                      {" "}
                      No. of Partial meetings : {partialmeeting.length}
                    </label>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

AllPartialMeetings.propTypes = {
  auth: PropTypes.object.isRequired,
  getAllPartialMeetings: PropTypes.func.isRequired,
  getAllPartialMeetingsDropdown: PropTypes.func.isRequired,
  batchAndMeetingDetails: PropTypes.func.isRequired,
  getAllMembersOfParticularBatch: PropTypes.func.isRequired,
  getAllPartialMeetingFilter: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getAllPartialMeetings,
  getAllPartialMeetingsDropdown,
  batchAndMeetingDetails,
  getAllMembersOfParticularBatch,
  getAllPartialMeetingFilter,
})(AllPartialMeetings);
