import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { AddBlock, getBlockDistrict } from "../../actions/area";
import Spinner from "../layout/Spinner";

const AddBlockDetails = ({
  auth: { isAuthenticated, user, users, loading },
  area: { blockDistrict },
  onAddDistrictModalChange,
  AddBlock,
  getBlockDistrict,
}) => {
  useEffect(() => {
    getBlockDistrict();
  }, [getBlockDistrict]);

  //formData
  const [formData, setFormData] = useState({
    blockName: "",
    isSubmitted: false,
  });

  const { blockName } = formData;
  const alldistricts = [];
  blockDistrict.map((district) =>
    alldistricts.push({
      districtId: district._id,
      label: district.districtName,
      value: district.districtName,
    })
  );

  const [district, getDistrictData] = useState();
  const [districtId, setStateID] = useState();

  const onStateChange = (e) => {
    //Required Validation Starts
    setError({
      ...error,
      sIdChecker: true,
      sIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    var districtId = "";
    getDistrictData(e);
    districtId = e.districtId;
    setStateID(districtId);
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //Required Validation Starts
  const [error, setError] = useState({
    sIdChecker: false,
    sIdErrorStyle: {},
  });
  const { sIdChecker, sIdErrorStyle } = error;

  const checkErrors = () => {
    if (!sIdChecker) {
      setError({
        ...error,
        sIdErrorStyle: { color: "#F00" },
      });
      return false;
    }
    return true;
  };
  //Required Validation ends
  const onSubmit = (e) => {
    e.preventDefault();
    //Required Validation Starts
    if (checkErrors()) {
      const finalData = {
        blockName: blockName,
        districtId: districtId,
        blockEnteredById: user._id,
        blockEnteredByName: user.userName,
        blockEnteredDateTime: new Date().toLocaleString("en-GB"),
        institutionId: user.institutionId,
        userData: user,
      };
      AddBlock(finalData);

      setFormData({
        ...formData,
        blockName: "",
        isSubmitted: true,
      });
      getDistrictData("");
    }
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control"> Block Name* :</label>
              <input
                type="text"
                name="blockName"
                value={blockName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control" style={sIdErrorStyle}>
                District* :
              </label>
              <Select
                name="districtName"
                options={alldistricts}
                isSearchable={true}
                value={district}
                placeholder="Select District"
                onChange={(e) => onStateChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
          </div>

          <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

AddBlockDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  AddBlock: PropTypes.func.isRequired,
  getBlockDistrict: PropTypes.func.isRequired,
};

const mapStateToProps = (district) => ({
  auth: district.auth,
  area: district.area,
});

export default connect(mapStateToProps, {
  getBlockDistrict,
  AddBlock,
})(AddBlockDetails);
