import React from "react";
import { Link } from "react-router-dom";
const kannada = () => {
  return (
    <>
      <div
        className="container container_align "
        style={{ marginLeft: "12px" }}
      >
        <div className=" row col-lg-12 col-md-11 col-sm-12 col-12  ">
          <div className="col-lg-9 col-md-11 col-sm-12 col-12">
            {" "}
            <h3 className="heading_color1">
              {" "}
              SHG ತರಬೇತಿ ವೀಡಿಯೊಗಳು
              <h6>( SHG Training Videos)</h6>{" "}
            </h3>
          </div>
          <div className="col-lg-2 col-md-11 col-sm-12 col-12 d-flex justify-content-end align-items-center ">
            {" "}
            <Link to="/shgLangList">
              <img
                className="img_icon_size log float-right ml-3"
                src={require("../../static/images/back.png")}
                alt="Back"
                title="Back"
              />
            </Link>
          </div>
        </div>
        <hr style={{ width: "98%", marginLeft: "-0.5%" }} />

        <section className="row scstyle">
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>
                <Link to="/kannadaIntro">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/introKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="Introduction to SHG Application">
                    SHG ಅಪ್ಲಿಕೇಶನ್‌ನ ಪರಿಚಯ
                  </h6>
                </Link>
              </center>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>
                <Link to="/kannadaCreateBatch">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/createBatchKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Create a Batch?">
                    SHG ಗುಂಪುಗಳನ್ನು ಹೇಗೆ ರಚಿಸುವುದು?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>
                <Link to="/kannadaAddMember">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/addMemKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to add the Members?">
                    SHG ಸದಸ್ಯರನ್ನು ಹೇಗೆ ಸೇರಿಸುವುದು?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>
                <Link to="/kannadaCreateUser">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/createUserKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Create Users?">
                    ಬಳಕೆದಾರರನ್ನು ಹೇಗೆ ರಚಿಸುವುದು?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
        </section>
        <section className="row mt-5 scstyle">
          {" "}
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>
                <Link to="/kannadaDeactiveMember">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/deactiveMemKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Deactivate a Member?">
                    SHG ಸದಸ್ಯರನ್ನು ಹೇಗೆ <br></br>ನಿಷ್ಕ್ರಿಯಗೊಳಿಸುವುದು?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>
                <Link to="/kannadaCreateArea">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/createAreaKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Create Area?">
                    ಪ್ರದೇಶದ ವಿವರಗಳನ್ನು ಹೇಗೆ <br></br>ರಚಿಸುವುದು?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>
                <Link to="/kannadaVerifyShgMeet">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/verifyShgKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Verify SHG Meetings?">
                    SHG ಸಭೆಯನ್ನು ಹೇಗೆ <br></br>ಪರಿಶೀಲಿಸುವುದು?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>
                <Link to="/kannadaCreateMeeting">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/createMeetKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="	How to Create a Meeting?">
                    ಆರಂಭಿಕ ಸಭೆಯ ಪ್ರವೇಶವನ್ನು ಹೇಗೆ ರಚಿಸುವುದು?{" "}
                  </h6>
                </Link>
              </center>
            </div>
          </div>
        </section>
        <section className="row mt-5 scstyle">
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-3">
              <center>
                <Link to="/kannadaAddRegMeeting">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/addRegMeetKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="	How to add the Regular Meeting?">
                    SHG ನಿಯಮಿತ ಸಭೆಯನ್ನು ಹೇಗೆ<br></br> ಸೇರಿಸುವುದು?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-3">
              <center>
                <Link to="/kannadaAddOLTypes">
                  <div class="image-container">
                    <img
                      className="ReportImg log"
                      src={require("../../static/images/olcatKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to add Other Loan Types & SHG Category?">
                    ಹೊರ ಸಾಲಗಳ ಹೆಸರುಗಳನ್ನು ಮತ್ತು SHG ವರ್ಗಗಳನ್ನು ಹೇಗೆ ಸೇರಿಸುವುದು?
                  </h6>
                </Link>
              </center>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-3">
              <center>
                <Link to="/kannadaUpdateShgMeetVid">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/upShgMeetKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Update SHG Meeting After Partial Meetings Steps?">
                    ಭಾಗಶಃ ಸಭೆಯ ಹಂತಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ ನಂತರ SHG ಸಭೆಯನ್ನು ಹೇಗೆ
                    ನಡೆಸುವುದು.?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content pb-3">
              <center>
                <Link to="/kannadaCheckShgMeetRep">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/checkMeetRepKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Check the Meeting in the Report Section?">
                    SHG ಸಭೆಯ ವರದಿ ಪಟ್ಟಿಯನ್ನು ಹೇಗೆ ಪರಿಶೀಲಿಸುವುದು?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
        </section>

        <section className="row mt-5 scstyle">
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>
                <Link to="/kannadaUpdatePartialMeetVid">
                  <div class="image-container">
                    <img
                      className="ReportImg log "
                      src={require("../../static/images/upParMeetKan.jpg")}
                      alt="Kannada"
                      title="Kannada"
                    />
                    <div class="play-button">
                      <i class="fa fa-play"></i>
                    </div>
                  </div>
                  <h6 title="How to Update The Partial SHG Meeting?">
                    ಭಾಗಶಃ ಸಭೆ ಹೇಗೆ ನಡೆಸುವುದು?
                  </h6>
                </Link>
              </center>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default kannada;
