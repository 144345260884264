import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { editStateDetails } from "../../actions/area";
import Spinner from "../layout/Spinner";

const EditStateDetails = ({
  savedMessage,
  auth: { isAuthenticated, user, users, loading },
  states,
  editStateDetails,
}) => {
  //formData
  const [formData, setFormData] = useState({
    stateName: states && states.stateName ? states.stateName : "",

    isSubmitted: false,
  });
  const { stateName } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onUpdate = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: states ? states._id : "",
      stateName: stateName,
      stateEnteredById: user._id,
      stateEnteredByName: user.userName,
      institutionId: user.institutionId,
    };
    editStateDetails(finalData);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onUpdate(e)}>
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <label className="label-control"> State Name* :</label>
              <input
                type="text"
                name="stateName"
                value={stateName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
          </div>

          <div className="col-md-10 col-lg-10 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Update"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditStateDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  editStateDetails: PropTypes.func.isRequired,
  savedMessage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  savedMessage: state.auth.savedMessage,
});

export default connect(mapStateToProps, {
  editStateDetails,
})(EditStateDetails);
