import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deactiveShgCategory } from "../../actions/shg";

const DeactiveShgCategories = ({
  auth: { isAuthenticated, user, users },
  allShgCategories,
  deactiveShgCategory,
  onDeactiveModalChange,
}) => {
  const [formData, setFormData] = useState({
    occcupationDeactiveReason: "",
    isSubmitted: false,
  });

  const { occcupationDeactiveReason } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: allShgCategories ? allShgCategories._id : "",
      shgCategoryStatus: "Deactive",
      shgCategoryDeactiveReason: occcupationDeactiveReason,
      shgCategoryDeactiveById: user && user._id,
      shgCategoryDeactiveByName: user && user.userName,
      shgCategoryDeactiveDateTime: new Date().toLocaleString("en-GB"),
    };
    deactiveShgCategory(finalData);
    onDeactiveModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div className="col-lg-12 col-md-9 col-sm-9 col-12 ">
        <form className="row" onSubmit={(e) => onSubmit(e)}>
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
            <div className="col-lg-12 col-md-4 col-sm-4 col-12">
              <label>Reason For Deactivation* :</label>
            </div>
            <div className="col-lg-12  col-md-4 col-sm-4 col-12">
              <textarea
                name="occcupationDeactiveReason"
                id="shgCategoryDeactiveReason"
                className="textarea form-control"
                rows="3"
                value={occcupationDeactiveReason}
                placeholder="Deactive Reason"
                onChange={(e) => onInputChange(e)}
                style={{ width: "100%" }}
                required
              ></textarea>
            </div>
            <div className="col-lg-12 col-md-4 col-sm-4 col-12 py-2">
              <label>Are You Sure You Want to Deactivate ??</label>
            </div>
            <div
              className="col-lg-12 col-md-9 col-sm-9 col-12 Savebutton"
              size="lg"
            >
              <input
                type="submit"
                name="Save"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

DeactiveShgCategories.propTypes = {
  auth: PropTypes.object.isRequired,
  deactiveShgCategory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  deactiveShgCategory,
})(DeactiveShgCategories);
