import React, { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getBatchwiseReport,
  getBatchOfParish,
  getAllBatchesDropdown,
  batchAndMeetingDetails,
  getBatchOfDistrict,
  getActiveShgCategory,
} from "../../actions/shg";
import {
  getVarados,
  getParish,
  getStates,
  getDistrict,
  getBlockDetails,
} from "../../actions/area";
import Spinner from "../layout/Spinner";
import { CSVLink } from "react-csv";

const ShgCategorywiseReport = ({
  auth: { isAuthenticated, user, users },
  shg: { batchwisereport, batchwisereportSum, activeShgCategories,principal_interest_sum,other_interest_sum },
  getActiveShgCategory,
  getBatchwiseReport,
}) => {
  useEffect(() => {
    getBatchwiseReport();
  }, [getBatchwiseReport]);
  useEffect(() => {
    getActiveShgCategory();
  }, [getActiveShgCategory]);
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const [showHide, setShowHide] = useState({
    showBatchSection:
      loggedUserInstitutionData &&
      loggedUserInstitutionData.institutionType === "Zoned"
        ? true
        : false,
  });
  const { showBatchSection } = showHide;

  const onClickReset = () => {
    //123
    getShgcategoryData("");
    getBatchwiseReport("");
  };

  const allSHGCategory = [];
  activeShgCategories.map((shgcategory) =>
    allSHGCategory.push({
      shgCategoryId: shgcategory._id,
      label: shgcategory.shgCategoryName,
      value: shgcategory.shgCategoryName,
    })
  );
  const outstandingAmt = (sanAmt, paidAmt) => {
    return Number(sanAmt) - Number(paidAmt);
  };

  const [shgcategory, getShgcategoryData] = useState();
  const [shgCategoryId, setShgCategoryID] = useState();
  const [shgCategoryName, setShgCategoryName] = useState();

  const onShgcategoryChange = (e) => {
    getShgcategoryData(e);
    setShgCategoryID(e.shgCategoryId ? e.shgCategoryId : null);
    setShgCategoryName(e.value ? e.value : "");
    const changeData = {
      shgCategoryIdVal: e.shgCategoryId,
    };
    getBatchwiseReport(changeData);
  };

  let totILOutStanding = batchwisereport.reduce(
    (acu, cur) => (acu += outstandingAmt(cur.batchLoanAmt, cur.totIntLoanPaid)),
    0
  );

  let totOLOutStanding = batchwisereport.reduce(
    (acu, cur) =>
      (acu += outstandingAmt(
        cur.otherLoanSanctionedToMember,
        cur.totOthLoanPaid
      )),
    0
  );

  const csvData = [
    [
      "SHG Name",
      "SHG Code",
      "Parish",
      "Total Saving",
      "Total Internal Loan Amount Given",
      "Total Other Loan Amount Given",
      "Outstanding Internal Loan Amount",
      "Outstanding Other Loan Amount",
      "Total No. of Meetings Conducted",
    ],
  ];
  batchwisereport.map((batchwisereport) => {
    csvData.push([
      batchwisereport.batchName,
      batchwisereport.batchCode,
      batchwisereport.parishName,
      batchwisereport.batchSavingAmt,
      batchwisereport.batchLoanAmt,
      batchwisereport.otherLoanSanctionedToMember,
      batchwisereport.OutstandingBatchLoanAmt,
      batchwisereport.OutstandingBatchOtherLoanAmt,
      batchwisereport.batchMeetingHeldOnDate,
    ]);
  });
  if (batchwisereportSum && batchwisereportSum[0]) {
    csvData.push([
      "Total",
      "",
      "",
      batchwisereportSum[0].batchSavingAmt,

      batchwisereportSum[0].batchLoanAmt,

      batchwisereportSum[0].otherLoanSanctionedToMember,

      batchwisereportSum[0].OutstandingBatchLoanAmt,

      batchwisereportSum[0].OutstandingBatchOtherLoanAmt,
    ]);
  }
  const csvFreeZonedData = [
    [
      "SHG Name",
      "SHG Code",
      "Saving",
      "Internal Loan Amount Given",
      "Other Loan Amount Given",
      "Outstanding Internal Loan Amount",
      "Outstanding Other Loan Amount",
      "Total No. of Meetings Conducted",
    ],
  ];
  let totbatchSavingAmt = 0;
  let totbatchLoanAmt = 0;
  let tototherLoanSanctionedToMember = 0;
  let totOutstandingBatchLoanAmt = 0;
  let totOutstandingBatchOtherLoanAmt = 0;
  batchwisereport.map((batchwisereport) => {
    totbatchSavingAmt += batchwisereport.batchSavingAmt;
    totbatchLoanAmt += batchwisereport.batchLoanAmt;
    tototherLoanSanctionedToMember +=
      batchwisereport.otherLoanSanctionedToMember;
    totOutstandingBatchLoanAmt += outstandingAmt(
      batchwisereport.batchLoanAmt,
      batchwisereport.totIntLoanPaid
    );
    totOutstandingBatchOtherLoanAmt += outstandingAmt(
      batchwisereport.otherLoanSanctionedToMember,
      batchwisereport.totOthLoanPaid
    );
    csvFreeZonedData.push([
      batchwisereport.batchName,
      batchwisereport.batchCode,
      batchwisereport.batchSavingAmt,
      batchwisereport.batchLoanAmt,
      batchwisereport.otherLoanSanctionedToMember,
      outstandingAmt(
        batchwisereport.batchLoanAmt,
        batchwisereport.totIntLoanPaid
      ),
      outstandingAmt(
        batchwisereport.otherLoanSanctionedToMember,
        batchwisereport.totOthLoanPaid
      ),
      batchwisereport.batchMeetingHeldOnDate,
    ]);
  });
  if (batchwisereportSum && batchwisereportSum[0]) {
    csvFreeZonedData.push([
      "Total",
      "",
      // batchwisereportSum[0].batchSavingAmt,
      totbatchSavingAmt,
      // batchwisereportSum[0].batchLoanAmt,
      totbatchLoanAmt,

      // batchwisereportSum[0].otherLoanSanctionedToMember,
      tototherLoanSanctionedToMember,

      // batchwisereportSum[0].OutstandingBatchLoanAmt,
      totOutstandingBatchLoanAmt,

      // batchwisereportSum[0].OutstandingBatchOtherLoanAmt,
      totOutstandingBatchOtherLoanAmt,
    ]);
  }
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );


 
  const get_the_Interest=(allData,loanType)=>{
    try {

      if(loanType === "internal"){
        let interest =   principal_interest_sum.find((ele)=>ele._id == allData)

        if(!interest.internalInterestReceivable){
        }
        return interest.internalInterestReceivable
      }else{

        let interest =   other_interest_sum.find((ele)=>ele._id == allData)

        if(!interest.otherInterestReceivable){
        }
        return interest.otherInterestReceivable
      }
    } catch (error) {
     return 0
    }
  }


  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color">SHG Categorywise Report </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-11 col-11 no_padding">
            <div className="col-lg-3 col-md-4 col-sm-6 col-11 mt-1">
              <Select
                name="shgcategory"
                options={allSHGCategory}
                isSearchable={false}
                value={shgcategory}
                placeholder="Select Category"
                onChange={(e) => onShgcategoryChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
            <div className="col-lg-8 col-md-3 col-sm-4 col-11 py-3">
              <Link to="/all-reports">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <img
                className="img_icon_size log float-right ml-3"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
              {loggedUserInstitutionData.institutionType === "Zoned" ? (
                <CSVLink data={csvData}>
                  <img
                    className="img_icon_size log float-right ml-4"
                    src={require("../../static/images/excel_icon.png")}
                    alt="Excel-Export"
                    title="Excel-Export"
                  />
                  {/* <button className="btn btn_green_bg log float-right ">
                    Export
                  </button> */}
                </CSVLink>
              ) : (
                <CSVLink data={csvFreeZonedData}>
                  <img
                    className="img_icon_size log float-right ml-4"
                    src={require("../../static/images/excel_icon.png")}
                    alt="Excel-Export"
                    title="Excel-Export"
                  />
                  {/* <button className="btn btn_green_bg log float-right ml-3">
                    Export
                  </button> */}
                </CSVLink>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                      <th className="sticky-column" style={{ zIndex: "999" }}>
                          SHG Name
                        </th>
                        <th>SHG Code</th>
                        {showBatchSection && (
                          <>
                            <th>Parish</th>
                          </>
                        )}
                        <th title="Total Savings">Savings</th>
                        <th title="Total Internal Loan Amount Given">
                          Internal Loan Given (P)
                        </th>
                        <th>Internal Loan Interest Receivable (I)</th>
                        <th title="Internal Loan Paid">Internal Loan Paid (P)</th>
                        <th title="Internal Loan Paid">Internal Loan Paid (I)</th>
                        <th title="Outstanding Internal Loan Amount">
                          O/S Internal Loan Principal{" "}
                        </th>
                        <th title="Outstanding Internal Loan Amount">
                          O/S Internal Loan Interest{" "}
                        </th>
                        <th title="Total Other Loan Amount Given">
                          Other Loan Given (P)
                        </th>
                        <th title="Total Other Loan Amount Given">
                          Other Loan Given (I)
                        </th>
                        <th title="Total Other Loan Amount Given">
                          Other Loan Paid (P)
                        </th>
                        <th title="Total Other Loan Amount Given">
                          Other Loan Interest Receivable (I)
                        </th>
                        {/* <th title=" Other Loan Paid">Other Loan Paid </th> */}
                        <th title="Outstanding Other Loan Amount">
                          O/S Other Loan  (P)
                        </th>
                        <th title="Outstanding Other Loan Amount">
                          O/S Other Loan (I)
                        </th>
                       
                        <th>Meetings Conducted</th>
                      </tr>
                    </thead>
                    <tbody>
                      {batchwisereportSum &&
                        batchwisereportSum.map((batchReport, idx) => {
                        
                         return (
                            
                            <tr key={idx} className="b">
                              <td colSpan={2}>
                                <b>Total</b>
                              </td>
                              {showBatchSection && (
                                <>
                                  <td></td>
                                </>
                              )}
                              <td>{batchReport.batchSavingAmt}</td>
                              <td>{batchReport.batchLoanAmt}</td>  {/* */}
                              <td>{batchwisereport.reduce((acu, cur) =>(acu += get_the_Interest(cur._id,"internal")),0).toFixed(2)}
                                </td>
                              <td>{batchwisereport.reduce((acu,curr)=>(acu += curr.totalInternalPrincipalPaid ),0).toFixed(2)}</td>
                              <td>{batchwisereport.reduce((acu,curr)=>(acu += curr.totalInternalInterestPaid ),0).toFixed(2)}</td>
                              <td>{batchwisereport.reduce((acu,curr)=>(acu += curr.outstandingInternalPricipal ),0).toFixed(2)}</td>
                              <td>{batchwisereport.reduce((acu, cur) =>(acu += get_the_Interest(cur._id,"internal")),0).toFixed(2)
                                   - 
                                  batchwisereport.reduce((acu,curr)=>(acu += curr.totalInternalInterestPaid ),0).toFixed(2)}</td>
                               <td>{batchwisereport.reduce((acu,curr)=>(acu += curr.otherLoanSanctionedToShg ),0).toFixed(2)}</td>
                               <td>{batchwisereport.reduce((acu,curr)=>(acu += curr.batchOtherLoanInterestShg ),0).toFixed(2)}</td>   
                               <td>{batchwisereport.reduce((acu,curr)=>(acu += curr.otherLoanPaidToShg ),0).toFixed(2)}</td>
                               <td>{batchwisereport.reduce((acu, cur) =>(acu += get_the_Interest(cur._id,"other")),0).toFixed(2)}</td>
                               <td>{batchwisereport.reduce((acu,curr)=>(acu += curr.outstandingOtherPricipal ),0).toFixed(2)  }</td>
                               <td>{batchwisereport.reduce((acu, cur) =>(acu += get_the_Interest(cur._id,"other")),0).toFixed(2) 
                                    - 
                                    batchwisereport.reduce((acu,curr)=>(acu += curr.batchOtherLoanInterestShg ),0).toFixed(2) }</td>
                              <td>{batchwisereport.reduce((acu,curr)=>(acu += curr.batchMeetingHeldOnDate ),0).toFixed(2)}</td>
                            </tr>
                          );
                        })}
                      {batchwisereport &&
                        batchwisereport.map((batchReport, idx) => {
                         
                          return (
                            <tr key={idx}>
                              <td className="sticky-column">{batchReport.batchName}</td>
                              <td>{batchReport.batchCode}</td>
                              {showBatchSection && (
                                <>
                                  <td>{batchReport.parishName}</td>
                                </>
                              )}
                              <td>{batchReport.batchSavingAmt}</td>
                              <td>{batchReport.batchLoanAmt}</td>
                              <td>{ get_the_Interest(batchReport._id,"internal")}</td>
                              {/* <td>{batchReport.totIntLoanPaid}</td> */}
                              <td>{batchReport.totalInternalPrincipalPaid}</td>
                              <td>{batchReport.totalInternalInterestPaid}</td>
                              <td>{batchReport.outstandingInternalPricipal}</td>
                              <td>{get_the_Interest(batchReport._id,"internal") - batchReport.totalInternalInterestPaid}</td>
                              <td>{batchReport.otherLoanSanctionedToShg}</td>
                              <td>{batchReport.batchOtherLoanInterestShg}</td>
                              <td>{batchReport.otherLoanPaidToShg}</td>
                              <td>{get_the_Interest(batchReport._id,"other")}</td>
                              <td>{batchReport.outstandingOtherPricipal}</td>
                              <td>{get_the_Interest(batchReport._id,"other") - batchReport.batchOtherLoanInterestShg }</td>
                              {/* <td>{Number(batchReport.totOthLoanPaid).toFixed(2)}</td> */}
                              {/* <td>{outstandingAmt(batchReport.batchLoanAmt,batchReport.totIntLoanPaid)}</td> */}
                              {/* <td>
                                {outstandingAmt(
                                  batchReport.otherLoanSanctionedToMember,
                                  batchReport.totOthLoanPaid
                                )}
                              </td> */}
                              <td>{batchReport.batchMeetingHeldOnDate}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
            <div className="col-lg-11 col-md-6 col-sm-11 col-11 align_right">
              <label>
                <b>No. of SHGs : {batchwisereport.length}</b>
              </label>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

ShgCategorywiseReport.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  getActiveShgCategory: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
});

export default connect(mapStateToProps, {
  getBatchwiseReport,
  getVarados,
  getParish,
  getBatchOfParish,
  getAllBatchesDropdown,
  batchAndMeetingDetails,
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getBlockDetails,
  getActiveShgCategory,
})(ShgCategorywiseReport);
