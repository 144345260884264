import React from "react";
import { Link } from "react-router-dom";
const shgLangList = () => {
  return (
    <>
      <div className=" container_align ">
        <div className="col-lg-12 col-md-11 col-sm-12 col-12 ">
          <h2 className="heading_color "> Select Language </h2>
          <hr style={{ width: "93%", marginRight: "15%" }} />
        </div>

        <section className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content ">
              <center>
                <Link to="/kannada">
                  <img
                    className="ReportImg log mt-3"
                    src={require("../../static/images/videoicon.png")}
                    alt="kannada"
                    title="Kannada"
                  />
                  <h4>Kannada </h4>
                </Link>
              </center>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="card card-content">
              <center>
                <Link to="/tamil">
                  <img
                    className="ReportImg log mt-3"
                    src={require("../../static/images/videoicon.png")}
                    alt="tamil"
                    title="tamil"
                  />
                  <h4>Tamil </h4>
                </Link>
              </center>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default shgLangList;
