import axios from "axios";

import {
  AUTH_ERROR,
  SAVED,
  RELIGIONS,
  ALL_RELIGION,
  ALL_CASTE,
  ALL_SUBCASTE,
  CASTE,
  SUBCASTE_CASTE,
  GET_SUBCASTE,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
} from "./types";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

var linkPath = process.env.REACT_APP_BASE_URL;
// var linkPath = "";

//ADD

export const AddReligion = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    const res = await axios.post(
      `${linkPath}/api/religionSetting/add-religion`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllReligion(reqData));
    dispatch({
      type: SAVED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddCaste = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/religionSetting/add-caste`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllCaste(reqData));
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddSubcaste = (finalData) => async (dispatch) => {
  const reqData = {
    userInfo: finalData.userData,
  };
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/religionSetting/add-Subcaste`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllSubcaste());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//EDIT

export const editSubcasteDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/religionSetting/edit-subcaste`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllSubcaste());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editReligionDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/religionSetting/edit-religion`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllReligion());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editCasteDetails = (finalData) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_TRUE,
    });
    await axios.post(
      `${linkPath}/api/religionSetting/edit-caste`,
      finalData,
      config
    );
    dispatch({
      type: SET_LOADING_FALSE,
    });
    dispatch(getAllCaste());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//FOR Dropdown
export const getReligions = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/religionSetting/get-religion`
    );
    localStorage.setItem("selReligionData", JSON.stringify(res.data));
    dispatch({
      type: RELIGIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//For all Views

export const getAllReligion = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/religionSetting/get-all-religion`,
      changeData
    );
    dispatch({
      type: ALL_RELIGION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllCaste = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/religionSetting/get-all-caste`,
      changeData
    );
    dispatch({
      type: ALL_CASTE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getCaste = (religionVal) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/religionSetting/get-caste`,
      religionVal
    );
    localStorage.setItem("activeCasteLocal", JSON.stringify(res.data));
    dispatch({
      type: CASTE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getSubcasteDetails = (casteVal) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/religionSetting/get-subcaste`,
      casteVal
    );
    localStorage.setItem("activeSubcasteLocal", JSON.stringify(res.data));
    dispatch({
      type: GET_SUBCASTE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getSubcasteCaste = (casteVal) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/religionSetting/get-subcaste-caste`,
      casteVal
    );
    localStorage.setItem("selCasteData", JSON.stringify(res.data));
    dispatch({
      type: SUBCASTE_CASTE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getAllSubcaste = (changeData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/religionSetting/get-all-subcaste`,
      changeData
    );
    dispatch({
      type: ALL_SUBCASTE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
