import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";

import area from "./area";
import shg from "./shg";
import user from "./user";
import setting from "./setting";
import skill from "./skill";
import religionSetting from "./religionSetting";

export default combineReducers({
  alert,
  auth,
  setting,
  area,
  shg,
  user,
  skill,
  religionSetting,
});
