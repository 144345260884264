import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { getBlockDistrict, editBlockDetails } from "../../actions/area";
import Spinner from "../layout/Spinner";

const EditBlockDetails = ({
  auth: { isAuthenticated, user, users, loading },
  getBlockDistrict,
  editBlockDetails,
  blocks,
}) => {
  useEffect(() => {
    getBlockDistrict();
  }, [getBlockDistrict]);

  //formData
  const [formData, setFormData] = useState({
    blockName: blocks && blocks.blockName ? blocks.blockName : "",
    isSubmitted: false,
  });

  const { blockName } = formData;
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const alldistricts = [];
  let selDistrictData = JSON.parse(localStorage.getItem("selDistrictData"));
  selDistrictData &&
    selDistrictData.map((district) => {
      alldistricts.push({
        districtId: district._id,
        label: district.districtName,
        value: district.districtName,
      });
    });

  const [district, setDistrictData] = useState("");

  if (!district && alldistricts.length !== 0) {
    setDistrictData(
      blocks
        ? alldistricts.length !== 0
          ? alldistricts &&
            alldistricts.filter((x) => x.districtId === blocks.districtId)[0]
          : ""
        : ""
    );
  }
  const [districtId, setDistrictID] = useState(blocks.districtId);

  const onDistrictChange = (e) => {
    var districtId = "";
    setDistrictData(e);
    districtId = e.districtId;
    setDistrictID(districtId);
  };

  const onUpdate = (blocks, idx) => {
    const finalData = {
      recordId: blocks ? blocks._id : "",
      blockName: blockName,
      districtId: districtId,
      blockEnteredById: user._id,
      blockEnteredByName: user.userName,
      institutionId: user.institutionId,
    };
    editBlockDetails(finalData);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <label className="label-control"> Block Name* :</label>
            <input
              type="text"
              name="blockName"
              value={blockName}
              className="form-control"
              onChange={(e) => onInputChange(e)}
              required
            />
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <label className="label-control">District* :</label>

            <Select
              name="districtName"
              options={alldistricts}
              isSearchable={true}
              value={district}
              placeholder="Select District"
              onChange={(e) => onDistrictChange(e)}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
        </div>

        <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
          {loading ? (
            <button
              className="btn sub_form btn_continue Save float-right"
              disabled
            >
              Loading...
            </button>
          ) : (
            <button
              variant="success"
              className="btn sub_form btn_continue Save float-right"
              onClick={() => onUpdate(blocks)}
              style={
                blockName !== ""
                  ? { opacity: "1" }
                  : { opacity: "1", pointerEvents: "none" }
              }
            >
              Update
            </button>
          )}
        </div>
      </div>
    </Fragment>
  );
};

EditBlockDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  editBlockDetails: PropTypes.func.isRequired,
  getBlockDistrict: PropTypes.func.isRequired,
};

const mapStateToProps = (district) => ({
  auth: district.auth,
  area: district.area,
});

export default connect(mapStateToProps, {
  getBlockDistrict,
  editBlockDetails,
})(EditBlockDetails);
