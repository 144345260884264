import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { editProgramDetails } from "../../actions/skill";
import Spinner from "../layout/Spinner";
import Select from "react-select";
import { getAllBatches, getAllBatchesDropdown } from "../../actions/shg";
import {
  EditSkillProgramDetails,
  getbatchShgMembers,
  getSkillDetails,
  getActivePrograms,
} from "../../actions/skill";

const EditSkillTrainingDetail = ({
  skillData,
  onUpdateModalChange,
  auth: { isAuthenticated, user, users, loading },
  shg: { allbatchesdropdown },
  skill: { activeprogram, shgMembersList },
  getbatchShgMembers,
  getAllBatchesDropdown,
  getActivePrograms,
  getAllBatches,
  getSkillDetails,
  EditSkillProgramDetails,
}) => {
  useEffect(() => {
    getActivePrograms();
  }, [getActivePrograms]);

  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);

  useEffect(() => {
    getbatchShgMembers({ batchId: skillData.batchId ? skillData.batchId : "" });
  }, [getbatchShgMembers]);

  useEffect(() => {
    getAllBatches();
  }, [getAllBatches]);

  //formData
  const [formData, setFormData] = useState({
    programDescription:
      skillData && skillData.programDescription
        ? skillData.programDescription
        : "",
    village: skillData && skillData.village ? skillData.village : "",
    trainingTime:
      skillData && skillData.trainingTime ? skillData.trainingTime : "",
    trainingDescription:
      skillData && skillData.trainingDescription
        ? skillData.trainingDescription
        : "",

    isSubmitted: false,
  });
  const {
    village,
    programDescription,
    trainingTime,
    trainingDescription,
  } = formData;

  const [trainingDate, settrainingDate] = useState(skillData.trainingDate);

  const onDateChange = (e) => {
    settrainingDate(e.target.value);
  };
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const allbatchesList = [];
  allbatchesdropdown.map((batchs) =>
    allbatchesList.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState(
    skillData
      ? allbatchesList &&
          allbatchesList.filter((x) => x.batchId === skillData.batchId)[0]
      : ""
  );
  const [batchId, setbatchId] = useState(skillData.batchId);
  const [batchName, setbatchName] = useState(skillData.batchName);
  const onBatchChange = (e) => {
    getmembersData("");
    var batchId = "";
    var batchName = "";
    getbatchsData(e);
    batchId = e.batchId;
    batchName = e.value;
    setbatchId(batchId);
    setbatchName(batchName);

    const finalData = {
      batchId: batchId,
      batchName: batchName,
    };

    getbatchShgMembers(finalData);
  };
  //member

  const allmemberList = [];
  shgMembersList.map((members) =>
    allmemberList.push({
      membersId: members._id,
      memberDesg: members.memberDesg,
      label: members.memberName,
      value: members.memberName,
    })
  );

  const [members, getmembersData] = useState(
    skillData
      ? allmemberList &&
          allmemberList.filter((x) => x.membersId === skillData.memberId)[0]
      : ""
  );
  const [membersId, setmembersId] = useState(skillData.memberId);
  const [memberName, setmembersName] = useState(skillData.memberName);
  const [memberDesg, setmemberDesg] = useState(skillData.memberDesg);

  const onmembersChange = (e) => {
    var membersId = "";
    var memberName = "";
    var memberDesg = "";
    getmembersData(e);
    membersId = e.membersId;
    memberName = e.value;
    memberDesg = e.memberDesg;
    setmembersId(membersId);
    setmembersName(memberName);
    setmemberDesg(memberDesg);
  };

  const allProgramList = [];
  activeprogram.map((program) =>
    allProgramList.push({
      programId: program._id,
      label: program.programName,
      value: program.programName,
    })
  );

  const [program, getprogramData] = useState(
    skillData
      ? allProgramList &&
          allProgramList.filter((x) => x.programId === skillData.programId)[0]
      : ""
  );
  const [programId, setprogramId] = useState(skillData.programId);
  const [programName, setprogramName] = useState(skillData.programName);
  const onprogramChange = (e) => {
    var programId = "";
    var programName = "";
    getprogramData(e);
    programId = e.programId;
    programName = e.value;
    setprogramId(programId);
    setprogramName(programName);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: skillData ? skillData._id : "",
      batchId: batchId,
      batchName: batchName,
      village: village,
      memberId: membersId,
      memberDesg: memberDesg,
      memberName: memberName,
      trainingDescription: trainingDescription,
      trainingDate: trainingDate,
      trainingTime: trainingTime,
      programId: programId,
      programName: programName,
      trainingEditedById: user._id,
      trainingEditedDateTime: new Date().toLocaleString("en-GB"),
    };

    EditSkillProgramDetails(finalData);
    getSkillDetails(finalData);
    onUpdateModalChange(true);
    setFormData({
      ...formData,
      programName: "",
      programDescription: "",
      isSubmitted: true,
    });
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
              <label className="label-control">Training Date* :</label>
              <br />
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="trainingDate"
                value={trainingDate}
                onChange={(e) => onDateChange(e)}
                style={{
                  width: "100%",
                }}
                required
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <label className="label-control">Training Type:</label>
              <Select
                name="programName"
                options={allProgramList}
                isSearchable={true}
                value={program}
                placeholder="Select Program"
                onChange={(e) => onprogramChange(e)}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <label className="label-control">Batch :</label>
              <Select
                name="batchName"
                options={allbatchesList}
                isSearchable={true}
                value={batchs}
                placeholder="Select SHG"
                onChange={(e) => onBatchChange(e)}
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <label className="label-control">Member :</label>
              <Select
                name="memberName"
                options={allmemberList}
                isSearchable={true}
                value={members}
                placeholder="Select Members"
                onChange={(e) => onmembersChange(e)}
              />
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
              <label className="label-control">Village :</label>
              <input
                type="text"
                name="village"
                value={village}
                className="form-control"
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <label className="label-control"> Member Role :</label>
              <input
                type="text"
                name="memberDesg"
                value={memberDesg}
                className="form-control"
                disabled
              />
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-12 py-2">
              <label className="label-control">Training Time:</label>
              <input
                type="time"
                className="form-control"
                onChange={(e) => onInputChange(e)}
                name="trainingTime"
                value={trainingTime}
                min="00:00"
                max="24:00"

                // required
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <label className="label-control">Remarks:</label>
              <textarea
                name="trainingDescription"
                id="trainingDescription"
                className="textarea form-control"
                rows="4"
                placeholder="Instructions"
                style={{ width: "100%" }}
                value={trainingDescription}
                onChange={(e) => onInputChange(e)}
                required
              ></textarea>
            </div>
          </div>

          <div className="col-md-10 col-lg-12 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Save"
                value="Update"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditSkillTrainingDetail.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  skill: PropTypes.object.isRequired,
  EditSkillProgramDetails: PropTypes.func.isRequired,
  getAllBatchesDropdown: PropTypes.func.isRequired,
  getActivePrograms: PropTypes.func.isRequired,
  getAllBatches: PropTypes.func.isRequired,
  getbatchShgMembers: PropTypes.func.isRequired,
  getSkillDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
  skill: state.skill,
});

export default connect(mapStateToProps, {
  getAllBatches,
  getAllBatchesDropdown,
  getActivePrograms,
  getbatchShgMembers,
  EditSkillProgramDetails,
  getSkillDetails,
})(EditSkillTrainingDetail);
