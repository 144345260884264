import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  checkMeetingDate,
  removeMessage,
  UpdateMeetingStatus,
  getEditMemberMeetingData,
  batchAndMeetingDetails,
  getAllMembersOfParticularBatch,
  getMeetingSummary,
  getActiveBankData,
} from "../../actions/shg";
import AddMembermeetingDetailModal from "./AddMembermeetingDetailModal";
import EditMemberModel from "./memberDetModel/EditMemberModel";
import Spinner from "../layout/Spinner";
import EditMembermeetingDetailModal from "./EditMembermeetingDetailModal";
import AddMembermeetingOpModal from "./AddMembermeetingOpModal";
import EditmemberMeetingopBalModal from "./EditmemberMeetingopBalModal";
import AddMemberModel from "./memberDetModel/AddMemberModel";

const AddMemberMeetingDetails = ({
  auth: { isAuthenticated, user, users, loading },
  shg: {
    membersOfBatch,
    meetingResponse,
    summaryData,
    memberClosingAmt,
    summaryDataOp,
    activeBankData,
  },
  checkMeetingDate,
  removeMessage,
  UpdateMeetingStatus,
  getEditMemberMeetingData,
  batchAndMeetingDetails,
  getAllMembersOfParticularBatch,
  getMeetingSummary,
  getActiveBankData,
}) => {
  let bankOpeningBalanceAmount =
    summaryDataOp && summaryDataOp[0] && summaryDataOp[0].batchBankDeposit;

  useEffect(() => {
    removeMessage();
  }, [removeMessage]);
  useEffect(() => {
    getMeetingSummary();
  }, [getMeetingSummary]);

  // const [showCheckMeeting, setshowCheckMeeting] = useState(false);
  // const handleLogoutModalClose = () => setshowCheckMeeting(false);
  // const handleLogoutModalShow = () => setshowCheckMeeting(true);
  // const checkmeeting = () => {
  //   if (selectedDate && selectedDate > lastMeetingDateYMD) {
  //     const finalData = {
  //       mdDate: selectedDate,
  //       mdBatchId: batchIdValue.batchIdVal,
  //     };
  //     checkMeetingDate(finalData);
  //     getMeetingSummary(finalData);
  //     setOldCheck(false);
  //   } else {
  //     setOldCheck(true);
  //   }
  //   handleLogoutModalClose();
  // };
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  let lastMeetingData = JSON.parse(localStorage.getItem("lastMeeingDate"));
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  const onMemberMeetingModalChange = (e) => {
    if (e) {
      handleMemberMeetingModalClose();
    }
  };
  useEffect(() => {
    getActiveBankData(batchIdValue);
  }, []);

  const [userData, setUserData] = useState(null);
  const [showMemberMeetingDetailsModal, setMemberMeetingDetailModal] = useState(
    false
  );
  const [impMemdata, setImpmemData] = useState(null);

  const handleMemberMeetingModalClose = () => {
    setMemberMeetingDetailModal(false);
  };

  const onOpenModal = (membersBatch, idx) => {
    setImpmemData({
      memberId: membersBatch._id,
      meetingHeldOnDate: selDate
        .split("-")
        .reverse("")
        .join("-"),
      batchId: membersBatch.batchId,
      memberName: membersBatch.memberName,
    });

    if (lastMeetingData && lastMeetingData.length === 0) {
      // setMemberMeetingOPModal(true);FLAG REQ
      setMemberMeetingDetailModal(true);
    } else {
      setMemberMeetingDetailModal(true);
    }

    setUserData(membersBatch);
  };

  //meeting modal
  const [showCheckMeeting, setshowCheckMeeting] = useState(false);
  const handleLogoutModalClose = () => setshowCheckMeeting(false);
  const handleLogoutModalShow = () => setshowCheckMeeting(true);
  const [showCheck, setShowCheck] = useState(false);

  const [addMettingDisabled, setAddMettingDisabled] = useState(false);

  const checkmeeting = async () => {
    if (selectedDate && selectedDate > lastMeetingDateYMD) {
      const finalData = {
        mdDate: selectedDate,
        mdBatchId: batchIdValue.batchIdVal,
      };
      await checkMeetingDate(finalData);
      getMeetingSummary(finalData);
      setOldCheck(false);
    } else {
      setOldCheck(true);
    }
    setShowCheck(true);
    handleLogoutModalClose();
  };

  const [oldCheck, setOldCheck] = useState(false);
  const oncheck = () => {
    handleLogoutModalShow();
  };

  const onMemberMeetingModalChanges = (e) => {
    setMemberMeetingDetailModals(false);
    // if (e) {
    // handleMemberMeetingModalCloses();
    // }
  };
  //start
  const [showMemberMeetingOPModal, setMemberMeetingOPModal] = useState(false);
  const handleMemberMeetingOPModalClose = () => setMemberMeetingOPModal(false);

  const onOpenOPModal = (membersBatch, idx) => {
    setMemberMeetingOPModal(true);
    setUserData(membersBatch);
  };

  const onMemberMeetingOPModalChanges = (e) => {
    if (e) {
      handleMemberMeetingOPModalClose();
    }
  };

  const [showMemberMeetingEditOPModal, setMemberMeetingEditOPModal] = useState(
    false
  );
  const handleMemberMeetingEditOPModalClose = () =>
    setMemberMeetingEditOPModal(false);

  const onOpenEditOPModal = (membersBatch, idx) => {
    setMemberMeetingEditOPModal(true);
    setUserData(membersBatch);
  };

  const onMemberMeetingEditOPModalChanges = (e) => {
    if (e) {
      handleMemberMeetingEditOPModalClose();
    }
  };
  //end

  const [userDatas, setUserDatas] = useState(null);
  const [userData2, setUserData2] = useState(null);
  const [
    showMemberMeetingDetailsModals,
    setMemberMeetingDetailModals,
  ] = useState(false);

  const handleMemberMeetingModalCloses = () =>
    setMemberMeetingDetailModals(false);

  const onOpenEditModal = (memberBatch, idx) => {
    setImpmemData({
      memberId: memberBatch._id,
      meetingHeldOnDate: memberBatch.meetingHeldOnDate,
      batchId: memberBatch.batchId,
      memberName: memberBatch.memberName,
    });

    localStorage.removeItem("memberEditData");
    const membersBatch = {
      meetingHeldOnDate: memberBatch.meetingHeldOnDate,
      memberDesg: memberBatch.memberDesg,
      memberName: memberBatch.memberName,
      memberId: memberBatch._id,
    };
    const meetingInfo = {
      mdDate: selectedDate,
      mdBatchId: batchIdValue.batchIdVal,
      memberId: memberBatch._id,
      meetingDate: batchIdValue.mdDate,
    };
    getEditMemberMeetingData(meetingInfo);

    if (lastMeetingData && lastMeetingData.length === 0) {
      setMemberMeetingEditOPModal(true);
    } else {
      setMemberMeetingDetailModals(true);
    }
    setUserDatas(membersBatch);
    setUserData2(meetingInfo);
  };
  const [selectedDate, setSelectedDate] = useState("");

  const onDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  if (batchIdValue && batchIdValue.mdDate && selectedDate === "") {
    setSelectedDate(batchIdValue.mdDate);
  }

  // const [oldCheck, setOldCheck] = useState(false);
  // const oncheck = () => {
  //   if (selectedDate && selectedDate > lastMeetingDateYMD) {
  //     const finalData = {
  //       mdDate: selectedDate,
  //       mdBatchId: batchIdValue.batchIdVal,
  //     };
  //     checkMeetingDate(finalData);
  //     getMeetingSummary(finalData);
  //     setOldCheck(false);
  //   } else {
  //     setOldCheck(true);
  //   }
  // };

  const onpartialmeetings = () => {
    const finalData = {
      batchIdVal: batchIdValue.batchIdVal,
      mdDate: selectedDate,
      mdBatchId: batchIdValue.batchIdVal,
      callFrom: "PartialMeeting",
    };
    batchAndMeetingDetails(finalData);

    getAllMembersOfParticularBatch(finalData);
  };
  const activeBank = [];
  activeBankData &&
    activeBankData.map((bank) =>
      activeBank.push({
        bankId: bank._id,
        label: bank.batchBankName,
        value: bank.batchBankName,
      })
    );

  const onClickReset = () => {
    const finalData = {
      mdDate: selectedDate,
      mdBatchId: batchIdValue.batchIdVal,
    };
    getAllMembersOfParticularBatch(finalData);
  };

  var lastMeetingDate = "";
  var lastMeetingDateYMD = "";
  if (lastMeetingData && lastMeetingData.length !== 0) {
    var LMD =
      lastMeetingData &&
      lastMeetingData[0] &&
      lastMeetingData[0].mdDate.split(/\D/g);
    lastMeetingDate = [LMD[2], LMD[1], LMD[0]].join("-");
    lastMeetingDateYMD =
      lastMeetingData && lastMeetingData[0] && lastMeetingData[0].mdDate;
  }
  const [isSubmitted, setSubmitted] = useState(false);
  const onSubmit = () => {
    const finalData = {
      mdDate: selectedDate,
      mdBatchId: batchIdValue.batchIdVal,
      mdStatus: "Completed",
      mdBatchName: batchIdValue.batchNameVal,
    };
    UpdateMeetingStatus(finalData);
    setSubmitted(true);
  };
  let meetingDone = 0;
  var ED = selectedDate.split(/\D/g);
  var selDate = [ED[2], ED[1], ED[0]].join("-");

  const getTomorrowDate = (currentDate) => {
    try {
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().split("T")[0];
    } catch (er) {
      const numberOfDays = 2000; //average 5 years from the date of entry
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() - numberOfDays);
      return tomorrow.toISOString().split("T")[0];
    }
  };

  if (isSubmitted) {
    return <Redirect to="/memberwise-batchdetails-meeting" />;
  }

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">
            Add Member's Meeting Details for {batchIdValue.batchNameVal}
          </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-12 col-md-11 col-sm-11 col-11 py-2">
              <Link
                to={{
                  pathname:
                    batchIdValue && batchIdValue.callFrom === "PartialMeeting"
                      ? "/all-partial-meetings"
                      : "/landing-page",
                }}
              >
                <img
                  className="img_icon_size log float-right"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <img
                className="img_icon_size log float-right mr-3"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
            </div>

            <div className="col-lg-10 col-md-11 col-sm-11 col-11">
              <h5 className="heading_color">
                {lastMeetingDate == selDate
                  ? ""
                  : `Last Meeting Held on : ${lastMeetingDate}`}
              </h5>
            </div>
          </div>
          {showCheck ? (
            <> </>
          ) : (
            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding">
              <div className="col-lg-3 col-md-4 col-sm-11 col-11">
                <h5 className="heading_color">
                  {lastMeetingData && lastMeetingData.length === 0
                    ? "Opening Balance On : "
                    : "Meeting Held On : "}
                  {batchIdValue.callFrom === "PartialMeeting" && selDate}
                </h5>
              </div>

              {batchIdValue.callFrom !== "PartialMeeting" && (
                <div className="row col-lg-7 col-md-8 col-sm-12 col-12">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-8">
                    <input
                      type="date"
                      placeholder="dd/mm/yyyy"
                      className="form-control cpp-input datevalidation"
                      name="selectedDate"
                      value={selectedDate}
                      min={getTomorrowDate(lastMeetingDateYMD)}
                      onChange={(e) => onDateChange(e)}
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                    {loading ? (
                      <button className="btn sub_form btn_continue1" disabled>
                        Loading...
                      </button>
                    ) : (
                      <>
                        <>
                          {lastMeetingData &&
                          lastMeetingData.length !== 0 &&
                          lastMeetingData[0].mdStatus != "Verified" ? (
                            <span className="text-danger">
                              Please Verify the last meeting
                            </span>
                          ) : (
                            <>
                              <button
                                className="btn sub_form btn_continue1"
                                onClick={() => oncheck()}
                                style={
                                  selectedDate !== ""
                                    ? { opacity: "1" }
                                    : { opacity: "1", pointerEvents: "none" }
                                }
                              >
                                Check
                              </button>
                            </>
                          )}
                        </>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className="col-lg-12 col-md-12 col-sm-11 col-11">
                {meetingResponse &&
                  meetingResponse.msg &&
                  batchIdValue.callFrom !== "PartialMeeting" && (
                    <>
                      <p style={{ color: "red" }}>{meetingResponse.msg}</p>
                      {meetingResponse &&
                      meetingResponse.mdStatus === "Created" ? (
                        <Link
                          to="/add-membermeeting-details"
                          className="btnLink"
                          onClick={() => onpartialmeetings()}
                        >
                          click here to continue
                        </Link>
                      ) : (
                        <p></p>
                      )}
                      {selectedDate && selectedDate < lastMeetingDateYMD && (
                        <p style={{ color: "red" }}>
                          Date selected cannot be less than last meeting date
                        </p>
                      )}
                    </>
                  )}
                {oldCheck ? (
                  <p style={{ color: "red" }}>
                    Date selected cannot be less than last meeting date
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-6 col-md-12 col-sm-11 col-11">
                {meetingResponse &&
                  meetingResponse.msg &&
                  batchIdValue.callFrom !== "PartialMeeting" && <></>}
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-lg-5 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className="body-inner no-padding  table-responsive fixTableHead">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>SL No</th>
                        <th>SHG Members</th>
                        <th>Members Designation</th>
                        <th>Edit</th>
                      </tr>
                    </thead>

                    {(meetingResponse &&
                      meetingResponse.saveStatus !== "Fail" &&
                      selectedDate > lastMeetingDateYMD) ||
                    (batchIdValue &&
                      batchIdValue.callFrom === "PartialMeeting") ? (
                      <tbody>
                        {membersOfBatch &&
                          membersOfBatch.map((memberBatch, idx) => {
                            if (memberBatch.meetingHeldOnDate.length > 0)
                              meetingDone += 1;
                            return (
                              <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>
                                  {memberBatch.meetingHeldOnDate.length > 0 ? (
                                    <span
                                      style={{
                                        color: "red",
                                        pointerEvents: "none",
                                      }}
                                    >
                                      {memberBatch.memberName}
                                    </span>
                                  ) : (
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        onOpenModal(memberBatch, idx)
                                      }
                                    >
                                      <span>{memberBatch.memberName}</span>
                                    </Link>
                                  )}
                                </td>
                                <td>{memberBatch.memberDesg}</td>
                                <td>
                                  {memberBatch &&
                                  memberBatch.meetingHeldOnDate &&
                                  memberBatch.meetingHeldOnDate.length > 0 &&
                                  lastMeetingData &&
                                  lastMeetingData.length !== 0 ? (
                                    <span>
                                      <img
                                        className="img_icon_size log"
                                        onClick={() =>
                                          onOpenEditModal(memberBatch, idx)
                                        }
                                        src={require("../../static/images/edit_icon.png")}
                                        alt="Edit"
                                        title="Edit"
                                      />
                                    </span>
                                  ) : (
                                    <Link to="#">
                                      <span></span>
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    ) : (
                      <Fragment />
                    )}
                  </table>
                </div>
                <>
                  <div className="col-lg-3 col-md-11 col-sm-11 col-11 ">
                    <button
                      variant="success"
                      className="btn sub_form btn_continue blackbrd Save"
                      onClick={() => onSubmit()}
                      style={
                        meetingDone === membersOfBatch.length &&
                        membersOfBatch.length !== 0
                          ? { opacity: "1" }
                          : { opacity: "1", pointerEvents: "none" }
                      }
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 col-11 ">
                    <label className="label-control colorRed">
                      *Enter all Members Details to Submit Meeting
                    </label>
                  </div>
                </>
              </section>
            </div>
          </div>
        </section>
        <Modal
          show={showMemberMeetingDetailsModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center my-1">
                Add{" "}
                {lastMeetingData && lastMeetingData.length === 0
                  ? " Opening"
                  : ""}{" "}
                Meeting Details
              </h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleMemberMeetingModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            {/* <AddMembermeetingDetailModal
              membersBatch={userData}
              selectedDate={selectedDate}
              onMemberMeetingModalChange={onMemberMeetingModalChange}
            /> */}
            <AddMemberModel
              memberData={impMemdata}
              allPermission={allPermission}
              activeBank={activeBank}
              setMemberMeetingDetailModal={setMemberMeetingDetailModal}
              getAllMembersOfParticularBatch={getAllMembersOfParticularBatch}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showMemberMeetingDetailsModals}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Edit Meeting Details</h3>
            </div>
            <div className="col-lg-2">
              <button
                onClick={handleMemberMeetingModalCloses}
                className="close"
              >
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            {/* flag req */}

            <EditMemberModel
              memberData={impMemdata}
              allPermission={allPermission}
              activeBank={activeBank}
              handleMemberMeetingModalCloses={onMemberMeetingModalChanges}
            />
          </Modal.Body>
        </Modal>

        {/* //for opening Balance */}
        <Modal
          show={showMemberMeetingOPModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Add Opening Details</h3>
            </div>
            <div className="col-lg-2">
              <button
                onClick={handleMemberMeetingOPModalClose}
                className="close"
              >
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <AddMembermeetingOpModal
              membersBatch={userData}
              selectedDate={selectedDate}
              onMemberMeetingOPModalChanges={onMemberMeetingOPModalChanges}
            />
          </Modal.Body>
        </Modal>

        {/* //for Edit opening Balance */}
        <Modal
          show={showMemberMeetingEditOPModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Edit Opening Details</h3>
            </div>
            <div className="col-lg-2">
              <button
                onClick={handleMemberMeetingEditOPModalClose}
                className="close"
              >
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditmemberMeetingopBalModal
              membersBatch={userData}
              selectedDate={selectedDate}
              onMemberMeetingEditOPModalChanges={
                onMemberMeetingEditOPModalChanges
              }
            />
          </Modal.Body>
        </Modal>

        {/* check meeting date  */}
        <Modal
          show={showCheckMeeting}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="logout-modal"
        >
          <Modal.Header className="confirmbox-heading">
            <h4 className="mt-0">Confirmation</h4>
          </Modal.Header>
          <Modal.Body>
            <h5>
              Are you sure to confirm the meeting for the date{" "}
              {selectedDate.slice(8, 10) +
                "-" +
                selectedDate.slice(5, 7) +
                "-" +
                selectedDate.slice(0, 4)}
              ?
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn_green_bg"
              onClick={() => {
                setAddMettingDisabled(true);
                checkmeeting();
              }}
              disabled={addMettingDisabled}
            >
              YES
            </button>
            <button
              className="btn btn_green_bg"
              onClick={() => handleLogoutModalClose(false)}
            >
              NO
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};

AddMemberMeetingDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  removeMessage: PropTypes.func.isRequired,
  checkMeetingDate: PropTypes.func.isRequired,
  UpdateMeetingStatus: PropTypes.func.isRequired,
  getMeetingSummary: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  removeMessage,
  checkMeetingDate,
  UpdateMeetingStatus,
  getEditMemberMeetingData,
  batchAndMeetingDetails,
  getAllMembersOfParticularBatch,
  getMeetingSummary,
  getActiveBankData,
})(AddMemberMeetingDetails);
