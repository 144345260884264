import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import Popup from "./Popup";
import { FileEarmarkPdf } from "react-bootstrap-icons";
import { BrowserView, MobileView } from "react-device-detect";

const PdfPreview = ({
  auth: { isAuthenticated, user, users },
  baseData,
  meetData,
}) => {
  let fileVal = "";
  if (meetData) {
    fileVal =
      meetData.batchName +
      "_" +
      meetData.mdDate
        .split("-")
        .reverse()
        .join("-");
  }
  const [popup, setPopup] = useState(false);
  const [url, setUrl] = useState("");
  const filename = fileVal || "Meeting Document.pdf";

  // Replace PDF in this following variable using Data URI format for Base64
  const base64Data = baseData && baseData.split(",")[1];
  const handleClick = () => {
    const binaryString = atob(base64Data);
    const pdfBlob = new Blob([binaryString], { type: "application/pdf" });
    const url = URL.createObjectURL(pdfBlob);
    setUrl(url);
    setPopup(true);
  };

  const handleDownload = () => {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <BrowserView>
        <span
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            padding: "1em 1.5em",
            background: "#D62917",
            color: "white",
            border: "none",
          }}
          onClick={handleClick}
        >
          <FileEarmarkPdf size="2rem" />
          <p
            style={{
              paddingLeft: "0.5em",
              fontSize: "1rem",
              fontWeight: 600,
            }}
          >
            Preview PDF
          </p>
        </span>
      </BrowserView>

      <MobileView>
        <span
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            padding: "1em 1.5em",
            background: "#D62917",
            color: "white",
            border: "none",
          }}
          onClick={handleDownload}
        >
          <FileEarmarkPdf size="2rem" />
          <p
            style={{
              paddingLeft: "0.5em",
              fontSize: "1rem",
              fontWeight: 600,
            }}
          >
            Download PDF
          </p>
        </span>
      </MobileView>
      {popup && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <BrowserView>
            <Popup
              setPopup={setPopup}
              base64Data={base64Data}
              url={url}
              filenameVal={filename}
            />
          </BrowserView>
        </div>
      )}
    </Fragment>
  );
};

PdfPreview.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(PdfPreview);
