import React, { Fragment, useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadUser } from "../../actions/auth";
import spinner from "../../static/gif/LoadingRnd.gif";

const RouteDriver = ({ auth: { user, otpAuthenticated }, loadUser }) => {
  useEffect(() => {
    loadUser();
  }, [loadUser]);

  if (user !== "" && !otpAuthenticated) {
    return <Redirect to="/landing-page" />;
  } else if (user !== "" && otpAuthenticated) {
    return <Redirect to="/change-password" />;
  }
  return (
    <Fragment>
      <div className="container container_align">
        <div id="page-loader" className="text-light">
          <div className="page-loader-inner">
            <div className="loader-logo-name">
              <img src={spinner} alt="Loading..." />
              <h2>Loading...</h2>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

RouteDriver.propTypes = {
  auth: PropTypes.object.isRequired,
  loadUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loadUser })(RouteDriver);
