import React, { useState, Fragment } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { recheckMeetingStatus } from "../../actions/shg";
const RecheckReasonModal = ({
  auth: { isAuthenticated, user, users, loading },
  show,
  handleModalClose,
  record,
  recheckMeetingStatus,
}) => {
  const [formData, setFormData] = useState({
    RecheckReason: "",
    isSubmitted: false,
  });
  const { RecheckReason, isSubmitted } = formData;
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      mdDate: record.mdDate,
      mdBatchId: record.mdBatchId,
      mdStatus: "Rechecked",
      recheckReason: RecheckReason,
      recheckedById: user && user._id,
    };
    recheckMeetingStatus(finalData);
    setFormData({
      ...formData,
      isSubmitted: true,
    });
  };
  if (isSubmitted) {
    return <Redirect to="/all-meetings-verification" />;
  }
  return !isAuthenticated || !user || !users ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">Recheck</h3>
          </div>
          <div className="col-lg-2">
            <button onClick={handleModalClose} className="close">
              <img
                src={require("../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 col-md-9 col-sm-9 col-12 ">
            <form className="row" onSubmit={(e) => onSubmit(e)}>
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
                <div className="col-lg-12 col-md-4 col-sm-4 col-12">
                  <label>Reason For Recheck* :</label>
                </div>
                <div className="col-lg-12  col-md-4 col-sm-4 col-12">
                  <textarea
                    name="RecheckReason"
                    id="RecheckReason"
                    className="textarea form-control"
                    rows="3"
                    placeholder="Recheck Reason"
                    onChange={(e) => onInputChange(e)}
                    style={{ width: "100%" }}
                    required
                  ></textarea>
                </div>
                <div className="col-lg-12 col-md-4 col-sm-4 col-12 py-2">
                  <label>Are You Sure ?</label>
                </div>
                <div
                  className="col-lg-12 col-md-9 col-sm-9 col-12 Savebutton"
                  size="lg"
                >
                  {loading ? (
                    <button
                      className="btn sub_form btn_continue blackbrd Save float-right"
                      disabled
                    >
                      Loading...
                    </button>
                  ) : (
                    <input
                      type="submit"
                      name="Save"
                      value="Submit"
                      className="btn sub_form btn_continue Save float-right"
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

RecheckReasonModal.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { recheckMeetingStatus })(
  RecheckReasonModal
);
