import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import {
  getSubcasteCaste,
  editSubcasteDetails,
} from "../../actions/religionSetting";
import Spinner from "../layout/Spinner";

const EditSubCasteDetails = ({
  auth: { isAuthenticated, user, users, loading },
  getSubcasteCaste,
  editSubcasteDetails,
  subcastes,
}) => {
  useEffect(() => {
    getSubcasteCaste();
  }, [getSubcasteCaste]);

  //formData
  const [formData, setFormData] = useState({
    subcasteName:
      subcastes && subcastes.subcasteName ? subcastes.subcasteName : "",
    isSubmitted: false,
  });

  const { subcasteName } = formData;
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const allcastes = [];
  let selCasteData = JSON.parse(localStorage.getItem("selCasteData"));
  selCasteData &&
    selCasteData.map((caste) => {
      allcastes.push({
        casteId: caste._id,
        label: caste.casteName,
        value: caste.casteName,
      });
    });

  const [caste, setCasteData] = useState("");

  if (!caste && allcastes.length !== 0) {
    setCasteData(
      subcastes
        ? allcastes.length !== 0
          ? allcastes &&
            allcastes.filter((x) => x.casteId === subcastes.casteId)[0]
          : ""
        : ""
    );
  }
  const [casteId, setCasteID] = useState(subcastes.casteId);

  const onCasteChange = (e) => {
    var casteId = "";
    setCasteData(e);
    casteId = e.casteId;
    setCasteID(casteId);
  };

  const onUpdate = (subcastes, idx) => {
    const finalData = {
      recordId: subcastes ? subcastes._id : "",
      subcasteName: subcasteName,
      casteId: casteId,
      subcasteEnteredById: user._id,
      subcasteEnteredByName: user.userName,
      institutionId: user.institutionId,
    };
    editSubcasteDetails(finalData);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <label className="label-control"> Subcaste Name* :</label>
            <input
              type="text"
              name="subcasteName"
              value={subcasteName}
              className="form-control"
              onChange={(e) => onInputChange(e)}
              required
            />
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <label className="label-control">Caste* :</label>

            <Select
              name="casteName"
              options={allcastes}
              isSearchable={true}
              value={caste}
              placeholder="Select Caste"
              onChange={(e) => onCasteChange(e)}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
        </div>

        <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
          {loading ? (
            <button
              className="btn sub_form btn_continue Save float-right"
              disabled
            >
              Loading...
            </button>
          ) : (
            <button
              variant="success"
              className="btn sub_form btn_continue Save float-right"
              onClick={() => onUpdate(subcastes)}
              style={
                subcasteName !== ""
                  ? { opacity: "1" }
                  : { opacity: "1", pointerEvents: "none" }
              }
            >
              Update
            </button>
          )}
        </div>
      </div>
    </Fragment>
  );
};

EditSubCasteDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  editSubcasteDetails: PropTypes.func.isRequired,
  getSubcasteCaste: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  religionSetting: state.religionSetting,
});

export default connect(mapStateToProps, {
  getSubcasteCaste,
  editSubcasteDetails,
})(EditSubCasteDetails);
