import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { programDeactive } from "../../actions/skill";
const DeactiveProgram = ({
  auth: { isAuthenticated, user, users },
  programDeactiveData,
  onDeactiveModalChange,
  programDeactive,
}) => {
  const [formData, setFormData] = useState({
    programDeactiveReason: "",
    isSubmitted: false,
  });

  const { programDeactiveReason } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: programDeactiveData ? programDeactiveData._id : "",
      programStatus: "Deactive",
      programDeactiveReason: programDeactiveReason,
      programDeactiveById: user && user._id,
      programDeactiveDateTime: new Date().toLocaleString("en-GB"),
    };
    programDeactive(finalData);
    onDeactiveModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div className="col-lg-12 col-md-9 col-sm-9 col-12 ">
        <form className="row" onSubmit={(e) => onSubmit(e)}>
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
            <div className="col-lg-12 col-md-4 col-sm-4 col-12">
              <label>Reason For Deactivation* :</label>
            </div>
            <div className="col-lg-12  col-md-4 col-sm-4 col-12">
              <textarea
                name="programDeactiveReason"
                id="programDeactiveReason"
                className="textarea form-control"
                rows="3"
                placeholder="Deactive Reason"
                onChange={(e) => onInputChange(e)}
                style={{ width: "100%" }}
                required
              ></textarea>
            </div>
            <div className="col-lg-12 col-md-4 col-sm-4 col-12 py-2">
              <label>Are You Sure You Want to Deactivate ??</label>
            </div>
            <div
              className="col-lg-12 col-md-9 col-sm-9 col-12 Savebutton"
              size="lg"
            >
              <input
                type="submit"
                name="Save"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

DeactiveProgram.propTypes = {
  auth: PropTypes.object.isRequired,
  skill: PropTypes.object.isRequired,
  programDeactive: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  skill: state.skill,
});

export default connect(mapStateToProps, { programDeactive })(DeactiveProgram);
