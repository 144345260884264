import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import AddOccupation from "./AddOccupation";
import { getAllOccupation } from "../../actions/shg";
import EditOccupation from "./EditOccupation";
import Spinner from "../layout/Spinner";
import Pagination from "../layout/Pagination";
import DeactiveOcccupation from "./DeactiveOccupation";
const AllOcccupation = ({
  auth: { isAuthenticated, user, users },
  shg: { activeInstitutions, allOccupation },
  getAllOccupation,
}) => {
  useEffect(() => {
    getAllOccupation();
  }, [getAllOccupation]);
  const [showAddOccupationModal, setShowAddOccupationModal] = useState(false);
  const handleAddOccupationModalClose = () => setShowAddOccupationModal(false);
  const onClickHandler = () => {
    setShowAddOccupationModal(true);
  };

  const onAddOccupationModalChange = (e) => {
    if (e) {
      handleAddOccupationModalClose();
    }
  };
  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (allOccupation, idx) => {
    setShowUpdateModal(true);
    setUserData(allOccupation);
  };

  //pagination code
  const [currentData, setCurrentData] = useState(1);
  const [dataPerPage] = useState(10);
  //Get Current Data
  const indexOfLastData = currentData * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentDatas =
    allOccupation && allOccupation.slice(indexOfFirstData, indexOfLastData);
  //change page
  const paginate = (nmbr) => {
    setCurrentData(nmbr);
  };
  //pagination code ends

  const allinstitution = [];
  // let activeInstitutionData = JSON.parse(
  //   localStorage.getItem("activeInstitutionData")
  // );
  activeInstitutions &&
    activeInstitutions.map((institution) =>
      allinstitution.push({
        institutionId: institution._id,
        label: institution.institutionName,
        value: institution.institutionName,
      })
    );

  const [institution, getinstitutionData] = useState();
  const [institutionId, setinstitutionId] = useState();
  const [institutionName, setinstitutionName] = useState();

  const onInstitutionChange = (e) => {
    var institutionId = "";
    var institutionName = "";
    getinstitutionData(e);
    institutionId = e.institutionId;
    institutionName = e.value;
    setinstitutionId(institutionId);
    setinstitutionName(institutionName);
    const changeData = {
      institutionIdVal: e.institutionId,
    };
    getAllOccupation(changeData);
  };

  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const [userDataDeactive, setUserDataDeactive] = useState(null);
  const onUpdate = (allOccupation, idx) => {
    setShowDeactiveModal(true);
    setUserDataDeactive(allOccupation);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-12 col-sm-12 col-12 no_padding">
            <div className="row col-lg-5 col-md-11 col-sm-10 col-10">
              <h5 className="heading_color">All Occupation Details</h5>
              <div className="col-lg-5 col-md-11 col-sm-11 col-11">
                {user.userGroupName === "Super Admin" && (
                  <Select
                    name="institutionName"
                    options={allinstitution}
                    isSearchable={true}
                    value={institution}
                    placeholder="Institution"
                    onChange={(e) => onInstitutionChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                )}
              </div>
            </div>
            {user.userGroupName !== "Super Admin" && (
              <div className="col-lg-7 col-md-1 col-sm-1 col-1 py-4">
                <img
                  className="img_icon_size log float-right"
                  onClick={() => onClickHandler()}
                  src={require("../../static/images/add-icon.png")}
                  alt="Add Occupation Details"
                  title="Add Occupation Details"
                />
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 text-center py-2">
              <section className="body">
                <div className=" body-inner no-padding  table-responsive">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Occupation</th>
                        {user.userGroupName !== "Super Admin" && <th>Op</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {currentDatas &&
                        currentDatas.map((allOccupation, idx) => {
                          return (
                            <tr key={idx}>
                              {allOccupation.occcupationStatus &&
                              allOccupation.occcupationStatus === "Deactive" ? (
                                <td
                                  className=" secondlinebreak1"
                                  style={{ backgroundColor: "#dda6a6" }}
                                >
                                  {allOccupation.occcupationName}
                                </td>
                              ) : (
                                <td className=" secondlinebreak1">
                                  {allOccupation.occcupationName}
                                </td>
                              )}

                              {allOccupation.occcupationStatus &&
                              allOccupation.occcupationStatus !== "Deactive"
                                ? user.userGroupName !== "Super Admin" && (
                                    <td>
                                      <img
                                        className="img_icon_size log"
                                        onClick={() =>
                                          onUpdate(allOccupation, idx)
                                        }
                                        src={require("../../static/images/delete.png")}
                                        alt="Deactivate Occupation Details"
                                        title="Deactivate Occupation Details"
                                      />{" "}
                                      &nbsp;
                                      <img
                                        className="img_icon_size log"
                                        onClick={() =>
                                          onEdit(allOccupation, idx)
                                        }
                                        src={require("../../static/images/edit_icon.png")}
                                        alt="Edit Occupation Details"
                                        title="Edit Occupation Details"
                                      />
                                    </td>
                                  )
                                : user.userGroupName !== "Super Admin" && (
                                    <td>Deactivated</td>
                                  )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
                    {allOccupation && allOccupation.length !== 0 ? (
                      <Pagination
                        dataPerPage={dataPerPage}
                        totalData={allOccupation.length}
                        paginate={paginate}
                        currentPage={currentData}
                      />
                    ) : (
                      <Fragment />
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 align_right">
                    <label>No. of Occupations : {allOccupation.length}</label>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        <Modal
          show={showAddOccupationModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Add Occupation</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleAddOccupationModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <AddOccupation
              onAddOccupationModalChange={onAddOccupationModalChange}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showUpdateModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Edit Occupation</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleUpdateModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditOccupation
              allOccupation={userData}
              onUpdateModalChange={onUpdateModalChange}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={showDeactiveModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Deactivate Occupation</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleDeactiveModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <DeactiveOcccupation
              allOccupation={userDataDeactive}
              onDeactiveModalChange={onDeactiveModalChange}
            />
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

AllOcccupation.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getAllOccupation: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getAllOccupation,
})(AllOcccupation);
