import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "../layout/NotFound";

//user Section
import PrivateRoute from "./PrivateRoute";
import RouteDriver from "../dashboard/RouteDriver";

//DashBoard
import changePassword from "../auth/ChangePwd";
import LandingPage from "../dashboard/LandingPage";
import AllUserDetails from "../dashboard/AllUserDetails";
import AddUser from "../dashboard/AddUser";
import AddInstitution from "../dashboard/AddInstitutionDetails";
import AllInstitutionDetails from "../dashboard/AllInstitutionDetails";
import AllBatchDetails from "../dashboard/AllBatchDetails";
import AllMemberDetails from "../dashboard/AllMemberDetails";
import AllParish from "../dashboard/AllParish";
import AllVarado from "../dashboard/AllVarado";
import AllDistricts from "../dashboard/AllDistricts";
import AllStates from "../dashboard/AllStates";
import AddVaradoDetails from "../dashboard/AddVaradoDetails";
import AddParishDetails from "../dashboard/AddParishDetails";
import AddStateDetails from "../dashboard/AddStateDetails";
import AddDistrictDetails from "../dashboard/AddDistrictDetails";
import AllPartialMeetings from "../dashboard/AllPartialMeetings";
import AllVerifications from "../dashboard/AllVerifications";
import AddMemberDetails from "../dashboard/AddMemberDetails";
import AddBatchDetails from "../dashboard/AddBatchDetails";
import EditBatchDetails from "../dashboard/EditBatchDetails";
import EditMemberDetails from "../dashboard/EditMemberDetails";
import AddUserGroup from "../dashboard/AddUserGroup";
import AllUserGroups from "../dashboard/AllUserGroups";
import AllReports from "../dashboard/AllReports";
import AllArea from "../dashboard/AllArea";
import AllOccupationSettings from "../occupation/AllOccupationSettings";
import AllReligionCasteTypes from "../ReligionCasteTypes/AllReligionCasteTypes";
import AddMemberMeetingDetails from "../dashboard/AddMemberMeetingDetails";
import AddBatchMeetingDetails from "../dashboard/AddBatchMeetingDetails";
import AddBatchMeetingTest from "../dashboard/AddBatchMeetingTest";
import AddPurpose from "../dashboard/AddPurpose";
import AllLoanPurpose from "../dashboard/AllLoanPurpose";
import MyProfile from "../dashboard/MyProfile";
import MemberMeetings from "../dashboard/MemberMeetings";
import Notification from "../layout/Notification";
import SelectAddMeeting from "../dashboard/SelectAddMeeting";
import AddMemberwisebatchMeetingDetails from "../dashboard/AddMemberwisebatchMeetingDetails";
import AddMemberBatchMeetingDetails from "../dashboard/AddMemberBatchMeetingDetails";
import VerificationBatchMeetingDetails from "../dashboard/VerificationBatchMeetingDetails";
import VerificationMemberBatchMeetingDetails from "../dashboard/VerificationMemberBatchMeetingDetails";
import EditIndivisualMemberMeeting from "../EditMeeting/EditIndivisualMemberMeeting";
import MemberloginAllMembers from "../dashboard/MemberloginAllMembers";
import MemberwiseTransfer from "../dashboard/MemberwiseTransfer";

import AllInstitutionRenewal from "../dashboard/AllInstitutionRenewal";

import AddMembermeetingOpModal from "../dashboard/AddMembermeetingOpModal";
import OpeningBalforBatchMeetingDetails from "../dashboard/OpeningBalforBatchMeetingDetails";
import EditmemberMeetingopBalModal from "../dashboard/EditmemberMeetingopBalModal";
//Reports
import BatchwiseReport from "../Reports/BatchwiseReport";
import ShgCategorywiseReport from "../Reports/ShgCategorywiseReport";
import ShgDonorReport from "../Reports/ShgDonorReport";
import AssetsLiabilityReport from "../Reports/AssetsLiabilityReport";
import AssaIncomeExpenseReport from "../Reports/AssaIncomeExpenseReport";
import SeeAllMemLoan from "../Reports/SeeAllMemLoan";
// import AttendanceReport from "../Reports/AttendanceReport";

import IncomeExpenseReport from "../Reports/IncomeExpenseReport";
import MonthlyIncomeExpenseReport from "../Reports/MonthlyIncomeExpenseReport";
import AllVerfiedMeetings from "../Reports/AllVerfiedMeetings";
import ProfitandLossReport from "../Reports/ProfitandLossReport";
import InchargewiseReport from "../Reports/InchargewiseReport";
import EditMeeting from "../EditMeeting/EditMeeting";
import EditVerifiedMeeting from "../EditMeeting/EditVerifiedMeeting";

//Skill Programs
import AllPrograms from "../dashboard/AllPrograms";
import AddSkillTraining from "../dashboard/AddSkillTraining";
import SkillTrainingReport from "../Reports/SkillTrainingReport";
//settings
import AllAccess from "../settings/AllAccess";
import AllPermission from "../settings/AllPermission";

// Other Loan Types
import AllOtherLoanTypes from "../otherLoanTypes/AllOtherLoanTypes";
import AllShgCategories from "../category/AllCategories";
import AllOccupations from "../occupation/AllOccupation";
import AddSubOccupation from "../occupation/AddSubOccupation";
import OccupationReports from "../Reports/OccupationReports";
import Memberwiseloanreport from "../Reports/Memberwiseloanreport";

//help section
import shgLangList from "../trainingVideo/shgLangList";
import kannada from "../trainingVideo/kannada";
import introductionkan from "../trainingVideo/kannada/introductionkan";
import { createBatchVid } from "../trainingVideo/kannada/createBatchVid";
import { addMembersVid } from "../trainingVideo/kannada/addMembersVid";
import { deactiveMemberVid } from "../trainingVideo/kannada/deactiveMemberVid";
import { createUserVid } from "../trainingVideo/kannada/createUserVid";
import { createAreaVid } from "../trainingVideo/kannada/createAreaVid";
import { addOLTypesVid } from "../trainingVideo/kannada/addOLTypesVid";
import { createMeetingVid } from "../trainingVideo/kannada/createMeetingVid";
import { addRegMeetVid } from "../trainingVideo/kannada/addRegMeetVid";
import { updatePartialMeetVid } from "../trainingVideo/kannada/updatePartialMeetVid";
import { updateShgMeetVid } from "../trainingVideo/kannada/updateShgMeetVid";
import { verifyShgMeetVid } from "../trainingVideo/kannada/verifyShgMeetVid";
import { checkMeetRepVid } from "../trainingVideo/kannada/checkMeetRepVid";
import tamil from "../trainingVideo/tamil";
import { introSHGVid } from "../trainingVideo/tamil/introSHGVid";
import { createBatchTamilVid } from "../trainingVideo/tamil/createBatchTamilVid";
import { addMemBatchTamilVid } from "../trainingVideo/tamil/addMemBatchTamilVid";
import { deactiveMemTamilVid } from "../trainingVideo/tamil/deactiveMemTamilVid";
import { CreateUserTamilVid } from "../trainingVideo/tamil/CreateUserTamilVid";
import { createAreaTamilVid } from "../trainingVideo/tamil/createAreaTamilVid";
import { addOLcatTamilVid } from "../trainingVideo/tamil/addOLcatTamilVid";
import { createMeetTamilVid } from "../trainingVideo/tamil/createMeetTamilVid";
import { addRegMeetTamilVid } from "../trainingVideo/tamil/addRegMeetTamilVid";
import { updatePartialMeetTamilVid } from "../trainingVideo/tamil/updatePartialMeetTamilVid";
import { updateSHGMeetTamilVid } from "../trainingVideo/tamil/updateSHGMeetTamilVid";
import { verifySHGMeetTamilVid } from "../trainingVideo/tamil/verifySHGMeetTamilVid";
import { checkMeetRepTamilVid } from "../trainingVideo/tamil/checkMeetRepTamilVid";
const RoutesFile = () => {
  return (
    <section>
      <Notification />
      <Switch>
        <PrivateRoute exact path="/landing-page" component={LandingPage} />
        <PrivateRoute exact path="/add-user" component={AddUser} />
        <PrivateRoute
          exact
          path="/add-institution"
          component={AddInstitution}
        />
        <PrivateRoute exact path="/all-access" component={AllAccess} />
        <PrivateRoute exact path="/all-Permission" component={AllPermission} />
        <PrivateRoute
          exact
          path="/member-transfer"
          component={MemberwiseTransfer}
        />
        <PrivateRoute
          exact
          path="/add-membermeeting-opbal"
          component={AddMembermeetingOpModal}
        />
        <PrivateRoute
          exact
          path="/add-opening-balance-for-batchmeeting-Details"
          component={OpeningBalforBatchMeetingDetails}
        />
        <PrivateRoute
          exact
          path="/edit-membermeeting-opbal"
          component={EditmemberMeetingopBalModal}
        />
        <PrivateRoute
          exact
          path="/all-institution"
          component={AllInstitutionDetails}
        />
        <PrivateRoute
          exact
          path="/all-institution-renewal"
          component={AllInstitutionRenewal}
        />
        <PrivateRoute exact path="/all-batches" component={AllBatchDetails} />
        <PrivateRoute
          exact
          path="/edit-batch-details"
          component={EditBatchDetails}
        />
        <PrivateRoute
          exact
          path="/edit-member-details"
          component={EditMemberDetails}
        />
        <PrivateRoute exact path="/all-members" component={AllMemberDetails} />
        <PrivateRoute
          exact
          path="/member-all-members"
          component={MemberloginAllMembers}
        />
        <PrivateRoute exact path="/all-users" component={AllUserDetails} />
        <PrivateRoute exact path="/all-parish" component={AllParish} />
        <PrivateRoute exact path="/all-varado" component={AllVarado} />
        <PrivateRoute exact path="/all-districts" component={AllDistricts} />
        <PrivateRoute exact path="/all-state" component={AllStates} />
        <PrivateRoute
          exact
          path="/add-varado-details"
          component={AddVaradoDetails}
        />
        <PrivateRoute
          exact
          path="/add-parish-details"
          component={AddParishDetails}
        />
        <PrivateRoute
          exact
          path="/add-state-details"
          component={AddStateDetails}
        />
        <PrivateRoute
          exact
          path="/add-district-details"
          component={AddDistrictDetails}
        />
        <PrivateRoute
          exact
          path="/all-partial-meetings"
          component={AllPartialMeetings}
        />
        <PrivateRoute
          exact
          path="/all-meetings-verification"
          component={AllVerifications}
        />
        <PrivateRoute
          exact
          path="/add-member-details"
          component={AddMemberDetails}
        />
        <PrivateRoute
          exact
          path="/add-batch-details"
          component={AddBatchDetails}
        />
        <PrivateRoute exact path="/all-area" component={AllArea} />
        <PrivateRoute
          exact
          path="/all-occupation-setting"
          component={AllOccupationSettings}
        />
        <PrivateRoute
          exact
          path="/all-religion-caste-types"
          component={AllReligionCasteTypes}
        />
        <PrivateRoute exact path="/all-reports" component={AllReports} />
        <PrivateRoute exact path="/all-user-groups" component={AllUserGroups} />
        <PrivateRoute
          exact
          path="/add-membermeeting-details"
          component={AddMemberMeetingDetails}
        />
        <PrivateRoute
          exact
          path="/add-batchmeeting-details"
          component={AddBatchMeetingDetails}
        />
        <PrivateRoute
          exact
          path="/add-batchmeeting-test-details"
          component={AddBatchMeetingTest}
        />
        <PrivateRoute
          exact
          path="/all-loan-purpose"
          component={AllLoanPurpose}
        />
        <PrivateRoute exact path="/my-profile" component={MyProfile} />
        <PrivateRoute exact path="/add-loan-purpose" component={AddPurpose} />
        <PrivateRoute exact path="/member-meeting" component={MemberMeetings} />
        <PrivateRoute exact path="/add-user-groups" component={AddUserGroup} />
        <PrivateRoute
          exact
          path="/select-add-meeting"
          component={SelectAddMeeting}
        />
        <PrivateRoute
          exact
          path="/add-memberwise-batchdetails-meeting"
          component={AddMemberwisebatchMeetingDetails}
        />
        <PrivateRoute
          exact
          path="/all-verified-meeting"
          component={AllVerfiedMeetings}
        />
        <PrivateRoute
          exact
          path="/edit-verified-meeting"
          component={EditVerifiedMeeting}
        />
        <PrivateRoute
          exact
          path="/memberwise-batchdetails-meeting"
          component={AddMemberBatchMeetingDetails}
        />
        <PrivateRoute
          exact
          path="/batchwise-report"
          component={BatchwiseReport}
        />
        <PrivateRoute exact path="/edit-meeting" component={EditMeeting} />
        <PrivateRoute
          exact
          path="/Shg-Categorywise-Report"
          component={ShgCategorywiseReport}
        />
        <PrivateRoute
          exact
          path="/Shg-Donor-Report"
          component={ShgDonorReport}
        />
        <PrivateRoute
          exact
          path="/AssetsandLiabilities"
          component={AssetsLiabilityReport}
        />
        <PrivateRoute
          exact
          path="/AssaIncomeExpenseReport"
          component={AssaIncomeExpenseReport}
        />

        <PrivateRoute exact path="/SeeAllMemLoan" component={SeeAllMemLoan} />

        {/* <PrivateRoute
          exact
          path="/AttendanceReport"
          component={AttendanceReport}
        /> */}
        <PrivateRoute
          exact
          path="/profit-loss-report"
          component={ProfitandLossReport}
        />
        <PrivateRoute
          exact
          path="/inchargewise-report"
          component={InchargewiseReport}
        />
        <PrivateRoute
          exact
          path="/skill-training-report"
          component={SkillTrainingReport}
        />
        <PrivateRoute
          exact
          path="/Memberwise-loan-report"
          component={Memberwiseloanreport}
        />
        <PrivateRoute
          exact
          path="/income-expense-report"
          component={IncomeExpenseReport}
        />
        <PrivateRoute
          exact
          path="/monthly-income-expense-report"
          component={MonthlyIncomeExpenseReport}
        />
        <PrivateRoute
          exact
          path="/verification-batchmeeting"
          component={VerificationBatchMeetingDetails}
        />
        <PrivateRoute
          exact
          path="/verification-batch-membermeeting"
          component={VerificationMemberBatchMeetingDetails}
        />
        <PrivateRoute
          exact
          path="/Edit-Indivisual-membermeeting"
          component={EditIndivisualMemberMeeting}
        />
        <PrivateRoute
          exact
          path="/change-password"
          component={changePassword}
        />
        <PrivateRoute exact path="/all-programs" component={AllPrograms} />
        <PrivateRoute
          exact
          path="/add-skill-training"
          component={AddSkillTraining}
        />
        {/* Other Loan Types */}
        <PrivateRoute
          exact
          path="/All-OtherLoan-Types"
          component={AllOtherLoanTypes}
        />
        <PrivateRoute
          exact
          path="/All-SHG-Category"
          component={AllShgCategories}
        />
        <PrivateRoute
          exact
          path="/All-Occupations"
          component={AllOccupations}
        />
        <PrivateRoute
          exact
          path="/AddSubOccupation"
          component={AddSubOccupation}
        />
        <PrivateRoute
          exact
          path="/occupation-reports"
          component={OccupationReports}
        />
        {/* <PrivateRoute
          exact
          path="/change-password"
          component={changePassword}
        /> */}
        <PrivateRoute exact path="/route-driver" component={RouteDriver} />
        {/* shg videos */}
        <PrivateRoute exact path="/shgLangList" component={shgLangList} />
        <PrivateRoute exact path="/kannada" component={kannada} />
        <PrivateRoute exact path="/tamil" component={tamil} />
        <PrivateRoute exact path="/kannadaIntro" component={introductionkan} />
        <PrivateRoute
          exact
          path="/kannadaCreateBatch"
          component={createBatchVid}
        />
        <PrivateRoute
          exact
          path="/kannadaAddMember"
          component={addMembersVid}
        />
        <PrivateRoute
          exact
          path="/kannadaDeactiveMember"
          component={deactiveMemberVid}
        />
        <PrivateRoute
          exact
          path="/kannadaCreateUser"
          component={createUserVid}
        />
        <PrivateRoute
          exact
          path="/kannadaCreateArea"
          component={createAreaVid}
        />
        <PrivateRoute
          exact
          path="/kannadaAddOLTypes"
          component={addOLTypesVid}
        />
        <PrivateRoute
          exact
          path="/kannadaCreateMeeting"
          component={createMeetingVid}
        />
        <PrivateRoute
          exact
          path="/kannadaAddRegMeeting"
          component={addRegMeetVid}
        />
        <PrivateRoute
          exact
          path="/kannadaUpdatePartialMeetVid"
          component={updatePartialMeetVid}
        />
        <PrivateRoute
          exact
          path="/kannadaUpdateShgMeetVid"
          component={updateShgMeetVid}
        />
        <PrivateRoute
          exact
          path="/kannadaVerifyShgMeet"
          component={verifyShgMeetVid}
        />
        <PrivateRoute
          exact
          path="/kannadaCheckShgMeetRep"
          component={checkMeetRepVid}
        />
        <PrivateRoute exact path="/TamilIntroSHG" component={introSHGVid} />
        <PrivateRoute
          exact
          path="/TamilCreateBatch"
          component={createBatchTamilVid}
        />
        <PrivateRoute
          exact
          path="/TamilAddMem"
          component={addMemBatchTamilVid}
        />
        <PrivateRoute
          exact
          path="/TamilDeactiveMem"
          component={deactiveMemTamilVid}
        />
        <PrivateRoute
          exact
          path="/TamilCreateUser"
          component={CreateUserTamilVid}
        />
        <PrivateRoute
          exact
          path="/TamilCreateArea"
          component={createAreaTamilVid}
        />
        <PrivateRoute
          exact
          path="/TamilAddOLCat"
          component={addOLcatTamilVid}
        />
        <PrivateRoute
          exact
          path="/TamilCreateMeet"
          component={createMeetTamilVid}
        />
        <PrivateRoute
          exact
          path="/TamilCreateRegMeet"
          component={addRegMeetTamilVid}
        />
        <PrivateRoute
          exact
          path="/TamilUpdateParMeet"
          component={updatePartialMeetTamilVid}
        />
        <PrivateRoute
          exact
          path="/TamilUpdateSHGMeet"
          component={updateSHGMeetTamilVid}
        />
        <PrivateRoute
          exact
          path="/TamilVerifySHGMeet"
          component={verifySHGMeetTamilVid}
        />
        <PrivateRoute
          exact
          path="/TamilCheckMeetRep"
          component={checkMeetRepTamilVid}
        />

        <Route component={NotFound} />
      </Switch>
    </section>
  );
};

export default RoutesFile;
