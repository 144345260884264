import {
  USER_GROUPS,
  ALL_USER_DETAILS,
  ALL_INCHARGE_DETAILS,
  ALL_COORDINATOR_DETAILS,
  CHECK_USER,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  users: [],
  errorResponse: "",
  successResponse: "",
  userGroups: [],
  getalluserdetails: [],
  allincharge: [],
  allcoordinator: [],
  checkUser: [],
};

const user = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_GROUPS:
      return {
        ...state,
        userGroups: payload,
      };
    case ALL_USER_DETAILS:
      return {
        ...state,
        getalluserdetails: payload,
      };
    case ALL_INCHARGE_DETAILS:
      return {
        ...state,
        allincharge: payload,
      };
    case ALL_COORDINATOR_DETAILS:
      return {
        ...state,
        allcoordinator: payload,
      };

    case CHECK_USER:
      return {
        ...state,
        checkUser: payload,
      };
    default:
      return state;
  }
};

export default user;
