import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { AddSubcaste, getSubcasteCaste } from "../../actions/religionSetting";
import Spinner from "../layout/Spinner";

const AddSubCasteDetails = ({
  auth: { isAuthenticated, user, users, loading },
  religionSetting: { subcasteCaste },
  onAddCasteModalChange,
  AddSubcaste,
  getSubcasteCaste,
}) => {
  useEffect(() => {
    getSubcasteCaste();
  }, [getSubcasteCaste]);

  //formData
  const [formData, setFormData] = useState({
    subcasteName: "",
    isSubmitted: false,
  });

  const { subcasteName } = formData;
  const allcastes = [];
  subcasteCaste.map((caste) =>
    allcastes.push({
      casteId: caste._id,
      label: caste.casteName,
      value: caste.casteName,
    })
  );

  const [caste, getCasteData] = useState();
  const [casteId, setReligionID] = useState();

  const onReligionChange = (e) => {
    //Required Validation Starts
    setError({
      ...error,
      sIdChecker: true,
      sIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    var casteId = "";
    getCasteData(e);
    casteId = e.casteId;
    setReligionID(casteId);
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //Required Validation Starts
  const [error, setError] = useState({
    sIdChecker: false,
    sIdErrorStyle: {},
  });
  const { sIdChecker, sIdErrorStyle } = error;

  const checkErrors = () => {
    if (!sIdChecker) {
      setError({
        ...error,
        sIdErrorStyle: { color: "#F00" },
      });
      return false;
    }
    return true;
  };
  //Required Validation ends
  const onSubmit = (e) => {
    e.preventDefault();
    //Required Validation Starts
    if (checkErrors()) {
      const finalData = {
        subcasteName: subcasteName,
        casteId: casteId,
        subcasteEnteredById: user._id,
        subcasteEnteredByName: user.userName,
        subcasteEnteredDateTime: new Date().toLocaleString("en-GB"),
        institutionId: user.institutionId,
        userData: user,
      };
      AddSubcaste(finalData);

      setFormData({
        ...formData,
        subcasteName: "",
        isSubmitted: true,
      });
      getCasteData("");
    }
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control"> Subcaste Name* :</label>
              <input
                type="text"
                name="subcasteName"
                value={subcasteName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control" style={sIdErrorStyle}>
                Caste* :
              </label>
              <Select
                name="casteName"
                options={allcastes}
                isSearchable={true}
                value={caste}
                placeholder="Select Caste"
                onChange={(e) => onReligionChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
          </div>

          <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

AddSubCasteDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  AddSubcaste: PropTypes.func.isRequired,
  getSubcasteCaste: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  religionSetting: state.religionSetting,
});

export default connect(mapStateToProps, {
  getSubcasteCaste,
  AddSubcaste,
})(AddSubCasteDetails);
