import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteLoanDetails } from "../../actions/shg";
const DeactiveLoanDetails = ({
  auth: { isAuthenticated, user, users, loading },
  loanDeactiveData,
  deleteLoanDetails,
  onDeactiveModalChange,
}) => {
  const [formData, setFormData] = useState({
    loanDeleteReason: "",
    isSubmitted: false,
  });

  const { loanDeleteReason } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: loanDeactiveData ? loanDeactiveData._id : "",
      batchId: loanDeactiveData ? loanDeactiveData.batchId : "",
      meetingHeldOnDate: loanDeactiveData
        ? loanDeactiveData.meetingHeldOnDate
        : "",
      loanSanctionedStatus: "Deleted",
      loanDeleteReason: loanDeleteReason,
      loanDeleteDateTime: new Date().toLocaleString("en-GB"),
    };
    deleteLoanDetails(finalData);
    onDeactiveModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div className="col-lg-12 col-md-9 col-sm-9 col-12 ">
        <form className="row" onSubmit={(e) => onSubmit(e)}>
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
            <div className="col-lg-12 col-md-4 col-sm-4 col-12">
              <label>Reason For Loan Deletion* :</label>
            </div>
            <div className="col-lg-12  col-md-4 col-sm-4 col-12">
              <textarea
                name="loanDeleteReason"
                id="loanDeleteReason"
                className="textarea form-control"
                rows="3"
                placeholder="Delete Reason"
                onChange={(e) => onInputChange(e)}
                style={{ width: "100%" }}
                required
              ></textarea>
            </div>
            <div className="col-lg-12 col-md-4 col-sm-4 col-12 py-2">
              <label>Are You Sure You Want to Delete this loan ??</label>
            </div>
            <div
              className="col-lg-12 col-md-9 col-sm-9 col-12 Savebutton"
              size="lg"
            >
              {loading ? (
                <button
                  className="btn sub_form btn_continue blackbrd Save float-right"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <input
                  type="submit"
                  name="Save"
                  value="Submit"
                  className="btn sub_form btn_continue Save float-right"
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

DeactiveLoanDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  deleteLoanDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  deleteLoanDetails,
})(DeactiveLoanDetails);
