import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";

import {
  getBatchesOnTransTypeChange,
  getMemberOrBatchwiseBatches,
  batchAndMeetingDetails,
  removeMessage,
} from "../../actions/shg";
import { getAllParish } from "../../actions/area";

const SelectAddMeeting = ({
  auth: { isAuthenticated, user, users },
  shg: { memberorbatchwisebatches, memberorbatchwisebatchesDropdown },
  area: { parishData },
  getBatchesOnTransTypeChange,
  getMemberOrBatchwiseBatches,
  batchAndMeetingDetails,
  removeMessage,
  getAllParish,
}) => {
  let selMemBatchMeetingDetails = JSON.parse(
    localStorage.getItem("selMemBatchMeetingDetails")
  );

  useEffect(() => {
    removeMessage();
  }, [removeMessage]);
  useEffect(() => {
    getAllParish();
  }, [getAllParish]);
  useEffect(() => {
    getMemberOrBatchwiseBatches(selMemBatchMeetingDetails);
  }, []);
  const [error, setError] = useState({
    addmeetingBtnStyle: { opacity: "0.5", pointerEvents: "none" },
  });
  const { addmeetingBtnStyle } = error;

  const [parish, getParishData] = useState();
  const [pId, setParishID] = useState();
  const [parishName, setParishName] = useState();
  const [parishCode, setParishCode] = useState("");
  const [batchCounter, setBatchCounter] = useState("");

  const onParishChange = (e) => {
    var pId = "";
    var parishName = "";
    var parishCode = "";
    var batchCounter = "";

    getParishData(e);
    pId = e.pId;
    parishName = e.value;
    parishCode = e.pCode;
    batchCounter = e.bCounter;
    setParishID(pId);
    setParishName(parishName);
    setParishCode(parishCode);
    setBatchCounter(batchCounter);

    const changeData = {
      pIdVal: e.pId,
      parishId: pId,
    };
    // getAllBatches(changeData);
    // getBatchOfParish(changeData);
    getbatchsData("");
    setFormData({
      ...formData,
      batchTransactionType: "",
    });
  };

  const allparish = [];

  parishData.map((parish) =>
    allparish.push({
      pId: parish._id,
      pCode: parish.parishCode,
      bCounter: parish.batchCounter,
      label: parish.parishName,
      value: parish.parishName,
    })
  );

  //formData
  const [formData, setFormData] = useState({
    batchTransactionType: selMemBatchMeetingDetails &&
      selMemBatchMeetingDetails.batchTransactionType && {
        label: selMemBatchMeetingDetails.batchTransactionType,
        value: selMemBatchMeetingDetails.batchTransactionType,
      },
    isSubmitted: false,
  });

  const { batchTransactionType } = formData;

  const allbatches = [];

  memberorbatchwisebatchesDropdown.map((batchs) =>
    allbatches.push({
      batchId: batchs._id,
      label: batchs.batchName + " (" + batchs.batchCode + ")",
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState(
    selMemBatchMeetingDetails && {
      batchId: selMemBatchMeetingDetails.selectedBatchId,
      label: selMemBatchMeetingDetails.batchNameLable,
      value: selMemBatchMeetingDetails.batchName,
    }
  );
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();

  const onBatchesChange = (e) => {
    let transactionTypeChange = {};
    setError({
      ...error,
      addmeetingBtnStyle: { opacity: "1" },
    });

    getbatchsData(e);
    setbatchId(e.batchId);
    setbatchName(e.batchName);
    transactionTypeChange = {
      batchTransactionType: batchTransactionType.value,
      batchIdVal: e.batchId,
      batchName: e.value,
      batchNameLable: e.label,
      pId: pId ? pId : null,
    };
    getMemberOrBatchwiseBatches(transactionTypeChange);
  };
  const PrabhaID = "628730e256381864dc4d498c";
  const MegRajID = "64bf63cde1591ca9225678fc";

  const TranscationMethods =
    (user && user.institutionId === PrabhaID) ||
    (user && user.institutionId === MegRajID)
      ? [{ value: "Memberwise", label: "Memberwise" }]
      : [
          { value: "Memberwise", label: "Memberwise" },
          { value: "Batchwise", label: "Batchwise" },
        ];
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const [showHide, setShowHide] = useState({
    showBatchSection:
      loggedUserInstitutionData &&
      loggedUserInstitutionData.institutionType === "Zoned"
        ? true
        : false,
  });

  const { showBatchSection } = showHide;
  const onbatchTransactionTypeChange = (e) => {
    let transactionTypeChange = {};
    if (e) {
      setFormData({
        ...formData,
        batchTransactionType: e,
      });
      transactionTypeChange = {
        batchTransactionType: e.value,
        pId: pId ? pId : null,
        batchIdVal: "",
        batchName: "",
      };
    }
    getBatchesOnTransTypeChange(transactionTypeChange);
    getMemberOrBatchwiseBatches(transactionTypeChange);
    getbatchsData("");
  };

  const handleGoToAllMember = (memberorbatchwisebatches) => {
    const batchIdData = {
      batchIdVal: memberorbatchwisebatches._id,
      batchNameVal: memberorbatchwisebatches.batchName,
    };
    batchAndMeetingDetails(batchIdData);
  };

  const onClickReset = () => {
    getbatchsData("");
    getMemberOrBatchwiseBatches("");
    getParishData("");
    setFormData({
      ...formData,
      batchTransactionType: "",
    });
  };
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">Meetings </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-3 col-md-3 col-sm-6 col-11 mt-1">
              {showBatchSection && (
                <Select
                  name="parishName"
                  options={allparish}
                  isSearchable={true}
                  value={parish}
                  placeholder="Select Parish"
                  onChange={(e) => onParishChange(e)}
                />
              )}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-4 col-11 mt-1">
              <Select
                name="batchTransactionType"
                options={TranscationMethods}
                isSearchable={false}
                value={batchTransactionType}
                placeholder="Select MeetingType"
                onChange={(e) => onbatchTransactionTypeChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>

            <div className="col-lg-3 col-md-4 col-sm-4 col-11 mt-1">
              <Select
                name="batchName"
                options={allbatches}
                isSearchable={true}
                value={batchs}
                placeholder="Select SHG"
                onChange={(e) => onBatchesChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>

            <div className="col-lg-3 col-md-2 col-sm-4 col-11 py-3">
              <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <img
                className="img_icon_size log float-right"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>SHG Name</th>
                        <th>SHG Code</th>
                      </tr>
                    </thead>
                    {memberorbatchwisebatches == null ? (
                      <>
                        <Spinner />
                      </>
                    ) : (
                      <tbody>
                        {memberorbatchwisebatches &&
                          memberorbatchwisebatches.map(
                            (memberorbatchwisebatches, idx) => {
                              return (
                                <tr key={idx}>
                                  <td>
                                    <Link
                                      to={{
                                        pathname:
                                          memberorbatchwisebatches &&
                                          memberorbatchwisebatches.batchTransactionType ===
                                            "Memberwise"
                                            ? "/add-memberwise-batchdetails-meeting"
                                            : "/add-batchmeeting-details",
                                      }}
                                      onClick={() =>
                                        handleGoToAllMember(
                                          memberorbatchwisebatches
                                        )
                                      }
                                    >
                                      {" "}
                                      {memberorbatchwisebatches.batchName}
                                    </Link>
                                  </td>

                                  <td>{memberorbatchwisebatches.batchCode}</td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    )}
                  </table>
                </div>
                <div className="col-lg-12 col-md-6 col-sm-11 col-11 align_right">
                  <label>
                    <b>
                      {memberorbatchwisebatches &&
                        "Remaining Meetings:" + memberorbatchwisebatches.length}
                    </b>
                  </label>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

SelectAddMeeting.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getBatchesOnTransTypeChange: PropTypes.func.isRequired,
  getMemberOrBatchwiseBatches: PropTypes.func.isRequired,
  batchAndMeetingDetails: PropTypes.func.isRequired,
  getAllParish: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
});

export default connect(mapStateToProps, {
  getBatchesOnTransTypeChange,
  getMemberOrBatchwiseBatches,
  batchAndMeetingDetails,
  removeMessage,
  getAllParish,
})(SelectAddMeeting);
