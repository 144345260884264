import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AddState, checkState } from "../../actions/area";
import Spinner from "../layout/Spinner";

const AddStateDetails = ({
  savedMessage,
  auth: { isAuthenticated, user, users, loading },
  area: { stateCheckResponse },
  AddState,
  checkState,
  onAddStateModalChange,
}) => {
  //formData
  const [formData, setFormData] = useState({
    stateName: "",
    isSubmitted: false,
  });
  const { stateName } = formData;
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onCheck = () => {
    const finalData = {
      sName: stateName,
    };
    checkState(finalData);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      stateName: stateName.trim(),
      stateEnteredById: user._id,
      stateEnteredByName: user.userName,
      institutionId: user.institutionId,
      userData: user,
    };
    onAddStateModalChange(false);
    AddState(finalData);
    setFormData({
      ...formData,
      stateName: "",
      isSubmitted: true,
    });
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <label className="label-control"> State Name* :</label>
              <input
                type="text"
                name="stateName"
                value={stateName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 py-5">
              <button
                className="btn sub_form"
                type="button"
                onClick={(e) => onCheck(e)}
              >
                Check
              </button>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            {stateCheckResponse && stateCheckResponse === "State Available" ? (
              <label style={{ color: "green" }}>{stateCheckResponse}</label>
            ) : (
              <label className="colorRed">{stateCheckResponse}</label>
            )}
          </div>
          {stateCheckResponse === "State Available" && (
            <div className="col-md-10 col-lg-10 col-sm-12 col-12 text-left">
              {loading ? (
                <button
                  className="btn sub_form btn_continue Save float-right"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <input
                  type="submit"
                  name="Save"
                  value="Submit"
                  className="btn sub_form btn_continue Save float-right"
                />
              )}
            </div>
          )}
        </form>
      </div>
    </Fragment>
  );
};

AddStateDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  AddState: PropTypes.func.isRequired,
  checkState: PropTypes.func.isRequired,
  savedMessage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  savedMessage: state.auth.savedMessage,
});

export default connect(mapStateToProps, {
  AddState,
  checkState,
})(AddStateDetails);
