import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { getOcccupationsDropdown, AddSubOccupation } from "../../actions/shg";
import Spinner from "../layout/Spinner";

const AddOccupations = ({
  savedMessage,
  auth: { isAuthenticated, user, users, loading },
  shg: { activeOccupation },
  AddSubOccupation,
  setShowAddSubOccupationModal,
  getOcccupationsDropdown,
}) => {
  useEffect(() => {
    getOcccupationsDropdown();
  }, [getOcccupationsDropdown]);
  //formData
  const [formData, setFormData] = useState({
    subOccupationName: "",
    isSubmitted: false,
  });
  const { subOccupationName } = formData;
  const [error, setError] = useState({
    oIdChecker: false,
    oIdErrorStyle: {},
  });
  const { oIdChecker, oIdErrorStyle } = error;

  const checkErrors = () => {
    if (!oIdChecker) {
      setError({
        ...error,
        oIdErrorStyle: { color: "#F00" },
      });
      return false;
    }
    return true;
  };

  const allOccupation = [];
  activeOccupation.map((occupation) =>
    allOccupation.push({
      oId: occupation._id,
      label: occupation.occcupationName,
      value: occupation.occcupationName,
    })
  );
  const [occupation, setOccupationData] = useState();
  const [occupationId, setOccupatioId] = useState();
  const onOccupationChange = (e) => {
    setError({
      ...error,
      oIdChecker: true,
      oIdErrorStyle: { color: "#000" },
    });
    var occupationId = "";
    setOccupationData(e);
    occupationId = e.oId;
    setOccupatioId(occupationId);
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    
    e.preventDefault();
    if (checkErrors()) {
      const finalData = {
        subOccupationName: subOccupationName,
        occupationId: occupationId,
        subOoccupationEnteredById: user._id,
        subOccupationEnteredByName: user.userName,
        subOccupationEnteredDateTime: new Date().toLocaleString("en-GB"),
        institutionId: user.institutionId,
      };
      AddSubOccupation(finalData);
      setFormData({
        ...formData,
        subOccupationName: "",
        isSubmitted: true,
      });
      setOccupationData("");
      setShowAddSubOccupationModal(false);
    }
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control">Sub Occupation* :</label>
              <input
                type="text"
                name="subOccupationName"
                value={subOccupationName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <label className="label-control" style={oIdErrorStyle}>
                Occupation* :
              </label>
              <Select
                name="occupationName"
                options={allOccupation}
                isSearchable={true}
                value={occupation}
                placeholder="Select Occupation"
                onChange={(e) => onOccupationChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
          </div>

          <div className="col-md-10 col-lg-10 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Save"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

AddOccupations.propTypes = {
  auth: PropTypes.object.isRequired,
  AddSubOccupation: PropTypes.func.isRequired,
  shg: PropTypes.object.isRequired,
  getOcccupationsDropdown: PropTypes.func.isRequired,
  savedMessage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  savedMessage: state.auth.savedMessage,
});

export default connect(mapStateToProps, {
  AddSubOccupation,
  getOcccupationsDropdown,
})(AddOccupations);
