import React, { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getIncomeAndExpenseReport,
  getBatchOfParish,
  getAllBatchesDropdown,
  getBatchOfDistrict,
} from "../../actions/shg";
import {
  getVarados,
  getParish,
  getStates,
  getDistrict,
  getBlockDetails,
} from "../../actions/area";
import Spinner from "../layout/Spinner";
import { CSVLink } from "react-csv";

const ProfitandLossReport = ({
  auth: { isAuthenticated, user, users },
  shg: {
    batchOfParish,
    allbatchesdropdown,
    incomeandexpensereport,
    batchOfDistrict,
  },
  area: { varadosData, activeParish, statesData, activeDistrict, getBlock },
  getIncomeAndExpenseReport,
  getVarados,
  getParish,
  getBatchOfParish,
  getAllBatchesDropdown,
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getBlockDetails,
}) => {
  useEffect(() => {
    getIncomeAndExpenseReport();
  }, [getIncomeAndExpenseReport]);
  useEffect(() => {
    getVarados();
  }, [getVarados]);
  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);
  useEffect(() => {
    getStates();
  }, [getStates]);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );
  const allvarado = [];
  varadosData.map((varado) =>
    allvarado.push({
      vId: varado._id,
      vCode: varado.varadoCode,
      label: varado.varadoName,
      value: varado.varadoName,
    })
  );
  const [varado, getVaradoData] = useState();
  const [varadoID, setVaradoID] = useState();
  const [varadoName, setvaradoName] = useState();
  const [varadoCode, setVaradoCode] = useState("");

  const onVaradoChange = (e) => {
    var varadoID = "";
    var varadoName = "";
    var varadoCode = "";
    getVaradoData(e);

    varadoID = e.vId;
    varadoName = e.value;
    varadoCode = e.vCode;
    setVaradoID(varadoID);
    setvaradoName(varadoName);
    setVaradoCode(varadoCode);
    let varadoVal = {
      varadoInfo: varadoID,
    };
    getParish(varadoVal);
    getParishData("");
    getbatchsData("");
    getIncomeAndExpenseReport("");
    getBatchOfParish("");
  };

  const allparish = [];

  activeParish.map((parish) =>
    allparish.push({
      pId: parish._id,
      pCode: parish.parishCode,
      bCounter: parish.batchCounter,
      label: parish.parishName,
      value: parish.parishName,
    })
  );

  const [parish, getParishData] = useState();
  const [pId, setParishID] = useState();
  const [parishName, setParishName] = useState();
  const [parishCode, setParishCode] = useState("");
  const [batchCounter, setBatchCounter] = useState("");

  const onParishChange = (e) => {
    var pId = "";
    var parishName = "";
    var parishCode = "";
    var batchCounter = "";

    getParishData(e);
    pId = e.pId;
    parishName = e.value;
    parishCode = e.pCode;
    batchCounter = e.bCounter;
    setParishID(pId);
    setParishName(parishName);
    setParishCode(parishCode);
    setBatchCounter(batchCounter);
    var filterData = {
      parishId: pId,
    };
    getBatchOfParish(filterData);
    getIncomeAndExpenseReport("");
    getbatchsData("");
  };
  const allbatches = [];

  batchOfParish.map((batchs) =>
    allbatches.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState();
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();
  let changeData = "";
  const onBatchesChange = (e) => {
    getbatchsData(e);
    setbatchId(e.batchId);
    setbatchName(e.batchName);
    changeData = {
      batchIdVal: e.batchId,
    };
    getIncomeAndExpenseReport(changeData);
  };

  const onClickReset = () => {
    getParishData("");
    getIncomeAndExpenseReport("");
    getVaradoData("");
    getbatchsData("");
    getbatchesData("");
    getParish("");
    getBatchOfParish("");
    getStates("");
    getDistrict("");
    getStateData("");
    getdistrictData("");
    getBatchOfDistrict("");
    setBatchDate("");
    setBatchDates("");
    setBlock("");
  };

  const [fromSelectedDate, setBatchDate] = useState("");
  const [toSelectedDate, setBatchDates] = useState("");
  const onDateChange = (e) => {
    setBatchDate(e.target.value);
    changeData = {
      batchIdVal: batchId,
      fromDate: e.target.value,
      toDate: toSelectedDate,
    };
    getIncomeAndExpenseReport(changeData);
  };

  const onDateChanges = (e) => {
    setBatchDates(e.target.value);
    changeData = {
      batchIdVal: batchesId,
      fromDate: fromSelectedDate,
      toDate: e.target.value,
    };
    getIncomeAndExpenseReport(changeData);
  };
  const allstates = [];
  statesData.map((state) =>
    allstates.push({
      sId: state._id,
      label: state.stateName,
      value: state.stateName,
    })
  );
  let passwrdTooltip = {
    marginLeft: "-16em",
    position: "absolute",
    marginTop: "1.5em",
    pointerEvents: "none",
    zIndex: "999",
    width: "300px",
  };

  const [state, getStateData] = useState();

  const [stateId, setStateID] = useState();
  const [stateName, setStateName] = useState();

  const onStateChange = (e) => {
    var stateId = "";
    var stateName = "";
    getStateData(e);

    stateId = e.sId;
    stateName = e.value;

    setStateID(stateId);
    setStateName(stateName);
    let stateVal = {
      // userInfo: user,
      stateInfo: stateId,
    };
    getDistrict(stateVal);
    getdistrictData("");
    getBatchOfDistrict("");
    getbatchesData("");
    setBlock("");
  };

  const alldistrict = [];

  activeDistrict.map((district) =>
    alldistrict.push({
      districtId: district._id,
      label: district.districtName,
      value: district.districtName,
    })
  );

  const allbatchesList = [];
  batchOfDistrict.map((batches) =>
    allbatchesList.push({
      batchesId: batches._id,
      label: batches.batchName,
      value: batches.batchName,
    })
  );
  const [batches, getbatchesData] = useState();
  const [batchesId, setbatchesId] = useState();
  const [batchesName, setbatchesName] = useState();
  const onBatchChange = (e) => {
    var batchesId = "";
    var batchesName = "";
    getbatchesData();
    batchesId = e.batchesId;
    batchesName = e.value;
    setbatchesId(batchesId);
    // setbatchesName(batchesName);
    const changeData = {
      batchIdVal: e.batchesId,
    };
    getIncomeAndExpenseReport(changeData);
  };

  const [district, getdistrictData] = useState();
  // const [bCode, getBCode] = useState();
  const [districtId, setdistrictID] = useState();
  const [districtName, setdistrictName] = useState();

  const ondistrictChange = (e) => {
    var districtId = "";
    var districtName = "";
    getdistrictData(e);
    getBlockDetails({ districtIdVal: e.districtId });
    districtId = e.districtId;
    districtName = e.value;

    setdistrictID(districtId);
    setdistrictName(districtName);
    var filterData = {
      districtId: districtId,
    };
    getBatchOfDistrict(filterData);
    getbatchesData("");
    setBlock("");
  };

  const allBlocks = [];
  getBlock &&
    getBlock.map((block) =>
      allBlocks.push({
        label: block.blockName,
        value: block._id,
      })
    );

  const [block, setBlock] = useState();
  const onBlockChange = (e) => {
    setBlock(e);
    getBatchOfDistrict({
      blockId: e.value,
    });
    getbatchesData("");
    getIncomeAndExpenseReport({ blockId: e.value });
  };

  let totalProfit,
    totalIncome,
    totalLoss,
    batchSubAmount,
    bankInterest,
    batchOtherContribution,
    loanRepaidByOtherSHG,
    batchInterestPaid,
    batchOtherLoanInterestDonor,
    paidToMahasangha,
    bankCommission,
    travellingExpenses,
    loanGivenToOtherSHG,
    stationaryExpenses,
    serviceCharges,
    batchDividendDistributed,
    otherExpenses,
    deficit,
    surplus,
    totalExpense = 0;
  const csvData = [
    [
      "SHG Name",
      "Loan Interest",
      "Bank Interest",
      "Subscription Amount",
      "Other Contribution",
      "Loan Repaid By Other SHG",
      "Other Loan Interest",
      "Paid To Union (Mahasangha/Federation/General HUB)",
      "Bank Commission",
      "Travelling Expenses",
      "Loan Given To Other SHG",
      "Stationary Expenses",
      "Service Charges",
      "Dividend Distributed",
      "Batch Other Expenses",
      "20% Reserve Fund",
    ],
  ];
  incomeandexpensereport.map((incomeandexpensereport) => {
    batchSubAmount = incomeandexpensereport.batchSubAmt;
    batchInterestPaid = incomeandexpensereport.batchInterestPaid;
    bankInterest = incomeandexpensereport.bankInterest;
    batchOtherContribution = incomeandexpensereport.batchOtherContribution;
    loanRepaidByOtherSHG = incomeandexpensereport.loanRepaidByOtherSHG;
    totalIncome =
      batchInterestPaid +
      bankInterest +
      batchSubAmount +
      batchOtherContribution +
      loanRepaidByOtherSHG;

    batchOtherLoanInterestDonor =
      incomeandexpensereport.batchOtherLoanInterestDonor;
    paidToMahasangha = incomeandexpensereport.paidToMahasangha;
    bankCommission = incomeandexpensereport.bankCommission;
    travellingExpenses = incomeandexpensereport.travellingExpenses;
    loanGivenToOtherSHG = incomeandexpensereport.loanGivenToOtherSHG;
    stationaryExpenses = incomeandexpensereport.stationaryExpenses;
    serviceCharges = incomeandexpensereport.serviceCharges;
    batchDividendDistributed = incomeandexpensereport.batchDividendDistributed;
    otherExpenses = incomeandexpensereport.otherExpenses;
    totalExpense =
      batchOtherLoanInterestDonor +
      travellingExpenses +
      loanGivenToOtherSHG +
      stationaryExpenses +
      paidToMahasangha +
      bankCommission +
      serviceCharges +
      batchDividendDistributed +
      otherExpenses;
    deficit = totalExpense - totalIncome;
    if (deficit < 0) {
      deficit = 0;
    }
    surplus = totalIncome - totalExpense;
    if (surplus < 0) {
      surplus = 0;
    }
    totalLoss = surplus + totalExpense;
    totalProfit = deficit + totalIncome;
    csvData.push([
      incomeandexpensereport.batchName,
      incomeandexpensereport.batchInterestPaid,
      incomeandexpensereport.bankInterest,
      incomeandexpensereport.batchSubAmt,
      incomeandexpensereport.batchOtherContribution,
      incomeandexpensereport.loanRepaidByOtherSHG,
      incomeandexpensereport.batchOtherLoanInterestDonor,
      incomeandexpensereport.paidToMahasangha,
      incomeandexpensereport.bankCommission,
      incomeandexpensereport.travellingExpenses,
      incomeandexpensereport.loanGivenToOtherSHG,
      incomeandexpensereport.stationaryExpenses,
      incomeandexpensereport.serviceCharges,
      incomeandexpensereport.batchDividendDistributed,
      incomeandexpensereport.otherExpenses,
      incomeandexpensereport.batchSavingWithdrawals,
    ]);
  });

  csvData.push([""]);
  csvData.push(["Deficit", deficit]);
  csvData.push(["Total Profit", totalProfit]);
  csvData.push(["Surplus", surplus]);
  csvData.push(["Total Loss", totalLoss]);
  const csvFreeZonedData = [
    [
      "SHG Name",
      "Loan Interest",
      "Bank Interest",
      "Subscription Amount",
      "Other Contribution",
      "Loan Repaid By Other SHG",
      "Other Loan Interest",
      "Paid To Union (Mahasangha/Federation/General HUB)",
      "Bank Commission",
      "Travelling Expenses",
      "Loan Given To Other SHG",
      "Stationary Expenses",
      "Service Charges",
      "Dividend Distributed",
      "Batch Other Expenses",
      "20% Reserve Fund",
    ],
  ];
  incomeandexpensereport.map((incomeandexpensereport) => {
    batchSubAmount = incomeandexpensereport.batchSubAmt;
    batchInterestPaid = incomeandexpensereport.batchInterestPaid;
    bankInterest = incomeandexpensereport.bankInterest;
    batchOtherContribution = incomeandexpensereport.batchOtherContribution;
    loanRepaidByOtherSHG = incomeandexpensereport.loanRepaidByOtherSHG;
    totalIncome =
      batchInterestPaid +
      bankInterest +
      batchSubAmount +
      batchOtherContribution +
      loanRepaidByOtherSHG;

    batchOtherLoanInterestDonor =
      incomeandexpensereport.batchOtherLoanInterestDonor;
    paidToMahasangha = incomeandexpensereport.paidToMahasangha;
    bankCommission = incomeandexpensereport.bankCommission;
    travellingExpenses = incomeandexpensereport.travellingExpenses;
    loanGivenToOtherSHG = incomeandexpensereport.loanGivenToOtherSHG;
    stationaryExpenses = incomeandexpensereport.stationaryExpenses;
    serviceCharges = incomeandexpensereport.serviceCharges;
    batchDividendDistributed = incomeandexpensereport.batchDividendDistributed;
    otherExpenses = incomeandexpensereport.otherExpenses;
    totalExpense =
      batchOtherLoanInterestDonor +
      travellingExpenses +
      loanGivenToOtherSHG +
      stationaryExpenses +
      paidToMahasangha +
      bankCommission +
      serviceCharges +
      batchDividendDistributed +
      otherExpenses;
    deficit = totalExpense - totalIncome;
    if (deficit < 0) {
      deficit = 0;
    }
    surplus = totalIncome - totalExpense;
    if (surplus < 0) {
      surplus = 0;
    }
    totalLoss = surplus + totalExpense;
    totalProfit = deficit + totalIncome;
    csvFreeZonedData.push([
      incomeandexpensereport.batchName,
      incomeandexpensereport.batchInterestPaid,
      incomeandexpensereport.bankInterest,
      incomeandexpensereport.batchSubAmt,
      incomeandexpensereport.batchOtherContribution,
      incomeandexpensereport.loanRepaidByOtherSHG,
      incomeandexpensereport.batchOtherLoanInterestDonor,
      incomeandexpensereport.paidToMahasangha,
      incomeandexpensereport.bankCommission,
      incomeandexpensereport.travellingExpenses,
      incomeandexpensereport.loanGivenToOtherSHG,
      incomeandexpensereport.stationaryExpenses,
      incomeandexpensereport.serviceCharges,
      incomeandexpensereport.batchDividendDistributed,
      incomeandexpensereport.otherExpenses,
      incomeandexpensereport.batchSavingWithdrawals,
    ]);
  });

  csvFreeZonedData.push([""]);
  csvFreeZonedData.push(["Deficit", deficit]);
  csvFreeZonedData.push(["Total Profit", totalProfit]);
  csvFreeZonedData.push(["Surplus", surplus]);
  csvFreeZonedData.push(["Total Loss", totalLoss]);
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color">Profit and Loss Report </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding">
            {loggedUserInstitutionData.institutionType === "Zoned" ? (
              <>
                <div className="col-lg-2 col-md-3 col-sm-3 col-11 ">
                  <Select
                    name="varadoName"
                    options={allvarado}
                    isSearchable={true}
                    value={varado}
                    placeholder="Select Varado"
                    onChange={(e) => onVaradoChange(e)}
                  />
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 col-11 ">
                  <Select
                    name="parishName"
                    options={allparish}
                    isSearchable={true}
                    value={parish}
                    placeholder="Select Parish"
                    onChange={(e) => onParishChange(e)}
                  />
                </div>
                <div className="col-lg-2 col-md-5 col-sm-4 col-11 ">
                  <Select
                    name="batchName"
                    options={allbatches}
                    isSearchable={true}
                    value={batchs}
                    placeholder="Select SHG"
                    onChange={(e) => onBatchesChange(e)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                  <Select
                    name="stateName"
                    options={allstates}
                    isSearchable={true}
                    value={state}
                    placeholder="Select State"
                    onChange={(e) => onStateChange(e)}
                  />
                </div>
                <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                  <Select
                    name="districtName"
                    options={alldistrict}
                    isSearchable={true}
                    value={district}
                    placeholder="Select District"
                    onChange={(e) => ondistrictChange(e)}
                  />
                </div>
                {allPermission && allPermission.includes("Blockwise") && (
                  <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                    <Select
                      name="blockName"
                      options={allBlocks}
                      isSearchable={true}
                      value={block}
                      placeholder="Select Block"
                      onChange={(e) => onBlockChange(e)}
                    />
                  </div>
                )}
                <div className="col-lg-2 col-md-5 col-sm-4 col-3 mt-1">
                  <Select
                    name="batchName"
                    options={allbatchesList}
                    isSearchable={true}
                    value={batches}
                    placeholder="Select SHG"
                    onChange={(e) => onBatchChange(e)}
                  />
                </div>
              </>
            )}
            <div className="col-lg-2 col-md-3 col-sm-3 col-11 ">
              {/* <label className="label-control">From Date:</label> */}
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="fromDate"
                value={fromSelectedDate}
                onChange={(e) => onDateChange(e)}
                style={{
                  width: "75%",
                }}
              />
            </div>
            <div className="col-lg-2 col-md-5 col-sm-4 col-11 ">
              {/* <label className="label-control">To Date:</label> */}
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="toDate"
                value={toSelectedDate}
                onChange={(e) => onDateChanges(e)}
                style={{
                  width: "75%",
                }}
              />
            </div>
            <div className="col-lg-12 col-md-3 col-sm-4 col-11 py-3  ">
              <Link to="/all-reports">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <img
                className="img_icon_size log float-right ml-3"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
              {loggedUserInstitutionData.institutionType === "Zoned" ? (
                <CSVLink data={csvData}>
                  <img
                    className="img_icon_size log float-right ml-4"
                    src={require("../../static/images/excel_icon.png")}
                    alt="Excel-Export"
                    title="Excel-Export"
                  />
                  {/* <button className="btn btn_green_bg log float-right ">
                    Export
                  </button> */}
                </CSVLink>
              ) : (
                <CSVLink data={csvFreeZonedData}>
                  <img
                    className="img_icon_size log float-right ml-4"
                    src={require("../../static/images/excel_icon.png")}
                    alt="Excel-Export"
                    title="Excel-Export"
                  />
                  {/* <button className="btn btn_green_bg log float-right ml-3">
                    Export
                  </button> */}
                </CSVLink>
              )}
            </div>
          </div>
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 "></div>
          <div className="row">
            <div className="col-lg-6 col-md-11 col-sm-11 col-11">
              <div className=" body-inner no-padding table-responsive ">
                <h4>
                  <center>
                    <b>Profit</b>
                  </center>
                </h4>
                <table
                  className="table table-bordered table-striped table-hover"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>Profit</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {incomeandexpensereport &&
                      incomeandexpensereport.map(
                        (incomeandexpensereport, idx) => {
                          let batchSubAmount =
                            incomeandexpensereport.batchSubAmt;
                          let batchInterestPaid =
                            incomeandexpensereport.batchInterestPaid;
                          let bankInterest =
                            incomeandexpensereport.bankInterest;
                          let batchOtherContribution =
                            incomeandexpensereport.batchOtherContribution;
                          let loanRepaidByOtherSHG =
                            incomeandexpensereport.loanRepaidByOtherSHG;

                          let totalIncome =
                            batchInterestPaid +
                            bankInterest +
                            batchSubAmount +
                            batchOtherContribution +
                            loanRepaidByOtherSHG;

                          let batchOtherLoanInterestDonor =
                            incomeandexpensereport.batchOtherLoanInterestDonor;
                          let paidToMahasangha =
                            incomeandexpensereport.paidToMahasangha;
                          let bankCommission =
                            incomeandexpensereport.bankCommission;
                          let travellingExpenses =
                            incomeandexpensereport.travellingExpenses;
                          let loanGivenToOtherSHG =
                            incomeandexpensereport.loanGivenToOtherSHG;

                          let stationaryExpenses =
                            incomeandexpensereport.stationaryExpenses;
                          let serviceCharges =
                            incomeandexpensereport.serviceCharges;
                          let batchDividendDistributed =
                            incomeandexpensereport.batchDividendDistributed;
                          let otherExpenses =
                            incomeandexpensereport.otherExpenses;
                          let totalExpense =
                            batchOtherLoanInterestDonor +
                            travellingExpenses +
                            loanGivenToOtherSHG +
                            stationaryExpenses +
                            paidToMahasangha +
                            bankCommission +
                            serviceCharges +
                            batchDividendDistributed +
                            otherExpenses;
                          let deficit = totalExpense - totalIncome;
                          if (deficit < 0) {
                            deficit = 0;
                          }
                          let total = deficit + totalIncome;
                          return (
                            <>
                              <tr key={idx}>
                                <td>Loan Interest</td>
                                <td>
                                  {incomeandexpensereport.batchInterestPaid}
                                </td>
                              </tr>
                              <tr>
                                <td>Bank Interest</td>
                                <td>{incomeandexpensereport.bankInterest}</td>
                              </tr>
                              <tr>
                                <td>Subscription Amount</td>
                                <td>{incomeandexpensereport.batchSubAmt}</td>
                              </tr>
                              <tr>
                                <td>Other Contribution</td>
                                <td>
                                  {
                                    incomeandexpensereport.batchOtherContribution
                                  }
                                </td>
                              </tr>
                              {allPermission &&
                              allPermission.includes("SHG-to-SHGLoan") ? (
                                <tr>
                                  <td>Loan Repaid By Other SHG</td>
                                  <td>
                                    {
                                      incomeandexpensereport.loanRepaidByOtherSHG
                                    }
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}

                              {deficit && deficit > 0 ? (
                                <tr>
                                  <td>
                                    <b>Deficit</b>
                                  </td>
                                  <td>
                                    <b>{deficit}</b>
                                  </td>
                                </tr>
                              ) : (
                                <Fragment />
                              )}

                              <tr>
                                <td>
                                  <b>Total</b>
                                </td>
                                <td>
                                  <b>{total}</b>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6 col-md-11 col-sm-11 col-11 ">
              <h4>
                <center>
                  <b>Loss</b>
                </center>
              </h4>

              <div className=" body-inner no-padding table-responsive ">
                <table
                  className="table table-bordered table-striped table-hover"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th width="60%">Loss</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {incomeandexpensereport &&
                      incomeandexpensereport.map(
                        (incomeandexpensereport, idx) => {
                          let batchSubAmount =
                            incomeandexpensereport.batchSubAmt;
                          let batchInterestPaid =
                            incomeandexpensereport.batchInterestPaid;
                          let bankInterest =
                            incomeandexpensereport.bankInterest;
                          let batchOtherContribution =
                            incomeandexpensereport.batchOtherContribution;
                          let loanRepaidByOtherSHG =
                            incomeandexpensereport.loanRepaidByOtherSHG;

                          let totalIncome =
                            batchInterestPaid +
                            bankInterest +
                            batchSubAmount +
                            batchOtherContribution +
                            loanRepaidByOtherSHG;
                          let batchOtherLoanInterestDonor =
                            incomeandexpensereport.batchOtherLoanInterestDonor;
                          let paidToMahasangha =
                            incomeandexpensereport.paidToMahasangha;
                          let bankCommission =
                            incomeandexpensereport.bankCommission;
                          let travellingExpenses =
                            incomeandexpensereport.travellingExpenses;
                          let loanGivenToOtherSHG =
                            incomeandexpensereport.loanGivenToOtherSHG;

                          let stationaryExpenses =
                            incomeandexpensereport.stationaryExpenses;
                          let serviceCharges =
                            incomeandexpensereport.serviceCharges;
                          let batchDividendDistributed =
                            incomeandexpensereport.batchDividendDistributed;
                          let otherExpenses =
                            incomeandexpensereport.otherExpenses;
                          let totalExpense =
                            batchOtherLoanInterestDonor +
                            travellingExpenses +
                            loanGivenToOtherSHG +
                            stationaryExpenses +
                            paidToMahasangha +
                            bankCommission +
                            serviceCharges +
                            batchDividendDistributed +
                            otherExpenses;
                          let surplus = totalIncome - totalExpense;
                          if (surplus < 0) {
                            surplus = 0;
                          }
                          let total = surplus + totalExpense;
                          return (
                            <>
                              <tr key={idx}>
                                <td>Other Loan Interest</td>
                                <td>
                                  {
                                    incomeandexpensereport.batchOtherLoanInterestDonor
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Paid To Union
                                  <inline
                                    // className="cstm-hint"
                                    id="pass_admin_help"
                                    style={{
                                      // top: "20px",
                                      //  right: "185px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &nbsp;
                                    <img
                                      src={require("../../static/images/info.png")}
                                      style={{ height: "18px", width: "18px" }}
                                      alt="help"
                                      id="img_tool_admin"
                                      className="pass_admin_help_icon_question "
                                    />
                                    <div
                                      id="tooltipPassAdmin"
                                      className="syle-hint "
                                      style={passwrdTooltip}
                                      data-hint="Mahasanga/Federation/General HUB"
                                    ></div>
                                  </inline>{" "}
                                </td>
                                <td>
                                  {incomeandexpensereport.paidToMahasangha}
                                </td>
                              </tr>
                              <tr>
                                <td>Bank Commission</td>
                                <td>{incomeandexpensereport.bankCommission}</td>
                              </tr>
                              <tr>
                                <td>Travelling Expenses</td>
                                <td>
                                  {incomeandexpensereport.travellingExpenses}
                                </td>
                              </tr>
                              <tr>
                                <td>Stationary Expenses</td>
                                <td>
                                  {incomeandexpensereport.stationaryExpenses}
                                </td>
                              </tr>

                              <tr>
                                <td>Service Charges</td>
                                <td>{incomeandexpensereport.serviceCharges}</td>
                              </tr>
                              <tr>
                                <td>Dividend Distributed</td>
                                <td>
                                  {
                                    incomeandexpensereport.batchDividendDistributed
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Batch Other Expenses</td>
                                <td>{incomeandexpensereport.otherExpenses}</td>
                              </tr>
                              {allPermission &&
                              allPermission.includes("SHG-to-SHGLoan") ? (
                                <tr>
                                  <td>Loan Given To Other SHG</td>
                                  <td>
                                    {incomeandexpensereport.loanGivenToOtherSHG}
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}

                              <tr>
                                <td>20% Reserve Fund(Saving Withdrawal)</td>
                                <td>
                                  {
                                    incomeandexpensereport.batchSavingWithdrawals
                                  }
                                </td>
                              </tr>
                              {surplus && surplus > 0 ? (
                                <tr>
                                  <td>
                                    <b>Surplus</b>
                                  </td>
                                  <td>
                                    <b>{surplus.toFixed(2)}</b>
                                  </td>
                                </tr>
                              ) : (
                                <Fragment />
                              )}
                              <tr>
                                <td>
                                  <b>Total</b>
                                </td>
                                <td>
                                  <b>{total}</b>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

ProfitandLossReport.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  getIncomeAndExpenseReport: PropTypes.func.isRequired,
  getParish: PropTypes.func.isRequired,
  getAllBatchesDropdown: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
});

export default connect(mapStateToProps, {
  getIncomeAndExpenseReport,
  getVarados,
  getParish,
  getBatchOfParish,
  getAllBatchesDropdown,
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getBlockDetails,
})(ProfitandLossReport);
