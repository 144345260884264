import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Spinner from "../layout/Spinner";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getStates, getDistrict, getBlockDetails } from "../../actions/area";
import {
  getBatchOfDistrict,
  getAllBatchesDropdown,
  getASSAAssetsAndLiabilityReport,
} from "../../actions/shg";
import { CSVLink } from "react-csv";
const AssetsLiabilityReport = ({
  auth: { isAuthenticated, user, users },
  area: { statesData, activeDistrict, getBlock },
  shg: { batchOfDistrict, ASSAAssetAndLiability },
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getBlockDetails,
  getAllBatchesDropdown,
  getASSAAssetsAndLiabilityReport,
}) => {
  useEffect(() => {
    getStates();
  }, [getStates]);
  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);

  useEffect(() => {
    getASSAAssetsAndLiabilityReport();
  }, [getASSAAssetsAndLiabilityReport]);
  ///////////////////Permission////////////////
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );
  ////////////////State DropDown////////////////
  const allstates = [];
  statesData.map((state) =>
    allstates.push({
      sId: state._id,
      label: state.stateName,
      value: state.stateName,
    })
  );
  const [state, getStateData] = useState();
  const [stateId, setStateID] = useState();
  const [stateName, setStateName] = useState();
  const onStateChange = (e) => {
    var stateId = "";
    var stateName = "";
    getStateData(e);

    stateId = e.sId;
    stateName = e.value;

    setStateID(stateId);
    setStateName(stateName);
    let stateVal = {
      stateInfo: stateId,
    };
    getDistrict(stateVal);
    getdistrictData("");
    getBatchOfDistrict("");
    getbatchesData("");
    setBlock("");
  };
  //////////////District DropDown/////////////////

  const alldistrict = [];

  activeDistrict.map((district) =>
    alldistrict.push({
      districtId: district._id,
      label: district.districtName,
      value: district.districtName,
    })
  );
  const [district, getdistrictData] = useState();

  const [districtId, setdistrictID] = useState();
  const [districtName, setdistrictName] = useState();

  const ondistrictChange = (e) => {
    var districtId = "";
    var districtName = "";
    getdistrictData(e);
    getBlockDetails({ districtIdVal: e.districtId });
    districtId = e.districtId;
    districtName = e.value;
    setdistrictID(districtId);
    setdistrictName(districtName);
    var filterData = {
      districtId: districtId,
    };
    getBatchOfDistrict(filterData);
    getbatchesData("");
    setBlock("");
  };
  /////////////////Block DropDown///////////////////
  const allBlocks = [];
  getBlock &&
    getBlock.map((block) =>
      allBlocks.push({
        label: block.blockName,
        value: block._id,
      })
    );

  const [block, setBlock] = useState();
  const onBlockChange = (e) => {
    setBlock(e);
    getBatchOfDistrict({
      blockId: e.value,
    });
    getbatchesData("");
    getASSAAssetsAndLiabilityReport({ blockId: e.value });
  };
  ////////////////////Batch DropDown///////////////
  const allbatchesList = [];
  batchOfDistrict.map((batches) =>
    allbatchesList.push({
      batchesId: batches._id,
      label: batches.batchName,
      value: batches.batchName,
    })
  );
  const [batches, getbatchesData] = useState();
  const [batchesId, setbatchesId] = useState();
  const [batchesName, setbatchesName] = useState();

  const onBatchChange = (e) => {
    var batchesId = "";
    var batchesName = "";
    getbatchesData();
    batchesId = e.batchesId;
    batchesName = e.value;
    setbatchesId(batchesId);
    // setbatchesName(batchesName);

    const changeData = {
      batchIdVal: e.batchesId,
    };
    getASSAAssetsAndLiabilityReport(changeData);

    // getIncomeAndExpenseReport(changeData);
    // getIncomeAndExpenseOpBal(changeData);
    getdistrictData("");
    getStateData("");
    setBlock("");
    setdistrictName("");
  };
  /////////////Refresh Button//////////////

  /////////////Date Change Dropdown//////////
  const [batchs, getbatchsData] = useState();
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();

  let changeData = "";

  // const onBatchesChange = (e) => {
  //   getbatchsData(e);
  //   setbatchId(e.batchId);
  //   setbatchName(e.batchName);
  //   changeData = {
  //     batchIdVal: e.batchId,
  //   };
  //   getASSAAssetsAndLiabilityReport(changeData);
  // };

  let fiscalyearstart = "",
    fiscalyearend = "";
  let today = new Date();

  if (today.getMonth() + 1 <= 3) {
    fiscalyearstart = today.getFullYear() - 1 + "-04-01";
    fiscalyearend = today.getFullYear() + "-03-31";
  } else {
    fiscalyearstart = today.getFullYear() + "-04-01";
    fiscalyearend = today.getFullYear() + 1 + "-03-31";
  }

  const [fromSelectedDate, setBatchDate] = useState("2000-01-01");
  const [toSelectedDate, setBatchDates] = useState(fiscalyearend);

  const onClickReset = () => {
    getASSAAssetsAndLiabilityReport();
    getbatchsData("");
    getbatchesData("");

    getStates("");
    getDistrict("");
    getStateData("");
    getdistrictData("");
    getBatchOfDistrict("");
    setBatchDate(fiscalyearstart);
    setBatchDates(fiscalyearend);
    setBlock("");
  };

  //start Date
  const onDateChange = (e) => {
    setBatchDate(e.target.value);
    changeData = {
      batchIdVal: batchId || batchesId,
      fromDate: e.target.value,
      toDate: toSelectedDate,
    };
    getASSAAssetsAndLiabilityReport(changeData);
  };

  //End Date
  const onDateChanges = (e) => {
    setBatchDates(e.target.value);
    changeData = {
      batchIdVal: batchId || batchesId,
      fromDate: fromSelectedDate,
      toDate: e.target.value,
    };
    getASSAAssetsAndLiabilityReport(changeData);
  };

  ////////////////Liability/////////////////
  const getAllOthLoan = (data) => {
    return (
      Number(data.otherLoanSanctionedToShg) - Number(data.otherLoanPaidToDonor)
    );
  };

  const getMemSvgAmt = (data) => {
    return Number(data.batchSavingAmt) - Number(data.batchSavingWithdrawals);
  };

  const getSDMem = (data) => {
    return (
      Number(data.batchSecurityDepositMemToShg) -
      Number(data.batchSecurityDeposit)
    );
  };
  ////////////////LiabilityTotal////////////////////
  const liabilityTotal = (liabilityTotal) => {
    return (
      getAllOthLoan(liabilityTotal) +
      getMemSvgAmt(liabilityTotal) +
      getSDMem(liabilityTotal)
    );
  };
  //////////////Assets///////////////////

  const getBankBalance = (data) => {
    return (
      Number(data.sumOfDeposit) +
      Number(data.sumOfInt) -
      Number(data.sumOfWithdr) -
      Number(data.sumOfComm)
    );
  };

  const getIL = (data) => {
    return Number(data.batchLoanAmt) - Number(data.batchLoanPaid);
  };

  const getOLPaid = (data) => {
    return (
      Number(data.otherLoanSanctionedToMember) - Number(data.otherLoanPaidToShg)
    );
  };

  const getSD = (data) => {
    return Number(data.sdUnionToSHG) - Number(data.sdSHGToUnion);
  };
  //////////////AssetsTotal/////////////////
  const assetTotal = (assetTotal) => {
    return (
      getBankBalance(assetTotal) +
      Number(assetTotal.cashInHand) +
      getIL(assetTotal) +
      getOLPaid(assetTotal) +
      getSD(assetTotal)
    );
  };
  /////////////////////Excel Report//////////////
  const csvData = [
    [
      //asset
      "Bank Balance",
      "Cash Balance",
      "Internal Loan",
      "OL Paid / Received From Members",
      "SHG Security Deposit",
      //Liability
      "All Other Loans",
      "Members Savings Amount",
      "Security Deposit Members",
    ],
  ];
  if (ASSAAssetAndLiability)
    csvData.push([
      //assets
      getBankBalance(ASSAAssetAndLiability),
      ASSAAssetAndLiability.cashInHand,
      getIL(ASSAAssetAndLiability),
      getOLPaid(ASSAAssetAndLiability),
      getSD(ASSAAssetAndLiability),

      //liability
      getAllOthLoan(ASSAAssetAndLiability),
      getMemSvgAmt(ASSAAssetAndLiability),
      getSDMem(ASSAAssetAndLiability),
    ]);
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color">Assets & Liabilities Report</h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding">
            <>
              <div className="col-lg-3 col-md-5 col-sm-4 col-11 mt-1">
                <Select
                  name="stateName"
                  options={allstates}
                  isSearchable={true}
                  value={state}
                  placeholder="Select State"
                  onChange={(e) => onStateChange(e)}
                />
              </div>
              <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                <Select
                  name="districtName"
                  options={alldistrict}
                  isSearchable={true}
                  value={district}
                  placeholder="Select District"
                  onChange={(e) => ondistrictChange(e)}
                />
              </div>
              {allPermission && allPermission.includes("Blockwise") && (
                <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                  <Select
                    name="blockName"
                    options={allBlocks}
                    isSearchable={true}
                    value={block}
                    placeholder="Select Block"
                    onChange={(e) => onBlockChange(e)}
                  />
                </div>
              )}
              <div className="col-lg-3 col-md-5 col-sm-4 col-3 mt-1">
                <Select
                  name="batchName"
                  options={allbatchesList}
                  isSearchable={true}
                  value={batches}
                  placeholder="Select SHG"
                  onChange={(e) => onBatchChange(e)}
                />
              </div>
            </>
          </div>
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding mt-2">
            <div className="col-lg-2 col-md-3 col-sm-3 col-11 " style={{display:"none"}}>
              {/* <label className="label-control">From Date:</label> */}
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="fromDate"
                value={fromSelectedDate}
                onChange={(e) => onDateChange(e)}
                style={{
                  width: "75%",
                }}
              />
            </div>
            <div className="col-lg-2 col-md-5 col-sm-4 col-11 ">
              {/* <label className="label-control">To Date:</label> */}
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="toDate"
                value={toSelectedDate}
                onChange={(e) => onDateChanges(e)}
                style={{
                  width: "75%",
                }}
              />
            </div>
            <div className="col-lg-8 col-md-3 col-sm-4 col-11 py-3">
              <Link to="/all-reports">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <img
                className="img_icon_size log float-right ml-3"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
              <CSVLink data={csvData}>
                <img
                  className="img_icon_size log float-right ml-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="Excel-Export"
                  title="Excel-Export"
                />
              </CSVLink>
            </div>
          </div>
          {ASSAAssetAndLiability ? (
            <>
              <div className="row col-lg-11 col-md-11 col-sm-11 col-11 "></div>
              <div className="row">
                <div className="col-lg-6 col-md-11 col-sm-11 col-11">
                  <div className=" body-inner no-padding table-responsive ">
                    <h4>
                      <center>
                        <b> ASSET</b>
                      </center>
                    </h4>
                    <table
                      className="table table-bordered table-striped table-hover"
                      id="datatable2"
                    >
                      <thead>
                        <tr>
                          <th>Asset</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          <tr>
                            <td>Bank Balance</td>
                            <td>{getBankBalance(ASSAAssetAndLiability)}</td>
                          </tr>
                          <tr>
                            <td> Cash Balance</td>
                            <td>{ASSAAssetAndLiability.cashInHand}</td>
                          </tr>
                          <tr>
                            <td>Internal Loan</td>
                            <td>{getIL(ASSAAssetAndLiability)}</td>
                          </tr>
                          <tr>
                            <td>OL Paid / Received From Members</td>
                            <td>{getOLPaid(ASSAAssetAndLiability)}</td>
                          </tr>
                          <tr>
                            <td>SHG Security Deposit</td>
                            <td>{getSD(ASSAAssetAndLiability)}</td>
                          </tr>

                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              <b>{assetTotal(ASSAAssetAndLiability)}</b>
                            </td>
                          </tr>
                        </>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-6 col-md-11 col-sm-11 col-11 ">
                  <h4>
                    <center>
                      <b>LIABILITY </b>
                    </center>
                  </h4>

                  <div className=" body-inner no-padding table-responsive ">
                    <table
                      className="table table-bordered table-striped table-hover"
                      id="datatable2"
                    >
                      <thead>
                        <tr>
                          <th width="60%">Liability</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          <tr>
                            <td>All Other Loans</td>
                            <td>{getAllOthLoan(ASSAAssetAndLiability)}</td>
                          </tr>
                          <tr>
                            <td>Members Savings Amount</td>
                            <td>{getMemSvgAmt(ASSAAssetAndLiability)}</td>
                          </tr>
                          <tr>
                            <td>Security Deposit Members</td>
                            <td>{getSDMem(ASSAAssetAndLiability)}</td>
                          </tr>

                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              <b>{liabilityTotal(ASSAAssetAndLiability)}</b>
                            </td>
                          </tr>
                        </>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </section>
      </div>
    </Fragment>
  );
};
AssetsLiabilityReport.prototypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  getAllBatchesDropdown: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  shg: state.shg,
});
export default connect(mapStateToProps, {
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getBlockDetails,
  getAllBatchesDropdown,
  getASSAAssetsAndLiabilityReport,
})(AssetsLiabilityReport);
