import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Select from "react-select";
import Spinner from "../layout/Spinner";
import { connect } from "react-redux";
import AddParishDetails from "./AddParishDetails";
import { getAllParish, getVarados } from "../../actions/area";
import EditParishDetails from "./EditParishDetails";
import Pagination from "../layout/Pagination";
import DeactiveParish from "./DeactiveParish";
const AllParish = ({
  auth: { allUser, isAuthenticated, user, users },
  area: { parishData },
  shg: { activeInstitutions },
  getVarados,
  getAllParish,
}) => {
  useEffect(() => {
    getAllParish();
  }, [getAllParish]);
  useEffect(() => {
    getVarados();
  }, [getVarados]);

  const [showAddParishModal, setShowAddParishModal] = useState(false);
  const handleAddParishModalClose = () => setShowAddParishModal(false);
  const onClickHandler = () => {
    setShowAddParishModal(true);
  };

  const onAddParishModalChange = (e) => {
    if (e) {
      handleAddParishModalClose();
    }
  };
  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (parishes, idx) => {
    //
    setShowUpdateModal(true);
    setUserData(parishes);
  };

  //pagination code
  const [currentData, setCurrentData] = useState(1);
  const [dataPerPage] = useState(8);
  //Get Current Data
  const indexOfLastData = currentData * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentDatas =
    parishData && parishData.slice(indexOfFirstData, indexOfLastData);
  //change page
  const paginate = (nmbr) => {
    setCurrentData(nmbr);
  };
  //pagination code ends

  const allinstitution = [];
  activeInstitutions.map((institution) =>
    allinstitution.push({
      institutionId: institution._id,
      label: institution.institutionName,
      value: institution.institutionName,
    })
  );

  const [institution, getinstitutionData] = useState();

  const [institutionId, setinstitutionId] = useState();
  const [institutionName, setinstitutionName] = useState();

  const onInstitutionChange = (e) => {
    var institutionId = "";
    var institutionName = "";
    getinstitutionData(e);

    institutionId = e.institutionId;
    institutionName = e.value;

    setinstitutionId(institutionId);
    setinstitutionName(institutionName);
    const changeData = {
      institutionIdVal: e.institutionId,
    };
    getAllParish(changeData);
  };
  // const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  // const handleDeactiveModalClose = () => setShowDeactiveModal(false);

  // const onDeactiveModalChange = (e) => {
  //   if (e) {
  //     handleDeactiveModalClose();
  //   }
  // };
  // const [userDatas, setUserDatas] = useState(null);
  // const onUpdate = (parishData, idx) => {
  //   setShowDeactiveModal(true);
  //   setUserDatas(parishData);
  // };
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-12 col-sm-12 col-12 no_padding">
            <div className="row col-lg-5 col-md-11 col-sm-10 col-10">
              <h5 className="heading_color">All Parish Details </h5>
              <div className="col-lg-5 col-md-11 col-sm-11 col-11">
                {user.userGroupName === "Super Admin" && (
                  <Select
                    name="institutionName"
                    options={allinstitution}
                    isSearchable={true}
                    value={institution}
                    placeholder="Institution"
                    onChange={(e) => onInstitutionChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                )}
              </div>
            </div>
            {user.userGroupName !== "Super Admin" && (
              <div className="col-lg-7 col-md-1 col-sm-1 col-1 py-4">
                <img
                  className="img_icon_size log float-right"
                  onClick={() => onClickHandler()}
                  src={require("../../static/images/add-icon.png")}
                  alt="Add Parish"
                  title="Add Parish"
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-12 col-sm-12 col-12 text-center py-2">
              <section className="body">
                <div className=" body-inner no-padding  table-responsive">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Parish Name</th>
                        <th>Varado</th>
                        <th>Parish Code</th>
                        {user.userGroupName !== "Super Admin" && <th>Op</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {currentDatas &&
                        currentDatas.map((parishData, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{parishData.parishName}</td>
                              <td>
                                {parishData &&
                                  parishData &&
                                  parishData.output[0].varadoName}
                              </td>
                              <td>{parishData.parishCode}</td>
                              {user.userGroupName !== "Super Admin" && (
                                <td>
                                  {/* <img
                                    className="img_icon_size log"
                                    onClick={() => onUpdate(parishData, idx)}
                                    src={require("../../static/images/delete.png")}
                                    alt="Deactivate"
                                    title="Deactivate"
                                  />{" "} */}
                                  &nbsp;
                                  <img
                                    className="img_icon_size log"
                                    onClick={() => onEdit(parishData, idx)}
                                    src={require("../../static/images/edit_icon.png")}
                                    alt="Edit Parish"
                                    title="Edit Parish"
                                  />
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 no_padding">
                    {parishData && parishData.length !== 0 ? (
                      <Pagination
                        dataPerPage={dataPerPage}
                        totalData={parishData.length}
                        paginate={paginate}
                        currentPage={currentData}
                      />
                    ) : (
                      <Fragment />
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-12 align_right">
                    <label>No. of Parishes : {parishData.length}</label>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        <Modal
          show={showAddParishModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Add Parish Details</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleAddParishModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <AddParishDetails onAddParishModalChange={onAddParishModalChange} />
          </Modal.Body>
        </Modal>
        <Modal
          show={showUpdateModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Edit Parish Details </h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleUpdateModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditParishDetails
              parishes={userData}
              onUpdateModalChange={onUpdateModalChange}
            />
          </Modal.Body>
        </Modal>

        {/* <Modal
          show={showDeactiveModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Deactivate Parish</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleDeactiveModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <DeactiveParish
              parishData={userDatas}
              onDeactiveModalChange={onDeactiveModalChange}
            />
          </Modal.Body>
        </Modal> */}
      </div>
    </Fragment>
  );
};

AllParish.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getAllParish: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getAllParish,
  getVarados,
})(AllParish);
