import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
// import ChangeMeetingDate from "./ChangeMeetingDate";

import {
  getVerifiedMeetingDetailsSort,
  getMeetingTransactionData,
  batchAndMeetingDetails,
  unsetCheckAvailableMeetingDate,
} from "../../actions/shg";

const EditVerifiedMeeting = ({
  auth: { isAuthenticated, user, users },
  shg: { verifiedmeetingdetailssort },
  getVerifiedMeetingDetailsSort,
  getMeetingTransactionData,
  batchAndMeetingDetails,
  unsetCheckAvailableMeetingDate,
}) => {
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  useEffect(() => {
    getVerifiedMeetingDetailsSort(batchIdValue);
  }, [getVerifiedMeetingDetailsSort]);

  const handleVerifyEditBatch = (verifiedmeetingdetailssort) => {
    localStorage.removeItem("batchTransactionData");
    const batchIdData = {
      batchId: verifiedmeetingdetailssort.mdBatchId,
      batchTransactionType: verifiedmeetingdetailssort.batchTransactionType,
      mdDate: verifiedmeetingdetailssort.mdDate,
      mdBatchId: verifiedmeetingdetailssort.mdBatchId,
      batchIdVal: batchIdValue.batchIdVal,
      batchName: batchIdValue.batchName,
      callFrom: "Report",
    };
    let meetingBatchInfo = batchIdData;
    getMeetingTransactionData(meetingBatchInfo);
    batchAndMeetingDetails(batchIdData);
  };

  // To change meeting date option

  const [userData1, setUserData1] = useState(null);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const handleChangeModalClose = () => setShowChangeModal(false);

  const onChangeModalChange = (e) => {
    if (e) {
      handleChangeModalClose();
    }
  };
  const onChangeMeetingDate = (verifiedmeetingdetailssort, idx) => {
    unsetCheckAvailableMeetingDate();
    setShowChangeModal(true);
    setUserData1(verifiedmeetingdetailssort);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">
            {" "}
            Meetings of {batchIdValue.batchName}
          </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 py-3">
              <Link to="/edit-meeting">
                <img
                  className="img_icon_size log float-right"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Meeting Date</th>
                        <th>Entered By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {verifiedmeetingdetailssort &&
                        verifiedmeetingdetailssort.map(
                          (verifiedmeetingdetailssort, idx) => {
                            var ED = verifiedmeetingdetailssort.mdDate.split(
                              /\D/g
                            );
                            var mdDate = [ED[2], ED[1], ED[0]].join("-");
                            return (
                              <tr key={idx}>
                                {idx === 0 ? (
                                  <>
                                    <td>
                                      {" "}
                                      <Link
                                        to={{
                                          pathname:
                                            verifiedmeetingdetailssort &&
                                            verifiedmeetingdetailssort.batchTransactionType ===
                                              "Memberwise"
                                              ? "/Edit-Indivisual-membermeeting"
                                              : "/Edit-Indivisual-membermeeting",
                                        }}
                                        className="btnLink"
                                        onClick={() =>
                                          handleVerifyEditBatch(
                                            verifiedmeetingdetailssort
                                          )
                                        }
                                      >
                                        {mdDate}
                                      </Link>
                                    </td>
                                    <td>
                                      {
                                        verifiedmeetingdetailssort.mdEnteredByName
                                      }
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      {" "}
                                      <i>{mdDate}</i>
                                    </td>
                                    <td>
                                      {
                                        verifiedmeetingdetailssort.mdEnteredByName
                                      }
                                    </td>
                                  </>
                                )}
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-md-6 col-sm-11 col-11 align_right">
                  <label>
                    <b>Meeting Count: {verifiedmeetingdetailssort.length}</b>
                  </label>
                </div>
              </section>
            </div>
          </div>
        </section>

        <Modal
          show={showChangeModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10 col-md-10 col-sm-10 col-10">
              <h3 className="modal-title text-center">Change Meeting Date </h3>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
              <button onClick={handleChangeModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body></Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

EditVerifiedMeeting.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getVerifiedMeetingDetailsSort: PropTypes.func.isRequired,
  getMeetingTransactionData: PropTypes.func.isRequired,
  batchAndMeetingDetails: PropTypes.func.isRequired,
  unsetCheckAvailableMeetingDate: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getVerifiedMeetingDetailsSort,
  getMeetingTransactionData,
  batchAndMeetingDetails,
  unsetCheckAvailableMeetingDate,
})(EditVerifiedMeeting);
