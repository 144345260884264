import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  Modal,
  // NavDropdown,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";
import { logout, getInstitutionData } from "../../actions/auth";
import "react-datepicker/dist/react-datepicker.css";

import Spinner from "../layout/Spinner";

const Header = ({
  auth: { isAuthenticated, loading, user, institutionData },
  logout,
  getInstitutionData,
}) => {
  useEffect(() => {
    if (isAuthenticated) {
      getInstitutionData();
    }
  }, [getInstitutionData]);

  const LoginMethods = [
    { value: "Co-ordinator", label: "Co-ordinator" },
    { value: "Incharge", label: "Incharge" },
  ];

  let loginTypeVal = localStorage.getItem("loginType");
  const [formData, setFormData] = useState({
    LoginType: loginTypeVal
      ? { value: loginTypeVal, label: loginTypeVal }
      : LoginMethods[0],
    isSubmitted: false,
  });

  const { LoginType, isSubmitted } = formData;

  const onLoginTypeChange = (e) => {
    localStorage.setItem("loginType", e.value);
    if (e) {
      setFormData({
        ...formData,
        LoginType: e,
      });
    }
    window.location.reload();
  };

  const [showLogout, setShowLogout] = useState(false);

  const handleLogoutModalClose = () => setShowLogout(false);
  const handleLogoutModalShow = () => setShowLogout(true);

  const LogoutModalClose = () => {
    setFormData({
      ...formData,
      LoginType: LoginMethods[0],
    });
    handleLogoutModalClose();
    logout();
  };

  const closeMenuForMobile = (openClose) => {
    // if (openClose === "isOpen") {
    //   if (window.innerWidth <= 992) {
    //     var element = document.getElementById("basic-navbar-nav");
    //     element.classList.remove("show");
    //     var element = document.getElementById("nav-toggler");
    //     element.classList.add("collapsed");
    //   }
    // } else if (openClose === "isClosed") {
    //   if (window.innerWidth <= 992) {
    //     var element1 = document.getElementById("nav-toggler");
    //     element1.classList.remove("collapsed");
    //     var element = document.getElementById("basic-navbar-nav");
    //     element.classList.add("show");
    //   }
    // }
  };
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const allAccess = [];
  institutionData &&
    institutionData.access &&
    institutionData.access.map((access) => allAccess.push(access.accessName));

  const allRights = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.groupRights &&
    loggedUserInstitutionData.groupRights.map((groupRights) =>
      allRights.push(groupRights)
    );

  return !isAuthenticated || !user ? (
    <Spinner />
  ) : (
    <Fragment>
      <header>
        <Container id="header_navbar">
          <Navbar
            className="navbar_height top_menu sidebar"
            expand="lg"
            collapseOnSelect
            style={{ padding: "1em 1em" }}
          >
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              id="nav-toggler"
              onClick={() => closeMenuForMobile("isClosed")}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto ">
                <NavItem>
                  {!loading && isAuthenticated && user ? (
                    <Fragment>
                      <Navbar.Brand>
                        <Link to="/my-profile">
                          <img
                            className="log_size"
                            alt=""
                            src={`${institutionData.institutionProfile}`}
                          />
                        </Link>
                      </Navbar.Brand>
                      <NavLink
                        to="/my-profile"
                        activeStyle={{
                          color: "#dffd35",
                          fontSize: "17px",
                          textDecoration: "none",
                        }}
                      >
                        {user.userFullName}
                      </NavLink>
                      {user.userGroupName === "Co-ordinator" &&
                        user.isIncharge === true && (
                          <Select
                            styles={{
                              control: (base) => ({
                                ...base,
                                background: "#144c5a",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                color: "#fff",
                              }),
                              input: (base) => ({
                                ...base,
                                color: "#fff",
                              }),
                            }}
                            name="LoginType"
                            options={LoginMethods}
                            isSearchable={false}
                            value={LoginType}
                            placeholder="Select"
                            onChange={(e) => onLoginTypeChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        )}

                      {((allAccess && allAccess.includes("shg")) ||
                        user.userGroupName === "Super Admin") && (
                        <NavLink
                          to="/landing-page"
                          onClick={() => closeMenuForMobile("isOpen")}
                          activeStyle={{
                            color: "#dffd35",
                            fontSize: "17px",
                            textDecoration: "none",
                          }}
                        >
                          Dashboard
                        </NavLink>
                      )}
                      {allAccess && allAccess.includes("shg") && (
                        <>
                          {(user.userGroupName === "Super Admin" ||
                            user.userGroupName === "Admin" ||
                            user.userGroupName === "Incharge" ||
                            user.userGroupName === "Co-ordinator") && (
                            <NavLink
                              onClick={() => {
                                localStorage.removeItem("selBatchDetails");
                                localStorage.removeItem("PageIndex");
                              }}
                              to="/all-batches"
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              Batches
                            </NavLink>
                          )}
                          {user.userGroupName === "Member" && (
                            <NavLink
                              to={{
                                pathname:
                                  user && user.userGroupName === "Member"
                                    ? "/member-all-members"
                                    : "/all-members",
                              }}
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              All Members
                            </NavLink>
                          )}
                          {(user.userGroupName === "Admin" ||
                            user.userGroupName === "Incharge" ||
                            user.userGroupName === "Co-ordinator") && (
                            <NavLink
                              onClick={() => {
                                localStorage.removeItem("selVerfyBatchDetails");
                              }}
                              to="/all-meetings-verification"
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              Verify
                            </NavLink>
                          )}
                          {(user.userGroupName === "Admin" ||
                            user.userGroupName === "Incharge" ||
                            user.userGroupName === "Auditor" ||
                            user.userGroupName === "Co-ordinator") && (
                            <NavLink
                              to="/all-reports"
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              Reports
                            </NavLink>
                          )}
                          {(user.userGroupName === "Super Admin" ||
                            user.userGroupName === "Admin") && (
                            <NavLink
                              to="/all-users"
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              All User
                            </NavLink>
                          )}
                          {(user.userGroupName === "Super Admin" ||
                            user.userGroupName === "Admin") && (
                            <>
                              <NavLink
                                to="/all-area"
                                onClick={() => closeMenuForMobile("isOpen")}
                                activeStyle={{
                                  color: "#dffd35",
                                  fontSize: "17px",
                                  textDecoration: "none",
                                }}
                              >
                                Area
                              </NavLink>
                              {allPermission &&
                                allPermission.includes("Religion") && (
                                  <NavLink
                                    to="/all-religion-caste-types"
                                    onClick={() => closeMenuForMobile("isOpen")}
                                    activeStyle={{
                                      color: "#dffd35",
                                      fontSize: "17px",
                                      textDecoration: "none",
                                    }}
                                  >
                                    Caste Setting
                                  </NavLink>
                                )}
                              <NavLink
                                to="/All-OtherLoan-Types"
                                onClick={() => closeMenuForMobile("isOpen")}
                                activeStyle={{
                                  color: "#dffd35",
                                  fontSize: "17px",
                                  textDecoration: "none",
                                }}
                              >
                                Other Loan Types
                              </NavLink>
                              <NavLink
                                to="/All-SHG-Category"
                                onClick={() => closeMenuForMobile("isOpen")}
                                activeStyle={{
                                  color: "#dffd35",
                                  fontSize: "17px",
                                  textDecoration: "none",
                                }}
                              >
                                SHG Category
                              </NavLink>
                              {allPermission &&
                                allPermission.includes("Occupation") && (
                                  <NavLink
                                    to="/all-occupation-setting"
                                    onClick={() => closeMenuForMobile("isOpen")}
                                    activeStyle={{
                                      color: "#dffd35",
                                      fontSize: "17px",
                                      textDecoration: "none",
                                    }}
                                  >
                                    Occupations
                                  </NavLink>
                                )}
                            </>
                          )}
                        </>
                      )}
                      {user.userGroupName === "Super Admin" && (
                        <>
                          <NavLink
                            to="/all-access"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            All Access
                          </NavLink>
                          <NavLink
                            to="/all-Permission"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            All Permissions
                          </NavLink>
                        </>
                      )}
                      {allPermission &&
                      allPermission.includes("SingleSkill") ? (
                        <>
                          <NavLink
                            onClick={() => {
                              localStorage.removeItem("selBatchDetails");
                              localStorage.removeItem("PageIndex");
                            }}
                            to="/all-batches"
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            Batches
                          </NavLink>
                          <NavLink
                            to="/all-reports"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            Reports
                          </NavLink>
                          <NavLink
                            to="/all-users"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            All User
                          </NavLink>
                          <NavLink
                            to="/all-area"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            Area
                          </NavLink>
                        </>
                      ) : (
                        <></>
                      )}
                      {allAccess &&
                        allAccess.includes("skill") &&
                        (user.userGroupName === "Super Admin" ||
                          user.userGroupName === "Admin" ||
                          user.userGroupName === "Incharge" ||
                          user.userGroupName === "Co-ordinator") && (
                          <>
                            <NavLink
                              to="/all-programs"
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              All Programs
                            </NavLink>
                            <NavLink
                              to="/add-skill-training"
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              Add Skill Training
                            </NavLink>
                            {/* <NavLink
                              to="/skill-training-report"
                              onClick={() => closeMenuForMobile("isOpen")}
                              activeStyle={{
                                color: "#dffd35",
                                fontSize: "17px",
                                textDecoration: "none",
                              }}
                            >
                              Skill Report
                            </NavLink> */}
                          </>
                        )}

                      <NavLink
                        to="/change-password"
                        onClick={() => closeMenuForMobile("isOpen")}
                        activeStyle={{
                          color: "#dffd35",
                          fontSize: "17px",
                          textDecoration: "none",
                        }}
                      >
                        Change Password
                      </NavLink>

                      {allRights &&
                      allRights.includes(
                        user.userGroupName + "TrainingVideo"
                      ) ? (
                        <>
                          <NavLink
                            to="/shgLangList"
                            onClick={() => closeMenuForMobile("isOpen")}
                            activeStyle={{
                              color: "#dffd35",
                              fontSize: "17px",
                              textDecoration: "none",
                            }}
                          >
                            Training Videos
                          </NavLink>
                        </>
                      ) : (
                        <></>
                      )}

                      <NavLink to="#" onClick={() => handleLogoutModalShow()}>
                        <span className="mb-4">Logout</span>
                      </NavLink>

                      <div className="copyRightCss my-2">
                        &copy; {new Date().getFullYear()} &nbsp;
                        <a
                          href="https://www.pinnacletechnologies.in"
                          target="_blank"
                          className="linkCopyRight"
                        >
                          Pinnacle Technologies
                        </a>
                        .<br /> All rights reserved.
                      </div>
                    </Fragment>
                  ) : (
                    <NavItem></NavItem>
                  )}
                </NavItem>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>

        {/* Logout Modal */}
        <Modal
          show={showLogout}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="logout-modal"
        >
          <Modal.Header className="confirmbox-heading">
            <h4 className="mt-0">Confirmation</h4>
          </Modal.Header>
          <Modal.Body>
            <h5>Are you sure you want to logout?</h5>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn_green_bg"
              onClick={() => LogoutModalClose()}
            >
              YES
            </button>
            <button
              className="btn btn_green_bg"
              onClick={() => handleLogoutModalClose()}
            >
              NO
            </button>
          </Modal.Footer>
        </Modal>
      </header>
    </Fragment>
  );
};
Header.propTypes = {
  logout: PropTypes.func.isRequired,
  getInstitutionData: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout, getInstitutionData })(Header);
