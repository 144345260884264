import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { editFamilyDetails, getFamilySubOccupation } from "../../actions/shg";
import Spinner from "../layout/Spinner";
import GovtCards from "./GovtCards.js";
import {
  getReligions,
  getCaste,
  getSubcasteDetails,
} from "../../actions/religionSetting";

const EditFamilyMember = ({
  auth: { isAuthenticated, user, users, loading },
  religionSetting: { religionsData, activeCaste, getSubcaste },
  shg: { activeOccupation, getAllSubOccupation, getAllFamilySubOccupation },
  allFamMembersData,
  getFamilySubOccupation,
  editFamilyDetails,
  onEditModalChange,
  memberdata,
  getSubcasteDetails,
  getCaste,
  getReligions,
}) => {
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  useEffect(() => {
    getReligions();
  }, [getReligions]);
  useEffect(() => {
    getCaste({
      religionInfo: allFamMembersData.familyMemberReligionId
        ? allFamMembersData.familyMemberReligionId
        : null,
    });
  }, [getCaste]);
  useEffect(() => {
    getSubcasteDetails({
      cIdVal: allFamMembersData.familyMemberCasteId
        ? allFamMembersData.familyMemberCasteId
        : null,
    });
  }, [getSubcasteDetails]);

  useEffect(() => {
    getFamilySubOccupation({
      occupationInfo: allFamMembersData.familyMemberOcupationId
        ? allFamMembersData.familyMemberOcupationId
        : null,
    });
  }, [getFamilySubOccupation]);

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );
  const allAccess = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.access &&
    loggedUserInstitutionData.access.map((access) =>
      allAccess.push(access.accessName)
    );
  const MemDisabilityList = [
    { value: "Blindness", label: "Blindness", DlId: "DL01" },
    { value: "Low-vision", label: "Low-vision", DlId: "DL02" },
    {
      value: "Leprosy Cured persons",
      label: "Leprosy Cured persons",
      DlId: "DL03",
    },
    {
      value: "Hearing Impairment (deaf and hard of hearing)",
      label: "Hearing Impairment (deaf and hard of hearing)",
      DlId: "DL04",
    },
    {
      value: "Locomotor Disability",
      label: "Locomotor Disability",
      DlId: "DL05",
    },
    { value: "Dwarfism", label: "Dwarfism", DlId: "DL06" },
    {
      value: "Intellectual Disability",
      label: "Intellectual Disability",
      DlId: "DL07",
    },
    { value: "Mental Illness", label: "Mental Illness", DlId: "DL08" },
    {
      value: "Autism Spectrum Disorder",
      label: "Autism Spectrum Disorder",
      DlId: "DL09",
    },
    { value: "Cerebral Palsy", label: "Cerebral Palsy", DlId: "DL10" },
    { value: "Muscular Dystrophy", label: "Muscular Dystrophy", DlId: "DL11" },
    {
      value: "Chronic Neurological conditions",
      label: "Chronic Neurological conditions",
      DlId: "DL12",
    },
    {
      value: "Specific Learning Disabilities",
      label: "Specific Learning Disabilities",
      DlId: "DL13",
    },
    { value: "Multiple Sclerosis", label: "Multiple Sclerosis", DlId: "DL14" },
    {
      value: "Speech and Language disability",
      label: "Speech and Language disability",
      DlId: "DL15",
    },
    { value: "Thalassemia", label: "Thalassemia", DlId: "DL16" },
    { value: "Hemophilia", label: "Hemophilia", DlId: "DL17" },
    {
      value: "Sickle Cell disease",
      label: "Sickle Cell disease",
      DlId: "DL18",
    },
    {
      value: "Multiple Disabilities including deafblindness",
      label: "Multiple Disabilities including deafblindness",
      DlId: "DL19",
    },
    { value: "Acid Attack victim", label: "Acid Attack victim", DlId: "DL20" },
    {
      value: "Parkinson's disease",
      label: "Parkinson's disease",
      DlId: "DL21",
    },
  ];

  const GenderOpt = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  const EducationOpt = [
    { value: "Primary", label: "Primary" },
    { value: "Higher primary", label: "Higher primary" },
    { value: "High School", label: "High School" },
    { value: "PUC", label: "PUC" },
    { value: "Degree", label: "Degree" },
    { value: "PG", label: "PG" },
    { value: "Others", label: "Others" },
  ];

  const [familyDisabilitiesChecker, setFamilyDisabilitiesChecker] = useState(
    allFamMembersData && allFamMembersData.isFamDisable
      ? allFamMembersData.isFamDisable
      : false
  );

  const [showFamHide, setFamShowHide] = useState({
    showFamOtherEducationSection:
      allFamMembersData && allFamMembersData.familyMemberEducation === "Others"
        ? true
        : false,
  });
  const { showFamOtherEducationSection } = showFamHide;

  //formData
  const [formData, setFormData] = useState({
    familyMemberName:
      allFamMembersData && allFamMembersData.familyMemberName
        ? allFamMembersData.familyMemberName
        : "",
    familyMemberPhone:
      allFamMembersData && allFamMembersData.familyMemberPhone
        ? allFamMembersData.familyMemberPhone
        : "",
    familyMemberAge:
      allFamMembersData && allFamMembersData.familyMemberAge
        ? allFamMembersData.familyMemberAge
        : "",
    familyMemberGender:
      allFamMembersData && allFamMembersData.familyMemberGender
        ? {
            value: allFamMembersData.familyMemberGender,
            label: allFamMembersData.familyMemberGender,
          }
        : "",
    familyMemberRelationship:
      allFamMembersData && allFamMembersData.familyMemberRelationship
        ? allFamMembersData.familyMemberRelationship
        : "",
    familyMemberMaritalStatus:
      allFamMembersData && allFamMembersData.familyMemberMaritalStatus
        ? allFamMembersData.familyMemberMaritalStatus
        : "",
    familyMemberEducation:
      allFamMembersData && allFamMembersData.familyMemberEducation
        ? {
            value: allFamMembersData.familyMemberEducation,
            label: allFamMembersData.familyMemberEducation,
          }
        : "",
    otherFamilyEducation:
      allFamMembersData && allFamMembersData.otherFamilyEducation
        ? allFamMembersData.otherFamilyEducation
        : "",
    familyMemberOcupation:
      allFamMembersData && allFamMembersData.familyMemberOcupation
        ? allFamMembersData.familyMemberOcupation
        : "",
    familySubOccupationId:
      allFamMembersData && allFamMembersData.familySubOccupationId
        ? allFamMembersData.familySubOccupationId
        : "",
    familySubOccupation:
      allFamMembersData && allFamMembersData.familySubOccupation
        ? allFamMembersData.familySubOccupation
        : "",
    familyMemberMonthlyIncome:
      allFamMembersData && allFamMembersData.familyMemberMonthlyIncome
        ? allFamMembersData.familyMemberMonthlyIncome
        : "",
    familyMemberAnnualIncome:
      allFamMembersData && allFamMembersData.familyMemberAnnualIncome
        ? allFamMembersData.familyMemberAnnualIncome
        : "",
    familyMemberDisabilityId:
      allFamMembersData && allFamMembersData.familyMemberDisabilityId
        ? allFamMembersData.familyMemberDisabilityId
        : "",
    familyMemberDisabilityType:
      allFamMembersData && allFamMembersData.familyMemberDisabilityType
        ? {
            value: allFamMembersData.familyMemberDisabilityType,
            label: allFamMembersData.familyMemberDisabilityType,
          }
        : "",
    familyUdidNo:
      allFamMembersData && allFamMembersData.familyUdidNo
        ? allFamMembersData.familyUdidNo
        : "",

    isSubmitted: false,
  });
  const {
    familyMemberName,
    familyMemberAge,
    familyMemberPhone,
    familyMemberGender,
    familyMemberRelationship,
    familyMemberMaritalStatus,
    familyMemberEducation,
    otherFamilyEducation,
    familyMemberOcupation,
    familyMemberMonthlyIncome,
    familyMemberAnnualIncome,
    familyMemberDisabilityId,
    familyMemberDisabilityType,
    familyUdidNo,
    isSubmitted,
  } = formData;

  const allOccupation = [];
  activeOccupation &&
    activeOccupation.map((occupation) =>
      allOccupation.push({
        occupationId: occupation._id,
        label: occupation.occcupationName,
        value: occupation.occcupationName,
      })
    );

  const [occupation, getOccupationData] = useState();
  if (
    !occupation &&
    allFamMembersData.familyMemberOcupationId &&
    allOccupation.length > 0
  ) {
    getOccupationData(
      allFamMembersData
        ? allOccupation &&
            allOccupation.filter(
              (x) =>
                x.occupationId === allFamMembersData.familyMemberOcupationId
            )[0]
        : ""
    );
  }
  const [occupationId, setOccupationID] = useState(
    allFamMembersData.familyMemberOcupationId
  );
  const [occupationName, setOccupationName] = useState();

  const onOccupationChange = (e) => {
    getOccupationData(e);
    setOccupationID(e.occupationId ? e.occupationId : null);
    setOccupationName(e.value ? e.value : "");
    getFamilySubOccupation({
      occupationInfo: e.occupationId,
    });
  };

  const onFammemberEducationChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        familyMemberEducation: e,
      });
    }
    if (e.value === "Others") {
      setFamShowHide({
        ...showFamHide,
        showFamOtherEducationSection: true,
      });
    } else {
      setFamShowHide({
        ...showFamHide,
        showFamOtherEducationSection: false,
      });
    }
  };

  let anualIncomeVal = 0;
  anualIncomeVal = familyMemberMonthlyIncome * 12;

  const handleOnFamilyChange = () => {
    setFamilyDisabilitiesChecker(!familyDisabilitiesChecker);
    if (!familyDisabilitiesChecker) {
      setFormData({
        ...formData,
        familyMemberDisabilityId: null,
        familyMemberDisabilityType: "",
        familyUdidNo: "",
      });
    }
  };

  const onFamDisabilityChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        familyMemberDisabilityType: e,
        familyMemberDisabilityId: e.DlId,
      });
      // setError({
      //   ...error,
      //   DisabilityTypeErrorStyle: { color: "#000" },
      // });
    }
  };

  const onInputChange1 = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onFamilyMemberGenderChange = (e) => {
    if (e) {
      setFormData({ ...formData, familyMemberGender: e });
    }
  };

  const onUpdate = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: allFamMembersData ? allFamMembersData._id : "",
      familyMemberName: familyMemberName,
      familyMemberPhone: familyMemberPhone,
      familyMemberAge: familyMemberAge,
      familyMemberGender: familyMemberGender.value,
      familyMemberRelationship: familyMemberRelationship,
      familyMemberMaritalStatus: familyMemberMaritalStatus,
      familyMemberEducation: familyMemberEducation.value,
      otherFamilyEducation: otherFamilyEducation ? otherFamilyEducation : "",
      otherFamilyEducation:
        otherFamilyEducation &&
        familyMemberEducation &&
        familyMemberEducation.value === "Others"
          ? otherFamilyEducation
          : "",
      familyMemberOcupationId: occupationId,
      familyMemberOcupation: occupationName,
      familyMemberSubOccupationId: familySubOccupationId,
      familyMemberSubOccupation: familySubOccupationName,
      familyMemberMonthlyIncome: familyMemberMonthlyIncome,
      familyMemberAnnualIncome: anualIncomeVal,
      isFamDisable: familyDisabilitiesChecker,
      familyMemberDisabilityId: familyDisabilitiesChecker
        ? familyMemberDisabilityId
        : null,
      familyMemberDisabilityType: familyDisabilitiesChecker
        ? familyMemberDisabilityType.value
        : "",
      familyUdidNo: familyDisabilitiesChecker ? familyUdidNo : "",
      familyCards: SelectedCardsFamily,
      familyMemberReligionId: familyReligionId,
      familyMemberReligion: familyReligionName,
      familyMemberCasteId: familyCasteId,
      familyMemberCaste: familyCasteName,
      familyMemberSubCasteId: familySubCasteId,
      familyMemberSubCaste: familySubCasteName,
      memberIdVal: memberdata._id,
    };
    editFamilyDetails(finalData);
    onEditModalChange(true);
  };
  //GOVT CARDS
  //FAMILY
  const [SelectedCardsFamily, setSelectedCardsFamily] = useState(
    allFamMembersData && allFamMembersData.familyCards
      ? allFamMembersData.familyCards
      : []
  );
  const [showCardsFamilyModal, setshowCardsFamilyModal] = useState(false);
  const handleCardsFamilyModalClose = () => setshowCardsFamilyModal(false);
  const onFamilyClickHandler = () => {
    setshowCardsFamilyModal(true);
  };
  //GOVT CARDS END

  //CASTE RELIGION START
  //Family Religion

  const allFamilyReligionsData = [];
  religionsData.map((religion) =>
    allFamilyReligionsData.push({
      rId: religion._id,
      label: religion.religionName,
      value: religion.religionName,
    })
  );

  const [familyReligion, getFamilyReligionData] = useState();
  if (
    !familyReligion &&
    allFamMembersData.familyMemberReligionId &&
    allFamilyReligionsData.length > 0
  ) {
    getFamilyReligionData(
      allFamMembersData
        ? allFamilyReligionsData &&
            allFamilyReligionsData.filter(
              (x) => x.rId === allFamMembersData.familyMemberReligionId
            )[0]
        : ""
    );
  }
  const [familyReligionId, setFamilyReligionID] = useState();
  const [familyReligionName, setFamilyReligionName] = useState();

  const onFamilyReligionChange = (e) => {
    //Required Validation end
    getFamilyReligionData(e);
    setFamilyReligionID(e.rId ? e.rId : null);
    setFamilyReligionName(e.value ? e.value : "");
    getCaste({
      religionInfo: e.rId,
    });

    getFamilyCasteData("");
    setFamilyCasteID(null);
    setFamilyCasteName("");
    setFamilySubCaste("");
    setFamilySubCasteID(null);
    setFamilySubCasteName("");
  };

  //Family Caste
  const allFamilyCasteData = [];
  activeCaste.map((caste) =>
    allFamilyCasteData.push({
      cId: caste._id,
      label: caste.casteName,
      value: caste.casteName,
    })
  );

  const [familyCaste, getFamilyCasteData] = useState();
  if (
    !familyCaste &&
    allFamMembersData.familyMemberCasteId &&
    allFamilyCasteData.length > 0
  ) {
    getFamilyCasteData(
      allFamMembersData
        ? allFamilyCasteData &&
            allFamilyCasteData.filter(
              (x) => x.cId === allFamMembersData.familyMemberCasteId
            )[0]
        : ""
    );
  }
  const [familyCasteId, setFamilyCasteID] = useState();
  const [familyCasteName, setFamilyCasteName] = useState();

  const allSubOccupation = [];
  getAllFamilySubOccupation &&
    getAllFamilySubOccupation.map((subOccupation) =>
      allSubOccupation.push({
        subOccupationId: subOccupation._id,
        label: subOccupation.subOccupationName,
        value: subOccupation.subOccupationName,
      })
    );
  const [familySubOccupation, setFamilySubOccupationData] = useState();
  if (
    !familySubOccupation &&
    allFamMembersData.familyMemberSubOccupationId &&
    allSubOccupation.length > 0
  ) {
    setFamilySubOccupationData(
      allFamMembersData
        ? allSubOccupation &&
            allSubOccupation.filter(
              (x) =>
                x.subOccupationId ===
                allFamMembersData.familyMemberSubOccupationId
            )[0]
        : ""
    );
  }
  const [familySubOccupationId, setFamilySubOccupationID] = useState(
    allFamMembersData.familyMemberSubOccupationId
  );
  const [familySubOccupationName, setFamilySubOccupationName] = useState(
    allFamMembersData.familyMemberSubOccupation
  );
  const onFamilySubOccupationChange = (e) => {
    setFamilySubOccupationData(e);
    setFamilySubOccupationID(e.subOccupationId ? e.subOccupationId : null);
    setFamilySubOccupationName(e.value ? e.value : "");
  };

  const onFamilyCasteChange = (e) => {
    getFamilyCasteData(e);
    setFamilyCasteID(e.cId ? e.cId : null);
    setFamilyCasteName(e.value ? e.value : "");
    getSubcasteDetails({ cIdVal: e.cId });
    setFamilySubCaste("");
    setFamilySubCasteID(null);
    setFamilySubCasteName("");
  };

  //Family Sub Caste
  const allFamilySubCaste = [];
  getSubcaste.map((subCaste) =>
    allFamilySubCaste.push({
      subCasteId: subCaste._id,
      label: subCaste.subcasteName,
      value: subCaste.subcasteName,
    })
  );

  const [familySubCaste, setFamilySubCaste] = useState();
  if (
    !familySubCaste &&
    allFamMembersData.familyMemberSubCasteId &&
    allFamilySubCaste.length > 0
  ) {
    setFamilySubCaste(
      allFamMembersData
        ? allFamilySubCaste &&
            allFamilySubCaste.filter(
              (x) => x.subCasteId === allFamMembersData.familyMemberSubCasteId
            )[0]
        : ""
    );
  }
  const [familySubCasteId, setFamilySubCasteID] = useState();
  const [familySubCasteName, setFamilySubCasteName] = useState();
  const onFamilySubCasteChange = (e) => {
    setFamilySubCaste(e);
    setFamilySubCasteID(e.subCasteId ? e.subCasteId : null);
    setFamilySubCasteName(e.value ? e.value : "");
  };
  //CASTE RELIGION END
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onUpdate(e)}>
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control"> Name :</label>
              <input
                type="text"
                name="familyMemberName"
                value={familyMemberName}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
                required
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control"> Phone :</label>
              <input
                type="text"
                name="familyMemberPhone"
                value={familyMemberPhone}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control"> Age :</label>
              <input
                type="text"
                name="familyMemberAge"
                value={familyMemberAge}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control"> Gender :</label>
              {/* <input
                type="text"
                name="familyMemberGender"
                value={familyMemberGender}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
              /> */}
              <Select
                name="familyMemberGender"
                options={GenderOpt}
                isSearchable={false}
                value={familyMemberGender}
                placeholder="Select Gender"
                onChange={(e) => onFamilyMemberGenderChange(e)}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control">Relationship :</label>
              <input
                type="text"
                name="familyMemberRelationship"
                value={familyMemberRelationship}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control">MaritalStatus :</label>
              <input
                type="text"
                name="familyMemberMaritalStatus"
                value={familyMemberMaritalStatus}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control">Education :</label>
              <Select
                name="familyMemberEducation"
                options={EducationOpt}
                isSearchable={false}
                value={familyMemberEducation}
                placeholder="Select"
                onChange={(e) => onFammemberEducationChange(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
            </div>
            {showFamOtherEducationSection && (
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <label className="label-control">Other Education :</label>
                <input
                  type="text"
                  name="otherFamilyEducation"
                  value={otherFamilyEducation}
                  className="form-control"
                  onChange={(e) => onInputChange1(e)}
                  required
                />
              </div>
            )}
            {allPermission && allPermission.includes("Occupation") && (
              <>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <label className="label-control"> Occupation :</label>
                  <Select
                    name="stateName"
                    options={allOccupation}
                    isSearchable={true}
                    value={occupation}
                    placeholder="Select Occupation"
                    onChange={(e) => onOccupationChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                  {/* <input
                type="text"
                name="familyMemberOcupation"
                value={familyMemberOcupation}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
              /> */}
                </div>
              </>
            )}
            {allPermission && allPermission.includes("Sub Occupation") && (
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <label className="label-control">Sub Occupation :</label>
                <Select
                  name="familyMemberSubOccupation"
                  options={allSubOccupation}
                  isSearchable={true}
                  value={familySubOccupation}
                  placeholder="Select Sub Occupation"
                  onChange={(e) => onFamilySubOccupationChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
            )}
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control">Monthly Income :</label>
              <input
                type="text"
                name="familyMemberMonthlyIncome"
                value={familyMemberMonthlyIncome}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control">Annual Income :</label>
              <input
                type="text"
                name="familyMemberAnnualIncome"
                value={anualIncomeVal}
                // value={familyMemberAnnualIncome}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
                disabled
              />
            </div>
            {allPermission && allPermission.includes("Religion") && (
              <>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Religion :</label>
                  <Select
                    name="familyMemberReligion"
                    options={allFamilyReligionsData}
                    isSearchable={false}
                    value={familyReligion}
                    placeholder="Select"
                    onChange={(e) => onFamilyReligionChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Caste :</label>
                  <Select
                    name="familyMemberCaste"
                    options={allFamilyCasteData}
                    isSearchable={false}
                    value={familyCaste}
                    placeholder="Select"
                    onChange={(e) => onFamilyCasteChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <label className="label-control">Sub Caste :</label>
                  <Select
                    name="familyMemberSubCaste"
                    options={allFamilySubCaste}
                    isSearchable={false}
                    value={familySubCaste}
                    placeholder="Select"
                    onChange={(e) => onFamilySubCasteChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
              </>
            )}

            {allPermission && allPermission.includes("Disability") && (
              <>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <label className="label-control">
                    Have any disabilities? :
                  </label>
                  <input
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "50%",
                      display: "block",
                    }}
                    type="checkbox"
                    checked={familyDisabilitiesChecker}
                    id="Unconfirmed"
                    onChange={handleOnFamilyChange}
                  />
                </div>
                {familyDisabilitiesChecker && (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <label
                        className="label-control"
                        // style={DisabilityTypeErrorStyle}
                      >
                        Disability Type* :
                      </label>
                      <Select
                        name="familyMemberDisabilityType"
                        options={MemDisabilityList}
                        isSearchable={false}
                        value={familyMemberDisabilityType}
                        placeholder="Select"
                        onChange={(e) => onFamDisabilityChange(e)}
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <label className="label-control">UDID Number* :</label>
                      <input
                        type="text"
                        name="familyUdidNo"
                        value={familyUdidNo}
                        className="form-control"
                        onChange={(e) => onInputChange1(e)}
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {allPermission && allPermission.includes("GovtCards") && (
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                <label className="label-control">Govt Cards : &emsp;</label>
                <img
                  className="img_icon_size4 log underLine"
                  onClick={() => onFamilyClickHandler()}
                  src={require("../../static/images/cards.png")}
                  alt="Govt Cards"
                  title="Add Govt Cards"
                />
              </div>
            )}
          </div>

          <div className="col-md-10 col-lg-10 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
      {showCardsFamilyModal && (
        <GovtCards
          showCardsModal={showCardsFamilyModal}
          handleCardsModalClose={handleCardsFamilyModalClose}
          setSelectedCards={setSelectedCardsFamily}
          SelectedCards={SelectedCardsFamily}
        />
      )}
    </Fragment>
  );
};

EditFamilyMember.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  shg: state.shg,
  savedMessage: state.auth.savedMessage,
  religionSetting: state.religionSetting,
});

export default connect(mapStateToProps, {
  editFamilyDetails,
  getReligions,
  getCaste,
  getSubcasteDetails,
  getFamilySubOccupation,
})(EditFamilyMember);
