import React, { useState, Fragment } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  getShgMembers,
  getBatchInfo,
  getAllBatches,
  addImportMemberData,
} from "../../actions/shg";
import Spinner from "../layout/Spinner";
import EditMemberDetails from "./EditMemberDetails";
import DeactiveMember from "./DeactiveMember";
import { CSVLink } from "react-csv";

const AllMemberDetails = ({
  auth: { allUser, isAuthenticated, user, users },
  shg: { shgMembers, isSet },
  getBatchInfo,
  addImportMemberData,
  batchId,
  getShgMembers,
  getAllBatches,
}) => {
  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);
  const [userData, setUserData] = useState(null);
  const onEdit = (membersdata, idx) => {
    localStorage.setItem("membersdata", JSON.stringify(membersdata));
    // setShowUpdateModal(true);
    // setUserData(membersdata);
  };
  let selectedBatchIdData = JSON.parse(localStorage.getItem("selBatchIdData"));
  let selBatchDeactiveIdData = JSON.parse(
    localStorage.getItem("selBatchDeactiveIdData")
  );
  let selBatchId = JSON.parse(localStorage.getItem("selBatchId"));
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const [showDeactiveModal, setShowDeactiveModal] = useState(false);
  const handleDeactiveModalClose = () => setShowDeactiveModal(false);
  const onDeactiveModalChange = (e) => {
    if (e) {
      handleDeactiveModalClose();
    }
  };
  const TSPPTID = "633822795d0f704ad4298a1c";
  const [userDatas, setUserDatas] = useState(null);
  const onUpdate = (shgMembers, idx) => {
    setShowDeactiveModal(true);
    setUserDatas(shgMembers);
  };
  const [isSubmitted, setSubmitted] = useState(false);
  const onClickHandler = () => {
    localStorage.removeItem("selBatchInfo");
    const finalData = {
      batchId: selBatchId.batchId,
    };
    getBatchInfo(finalData);
    setSubmitted(true);
  };
  const [showHide, setShowHide] = useState({
    showBatchSection:
      loggedUserInstitutionData &&
      loggedUserInstitutionData.institutionType === "Zoned"
        ? true
        : false,
  });
  const { showBatchSection } = showHide;
  const [formData, setFormData] = useState({
    batchCsvPath: "",
    isSubmitted: false,
  });
  const handleImg = (e) => {
    setFormData({
      ...formData,
      batchCsvPath: e.target.files[0].name,
    });
  };
  const { batchCsvPath } = formData;
  const [showPathSettingModal, setShowPathModal] = useState(false);
  const handleShowPathModalClose = () => setShowPathModal(false);
  const onClickMember = () => {
    setShowPathModal(true);
  };
  const onFileUpload = (e) => {
    e.preventDefault();
    const finalData = {
      filePathName: batchCsvPath,
    };
    addImportMemberData(finalData);
    handleShowPathModalClose();
  };
  // const [isSubmitted, setSubmitted] = useState(false);
  // const handleGoToAllBatches = () => {
  //   const changeData = {
  //     institutionIdVal:
  //       selectedBatchIdData && selectedBatchIdData[0].institutionId,
  //     batchIdVal: selectedBatchIdData && selectedBatchIdData[0].batchId,
  //     batchName: selectedBatchIdData && selectedBatchIdData[0].batchName,
  //   };
  //   getAllBatches(changeData);
  // };

  //CSV Data
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );
  const csvMemberData = [
    [
      "SL.NO",
      // "Village Name",
      "SHG Name",
      "SHG Formation Date",
      "Member Name",
      "Joining Date",
      "Education",
      "Aadhar Number",
      "Designation",
      "Phone Number",
    ],
  ];

  // const villageName = JSON.parse(localStorage.getItem("ShgVillage")) || "";
  const shgData = JSON.parse(localStorage.getItem("ShgVillage"));
  selectedBatchIdData &&
    selectedBatchIdData.map((shgMembers, idx) => {
      return csvMemberData.push([
        idx + 1,
        // shgData.villageName,
        shgMembers.batchName,
        shgData.batchFormationDate,
        shgMembers.memberName,
        shgMembers.memberJoiningDate,
        shgMembers.memberEducation,
        shgMembers.memberAadharNo,
        shgMembers.memberDesg,
        shgMembers.memberPhone,
      ]);
    });
  if (isSet && isSubmitted) {
    return <Redirect to="/add-member-details" />;
  }
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2>
            All Member Details of :&nbsp;
            {selBatchId && selBatchId.batchName}
            {showBatchSection && (
              <h4> Parish Name: {selBatchId && selBatchId.parishName}</h4>
            )}
          </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-11 col-md-2 col-sm-2 col-2 py-3">
              <Link to="/all-batches">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              {user.userGroupName === "Super Admin" && (
                <img
                  className="img_icon_size log float-right ml-3"
                  onClick={() => onClickMember()}
                  src={require("../../static/images/import.png")}
                  alt="Add User"
                  title="Add Import Data"
                />
              )}
              {user.institutionId &&
              user.institutionId === "63f9aaf2224b9079853a9677" &&
              selectedBatchIdData &&
              selectedBatchIdData.length < 20 ? (
                <Link to="#">
                  <img
                    className="img_icon_size log float-right"
                    onClick={() => onClickHandler()}
                    src={require("../../static/images/add-icon.png")}
                    alt="Add Member"
                    title="Add Member"
                  />
                </Link>
              ) : user.institutionId &&
                user.institutionId !== "63f9aaf2224b9079853a9677" ? (
                <Link to="#">
                  <img
                    className="img_icon_size log float-right"
                    onClick={() => onClickHandler()}
                    src={require("../../static/images/add-icon.png")}
                    alt="Add Member"
                    title="Add Member"
                  />
                </Link>
              ) : (
                <Fragment></Fragment>
              )}
              {allPermission && allPermission.includes("MemberExcel_Export") ? (
                <CSVLink data={csvMemberData}>
                  <img
                    className="img_icon_size log float-right ml-2 mr-2"
                    src={require("../../static/images/excel_icon.png")}
                    alt="Excel-Export"
                    title="Excel-Export"
                  />
                </CSVLink>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className="body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Sl No.</th>
                        <th>Member Name</th>
                        <th>Member Code</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Aadhaar</th>
                        <th>Designation</th>
                        <th>Joining Date</th>
                        <th>Closing Amt</th>
                        <th>Saving amount</th>
                        <th>Op</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedBatchIdData &&
                        selectedBatchIdData.map((shgMembers, idx) => {
                          var ED = shgMembers.memberJoiningDate.split(/\D/g);
                          var memberJoiningDate = [ED[2], ED[1], ED[0]].join(
                            "-"
                          );

                          return (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td className="text-left">
                                {shgMembers.memberName}
                              </td>
                              <td>{shgMembers.memberCode}</td>
                              <td>{shgMembers.memberPhone}</td>
                              <td className="text-left">
                                {shgMembers.memberAddress}
                              </td>
                              {/* <td>
                                {localStorage.getItem("ShgVillage") || ""}
                              </td> */}
                              <td>{shgMembers.memberAadharNo}</td>

                              <td>{shgMembers.memberDesg}</td>
                              <td>{memberJoiningDate}</td>
                              <td>{shgMembers.memberClossingAmt || 0}</td>
                              <td>{shgMembers.savingAmountSum}</td>
                              <td>
                                {shgMembers.memberStatus &&
                                shgMembers.memberStatus !== "Deactive" ? (
                                  <img
                                    className="img_icon_size log"
                                    onClick={() => onUpdate(shgMembers, idx)}
                                    src={require("../../static/images/delete.png")}
                                    alt="Deactivate"
                                    title="Deactivate"
                                  />
                                ) : (
                                  <Fragment></Fragment>
                                )}
                                &nbsp;
                                <Link
                                  className="img_icon_size log"
                                  src={require("../../static/images/edit_icon.png")}
                                  alt="Edit"
                                  title="Edit"
                                  to="/edit-member-details"
                                  onClick={() => onEdit(shgMembers, idx)}
                                >
                                  <img
                                    className="img_icon_size log"
                                    src={require("../../static/images/edit_icon.png")}
                                    alt="Edit"
                                    title="Edit"
                                  />
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      {selBatchDeactiveIdData &&
                        selBatchDeactiveIdData.map((shgMembers, idx) => {
                          var ED = shgMembers.memberJoiningDate.split(/\D/g);
                          var memberJoiningDate = [ED[2], ED[1], ED[0]].join(
                            "-"
                          );
                          return (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              {shgMembers.memberStatus &&
                              shgMembers.memberStatus === "Deactive" ? (
                                <td
                                  className="secondlinebreak1 text-left"
                                  style={{ backgroundColor: "#dda6a6" }}
                                >
                                  {shgMembers.memberName}
                                </td>
                              ) : (
                                <td></td>
                              )}
                              {/* <td className="text-left">
                                {shgMembers.memberName}
                              </td> */}
                              <td>{shgMembers.memberCode}</td>
                              <td>{shgMembers.memberPhone}</td>
                              <td className="text-left">
                                {shgMembers.memberAddress}
                              </td>
                              <td>{shgMembers.memberAadharNo}</td>
                              <td>{shgMembers.memberDesg}</td>
                              <td>{memberJoiningDate}</td>
                              <td>{shgMembers.memberClossingAmt || 0}</td>
                              <td>
                                {shgMembers.memberStatus &&
                                shgMembers.memberStatus !== "Deactive" ? (
                                  <img
                                    className="img_icon_size log"
                                    onClick={() => onUpdate(shgMembers, idx)}
                                    src={require("../../static/images/delete.png")}
                                    alt="Deactivate"
                                    title="Deactivate"
                                  />
                                ) : (
                                  <>
                                    Deactivated &nbsp;
                                    <img
                                      className="img_icon_size log "
                                      src={require("../../static/images/info.png")}
                                      alt="Reason"
                                      title={shgMembers.memberDeactiveReason}
                                    />
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-11 col-11 align_right">
                    <label>
                      Active:{" "}
                      {selectedBatchIdData && selectedBatchIdData.length}
                    </label>{" "}
                    &nbsp;
                    <label>
                      Deactive :{" "}
                      {selBatchDeactiveIdData && selBatchDeactiveIdData.length}
                    </label>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        <Modal
          show={showUpdateModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Edit Member Details </h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleUpdateModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditMemberDetails
              membersdata={userData}
              onUpdateModalChange={onUpdateModalChange}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showDeactiveModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Deactivate Member</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleDeactiveModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <DeactiveMember
              shgMembers={userDatas}
              onDeactiveModalChange={onDeactiveModalChange}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showPathSettingModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Import Member Details</h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleShowPathModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <form className="row" onSubmit={(e) => onFileUpload(e)}>
            <Modal.Body>
              <input
                type="file"
                accept=".csv"
                id="photo"
                className="visually-hidden"
                onChange={handleImg}
                required
              />
              <input
                type="submit"
                name="Submit"
                value="Submit"
                className="btn sub_form btn_continue blackbrd Save float-right"
              />
              {/* <AddUser onAddUserModalChange={onAddUserModalChange} /> */}
            </Modal.Body>
          </form>
        </Modal>
      </div>
    </Fragment>
  );
};

AllMemberDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getShgMembers: PropTypes.func.isRequired,
  getBatchInfo: PropTypes.func.isRequired,
  addImportMemberData: PropTypes.func.isRequired,
  getAllBatches: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getShgMembers,
  getAllBatches,
  getBatchInfo,
  addImportMemberData,
})(AllMemberDetails);
