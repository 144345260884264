import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAggrementDetails } from "../../actions/shg";
import Spinner from "../layout/Spinner";
const InstitutionRenewalHistory = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { allAggrementDetails },
  getAggrementDetails,
  HistoryData,
}) => {
  useEffect(() => {
    getAggrementDetails({ institutionId: HistoryData._id });
  }, [getAggrementDetails]);

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
          <div className="col-lg-11 col-md-11 col-sm-11 col-11">
            <h6 className="heading_color">
              <b>Institution Name: {HistoryData.institutionName}</b>
            </h6>
          </div>
          <table
            className="table table-bordered table-striped table-hover smll_row"
            id="datatable2"
          >
            <thead>
              <tr>
                <th>Start Date </th>
                <th>End Date</th>
                <th>Payment Mode</th>
                <th>ChequeNo Or DDNo</th>
                <th>Aggrement Status</th>
              </tr>
            </thead>
            <tbody>
              {allAggrementDetails &&
                allAggrementDetails.map((allAggrementData, idx) => {
                  var ED = allAggrementData.aggrementStartDate.split(/\D/g);
                  var aggrementStartDate = [ED[2], ED[1], ED[0]].join("-");
                  var EDE = allAggrementData.aggrementEndDate.split(/\D/g);
                  var aggrementEndDate = [EDE[2], EDE[1], EDE[0]].join("-");
                  return (
                    <tr key={idx}>
                      <td>{aggrementStartDate}</td>
                      <td>{aggrementEndDate}</td>
                      <td>{allAggrementData.aggrementPaymentMode}</td>
                      <td>{allAggrementData.ChequeNoOrDDNo}</td>
                      <td>{allAggrementData.aggrementStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

InstitutionRenewalHistory.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getAggrementDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getAggrementDetails,
})(InstitutionRenewalHistory);
