import axios from "axios";
import { setAlert } from "./alert";

import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  ADD_USER_INIT,
  CHANGE_PWD_FAIL,
  FORGOT_PWD_SUCCESS,
  FORGOT_PWD_FAIL,
  REMOVE_ERROR,
  ALL_USERS,
  GET_ALL_USER,
  LOGOUT,
  SET_LOADING_TRUE,
  USER_BATCH,
  INSTITUTION_DATA,
  PARTIAL_MEETING_COUNT,
  UNVERIFIED_MEETING_COUNT,
  OTP_LOGIN_SUCCESS,
} from "./types";
import setAuthToken from "../utils/setAuthToken";

var linkPath = process.env.REACT_APP_BASE_URL;
// var linkPath = "";

// Login User
export const login = (userName, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ userName, password });

  try {
    const res = await axios.post(`${linkPath}/api/auth/login`, body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch({
      type: LOGIN_FAIL,
      payload: errors[0].msg,
    });
  }
};

export const editOnUpdatepersonal = (finalData) => async (dispatch) => {
  try {
    await axios.post(
      `${linkPath}/api/users/edit-on-update-personal`,
      finalData
    );
    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`${linkPath}/api/auth/load-user`);
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
    let resData = {
      reqData: res.data,
    };
    dispatch(getInstitutionData());
    dispatch(getPartialMeetingCount());
    dispatch(getUnverifiedMeetingCount());

    if (res.data.userGroupName === "Member") {
      dispatch(getUserBatchDetails(resData));
    }
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getUserBatchDetails = (resData) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/shg/user-batch-details`,
      resData
    );
    localStorage.setItem("loggedMemberBatch", JSON.stringify(res.data));
    dispatch({
      type: USER_BATCH,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Get All Users
export const getAllUsers = () => async (dispatch) => {
  try {
    const res = await axios.get(`${linkPath}/api/auth/all-users`);
    dispatch({
      type: GET_ALL_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const AddUserDetailsform = (finalData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.post(
      `${linkPath}/api/auth/add-user-details`,
      finalData,
      config
    );
    dispatch({
      type: ADD_USER_INIT,
    });
    dispatch(getAllUsers());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Get user names based on search filter
export const getSearchUsersByFilter = (finalData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(
      `${linkPath}/api/auth/filter-users`,
      finalData,
      config
    );
    dispatch({
      type: ALL_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Change Password
export const changePwd = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(
      `${linkPath}/api/auth/change-pwd`,
      formData,
      config
    );
    dispatch({
      type: LOGOUT,
    });
    dispatch(setAlert(res.data.msg, "danger"));
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch({
      type: CHANGE_PWD_FAIL,
      payload: errors[0].msg,
    });
  }
};

export const editPwd = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(
      `${linkPath}/api/auth/edit-pwd`,
      formData,
      config
    );
    dispatch(setAlert(res.data.msg, "danger"));
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch({
      type: CHANGE_PWD_FAIL,
      payload: errors[0].msg,
    });
  }
};

export const getInstitutionData = () => async (dispatch) => {
  try {
    const res = await axios.get(`${linkPath}/api/auth/get-institution-data`);
    localStorage.setItem("loggedUserInstitutionData", JSON.stringify(res.data));
    const res2 = await axios.get(
      `${linkPath}/api/auth/get-institution-aggrement-data`
    );
    localStorage.setItem("loggedInstAggrementData", JSON.stringify(res2.data));
    dispatch({
      type: INSTITUTION_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getPartialMeetingCount = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/auth/get-partial-meeting-count`,
      {
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: PARTIAL_MEETING_COUNT,
      payload: res.data,
    });
    // dispatch(getBatchCount());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const getUnverifiedMeetingCount = () => async (dispatch) => {
  try {
    const res = await axios.post(
      `${linkPath}/api/auth/get-unverified-meeting-count`,
      {
        loginType: localStorage.getItem("loginType"),
      }
    );
    dispatch({
      type: UNVERIFIED_MEETING_COUNT,
      payload: res.data,
    });
    // dispatch(getBatchCount());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const editOnUpdateInstitution = (finalData) => async (dispatch) => {
  try {
    await axios.post(
      `${linkPath}/api/shg/edit-on-update-institution`,
      finalData
    );
    dispatch(getInstitutionData());
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Forgot Password
export const forgotPwd = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  dispatch({
    type: SET_LOADING_TRUE,
  });

  const body = JSON.stringify({ email });

  try {
    const res = await axios.post(
      `${linkPath}/api/auth/forgot-pwd`,
      body,
      config
    );
    dispatch({
      type: FORGOT_PWD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch({
      type: FORGOT_PWD_FAIL,
      payload: errors[0].msg,
    });
  }
};

export const otpLogin = (userName, otpVal) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ userName, otpVal });
  try {
    const res = await axios.post(
      `${linkPath}/api/auth/login-using-otp`,
      body,
      config
    );
    dispatch({
      type: OTP_LOGIN_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    dispatch({
      type: LOGIN_FAIL,
      payload: errors[0].msg,
    });
  }
};

// Logout
export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
};

// remove error
export const removeError = () => async (dispatch) => {
  dispatch({ type: REMOVE_ERROR });
};
