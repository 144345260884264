import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PermissionDeactive } from "../../actions/setting";
const DeactivePermission = ({
  auth: { isAuthenticated, user, users },
  permissionDeactiveData,
  onDeactiveModalChange,
  PermissionDeactive,
}) => {
  const [formData, setFormData] = useState({
    permissionDeactiveReason: "",
    isSubmitted: false,
  });

  const { permissionDeactiveReason } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: permissionDeactiveData ? permissionDeactiveData._id : "",
      permissionStatus: "Deactive",
      permissionDeactiveReason: permissionDeactiveReason,
      permissionDeactiveById: user && user._id,
      permissionDeactiveDateTime: new Date().toLocaleString("en-GB"),
    };
    PermissionDeactive(finalData);
    onDeactiveModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div className="col-lg-12 col-md-9 col-sm-9 col-12 ">
        <form className="row" onSubmit={(e) => onSubmit(e)}>
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12 ">
            <div className="col-lg-12 col-md-4 col-sm-4 col-12">
              <label>Reason For Deactivation* :</label>
            </div>
            <div className="col-lg-12  col-md-4 col-sm-4 col-12">
              <textarea
                name="permissionDeactiveReason"
                id="permissionDeactiveReason"
                className="textarea form-control"
                rows="3"
                placeholder="Deactive Reason"
                onChange={(e) => onInputChange(e)}
                style={{ width: "100%" }}
                required
              ></textarea>
            </div>
            <div className="col-lg-12 col-md-4 col-sm-4 col-12 py-2">
              <label>Are You Sure You Want to Deactivate ??</label>
            </div>
            <div
              className="col-lg-12 col-md-9 col-sm-9 col-12 Savebutton"
              size="lg"
            >
              <input
                type="submit"
                name="submit"
                value="Deactivate"
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

DeactivePermission.propTypes = {
  auth: PropTypes.object.isRequired,
  PermissionDeactive: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { PermissionDeactive })(
  DeactivePermission
);
