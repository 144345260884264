import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { editBankDetails } from "../../actions/shg";
import Spinner from "../layout/Spinner";

const EditBank = ({
  auth: { isAuthenticated, user, users, loading },
  allBankData,
  editBankDetails,
  onEditModalChange,
  batchData,
}) => {
  //formData
  const [formData, setFormData] = useState({
    batchBankName:
      allBankData && allBankData.batchBankName ? allBankData.batchBankName : "",

    batchBankAccountNumber:
      allBankData && allBankData.batchBankAccountNumber
        ? allBankData.batchBankAccountNumber
        : "",
    batchBankBranch:
      allBankData && allBankData.batchBankBranch
        ? allBankData.batchBankBranch
        : "",
    batchBankIFSC:
      allBankData && allBankData.batchBankIFSC ? allBankData.batchBankIFSC : "",
    isSubmitted: false,
  });
  const {
    batchBankName,
    batchBankAccountNumber,
    batchBankBranch,
    batchBankIFSC,
    isSubmitted,
  } = formData;

  const onInputChange1 = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onUpdate = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: allBankData ? allBankData._id : "",
      batchBankName: batchBankName,
      batchBankIFSC: batchBankIFSC,
      batchBankAccountNumber: batchBankAccountNumber,
      batchBankBranch: batchBankBranch,
      batchIdVal: batchData._id,
    };
    editBankDetails(finalData);
    onEditModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onUpdate(e)}>
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control">Bank Name :</label>
              <input
                type="text"
                name="batchBankName"
                value={batchBankName}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
                required
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control">IFSC Code :</label>
              <input
                type="text"
                name="batchBankIFSC"
                value={batchBankIFSC}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control">Account Number :</label>
              <input
                type="number"
                name="batchBankAccountNumber"
                value={batchBankAccountNumber}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              <label className="label-control">Branch :</label>
              <input
                type="text"
                name="batchBankBranch"
                value={batchBankBranch}
                className="form-control"
                onChange={(e) => onInputChange1(e)}
              />
            </div>
          </div>

          <div className="col-md-10 col-lg-10 col-sm-12 col-12 text-left">
            {loading ? (
              <button
                className="btn sub_form btn_continue Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Submit"
                className="btn sub_form btn_continue Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditBank.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  savedMessage: state.auth.savedMessage,
});

export default connect(mapStateToProps, {
  editBankDetails,
})(EditBank);
