import React, { useState, useEffect } from "react";
import Select from "react-select";
const BankDetTab = ({
  membersMeetingData,
  activeBank,
  batchData,
  NextBackBtn,
  formData,
  setFormData,
}) => {
  let {
    cashInHand,
    //Expense
    travellingExpenses, //bc
    paidToMahasangha, //bc
    stationaryExpenses, //bc
    serviceCharges, //bc
    batchDividendDistributed, //bc
    otherExpenses, //bc
    loanGivenToOtherSHG, //bc
    batchSavingWithdrawals, //bc
    batchSecurityDeposit, //bc
    donationToOutsider, //bc
    otherLoanBatchTransaction, //Array //Exp batchOtherLoanInterestDonor subsidyDistributed otherLoanPaidToDonor   //Income otherLoanSanctionedToShg
    sdSHGToUnion,

    //Income
    batchSavingAmt, //bc
    batchSubAmt, //bc
    batchOtherContribution, //bc
    batchMembershipFees, //bc
    batchLoanPaid, //bc
    batchInterestPaid, //bc

    otherLoanPaidToShg, //only
    batchOtherLoanInterestShg, //only

    deactiveMemberSaving, //bc
    subsidyReceived, //bc
    batchOtherIncome, //bc
    seedMoney, //bc
    loanRepaidByOtherSHG, //bc
    batchSecurityDepositMemToShg, //bc
    sdUnionToSHG, //bc
    otherLoanSanctionedToMember,
  } = formData;

  // const [newBankBal, setNewBankBal] = useState(0);

  // useEffect(() => {
  //   setNewBankBal();
  // }, [formData]);

  const [localBankDet, setlocalBankDet] = useState({
    // batchBankNameId: null,
    // batchBankName: null,
    batchBankNameObj: "",
    indBankInterest: 0,
    indBankCommission: 0,
    indBatchBankDeposit: 0,
    indBatchBankWithdrawals: 0,
  });
  let {
    batchBankNameObj,
    indBankInterest,
    indBankCommission,
    indBatchBankDeposit,
    indBatchBankWithdrawals,
  } = localBankDet;

  const inputBankDetChange = (e, selectedName = null) => {
    if (selectedName) {
      setlocalBankDet({
        ...localBankDet,
        batchBankNameObj: e,
        // batchBankNameId: e.bankId,
        // batchBankName: e.label,
      });
    } else {
      setlocalBankDet({
        ...localBankDet,
        [e.target.name]: e.target.value,
      });
    }
  };

  const totalLoans = (MemberLoanArray) => {
    try {
      let intLoanBank = 0;
      let intLoanCash = 0;
      let othLoanBank = 0;
      let othLoanCash = 0;

      let othPriPaidBank = 0;
      let othPriPaidCash = 0;

      let othIntPaidBank = 0;
      let othIntPaidCash = 0;

      MemberLoanArray.map((ele) => {
        intLoanBank += ele.loanTakenAmt.bankAmt;
        intLoanCash += ele.loanTakenAmt.cash;

        othLoanBank += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherLoanTakenAmt.bankAmt)),
          0
        );

        othLoanCash += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherLoanTakenAmt.cash)),
          0
        );

        othPriPaidBank += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherLoanPaid.bankAmt)),
          0
        );
        othPriPaidCash += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherLoanPaid.cash)),
          0
        );

        othIntPaidBank += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherInterestPaid.bankAmt)),
          0
        );

        othIntPaidCash += ele.otherLoan.reduce(
          (acu, cur) => (acu += Number(cur.otherInterestPaid.cash)),
          0
        );
      });

      return {
        intLoanBank: intLoanBank,
        intLoanCash: intLoanCash,

        othLoanBank: othLoanBank,
        othLoanCash: othLoanCash,

        othLoanPriPaidBank: othPriPaidBank,
        othLoanPriPaidCash: othPriPaidCash,
        otherLoanIntPaidBank: othIntPaidBank,
        otherLoanIntPaidCash: othIntPaidCash,
      };
      //   MemberLoanArray.
    } catch (er) {
      console.log(er);
    }
  };

  const Loandata = totalLoans(membersMeetingData);

  const [DBBankArray, setDBBankArray] = useState(
    formData.bankTransactions || []
  );

  const onInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onAddBankDet = () => {
    setDBBankArray([
      ...DBBankArray,
      {
        batchBankNameId: localBankDet.batchBankNameObj.bankId,
        batchBankName: localBankDet.batchBankNameObj.label,
        ...localBankDet,
      },
    ]);

    setlocalBankDet({
      batchBankNameObj: "",
      indBankInterest: 0,
      indBankCommission: 0,
      indBatchBankDeposit: 0,
      indBatchBankWithdrawals: 0,
    });
  };

  const onDeleteBankDet = (bankId) => {
    setDBBankArray(DBBankArray.filter((ele) => ele.batchBankNameId != bankId));
  };

  const totExpenseCash =
    Number(travellingExpenses ? travellingExpenses.cash : 0) +
    Number(paidToMahasangha ? paidToMahasangha.cash : 0) +
    Number(stationaryExpenses ? stationaryExpenses.cash : 0) +
    Number(serviceCharges ? serviceCharges.cash : 0) +
    Number(batchDividendDistributed ? batchDividendDistributed.cash : 0) +
    Number(otherExpenses ? otherExpenses.cash : 0) +
    Number(loanGivenToOtherSHG ? loanGivenToOtherSHG.cash : 0) +
    Number(batchSavingWithdrawals ? batchSavingWithdrawals.cash : 0) +
    Number(batchSecurityDeposit ? batchSecurityDeposit.cash : 0) +
    Number(donationToOutsider ? donationToOutsider.cash : 0) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestDonor.cash)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.subsidyDistributed.cash)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToDonor.cash)),
      0
    ) +
    Number(sdSHGToUnion ? sdSHGToUnion.cash : 0);

  const totExpenseBank =
    Number(travellingExpenses ? travellingExpenses.bankAmt : 0) +
    Number(paidToMahasangha ? paidToMahasangha.bankAmt : 0) +
    Number(stationaryExpenses ? stationaryExpenses.bankAmt : 0) +
    Number(serviceCharges ? serviceCharges.bankAmt : 0) +
    Number(batchDividendDistributed ? batchDividendDistributed.bankAmt : 0) +
    Number(otherExpenses ? otherExpenses.bankAmt : 0) +
    Number(loanGivenToOtherSHG ? loanGivenToOtherSHG.bankAmt : 0) +
    Number(batchSavingWithdrawals ? batchSavingWithdrawals.bankAmt : 0) +
    Number(batchSecurityDeposit ? batchSecurityDeposit.bankAmt : 0) +
    Number(donationToOutsider ? donationToOutsider.bankAmt : 0) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestDonor.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.subsidyDistributed.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToDonor.bankAmt || 0)),
      0
    ) +
    Number(sdSHGToUnion ? sdSHGToUnion.bankAmt : 0);

  const totIncomeCash =
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToShg.cash || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestShg.cash || 0)),
      0
    ) +
    Number(batchSavingAmt ? batchSavingAmt.cash : 0) +
    Number(batchSubAmt ? batchSubAmt.cash : 0) +
    Number(batchOtherContribution ? batchOtherContribution.cash != undefined ? batchOtherContribution.cash : 0 : 0) +
    Number(batchMembershipFees ? batchMembershipFees.cash : 0) +
    Number(batchLoanPaid ? batchLoanPaid.cash : 0) +
    Number(batchInterestPaid ? batchInterestPaid.cash : 0) +
    Number(deactiveMemberSaving ? deactiveMemberSaving.cash : 0) +
    Number(
      otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.subsidyReceived.cash || 0)),
        0
      )
    ) +
    Number(batchOtherIncome ? batchOtherIncome.cash != undefined ? batchOtherIncome.cash : 0 : 0) +
    Number(seedMoney ? seedMoney.cash != undefined ? seedMoney.cash : 0  : 0) +
    Number(loanRepaidByOtherSHG ? loanRepaidByOtherSHG.cash : 0) +
    Number(
      batchSecurityDepositMemToShg ? batchSecurityDepositMemToShg.cash : 0
    ) +
    Number(sdUnionToSHG ? sdUnionToSHG.cash : 0);

  const totIncomeBank =
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanPaidToShg.bankAmt || 0)),
      0
    ) +
    otherLoanBatchTransaction.reduce(
      (acu, cur) => (acu += Number(cur.otherLoanInterestShg.bankAmt || 0)),
      0
    ) +
    Number(batchSavingAmt ? batchSavingAmt.bankAmt : 0) +
    Number(batchSubAmt ? batchSubAmt.bankAmt : 0) +
    Number(batchOtherContribution ? batchOtherContribution.bankAmt !=undefined ? batchOtherContribution.bankAmt : 0 : 0) +
    Number(batchMembershipFees ? batchMembershipFees.bankAmt : 0) +
    Number(batchLoanPaid ? batchLoanPaid.bankAmt : 0) +
    Number(batchInterestPaid ? batchInterestPaid.bankAmt : 0) +
    Number(deactiveMemberSaving ? deactiveMemberSaving.bankAmt : 0) +
    Number(
      otherLoanBatchTransaction.reduce(
        (acu, cur) => (acu += Number(cur.subsidyReceived.bankAmt || 0)),
        0
      )
    ) +
    Number(batchOtherIncome ? batchOtherIncome.bankAmt !=undefined ? batchOtherIncome.bankAmt : 0 : 0) +
    Number(seedMoney ? seedMoney.bankAmt !=undefined ? seedMoney.bankAmt : 0 : 0) +
    Number(loanRepaidByOtherSHG ? loanRepaidByOtherSHG.bankAmt : 0) +
    Number(
      batchSecurityDepositMemToShg ? batchSecurityDepositMemToShg.bankAmt : 0
    ) +
    Number(sdUnionToSHG ? sdUnionToSHG.bankAmt : 0);


    
  const finAddBankDet = () => {
    setFormData({
      ...formData,
      bankTransactions: DBBankArray,
      bankInterest: DBBankArray.reduce(
        (ac, cur) => (ac += Number(cur.indBankInterest)),
        0
      ),
      bankCommission: DBBankArray.reduce(
        (ac, cur) => (ac += Number(cur.indBankCommission)),
        0
      ),
      batchBankDeposit: DBBankArray.reduce(
        (ac, cur) => (ac += Number(cur.indBatchBankDeposit)),
        0
      ),
      batchBankWithdrawals: DBBankArray.reduce(
        (ac, cur) => (ac += Number(cur.indBatchBankWithdrawals)),
        0
      ),
    });
    NextBackBtn(8);
  };

  return (
    <div>
      {activeBank.length > 0 ? (
        <>
          <div className="row card-new  py-3">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h5>Bank Details</h5>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
              <h5>Income: </h5>
              Bank:
              <span class="mx-2">{totIncomeBank}</span>
              <br />
              Cash:
              <span class="mx-2">{totIncomeCash}</span>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
              <h5>Expense :</h5>
              Bank:
              <span class="mx-2">{totExpenseBank}</span>
              <br />
              Cash:
              <span class="mx-2">{totExpenseCash}</span>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2"></div>
            {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 py-2">
              <h5>OL Details:</h5>
              <b>Sanctioned:</b>
              <br />
              <span class="mx-2">Cash: {Loandata.othLoanCash}</span>
              <span class="mx-2">Bank: {Loandata.othLoanBank}</span>
              <br />
              <b>Repayment:</b>
              <br />
              <span class="mx-2">
                Cash:{" "}
                {Number(Loandata.othLoanPriPaidCash) +
                  Number(Loandata.otherLoanIntPaidCash)}
              </span>
              <span class="mx-2">
                Bank:{" "}
                {Number(Loandata.othLoanPriPaidBank) +
                  Number(Loandata.otherLoanIntPaidBank)}
              </span>
            </div> */}

            {/* <div className="col-lg-12 col-md-12 col-sm-12 col-12 "> */}
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 ">
              <label className="label-control">Cash In Hand* :</label>
              <input
                type="number"
                name="cashInHand"
                value={cashInHand}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                //   onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12 "></div>
            {/* </div> */}

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label
                className="label-control"
                // style={bankErrorStyle}
              >
                Select Bank :
              </label>
              <Select
                name="selBanks"
                options={activeBank}
                isSearchable={true}
                placeholder="Select Bank"
                onChange={(e) => inputBankDetChange(e, "selBanks")}
                value={batchBankNameObj}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label className="label-control">Bank Interest : </label>
              <input
                type="number"
                name="indBankInterest"
                value={indBankInterest}
                className="form-control"
                onChange={(e) => inputBankDetChange(e)}
              />
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label className="label-control">Bank Commission : </label>
              <input
                type="number"
                name="indBankCommission"
                value={indBankCommission}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => inputBankDetChange(e)}
                //   onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label
                // style={myerror}
                className="label-control"
              >
                Bank Deposit:
              </label>
              <input
                type="number"
                name="indBatchBankDeposit"
                value={indBatchBankDeposit}
                className="form-control"
                onChange={(e) => inputBankDetChange(e)}
                //   onKeyDown={(e) => funcKeyDown(e)}
                required
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label className="label-control">Bank Withdrawal :</label>
              <input
                type="number"
                name="indBatchBankWithdrawals"
                value={indBatchBankWithdrawals}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => inputBankDetChange(e)}
              />
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <input
                type="button"
                name="Submit"
                value="ADD"
                className="btn sub_form btn_continue blackbrd Save float-right"
                onClick={() => {
                  onAddBankDet();
                }}
                style={
                  DBBankArray.map((ele) => ele.batchBankNameId).includes(
                    batchBankNameObj.bankId
                  )
                    ? { display: "none" }
                    : {}
                }
                disabled={batchBankNameObj == ""}

                // disabled={[
                //   AddedBankDetails.map(
                //     (ele) => ele && ele.batchBankName
                //   ),
                //   exstBankData &&
                //     exstBankData.bankTransactions.map(
                //       (ele) => ele && ele.batchBankName
                //     ),
                // ].includes(bank.label)}
              />
            </div>
          </div>

          <div className="body-inner no-padding  table-responsive">
            <table
              className="tabllll table table-bordered table-striped table-hover"
              id="datatable2"
            >
              <thead>
                <tr>
                  <th>Bank Name</th>
                  <th>Bank Interest</th>
                  <th>Bank Commission</th>
                  <th>Bank Deposit Amount</th>
                  <th>Bank Withdrawal Amount</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {DBBankArray.map((ele, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{ele.batchBankName}</td>
                      <td>{ele.indBankInterest}</td>
                      <td>{ele.indBankCommission}</td>
                      <td>{ele.indBatchBankDeposit}</td>
                      <td>{ele.indBatchBankWithdrawals}</td>
                      <td>
                        <img
                          className="img_icon_size log"
                          src={require("../../../static/images/delete.png")}
                          alt="Delete"
                          title="Delete"
                          onClick={() => onDeleteBankDet(ele.batchBankNameId)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <div className="row card-new  py-3">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h5>Bank Details</h5>
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12 col-12 py-2">
              <span>Income:</span>
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12 col-12 py-2">
              <span>Expense :</span>
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <label className="label-control">Cash In Hand* :</label>
              <input
                type="number"
                name="cashInHand"
                value={cashInHand}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                //   onChange={(e) => onInputChange(e)}
                //   onKeyDown={(e) => funcKeyDown(e)}
              />
            </div>
          </div>
        </>
      )}

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <button
          className="btn sub_form btn_continue Save float-right"
          // onClick={() => onAddOLRSingle(8)}
          onClick={() => finAddBankDet()}
          type="button"
        >
          Next
        </button>
        <button
          className="btn sub_form btn_continue Save float-right"
          type="button"
          onClick={() => NextBackBtn(6)}
        >
          Previous
        </button>
      </div>
    </div>
  );
};

export default BankDetTab;
