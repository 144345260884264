import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AddTankName } from "../../actions/area";
// import { AddState, checkState } from "../../actions/area";
import Spinner from "../layout/Spinner";

const AddTankNameDetails = ({
  setShowAddStateModal,
  savedMessage,
  auth: { isAuthenticated, user, users, loading },
  area: { stateCheckResponse },
  AddTankName,
  //   checkState,
  onAddStateModalChange,
}) => {
  //formData
  const [formData, setFormData] = useState({
    tankName: "",
    isSubmitted: false,
  });
  const { tankName } = formData;
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //   const onCheck = () => {
  //     const finalData = {
  //       sName: stateName,
  //     };
  //     checkState(finalData);
  //   };

  //   const onSubmit = (e) => {
  //     e.preventDefault();
  //     const finalData = {
  //       stateName: stateName.trim(),
  //       stateEnteredById: user._id,
  //       stateEnteredByName: user.userName,
  //       institutionId: user.institutionId,
  //       userData: user,
  //     };
  //     onAddStateModalChange(false);
  //     AddState(finalData);
  //     setFormData({
  //       ...formData,
  //       stateName: "",
  //       isSubmitted: true,
  //     });
  //   };
  const onSubmit = (e) => {
    e.preventDefault();
    //Required Validation Starts
    // if (checkErrors()) {
    if (true) {
      const finalData = {
        tankName: tankName,

        tankNameEnteredById: user._id,
        tankNameEnteredByName: user.userName,
        tankNameEnteredDateTime: new Date().toLocaleString("en-GB"),
        institutionId: user.institutionId,
        userData: user,
      };

      AddTankName(finalData);

      setFormData({
        ...formData,
        tankName: "",
        isSubmitted: true,
      });
    }
    setShowAddStateModal(false);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onSubmit(e)} autoComplete="off">
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="col-lg-10 col-md-12 col-sm-12 col-12">
              <label className="label-control"> Tank Name* :</label>
              <input
                type="text"
                name="tankName"
                value={tankName}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                required
              />
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 col-12 py-5">
              <button className="btn sub_form" type="submit">
                Add
              </button>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            {/* {stateCheckResponse && stateCheckResponse === "State Available" ? (
              <label style={{ color: "green" }}>{stateCheckResponse}</label>
            ) : (
              <label className="colorRed">{stateCheckResponse}</label>
            )} */}
          </div>
          {stateCheckResponse === "State Available" && (
            <div className="col-md-10 col-lg-10 col-sm-12 col-12 text-left">
              {/* {loading ? (
                <button
                  className="btn sub_form btn_continue Save float-right"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <input
                  type="submit"
                  name="Save"
                  value="Submit"
                  className="btn sub_form btn_continue Save float-right"
                />
              )} */}
            </div>
          )}
        </form>
      </div>
    </Fragment>
  );
};

AddTankNameDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  AddState: PropTypes.func.isRequired,
  checkState: PropTypes.func.isRequired,
  savedMessage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  savedMessage: state.auth.savedMessage,
});

export default connect(mapStateToProps, {
  AddTankName,
  //   checkState,
})(AddTankNameDetails);
