import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import Select from "react-select";
import { getAllIncharge } from "../../actions/user";
import { getInchargeReport } from "../../actions/shg";
import { CSVLink } from "react-csv";

const InchargewiseReport = ({
  auth: { isAuthenticated, user, users },
  shg: { inchargeReport },
  user: { allincharge },
  getInchargeReport,
  getAllIncharge,
}) => {
  useEffect(() => {
    getInchargeReport();
  }, [getInchargeReport]);
  useEffect(() => {
    getAllIncharge();
  }, [getAllIncharge]);
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const DateMethods = [
    { value: "Single Date", label: "Single Date" },
    { value: "Multi Date", label: "Multi Date" },
  ];
  const allincharges = [];
  allincharge.map((incharge) =>
    allincharges.push({
      batchInchargeId: incharge._id,
      label: incharge.userFullName,
      value: incharge.userFullName,
    })
  );

  const [showParishHide, setshowParishHide] = useState({
    showParishSection:
      loggedUserInstitutionData &&
      loggedUserInstitutionData.institutionType === "Zoned"
        ? true
        : false,
  });

  const [inchargeData, setInchargeData] = useState();
  const { showParishSection } = showParishHide;

  const [showHide, setShowHide] = useState({
    showMultiDateSection: false,
    showdateSingleDateSection: true,
  });

  const [formData, setFormData] = useState({
    dateselect: "",
    Dateselectmode: DateMethods[0],
    isSubmitted: false,
  });

  const { dateselect, Dateselectmode } = formData;
  const { showMultiDateSection, showdateSingleDateSection } = showHide;
  const onDateModeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        Dateselectmode: e,
      });
    }
    if (e.value === "Multi Date") {
      setShowHide({
        ...showHide,
        showMultiDateSection: true,
        showdateSingleDateSection: false,
      });
    } else {
      setShowHide({
        ...showHide,
        showMultiDateSection: false,
        showdateSingleDateSection: true,
      });
    }
  };

  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const [singledate, setsingledate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const onDateChange2 = (e) => {
    let selDateData = {
      selDate:e.target.value,
      dateType: "Single Date",
      batchInchargeId: inchargeData ? inchargeData.batchInchargeId : "",
    };
    getInchargeReport(selDateData);
    setsingledate(e.target.value);
  };
  const [todate, settodate] = useState("");
  const onDateChange1 = (e) => {
   
    let selDateData = {
      fromdate: fromdate,
      todate:e.target.value,
      dateType: "Multi Date",
      batchInchargeId: inchargeData ? inchargeData.batchInchargeId : "",
    };
    getInchargeReport(selDateData);
     settodate(e.target.value);
  };

  const [fromdate, setfromdate] = useState("");
  const onDateChange = (e) => {
    setfromdate(e.target.value);
  };

  const onInchargeChange = (e) => {
    if (e) {
      setInchargeData(e);
      if (showMultiDateSection) {
        let selDateData = {
          fromdate: fromdate,
          todate: todate,
          dateType: "Multi Date",
          batchInchargeId: e.batchInchargeId,
        };
        getInchargeReport(selDateData);
      } else if (showdateSingleDateSection) {
        let selDateData = {
          selDate: singledate,
          dateType: "Single Date",
          batchInchargeId: e.batchInchargeId,
        };
        getInchargeReport(selDateData);
      }
    }
  };

  const onSearch = (e) => {
    let selDateData = {
      selDate: singledate,
      dateType: "Single Date",
      batchInchargeId: inchargeData ? inchargeData.batchInchargeId : "",
    };
    getInchargeReport(selDateData);
  };

  const onSearchmultidate = (e) => {
    let selDateData = {
      fromdate: fromdate,
      todate: todate,
      dateType: "Multi Date",
      batchInchargeId: inchargeData ? inchargeData.batchInchargeId : "",
    };
    getInchargeReport(selDateData);
  };

  const onClickReset = () => {
    getInchargeReport("");
    settodate("");
    setfromdate("");
    setInchargeData("");
    setFormData({ dateselect: "",
    Dateselectmode: DateMethods[0],
    isSubmitted: false,})
    setShowHide({  showMultiDateSection: false,
      showdateSingleDateSection: true,})
   
  };

  const csvFreeZonedData = [
    [
      "Meeting Date",
      "Batch Name",
      "Total Saving Amount",
      "Total Loan Amount",
      "Total Bank Deposit",
      "Total Withdrawal",
    ],
  ];
  inchargeReport.map((inchargeReport) => {
    return csvFreeZonedData.push([
      inchargeReport.batchMeetingHeldOnDate,
      inchargeReport.batchName,
      inchargeReport.batchSavingAmt,
      inchargeReport.batchLoanAmt,
      inchargeReport.batchBankDeposit,
      inchargeReport.batchBankWithdrawals,
    ]);
  });

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color"> Inchargewise Report</h2>
          <hr />
        </div>

        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
           
            <div className="col-lg-2 col-md-4 col-sm-4 col-12 py-2">
              <Select
                name="Dateselectmode"
                options={DateMethods}
                isSearchable={true}
                // defaultValue={DateMethods[0]}
                value={Dateselectmode}
                placeholder="Select"
                onChange={(e) => onDateModeChange(e)}
              />
            </div>
           
            <div className="col-lg-5 col-md-4 col-sm-4 col-12">
              {showMultiDateSection && (
                <div className="row">
                  <div className="col-lg-5 col-md-11 col-sm-10 col-10 py-2">
                    <input
                      type="date"
                      placeholder="dd/mm/yyyy"
                      className="form-control cpp-input datevalidation"
                      name="fromdate"
                      value={fromdate}
                      onChange={(e) => onDateChange(e)}
                      style={{
                        width: "110%",
                      }}
                      required
                    />
                  </div>
                  <div className=" col-lg-5 col-md-11 col-sm-10 col-10 py-2">
                    <input
                      type="date"
                      placeholder="dd/mm/yyyy"
                      className="form-control cpp-input datevalidation"
                      name="todate"
                      value={todate}
                      onChange={(e) => onDateChange1(e)}
                      style={{
                        width: "110%",
                      }}
                      required
                    />
                  </div>
                  {/* <div className="col-lg-2 col-md-11 col-sm-10 col-10 py-3">
                    <img
                      className="img_icon_size log"
                      onClick={() => onSearchmultidate()}
                      src={require("../../static/images/Search_Icon.png")}
                      alt="Search_Icon"
                      title="Search_Icon"
                    />
                  </div> */}
                </div>
              )}

              {showdateSingleDateSection && (
                <div className="row">
                  <div className=" col-lg-5 col-md-11 col-sm-10 col-10 py-2">
                    <input
                      type="date"
                      placeholder="dd/mm/yyyy"
                      className="form-control cpp-input datevalidation"
                      name="singledate"
                      value={singledate}
                      onChange={(e) => onDateChange2(e)}
                      style={{
                        width: "100%",
                      }}
                      required
                    />
                  </div>       
                  {/* <div className="col-lg-2 col-md-11 col-sm-10 col-10 py-3">
                    <img
                      className="img_icon_size log"
                      onClick={() => onSearch()}
                      src={require("../../static/images/Search_Icon.png")}
                      alt="Search_Icon"
                      title="Search_Icon"
                    />
                  </div> */}
                </div>
              )}
              
            </div>
            {(user.userGroupName === "Admin" ||
              user.userGroupName === "Auditor" ||
              user.userGroupName === "Co-ordinator") && (
              <div className="col-lg-2 col-md-4 col-sm-4 col-12 py-2 ">
                <Select
                  name="userFullName"
                  options={allincharges}
                  isSearchable={true}
                  value={inchargeData}
                  placeholder="Select Incharge"
                  onChange={(e) => onInchargeChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
            )}
            <div className="col-lg-2 col-md-11 col-sm-11 col-11 py-3">
              <Link to="/all-reports">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <img
                className="img_icon_size log float-right  ml-3"
                onClick={() => onClickReset()}
                src={require("../../static/images/refresh-icon.png")}
                alt="refresh"
                title="Refresh"
              />
              <CSVLink data={csvFreeZonedData}>
                <img
                  className="img_icon_size log float-right ml-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="excel-export"
                  title="excel-export"
                />
              </CSVLink>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Meeting Date</th>
                        <th>Batch Name</th>
                        {showParishSection && (
                          <>
                            <th>Parish</th>
                          </>
                        )}
                        <th>Total Saving Amount</th>
                        <th>Total Loan Amount</th>
                        <th>Total Bank Deposit</th>
                        <th>Total Withdrawal</th>
                        <th>Entered By</th>
                        <th>Entered Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {inchargeReport &&
                        inchargeReport.map((inchargeReport, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                {inchargeReport.batchMeetingHeldOnDate
                                  .split("-")
                                  .reverse()
                                  .join("-")}
                              </td>
                              <td>{inchargeReport.batchName}</td>
                              {showParishSection && (
                                <>
                                  <td>{inchargeReport.output.parishName}</td>
                                </>
                              )}
                              <td>{inchargeReport.batchSavingAmt}</td>
                              <td>{inchargeReport.batchLoanAmt}</td>
                              <td>{inchargeReport.batchBankDeposit}</td>
                              <td>{inchargeReport.batchBankWithdrawals}</td>
                              <td>{inchargeReport.btEnteredByName}</td>
                              <td>
                                {inchargeReport.btEnteredDate
                                  .split("-")
                                  .reverse()
                                  .join("-")}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
            <div className="col-lg-11 col-md-6 col-sm-11 col-11 align_right">
              <label>
                <b>Counter: {inchargeReport.length}</b>
              </label>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

InchargewiseReport.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  getInchargeReport: PropTypes.func.isRequired,
  getAllIncharge: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  user: state.user,
});

export default connect(mapStateToProps, {
  getInchargeReport,
  getAllIncharge,
})(InchargewiseReport);
