import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getActiveInstitutions } from "../../actions/shg";
import { editUserGroup } from "../../actions/user";
import Spinner from "../layout/Spinner";
const EditUserGroup = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { activeInstitutions },
  editUserGroup,
  onUpdateModalChange,
  usergroupdata,
  getActiveInstitutions,
}) => {
  useEffect(() => {
    getActiveInstitutions();
  }, [getActiveInstitutions]);
  //formData
  const [formData, setFormData] = useState({
    userGroupName:
      usergroupdata && usergroupdata.userGroupName
        ? usergroupdata.userGroupName
        : "",

    isSubmitted: false,
  });

  const { userGroupName } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [showInformationModal, setShowInformation] = useState(false);

  const handleInformationModalClose = () => setShowInformation(false);
  const LogoutModalClose = () => {
    handleInformationModalClose();
  };
  const onUpdate = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: usergroupdata ? usergroupdata._id : "",
      userGroupName: userGroupName,
    };
    editUserGroup(finalData);
    onUpdateModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <form className="row" onSubmit={(e) => onUpdate(e)}>
          <div className=" col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <label className="label-control"> User Group Name* :</label>
                <input
                  type="text"
                  name="userGroupName"
                  value={userGroupName}
                  className="form-control"
                  onChange={(e) => onInputChange(e)}
                  required
                />
              </div>
            </div>
          </div>

          <div className="col-lg-8 Savebutton " size="lg">
            {loading ? (
              <button
                className="btn sub_form btn_continue blackbrd Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Submit"
                className="btn sub_form btn_continue blackbrd Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditUserGroup.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  editUserGroup: PropTypes.func.isRequired,
  getActiveInstitutions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  editUserGroup,
  getActiveInstitutions,
})(EditUserGroup);
