import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  editBatchTransaction,
  getParticularLoanReason,
  getMembersMeetingData,
  getMeetingSummary,
  getMemberMeetingSummary, //memSum
  UpdateMeetingStatus,
  getEditMemberMeetingData,
  getSeletedBatchDetails,
  getActiveBankData,
  getActiveOtherLoanType,
  getExistingOLData,
  getExistingBankData,
  deleteBatchOtherLoan,
  deleteBankData,
  addNewOtherLoanBatchTransaction,
  addNewBankTransaction,
  getSelMeetingDateData,
  sendMettingFile,
  getBatchMeetingData,
  getMeetingTransactionData
} from "../../actions/shg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Spinner from "../layout/Spinner";
import RecheckReasonModal from "./RecheckReasonModal";
import SummaryMemTab from "./memBatchMeetingTabs/SummaryMemTab";
import AllMemberTab from "./memBatchMeetingTabs/AllMemberTab";
import EditMemberModel from "./memberDetModel/EditMemberModel";
import IncomeTab from "./memBatchMeetingTabs/IncomeTab";
import ExpenseTab from "./memBatchMeetingTabs/ExpenseTab";
import LoanDetTab from "./memBatchMeetingTabs/LoanDetTab";
import OLRepaymentTab from "./memBatchMeetingTabs/OLRepaymentTab";
import BankDetTab from "./memBatchMeetingTabs/BankDetTab";
import EODSummaryTab from "./memBatchMeetingTabs/EODSummaryTab";

const VerificationMemberBatchMeetingDetails = ({
  auth: { isAuthenticated, user, users,loading },
  shg: {
    membersMeetingData,
    memberClosingAmtAll,
    activeBankData,
    pdfBase64,
    memSummaryData,
    batchData,
    file_upload_successful,
    storage_info,
    fileUploadSpinner
  },
  getParticularLoanReason,
  getMembersMeetingData,
  getMeetingSummary,
  getActiveBankData,
  getActiveOtherLoanType,
  getExistingOLData,
  getExistingBankData,
  getSelMeetingDateData,
  sendMettingFile,
  getMemberMeetingSummary,
  getBatchMeetingData,
  getMeetingTransactionData
}) => {
  let batchTransactionData = JSON.parse(
    localStorage.getItem("batchTransactionData")
  );
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const [formData, setFormData] = useState({
    batchId: null,
    batchName: null,
    batchMeetingHeldOnDate: null,
    batchMeetingMonth: null,
    batchMeetingYear: null,
    batchTransactionType: null, // selBatchData[0].batchTransactionType,
    meetingCategory: null,
    meetingNotes: "",
    batchSavingAmt: null, //BC
    batchSubAmt: null, //BC
    batchOtherContribution: null, //BC
    batchMembershipFees: null, //BC
    deactiveMemberSaving: null, //BC
    batchOtherIncome: null, //BC
    seedMoney: null, //BC
    batchSecurityDepositMemToShg: null, //BC
    sdUnionToSHG: null, //BC
    loanRepaidByOtherSHG: null, //BC
    travellingExpenses: null, //BC
    stationaryExpenses: null, //bc,
    paidToMahasangha: null, //bc
    bankCommission: null, //no
    serviceCharges: null, //bc
    batchDividendDistributed: null, //bc
    otherExpenses: null, //bc
    batchSavingWithdrawals: null, //bc
    batchSecurityDeposit: null, //bc
    donationToOutsider: null, //bc
    sdSHGToUnion: null, //bc
    batchLoanAmt: null, //bc
    batchLoanPaid: null, //bc
    batchInterestPaid: null, //bc
    otherLoanSanctionedToMember: null, //bc
    otherLoanSanctionedToShg: 0, //no
    loanGivenToOtherSHG: 0, //no
    subsidyDistributed: 0, //no
    subsidyReceived: 0, //NO
    bankInterest: 0, //NO
    otherLoanPaidToShg: 0, //no
    otherLoanPaidToDonor: 0, //no
    batchOtherLoanInterestShg: 0, // no
    batchOtherLoanInterestDonor: 0, //no
    otherLoanBatchTransaction: [], // [], //Array
    bankTransactions: [], //[] //array
    cashInHand: 0, //no
    batchBankDeposit: 0, //no
    batchBankWithdrawals: 0, //no
    institutionId: loggedUserInstitutionData.institutionId,
    meetingFileName: null,
  });

  const [prevBankBalance, setPrevBankBalance] = useState(0);
 
  useEffect(() => {
    if (batchData) {
      setFormData({
        _id: batchData._id,
        batchId: batchData.batchId,
        batchName: batchData.batchName,
        batchMeetingHeldOnDate: batchData.batchMeetingHeldOnDate,
        batchMeetingMonth: batchData.batchMeetingMonth,
        batchMeetingYear: batchData.batchMeetingYear,
        batchTransactionType: batchData.batchTransactionType,
        // meetingCategory: batchData.meetingCategory,
        meetingNotes: batchData.meetingNotes,

        batchSavingAmt: batchData.batchSavingAmt, //BC

        batchSubAmt: batchData.batchSubAmt, //BC

        batchOtherContribution: batchData.batchOtherContribution, //BC

        batchMembershipFees: batchData.batchMembershipFees, //BC

        bankInterest: batchData.bankInterest, //NO

        deactiveMemberSaving: batchData.deactiveMemberSaving, //BC

        subsidyReceived: batchData.subsidyReceived, //NO

        batchOtherIncome: batchData.batchOtherIncome, //BC
        seedMoney: batchData.seedMoney, //BC
        batchSecurityDepositMemToShg: batchData.batchSecurityDepositMemToShg, //BC
        sdUnionToSHG: batchData.sdUnionToSHG, //BC

        bankSDUSId: null,
        bankSDUS: null,
        selectionSDUSType: "",

        loanRepaidByOtherSHG: batchData.loanRepaidByOtherSHG, //BC
        travellingExpenses: batchData.travellingExpenses, //BC
        stationaryExpenses: batchData.stationaryExpenses, //bc,
        paidToMahasangha: batchData.paidToMahasangha, //bc
        bankCommission: batchData.bankCommission, //no
        serviceCharges: batchData.serviceCharges, //bc
        batchDividendDistributed: batchData.batchDividendDistributed, //bc
        otherExpenses: batchData.otherExpenses, //bc
        batchSavingWithdrawals: batchData.batchSavingWithdrawals, //bc
        batchSecurityDeposit: batchData.batchSecurityDeposit, //bc
        subsidyDistributed: batchData.subsidyDistributed, //no
        donationToOutsider: batchData.donationToOutsider, //bc
        sdSHGToUnion: batchData.sdSHGToUnion, //bc
        bankSDSUId: null,
        bankSDSU: "",
        selectionSDSUType: "",
        loanGivenToOtherSHG: batchData.loanGivenToOtherSHG, //no
        batchLoanAmt: batchData.batchLoanAmt, //bc
        batchLoanPaid: batchData.batchLoanPaid, //bc
        batchInterestPaid: batchData.batchInterestPaid, //bc
        otherLoanSanctionedToShg: batchData.otherLoanSanctionedToShg, //no
        otherLoanSanctionedToMember: batchData.otherLoanSanctionedToMember, //bc
        otherLoanPaidToShg: batchData.otherLoanPaidToShg, //no
        otherLoanPaidToDonor: batchData.otherLoanPaidToDonor, //no
        batchOtherLoanInterestShg: batchData.batchOtherLoanInterestShg, //no
        batchOtherLoanInterestDonor: batchData.batchOtherLoanInterestDonor, //no
        otherLoanBatchTransaction: batchData.otherLoanBatchTransaction, // [], //Array
        bankTransactions: batchData.bankTransactions, //[] //array
        cashInHand: batchData.cashInHand, //no
        batchBankDeposit: batchData.batchBankDeposit, //no
        batchBankWithdrawals: batchData.batchBankWithdrawals, //no
        institutionId: batchData.institutionId,
        meetingFileName: batchData.meetingFileName,
      });
    }
  }, [batchData]);
  let {
    meetingNotes,
    batchSavingAmt, //BC
    batchSubAmt, //BC
    batchOtherContribution, //BC
    batchMembershipFees, //BC

    deactiveMemberSaving, //BC

    batchOtherIncome, //BC
    seedMoney, //BC
    batchSecurityDepositMemToShg, //BC
    sdUnionToSHG, //BC

    loanRepaidByOtherSHG, //BC
    travellingExpenses, //BC
    stationaryExpenses, //bc,
    paidToMahasangha, //bc

    serviceCharges, //bc
    batchDividendDistributed, //bc
    otherExpenses, //bc
    batchSavingWithdrawals, //bc
    batchSecurityDeposit, //bc

    donationToOutsider, //bc
    sdSHGToUnion, //bc

    batchLoanAmt, //bc
    batchLoanPaid, //bc
    batchInterestPaid, //bc

    otherLoanSanctionedToMember,

    bankInterest,
    batchBankDeposit,
    bankCommission,
    batchBankWithdrawals,
  } = formData;

  //  getBatchMeetingData  123

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const allRights = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.groupRights &&
    loggedUserInstitutionData.groupRights.map((groupRights) =>
      allRights.push(groupRights)
    );

  const meetingBatchInfo = {
    mdDate: batchIdValue && batchIdValue.mdDate,
    mdBatchId: batchIdValue && batchIdValue.batchId,
  };
  

  useEffect(() => {
    getBatchMeetingData(meetingBatchInfo);
   
  }, [getBatchMeetingData]);

  useEffect(() => {
    getMeetingTransactionData(meetingBatchInfo);
  }, []);

  useEffect(() => {
    getParticularLoanReason();

    getSelMeetingDateData(meetingBatchInfo);
  }, [getParticularLoanReason]);

  useEffect(() => {
    getMembersMeetingData(meetingBatchInfo);
  }, [getMembersMeetingData]);

  useEffect(() => {
    getMeetingSummary(meetingBatchInfo);
  }, [getMeetingSummary]);

  useEffect(() => {
    getMemberMeetingSummary(meetingBatchInfo);
  }, [getMemberMeetingSummary]);

  useEffect(() => {
    getActiveBankData(batchIdValue);
  }, [getActiveBankData]);

  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);

  useEffect(() => {
    getExistingOLData(meetingBatchInfo);
  }, [getExistingOLData]);

  useEffect(() => {
    getExistingBankData(meetingBatchInfo);
  }, [getExistingBankData]);

  //for model close open

  const [
    showMemberMeetingDetailsModals,
    setMemberMeetingDetailModals,
  ] = useState(false);

  //validation for select for otherLoan

  //NEW START
  const activeBank = [];
  activeBankData &&
    activeBankData.map((bank) =>
      activeBank.push({
        bankId: bank._id,
        label: bank.batchBankName,
        value: bank.batchBankName,
      })
    );

  //OLR GRID START==============================================================================

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);
  const [newBkBal, setnewBkBal] = useState(0);

  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  // const onSubmit = (e) => {
  //   if (batchBankDeposit === "") {
  //     // setMyError({ color: "red" });
  //     // alert("error");
  //   } else {
  //     let bankTran = [];
  //     if (activeBank.length <= 1) {
  //       bankTran = onAddBankSingle(e);
  //     } else {
  //       bankTran = AddedBankDetails;
  //     }

  //     let otherLoanSanctionedToShgSum = 0,
  //       otherLoanSanctionedToMemberSum = 0,
  //       otherLoanPaidToShgSum = 0,
  //       otherLoanPaidToDonorSum = 0,
  //       batchOtherLoanInterestShgSum = 0,
  //       batchOtherLoanInterestDonorSum = 0,
  //       subsidyReceivedSum = 0,
  //       indSubsidyDistributedSum = 0;

  //     if (activeOtherLoan.length > 1) {
  //       exstOLData &&
  //         exstOLData.otherLoanBatchTransaction &&
  //         exstOLData.otherLoanBatchTransaction.map((AddDetail, idx) => {
  //           otherLoanSanctionedToShgSum += parseFloat(
  //             AddDetail.otherLoanSanctionedToShg || 0
  //           );
  //           otherLoanSanctionedToMemberSum += parseFloat(
  //             AddDetail.batchOtherLoanAmts || 0
  //           );
  //           otherLoanPaidToShgSum += parseFloat(
  //             AddDetail.otherLoanPaidToShg || 0
  //           );
  //           otherLoanPaidToDonorSum += parseFloat(
  //             AddDetail.otherLoanPaidToDonor || 0
  //           );
  //           batchOtherLoanInterestShgSum += parseFloat(
  //             AddDetail.batchOtherLoanInterestShg || 0
  //           );
  //           batchOtherLoanInterestDonorSum += parseFloat(
  //             AddDetail.batchOtherLoanInterestDonor || 0
  //           );
  //           subsidyReceivedSum += parseFloat(AddDetail.subsidyReceived || 0);
  //           indSubsidyDistributedSum += parseFloat(
  //             AddDetail.subsidyDistributed || 0
  //           );
  //         });
  //     }

  //     AddedDetailsOLR.map((AddDetail, idx) => {
  //       otherLoanSanctionedToShgSum += parseFloat(
  //         AddDetail.otherLoanSanctionedToShg || 0
  //       );
  //       otherLoanSanctionedToMemberSum += parseFloat(
  //         AddDetail.batchOtherLoanAmts || 0
  //       );
  //       otherLoanPaidToShgSum += parseFloat(AddDetail.otherLoanPaidToShg || 0);
  //       otherLoanPaidToDonorSum += parseFloat(
  //         AddDetail.otherLoanPaidToDonor || 0
  //       );
  //       batchOtherLoanInterestShgSum += parseFloat(
  //         AddDetail.batchOtherLoanInterestShg || 0
  //       );
  //       batchOtherLoanInterestDonorSum += parseFloat(
  //         AddDetail.batchOtherLoanInterestDonor || 0
  //       );
  //       subsidyReceivedSum += parseFloat(AddDetail.subsidyReceived || 0);
  //       indSubsidyDistributedSum += parseFloat(
  //         AddDetail.subsidyDistributed || 0
  //       );
  //     });

  //     let batchBankDepositSum = 0,
  //       batchBankWithdrawalsSum = 0,
  //       bankInterestSum = 0,
  //       bankCommissionSum = 0;

  //     if (activeBank.length > 1) {
  //       exstBankData &&
  //         exstBankData.bankTransactions &&
  //         exstBankData.bankTransactions.map((AddDetail) => {
  //           batchBankDepositSum += parseFloat(
  //             AddDetail.indBatchBankDeposit || 0
  //           );
  //           batchBankWithdrawalsSum += parseFloat(
  //             AddDetail.indBatchBankWithdrawals || 0
  //           );
  //           bankInterestSum += parseFloat(AddDetail.indBankInterest || 0);
  //           bankCommissionSum += parseFloat(AddDetail.indBankCommission || 0);
  //         });
  //     }

  //     bankTran.map((AddDetail) => {
  //       batchBankDepositSum += parseFloat(AddDetail.indBatchBankDeposit || 0);
  //       batchBankWithdrawalsSum += parseFloat(
  //         AddDetail.indBatchBankWithdrawals || 0
  //       );
  //       bankInterestSum += parseFloat(AddDetail.indBankInterest || 0);
  //       bankCommissionSum += parseFloat(AddDetail.indBankCommission || 0);
  //     });

  //     e.preventDefault();

  //     const finalData = {
  //       //INCOME
  //       batchSavingAmt: batchSavingAmt,
  //       batchSubAmt: batchSubAmt,
  //       batchOtherContribution: batchOtherContribution
  //         ? batchOtherContribution
  //         : 0,
  //       batchMembershipFees: batchMembershipFees ? batchMembershipFees : 0,
  //       bankInterest: bankInterestSum ? Number(bankInterestSum).toFixed(2) : 0, //sumB
  //       deactiveMemberSaving: deactiveMemberSaving ? deactiveMemberSaving : 0,
  //       subsidyReceived: subsidyReceivedSum
  //         ? Number(subsidyReceivedSum).toFixed(2)
  //         : 0, //sum
  //       batchOtherIncome: batchOtherIncome ? batchOtherIncome : 0,
  //       seedMoney: seedMoney ? seedMoney : 0,
  //       loanRepaidByOtherSHG: loanRepaidByOtherSHG ? loanRepaidByOtherSHG : 0,
  //       batchSecurityDepositMemToShg: batchSecurityDepositMemToShg
  //         ? batchSecurityDepositMemToShg
  //         : 0,
  //       sdUnionToSHG: sdUnionToSHG ? sdUnionToSHG : 0,
  //       bankSDUSId: bankSDUS ? bankSDUS.bankId : null,
  //       bankSDUS: bankSDUS ? bankSDUS.value : "",
  //       selectionSDUSType: selectionSDUSType ? selectionSDUSType : "",
  //       //EXPENSE
  //       travellingExpenses: travellingExpenses ? travellingExpenses : 0,
  //       stationaryExpenses: stationaryExpenses ? stationaryExpenses : 0,
  //       paidToMahasangha: paidToMahasangha ? paidToMahasangha : 0,
  //       bankCommission: bankCommissionSum
  //         ? Number(bankCommissionSum).toFixed(2)
  //         : 0, //sumB
  //       serviceCharges: serviceCharges ? serviceCharges : 0,
  //       batchDividendDistributed: batchDividendDistributed
  //         ? batchDividendDistributed
  //         : 0,
  //       otherExpenses: otherExpenses ? otherExpenses : 0,
  //       loanGivenToOtherSHG: loanGivenToOtherSHG ? loanGivenToOtherSHG : 0,
  //       batchSavingWithdrawals: batchSavingWithdrawals
  //         ? batchSavingWithdrawals
  //         : 0,
  //       batchSecurityDeposit: batchSecurityDeposit ? batchSecurityDeposit : 0,
  //       donationToOutsider: donationToOutsider ? donationToOutsider : 0,
  //       subsidyDistributed: indSubsidyDistributedSum
  //         ? Number(indSubsidyDistributedSum).toFixed(2)
  //         : 0, //sum
  //       sdSHGToUnion: sdSHGToUnion ? sdSHGToUnion : 0,
  //       bankSDSUId: bankSDSU ? bankSDSU.bankId : null,
  //       bankSDSU: bankSDSU ? bankSDSU.value : "",
  //       selectionSDSUType: selectionSDSUType ? selectionSDSUType : 0,
  //       //LOAN DETAILS
  //       batchLoanAmt: batchLoanSanctionedAmt ? batchLoanSanctionedAmt : 0,
  //       batchLoanPaid: batchLoanPaid ? batchLoanPaid : 0,
  //       batchInterestPaid: batchInterestPaid ? batchInterestPaid : 0,
  //       //OTHER LOAN DETAILS
  //       otherLoanSanctionedToShg: otherLoanSanctionedToShgSum
  //         ? Number(otherLoanSanctionedToShgSum).toFixed(2)
  //         : 0, //sum
  //       otherLoanSanctionedToMember: batchOtherLoanAmts
  //         ? batchOtherLoanAmts
  //         : 0,
  //       otherLoanPaidToShg: otherLoanPaidToShgSum
  //         ? Number(otherLoanPaidToShgSum).toFixed(2)
  //         : 0, //sum
  //       otherLoanPaidToDonor: otherLoanPaidToDonorSum
  //         ? Number(otherLoanPaidToDonorSum).toFixed(2)
  //         : 0, //sum
  //       batchOtherLoanInterestDonor: batchOtherLoanInterestDonorSum
  //         ? Number(batchOtherLoanInterestDonorSum).toFixed(2)
  //         : 0, //sum
  //       batchOtherLoanInterestShg: batchOtherLoanInterestShgSum
  //         ? Number(batchOtherLoanInterestShgSum).toFixed(2)
  //         : 0, //sum
  //       //BANK DETAILS

  //       cashInHand: cashInHand || 0,
  //       batchBankDeposit: batchBankDepositSum
  //         ? Number(batchBankDepositSum).toFixed(2)
  //         : 0, //sumB
  //       batchBankWithdrawals: batchBankWithdrawalsSum
  //         ? Number(batchBankWithdrawalsSum).toFixed(2)
  //         : 0, //sumB
  //       //OTHER
  //       meetingNotes: meetingNotes,

  //       verifiedById:
  //         (e.target.value === "Verify" || batchTransactionData.verifiedById) &&
  //         user &&
  //         user._id,
  //       recordId: batchTransactionData._id,
  //       batchTransactionData: batchTransactionData,
  //     };

  //     editBatchTransaction(finalData);

  //     if (e.target.value === "Verify" || e.target.value === "Update") {
  //       const meetingUpdateData = {
  //         mdDate: meetingBatchInfo.mdDate,
  //         mdBatchId: meetingBatchInfo.mdBatchId,
  //         mdStatus:
  //           selMeetingData.mdStatus === "Rechecked" ? "Submitted" : "Verified",
  //       };
  //       UpdateMeetingStatus(meetingUpdateData);
  //     }

  //     AddedDetailsOLR &&
  //       AddedDetailsOLR.map((data) => {
  //         addNewOtherLoanBatchTransaction(data);
  //       });

  //     bankTran &&
  //       bankTran.map((data) => {
  //         addNewBankTransaction(data);
  //       });

  //     setFormData({
  //       ...formData,
  //       isSubmitted: true,
  //     });
  //   }

  //   //ok
  // };

  const handleMemberMeetingModalCloses = () =>
    setMemberMeetingDetailModals(false);

  const [impMemdata, setImpmemData] = useState(null);

  const onOpenEditModal = (memTransactionData) => {
    setImpmemData({
      memberId: memTransactionData.memberId,
      meetingHeldOnDate: memTransactionData.meetingHeldOnDate,
      batchId: memTransactionData.batchId,
    });

    // meetingHeldOnDate
    // memberId

    // localStorage.removeItem("memberEditData");
    // const meetingInfo = {
    //   mdDate: memTransactionData.meetingHeldOnDate,
    //   mdBatchId: memTransactionData.batchId,
    //   memberId: memTransactionData.memberId,
    // };
    // const batchIdData = {
    //   batchIdVal: memTransactionData.batchId,
    // };
    // getSeletedBatchDetails(batchIdData);
    // getEditMemberMeetingData(meetingInfo);
    // setUserDatas(memTransactionData);
    // setUserData2(meetingInfo);

    setMemberMeetingDetailModals(true);
  };

  const onMemberMeetingModalChanges = (e) => {
    if (e) {
      handleMemberMeetingModalCloses();
    }
  };

  //NEW BANK BALANCE END

  //RECHECK START
  const [showRecheckModal, setShowRecheckModal] = useState(false);
  const handleRecheckModalClose = () => setShowRecheckModal(false);

  const onRecheckModalChange = (e) => {
    if (e) {
      handleRecheckModalClose();
    }
  };
  const onRecheck = () => {
    setShowRecheckModal(true);
  };
  //RECHECK END

  // const funcKeyDown = (e) => {
  //   ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  // };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let mdDate =
    batchIdValue &&
    batchIdValue.mdDate
      .split("-")
      .reverse()
      .join("-");

  //////////PDF
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfFormData, setPdfFormData] = useState("");
  
  const handleFileChange = async (event) => {
   
    let size = 0;
    // console.log(event.target.files.length)
    if(event.target.files.length > 0){
       size = event.target.files[0].size ;
    }else{
      size = 0
    }
     if(size > 4000000){
      event.target.value = null
      alert("File size too large")
     }else{
      setSelectedFile(event.target.files[0]);
      let ED = batchIdValue.mdDate.split(/\D/g);
      let selDate = [ED[2], ED[1], ED[0]].join("-");
  
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      formData.append("batchName", batchIdValue.batchName);
      formData.append("batchMeetingHeldOnDate", selDate);
      
      sendMettingFile(formData);
      setDelay(true)
     }

    

    // setPdfFormData(formData);
  };

  //pdf show hide

  const [showView, setShowView] = useState(false);
  useEffect(() => {
    pdfBase64 ? setShowView(true) : setShowView(false);
  }, [pdfBase64]);

  /////////////////////////////////file upload delay start///////////////////////////////////////
  const[delay,setDelay] = useState(false)
  useEffect(()=>{
    file_upload_successful && file_upload_successful === "success" && setShowView(true)
      },[file_upload_successful])
  /////////////////////////////////file upload delay end////////////////////////////////////////

  ////////////////////storage % calculation///////////////////////////
  let storage = 0;
  let details = ""
  if(storage_info > 0){
   storage =  (storage_info/15360)*100;
    details = storage_info+"MB"+" "+"("+storage.toFixed(2)+"%"+")"
  }else{
    details =  storage_info
  }  ///////////////////////////////////////

  // if (isSubmitted) {
  //   return <Redirect to="/landing-page" />;

  const getAddCurBankBalance = () => {
    return (
      Number(batchSavingAmt.bankAmt) +
      Number(batchSubAmt.bankAmt) +
      Number(batchOtherContribution.bankAmt) +
      Number(batchMembershipFees.bankAmt) +
      Number(deactiveMemberSaving.bankAmt) +
      Number(batchOtherIncome.bankAmt) +
      Number(seedMoney.bankAmt) +
      Number(batchSecurityDepositMemToShg.bankAmt) +
      Number(sdUnionToSHG.bankAmt) +
      Number(loanRepaidByOtherSHG.bankAmt) +
      // Number(travellingExpenses.bankAmt) +
      // Number(stationaryExpenses.bankAmt) +
      // Number(paidToMahasangha.bankAmt) +
      // Number(serviceCharges.bankAmt) +
      // Number(batchDividendDistributed.bankAmt) +
      // Number(otherExpenses.bankAmt) +
      // Number(batchSavingWithdrawals.bankAmt) +
      // Number(batchSecurityDeposit.bankAmt) +
      // Number(donationToOutsider.bankAmt) +
      // Number(sdSHGToUnion.bankAmt) +

      Number(batchLoanAmt.bankAmt) +
      Number(batchLoanPaid.bankAmt) +
      Number(batchInterestPaid.bankAmt)
    );
  };

  const getSubCurBankBalance = () => {
    return (
      Number(travellingExpenses.bankAmt) +
      Number(stationaryExpenses.bankAmt) +
      Number(paidToMahasangha.bankAmt) +
      Number(serviceCharges.bankAmt) +
      Number(batchDividendDistributed.bankAmt) +
      Number(otherExpenses.bankAmt) +
      Number(batchSavingWithdrawals.bankAmt) +
      Number(batchSecurityDeposit.bankAmt) +
      Number(donationToOutsider.bankAmt) +
      Number(sdSHGToUnion.bankAmt)
    );
  };

  const bankBalance = (memSummaryData) => {
    try {
      let {
        batchBankDeposit,
        batchBankWithdrawals,
        bankInterest,
        bankCommission,
        subsidyReceivedBank,
        otherLoanSanctionedToShgBank,
      } = memSummaryData;

      return Number(
        Number(batchBankDeposit) -
          Number(batchBankWithdrawals) +
          Number(bankInterest) -
          Number(bankCommission) +
          Number(subsidyReceivedBank || 0) +
          Number(otherLoanSanctionedToShgBank || 0)
      ).toFixed(2);
    } catch (er) {
      return 0;
    }
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">
            Verify Batch Meeting Details of {batchIdValue.batchName}
          </h2>
          <hr />
        </div>
        <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding">
          <div className="col-lg-6 col-md-11 col-sm-11 col-11 ">
            <h4 className="heading_color">
              Meeting Date:
              {mdDate && mdDate}
            </h4>
          </div>
          <div className="col-lg-4 col-md-11 col-sm-11 col-11">
            {(user.userGroupName === "Admin" ||
              user.userGroupName === "Co-ordinator") &&
              batchTransactionData &&
              !batchTransactionData.verifiedById && (
                <button className="btn float-right" onClick={() => onRecheck()}>
                  Recheck
                </button>
              )}
          </div>
          <div className="col-lg-2 col-md-11 col-sm-11 col-11">
            <Link
              to={{
                pathname:
                  batchIdValue.callFrom === "Verification"
                    ? "/all-meetings-verification"
                    : "/all-verified-meeting",
              }}
            >
              <img
                className="img_icon_size log"
                src={require("../../static/images/back.png")}
                alt="Back"
                title="Back"
              />
            </Link>
          </div>
        </div>
        <section className="sub_reg">
          <Tabs
            selectedIndex={tabIndex}
            // onSelect={(index) => setTabIndex(index)}
          >
            <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
              <TabList>
                <Tab tabfor="0"> Prv Summary </Tab>
                {allPermission &&
                allPermission.includes("Screenshot") &&
                batchTransactionData &&
                batchTransactionData.meetingCategory !== "OP" ? (
                  <Tab tabfor="1">MOM</Tab>
                ) : (
                  <Tab tabfor="1" style={{ display: "none" }}>
                    MOM
                  </Tab>
                )}
                <Tab tabfor="2">All Members</Tab>
                <Tab tabfor="3">Income</Tab>
                <Tab tabfor="4">Expense</Tab>
                <Tab tabfor="5">Loan Details</Tab>
                <Tab tabfor="6">OL Repayment</Tab>
                <Tab tabfor="7">Bank Details</Tab>
                <Tab tabfor="8">EOD Summary</Tab>
              </TabList>
            </div>

            <TabPanel tabId="0">
              <div className="row col-lg-11 col-md-11 col-sm-11 col-11">
                <SummaryMemTab
                  memSummaryData={memSummaryData || ""}
                  allPermission={allPermission}
                  memberClosingAmtAll={
                    memberClosingAmtAll && memberClosingAmtAll[0]
                  }
                  setPrevBankBalance={setPrevBankBalance}
                />

                <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                  <button
                    className="btn sub_form btn_continue Save float-right"
                    onClick={() =>
                      NextBackBtn(
                        allPermission.includes("Screenshot") &&
                          batchTransactionData &&
                          batchTransactionData.meetingCategory !== "OP"
                          ? 1
                          : 2
                      )
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </TabPanel>

            {tabIndex !== 0 && (
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row card-new  py-1">
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                      <label>
                        <b>
                          <b>
                            Previous Bank Balance: {bankBalance(memSummaryData)}
                          </b>
                        </b>
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                      <label>
                        <b>
                          New Bank Balance:
                          {(
                            Number(bankBalance(memSummaryData)) +
                            Number(bankInterest) +
                            Number(batchBankDeposit) -
                            (Number(bankCommission) +
                              Number(batchBankWithdrawals))
                          ).toFixed(2)}
                        </b>
                      </label>
                    </div>

                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label>
                        <b>
                          Previous Cash in Hand :
                          {(memSummaryData && memSummaryData.cashInHand) || 0}
                        </b>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <TabPanel tabId="1">
              <form onSubmit={(e) => NextBackBtn(2)}>
                
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row card-new py-3 ">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5>MOM</h5>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="row">
                          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Meeting Notes* :
                            </label>
                            <textarea
                              name="meetingNotes"
                              value={meetingNotes}
                              id="meetingNotes"
                              className="textarea form-control"
                              rows="4"
                              placeholder="Meeting Notes"
                              onChange={(e) => onInputChange(e)}
                              style={{ width: "100%" }}
                              required
                            ></textarea>
                          </div>

                          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                          {!fileUploadSpinner  ? (<></>) :(<Spinner/>) }
                         
                            <label className="label-control"><b>Upload only PDF</b></label>
                            <input type="file" 
                                   id="application_pdf"
                                   accept="application/pdf" 
                                   multiple={false} onChange={(event) => handleFileChange(event)}/>
                            
                            {!showView ? (
                              <></>
                            ) : (
                              <a
                                href={`${pdfBase64}`}
                                target="_blank"
                                className="text-primary font-weight-bold h6 text-underline"
                              >
                                View PDF 
                              </a>
                            )}
                             <label  className="text-primary">
                              (Max limit 4MB)
                              <br/>
                              Avaiable Storage: {details}
                             </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    
                         <input
                         type="submit"
                         name="submit"
                         value="Next"
                         className="btn sub_form btn_continue Save float-right"
                       />
                    
                         <button
                           className="btn sub_form btn_continue Save float-right"
                           onClick={() => NextBackBtn(0)}
                         >
                           Previous
                         </button>
                       </div>
                    </div>
                  </div>
                </div>
              </form>
            </TabPanel>

            <TabPanel tabId="2">
              <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
                <section className="body">
                  <AllMemberTab
                    membersMeetingData={membersMeetingData}
                    batchTransactionData={batchTransactionData}
                    allRights={allRights}
                    user={user}
                    onOpenEditModal={onOpenEditModal}
                  />
                  <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
                    <button
                      className="btn sub_form btn_continue Save float-right"
                      onClick={() => NextBackBtn(3)}
                    >
                      Next
                    </button>
                    <button
                      className="btn sub_form btn_continue Save float-right"
                      onClick={() =>
                        NextBackBtn(
                          allPermission.includes("Screenshot") &&
                            batchTransactionData &&
                            batchTransactionData.meetingCategory !== "OP"
                            ? 1
                            : 0
                        )
                      }
                    >
                      Previous
                    </button>
                  </div>
                </section>
              </div>
            </TabPanel>

            <TabPanel tabId="3">
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                <IncomeTab
                  membersMeetingData={membersMeetingData}
                  allPermission={allPermission}
                  activeBank={activeBank}
                  formData={formData}
                  setFormData={setFormData}
                  NextBackBtn={NextBackBtn}
                  newBkBal={newBkBal}
                  setnewBkBal={setnewBkBal}
                />
              </div>
            </TabPanel>

            <TabPanel tabId="4">
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                <ExpenseTab
                  formData={formData}
                  setFormData={setFormData}
                  membersMeetingData={membersMeetingData}
                  allPermission={allPermission}
                  activeBank={activeBank}
                  NextBackBtn={NextBackBtn}
                  newBkBal={newBkBal}
                  setnewBkBal={setnewBkBal}
                />
              </div>
            </TabPanel>

            <TabPanel tabId="5">
              <div className="row col-md-12 col-lg-11 col-sm-12 col-12 ">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                  <LoanDetTab
                    membersMeetingData={membersMeetingData}
                    NextBackBtn={NextBackBtn}
                    formData={formData}
                    setFormData={setFormData}
                    newBkBal={newBkBal}
                    setnewBkBal={setnewBkBal}
                  />

                  {/* loan tab end */}
                </div>
              </div>
            </TabPanel>

            <TabPanel tabId="6">
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <OLRepaymentTab
                  membersMeetingData={membersMeetingData}
                  activeBank={activeBank}
                  NextBackBtn={NextBackBtn}
                  formData={formData}
                  setFormData={setFormData}
                  newBkBal={newBkBal}
                  setnewBkBal={setnewBkBal}
                />
              </div>
            </TabPanel>

            <TabPanel tabId="7">
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                  <BankDetTab
                    membersMeetingData={membersMeetingData}
                    activeBank={activeBank}
                    batchData={batchData}
                    NextBackBtn={NextBackBtn}
                    formData={formData}
                    setFormData={setFormData}
                    newBkBal={newBkBal}
                    setnewBkBal={setnewBkBal}
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel tabId="8">
              <div>
                <EODSummaryTab
                  NextBackBtn={NextBackBtn}
                  formData={formData}
                  allPermission={allPermission}
                  memSummaryData={memSummaryData || ""}
                  memberClosingAmtAll={
                    memberClosingAmtAll && memberClosingAmtAll[0]
                  }
                  batchTransactionData={batchTransactionData}
                  allRights={allRights}
                  user={user}
                  fromPage={"EditMeeting"}
                />
              </div>
            </TabPanel>
          </Tabs>
        </section>

        <Modal
          show={showMemberMeetingDetailsModals}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Edit Meeting Details</h3>
            </div>
            <div className="col-lg-2">
              <button
                onClick={handleMemberMeetingModalCloses}
                className="close"
              >
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditMemberModel
              memberData={impMemdata}
              allPermission={allPermission}
              activeBank={activeBank}
              handleMemberMeetingModalCloses={handleMemberMeetingModalCloses}
            />

            {/* <EditMembermeetingDetailModal
              membersBatch={userDatas}
              userData2={userData2}
              selectedDate={batchIdValue.mdDate}
              onMemberMeetingModalChanges={onMemberMeetingModalChanges}
            /> */}
          </Modal.Body>
        </Modal>

        {showRecheckModal && (
          <RecheckReasonModal
            show={showRecheckModal}
            handleModalClose={handleRecheckModalClose}
            record={meetingBatchInfo}
            onModalChange={onRecheckModalChange}
          />
        )}
      </div>
    </Fragment>
  );
};

VerificationMemberBatchMeetingDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  editBatchTransaction,
  getParticularLoanReason,
  getMembersMeetingData,
  getMeetingSummary,
  getMemberMeetingSummary, //getMemberMeetingSummary
  UpdateMeetingStatus,
  getEditMemberMeetingData,
  getSeletedBatchDetails,
  getActiveBankData,
  getActiveOtherLoanType,
  getExistingOLData,
  getExistingBankData,
  deleteBatchOtherLoan,
  deleteBankData,
  addNewOtherLoanBatchTransaction,
  addNewBankTransaction,
  getSelMeetingDateData,
  sendMettingFile,
  getBatchMeetingData,
  getMeetingTransactionData
})(VerificationMemberBatchMeetingDetails);
