import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { getStates, editDistrictDetails } from "../../actions/area";
import Spinner from "../layout/Spinner";

const EditDistrictDetails = ({
  auth: { isAuthenticated, user, users, loading },
  getStates,
  editDistrictDetails,
  districts,
}) => {
  useEffect(() => {
    getStates();
  }, [getStates]);

  //formData
  const [formData, setFormData] = useState({
    districtName:
      districts && districts.districtName ? districts.districtName : "",
    isSubmitted: false,
  });

  const { districtName } = formData;
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const allstates = [];
  let selStateData = JSON.parse(localStorage.getItem("selStateData"));
  selStateData &&
    selStateData.map((state) => {
      allstates.push({
        stateId: state._id,
        label: state.stateName,
        value: state.stateName,
      });
    });

  const [state, setStateData] = useState(
    districts
      ? allstates.length !== 0
        ? allstates &&
          allstates.filter((x) => x.stateId === districts.stateId)[0]
        : ""
      : ""
  );
  if (selStateData && selStateData.length !== 0) {
    let hi = districts
      ? allstates.length !== 0
        ? allstates &&
          allstates.filter((x) => x.stateId === districts.stateId)[0]
        : ""
      : "";
  }
  const [stateId, setStateID] = useState(districts.stateId);

  const onStateChange = (e) => {
    var stateId = "";
    setStateData(e);
    stateId = e.stateId;
    setStateID(stateId);
  };

  const onUpdate = (districts, idx) => {
    const finalData = {
      recordId: districts ? districts._id : "",
      districtName: districtName,
      stateId: stateId,
      districtEnteredById: user._id,
      districtEnteredByName: user.userName,
      institutionId: user.institutionId,
    };
    editDistrictDetails(finalData);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <label className="label-control"> District Name* :</label>
            <input
              type="text"
              name="districtName"
              value={districtName}
              className="form-control"
              onChange={(e) => onInputChange(e)}
              required
            />
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <label className="label-control">State* :</label>
            <Select
              name="stateName"
              options={allstates}
              isSearchable={true}
              value={state}
              placeholder="Select State"
              onChange={(e) => onStateChange(e)}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
        </div>

        <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
          {loading ? (
            <button
              className="btn sub_form btn_continue Save float-right"
              disabled
            >
              Loading...
            </button>
          ) : (
            <button
              variant="success"
              className="btn sub_form btn_continue Save float-right"
              onClick={() => onUpdate(districts)}
              style={
                districtName !== ""
                  ? { opacity: "1" }
                  : { opacity: "1", pointerEvents: "none" }
              }
            >
              Update
            </button>
          )}
        </div>
      </div>
    </Fragment>
  );
};

EditDistrictDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  editDistrictDetails: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
});

export default connect(mapStateToProps, {
  getStates,
  editDistrictDetails,
})(EditDistrictDetails);
