import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Select from "react-select";
import { Modal } from "react-bootstrap";
import PDFViewer from "../layout/PDFViwer";
import ImgPreview from "./ImgPreview";

import {
  editBatchTransaction,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  removeMessage,
  getMeetingSummary,
  AddLoanSanctionedDetails,
  getMeetingTransactionData,
  getLoanSantionedData,
  UpdateMeetingStatus,
  getActiveOtherLoanType,
  getActiveBankData,
  deleteBatchOtherLoan,
  deleteBankData,
  addNewOtherLoanBatchTransaction,
  getExistingOLData,
  getExistingBankData,
  addNewBankTransaction,
  getSelMeetingDateData,
  sendMettingFile,
  getMemberMeetingSummary,
  getBatchMeetingData,
} from "../../actions/shg";

import EditLoanDetails from "./EditLoanDetails";
import EditOtherLoanDetails from "./EditOtherLoanDetails";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Spinner from "../layout/Spinner";
import DeactiveLoanDetails from "./DeactiveLoanDetails";
import RecheckReasonModal from "./RecheckReasonModal";

import BatchIncomeTab from "./batchMeetingTabs/BatchIncomeTab";
import BatchExpenseTab from "./batchMeetingTabs/BatchExpenseTab";
import BatchOLRepayment from "./batchMeetingTabs/BatchOLRepayment";
import BatchLoanSanction from "./batchMeetingTabs/BatchLoanSanction";
import BatchOLSanction from "./batchMeetingTabs/BatchOLSanction";
import SummaryBatchTab from "./batchMeetingTabs/SummaryBatchTab";

import BatchBankDetTab from "./batchMeetingTabs/BatchBankDetTab";

const VerificationBatchMeetingDetails = ({
  auth: { isAuthenticated, user, users, loading },
  shg: {
    loanreason,
    meetingResponse,
    summaryData,
    summaryDataOp,
    selBatchMem,
    loanSactionedData,
    memberClosingAmt,
    activeOtherLoanTypes,
    activeBankData,
    exstOLData,
    exstBankData,
    selMeetingData,
    pdfBase64,
    memSummaryData,
    batchData,
  },
  editBatchTransaction,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  removeMessage,
  getMeetingSummary,
  getLoanSantionedData,
  UpdateMeetingStatus,
  AddLoanSanctionedDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  deleteBatchOtherLoan,
  deleteBankData,
  addNewOtherLoanBatchTransaction,
  getExistingOLData,
  getExistingBankData,
  addNewBankTransaction,
  getSelMeetingDateData,
  sendMettingFile,
  getMemberMeetingSummary,

  getBatchMeetingData,
}) => {
  // let bankBalance = 0,
  //   outstandingInternalLoanAmt = 0,
  //   outstandinOtherLoanAmt = 0;

  // let lastMeetingData = JSON.parse(localStorage.getItem("lastMeeingDate"));
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  // let selectedBatchData = JSON.parse(localStorage.getItem("selBatchData"));
  let batchTransactionData = JSON.parse(
    localStorage.getItem("batchTransactionData")
  );
  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const allRights = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.groupRights &&
    loggedUserInstitutionData.groupRights.map((groupRights) =>
      allRights.push(groupRights)
    );

  const meetingBatchInfo = {
    mdDate: batchIdValue.mdDate,
    mdBatchId: batchIdValue.batchId,
  };

  useEffect(() => {
    removeMessage();
  }, [removeMessage]);

  useEffect(() => {
    getParticularLoanReason();
    getSelMeetingDateData(meetingBatchInfo);
    getMemberMeetingSummary(meetingBatchInfo);
    getBatchMeetingData(meetingBatchInfo);
  }, [getParticularLoanReason]);
  useEffect(() => {
    getMembersOfSelectedBatch({ batchIdValue: batchIdValue.batchId });
  }, [getMembersOfSelectedBatch]);
  useEffect(() => {
    getMeetingSummary(meetingBatchInfo);
  }, [getMembersOfSelectedBatch]);
  useEffect(() => {
    // 123
    getLoanSantionedData(meetingBatchInfo);
  }, [getLoanSantionedData]);
  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);
  useEffect(() => {
    getExistingOLData(meetingBatchInfo);
  }, [getExistingOLData]);
  useEffect(() => {
    getExistingBankData(meetingBatchInfo);
  }, [getExistingBankData]);
  useEffect(() => {
    getActiveBankData(batchIdValue);
  }, [getActiveBankData]);

  const [formData, setFormData] = useState({
    // deactiveMemberSaving: null,
    // sdUnionToSHG: null,
    batchId: null,
    batchName: null,
    batchMeetingHeldOnDate: null,
    batchMeetingMonth: null,
    batchMeetingYear: null,
    batchTransactionType: null, // selBatchData[0].batchTransactionType,
    meetingCategory: null,
    meetingNotes: "",
    batchSavingAmt: null, //BC
    batchSubAmt: null, //BC
    batchOtherContribution: null, //BC
    batchMembershipFees: null, //BC
    deactiveMemberSaving: null, //BC
    batchOtherIncome: null, //BC
    seedMoney: null, //BC
    batchSecurityDepositMemToShg: null, //BC
    sdUnionToSHG: null, //BC
    loanRepaidByOtherSHG: null, //BC
    travellingExpenses: null, //BC
    stationaryExpenses: null, //bc,
    paidToMahasangha: null, //bc
    bankCommission: null, //no
    serviceCharges: null, //bc
    batchDividendDistributed: null, //bc
    otherExpenses: null, //bc
    batchSavingWithdrawals: null, //bc
    batchSecurityDeposit: null, //bc
    donationToOutsider: null, //bc
    sdSHGToUnion: null, //bc
    batchLoanAmt: null, //bc
    batchLoanPaid: null, //bc
    batchInterestPaid: null, //bc
    otherLoanSanctionedToMember: null, //bc
    otherLoanSanctionedToShg: 0, //no
    loanGivenToOtherSHG: 0, //no
    subsidyDistributed: 0, //no
    subsidyReceived: 0, //NO
    bankInterest: 0, //NO
    otherLoanPaidToShg: 0, //no
    otherLoanPaidToDonor: 0, //no
    batchOtherLoanInterestShg: 0, // no
    batchOtherLoanInterestDonor: 0, //no
    otherLoanBatchTransaction: [], // [], //Array
    bankTransactions: [], //[] //array
    cashInHand: 0, //no
    batchBankDeposit: 0, //no
    batchBankWithdrawals: 0, //no
    institutionId: loggedUserInstitutionData.institutionId,
    meetingFileName: null,
    loanSancArray: [],
    othloanSancArray: [],
    internalBankExpense: 0,
    // bankSDSUId: null,
    // bankSDSU: "",
    // selectionSDSUType: "",
  });

  const [showNewBankBalance, setShowNewBankBalnce] = useState([]); //flag 123
  const [thisMeetingBnkBal, setThisMeetingBnkBal] = useState(0);
  // const [negateShowNewbankBalance, setNegateShowNewBankBalance] = useState();

  useEffect(() => {
    if (batchData) {
      setFormData({
        ...formData,
        _id: batchData._id,
        batchId: batchData.batchId,
        batchName: batchData.batchName,
        batchMeetingHeldOnDate: batchData.batchMeetingHeldOnDate,
        batchMeetingMonth: batchData.batchMeetingMonth,
        batchMeetingYear: batchData.batchMeetingYear,
        batchTransactionType: batchData.batchTransactionType,
        meetingCategory: batchData.meetingCategory,
        meetingNotes: batchData.meetingNotes || "",
        batchSavingAmt: batchData.batchSavingAmt, //BC
        batchSubAmt: batchData.batchSubAmt, //BC
        batchOtherContribution: batchData.batchOtherContribution, //BC
        batchMembershipFees: batchData.batchMembershipFees, //BC
        bankInterest: batchData.bankInterest, //NO
        deactiveMemberSaving: batchData.deactiveMemberSaving, //BC
        subsidyReceived: batchData.subsidyReceived, //NO
        batchOtherIncome: batchData.batchOtherIncome, //BC
        seedMoney: batchData.seedMoney, //BC
        batchSecurityDepositMemToShg: batchData.batchSecurityDepositMemToShg, //BC
        sdUnionToSHG: batchData.sdUnionToSHG, //BC

        loanRepaidByOtherSHG: batchData.loanRepaidByOtherSHG, //BC
        travellingExpenses: batchData.travellingExpenses, //BC
        stationaryExpenses: batchData.stationaryExpenses, //bc,
        paidToMahasangha: batchData.paidToMahasangha, //bc
        bankCommission: batchData.bankCommission, //no
        serviceCharges: batchData.serviceCharges, //bc
        batchDividendDistributed: batchData.batchDividendDistributed, //bc
        otherExpenses: batchData.otherExpenses, //bc
        batchSavingWithdrawals: batchData.batchSavingWithdrawals, //bc
        batchSecurityDeposit: batchData.batchSecurityDeposit, //bc
        subsidyDistributed: batchData.subsidyDistributed, //no
        donationToOutsider: batchData.donationToOutsider, //bc
        sdSHGToUnion: batchData.sdSHGToUnion, //bc

        selectionSDSUType: "",
        loanGivenToOtherSHG: batchData.loanGivenToOtherSHG, //no
        batchLoanAmt: batchData.batchLoanAmt, //bc
        batchLoanPaid: batchData.batchLoanPaid, //bc
        batchInterestPaid: batchData.batchInterestPaid, //bc
        otherLoanSanctionedToShg: batchData.otherLoanSanctionedToShg, //no
        otherLoanSanctionedToMember: batchData.otherLoanSanctionedToMember, //bc
        otherLoanPaidToShg: batchData.otherLoanPaidToShg, //no
        otherLoanPaidToDonor: batchData.otherLoanPaidToDonor, //no
        batchOtherLoanInterestShg: batchData.batchOtherLoanInterestShg, //no
        batchOtherLoanInterestDonor: batchData.batchOtherLoanInterestDonor, //no
        otherLoanBatchTransaction: batchData.otherLoanBatchTransaction, // [], //Array

        bankTransactions: batchData.bankTransactions, //[] //array

        cashInHand: batchData.cashInHand, //no
        batchBankDeposit: batchData.batchBankDeposit, //no
        batchBankWithdrawals: batchData.batchBankWithdrawals, //no
        institutionId: batchData.institutionId,
        meetingFileName: batchData.meetingFileName,
        internalBankExpense: batchData.internalBankExpense,
      });
    }
  }, [batchData]);

  useEffect(() => {}, [loanSactionedData]);

  let {
    meetingNotes,
    batchSavingAmt, //BC
    batchSubAmt, //BC
    batchOtherContribution, //BC
    batchMembershipFees, //BC

    deactiveMemberSaving, //BC

    batchOtherIncome, //BC
    seedMoney, //BC
    batchSecurityDepositMemToShg, //BC
    sdUnionToSHG, //BC

    loanRepaidByOtherSHG, //BC
    travellingExpenses, //BC
    stationaryExpenses, //bc,
    paidToMahasangha, //bc

    serviceCharges, //bc
    batchDividendDistributed, //bc
    otherExpenses, //bc
    batchSavingWithdrawals, //bc
    batchSecurityDeposit, //bc

    donationToOutsider, //bc
    sdSHGToUnion, //bc

    batchLoanAmt, //bc
    batchLoanPaid, //bc
    batchInterestPaid, //bc

    otherLoanSanctionedToMember,

    bankInterest,
    bankTransactions,
    batchBankDeposit,
    bankCommission,
    batchBankWithdrawals,
    otherLoanBatchTransaction,
  } = formData;

  // setThisMeetingBnkBal("1");
  useEffect(() => {
    setThisMeetingBnkBal(
      bankTransactions.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.indBatchBankDeposit || 0) +
            Number(cur.indBankInterest || 0) -
            (Number(cur.indBankCommission || 0) +
              Number(cur.indBatchBankWithdrawals || 0))),
        0
      )
    );
  }, [bankTransactions]);

  const activeBank = [];
  activeBankData &&
    activeBankData.map((bank) =>
      activeBank.push({
        bankId: bank._id,
        label: bank.batchBankName,
        value: bank.batchBankName,
      })
    );

  const allMembers = [];
  selBatchMem.map((member) =>
    allMembers.push({
      memberId: member._id,
      label: member.memberName,
      value: member.memberName,
    })
  );

  const allpurpose = [];
  loanreason.map((purpose) =>
    allpurpose.push({
      loanReasonId: purpose._id,
      label: purpose.loanPurpose,
      value: purpose.loanPurpose,
    })
  );

  const [showPdf, setShowPdf] = useState(false);

  const showMyPdf = (e) => {
    setShowPdf(true);
  };

  const [showView, setShowView] = useState(false);

  useEffect(() => {
    pdfBase64 ? setShowView(true) : setShowView(false);
  }, [pdfBase64]);

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);

  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //RECHECK START
  const [showRecheckModal, setShowRecheckModal] = useState(false);
  const handleRecheckModalClose = () => setShowRecheckModal(false);

  const onRecheckModalChange = (e) => {
    if (e) {
      handleRecheckModalClose();
    }
  };

  const onRecheck = () => {
    setShowRecheckModal(true);
  };

  useEffect(() => {}, [memSummaryData]);

  let prevBankBalance =
    Number((memSummaryData && memSummaryData.bankInterest) || 0) +
    Number((memSummaryData && memSummaryData.batchBankDeposit) || 0) -
    Number((memSummaryData && memSummaryData.bankCommission) || 0) -
    Number((memSummaryData && memSummaryData.batchBankWithdrawals) || 0);

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="row col-lg-12 col-md-11 col-sm-12 col-12">
          <h2 className="">
            Verify SHG Meeting Details of{""}
            {batchIdValue.batchName}
          </h2>
          <hr />
        </div>

        <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding">
          <div className="col-lg-6 col-md-10 col-sm-11 col-11">
            <h4 className="heading_color">Meeting Held On </h4>
          </div>
          <div className="col-lg-4 col-md-11 col-sm-11 col-11">
            {(user.userGroupName === "Admin" ||
              user.userGroupName === "Co-ordinator") &&
              batchTransactionData &&
              !batchTransactionData.verifiedById && (
                <button className="btn float-right" onClick={() => onRecheck()}>
                  Recheck
                </button>
              )}
          </div>
          <div className="col-lg-2 col-md-2 col-sm-11 col-11">
            <Link
              to={{
                pathname:
                  batchIdValue && batchIdValue.callFrom === "Verification"
                    ? "/all-meetings-verification"
                    : "/all-verified-meeting",
              }}
            >
              <img
                className="img_icon_size log"
                src={require("../../static/images/back.png")}
                alt="Back"
                title="Back"
              />
            </Link>
          </div>
          <div className="row col-lg-9 col-md-12 col-sm-11 col-11 ">
            <div className="col-lg-3 col-md-12 col-sm-11 col-11">
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="selectedDate"
                value={batchIdValue.mdDate}
                style={{
                  width: "100%",
                }}
                disabled
              />
            </div>
          </div>
        </div>
        <section className="sub_reg">
          <Tabs
            selectedIndex={tabIndex}
            //   onSelect={(index) => setTabIndex(index)}
          >
            <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
              <TabList>
                <Tab tabfor="0"> Summary </Tab>
                {allPermission &&
                allPermission.includes("Screenshot") &&
                batchTransactionData &&
                batchTransactionData.meetingCategory !== "OP" ? (
                  <Tab tabfor="1">MOM</Tab>
                ) : (
                  <Tab tabfor="1" style={{ display: "none" }}>
                    MOM
                  </Tab>
                )}
                <Tab tabfor="2">Income</Tab>
                <Tab tabfor="3">Expense</Tab>
                <Tab tabfor="4">OL Sanctioned and Repayment</Tab>
                <Tab tabfor="5">Loan Sanctioned</Tab>
                <Tab tabfor="6">OL Mem Sanctioned</Tab>
                <Tab tabfor="7">Bank Details</Tab>
              </TabList>
            </div>

            <TabPanel tabId="0">
              <form
                onSubmit={(e) =>
                  NextBackBtn(
                    allPermission.includes("Screenshot") &&
                      batchTransactionData &&
                      batchTransactionData.meetingCategory !== "OP"
                      ? 1
                      : 2
                  )
                }
              >
                <div className="row col-lg-11 col-md-11 col-sm-11 col-11">
                  {memSummaryData ? (
                    <SummaryBatchTab
                      memSummaryData={memSummaryData || null}
                      allPermission={allPermission}
                    />
                  ) : (
                    <></>
                  )}

                  <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                    <input
                      type="submit"
                      name="submit"
                      value="Next"
                      className="btn sub_form btn_continue Save float-right"
                    />
                  </div>
                </div>
              </form>
            </TabPanel>
            {tabIndex !== 0 &&
              batchTransactionData &&
              batchTransactionData.meetingCategory !== "OP" && (
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row card-new  py-1">
                      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>
                          <b>
                            Previous Bank Balance :{prevBankBalance.toFixed(2)}
                          </b>
                        </label>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>
                          <b>
                            New Bank Balance:
                            {(
                              Number(prevBankBalance) +
                              Number(thisMeetingBnkBal)
                            )
                              // beta test
                              // Number(formData.bankInterest) +
                              // Number(formData.batchBankDeposit) -
                              // (Number(formData.bankCommission) +
                              //   Number(formData.batchBankWithdrawals))
                              .toFixed(2)}
                          </b>
                        </label>
                      </div>

                      <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>
                          <b>
                            Previous Cash in Hand :{" "}
                            {memSummaryData && memSummaryData.cashInHand}
                          </b>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <TabPanel tabId="1">
              {batchTransactionData &&
                batchTransactionData.meetingCategory !== "OP" && (
                  <form onSubmit={(e) => NextBackBtn(2)}>
                    <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="row card-new py-3 ">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h5>MOM</h5>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="row">
                              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <label className="label-control">
                                  Meeting Notes* :
                                </label>
                                <textarea
                                  name="meetingNotes"
                                  value={meetingNotes}
                                  id="meetingNotes"
                                  className="textarea form-control"
                                  rows="4"
                                  placeholder="Meeting Notes"
                                  onChange={(e) => onInputChange(e)}
                                  style={{ width: "100%" }}
                                  required
                                ></textarea>
                              </div>
                              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12"> */}
                                <label className="label-control">
                                  <b>Upload only PDF</b>
                                </label>
                                <input
                                  type="file"
                                  accept="application/pdf"
                                  multiple={false}
                                  // onChange={(event) => handleFileChange(event)}
                                />
                                {!showView ? (
                                  <></>
                                ) : (
                                  <button
                                    type="button"
                                    className="mt-3 btn btn-block"
                                    onClick={(e) => showMyPdf(e)}
                                  >
                                    view
                                  </button>
                                )}
                                {/* </div> */}
                              </div>
                              <div className="col-lg-4 col-md-12 col-sm-12 col-12"></div>
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <input
                              type="submit"
                              name="submit"
                              value="Next"
                              className="btn sub_form btn_continue Save float-right"
                            />
                            <button
                              className="btn sub_form btn_continue Save float-right"
                              onClick={() => NextBackBtn(0)}
                            >
                              Previous
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
            </TabPanel>
            <TabPanel tabId="2">
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <BatchIncomeTab
                    NextBackBtn={NextBackBtn}
                    formData={formData}
                    setFormData={setFormData}
                    activeBank={activeBank}
                    allPermission={allPermission}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="3">
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  {/* <OnlyBatchExpense /> */}
                  <BatchExpenseTab
                    NextBackBtn={NextBackBtn}
                    formData={formData}
                    setFormData={setFormData}
                    activeBank={activeBank}
                    allPermission={allPermission}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="4">
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                  <BatchOLRepayment
                    // membersMeetingData={membersMeetingData}
                    activeBank={activeBank}
                    NextBackBtn={NextBackBtn}
                    formData={formData}
                    setFormData={setFormData}
                    allPermission={allPermission}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="5">
              <div className="row col-md-12 col-lg-11 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <BatchLoanSanction
                    allMembers={allMembers}
                    activeBank={activeBank}
                    NextBackBtn={NextBackBtn}
                    formData={formData}
                    setFormData={setFormData}
                    getLoanSantionedData={getLoanSantionedData}
                    meetingBatchInfo={meetingBatchInfo}
                    allpurpose={allpurpose}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="6">
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                  <BatchOLSanction
                    NextBackBtn={NextBackBtn}
                    formData={formData}
                    getLoanSantionedData={getLoanSantionedData}
                    meetingBatchInfo={meetingBatchInfo}
                    setFormData={setFormData}
                    allMembers={allMembers}
                    activeBank={activeBank}
                    allpurpose={allpurpose}
                    allPermission={allPermission}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel tabId="7">
              <BatchBankDetTab
                activeBank={activeBank}
                batchData={batchData}
                NextBackBtn={NextBackBtn}
                formData={formData}
                setFormData={setFormData}
                fromPage={"EditBatchMeeting"}
                allRights={allRights}
                allPermission={allPermission}
                batchTransactionData={batchTransactionData}
                user={user}
                setShowNewBankBalnce={setShowNewBankBalnce}
              />
            </TabPanel>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12 float-right">
              <label className="label-control colorRed">
                * Indicates mandatory fields, Please fill mandatory fields
                before Submit
              </label>
            </div>
          </Tabs>
        </section>
      </div>

      {/* Model for pdf */}
      {/* <Modal show={showPdf} size="lg" style={{ height: "90%" }}>
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">PDF VIEW</h3>
          </div>
          <div className="col-lg-2">
            <button onClick={() => setShowPdf(false)} className="close">
              <img
                src={require("../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <PDFViewer base64Data={pdfBase64} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showUpdateModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">Edit Loan Details </h3>
          </div>
          <div className="col-lg-2">
            <button onClick={handleUpdateModalClose} className="close">
              <img
                src={require("../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <EditLoanDetails
            loanpurposedata={userData}
            onUpdateModalChange={onUpdateModalChange}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showotherLoanUpdateModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">
              Edit other Loan Details{" "}
            </h3>
          </div>
          <div className="col-lg-2">
            <button onClick={handleotherLoanUpdateModalClose} className="close">
              <img
                src={require("../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <EditOtherLoanDetails
            otherloanpurposedata={userData1}
            onotherloanUpdateModalChange={onotherloanUpdateModalChange}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeactiveModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">Delete Loan Details</h3>
          </div>
          <div className="col-lg-2">
            <button onClick={handleDeactiveModalClose} className="close">
              <img
                src={require("../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <DeactiveLoanDetails
            loanDeactiveData={userDatas}
            onDeactiveModalChange={onDeactiveModalChange}
          />
        </Modal.Body>
      </Modal>

      {showRecheckModal && (
        <RecheckReasonModal
          show={showRecheckModal}
          handleModalClose={handleRecheckModalClose}
          record={meetingBatchInfo}
          onModalChange={onRecheckModalChange}
        />
      )}

      {showImage && (
        <ImgPreview
          show={showImage}
          setShow={setShowImage}
          data={pertImg}
          // data={meetingPhotoUpload}
        />
      )} */}
      {showRecheckModal && (
        <RecheckReasonModal
          show={showRecheckModal}
          handleModalClose={handleRecheckModalClose}
          record={meetingBatchInfo}
          onModalChange={onRecheckModalChange}
        />
      )}
    </Fragment>
  );
};

VerificationBatchMeetingDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  editBatchTransaction,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  removeMessage,
  getMeetingSummary,
  AddLoanSanctionedDetails,
  getMeetingTransactionData,
  getLoanSantionedData,
  UpdateMeetingStatus,
  getActiveOtherLoanType,
  getActiveBankData,
  deleteBatchOtherLoan,
  deleteBankData,
  addNewOtherLoanBatchTransaction,
  getExistingOLData,
  getExistingBankData,
  addNewBankTransaction,
  getSelMeetingDateData,
  sendMettingFile,
  getMemberMeetingSummary,
  getBatchMeetingData,
})(VerificationBatchMeetingDetails);
