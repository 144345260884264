import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUserGroups } from "../../actions/user";
import EditUserGroup from "./EditUserGroup";
import Spinner from "../layout/Spinner";

const AllUserGroups = ({
  auth: { isAuthenticated, user, users },
  user: { userGroups },
  getUserGroups,
}) => {
  useEffect(() => {
    getUserGroups();
  }, [getUserGroups]);

  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);

  const [userData, setUserData] = useState(null);
  const onEdit = (usergroupdata, idx) => {
    setShowUpdateModal(true);
    setUserData(usergroupdata);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">All User Group Details </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-12 col-md-11 col-sm-12 col-12 no_padding">
            <div className="col-lg-11 col-md-12 col-sm-12 col-11 py-3">
              <Link to="/landing-page">
                <img
                  className="img_icon_size log float-right ml-3"
                  src={require("../../static/images/back.png")}
                  alt="Back"
                  title="Back"
                />
              </Link>
              <Link to="/add-user-groups">
                <img
                  className="img_icon_size log float-right"
                  src={require("../../static/images/add-icon.png")}
                  alt="Add UserGroup"
                  title="Add UserGroup"
                />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
              <section className="body">
                <div className=" body-inner no-padding table-responsive ">
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>User Group Name</th>
                        <th>Op</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userGroups &&
                        userGroups.map((userGroups, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{userGroups.userGroupName}</td>
                              <td>
                                {" "}
                                <img
                                  className="img_icon_size log"
                                  onClick={() => onEdit(userGroups, idx)}
                                  src={require("../../static/images/edit_icon.png")}
                                  alt="Edit"
                                  title="Edit"
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </section>
              <Modal
                show={showUpdateModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <div className="col-lg-10">
                    <h3 className="modal-title text-center">
                      Edit UserGroup Details{" "}
                    </h3>
                  </div>
                  <div className="col-lg-2">
                    <button onClick={handleUpdateModalClose} className="close">
                      <img
                        src={require("../../static/images/close.png")}
                        alt="X"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </button>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <EditUserGroup
                    usergroupdata={userData}
                    onUpdateModalChange={onUpdateModalChange}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </section>
        <div className="row">
          <div className="col-lg-11 col-md-6 col-sm-12 col-12 align_right">
            <label className="float-right ">
              No. of User Groups : {userGroups.length}
            </label>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AllUserGroups.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  getUserGroups: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
});

export default connect(mapStateToProps, {
  getUserGroups,
})(AllUserGroups);
